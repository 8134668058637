/*! modernizr 3.12.0 (Custom Build) | MIT *
 * https://modernizr.com/download/?-cors-es6array-es6string-flexbox-flexwrap-video !*/
!(function (e, t, n, r) {
  function o(e, t) {
    return typeof e === t
  }
  function i(e, t) {
    return !!~('' + e).indexOf(t)
  }
  function a() {
    return 'function' != typeof n.createElement
      ? n.createElement(arguments[0])
      : x
      ? n.createElementNS.call(n, 'http://www.w3.org/2000/svg', arguments[0])
      : n.createElement.apply(n, arguments)
  }
  function s() {
    var e = n.body
    return e || ((e = a(x ? 'svg' : 'body')), (e.fake = !0)), e
  }
  function l(e, t, r, o) {
    var i,
      l,
      p,
      d,
      c = 'modernizr',
      f = a('div'),
      u = s()
    if (parseInt(r, 10))
      for (; r--; )
        (p = a('div')), (p.id = o ? o[r] : c + (r + 1)), f.appendChild(p)
    return (
      (i = a('style')),
      (i.type = 'text/css'),
      (i.id = 's' + c),
      (u.fake ? u : f).appendChild(i),
      u.appendChild(f),
      i.styleSheet
        ? (i.styleSheet.cssText = e)
        : i.appendChild(n.createTextNode(e)),
      (f.id = c),
      u.fake &&
        ((u.style.background = ''),
        (u.style.overflow = 'hidden'),
        (d = P.style.overflow),
        (P.style.overflow = 'hidden'),
        P.appendChild(u)),
      (l = t(f, e)),
      u.fake && u.parentNode
        ? (u.parentNode.removeChild(u), (P.style.overflow = d), P.offsetHeight)
        : f.parentNode.removeChild(f),
      !!l
    )
  }
  function p(e) {
    return e
      .replace(/([A-Z])/g, function (e, t) {
        return '-' + t.toLowerCase()
      })
      .replace(/^ms-/, '-ms-')
  }
  function d(e, n, r) {
    var o
    if ('getComputedStyle' in t) {
      o = getComputedStyle.call(t, e, n)
      var i = t.console
      if (null !== o) r && (o = o.getPropertyValue(r))
      else if (i) {
        var a = i.error ? 'error' : 'log'
        i[a].call(
          i,
          'getComputedStyle returning null, its possible modernizr test results are inaccurate'
        )
      }
    } else o = !n && e.currentStyle && e.currentStyle[r]
    return o
  }
  function c(e, n) {
    var o = e.length
    if ('CSS' in t && 'supports' in t.CSS) {
      for (; o--; ) if (t.CSS.supports(p(e[o]), n)) return !0
      return !1
    }
    if ('CSSSupportsRule' in t) {
      for (var i = []; o--; ) i.push('(' + p(e[o]) + ':' + n + ')')
      return (
        (i = i.join(' or ')),
        l(
          '@supports (' + i + ') { #modernizr { position: absolute; } }',
          function (e) {
            return 'absolute' === d(e, null, 'position')
          }
        )
      )
    }
    return r
  }
  function f(e) {
    return e
      .replace(/([a-z])-([a-z])/g, function (e, t, n) {
        return t + n.toUpperCase()
      })
      .replace(/^-/, '')
  }
  function u(e, t, n, s) {
    function l() {
      d && (delete _.style, delete _.modElem)
    }
    if (((s = !o(s, 'undefined') && s), !o(n, 'undefined'))) {
      var p = c(e, n)
      if (!o(p, 'undefined')) return p
    }
    for (
      var d, u, y, v, m, h = ['modernizr', 'tspan', 'samp'];
      !_.style && h.length;

    )
      (d = !0), (_.modElem = a(h.shift())), (_.style = _.modElem.style)
    for (y = e.length, u = 0; u < y; u++)
      if (
        ((v = e[u]),
        (m = _.style[v]),
        i(v, '-') && (v = f(v)),
        _.style[v] !== r)
      ) {
        if (s || o(n, 'undefined')) return l(), 'pfx' !== t || v
        try {
          _.style[v] = n
        } catch (e) {}
        if (_.style[v] !== m) return l(), 'pfx' !== t || v
      }
    return l(), !1
  }
  function y(e, t) {
    return function () {
      return e.apply(t, arguments)
    }
  }
  function v(e, t, n) {
    var r
    for (var i in e)
      if (e[i] in t)
        return !1 === n
          ? e[i]
          : ((r = t[e[i]]), o(r, 'function') ? y(r, n || t) : r)
    return !1
  }
  function m(e, t, n, r, i) {
    var a = e.charAt(0).toUpperCase() + e.slice(1),
      s = (e + ' ' + C.join(a + ' ') + a).split(' ')
    return o(t, 'string') || o(t, 'undefined')
      ? u(s, t, r, i)
      : ((s = (e + ' ' + b.join(a + ' ') + a).split(' ')), v(s, t, n))
  }
  function h(e, t, n) {
    return m(e, r, r, t, n)
  }
  var g = [],
    w = {
      _version: '3.12.0',
      _config: {
        classPrefix: '',
        enableClasses: !0,
        enableJSClass: !0,
        usePrefixes: !0,
      },
      _q: [],
      on: function (e, t) {
        var n = this
        setTimeout(function () {
          t(n[e])
        }, 0)
      },
      addTest: function (e, t, n) {
        g.push({ name: e, fn: t, options: n })
      },
      addAsyncTest: function (e) {
        g.push({ name: null, fn: e })
      },
    },
    Modernizr = function () {}
  ;(Modernizr.prototype = w), (Modernizr = new Modernizr())
  var T = []
  Modernizr.addTest(
    'cors',
    'XMLHttpRequest' in t && 'withCredentials' in new XMLHttpRequest()
  ),
    Modernizr.addTest(
      'es6array',
      !!(
        Array.prototype &&
        Array.prototype.copyWithin &&
        Array.prototype.fill &&
        Array.prototype.find &&
        Array.prototype.findIndex &&
        Array.prototype.keys &&
        Array.prototype.entries &&
        Array.prototype.values &&
        Array.from &&
        Array.of
      )
    ),
    Modernizr.addTest(
      'es6string',
      !!(
        String.fromCodePoint &&
        String.raw &&
        String.prototype.codePointAt &&
        String.prototype.repeat &&
        String.prototype.startsWith &&
        String.prototype.endsWith &&
        String.prototype.includes
      )
    )
  var S = 'Moz O ms Webkit',
    C = w._config.usePrefixes ? S.split(' ') : []
  w._cssomPrefixes = C
  var P = n.documentElement,
    x = 'svg' === P.nodeName.toLowerCase(),
    A = { elem: a('modernizr') }
  Modernizr._q.push(function () {
    delete A.elem
  })
  var _ = { style: A.elem.style }
  Modernizr._q.unshift(function () {
    delete _.style
  })
  var b = w._config.usePrefixes ? S.toLowerCase().split(' ') : []
  ;(w._domPrefixes = b),
    (w.testAllProps = m),
    (w.testAllProps = h),
    Modernizr.addTest('flexbox', h('flexBasis', '1px', !0)),
    Modernizr.addTest('flexwrap', h('flexWrap', 'wrap', !0)),
    (function () {
      var e = a('video')
      Modernizr.addTest('video', function () {
        var t = !1
        try {
          ;(t = !!e.canPlayType), t && (t = new Boolean(t))
        } catch (e) {}
        return t
      })
      try {
        e.canPlayType &&
          (Modernizr.addTest(
            'video.ogg',
            e.canPlayType('video/ogg; codecs="theora"').replace(/^no$/, '')
          ),
          Modernizr.addTest(
            'video.h264',
            e.canPlayType('video/mp4; codecs="avc1.42E01E"').replace(/^no$/, '')
          ),
          Modernizr.addTest(
            'video.h265',
            e.canPlayType('video/mp4; codecs="hev1"').replace(/^no$/, '')
          ),
          Modernizr.addTest(
            'video.webm',
            e
              .canPlayType('video/webm; codecs="vp8, vorbis"')
              .replace(/^no$/, '')
          ),
          Modernizr.addTest(
            'video.vp9',
            e.canPlayType('video/webm; codecs="vp9"').replace(/^no$/, '')
          ),
          Modernizr.addTest(
            'video.hls',
            e
              .canPlayType('application/x-mpegURL; codecs="avc1.42E01E"')
              .replace(/^no$/, '')
          ),
          Modernizr.addTest(
            'video.av1',
            e.canPlayType('video/mp4; codecs="av01"').replace(/^no$/, '')
          ))
      } catch (e) {}
    })(),
    (function () {
      var e, t, n, r, i, a, s
      for (var l in g)
        if (g.hasOwnProperty(l)) {
          if (
            ((e = []),
            (t = g[l]),
            t.name &&
              (e.push(t.name.toLowerCase()),
              t.options && t.options.aliases && t.options.aliases.length))
          )
            for (n = 0; n < t.options.aliases.length; n++)
              e.push(t.options.aliases[n].toLowerCase())
          for (
            r = o(t.fn, 'function') ? t.fn() : t.fn, i = 0;
            i < e.length;
            i++
          )
            (a = e[i]),
              (s = a.split('.')),
              1 === s.length
                ? (Modernizr[s[0]] = r)
                : ((Modernizr[s[0]] &&
                    (!Modernizr[s[0]] || Modernizr[s[0]] instanceof Boolean)) ||
                    (Modernizr[s[0]] = new Boolean(Modernizr[s[0]])),
                  (Modernizr[s[0]][s[1]] = r)),
              T.push((r ? '' : 'no-') + s.join('-'))
        }
    })(),
    delete w.addTest,
    delete w.addAsyncTest
  for (var E = 0; E < Modernizr._q.length; E++) Modernizr._q[E]()
  e.Modernizr = Modernizr
})(window, window, document)
