import { Auth0Provider } from '@auth0/auth0-react'
import { ReactNode } from 'react'

import { authService, removeAuth0QueryParams } from '../../../util/auth-service'
import { logAction } from '../../../util/logging'
import { getEnvConfig } from '../../env'

export const DOM_EVENT_USER_SIGNED_IN = 'usersignedin'

export type Auth0ProviderWithHistoryProps = {
  children: ReactNode
}

// This is only used during Cypress tests
const sessionStorageCache = {
  get: function (key: string): string | null {
    const storageItem = sessionStorage.getItem(key)
    return storageItem ? JSON.parse(storageItem) : null
  },
  set: function (key: string, value: string) {
    sessionStorage.setItem(key, JSON.stringify(value))
  },
  remove: function (key: string) {
    sessionStorage.removeItem(key)
  },
}

export const Auth0ProviderWithHistory = ({
  children,
}: Auth0ProviderWithHistoryProps) => {
  const envConfig = getEnvConfig()

  const onRedirectCallback = (): void => {
    // I initially tried to pass a callback prop to handle the login event,
    // but the analytics context (SegmentProvider) was not available in the callback.
    //
    // I'm decoupling this by just dispatching a custom event that is listened for in the analytics module.
    // This is a bit of a hack, but it works.
    const returnTo = authService.getAuthReturnTo()
    const navigateTo = returnTo || window.location.search

    logAction({ message: `User signed in`, context: { navigateTo, returnTo } })
    sessionStorage.removeItem('sessionId')
    setTimeout(() => {
      document.dispatchEvent(new Event(DOM_EVENT_USER_SIGNED_IN))
    }, 1_500)

    authService.resetSessionStart()

    // Not redirecting if the path is the same
    const [navigateToPathname] = navigateTo.split('?')
    if (window.location.pathname === navigateToPathname) {
      const appPathname = window.location.pathname
      logAction({
        message: `User signed in to the same path, skipping auth redirect. Removing any auth0 query params`,
        context: { appPathname, navigateToPathname },
      })

      const newUrlParams = removeAuth0QueryParams()
      window.location.assign(
        `${window.location.origin}${appPathname}${newUrlParams}`
      )
      return
    }

    // We can't rely on the router to do this redirect because we still can land on either the container or an app
    // meaning that if we were to land in the container and redirect to an app without module federation, it will break.
    // Until we get rid of the container, we are going to .assign the next route in
    logAction({
      message: `Redirecting user to their intended page`,
      context: { navigateTo },
    })
    window.location.assign(`${window.location.origin}${navigateTo}`)
  }

  const sessionId = sessionStorage.getItem('sessionId')
  const [, appBasePath] = window.location.pathname.split('/')

  return envConfig ? (
    <Auth0Provider
      domain={envConfig.AUTH0_DOMAIN}
      clientId={envConfig.AUTH0_CLIENT_ID}
      authorizationParams={{
        authorizationParams: sessionId
          ? JSON.stringify({ session_id: sessionId })
          : JSON.stringify({}),
        audience: envConfig.AUTH0_AUDIENCE,
        redirect_uri: `${window.location.origin}/${
          __NX_DEVELOPMENT__ && !__NX_USE_AUTH_REDIRECT__
            ? appBasePath
            : `auth-redirect?app=${appBasePath}`
        }`,
      }}
      // Callback to call once user arrives at the above redirectUri to redirect to specific page and query string
      onRedirectCallback={onRedirectCallback}
      // When Cypress tests are running, swap the default inMemory cache for sessionStorageCache so we can manipulate the state
      // @ts-ignore
      cache={window.Cypress ? sessionStorageCache : undefined}
    >
      {children}
    </Auth0Provider>
  ) : null
}
