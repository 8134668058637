import { APP_PATHS } from '../../../../domain/apps/lib/apps'
import type {
  CaisiqVideo,
  CaisiqVideoPlaylist,
  ExternalContent,
  GetLandingPageQuery,
  GetPressReleasesQuery,
  PageLanding,
  Link,
  LinkCmsLink,
  GetHomepageLandingPageQuery,
  EventCardFragment,
  Pdf,
} from '../../../graphql/mfe-contentful'
import type { TypeArticleData } from '../../../type/article-data'
import type { WhitepaperData } from '../../../type/whitepaper-data'

export type EventType = EventCardFragment
export type EventStatusKey = 'upcoming' | 'open' | 'almost-full' | 'full'

export type ContentDataType =
  | TypeArticleData
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | WhitepaperData
  | EventType
  | ExternalContent

// This is obviously not necessary but felt like a better naming convention
export type CuratedContentDataType =
  | TypeArticleData
  | CaisiqVideo
  | CaisiqVideoPlaylist
  | WhitepaperData
  | EventType
  | ExternalContent
  | Pdf
  | Link

export type PressReleaseType = NonNullable<
  GetPressReleasesQuery['pressReleases']
>['items'][0]

export type HomeContentType = {
  latest: { carousel: ContentDataType[]; error: boolean }
  latestByFirm: { carousel: ContentDataType[]; error: boolean }
  events: { carousel: ContentDataType[]; error: boolean }
  webinars: { carousel: ContentDataType[]; error: boolean }
  research: { carousel: ContentDataType[]; error: boolean }
  tutorials: { carousel: ContentDataType[]; error: boolean }
  products: { carousel: ContentDataType[]; error: boolean }
}

export type PageData = NonNullable<
  GetLandingPageQuery['pageCollection']
>['items'][0]

export type HomepageLandingPageQueryData = NonNullable<
  GetHomepageLandingPageQuery['homepageLandingPageCollection']
>['items'][0]

export type LandingPageData = Omit<Exclude<PageData, null>, 'content'> & {
  content: PageLanding
}

/**
  Pages and Links
*/
export type PageTypes = 'CaisiqArticle' | 'Whitepaper' | 'PageResource'

export type SiteConfig = {
  pages: {
    readonly [key in PageTypes]: {
      path: string
      slug?: string
    }
  }
}

export type CTALink =
  | (Partial<Omit<Link, 'cmsLink'>> & {
      cmsLink?: Partial<LinkCmsLink> | null
    })
  | null

type AppIds = (typeof APP_PATHS)[number]
type AppsSiteConfig = Partial<Record<AppIds, SiteConfig>>

const SITE_CONFIG: AppsSiteConfig = {
  homepage: {
    pages: {
      PageResource: {
        path: '/resources',
        slug: '/[slug]',
      },
      CaisiqArticle: {
        path: '/articles',
        slug: '/[slug]',
      },
      Whitepaper: {
        path: '/whitepapers',
        slug: '/[slug]',
      },
    },
  },
}

export const HOMEPAGE_SITE_CONFIG = SITE_CONFIG.homepage as SiteConfig
