import type { Options, RenderNode } from '@contentful/rich-text-react-renderer'
import type { Node } from '@contentful/rich-text-types'
import { INLINES } from '@contentful/rich-text-types'
import { ReactNode } from 'react'

import { Link } from '../../../../../equity/web/atoms/link'
import { BodyLinks } from '../../../../util/type/body'
import { baseBlockComponents } from '../../rich-text-base'

function inlineLink(node: Node, children: ReactNode, segmentPageId?: string) {
  // TODO HP-102 add support for tracking - potentially needs to be app specific
  return <Link href={node.data['uri']}>{children}</Link>
}

export type RichTextRenderProps = {
  links: BodyLinks
  segmentPageId?: string
  renderNode?:
    | RenderNode
    | ((links?: BodyLinks | undefined) => RenderNode)
    | undefined
}

export function richTextRenderOptions(props: RichTextRenderProps): Options {
  return {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) =>
        inlineLink(node, children, props?.segmentPageId),
      ...baseBlockComponents,
    },
  }
}
