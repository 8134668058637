import {
  useLayoutEffect,
  useRef,
  useState,
  type ReactElement,
  type ReactNode,
} from 'react'
import { useSnapCarousel } from 'react-snap-carousel'

import { CarouselButton } from './carousel-button'

export type CarouselProps<T> = {
  items: T[]
  itemsPerPage?: 3 | 4
  itemMinWidth?: keyof typeof layout.itemWidth
  renderItem: (
    props: CarouselRenderItemProps<T>
  ) => ReactElement<CarouselItemProps> | null
  minHeight?: `${number}px`
  testId?: string
}

type CarouselRenderItemProps<T> = {
  item: T
  isSnapPoint?: boolean
}

type CarouselItemProps = {
  isSnapPoint?: boolean
  children?: ReactNode
}

function makeWidthFunction(props: { count: number; min: number; max: number }) {
  const { min, max, count } = props
  return `clamp(${min}px,calc((var(--container-width,0) - ${
    count - 1
  } * var(--gap,0) - var(--elevation,0)) / ${count}),${max}px)`
}

const layout = {
  itemGap: {
    small: 'md:[--gap:24px]',
    medium: 'md:[--gap:32px]',
    large: 'md:[--gap:32px]',
  },
  itemWidth: {
    small: (count: number) => makeWidthFunction({ count, min: 250, max: 320 }),
    medium: (count: number) => makeWidthFunction({ count, min: 300, max: 363 }),
    large: (count: number) => makeWidthFunction({ count, min: 360, max: 480 }),
  },
  itemsPerPage: {
    3: '[--itemsPerPage:3]',
    4: '[--itemsPerPage:4]',
  },
}

const cssToMakeUnitTestHappy = {
  scrollMargin: {
    scrollMarginLeft: '0px',
    scrollMarginTop: '0px',
  },
  scrollPadding: {
    scrollPaddingLeft: 'auto',
    scrollPaddingTop: 'auto',
  },
}

export const Carousel = <
  T extends (Record<string, unknown> & { id?: string | number | null }) | null
>(
  props: CarouselProps<T>
) => {
  const {
    items,
    itemsPerPage = 4,
    itemMinWidth,
    minHeight,
    renderItem,
    testId,
  } = props
  const { activePageIndex, next, pages, prev, scrollRef, snapPointIndexes } =
    useSnapCarousel()
  const [containerWidth, setContainerWidth] = useState(0)
  const containerRef = useRef<HTMLDivElement>(null)

  // Observe the container width and recalculate the item width so that they fit the space as evenly as possible
  useLayoutEffect(() => {
    if (containerRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (const entry of entries) {
          setContainerWidth(entry.contentRect.width)
        }
      })

      observer.observe(containerRef.current)

      return () => {
        observer.disconnect()
      }
    }
    return
  }, [])

  const widthFunction = itemMinWidth
    ? layout.itemWidth[itemMinWidth](itemsPerPage)
    : ''
  const itemGap = itemMinWidth ? layout.itemGap[itemMinWidth] : ''
  const itemsPerPageCssVar = layout.itemsPerPage[itemsPerPage]

  return (
    <div ref={containerRef} className="relative max-w-full">
      <ul
        data-testid={`${testId}-carousel`}
        ref={scrollRef}
        className={`${itemGap} ${itemsPerPageCssVar} relative mb-[2px] flex snap-x snap-mandatory gap-[--gap] overflow-auto overscroll-x-contain [--elevation:2px] [--gap:16px] [--offset:0.5rem] [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden`}
        style={{
          // @ts-expect-error Declaring css variable
          '--min-width': widthFunction,
          '--container-width': `${containerWidth}px`,
          ...cssToMakeUnitTestHappy.scrollPadding,
          ...(minHeight && { minHeight }), // Note: tactical solution to make the home Browse Content carousels equal height
        }}
      >
        {items.map((item, index) => (
          <CarouselItem
            key={item?.id || index}
            isSnapPoint={snapPointIndexes.has(index)}
          >
            {renderItem({ item })}
          </CarouselItem>
        ))}
      </ul>
      {pages.length > 1 ? (
        <>
          <CarouselButton
            disabled={activePageIndex === 0}
            direction="prev"
            handleClick={prev}
          />
          <CarouselButton
            disabled={activePageIndex === pages.length - 1}
            direction="next"
            handleClick={next}
          />
        </>
      ) : null}
    </div>
  )
}

const CarouselItem = ({ isSnapPoint, children }: CarouselItemProps) => (
  <li
    className={`flex w-[var(--min-width)] items-stretch pb-[--offset] pl-[--elevation] ${
      isSnapPoint ? 'snap-start' : ''
    }`}
    style={cssToMakeUnitTestHappy.scrollMargin}
  >
    {children}
  </li>
)
