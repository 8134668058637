export const menus = {
  PROFILE_MENU: [
    {
      testId: 'profile-button',
      name: 'My Profile',
      href: '/access-manager/profile',
      entitlements: {
        hard_coded_rule: '!justcaisiq',
      },
    },
    {
      testId: 'settings-button',
      name: 'Continuing Education Profile',
      href: '[caisiq]/authenticated/profile',
      urlType: 'absolute',
    },
    {
      name: 'Admin',
      href: '/access-manager/admin',
      entitlements: {
        permission: 'admin:read',
      },
    },
  ],
  MENU: [
    {
      name: 'Investments',
      menu: {
        id: 'investments-menu',
        groups: [
          {
            items: [
              {
                name: 'Alternatives',
                href: '/funds-pre-trade',
                entitlements: {
                  and: [
                    {
                      featureFlag: 'cais.global-nav-investments-menu',
                    },
                    {
                      permission: 'funds:products:advisor-read',
                    },
                  ],
                },
              },
              {
                name: 'Structured Investments',
                href: '/structured-investments/current-offerings',
                entitlements: {
                  and: [
                    {
                      featureFlag: 'cais.global-nav-investments-menu',
                    },
                    {
                      permission: 'structured-products:current-offerings:read',
                    },
                  ],
                },
              },
              {
                name: 'Structured Investments SMAs',
                href: '/homepage/structured-investments/structured-investments-smas',
                entitlements: {
                  and: [
                    { featureFlag: 'cais.global-nav-investments-menu' },
                    {
                      permission: 'structured-products:current-offerings:read',
                    },
                    {
                      capability: 'STRUCTURED_PRODUCTS',
                    },
                    { capability: 'STRUCTURED_PRODUCTS_MARINER_SMAS' },
                  ],
                },
              },
              {
                name: 'Hedging and Monetization Strategies',
                href: '/homepage/structured-investments/hedging-and-monetization-strategies',
                entitlements: {
                  and: [
                    { featureFlag: 'cais.global-nav-investments-menu' },
                    {
                      permission: 'structured-products:current-offerings:read',
                    },
                    { capability: 'STRUCTURED_PRODUCTS' },
                    { capability: 'STRUCTURED_PRODUCTS_HEDGE_MONETIZATION' },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Offerings',
      id: 'offerings-menu',
      menu: {
        groups: [
          {
            items: [
              {
                name: 'Alternatives',
                href: '/funds-pre-trade',
                entitlements: {
                  and: [
                    { permission: 'funds:products:advisor-read' },
                    { featureFlag: '!cais.global-nav-investments-menu' },
                  ],
                },
              },
              {
                name: 'Structured Investments',
                href: '/structured-investments/current-offerings',
                entitlements: {
                  and: [
                    {
                      permission: 'structured-products:current-offerings:read',
                    },
                    { featureFlag: '!cais.global-nav-investments-menu' },
                  ],
                },
              },
              {
                name: 'Structured Investments SMAs',
                href: '/homepage/structured-investments/structured-investments-smas',
                entitlements: {
                  and: [
                    {
                      permission: 'structured-products:current-offerings:read',
                    },
                    {
                      capability: 'STRUCTURED_PRODUCTS',
                    },
                    { capability: 'STRUCTURED_PRODUCTS_MARINER_SMAS' },
                    { featureFlag: '!cais.global-nav-investments-menu' },
                  ],
                },
              },
            ],
          },
          {
            items: [
              {
                name: 'Model Portfolios',
                href: '/model-portfolios',
                entitlements: {
                  and: [
                    { featureFlag: '!cais.global-nav-investments-menu' },
                    { permission: 'portfolios:model:read' },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Models',
      href: '/model-portfolios',
      entitlements: {
        and: [
          { featureFlag: 'cais.global-nav-model-portfolios' },
          {
            permission: 'portfolios:model:read',
          },
        ],
      },
    },
    {
      name: 'Orders',
      menu: {
        id: 'orders-menu',
        groups: [
          {
            items: [
              {
                name: 'Alternatives',
                href: '/investment-pipeline/ai/grid/5',
                entitlements: {
                  or: [
                    {
                      feature: 'PORTAL_PIPELINE_ALTERNATIVES_ACTIVE_USER',
                    },
                    {
                      feature: 'PORTAL_PIPELINE_ALTERNATIVES_ACTIVE_ADMIN',
                    },
                    {
                      permission: 'portal:view-pipeline',
                    },
                    {
                      permission: 'portal:view-manage-transaction-details',
                    },
                    {
                      permission: 'portal:generate-recommendations',
                    },
                  ],
                },
              },
              {
                name: 'Structured Investments',
                href: '/structured-investments/advisor/order-management-tool',
                entitlements: {
                  or: [
                    {
                      permission: 'structured-products:orders:advisors:read',
                    },
                    {
                      permission: 'structured-products:orders:advisors:write',
                    },
                  ],
                },
              },
              {
                name: 'Structured Investments (Internal)',
                href: '/structured-investments/sales/order-management-tool',
                entitlements: {
                  or: [
                    {
                      permission: 'structured-products:orders:sales:read',
                    },
                    {
                      permission: 'structured-products:orders:sales:write',
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Holdings',
      menu: {
        id: 'holdings-menu',
        groups: [
          {
            items: [
              {
                name: 'Alternatives',
                href: '/cais-accounts/holdings/hedge-funds/positions/cais',
                entitlements: {
                  permission: 'portal:view-cais-accounts',
                },
              },
              {
                name: 'Structured Investments',
                href: '/structured-investments/holdings',
                entitlements: {
                  permission: 'structured-products:read',
                },
              },
              {
                name: 'Documents',
                href: '/document-library/',
                entitlements: {
                  or: [
                    { permission: 'documents:read:all' },
                    { permission: 'documents:read:firm' },
                    { permission: 'documents:read:team' },
                    { permission: 'documents:read:user' },
                    { permission: 'pipelines:documents:read' },
                    { permission: 'pipelines:documents:rerun' },
                  ],
                },
              },
              {
                name: 'Client Snapshot',
                href: '/snapshot/',
                entitlements: {
                  feature: 'PORTAL_DASHBOARD',
                },
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Accounts',
      href: '/investor-profile',
      entitlements: {
        permission: 'ips:profiles:read',
      },
    },
    {
      name: 'Analytics',
      menu: {
        id: 'analytics-menu',
        groups: [
          {
            items: [
              {
                name: 'CAIS IQ Dashboard',
                href: '[caisiq]authenticated/homeoffice',
                entitlements: {
                  or: [
                    {
                      permission: 'caisiq:reporting:read',
                    },
                    {
                      permission: 'portal:ROLE_CAISIQ_REPORTING',
                    },
                  ],
                },
                urlType: 'absolute',
              },
              {
                name: 'Home Office Dashboard',
                href: '/home-office-insights',
                entitlements: {
                  permission: 'home-office-insights:read',
                },
              },
              {
                name: 'Manager Dashboard',
                href: '/fund-manager',
                entitlements: {
                  permission: 'insights:read',
                },
              },
              {
                name: 'Reports',
                href: '/platform/reports',
                entitlements: {
                  feature: 'PORTAL_REPORTS',
                },
              },
              {
                name: 'CAIS Compass',
                href: '/cais-compass',
                entitlements: {
                  permission: 'portfolios:custom:read',
                },
              },
            ],
          },
        ],
      },
    },
    {
      name: 'CAIS IQ',
      href: '[caisiq]/authenticated/courses',
      entitlements: {
        feature: 'PORTAL_EDUCATION_CAISIQ',
      },
      urlType: 'absolute',
    },
    {
      name: 'Insights',
      menu: {
        id: 'insights-menu',
        groups: [
          {
            items: [
              {
                name: 'Events',
                href: '/homepage/events',
                entitlements: {
                  and: [
                    {
                      persona: '!FUND_MANAGER',
                      hard_coded_rule: '!justcaisiq',
                    },
                  ],
                },
              },
              {
                name: 'Research',
                href: '/homepage/research',
                entitlements: {
                  and: [
                    {
                      persona: '!FUND_MANAGER',
                      hard_coded_rule: '!justcaisiq',
                    },
                  ],
                },
              },
              {
                name: 'Structured Investments',
                href: '/homepage/resources/structured-investments',
                entitlements: {
                  and: [
                    {
                      persona: '!FUND_MANAGER',
                      hard_coded_rule: '!justcaisiq',
                      permission: 'structured-products:current-offerings:read',
                      capability: 'STRUCTURED_PRODUCTS',
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Help',
      menu: {
        id: 'help-menu',
        groups: [
          {
            items: [
              {
                name: 'Tutorials',
                href: '/homepage/help/tutorials',
                entitlements: {
                  and: [
                    {
                      persona: '!FUND_MANAGER',
                      hard_coded_rule: '!justcaisiq',
                    },
                  ],
                },
              },
              {
                name: 'Glossary',
                href: '/homepage/help/glossary',
                entitlements: {
                  and: [
                    {
                      persona: '!FUND_MANAGER',
                      hard_coded_rule: '!justcaisiq',
                    },
                  ],
                },
              },
              {
                name: 'FAQ',
                href: '/homepage/help/faqs',
                entitlements: {
                  and: [
                    {
                      persona: '!FUND_MANAGER',
                      hard_coded_rule: '!justcaisiq',
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
    {
      name: 'Manage CAIS',
      menu: {
        id: 'manage-cais-menu',
        groups: [
          {
            items: [
              {
                name: 'Alternatives',
                href: '/funds-product-master',
                entitlements: {
                  permission: 'funds:products:admin-read',
                },
              },
              {
                name: 'Structured Investments',
                href: '/product-master',
                entitlements: {
                  permission: 'structured-products:write',
                },
              },
              {
                name: 'CAIS IQ',
                href: '[caisiq]/authenticated/manage/experiences',
                entitlements: {
                  permission: 'portal:ROLE_CAISIQ_ADMIN',
                },
                urlType: 'absolute',
              },
              {
                name: 'Firms & Users',
                href: '/access-manager',
                entitlements: {
                  or: [
                    { permission: 'iam:users:list:all' },
                    { permission: 'iam:firms:list:all' },
                  ],
                },
              },
              {
                name: 'Custodians',
                href: '/custodian-manager',
                entitlements: {
                  or: [
                    { permission: 'custodians:read' },
                    { permission: 'custodians:list' },
                  ],
                },
              },
              {
                name: 'Systems Manager',
                href: '/systems-manager',
                entitlements: { permission: 'communications:list:all' },
              },
              {
                name: 'Funds Orders',
                href: '/funds-trade/active-orders',
                entitlements: { permission: 'funds:orders:read' },
              },
              {
                name: 'Feature Flags',
                href: '/access-manager/feature-flags',
                entitlements: { permission: 'cais:feature-flags:read' },
              },
              {
                name: 'Funds Investments (ADS)',
                href: '/ads',
                entitlements: { permission: 'files:ads:read:all' },
              },
            ],
          },
          {
            name: 'Classic Platform',
            items: [
              {
                name: 'Sales',
                href: '/manageCAISX#sales',
                entitlements: {
                  feature: 'PORTAL_MANAGE_CAIS',
                },
              },
              {
                name: 'Marketing',
                href: '/manageCAISX#marketing',
                entitlements: {
                  feature: 'PORTAL_MANAGE_CAIS',
                },
              },
              {
                name: 'Operations',
                href: '/manageCAISX#operations',
                entitlements: {
                  feature: 'PORTAL_MANAGE_CAIS',
                },
              },
              {
                name: 'Legal',
                href: '/internal/eta/newrequests',
                entitlements: {
                  feature: 'PORTAL_MANAGE_CAIS',
                },
              },
              {
                name: 'Investment Solutions',
                href: '/manageCAISX#investmentSolutions',
                entitlements: {
                  feature: 'PORTAL_MANAGE_CAIS',
                },
              },
              {
                name: 'Analytics',
                href: '/internal/analytics',
                entitlements: {
                  feature: 'PORTAL_MANAGE_CAIS',
                },
              },
              {
                name: 'Data Quality',
                href: '/internal/dataquality',
                entitlements: {
                  feature: 'PORTAL_MANAGE_CAIS',
                },
              },
            ],
          },
        ],
      },
    },
  ],
}
