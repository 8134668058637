import { Icon } from '../../../icon'

import type { BaseButtonProps } from './types'

type AdornmentProps = {
  adornment: BaseButtonProps['endAdornment' | 'startAdornment']
  size: BaseButtonProps['size']
}

export const Adornment = ({ adornment, size }: AdornmentProps) => {
  return adornment ? (
    <Icon type={adornment} size={size === 'small' ? 'small' : 'regular'} />
  ) : null
}
