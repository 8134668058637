import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { parseCssString } from './utils'

// Styles from Type system on figma, use these from now on, challenge if design strays from guidelines:
// https://www.figma.com/file/9p46RPQfnXRph68T9RvTOR/Type-system?node-id=42%3A2

export const H2Css = css`
  font-size: 3rem; // 48px
  line-height: 4.5rem; // 72px
  font-weight: normal;
`

export const H2CssObject = parseCssString(H2Css.styles)

export const H2 = styled.h2`
  ${H2Css}
`

export const H2StrongCss = css`
  ${H2Css}
  font-weight: 600;
`

export const H2StrongCssObject = parseCssString(H2StrongCss.styles)

export const H2Strong = styled.h2`
  ${H2StrongCss}
`

export const H2SerifCss = css`
  ${H2Css}
  font-family: var(--font-serif);
`

export const H2SerifCssObject = parseCssString(H2SerifCss.styles)

export const H2Serif = styled.h2`
  ${H2SerifCss}
`
