import { APP_URL } from '@cais-group/homepage/util/common'
import type { CuratedContentCardProps } from '@cais-group/shared/ui/contentful/card'
import {
  prepareCuratedCard,
  CardCurated,
} from '@cais-group/shared/ui/contentful/card'
import type { CuratedContentDataType } from '@cais-group/shared/util/contentful/types'

type HighlightedContentProps = {
  cards: CuratedContentDataType[]
}

export function CuratedContent(props: HighlightedContentProps) {
  if (!props.cards.length) {
    return null
  }

  return (
    <div className="bg-neutral-0 p-24" aria-label="Curated firm resources">
      <h2 className="headline-s-strong mb-16">Your Resources</h2>
      <div className="space-y-8">
        {props.cards.map((card, index) => {
          const props = prepareCuratedCard<CuratedContentCardProps>(
            card,
            APP_URL
          )
          return props ? (
            <CardCurated
              key={`${card.sys.id}`}
              {...props}
              variant={index === 0 ? 'primary' : 'secondary'}
            />
          ) : null
        })}
      </div>
    </div>
  )
}
