import {
  TRACKING_PREFIX,
  type TrackingAttributes,
  TrackingPropsExtended,
} from './constants'

export function mapTrackingKeysToDataAttributes<
  TOthers extends object = object
>(props: Partial<TrackingPropsExtended<TOthers>> = {}): TrackingAttributes {
  const { status, ...tracking } = props

  if (status === 'off') {
    return {
      [TRACKING_PREFIX]: false,
    }
  }

  const mappedKeys: Record<string, unknown> = {}

  for (const [key, value] of Object.entries(tracking)) {
    const mappedKey = `${TRACKING_PREFIX}-${key}`
    if (typeof value === 'object') {
      mappedKeys[mappedKey] = JSON.stringify(value)
    } else {
      mappedKeys[mappedKey] = value
    }
  }

  return {
    ...(status === 'on' && { [TRACKING_PREFIX]: true }),
    ...mappedKeys,
  }
}
