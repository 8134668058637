import cx from 'classnames'
import * as React from 'react'
import { ForwardedRef, useId } from 'react'

import {
  mapTrackingKeysToDataAttributes,
  Tracking,
} from '../../../../utils/tracking-utils'
import { Icon } from '../../icon'

import { getBackgroundColor, getBorderColor } from './styles'
import { CheckboxColor } from './types'

export type CheckboxProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'style' | 'className'
> & {
  /** Whether the checkbox is checked */
  checked?: boolean
  /** The variant of the button */
  color?: CheckboxColor
  /** If the label should be display as bold */
  bold?: boolean
  /** Whether the checkbox is in an indeterminate state */
  indeterminate?: boolean
  /** Where the checkbox is shown at the center or at the top (useful for multilines label) */
  verticalAlign?: 'top' | 'center'
} & Tracking

/**
 * A Checkbox component that allows users to select or not a given option,
 * when using with others it allows to select one or more options from a list of options.
 * A checkbox can be used as a controlled or uncontrolled component.
 */
export const Checkbox = React.forwardRef(
  (props: CheckboxProps, ref: ForwardedRef<HTMLInputElement>) => {
    const {
      id: providedId,
      children,
      bold = false,
      indeterminate = false,
      checked,
      value,
      color = 'primary',
      disabled,
      readOnly,
      verticalAlign = 'center',
      tracking,
      ...rest
    } = props
    const generatedId = useId()
    const id = readOnly ? generatedId : providedId ?? generatedId
    const inputProps = readOnly
      ? { readOnly: true, 'aria-labelledby': id }
      : { ref, ...rest }

    return (
      <div
        className="relative flex items-center"
        onClick={props?.onMouseDown}
        {...mapTrackingKeysToDataAttributes({
          status: 'on',
          click_type: 'Checkbox',
          ...tracking,
          item_name: `${tracking?.item_name ?? children} (${
            // This is deliberately opposite because the tracking happens before the state change
            checked ? 'On -> Off' : 'Off -> On'
          })`,
        })}
      >
        <input
          id={readOnly ? undefined : id}
          type="checkbox"
          className={cx('peer absolute inset-0 m-0 h-16 w-16 opacity-0', {
            'cursor-not-allowed': disabled,
            'cursor-pointer': !disabled,
          })}
          disabled={disabled}
          checked={checked}
          data-testid="checkbox"
          {...inputProps}
        />
        <label
          id={readOnly ? id : undefined}
          htmlFor={id}
          className={cx(
            'flex select-none space-x-16 [&>svg>*]:opacity-0 peer-checked:[&>svg>*]:opacity-100',
            'tr-standard-short-1-colors [&>svg]:border [&>svg]:border-solid peer-checked:[&>svg]:border-0',
            verticalAlign === 'top'
              ? 'items-start [&>svg]:mt-4'
              : 'items-center',
            bold ? 'small-strong' : 'small',
            disabled
              ? 'cursor-not-allowed text-neutral-500'
              : 'cursor-pointer text-neutral-900',
            getBorderColor(color),
            getBackgroundColor(color)
          )}
        >
          <Icon
            size="tiny"
            type={!indeterminate ? 'Check' : 'Remove'}
            color={
              color === 'warning' && !disabled
                ? 'eq-color-neutral-900'
                : 'eq-color-neutral-0'
            }
            className="!tr-standard-short-1-colors !motion-reduce:transition-none"
          />
          {children ? (
            <span className="checked:scale-150">{children}</span>
          ) : null}
        </label>
      </div>
    )
  }
)
