import { pathOr } from 'remeda'

import { TailwindEquityColor } from '../../../../../particles/colors'

import {
  ButtonSize,
  ButtonState,
  ButtonVariant,
  ColorCombination,
} from './types'

export const getBorderColor = (
  variant: ButtonVariant,
  color: ColorCombination
): string => {
  const borderColorMap: Partial<
    Record<ButtonVariant, Record<ColorCombination, Array<TailwindEquityColor>>>
  > = {
    primary: {
      'primary-regular': [
        'border-primary-600',
        'hover:border-primary-700',
        'disabled:border-primary-300',
      ],
      'primary-inverse': [
        'border-primary-600-c',
        'hover:border-primary-200',
        'disabled:border-primary-500',
      ],
      'success-regular': [
        'border-success-600',
        'hover:border-success-700',
        'disabled:border-success-300',
      ],
      'success-inverse': [
        'border-success-600-c',
        'hover:border-success-200',
        'disabled:border-success-500',
      ],
      'error-regular': [
        'border-error-600',
        'hover:border-error-700',
        'disabled:border-error-300',
      ],
      'error-inverse': [
        'border-error-600-c',
        'hover:border-error-200',
        'disabled:border-error-500',
      ],
      'neutral-regular': [
        'border-neutral-600',
        'hover:border-neutral-700',
        'disabled:border-neutral-300',
      ],
      'neutral-inverse': [
        'border-neutral-900-c',
        'hover:border-neutral-300',
        'disabled:border-neutral-600',
      ],
    },
    secondary: {
      'primary-regular': [
        'border-neutral-200',
        'hover:border-neutral-600',
        'disabled:border-neutral-200',
      ],
      'primary-inverse': [
        'border-primary-400',
        'hover:border-primary-200',
        'disabled:border-primary-400',
      ],
      'success-regular': [
        'border-success-200',
        'hover:border-success-600',
        'disabled:border-success-200',
      ],
      'success-inverse': [
        'border-success-400',
        'hover:border-success-200',
        'disabled:border-success-400',
      ],
      'error-regular': [
        'border-error-200',
        'hover:border-error-600',
        'disabled:border-error-200',
      ],
      'error-inverse': [
        'border-error-400',
        'hover:border-error-200',
        'disabled:border-error-400',
      ],
      'neutral-regular': [
        'border-neutral-400',
        'hover:border-neutral-600',
        'disabled:border-neutral-200',
      ],
      'neutral-inverse': [
        'border-neutral-400',
        'hover:border-neutral-200',
        'disabled:border-neutral-600',
      ],
    },
  }

  return pathOr(borderColorMap, [variant, color], []).join(' ')
}

export const getTextColor = (
  variant: ButtonVariant,
  color: ColorCombination,
  state: ButtonState
): string => {
  const textColorMap: Record<
    ButtonVariant,
    Record<ColorCombination, Array<TailwindEquityColor>>
  > = {
    primary: {
      'primary-regular': ['text-primary-600-c'],
      'primary-inverse': ['text-primary-600', 'disabled:text-primary-300'],
      'success-regular': ['text-success-600-c'],
      'success-inverse': ['text-success-600', 'disabled:text-success-300'],
      'error-regular': ['text-error-600-c'],
      'error-inverse': ['text-error-600', 'disabled:text-error-300'],
      'neutral-regular': ['text-neutral-0'],
      'neutral-inverse': ['text-neutral-900', 'disabled:text-neutral-500'],
    },
    secondary: {
      'primary-regular': ['text-neutral-900', 'disabled:text-neutral-400'],
      'primary-inverse': ['text-primary-600-c', 'disabled:text-primary-300'],
      'success-regular': ['text-success-600', 'disabled:text-success-300'],
      'success-inverse': ['text-success-600-c', 'disabled:text-success-300'],
      'error-regular': ['text-error-600', 'disabled:text-error-300'],
      'error-inverse': ['text-error-600-c', 'disabled:text-error-300'],
      'neutral-regular': ['text-neutral-900', 'disabled:text-neutral-400'],
      'neutral-inverse': ['text-neutral-900-c', 'disabled:text-neutral-500'],
    },
    tertiary: {
      'primary-regular': [
        'text-primary-600',
        'hover:text-primary-700',
        'disabled:text-primary-300',
      ],
      'primary-inverse': [
        'text-primary-600-c',
        'hover:text-primary-200',
        'disabled:text-primary-300',
      ],
      'success-regular': [
        'text-success-600',
        'hover:text-success-700',
        'disabled:text-success-300',
      ],
      'success-inverse': [
        'text-success-600-c',
        'hover:text-success-200',
        'disabled:text-success-300',
      ],
      'error-regular': [
        'text-error-600',
        'hover:text-error-700',
        'disabled:text-error-300',
      ],
      'error-inverse': [
        'text-error-600-c',
        'hover:text-error-200',
        'disabled:text-error-300',
      ],
      'neutral-regular': [
        'text-neutral-600',
        'hover:text-neutral-700',
        'disabled:text-neutral-300',
      ],
      'neutral-inverse': [
        'text-neutral-0',
        'hover:text-neutral-300',
        'disabled:text-neutral-500',
      ],
    },
  }

  return textColorMap[variant][color].join(' ')
}

export const getBackgroundColor = (
  variant: ButtonVariant,
  color: ColorCombination
): string => {
  const backgroundColorMap: Partial<
    Record<ButtonVariant, Record<ColorCombination, Array<TailwindEquityColor>>>
  > = {
    primary: {
      'primary-regular': [
        'bg-primary-600',
        'hover:bg-primary-700',
        'disabled:bg-primary-300',
      ],
      'primary-inverse': [
        'bg-primary-600-c',
        'hover:bg-primary-200',
        'disabled:bg-primary-500',
      ],
      'success-regular': [
        'bg-success-600',
        'hover:bg-success-700',
        'disabled:bg-success-300',
      ],
      'success-inverse': [
        'bg-success-600-c',
        'hover:bg-success-200',
        'disabled:bg-success-500',
      ],
      'error-regular': [
        'bg-error-600',
        'hover:bg-error-700',
        'disabled:bg-error-300',
      ],
      'error-inverse': [
        'bg-error-600-c',
        'hover:bg-error-200',
        'disabled:bg-error-500',
      ],
      'neutral-regular': [
        'bg-neutral-600',
        'hover:bg-neutral-700',
        'disabled:bg-neutral-300',
      ],
      'neutral-inverse': [
        'bg-neutral-0',
        'hover:bg-neutral-300',
        'disabled:bg-neutral-600',
      ],
    },
    secondary: {
      'primary-regular': ['bg-primary-600-c'],
      'primary-inverse': ['bg-primary-600'],
      'success-regular': ['bg-success-600-c'],
      'success-inverse': ['bg-success-600'],
      'error-regular': ['bg-error-600-c'],
      'error-inverse': ['bg-error-600'],
      'neutral-regular': ['bg-neutral-900-c'],
      'neutral-inverse': ['bg-neutral-900'],
    },
  }

  return pathOr(backgroundColorMap, [variant, color], []).join(' ')
}

export const getBorderSize = (variant: ButtonVariant) =>
  variant === 'primary' || variant === 'secondary' ? 'border border-solid' : ''

export const getFontSize = (size: ButtonSize) =>
  size === 'small' ? 'small-strong' : 'body-strong'

export const getCursor = (state: ButtonState) =>
  state !== 'default' ? 'cursor-not-allowed' : ''

export const getHeight = (size: ButtonSize) => {
  const sizeHeightMap: Record<ButtonSize, string> = {
    regular: 'h-[48px]',
    medium: 'h-[40px]',
    small: 'h-32',
  }
  return sizeHeightMap[size]
}

export const getPadding = (size: ButtonSize, variant: ButtonVariant) => {
  const sizePaddingMap: Record<ButtonSize, string> = {
    regular: 'px-24',
    medium: 'px-16',
    small: 'px-16',
  }

  return variant === 'tertiary' ? '' : sizePaddingMap[size]
}

export const getOutlineColor = (color: ColorCombination) => {
  const outlineColorMap: Record<
    ColorCombination,
    Array<TailwindEquityColor>
  > = {
    'primary-regular': ['focus-visible:outline-primary-600'],
    'primary-inverse': ['focus-visible:outline-primary-600-c'],
    'success-regular': ['focus-visible:outline-success-600'],
    'success-inverse': ['focus-visible:outline-success-600-c'],
    'error-regular': ['focus-visible:outline-error-600'],
    'error-inverse': ['focus-visible:outline-error-600-c'],
    'neutral-regular': ['focus-visible:outline-neutral-900'],
    'neutral-inverse': ['focus-visible:outline-neutral-900-c'],
  }

  return outlineColorMap[color].join(' ')
}
