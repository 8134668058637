import { useMediaQuery, useTheme } from '@mui/material'
import { useLayoutEffect, useRef, useState } from 'react'

export const useLightbox = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const lightboxRef = useRef<HTMLDialogElement>(null)

  const [openLightBox, setOpenLightBox] = useState(false)
  const loadingState = useState(true)
  const [, setLoading] = loadingState
  const openLightbox = () => {
    // Prevent lightbox state from changing on mobile
    if (isMobile) return
    const lightbox = document.getElementById('expanded-lightbox')
    // prevent multiple lightboxes from opening at same time
    if (!lightbox) {
      setOpenLightBox(true)
      const body = document.querySelector('body')
      if (body) {
        body.style.overflow = 'hidden'
      }
    }
  }
  const closeLightbox = () => {
    // Prevent lightbox state from changing on mobile
    if (isMobile) return
    const body = document.querySelector('body')
    if (body) {
      body.style.overflow = 'auto'
    }
    setOpenLightBox(false)
    setLoading(true)
  }

  useLayoutEffect(() => {
    if (isMobile) {
      const body = document.querySelector('body')
      if (body) {
        body.style.overflow = 'auto'
      }
      if (openLightBox) {
        setOpenLightBox(false)
      }
    }
  }, [isMobile, openLightBox])

  return {
    isOpen: openLightBox,
    openLightbox,
    closeLightbox,
    lightboxRef,
    loadingState,
  }
}
