import type { BreadcrumbItem } from '../../../../../equity/web/atoms/breadcrumbs'
import { Breadcrumbs } from '../../../../../equity/web/atoms/breadcrumbs'

import { defaultConfig } from './config'
import type { Config } from './config'

type ContentfulBreadcrumbProps = {
  category: string
  currentPage: string
  baseUrl?: string
  config?: Config
  levelOneOnly?: boolean
}

export function ContentfulBreadcrumbs(props: ContentfulBreadcrumbProps) {
  const crumbs = makeBreadcrumbs(props)
  return crumbs ? (
    <div className="border-b-1 border-b-neutral-200  px-24 py-24 md:px-32 lg:px-56">
      <Breadcrumbs items={crumbs} />
    </div>
  ) : null
}

export function makeBreadcrumbs(
  props: ContentfulBreadcrumbProps
): BreadcrumbItem[] | undefined {
  const {
    baseUrl,
    category,
    config = defaultConfig,
    currentPage,
    levelOneOnly = false,
  } = props || {}

  if (levelOneOnly) {
    return [
      { label: 'Home', path: baseUrl || '/' },
      { label: currentPage, path: '' },
    ]
  }

  const crumbs = config[category]

  if (!crumbs) {
    return
  }

  return [
    { label: 'Home', path: baseUrl || '/' },
    ...(baseUrl ? prefixUrl(crumbs, baseUrl) : crumbs),
    { label: currentPage, path: '' },
  ]
}

const prefixUrl = (crumbs: BreadcrumbItem[], url: string) =>
  crumbs.map((crumb) => ({
    ...crumb,
    path: `${url}${crumb.path}`,
  }))
