import React from 'react'
import { useLocation } from 'react-router-dom'

import { LOGOUT_ACTION } from '../../../../ui/main-nav'
import {
  useGetAnnouncementsQuery,
  type AnnouncementFragment,
  type GetAnnouncementsQueryVariables,
} from '../../../../util/graphql/mfe-contentful'
import { useUserProfile } from '../../../../util/hook/use-user-profile'
import { today } from '../../../../util/time/date-time-contentful'
import { useGetAllowedContentPermissions } from '../../api'

import { DATE_FORMAT, announcementsService } from './announcements-service'
import {
  isValidUrl,
  prepareAnnouncementProps,
  selectAnnouncements,
} from './helpers'

// TODO: When we move to BFF copy over code to here and remove other hook
export const useGetAnnouncements = (
  variables: GetAnnouncementsQueryVariables,
  options?: {
    allowSingleDismissalPerSession?: boolean
    appUrl?: string
  }
) => {
  const { userProfileState } = useUserProfile()
  const { userProfile } = userProfileState
  const { allowSingleDismissalPerSession = false, appUrl = '/homepage' } =
    options || {}
  const [announcement, setAnnouncement] =
    React.useState<AnnouncementFragment | null>(null)

  const allowedUserPermissions = useGetAllowedContentPermissions()

  const location = useLocation()
  const announcements = useGetAnnouncementsQuery(
    {
      id: variables.id,
      date: variables.date || today(DATE_FORMAT),
    },
    {
      refetchOnWindowFocus: false,
      select: (data) => selectAnnouncements(data),
      // prevents default announcement from being shown while permissions are loading or announcements have been dismissed during the session
      enabled:
        !allowedUserPermissions.isLoading &&
        !announcementsService.hasDismissedThisSession,
    }
  )

  const getNextAnnouncement = React.useCallback(
    () =>
      isValidUrl(location.pathname, announcements.data?.excludedUrls) &&
      !announcements.isLoading &&
      !announcementsService.hasDismissedThisSession
        ? announcementsService.announcement
        : null,
    [
      announcements.data?.excludedUrls,
      announcements.isLoading,
      location.pathname,
    ]
  )

  React.useEffect(() => {
    const handleLoggingOut = () => {
      if (announcementsService.hasDismissedThisSession) {
        announcementsService.clearHasDismissed()
      }
    }
    document.addEventListener(LOGOUT_ACTION, handleLoggingOut)
    if (announcements.data) {
      announcementsService.initiateAnnouncements(
        announcements.data,
        userProfile?.id,
        allowedUserPermissions.data
      )

      setAnnouncement(announcementsService.announcement)
    }
    return () => {
      document.removeEventListener(LOGOUT_ACTION, handleLoggingOut)
    }
  }, [
    allowedUserPermissions.data,
    announcements.data,
    getNextAnnouncement,
    location.pathname,
    userProfile?.id,
  ])

  const dismissAnnouncement = () => {
    if (announcement) {
      announcementsService.setAnnouncementsDismissed({
        announcement: {
          [announcement.sys.id]: {
            dismissed: true,
            dateTimeEnd: announcement.dateTimeEnd,
          },
        },
        allowSingleDismissalPerSession,
      })
      setAnnouncement(announcementsService.announcement)
    }
  }

  return {
    announcement: announcement
      ? prepareAnnouncementProps({
          appUrl,
          announcement,
          dismissAnnouncement: !userProfile?.isSwitched
            ? dismissAnnouncement
            : () => {},
          isSwitched: Boolean(userProfile?.isSwitched),
        })
      : null,
  }
}
