export const markdownToEquityMap = {
  overrides: {
    h1: {
      props: {
        className: 'headline-xxl',
        id: null,
      },
    },
    h2: {
      props: {
        className: 'headline-xl',
        id: null,
      },
    },
    h3: {
      props: {
        className: 'headline-l',
        id: null,
      },
    },
    h4: {
      props: {
        className: 'headline-m',
        id: null,
      },
    },
    h5: {
      props: {
        className: 'headline-s',
        id: null,
      },
    },
    h6: {
      props: {
        className: 'headline-xs',
        id: null,
      },
    },
    p: {
      props: {
        className: 'body',
      },
    },
    small: {
      props: {
        className: 'small',
      },
    },
    b: {
      props: {
        className: 'body-strong',
      },
    },
    strong: {
      props: {
        className: 'body-strong',
      },
    },
    i: {
      props: {
        className: 'italic',
      },
    },
    em: {
      props: {
        className: 'italic',
      },
    },
    ul: {
      props: {
        className: 'list-inside list-disc ml-16 body',
      },
    },
    ol: {
      props: {
        className: 'list-inside list-decimal ml-16 body',
      },
    },
  },
}
