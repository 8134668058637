import { Components } from '@mui/material'

import { MuiAppBar } from './MuiAppBar'
import { MuiButton } from './MuiButton'
import { MuiButtonGroup } from './MuiButtonGroup'
import { MuiCard } from './MuiCard'
import { MuiCheckbox } from './MuiCheckbox'
import { MuiChip } from './MuiChip'
import { MuiDivider } from './MuiDivider'
import { MuiFormLabel } from './MuiFormLabel'
import { MuiFormControlLabel } from './MuiFormLabelControl'
import { MuiInputLabel } from './MuiInputLabel'
import { MuiMenu } from './MuiMenu'
import { MuiMenuItem } from './MuiMenuItem'
import { MuiOutlinedInput } from './MuiOutlinedInput'
import { MuiPaper } from './MuiPaper'
import { MuiPopover } from './MuiPopover'
import { MuiRadio } from './MuiRadio'
import { MuiSelect } from './MuiSelect'
import { MuiSkeleton } from './MuiSkeleton'
import { MuiSwitch } from './MuiSwitch'
import { MuiTextField } from './MuiTextField'
import { MuiTooltip } from './MuiTooltip'

export * from './MuiCard'

export const components: Components = {
  MuiAppBar,
  MuiButton,
  MuiButtonGroup,
  MuiCard,
  MuiChip,
  MuiCheckbox,
  MuiDivider,
  MuiFormLabel,
  MuiFormControlLabel,
  MuiInputLabel,
  MuiMenu,
  MuiMenuItem,
  MuiPaper,
  MuiPopover,
  MuiOutlinedInput,
  MuiRadio,
  MuiSelect,
  MuiSkeleton,
  MuiSwitch,
  MuiTooltip,
  MuiTextField,
}
