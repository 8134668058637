import type { EnabledAccessControls, Feature } from './types'

// TODO import this from a shared library
type UserProfileFeatureV1Status =
  (typeof UserProfileFeatureV1Status)[keyof typeof UserProfileFeatureV1Status]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileFeatureV1Status = {
  ON: 'ON',
  OFF: 'OFF',
} as const

export interface UserProfileFeatureV1 {
  name: string
  status: UserProfileFeatureV1Status
}

export type Features = UserProfileFeatureV1[]

export const getUserEnabledFeatures = (
  features: Features = []
): EnabledAccessControls['enabledFeatures'] => {
  return (
    features.reduce<Feature[]>((acc, feature) => {
      if (feature.status === 'ON') {
        return [...acc, feature.name] as Feature[]
      }
      return acc
    }, []) || []
  )
}
