import styled from '@emotion/styled'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'

import { CaptionRegular } from '@cais-group/approved/ui/typography'
import { colors } from '@cais-group/equity/particles/colors'
import { spacing } from '@cais-group/equity/particles/spacing'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'

import { HandleCloseTabType } from '../shared-ui-contentful-preview-banner'

const PreviewTabContainer = styled.div<{ hasHover?: boolean }>`
  position: relative;
  top: calc(
    ${spacing.s4} * -1
  ); /* This negates the border size to make the eye happy with vertical align */
  display: flex;

  background-color: ${colors['eq-color-primary-600']};
  color: white;
  flex-grow: 0;
  align-items: center;
  padding: 0 ${spacing.s16};
  border-bottom-left-radius: ${spacing.s8};
  border-bottom-right-radius: ${spacing.s8};
  height: ${spacing.s24};
  ${({ hasHover }) =>
    hasHover
      ? `cursor: pointer; &:hover {
  background-color: ${colors['eq-color-primary-700']}
}`
      : 'cursor: auto'}
  & > button > svg {
    fill: white;
  }
`

type PreviewTabType = {
  caption: string
  label: string
  show: boolean
  hasModal?: boolean
}

export const PreviewTab = ({
  onExit,
  label,
  caption,
  show,
  onClickDeleteSwitch,
}: Pick<PreviewTabType, 'caption' | 'label' | 'show'> & {
  onExit(): void
  onClickDeleteSwitch?(): void
}) => {
  if (!show) {
    return null
  }

  return (
    <PreviewTabContainer hasHover={Boolean(onClickDeleteSwitch)}>
      <CaptionRegular onClick={onClickDeleteSwitch}>{caption}</CaptionRegular>
      <IconButton aria-label={label} onClick={onExit} size="small">
        <CloseIcon style={{ width: '16px', color: 'white' }} />
      </IconButton>
    </PreviewTabContainer>
  )
}

export const PreviewTabs = ({
  onHandleCloseTab,
  showDeleteSwitchTab,
  isFirmExperience,
}: {
  onHandleCloseTab: HandleCloseTabType
  showDeleteSwitchTab?: boolean
  isFirmExperience?: boolean
}) => {
  const previewTabs = [
    {
      caption: 'Previewing Contentful Content',
      label: 'Exit Preview Mode',
      enabled: previewService.enabled,
    },
    {
      caption: showDeleteSwitchTab
        ? 'Delete switch'
        : 'Switch Course Experience',
      label: 'Exit Preview Course Mode',
      // determines whether the tabs are active or inactive, based on certain conditions that must be met including if the experience has been created yet at firm level.
      enabled: Boolean(
        previewService.enabledFirm &&
          previewService.enabledFirm !== 'caisiqdirect' &&
          isFirmExperience
      ),
      hasModal: true,
    },
  ]

  const { openModal, onExit } = onHandleCloseTab

  return (
    <div className="column flex gap-x-4">
      {previewTabs.map(({ label, caption, enabled, hasModal }) => {
        return (
          <PreviewTab
            key={label}
            label={label}
            caption={caption}
            onExit={!showDeleteSwitchTab ? onExit : (openModal as () => void)}
            show={Boolean(enabled)}
            onClickDeleteSwitch={hasModal ? openModal : undefined}
          />
        )
      })}
    </div>
  )
}
