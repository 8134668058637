import { Components } from '@mui/material'

export const MuiMenu: Components['MuiMenu'] = {
  styleOverrides: {
    root: {
      borderRadius: 0,
    },
    list: {
      '& > .MuiButton-root': {
        display: 'block',
        borderRadius: 0,
        border: 'none',
        padding: 'var(--s4) var(--s8)',
      },
    },
  },
}
