import { ReactNode } from 'react'

import { AutoGrid, Carousel } from '@cais-group/shared/ui/contentful/components'

import { Skeleton, SkeletonAnimate } from '../../skeleton/skeleton'

export const SkeletonCard = () => (
  <SkeletonAnimate className="relative h-[440px] w-fit min-w-[var(--min-width)] bg-neutral-100">
    <Skeleton height={180} variant="rectangular" />
    <div className="flex flex-col gap-16 px-24 py-16">
      <Skeleton height={24} variant="rectangular" />
      <Skeleton width={100} height={24} variant="rectangular" />
    </div>
    <div className="flex flex-col gap-16 px-24">
      <Skeleton height={16} variant="rectangular" />
      <Skeleton height={16} variant="rectangular" />
      <Skeleton height={16} variant="rectangular" />
    </div>
  </SkeletonAnimate>
)

export const SkeletonCardList = ({
  testId,
  skeletonControls,
}: {
  testId?: string
  skeletonControls?: ReactNode
}) => (
  <>
    {skeletonControls}
    <Carousel
      items={Array.from({ length: 4 }) as []}
      itemMinWidth="medium"
      minHeight="400px"
      renderItem={() => {
        return <SkeletonCard />
      }}
      testId={`${testId}-skeleton`}
    />
  </>
)

export const SkeletonGrid = ({
  testId,
  count = 12,
  skeletonControls,
}: {
  testId?: string
  count?: number
  skeletonControls?: ReactNode
}) => (
  <>
    {skeletonControls}
    <AutoGrid data-test={`skeleton-grid-${testId}`}>
      {Array.from({ length: count }).map((_, i) => (
        <SkeletonCard key={i} />
      ))}
    </AutoGrid>
  </>
)
