import { useMatches } from 'react-router-dom'

import { mapTrackingKeysToDataAttributes } from '../../../../equity/utils/tracking-utils'

import { type TrackingProps } from './types'

/**
 * Accumulates HTML attributes using React Router's Route `handle` property.
 * See: https://reactrouter.com/en/6.23.1/hooks/use-matches
 *
 * Usage:
 *
 * - Specify `handle.tracking = { section: "My Section" } for Routes
 * - Specify sub-sections by adding `handle.tracking = { sub_section: "My Sub-section" }` to sub-routes.
 *
 * `useRouterTrackingAttributes` will accumulate tracking attributes and return an object:
 *
 * {
 *   "data-track-section": "My Section",
 *   "data-track-sub_section": "My Sub-section"
 * }
 *
 * Attach these attributes to a root DOM node.
 *
 * Any non-mergeable tracking attributes between a trackable element and the root will take precedence.
 *
 * Consider the following HTML:
 *
 * <main id="root" data-track-section="My Section" data-track-sub_section="Default Sub-section">
 *   <a href="anchors.html" data-track="true">Anchor</a>
 *   <nav data-track-sub_section="Navigation">
 *     <ol>
 *       <li><a href="index.html" data-track="true">Nav Link</a></li>
 *     </ol>
 *   </nav>
 *   <section data-track-sub_section="Strange Happenings">
 *     <h2>Strange Happenings</h2>
 *     <a href="..." data-track="true">Find out more</a>
 *   </section>
 * </main>
 *
 * When "Anchor" is clicked the tracking will be: { section: "My Section", sub_section: "Default Sub-section" }
 * When "Nav Link" is clicked the tracking will be: { section: "My Section", sub_section: "Navigation" }
 * When "Find out more" is clicked the tracking will be: { section: "My Section", sub_section: "Strange Happenings" }
 */
export function useRouterTrackingAttributes() {
  const matches = useMatches() as Array<{
    handle: { tracking?: TrackingProps }
  }>

  const mergedTrackingProps: TrackingProps = matches.reduce(
    (acc, match) => Object.assign(acc, match?.handle?.tracking ?? null),
    {}
  )

  return mapTrackingKeysToDataAttributes(mergedTrackingProps)
}
