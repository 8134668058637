import { useAuth0 } from '@auth0/auth0-react'
import axios from 'axios'
import { useCallback, useEffect, useState } from 'react'

import { Modal, useModal } from '../../../../equity/web/organisms/modal'
import { authService } from '../../../util/auth-service'
import { logWarning, logAction } from '../../../util/logging'
import { getEnvConfig } from '../../env'
import { LOGOUT_ACTION } from '../../main-nav'

export const minutes = (n = 1) => n * 1000 * 60
// Show a modal when there is less than 60 seconds from the session timeout
const SHOW_MODAL_THRESHOLD = minutes(1)
const DEFAULT_SESSION_DURATION_TIME = minutes(15)
const DEVELOPMENT_SESSION_DURATION_TIME = 8 * minutes(60) // 8 hours
const TIMESTAMP_CHECK_INTERVAL = 1000

const getRemainingTime = (sessionDurationTime: number) => {
  const currentTimestamp = new Date().getTime()
  const sessionStartTime =
    authService.getSessionStartTime() || Number.POSITIVE_INFINITY

  return sessionStartTime + sessionDurationTime - currentTimestamp
}

const extendPortalSession = async () => {
  const config = getEnvConfig()
  if (!config?.PORTAL_URL || config?.ENVIRONMENT === 'localhost') {
    return
  }

  axios
    .get(`${config.PORTAL_URL}/sessionManagement`, {
      maxRedirects: 0,
    })
    .catch(console.error)
}

const handleExtendSession = async () => {
  await authService.getAccessTokenSilently({ cacheMode: 'off' })
  authService.resetSessionStart()
  await extendPortalSession()
}

let lastUserActivityTime: number | undefined

const handleVideoPlaying = () => {
  lastUserActivityTime = new Date().getTime()
}

// debounce to every minute since these events come through several times per second
setInterval(() => {
  const currentTime = new Date().getTime()
  if (
    lastUserActivityTime !== undefined &&
    currentTime - lastUserActivityTime < minutes(1)
  ) {
    handleExtendSession().catch((e) => {
      let message = ''
      if (typeof lastUserActivityTime !== 'number') {
        message = `Failed to auto extend session, cannot calculate time left because lastUserActivityTime is not a number, got value: ${lastUserActivityTime}`
      } else {
        message = `Failed to auto extend session with ${
          currentTime - lastUserActivityTime
        } time left`
      }
      logWarning({
        error: e,
        message,
      })
      console.warn(message, e)
    })
  }
}, minutes(1))

document.addEventListener('VIDEO_PLAYING', handleVideoPlaying)

const sessionExtendingEvents = ['pointerdown', 'pointermove']
sessionExtendingEvents.forEach((event) => {
  document.addEventListener(event, () => {
    lastUserActivityTime = new Date().getTime()
  })
})

export const SessionModal = () => {
  const [timeRemaining, setTimeRemaining] = useState(Number.POSITIVE_INFINITY)
  const [isActive, setIsActive] = useState(true)

  const { logout: auth0Logout, isAuthenticated } = useAuth0()

  const modal = useModal()

  const handleLogout = useCallback(() => {
    const appBasePath = window.location.pathname.split('/')[1]

    if (!authService.getIsValidSession()) {
      logAction({
        message: 'Session expired, though user was already logged out',
        context: { appBasePath },
      })
      return
    }
    authService.invalidateSession()

    document.dispatchEvent(new Event(LOGOUT_ACTION))
    const returnTo =
      __NX_DEVELOPMENT__ && !__NX_USE_AUTH_REDIRECT__
        ? `${window.location.origin}/${appBasePath}`
        : `${window.location.origin}/auth-redirect?app=${appBasePath}`

    logAction({
      message: `User signed out by session modal`,
      context: { returnTo },
    })
    auth0Logout({
      logoutParams: {
        returnTo,
      },
    })
  }, [auth0Logout])

  useEffect(() => {
    const config = getEnvConfig()
    const sessionDurationTime = ['localhost', 'dev'].includes(
      config?.ENVIRONMENT
    )
      ? DEVELOPMENT_SESSION_DURATION_TIME
      : config?.SESSION_DURATION_TIME || DEFAULT_SESSION_DURATION_TIME

    const interval: NodeJS.Timeout = setInterval(() => {
      const remainingTime = getRemainingTime(sessionDurationTime)

      setTimeRemaining(remainingTime)
    }, TIMESTAMP_CHECK_INTERVAL)

    return () => {
      if (interval) clearInterval(interval)
    }
  }, [])

  const timeInSeconds = Math.round(timeRemaining / 1000)
  const sessionTimeInMinutes = Math.round(DEFAULT_SESSION_DURATION_TIME / 60000)

  useEffect(() => {
    if (!isActive || !isAuthenticated) {
      return
    }
    const shouldOpenModal =
      isAuthenticated && timeRemaining < SHOW_MODAL_THRESHOLD

    try {
      if (!shouldOpenModal && modal.isOpen) {
        modal.closeModal()
      }

      if (shouldOpenModal && !modal.isOpen) {
        modal.openModal()
        return
      }

      if (timeRemaining < 0 && modal.isOpen) {
        modal.closeModal()
        setIsActive(false)
      }
    } catch (e) {
      return
    }
  }, [isAuthenticated, modal, isActive, timeRemaining])

  // Logout the user when the time's up. Clear the localStorage value so we don't get into the loop
  useEffect(() => {
    if (timeInSeconds <= 0) {
      logAction({
        message: 'Logging user out due to inactivity',
      })
      handleLogout()
    }
  }, [timeInSeconds, handleLogout])

  return (
    <Modal
      control={modal}
      actions={[
        {
          onClick: handleLogout,
          children: 'Log out',
          variant: 'secondary',
        },
        {
          onClick: handleExtendSession,
          children: 'Stay logged in',
          variant: 'primary',
        },
      ]}
      title={`Your session will end in ${timeInSeconds} second${
        timeInSeconds !== 1 ? 's' : ''
      }`}
      isDismissible={false}
    >{`For your security, sessions automatically end after ${sessionTimeInMinutes} minutes of inactivity unless you choose to stay logged in.`}</Modal>
  )
}
