import cx from 'classnames'

import { TailwindEquityColor } from '../../../../particles/colors'

const highlightTextColorMap: Record<HighlightType, TailwindEquityColor[]> = {
  info: ['text-primary-600', 'bg-primary-100'],
  success: ['text-success-700', 'bg-success-100'],
  error: ['text-error-700', 'bg-error-100'],
  warning: ['text-warning-800', 'bg-warning-100'],
}

/**
 * Represents a segment of text with a mark flag indicating whether it should be highlighted.
 */
interface TextSegment {
  text: string
  highlight: boolean
}

/**
 * Finds all instances of a string within another string and returns an Fragment of elements
 * representing the rendered segments of the string.
 * Highlighted segments are wrapped in a mark element with the appropriate classes based on the type.
 *
 * @param inputText - The input string to search within.
 * @param textToHighlight - The string to search for within the input string.
 * @param type - The type of highlight to apply.
 * @returns An array of TextSegment objects representing the segments of the string.
 */
function HighlightedText({
  textToHighlight,
  inputText,
  type = 'info',
}: HighlighterProps): string | JSX.Element {
  if (!textToHighlight) return inputText

  const regex =
    textToHighlight instanceof RegExp
      ? textToHighlight
      : new RegExp(textToHighlight, 'gi')
  const matches = Array.from(inputText.matchAll(regex))

  if (matches.length === 0) return inputText

  const segments: TextSegment[] = []
  let lastIndex = 0

  for (const match of matches) {
    const [fullMatch] = match
    const startIndex = match.index

    if (startIndex !== undefined) {
      // startIndex is defined, add non-matching segment before the current match
      if (startIndex > lastIndex) {
        segments.push({
          text: inputText.slice(lastIndex, startIndex),
          highlight: false,
        })
      }

      // Add the matching segment
      segments.push({ text: fullMatch, highlight: true })
      lastIndex = startIndex + fullMatch.length
    }
  }

  if (lastIndex < inputText.length) {
    // Add remaining text after the last match as a non-matching segment
    segments.push({ text: inputText.slice(lastIndex), highlight: false })
  }

  return (
    <>
      {segments.map(({ text, highlight }, index) =>
        highlight ? (
          <mark
            key={index}
            className={cx(highlightTextColorMap[type].join(' '))}
          >
            {text}
          </mark>
        ) : (
          <span key={index}>{text}</span>
        )
      )}
    </>
  )
}

export type HighlightType = 'error' | 'success' | 'warning' | 'info'

export type HighlighterProps = {
  /** The text to highlight within the input text, we can also use Regular Expressions */
  textToHighlight: string | RegExp | null | undefined
  /** The text to be display and checked */
  inputText: string
  /** The semantic color to use */
  type?: HighlightType
}

/**
 * The Highlighter component is a designed to highlight a specific substring within an input string.
 * It offers a straightforward way to emphasize a particular part of the text,
 * making it useful for various applications such as search result highlighting, text highlighting in forms, and more.
 */
export const Highlighter = ({
  textToHighlight,
  inputText,
  type = 'info',
}: HighlighterProps) => (
  <span>
    <HighlightedText
      textToHighlight={textToHighlight}
      inputText={inputText}
      type={type}
    />
  </span>
)

export const __TEST__ = {
  HighlightedText,
}
