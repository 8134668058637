import styled from '@emotion/styled'
import { forwardRef, ReactNode } from 'react'
import { omit } from 'remeda'

import { uiTypeSize } from '../../../type-size'

export type CoverProps = {
  centered?: string // A selector for which element you want centered
  space?: uiTypeSize // Min space around child elements
  minHeight?: string // Min height for the Cover
  noPad?: boolean // Whether the spacing is also applied as padding to the container element
  children: ReactNode
  className?: string
}

export const Cover = styled(
  forwardRef<HTMLDivElement, CoverProps>((props, ref) => {
    const passedProps = omit(props, [
      'centered',
      'space',
      'minHeight',
      'noPad',
      'className',
      'children',
    ])
    return (
      <div
        ref={ref}
        className={props.className}
        data-layout="Cover"
        {...passedProps}
      >
        {props.children}
      </div>
    )
  })
)(
  ({
    centered = 'h1',
    space = 'var(--s24)',
    minHeight = '100vh',
    noPad = false,
  }) => {
    return `
      display: flex;
      flex-direction: column;
      min-height: ${minHeight};
      padding: ${!noPad ? space : '0'};

      & > * {
        margin-top: ${space};
        margin-bottom: ${space};
      }

      & > :first-of-type:not(${centered}) {
        margin-top: 0;
      }

      & > :last-child:not(${centered}) {
        margin-bottom: 0;
      }

      & > ${centered} {
        margin-top: auto;
        margin-bottom: auto;
      }
    `
  }
)

Cover.displayName = 'Cover'
