import styled from '@emotion/styled'
import { forwardRef, ReactNode } from 'react'
import { omit } from 'remeda'

import { uiTypeSize } from '../../../type-size'

export type LayoutImposterProps = {
  breakout?: boolean
  margin?: uiTypeSize
  fixed?: boolean
  children?: ReactNode
  className?: string
}

/**
 * This places its children in the center of its container, where they are sized intrinsically
 * Useful for modals and dialogs
 *
 * @breakout Whether the element is allowed to break out of the container over which it is positioned
 * @margin The minimum space between the element and the inside edges of the positioning container over which it is placed (where is not applied)
 * @fixed Whether to position the element relative to the viewport
 */
export const LayoutImposter = styled(
  forwardRef<HTMLDivElement, LayoutImposterProps>((props, ref) => {
    const passedProps = omit(props, [
      'breakout',
      'margin',
      'fixed',
      'className',
      'children',
    ])
    return (
      <div
        ref={ref}
        className={props.className}
        data-layout="LayoutImposter"
        {...passedProps}
      >
        {props.children}
      </div>
    )
  })
)(({ breakout = false, margin = 'var(--s24)', fixed = false }) => {
  const marginFixed = margin === '0' ? '0px' : margin // for use with calc
  return `
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      ${
        !breakout
          ? `
        max-width: calc(100% - (${marginFixed} * 2));
        max-height: calc(100% - (${marginFixed} * 2));
        overflow: auto;`
          : ''
      }
      ${
        fixed
          ? `
        position: fixed;`
          : ''
      }
    `
})
LayoutImposter.displayName = 'LayoutImposter'
