/* WARNING: DO NOT EDIT */
/* THESE VALUES ARE FROM STYLE GUIDE - DO NOT ADD OR REMOVE UNLESS THERE ARE AMENDMENTS THERE */

/* REFERENCE: https://www.figma.com/file/2tZq7RGKNp3o8r9hiIFpxn/Grids-and-spacing?node-id=523%3A177 */
/* We choose 481, 769 and 1025 as we want 480, 768, 1024 to be inclusive upper bounds: https://app.shortcut.com/cais/story/19151/ui-adjust-breakpoints */
export const BREAKPOINTS = {
  breakpointSm: '30.0625rem' /* 481px */,
  breakpointMd: '48.0625rem' /* 769px */,
  breakpointLg: '64.0625rem' /* 1025px */,
  breakpointXl: '103.75rem' /* 1660px */,
}

export const DRAWER_BREAKPOINTS = {
  small: 500,
  medium: 1880,
  large: 1920,
}

/* REFERENCE: https://www.figma.com/file/1q7uzq285UmqkNTRXWpye9/Color?node-id=617%3A3 */
const Mariner = '#313B97'
const Cornflower = '#8CADE9'
const Peacock = '#328883'
const Pistachio = '#96DBC8'
const Marigold = '#FFC170'
const TerraCotta = '#BB530A'
const Wine = '#87394D'
const Ube = '#C0BBF7'
const Mako = '#595C5C'
const Aegean = '#3958a1'
const Birch = '#f4f0db'
const ButtonHover = '#002C55'
const Grass = '#a2cd7f'
const Harissa = '#c47774'
const Heather = '#6f80ba'
const Moss = '#d1e0a5'
const Oyster = '#cdd1d8'
const Periwinkle = '#dadeff'
const PowderBlue = '#cee3fc'
const Rosewood = '#925364'
const Sienna = '#e38852'
const Toast = '#f3d7bc'

// REFERENCE: https://www.figma.com/file/1q7uzq285UmqkNTRXWpye9/Color?node-id=617%3A3
export const DATA_VISUALIZATION_PALETTE = [
  Mariner,
  Cornflower,
  Peacock,
  Pistachio,
  Marigold,
  TerraCotta,
  Wine,
  Ube,
  Mako,
  Heather,
  PowderBlue,
  Grass,
  Moss,
  Toast,
  Sienna,
  Rosewood,
  Periwinkle,
  Oyster,
  ButtonHover,
  Aegean,
  Birch,
  Harissa,
]

export const DATA_VISUALIZATION_COLORS = {
  Aegean,
  Birch,
  ButtonHover,
  Cornflower,
  Grass,
  Harissa,
  Heather,
  Mako,
  Marigold,
  Mariner,
  Moss,
  Oyster,
  Peacock,
  Periwinkle,
  Pistachio,
  PowderBlue,
  Rosewood,
  Sienna,
  TerraCotta,
  Toast,
  Ube,
  Wine,
}
