import cx from 'classnames'
import type { PropsWithChildren } from 'react'
import { useState } from 'react'

import {
  Tracking,
  mapTrackingKeysToDataAttributes,
} from '../../../../utils/tracking-utils'
import { Icon } from '../../../atoms/icon'
import { InfoTag } from '../../../atoms/info-tag'
import { LoadingIndicator } from '../../../atoms/loading-indicator/index'

export type FileDownloadProps = Tracking & {
  /** The URL of the file to be downloaded when the component is clicked. */
  source: string
  /** Text to display as the title of the file to be downloaded. */
  title: string
  /** Optional: file type indicator that will be displayed next to the title. */
  fileType?: string
  /** Optional: file name */
  fileName?: string
  /** Disables the file component when set to true. */
  disabled?: boolean
  /** Optional: Indicates if a download is currently in progress. To be used only in controlled mode with `onClick` provided. */
  downloadInProgress?: boolean
  /** Optional: callback function that gets triggered when the link is clicked. Useful for registering clicks for analytics. */
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  /** Optional: custom click handler function. If provided, enables controlled mode and bypasses internal click handling. */
  downloadHandler?: () => void
}

/**
 * The `FileDownload` component allows a user to initiate file downloads. It supports:
 * - Optional file type indicators
 * - Custom click handlers
 * - Controlled mode for download progress (to indicate the download progress)
 * - Responsively handle long file names
 */
export const FileDownload = ({
  source,
  title,
  fileType,
  fileName,
  disabled,
  downloadInProgress,
  onClick,
  downloadHandler,
  tracking,
}: FileDownloadProps) => {
  const [internalInProgress, setInternalInProgress] = useState(false)
  const isControlled = typeof downloadHandler === 'function'

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!isControlled) {
      onClick?.(event)
      setInternalInProgress(true)
      setTimeout(() => {
        setInternalInProgress(false)
      }, 1000)
    }
  }

  const Wrapper = ({
    children,
    className,
  }: PropsWithChildren<{ className: string }>) =>
    disabled ? (
      <div className={`${className} w-full cursor-default`}>{children}</div>
    ) : downloadHandler ? (
      <div className={`${className} cursor-pointer`} onClick={downloadHandler}>
        {children}
      </div>
    ) : (
      <a
        {...(source ? { href: source } : { role: 'link' })}
        download={fileName || true}
        target="_blank noreferrer noopener"
        className={`${className} display-block w-full`}
        onClick={handleClick}
        {...mapTrackingKeysToDataAttributes({
          status: 'on',
          click_type: 'Download',
          item_name: title || fileName,
          ...tracking,
        })}
      >
        {children}
      </a>
    )

  return (
    <Wrapper
      className={cx(
        'bg-neutral-0 body-strong text-primary-600 flex w-full select-none flex-wrap items-center gap-x-32 gap-y-16 border border-solid border-neutral-200 px-24 py-16 transition-colors sm:flex-nowrap sm:justify-center',
        { 'bg-neutral-100': disabled },
        { 'hover:shadow-2 hover:text-primary-700': !disabled }
      )}
    >
      <div className="sr-only">Download</div>
      <div
        className={cx('eq-break-words mr-auto', {
          'text-neutral-500': disabled,
        })}
      >
        {title}
      </div>
      <span className="flex items-center gap-x-32">
        {fileType ? (
          <div className={disabled ? 'brightness-90 filter' : ''}>
            <span className="sr-only">as </span>
            <InfoTag>{fileType}</InfoTag>
          </div>
        ) : null}
        <div className=" flex w-32 items-center justify-end">
          {((isControlled && downloadInProgress) || internalInProgress) &&
          !disabled ? (
            <LoadingIndicator size="small" />
          ) : (
            <Icon
              type="FileDownload"
              color={disabled ? 'eq-color-neutral-300' : undefined}
            />
          )}
        </div>
      </span>
    </Wrapper>
  )
}
