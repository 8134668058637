import { type ReactNode } from 'react'

import { MainNav as MainNavEQL249 } from './main-nav-eql-249'
import { MainNav as MainNavOriginal } from './main-nav-original'
import { NavItem, Profile } from './types'

type GlobalNavProps = {
  navItems: NavItem[]
  profile: Profile
  logos?: ReactNode[]
  logoHref?: string
  isExternalNav?: boolean
  isContainerNav?: boolean
  enabledFeatureFlags?: string[]
}

export function MainNav({
  enabledFeatureFlags = [],
  ...props
}: GlobalNavProps) {
  if (
    enabledFeatureFlags.includes('GLOBAL_NAV_EQL-249') ||
    enabledFeatureFlags.includes('cais.global-nav-eql-249')
  ) {
    return <MainNavEQL249 {...props} />
  }
  return <MainNavOriginal {...props} />
}

export default MainNav
