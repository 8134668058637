import { APP_PATHS } from '../../../domain/apps'
import { logAction } from '../../../util/logging'

import { Rule } from './use-redirect'

export const fundManagerPersona: Rule = {
  test: ({ userProfile, location }) => {
    const personas = userProfile?.personas
    const urlMatch =
      location?.pathname === '/' || location?.pathname === '/homepage'

    if (!personas || !urlMatch) {
      return false
    }

    const test1 = () =>
      personas.length === 1 && personas?.includes('FUND_MANAGER')
    const test2 = () =>
      personas.length === 2 &&
      personas?.includes('FUND_MANAGER') &&
      personas?.includes('ADMIN')

    if (test1() || test2()) {
      return true
    }

    return false
  },
  action: () => {
    window.location.replace('/fund-manager')
  },
}

export const switchBeetweenApps: Rule = {
  test: ({ previousLocation }) => {
    const href = window.location.pathname

    if (!href || !previousLocation) return false

    const currentAPP = previousLocation.split('/')[1]
    const appPathsWithoutCurrent = APP_PATHS.filter(
      (path) => path !== currentAPP
    )
    let hrefBasepath = href.startsWith('/') ? href.substring(1) : href
    hrefBasepath = hrefBasepath.split('/')[0]
    const isCrossAppLink = !!appPathsWithoutCurrent.find(
      (path) => hrefBasepath === path
    )
    return isCrossAppLink
  },
  action: () => {
    logAction({
      message: 'User switched between apps',
      context: {
        previousLocation: window.location.pathname,
        previousSearch: window.location.search,
      },
    })
    window.location.assign(window.location.pathname + window.location.search)
  },
}

export const redirectRulesToRunOnceAfterLogin: Rule[] = [fundManagerPersona]
export const redirectRulesToRunOnEachLocationChange: Rule[] = [
  switchBeetweenApps,
]
