import { useEffect } from 'react'

import { useLocationTrackingValues } from './use-location-tracking-values'
import { usePageTrack } from './use-track'
import { extractSearchId } from './utils'

export const EVENT_PAGE_VIEWED = 'Page Viewed'

const defaultEmptyAdditionalData = {}

/**
 * Will call Segment's `page` method on every url change manually passing the url, referrer, path, and search properties.
 *
 * NB see for example apps/home-office-insights/src/app/app.tsx for how to set up with a react-router-dom Route containing a custom wrapper element with Outlet. You can't just drop this hook into a top-ish level component of you MFE.
 *
 * You can verify this is working by looking for `p` network calls in the browser dev tools.
 */
export function usePageTracking(
  getAdditionalData = () => defaultEmptyAdditionalData
) {
  const { app, url, path, search, referrer } = useLocationTrackingValues()
  /**
   * We have to manually keep track of the referrer because in SPA's, the referrer is not populated as expected.
   * For more context, see: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/single-page-apps/.
   */
  const pageTrack = usePageTrack({ app })
  const additionalData = getAdditionalData()

  useEffect(() => {
    const id = extractSearchId(search)

    pageTrack({
      event: EVENT_PAGE_VIEWED,
      data: {
        url,
        referrer,
        path,
        id,
        ...additionalData,
      },
    })
  }, [pageTrack, search, url, referrer, path, additionalData])
}
