import { Components } from '@mui/material'

import { colors } from '../../../../../equity/particles/colors'

export const MuiMenuItem: Components['MuiMenuItem'] = {
  styleOverrides: {
    root: {
      '&:hover': {
        background: colors['eq-color-primary-100'],
      },
      '&.Mui-selected': {
        background: colors['eq-color-primary-100'],
        '&:hover': {
          background: colors['eq-color-primary-100'],
        },
      },
    },
  },
}
