import { Color } from '../../../../particles/colors'

import { CounterVariant, SemanticColor, Size } from './types'

/**
 * Map of semantic colors to background colors for counter component
 */
const backgroundColorMap: Record<
  SemanticColor,
  Record<CounterVariant, string>
> = {
  neutral: {
    light: 'bg-neutral-200',
    dark: 'bg-neutral-900',
  },
  primary: {
    light: 'bg-primary-200',
    dark: 'bg-primary-600',
  },
  accent: {
    light: 'bg-accent-200',
    dark: 'bg-accent-600',
  },
  warning: {
    light: 'bg-warning-200',
    dark: 'bg-warning-600',
  },
  success: {
    light: 'bg-success-200',
    dark: 'bg-success-600',
  },
  error: {
    light: 'bg-error-200',
    dark: 'bg-error-600',
  },
}

/**
 * Map of semantic colors to icon colors for counter component
 */
export const counterIconColorMap: Record<
  SemanticColor,
  Record<CounterVariant, Color>
> = {
  neutral: {
    light: 'eq-color-neutral-400',
    dark: 'eq-color-neutral-300',
  },
  primary: {
    light: 'eq-color-primary-400',
    dark: 'eq-color-primary-300',
  },
  accent: {
    light: 'eq-color-accent-400',
    dark: 'eq-color-accent-300',
  },
  success: {
    light: 'eq-color-success-400',
    dark: 'eq-color-success-300',
  },
  error: {
    light: 'eq-color-error-400',
    dark: 'eq-color-error-300',
  },
  warning: {
    light: 'eq-color-warning-400',
    dark: 'eq-color-warning-300',
  },
}

/**
 * Returns the background color for the counter component
 *
 * @param color The semantic color of the counter
 * @param variant The variant of the counter
 */
export const getBackgroundColor = (
  color: SemanticColor,
  variant: CounterVariant
): string => {
  return backgroundColorMap[color][variant]
}

/**
 * Returns the icon color for the counter component
 *
 * @param color The semantic color of the counter
 * @param variant The variant of the counter
 */
export const getIconColor = (
  color: SemanticColor,
  variant: CounterVariant
): Color => {
  return counterIconColorMap[color][variant]
}

/**
 * Returns the padding for the counter component based on size
 *
 * @param size The size of the counter
 */
export const getPadding = (size: Size): string =>
  size === 'large' ? 'p-4' : 'p-4 h-24'

/**
 * Returns the font for the counter component based on size
 *
 * @param size The size of the counter
 */
export const getFont = (size: Size): string =>
  size === 'large' ? 'small-mono' : 'caption-mono'

/**
 * Returns the text color for the counter component based on variant and color
 *
 * @param variant The variant of the counter
 * @param color The semantic color of the counter
 */
export const getTextColor = (
  variant: CounterVariant,
  color: SemanticColor
): string => {
  if (variant === 'dark' && color !== 'warning') {
    return 'text-neutral-0'
  }
  return 'text-neutral-900'
}
