// NOTE: using min-h-[40px] is tactical to prevent the logo shrinking in Firefox.
// We should consider a better solution such as using the Cloudinary SDK to fetch the image and set height and width more accurately
// The shared-ui-cloudinary-image component could be used as well.

export function FirmLogo(props: { src: string; alt: string }) {
  return (
    <img
      src={props.src}
      alt={props.alt}
      className="aspect-auto max-h-[40px] min-h-[40px] max-w-[155px]"
    />
  )
}
