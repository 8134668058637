import { PreviewTabs } from './components/shared-ui-preview-experience-preview-tabs'

export type HandleCloseTabType = {
  readonly openModal?: () => void
  readonly onExit: () => void
}

export interface PreviewBannerProps {
  isFirmExperience?: boolean
  showDeleteSwitchTab?: boolean
  onHandleCloseTab: HandleCloseTabType
}

export const PreviewBanner = (props: PreviewBannerProps) => (
  <div className="border-primary-600 fixed left-0 right-0 top-0 z-[100] flex h-0 justify-end border-t-4 border-solid">
    <PreviewTabs {...props} />
  </div>
)

export default PreviewBanner
