import cx from 'classnames'
import { forwardRef, ReactNode } from 'react'

import { Color } from '../../../../particles/colors'
import {
  TrackingProps,
  mapTrackingKeysToDataAttributes,
} from '../../../../utils/tracking-utils'
import { Icon, isBrandIconType, MixedIcon, MixedIconProps } from '../../icon'

type PillColor =
  | 'neutral'
  | 'primary'
  | 'accent'
  | 'success'
  | 'error'
  | 'inverted'

export type PillState = 'regular' | 'active' | 'disabled'

type ColorStateMap = Record<PillColor, Record<PillState, string | string[]>>

const backgroundColorMap: ColorStateMap = {
  neutral: {
    regular: 'bg-neutral-0',
    active: 'bg-neutral-900',
    disabled: 'bg-neutral-0',
  },
  primary: {
    regular: 'bg-neutral-0',
    active: 'bg-primary-100',
    disabled: 'bg-neutral-0',
  },
  accent: {
    regular: 'bg-neutral-0',
    active: 'bg-accent-100',
    disabled: 'bg-neutral-0',
  },
  success: {
    regular: 'bg-neutral-0',
    active: 'bg-success-100',
    disabled: 'bg-neutral-0',
  },
  error: {
    regular: 'bg-neutral-0',
    active: 'bg-error-100',
    disabled: 'bg-neutral-0',
  },
  inverted: {
    regular: 'bg-neutral-0/25',
    active: 'bg-neutral-900/75',
    disabled: 'bg-neutral-0/25',
  },
}

const getBackgroundColor = (state: PillState, color: PillColor): string => {
  // once we updated to version 4.9 of Typescript we can use `satisfies` and here we will not need to check if it is an array
  const tailwindColor = backgroundColorMap[color][state]
  return Array.isArray(tailwindColor) ? tailwindColor.join(' ') : tailwindColor
}

const borderColorMap: ColorStateMap = {
  neutral: {
    regular: [
      'border-neutral-200',
      'hover:border-neutral-600 focus:border-neutral-600',
    ],
    active: 'border-neutral-600',
    disabled: 'border-neutral-200',
  },
  primary: {
    regular: [
      'border-neutral-200',
      'hover:border-primary-600 focus:border-primary-600',
    ],
    active: 'border-primary-600',
    disabled: 'border-neutral-200',
  },
  accent: {
    regular: [
      'border-neutral-200',
      'hover:border-accent-600 focus:border-accent-600',
    ],
    active: 'border-accent-600',
    disabled: 'border-neutral-200',
  },
  success: {
    regular: [
      'border-neutral-200',
      'hover:border-success-600 focus:border-success-600',
    ],
    active: 'border-success-600',
    disabled: 'border-neutral-200',
  },
  error: {
    regular: [
      'border-neutral-200',
      'hover:border-error-600 focus:border-error-600',
    ],
    active: 'border-error-600',
    disabled: 'border-neutral-200',
  },
  inverted: {
    regular: 'border-neutral-500 hover:border-neutral-0 focus:border-neutral-0',
    active: 'border-neutral-600',
    disabled: 'border-neutral-600',
  },
}

const getBorderColor = (state: PillState, color: PillColor): string => {
  const tailwindColor = borderColorMap[color][state]
  return Array.isArray(tailwindColor) ? tailwindColor.join(' ') : tailwindColor
}

const textColorMap: ColorStateMap = {
  neutral: {
    regular: 'text-neutral-900',
    active: 'text-neutral-0',
    disabled: 'text-neutral-400',
  },
  primary: {
    regular: 'text-primary-600',
    active: 'text-primary-600',
    disabled: 'text-neutral-400',
  },
  accent: {
    regular: 'text-accent-600',
    active: 'text-accent-600',
    disabled: 'text-neutral-400',
  },
  success: {
    regular: 'text-success-600',
    active: 'text-success-600',
    disabled: 'text-neutral-400',
  },
  error: {
    regular: 'text-error-600',
    active: 'text-error-600',
    disabled: 'text-neutral-400',
  },
  inverted: {
    regular: 'text-neutral-0',
    active: 'text-neutral-0',
    disabled: 'text-neutral-400',
  },
}

const getTextColor = (state: PillState, color: PillColor): string => {
  const tailwindColor = textColorMap[color][state]
  return Array.isArray(tailwindColor) ? tailwindColor.join(' ') : tailwindColor
}

const getCursor = (state: PillState): string => {
  return state === 'disabled' ? 'cursor-not-allowed' : 'cursor-pointer'
}

const typeSizePaddingMap: Record<PillSize, Record<PillType, string>> = {
  regular: {
    default: 'px-24 py-8',
    dropdown: 'py-8 pl-24',
    dismissable: 'py-8 pl-24',
  },
  small: {
    default: 'px-16 py-4',
    dropdown: 'py-4 pl-16',
    dismissable: 'py-4 pl-16',
  },
}

const getPadding = (type: PillType, size: PillSize): string => {
  return typeSizePaddingMap[size][type]
}

const getHeight = (size: PillSize): string => {
  return size === 'small' ? 'h-32' : 'h-[40px]'
}

export const pillIconColorMap: Record<PillColor, Color> = {
  neutral: 'eq-color-neutral-900',
  primary: 'eq-color-primary-600',
  accent: 'eq-color-accent-600',
  success: 'eq-color-success-600',
  error: 'eq-color-error-600',
  inverted: 'eq-color-neutral-0',
}

const getIconColor = (state: PillState, color: PillColor): Color => {
  if (state === 'disabled') {
    return 'eq-color-neutral-400'
  }
  if (state === 'active' && color === 'neutral') {
    return 'eq-color-neutral-0'
  }
  return pillIconColorMap[color]
}

const PillIcon = ({
  icon,
  color,
}: {
  icon: MixedIconProps['type']
  color: Color
}) => (
  <span className="mr-8 flex items-center justify-center">
    {isBrandIconType(icon) ? (
      <MixedIcon type={icon} size="small" />
    ) : (
      <MixedIcon type={icon} size="small" color={color} />
    )}
  </span>
)

export type PillType = 'default' | 'dropdown' | 'dismissable'

type PillSize = 'regular' | 'small'

export type PillProps = {
  /** Optional property to determine what size the Pill should be */
  size?: PillSize
  /** Optional property to set which Equity semantic color to be used */
  color?: PillColor
  /** Optional property to define in which state the Pill should be */
  state?: PillState
  /** Optional property to determine either if the Pill should have a dropdown icon or not */
  type?: PillType
  /** Optional property to define the icon shown just before the text */
  icon?: MixedIconProps['type']
  /** Optional property to define the color of the pill icon */
  iconColor?: Color
  /** The function to be called when pressing on the Pill (if the state is not disabled) */
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  /** Optional property to determine whether the Pill is selected or not */
  selected?: boolean
  /** Optional property to set the test id */
  testId?: string
  /** Aria Label for this pill */
  ariaLabel?: string
  /** Tracking properties to be sent to Segment */
  tracking?: TrackingProps
} & (
  | {
      /** @deprecated Use the children itself to define the text of the pill (2024-09-03) */
      label: ReactNode
      /** The text of the pill */
      children?: never
    }
  | {
      /** @deprecated Use the children itself to define the text of the pill (2024-09-03) */
      label?: never
      /** The text of the pill */
      children: ReactNode
    }
)

/**
 * A pill is a compact label that appears beside a primary interface area which is used to represent status or metadata for that area.
 * Pills allow users to enter information, make selections, filter content, or trigger actions.
 */
export const Pill = forwardRef<HTMLButtonElement, PillProps>(
  (
    {
      label,
      children,
      size = 'regular',
      color = 'neutral',
      state = 'regular',
      type = 'default',
      icon,
      iconColor,
      onClick,
      selected = false,
      testId = 'pill',
      ariaLabel,
      tracking,
    },
    ref
  ) => {
    return (
      <button
        aria-label={ariaLabel}
        ref={ref}
        type="button"
        onClick={onClick}
        disabled={state === 'disabled'}
        data-testid={testId}
        className={cx(
          'small-strong inline-flex w-fit shrink-0 items-center justify-start overflow-hidden rounded-full border border-solid',
          getBackgroundColor(state, color),
          getTextColor(state, color),
          getCursor(state),
          getBorderColor(state, color),
          getPadding(type, size),
          getHeight(size)
        )}
        aria-expanded={selected}
        {...mapTrackingKeysToDataAttributes({
          status: 'on',
          click_type: 'Button',
          ...tracking,
        })}
      >
        {icon ? (
          <PillIcon
            icon={icon}
            color={iconColor || getIconColor(state, color)}
          />
        ) : null}
        <span className="small-strong whitespace-pre-wrap">
          {children || label}
        </span>
        {type === 'dropdown' || type === 'dismissable' ? (
          <span className="flex items-center justify-center px-8">
            <Icon
              type={
                type === 'dismissable'
                  ? 'Close'
                  : selected
                  ? 'ArrowDropUp'
                  : 'ArrowDropDown'
              }
              color={iconColor || getIconColor(state, color)}
              size={size === 'small' ? 'small' : 'regular'}
            />
          </span>
        ) : null}
      </button>
    )
  }
)
