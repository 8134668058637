import { getEnvConfig } from '../../../../env'

import * as styles from './styles'

export function EnvironmentTag() {
  const environmentColorMap: Record<string, string> = {
    localhost: 'bg-neutral-200 text-neutral-900',
    staging: 'bg-primary-200 text-neutral-900',
    dev: 'bg-warning-600 text-neutral-900',
    qa: 'bg-success-500 text-neutral-0',
    ephemeral: 'bg-error-500 text-neutral-0',
  }

  const environment = getEnvConfig()?.ENVIRONMENT || ''

  return ['localhost', 'dev', 'qa', 'staging', 'ephemeral'].includes(
    environment
  ) ? (
    <span
      className={`small-mono grid w-fit place-items-center rounded-full px-16 py-4 ${styles.wideScreenBreakpoint.environmentTag}
        ${environmentColorMap[environment]}`}
    >
      {environment.toUpperCase()}
    </span>
  ) : null
}
