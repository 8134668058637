import styled from '@emotion/styled'
import { forwardRef, ReactNode } from 'react'
import { omit } from 'remeda'

export type FrameProps = {
  x?: number
  y?: number
  children: ReactNode
  className?: string
}

export const LayoutFrame = styled(
  forwardRef<HTMLDivElement, FrameProps>((props, ref) => {
    const passedProps = omit(props, ['x', 'y', 'className', 'children'])
    return (
      <div
        ref={ref}
        className={props.className}
        data-layout="LayoutFrame"
        {...passedProps}
      >
        {props.children}
      </div>
    )
  })
)(({ x = 1, y = 1.618 }) => {
  return `
      display: block;
      position: relative;
      padding-bottom: calc(${y} / ${x} * 100%);
      
      & > * {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
      
      & > img, 
      & > video {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    `
})

LayoutFrame.displayName = 'LayoutFrame'
