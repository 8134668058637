import { AxiosRequestConfig } from 'axios'

import { getEnvConfig } from '../../../ui/env'
import { getEnvConfig as getCaisIQEnvConfig } from '../../../ui/env-caisiq'
import { fetchInstance as sharedFetchInstance } from '../../../util/fetch'

export async function fetchInstanceExperienceManager<T>(
  requestConfig: AxiosRequestConfig
) {
  const envConfig = getEnvConfig() || getCaisIQEnvConfig()
  return sharedFetchInstance<T>(requestConfig, { envConfig })
}
