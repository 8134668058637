import BigNumber from 'bignumber.js'

// https://www.avioconsulting.com/blog/overcoming-javascript-numeric-precision-issues
// most decimal fractions cannot be represented exactly as binary fractions.

export enum NumberStyle {
  DECIMAL = 'decimal',
  CURRENCY = 'currency',
  PERCENT = 'percent',
  UNIT = 'unit',
}

export type SignDisplayType = 'auto' | 'never' | 'always' | 'exceptZero'

export type NumberFormatOptionsType = Intl.NumberFormatOptions & {
  truncate?: boolean
}
// USD, EUR, INR, JPY
// en-US, de-DE, en-IN, ja-JP
export const defaultNumberFormatOptions: NumberFormatOptionsType = {
  style: NumberStyle.CURRENCY,
  currency: 'USD',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  useGrouping: true,
  signDisplay: 'auto',
}

export const numberFormatOptionsWithTwoDp: NumberFormatOptionsType = {
  style: NumberStyle.CURRENCY,
  currency: 'USD',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
  signDisplay: 'auto',
}

export const numberFormatOptionsWithTruncatedTwoDp: NumberFormatOptionsType = {
  style: NumberStyle.CURRENCY,
  currency: 'USD',
  currencyDisplay: 'symbol',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
  signDisplay: 'auto',
  truncate: true,
}

export const numberFormatOptionsWithTwoDpNoSymbol: NumberFormatOptionsType = {
  style: NumberStyle.DECIMAL,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: true,
}

export const numberFormatOptionsWithTruncatedTwoDpNoSymbol: NumberFormatOptionsType =
  {
    style: NumberStyle.DECIMAL,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    useGrouping: true,
    truncate: true,
  }

export const formatNumberWithUnits = (
  value: string | number | null | undefined,
  options: NumberFormatOptionsType = defaultNumberFormatOptions,
  locale = 'en-US'
): string => {
  if (typeof value !== 'number' && typeof value !== 'string') return '-'

  let bigNumber = new BigNumber(value)

  if (options.truncate && options.maximumFractionDigits !== undefined) {
    bigNumber = bigNumber.decimalPlaces(
      options.maximumFractionDigits,
      BigNumber.ROUND_DOWN
    )
  }

  return options
    ? new Intl.NumberFormat(locale, options).format(bigNumber.toNumber())
    : new Intl.NumberFormat(locale).format(bigNumber.toNumber())
}

export const formatNumberWithCommas = (
  value?: number,
  options?: NumberFormatOptionsType
) =>
  typeof value === 'number'
    ? `${formatNumberWithUnits(value, { ...options })}`
    : ''

// We should properly consider how to handle especially around precision etc, but this will allow for fix now + can at least reuse this (and improve implementation)
export const flooredFormatNumberWithCommas = (
  value?: number | null,
  dp = 2
) => {
  if (typeof value === 'number') {
    // Otherwise will be rounded to nearest by the formatting
    const flooredValue = new BigNumber(value).decimalPlaces(
      dp,
      BigNumber.ROUND_DOWN
    )

    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: dp,
      maximumFractionDigits: dp,
    }).format(flooredValue.toNumber())
  } else {
    return '-'
  }
}

export type Currency = { currency: string; value: number }

export const isNumber = (value: number | undefined) =>
  typeof value === 'number' && isFinite(value)

/**
 * @param value a number to format as percentage. NB 0.2 = 20%
 * @param decimalPlaces on final percentage value (not on input number)
 * @param appendSymbol add a % or not (default true)
 * @returns
 */
export function moreExactPercentageFormatter({
  value,
  decimalPlaces = 0,
  appendSymbol = true,
  roundingMode = BigNumber.ROUND_DOWN,
  showFallback = true,
}: {
  value: number | undefined | null
  decimalPlaces?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  appendSymbol?: boolean
  roundingMode?: BigNumber.RoundingMode
  showFallback?: boolean
}): string {
  if (typeof value === 'number') {
    return `${new BigNumber(value)
      .multipliedBy(100)
      .toFixed(decimalPlaces, roundingMode)}${appendSymbol ? '%' : ''}`
  } else {
    return showFallback ? '-' : ''
  }
}

/**
 *
 * Format a return as overall outcome as in some cases we are getting 0.2 from BE and want to format as 120% (i.e. 20% return)
 *
 * @param value a number to format as percentage; assumed to be return e.g. 0.2 = 20%
 * @param decimalPlaces on final percentage value (not on input number)
 * @returns
 */
export function formattedReturnAsOutcome({
  value,
  decimalPlaces = 0,
  roundingMode = BigNumber.ROUND_DOWN,
}: {
  value: number | undefined | null
  decimalPlaces?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9
  roundingMode?: BigNumber.RoundingMode
}): string {
  if (typeof value === 'number') {
    return `${new BigNumber(value)
      .plus(1)
      .multipliedBy(100)
      .toFixed(decimalPlaces, roundingMode)}%`
  } else {
    return '-'
  }
}

/**
 * In SP this is actually used in a wide variety of cases, so trying to make it simple. 4dp is sometimes used; but 2dp truncation should be our default.
 *
 * Sure this one is right this time https://xkcd.com/927/
 *
 * @param value a number (but might be missing, i.e. null or undefined as with our APIs this is often at least in type gen case possible, though often happens too as null)
 */
export function standard2dpPercentageFormatter(
  value: number | undefined | null,
  appendSymbol = true
): string {
  return moreExactPercentageFormatter({
    value,
    decimalPlaces: 2,
    appendSymbol: appendSymbol,
  })
}

export const formatBytes = (value: number) => {
  const exponent = Math.max(0, Math.floor(Math.log10(value) / 3) || 0)

  const units = ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte']
  const formatter = new Intl.NumberFormat('en', {
    style: 'unit',
    // @ts-ignore
    unit: units[exponent],
    unitDisplay: exponent === 0 ? 'long' : 'short',
    maximumFractionDigits: 2,
  })
  return formatter.format(value / 1000 ** exponent)
}

export function safelyParseFloat(value: string | number | null | undefined) {
  if (typeof value !== 'number' && typeof value !== 'string') return undefined
  if (typeof value === 'string' && value === '') return undefined
  return parseFloat(value.toString())
}

export function safelyDivideBy100(value: number | string | null | undefined) {
  if (typeof value !== 'number' && typeof value !== 'string') return undefined
  return new BigNumber(value).dividedBy(100).toNumber()
}

export function safelyMultiplyBy100(value: number | string | null | undefined) {
  if (typeof value !== 'number' && typeof value !== 'string') return undefined
  return new BigNumber(value).multipliedBy(100).toNumber()
}

export function decimalToPercent(n: number) {
  return new BigNumber(n).multipliedBy(100).toNumber()
}

export function percentToDecimal(n: number) {
  return new BigNumber(n).dividedBy(100).toNumber()
}
