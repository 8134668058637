import { useCallback } from 'react'
import { isEmpty, isPlainObject, pickBy } from 'remeda'

import { useLocationTrackingValues } from './use-location-tracking-values'
import { useTrack } from './use-track'

export const EVENT_ITEM_CLICKED = 'Item Clicked'

/**
 * A helper hook that wraps the 'Item Clicked' event.
 */
export function useTrackItemClicked<Payload>(getSection?: () => string) {
  const { app, url, ...locationProps } = useLocationTrackingValues()

  const track = useTrack({
    app,
    url,
  })

  return useCallback(
    (data: Payload) => {
      track({
        event: EVENT_ITEM_CLICKED,
        data: {
          section: getSection && getSection(),
          ...pickBy(locationProps, (val, _) => !isEmpty(val)),
          ...(isPlainObject(data)
            ? pickBy(data, (val, _) => val && !isEmpty(val))
            : {}),
        },
      })
    },
    [getSection, locationProps, track]
  )
}

export type TrackItemClicked = ReturnType<typeof useTrackItemClicked>
