import { Transition } from '@headlessui/react'
import { Toaster, toast as hotToast, resolveValue } from 'react-hot-toast'

import { Button } from '../../../atoms/button'
import { Icon } from '../../../atoms/icon'

import {
  toastTypeToBackgroundColorMap,
  toastTypeToIconColorMap,
  toastTypeToIconMap,
} from './styles'
import { ToastAction, ToastType } from './types'

/**
 * Props for the `Toast` component.
 */
export type ToastProps = {
  /** The id of the toast message to display. */
  id?: string
  /** The type of toast message to display. Defaults to info */
  type?: ToastType
  /** The title of the toast message. */
  title: string
  /** A boolean indicating whether a toast can be dismissed. */
  dismissable?: boolean
  /** An optional action to display in the toast. */
  action?: ToastAction
  /** A boolean indicating whether to dismiss on click of Action. */
  dismissOnClick?: boolean
}

/**
 * A component that displays a toast message.
 */
export const Toast = ({
  title,
  type = 'info',
  id,
  dismissable,
  action,
  dismissOnClick = true,
}: ToastProps): JSX.Element => {
  const ToastContent = (
    <div className="text-neutral-0 flex gap-x-16">
      <Icon
        type={toastTypeToIconMap[type]}
        color={toastTypeToIconColorMap[type]}
      />
      <div className="bodyStrong" data-testid="toast-title">
        {title}
      </div>
    </div>
  )

  const ActionButton = action?.text && (
    <Button
      variant="secondary"
      color={type === 'info' ? 'primary' : type}
      size="small"
      data-testid="toast-action-button"
      onClick={() => {
        action?.handler()
        if (dismissOnClick) {
          hotToast.dismiss(id)
        }
      }}
    >
      {action.text}
    </Button>
  )

  const DismissButton = dismissable && (
    <div
      className="duration-medium-2 flex cursor-pointer transition-all"
      data-testid="toast-dismiss"
      onClick={() => hotToast.dismiss(id)}
    >
      <Icon type="Close" color={toastTypeToIconColorMap[type]} />
    </div>
  )

  return (
    <div
      className={`shadow-6 flex h-[64px] w-full items-center justify-between px-24 ${toastTypeToBackgroundColorMap[type]}`}
    >
      {ToastContent}
      <div className="duration-medium-2 flex items-center gap-x-16 transition-all">
        {ActionButton}
        {DismissButton}
      </div>
    </div>
  )
}

export const NotificationsManager = () => {
  return (
    <Toaster containerStyle={{ inset: '0px' }}>
      {(t) => (
        <div
          style={{ pointerEvents: 'none' }}
          className="flex w-full items-center justify-center"
        >
          {resolveValue(t.message, t)}
        </div>
      )}
    </Toaster>
  )
}

const DISMISS_TIMEOUT_MS = 3000
export const showToast = (props: ToastProps) =>
  hotToast(
    (t) => (
      <Transition
        appear
        show={t.visible}
        className="duration-medium-2 pointer-events-auto mb-16 flex w-3/5 transform transition-all"
        enter="transition-all ease-standard duration-medium-2"
        enterFrom="opacity-0 scale-50"
        enterTo="opacity-100 scale-100"
        leave="transition-all ease-standard duration-medium-2"
        leaveFrom="opacity-100 scale-300"
        leaveTo="opacity-0 scale-75"
        data-testid="toast-manager"
      >
        <Toast {...props} />
      </Transition>
    ),
    {
      duration: props.dismissable ? Infinity : DISMISS_TIMEOUT_MS,
      position: 'bottom-center',
      id: props.id || `default-id-${Date.now().toString()}`,
    }
  )
