import { Avatar } from '../../../../../equity/web/atoms/avatar'

import { MobileMenuContainer } from './mobile-menu-container'
import { Menu } from './shared/menu'
import * as styles from './shared/styles'
import { type ProfileMenuProps } from './types'

export const ProfileMenu = (props: ProfileMenuProps) => {
  const { menuContents, profile, ...otherProps } = props
  const profileMenuItems = profile.menu.groups[0].items
  const userName = `${profile.firstName} ${profile.lastName}`

  return (
    <>
      <MobileMenuContainer
        {...otherProps}
        id={profile.menu.id}
        label="Profile navigation"
        menuContents={menuContents}
        navItems={profileMenuItems}
      >
        <UserAvatar userName={userName} />
      </MobileMenuContainer>

      {/* Show menu on larger screens */}
      <Menu
        {...otherProps}
        classNames={{
          listItem: 'group relative hidden md:block',
          menu: styles.profile.menuButton,
          menuItem: styles.primary.menuItem,
        }}
        isMenuOpen={profile.menu.id === props.openMenu}
        menuButton={
          <MenuButton companyName={profile.companyName} userName={userName} />
        }
        menuContents={menuContents?.[0] || null}
        menuId={profile.menu.id}
        type="desktop"
      />
    </>
  )
}

function UserLabel(props: { userName: string; companyName: string }) {
  return (
    <p
      className={`small-strong hidden text-right ${styles.wideScreenBreakpoint.display.block} ${styles.profile.userName} ${styles.animate}`}
    >
      {props.userName}
      <span
        className={`caption block uppercase ${styles.profile.company} ${styles.animate}`}
      >
        {props.companyName}
      </span>
    </p>
  )
}

function UserAvatar(props: { userName: string }) {
  return (
    <div className={styles.profile.avatar}>
      <Avatar>{props.userName}</Avatar>
    </div>
  )
}

function MenuButton({
  companyName,
  userName,
}: {
  companyName: string
  userName: string
}) {
  return (
    <div className="flex items-center space-x-8 focus-visible:ring">
      <UserLabel userName={userName} companyName={companyName} />
      <UserAvatar userName={userName} />
    </div>
  )
}
