import { keys } from 'remeda'

import spacingJson from './spacing.json'

type Spacing = {
  [P in (typeof spacingKeys)[number] as `s${P}`]: `var(--eq-s-${P})`
}
/**
 * The `spacing` object lists all the different spaces defined in the Equity
 * Spacing Particle. Having it defined that way means IntelliSense will display
 * the proper value of a property as a type, not just "string".
 *
 * It replicates the list that is defined in the `spacing.json` file, which
 * should be considered the source of truth.
 *
 * The type definition guarantees, at compile time, the `spacing` object lists
 * all the items present in the json file.
 */
export const spacing: Spacing = {
  s4: 'var(--eq-s-4)',
  s8: 'var(--eq-s-8)',
  s16: 'var(--eq-s-16)',
  s24: 'var(--eq-s-24)',
  s32: 'var(--eq-s-32)',
  s56: 'var(--eq-s-56)',
  s88: 'var(--eq-s-88)',
  s144: 'var(--eq-s-144)',
  s232: 'var(--eq-s-232)',
  s376: 'var(--eq-s-376)',
  s608: 'var(--eq-s-608)',
} as const

type ToNumber<S extends string> = S extends `${infer N extends number}`
  ? N
  : never

const spacingKeys = keys(spacingJson)
type SpacingUnit = {
  [P in (typeof spacingKeys)[number] as `s${P}`]: ToNumber<P>
}

/** @deprecated
 * The spacing values are defined in pixels and should not be used unitless.
 * Use the `spacing` object from the same file instead.
 * In case you need to do any calculation this can be achieved using the CSS `Calc()` funtion.
 *
 * The `spacingUnits` object was added to Equity to ease the task of making the legacy code
 * using the Equity Spacing particle, deleting the legacy spacing tokens, and gradually
 * updating the legacy code to use the `spacing` object instead (or the Tailwind classes).
 * */
export const spacingUnits: SpacingUnit = {
  s4: 4,
  s8: 8,
  s16: 16,
  s24: 24,
  s32: 32,
  s56: 56,
  s88: 88,
  s144: 144,
  s232: 232,
  s376: 376,
  s608: 608,
} as const
