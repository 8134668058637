import { Components } from '@mui/material'

import { shadow } from '../shadows'

export const MuiPaper: Components['MuiPaper'] = {
  defaultProps: {
    variant: 'elevation',
    elevation: 0,
  },
  styleOverrides: {},
  variants: [
    {
      props: { variant: 'simple-hover' },
      style: {
        '&:hover': {
          boxShadow: shadow(24),
        },
      },
    },
  ],
}
