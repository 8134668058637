import type { PropsWithChildren } from 'react'

import type { TrackingAttributes } from '../../../../../../equity/utils/tracking-utils'
import { contentContainer } from '../content-container'

const backgrounds = {
  brandBackground:
    'bg-neutral-100 bg-gradient-to-b from-[56px] to-[56px] from-brand-800 to-neutral-100',
  default: 'bg-neutral-0',
}

export const Main = (
  props: PropsWithChildren<{
    background?: keyof typeof backgrounds
    fullWidth?: boolean
    trackingAttributes?: TrackingAttributes
  }>
) => {
  const { children, fullWidth, trackingAttributes } = props
  const background = backgrounds[props.background || 'default']

  return (
    <main
      id="main-content"
      tabIndex={-1}
      className={`${background} isolate flex-auto ${
        fullWidth ? '' : `${contentContainer} py-24 lg:p-56`
      }`}
      {...trackingAttributes}
    >
      {children}
    </main>
  )
}
