import { Link } from '@cais-group/equity/atoms/link'

export function ViewAll({
  href,
  text = 'View All',
}: {
  href: string
  text?: string
}) {
  return (
    <Link
      className="body-small md:body-strong align-middle leading-4"
      href={href}
      tracking={{
        click_type: 'Link',
        item_name: text,
      }}
    >
      {text}
    </Link>
  )
}
