import { Components } from '@mui/material'

import { palette } from '../palette'

export const MuiButtonGroup: Components['MuiButtonGroup'] = {
  styleOverrides: {
    root: {
      boxShadow: 'none',
    },
    grouped: {
      '&.MuiButton-containedBlack': {
        borderWidth: '1px',
        borderColor: palette.black.main,
      },
      '&.MuiButton-containedNeutral': {
        borderWidth: '1px',
        background: palette.neutral.light,
        borderColor: palette.neutral.light,
      },
    },
  },
}
