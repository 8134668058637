const cssToJs = (s: string) =>
  s.replace(/\W+\w/g, (match) => match.slice(-1).toUpperCase())

/**
 * NOTE: doesn't work with nested props, that contains & for example.
 */
export const parseCssString = (css: string): Record<string, string> => {
  const style: Record<string, string> = {}
  const cssString = css
    .replace(/\/\*[\s\S]*?\*\/|\/\/.*/g, '') // remove comments
    .trim()
    .replace(/label:(.*)/, '') // emotion adds `label: var-name` to the end of string, remove it.
    .replace(/\s+/g, ' ')
  const [, , rule] = cssString.match(/ ?(.*?) ?{([^}]*)}/) ?? [
    undefined,
    undefined,
    cssString,
  ]
  const properties =
    rule?.split(';').map((o) => o.split(':').map((x) => x && x.trim())) ?? []

  for (const [property, value] of properties) {
    style[cssToJs(property)] = value
  }

  return Object.fromEntries(Object.entries(style).filter(([_, value]) => value))
}

export const shouldForwardProp = (props: string | string[]) => {
  return (prop: string): boolean => {
    if (Array.isArray(props)) {
      return !props.includes(prop)
    }

    return ![props].includes(prop)
  }
}
