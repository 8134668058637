import { NavLink } from '../../components/nav-link'
import { type BuildMenuContent, type MenuContents } from '../types'

import * as styles from './styles'
import { SubMenu } from './sub-menu'

export const buildMenuContent = (
  props: BuildMenuContent
): MenuContents | null => {
  const { env, navItems } = props

  return navItems
    ? navItems.map((navItem) => {
        return 'menu' in navItem ? (
          navItem.menu.groups.map((group, index) => (
            <SubMenu
              env={env}
              key={`group-${index}`}
              enablePendo
              group={group}
              index={index}
              subMenuId={navItem.menu.id}
              trackMenuLabel={navItem.name}
            />
          ))
        ) : (
          <NavLink
            to={navItem.href}
            className={`${styles.primary.navLink}`}
            urlType={navItem.urlType}
            env={props.env}
            data-menu-label // Note: this is used by css to style active menu state
          >
            {navItem.name}
          </NavLink>
        )
      })
    : null
}
