export const millisecondsToMinutes = (
  length: number,
  mathFunc: 'ceil' | 'round' | 'floor'
): number => Math[mathFunc](length / 60 / 1000)

export const millisecondsToSeconds = (
  length: number,
  mathFunc: 'ceil' | 'round' | 'floor'
): number => Math[mathFunc](length / 1000)

export const secondsToMinutes = (
  length: number,
  mathFunc: 'ceil' | 'round' | 'floor'
): number => Math[mathFunc](length / 60)
