import type {
  CuratedContentDataType,
  EventType,
} from '../../../../util/contentful/types'
import type { UserProfileFirm } from '../../../../util/graphql'
import type { Maybe } from '../../../../util/graphql/mfe-contentful'

import {
  getHref,
  getIconType,
  isEvent,
  getCtaIcon,
  getCtaText,
  getType,
  isLink,
  isArticle,
  isExternalContent,
  getContentOwnershipTag,
} from './helper'
import { baseEventCardProps } from './prepare-card-props'
import type { CtaAndIconType } from './types'

const pdfRegex = /\.pdf$/i

function prepareEventCuratedCard<T>(
  event: EventType & {
    customDescription?: Maybe<string> | undefined
    customTitle?: Maybe<string> | undefined
  }
): T | null {
  if (!event) {
    return null
  }

  const baseProps = baseEventCardProps(event)

  const callToAction = {
    text: baseProps?.callToAction,
    href: baseProps?.href,
    icon: getCtaIcon({
      __typename: event.__typename,
      href: baseProps?.href,
    }),
  }

  return {
    ...baseProps,
    callToAction,
    description: event.customDescription || event.description,
    iconType: getIconType('Event'),
    title: event.customTitle || event.title,
  } as T
}

export function prepareCuratedCard<T>(
  contentType: CuratedContentDataType & {
    userFirms?: UserProfileFirm[]
  },
  appUrl: string
): T | null {
  if (!contentType) {
    return null
  }

  if (isEvent(contentType)) {
    return prepareEventCuratedCard<T>(contentType)
  }

  const href = getHref(appUrl, contentType)
  let type = getType(contentType) as CtaAndIconType

  if (type === 'Pdf') {
    if (pdfRegex.test(href || '')) {
      type = 'CmsPdf'
    } else {
      type = 'CmsDocument'
    }
  }

  const callToAction = {
    href: href,
    icon: getCtaIcon({
      __typename: contentType.__typename,
      href,
    }),
    text: getCtaText(type),
  }

  const backupDescription = isArticle(contentType)
    ? contentType.description
    : ''
  const backupTitle = !isLink(contentType) ? contentType.title : ''

  const contentOwnershipTag = getContentOwnershipTag(contentType)
  const date =
    (isExternalContent(contentType) && contentType.type === 'Fund') ||
    isLink(contentType)
      ? undefined
      : 'displayDate' in contentType
      ? contentType.displayDate
      : ''

  return {
    ...contentType,
    callToAction,
    id: contentType.sys?.id,
    iconType: getIconType(type),
    description: contentType.customDescription || backupDescription,
    title: contentType.customTitle || backupTitle,
    contentOwnershipTag: contentOwnershipTag
      ? {
          name: contentOwnershipTag.name,
          id: contentOwnershipTag.id,
        }
      : undefined,
    date,
  } as T
}
