import { Components } from '@mui/material'

import { colors } from '../../../../../equity/particles/colors'

export const MuiAppBar: Components['MuiAppBar'] = {
  defaultProps: {
    variant: 'elevation',
    elevation: 0,
  },
  styleOverrides: {
    root: {
      background: colors['eq-color-neutral-0'],
      '& [class*=MuiStack-root]': {
        width: '100%',
      },
      '& .MuiButton-text:not(:last-child)': {
        marginRight: 'var(--s32)',
      },
      '& .MuiMenuItem-root': {
        color: colors['eq-color-neutral-900'],
        width: '100%',
      },
      '& .MuiAccordion-root.Mui-expanded': {
        margin: '0',
      },
      '& .MuiAccordion-root': {
        '&:before': {
          background: 'none',
        },
        minHeight: '54px',
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '54px',
      },
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0',
      },
      '& .MuiListItem-root': {
        padding: 0,
      },
      '& .MuiAccordionSummary-root': {
        padding: '0',
      },
      '& .MuiAccordionDetails-root': {
        padding: '0',
      },
      '& .hamburger-menu svg': {
        margin: '12px',
      },
      '& .MuiAccordionSummary-expandIconWrapper': {
        transform: 'rotate(-90deg)',
      },
      '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(0deg)',
      },
    },
  },
}
