import type { PropsWithChildren, ReactNode } from 'react'
import { useId } from 'react'

import { mapTrackingKeysToDataAttributes } from '../../../../../../equity/utils/tracking-utils'
import { Counter } from '../../../../../../equity/web/atoms/counter'

export type SectionProps = {
  title?: string
  count?: number
  controls?: ReactNode
  description?: string
  endAdornment?: ReactNode
  sectionId?: string
  withPadding?: boolean
}

export function Section(props: PropsWithChildren<SectionProps>) {
  const {
    children,
    controls,
    count,
    description,
    endAdornment,
    title,
    sectionId,
    withPadding,
  } = props
  const headingId = `heading-${useId()}`
  const ariaLabelType = sectionId && !title ? 'label' : 'labelledby'
  const ariaLabelProps =
    ariaLabelType === 'label'
      ? { 'aria-label': sectionId }
      : { 'aria-labelledby': headingId }
  return (
    <section
      data-track-sub-section={title || sectionId}
      className={`bg-neutral-0 space-y-24 ${withPadding ? 'p-32' : ''}`}
      {...ariaLabelProps}
      {...mapTrackingKeysToDataAttributes({ sub_section: title || sectionId })}
    >
      {title ? (
        <header className="flex flex-wrap items-center justify-start gap-8 min-[376px]:justify-between sm:justify-start lg:gap-16">
          <h2 className="body-strong md:headline-s-strong" id={headingId}>
            {title}
          </h2>
          {count ? <Counter value={count} variant="light" /> : null}
          {endAdornment ? (
            <p className="small-strong ml-auto">{endAdornment}</p>
          ) : null}
          {controls}
        </header>
      ) : null}
      {description ? (
        <p className="body w-full text-center text-neutral-600 md:text-start">
          {description}
        </p>
      ) : null}
      {children}
    </section>
  )
}
