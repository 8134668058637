import { getEnvConfig } from '@cais-group/shared/ui/env'
import { useHasAttribute } from '@cais-group/shared/ui/feature-flag'

import { defaultQuickLinks } from './default-quick-links'

export function useQuickLinks() {
  const { hasAccess } = useHasAttribute('cais.global-nav-model-portfolios')
  let canAccessCompassLinks = hasAccess

  if (!canAccessCompassLinks) {
    const env = getEnvConfig() ?? {}

    for (const [name, value] of Object.entries(env.FEATURE_FLAGS ?? {})) {
      if (name === 'GLOBAL_NAV_MODEL-PORTFOLIOS' && value === true) {
        canAccessCompassLinks = true
        break
      }
    }
  }

  return defaultQuickLinks({
    enableModelPortfoliosLinks: canAccessCompassLinks,
  })
}
