import { useEffect, useRef, type MutableRefObject } from 'react'

import { useTrackItemClicked } from '../../../../util/segment'

import { useOnClickOutside } from './shared/use-click-outside'
import { type NavRootProps } from './types'

export function Nav(props: NavRootProps) {
  const { children, handleCloseMenu, isFixed, id = '' } = props
  const navRef = useRef<HTMLElement>(null) as MutableRefObject<HTMLDivElement>
  const trackClick = useTrackItemClicked()

  useOnClickOutside({
    ref: navRef,
    callback: handleCloseMenu,
  })

  useEffect(() => {
    const handleNavLinkClick = (event: Event) => {
      const el = event.target as HTMLAnchorElement

      if (!el.hasAttributes()) {
        return
      }

      const payload: Record<string, string> = {
        section: 'Global Nav',
        click_type: 'Nav Link',
      }

      for (const attr of el.attributes) {
        if (attr.name.startsWith('data-track-')) {
          const key = attr.name.replace('data-track-', '')
          payload[key] = attr.value
        }
      }

      trackClick(payload)
    }

    const navElement = navRef.current
    if (!navElement) return

    navElement.addEventListener('click', handleNavLinkClick)
    return () => navElement.removeEventListener('click', handleNavLinkClick)
  }, [trackClick])

  const activeLinkCss =
    '[&_li:has(a.active)]:bg-primary-100 [&_li:has(a.active):hover]:bg-primary-200'
  const activeLinkNotPriorityCss =
    '[&:has(a.active-current-path)_li:has(a.active:not(.active-current-path))]:bg-neutral-0' +
    ' ' +
    '[&:has(a.active-current-path)_li:has(a.active:not(.active-current-path)):hover]:bg-neutral-100'

  return (
    <nav
      ref={navRef}
      className={`${isFixed ? 'fixed left-0 right-0 top-0 z-10' : ''}
        bg-neutral-0 border-b-1 flex h-[theme(constants.mainMenu.height)px] items-center border-0 border-solid border-neutral-100 px-24 lg:px-32
  xl:px-56 ${activeLinkCss} ${activeLinkNotPriorityCss}
      `}
      id={id}
      aria-label="global navigation"
    >
      {children}
    </nav>
  )
}
