import { useEffect } from 'react'

import {
  allowedTrackingConfig,
  TRACKING_PREFIX,
  type AllowedTrackingKeys,
  type TrackingProps,
  type TrackingPropsExtended,
} from './constants'

const withTrackPrefix = (key: AllowedTrackingKeys) =>
  `${TRACKING_PREFIX}-${key}`

const closest = (element: HTMLElement, key: string): HTMLElement | null =>
  element.closest(`[${key}]`)

function getTrackableElement(element: HTMLElement | null): HTMLElement | null {
  if (!element) return null

  if (
    (element.hasAttribute(TRACKING_PREFIX) &&
      element.getAttribute(TRACKING_PREFIX) !== 'true') ||
    !element.closest(`[${withTrackPrefix('click_type')}]`)
  ) {
    return null
  }

  return element
    ? closest(element, withTrackPrefix('click_type')) ||
        closest(element, withTrackPrefix('item_name'))
    : null
}

function getMergeableAttributeValue(
  element: HTMLElement,
  attribute: string
): string {
  let value =
    element.getAttribute(`${TRACKING_PREFIX}-${attribute}`) ?? element.innerText
  const parentElement = element.closest(`[${TRACKING_PREFIX}-${attribute}]`)

  if (parentElement) {
    const parentValue =
      parentElement?.getAttribute(`${TRACKING_PREFIX}-${attribute}`) ?? ''

    if (parentValue && value !== parentValue) {
      value = `${parentValue} - ${value}`
    }
  }

  return value
}

export const useEquityTracking = <T extends TrackingProps = TrackingProps>(
  trackEvent: (trackingData: TrackingPropsExtended<T>) => void
) => {
  useEffect(() => {
    const handlePointerUp = (event: PointerEvent | MouseEvent) => {
      // Cancel if left, middle, or right button was clicked
      if (event.button > 2) return

      const el = getTrackableElement(event.target as HTMLElement)

      if (!el) return

      const trackingData = buildTrackingData<T>(el)

      trackEvent(trackingData)
    }

    document.addEventListener('pointerup', handlePointerUp, true)
    return () =>
      document.removeEventListener('pointerup', handlePointerUp, true)
  }, [trackEvent])
}

function buildTrackingData<T>(el: HTMLElement) {
  const trackingData: Record<string, unknown> = {}

  for (const { name, mergeable } of allowedTrackingConfig) {
    const prefixedName = withTrackPrefix(name)
    trackingData[name] = mergeable
      ? getMergeableAttributeValue(el, name)
      : closest(el, prefixedName)?.getAttribute(prefixedName) ?? undefined
  }

  return trackingData as TrackingPropsExtended<T>
}
