import { snakeCase } from 'lodash-es'

export const DATA_TRACK_ATTRIBUTE = 'data-track' as const
export const TRACK_EVENT_ATTRIBUTE = `${DATA_TRACK_ATTRIBUTE}-event` as const
export const TRACK_SECTION_ATTRIBUTE =
  `${DATA_TRACK_ATTRIBUTE}-section` as const
export const TRACK_ITEM_NAME_ATTRIBUTE =
  `${DATA_TRACK_ATTRIBUTE}-item-name` as const
export const TRACK_SUB_SECTION_ATTRIBUTE =
  `${DATA_TRACK_ATTRIBUTE}-sub-section` as const

export function getTrackableElement(element: HTMLElement) {
  return element.closest(`[${TRACK_EVENT_ATTRIBUTE}]`)
}

export function getTrackingDataIfExists(element: HTMLElement) {
  const trackableEl = getTrackableElement(element)
  if (!trackableEl) return

  const section =
    document
      .querySelector(`[${TRACK_SECTION_ATTRIBUTE}]`)
      ?.getAttribute(TRACK_SECTION_ATTRIBUTE) ?? undefined

  const sub_section =
    trackableEl
      .closest(`[${TRACK_SUB_SECTION_ATTRIBUTE}]`)
      ?.getAttribute(`${TRACK_SUB_SECTION_ATTRIBUTE}`) ?? undefined

  const payload: Record<string, unknown> = {}
  for (const attr of trackableEl.attributes) {
    if (
      attr.name.startsWith(DATA_TRACK_ATTRIBUTE) &&
      !(attr.name === TRACK_EVENT_ATTRIBUTE)
    ) {
      const payloadKey = attr.name.replace('data-track-', '')
      payload[snakeCase(payloadKey)] = attr.value
    }
  }

  return {
    click_type: trackableEl.getAttribute(TRACK_EVENT_ATTRIBUTE),
    section,
    sub_section,
    ...payload,
  }
}

export function extractSearchId(search: string): string | undefined {
  const searchParams = new URLSearchParams(search)
  // would be null by default if missing, but for analytics payload undefined is preferred (send nothing)
  return searchParams.get('id') ?? undefined
}
