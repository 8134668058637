import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { parseCssString } from './utils'

// Styles from Type system on figma, use these from now on, challenge if design strays from guidelines:
// https://www.figma.com/file/9p46RPQfnXRph68T9RvTOR/Type-system?node-id=42%3A2

export const H4Css = css`
  font-size: 2rem; // 32px
  line-height: 3rem; // 48px
  font-weight: normal;
`

export const H4CssObject = parseCssString(H4Css.styles)

export const H4 = styled.h4`
  ${H4Css}
`

export const H4StrongCss = css`
  ${H4Css}
  font-weight: 600;
`

export const H4StrongCssObject = parseCssString(H4StrongCss.styles)

export const H4Strong = styled.h4`
  ${H4StrongCss}
`

export const H4SerifCss = css`
  ${H4Css}
  font-family: var(--font-serif);
`

export const H4SerifCssObject = parseCssString(H4SerifCss.styles)

export const H4Serif = styled.h4`
  ${H4SerifCss}
`
