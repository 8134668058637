import cx from 'classnames'

import { GridItemProps } from '../types'

const DescriptionGridItem = ({ label, description }: GridItemProps) => {
  return (
    <div>
      <dt className="small leading-snug text-neutral-600">{label}</dt>
      <dd className="small md:body text-neutral-900">{description}</dd>
    </div>
  )
}
export const ExternalGridDescription = (
  props: { gridItems: GridItemProps[] } & { withVerticalBorder?: boolean }
) => {
  const { withVerticalBorder, gridItems } = props
  return (
    <dl
      className={cx(
        'grid w-full grid-cols-[repeat(auto-fit,minmax(100px,1fr))] gap-16 break-words py-8',
        { 'border-t-1 border-b-1 border-neutral-200': withVerticalBorder }
      )}
    >
      {gridItems.map(({ label, description }) => (
        <DescriptionGridItem
          key={label}
          label={label}
          description={description}
        />
      ))}
    </dl>
  )
}

export function ExternalCardDescription(props: {
  withVerticalBorder?: boolean
  externalContentDetails: GridItemProps[]
}) {
  const { externalContentDetails, withVerticalBorder } = props

  return (
    <ExternalGridDescription
      gridItems={externalContentDetails}
      withVerticalBorder={withVerticalBorder}
    />
  )
}
