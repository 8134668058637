import { useRef, useState, useLayoutEffect, ReactNode } from 'react'

import { ModalControlProps } from './useModal'

export const useScrollableContent = (
  control: ModalControlProps,
  children: ReactNode
) => {
  const scrollableContentRef = useRef<HTMLDivElement>(null)
  const [hasScrollableContent, setHasScrollableContent] = useState(false)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)

  useLayoutEffect(() => {
    const scrollableContentElement = scrollableContentRef.current
    if (scrollableContentElement) {
      const hasScroll =
        scrollableContentElement.scrollHeight >
        scrollableContentElement.clientHeight
      setHasScrollableContent(hasScroll)
    }

    const handleScroll = () => {
      if (scrollableContentElement) {
        const isAtBottom =
          Math.abs(
            scrollableContentElement.scrollHeight -
              scrollableContentElement.scrollTop -
              scrollableContentElement.clientHeight
          ) < 1
        setIsScrolledToBottom(isAtBottom)
      }
    }

    scrollableContentElement?.addEventListener('scroll', handleScroll)

    return () => {
      scrollableContentElement?.removeEventListener('scroll', handleScroll)
    }
  }, [scrollableContentRef, children])

  return { scrollableContentRef, hasScrollableContent, isScrolledToBottom }
}
