import { PropsWithChildren, ReactNode } from 'react'

import { useUserProfile } from '../../../util/hook/use-user-profile'

export const useHasAttribute = (attribute: string) => {
  const {
    userProfileState: { userProfile, status },
  } = useUserProfile()

  const featureEnabled =
    attribute in (userProfile?.attributes ?? {}) &&
    userProfile?.attributes?.[attribute] === true

  const inheritedFeatureEnabled = userProfile?.inheritedAttributes?.some(
    ({ attributes }) => attributes?.[attribute] === true
  )

  return { hasAccess: inheritedFeatureEnabled || featureEnabled, status }
}

export const useHasFeatureFlag = (featureFlagKey: string) => {
  const {
    userProfileState: { userProfile, status },
  } = useUserProfile()

  const featureEnabled: boolean =
    featureFlagKey in (userProfile?.featureFlags ?? {}) &&
    userProfile?.featureFlags?.[featureFlagKey].enabled === true

  return {
    isEnabled: status === 'loaded' ? featureEnabled : undefined,
    status,
  }
}

type LegacyFeatureFlagOptions = { attribute: string; featureFlagKey?: never }
type FeatureFlagOptions = { featureFlagKey: string; attribute?: never }

export const FeatureFlag = (
  props: PropsWithChildren<{ alternative?: ReactNode }> &
    (FeatureFlagOptions | LegacyFeatureFlagOptions)
) => {
  if ('featureFlagKey' in props) {
    return (
      <NewFeatureFlag
        featureFlagKey={props.featureFlagKey as string}
        children={props.children}
        alternative={props.alternative}
      />
    )
  }

  return (
    <LegacyFeatureFlag
      attribute={props.attribute as string}
      children={props.children}
      alternative={props.alternative}
    />
  )
}

const NewFeatureFlag = ({
  featureFlagKey,
  children,
  alternative = null,
}: PropsWithChildren<{ featureFlagKey: string; alternative?: ReactNode }>) => {
  const { isEnabled, status } = useHasFeatureFlag(featureFlagKey)

  if (status === 'loaded') {
    return (isEnabled ? children : alternative) as ReactNode
  }

  if (status === 'error') {
    return alternative
  }

  return null
}

const LegacyFeatureFlag = ({
  attribute,
  children,
  alternative = null,
}: PropsWithChildren<{ attribute: string; alternative?: ReactNode }>) => {
  const { hasAccess, status } = useHasAttribute(attribute)

  if (status === 'loaded') {
    return (hasAccess ? children : alternative) as ReactNode
  }

  if (status === 'error') {
    return alternative
  }

  return null
}
