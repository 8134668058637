import styled from '@emotion/styled'
import { forwardRef, ReactNode } from 'react'
import { omit } from 'remeda'

import { uiTypeSize } from '../../../type-size'

export type LayoutSidebarProps = {
  side?: 'left' | 'right'
  sideWidth?: uiTypeSize
  contentMin?: number // percentage
  gutter?: uiTypeSize
  noStretch?: boolean
  // children should be typed as a tuple [ReactElement, ReactElement], but there seems to be a tsx issue with it
  children: ReactNode
  className?: string
}

export const LayoutSidebar = styled(
  forwardRef<HTMLDivElement, LayoutSidebarProps>((props, ref) => {
    const passedProps = omit(props, [
      'side',
      'sideWidth',
      'contentMin',
      'gutter',
      'noStretch',
      'className',
      'children',
    ])
    return (
      <div
        ref={ref}
        className={props.className}
        data-layout="LayoutSidebar"
        {...passedProps}
      >
        <div>{props.children}</div>
      </div>
    )
  })
)(
  ({
    side = 'left',
    sideWidth,
    contentMin = 50,
    gutter = 'var(--s16)',
    noStretch = false,
  }) => {
    if (contentMin > 100 || contentMin < 0)
      console.warn(
        `contentMin must be between 0 & 100, you set it to ${contentMin}`
      )

    const fixedGutter = gutter === '0' ? '0px' : gutter // For compatibility with calc
    return `
      & > * {
        display: flex;
        flex-wrap: wrap;
        margin: calc(${fixedGutter} / 2 * -1);
        ${noStretch ? 'align-items: flex-start;' : ''}

      }
      & > * > * {
        flex-grow: 1;
        margin: calc(${fixedGutter} / 2);
        ${sideWidth ? `flex-basis: ${sideWidth};` : ''}
      }
      & > * > ${side !== 'left' ? ':first-of-type' : ':last-child'} {
        flex-basis: 0;
        flex-grow: 999;
        min-width: calc(
          ${contentMin}% - ${fixedGutter}
        );
      }
    `
  }
)
LayoutSidebar.displayName = 'LayoutSidebar'
