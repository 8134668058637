import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { parseCssString } from './utils'

// Styles from Type system on figma, use these from now on, challenge if design strays from guidelines:
// https://www.figma.com/file/9p46RPQfnXRph68T9RvTOR/Type-system?node-id=42%3A2

export const H5Css = css`
  font-size: 1.5rem; // 24px
  line-height: 2rem; // 32px
  font-weight: normal;
`

export const H5CssObject = parseCssString(H5Css.styles)

export const H5 = styled.h5`
  ${H5Css}
`

export const H5StrongCss = css`
  ${H5Css}
  font-weight: 600;
`

export const H5StrongCssObject = parseCssString(H5StrongCss.styles)

export const H5Strong = styled.h5`
  ${H5StrongCss}
`

export const H5SerifCss = css`
  ${H5Css}
  font-family: var(--font-serif);
`

export const H5SerifCssObject = parseCssString(H5SerifCss.styles)

export const H5Serif = styled.h5`
  ${H5SerifCss}
`
