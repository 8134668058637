import { Modal, useModal } from '../../../../equity/web/organisms/modal'

export const TermsAndConditionsConfirmDeclineModal = (props: {
  modal: ReturnType<typeof useModal>
  onConfirm: () => void
  onCancel: () => void
}) => {
  const { modal, onConfirm, onCancel } = props

  return (
    <Modal
      control={modal}
      onClose={onCancel}
      title="Are you sure you wish to decline?"
      actions={[
        {
          onClick: onConfirm,
          children: 'Yes',
          variant: 'secondary',
          'data-testid': 'terms-confirm-decline',
        },
        {
          onClick: onCancel,
          children: 'No',
          variant: 'primary',
        },
      ]}
      transparentBackdrop
    >
      <p className="body text-neutral-600">
        If you do not agree to accept the Terms of Use, you will be unable to
        access CAIS. Are you sure you do not want to accept the Terms of Use?
      </p>
    </Modal>
  )
}
