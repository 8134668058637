import { type MenuRootProps } from '../types'

import { Menu } from './menu'

export function MenuRoot(props: MenuRootProps) {
  const {
    handleNavItemClick,
    id,
    menuButton: MenuButton,
    menuContents,
    navItems,
    ...otherProps
  } = props

  if (id === 'profile-menu') {
    return (
      <div className={props.classNames.list} id={id}>
        {menuContents?.[0]}
      </div>
    )
  }

  return navItems ? (
    <ul className={props.classNames.list} id={id}>
      {navItems.map((navItem, index) => {
        const menuContent = menuContents && menuContents[index]
        if ('menu' in navItem) {
          const menuId = `${navItem.menu.id}-${otherProps.type}`
          return (
            <Menu
              key={navItem.name}
              {...otherProps}
              isMenuOpen={props.openMenu === menuId}
              menuButton={<MenuButton name={navItem.name} />}
              menuId={menuId}
              menuContents={menuContent}
              trackMenuLabel={navItem.name}
            />
          )
        }
        return (
          <li key={navItem.href} data-link-id={navItem.id}>
            {menuContent}
          </li>
        )
      })}
    </ul>
  ) : null
}
