import { Fallback } from '@cais-group/approved/ui/fallback'
import { Icon } from '@cais-group/equity/atoms/icon'
import { colors } from '@cais-group/equity/particles/colors'

export const CarouselSplash = ({ children }: { children: React.ReactNode }) => (
  <div className="py-88 flex flex-col items-center justify-center bg-neutral-100 px-56">
    {children}
  </div>
)

const NoResults = ({ title = 'No results found' }) => (
  <CarouselSplash>
    <Fallback
      title={title}
      backgroundColor={colors['eq-color-neutral-100']}
      icon={() => (
        <Icon type="Info" color="eq-color-neutral-600" size="small" />
      )}
    />
  </CarouselSplash>
)

CarouselSplash.NoResults = NoResults

const Error = () => (
  <CarouselSplash>
    <Fallback
      title="An error occurred when loading this content"
      backgroundColor={colors['eq-color-neutral-100']}
      icon={() => <Icon type="Info" color="eq-color-error-600" size="small" />}
    />
  </CarouselSplash>
)

CarouselSplash.Error = Error
