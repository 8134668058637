import { useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { defaultColors } from '../../../../equity/particles/colors'
import { UserProfileV1 } from '../../../../tmp/generated/access-manager-api'
import { logWarning } from '../../../util/logging'
import {
  getExperienceForFirm,
  FirmExperienceV1,
  FirmExperienceV1BrandingTheme,
} from '../../experience-manager'

export const setBrowserTheme = ({
  userProfile,
  experience,
}: {
  userProfile: UserProfileV1 | null
  experience: FirmExperienceV1 | null
}) => {
  const themeAttribute = userProfile?.inheritedAttributes?.find(
    (attribute) => attribute.attributes?.['THEME']
  )?.attributes?.['THEME']

  if (themeAttribute) {
    document.body.setAttribute('data-theme', themeAttribute as string)
    return
  } else {
    document.body.removeAttribute('data-theme')
  }

  if (
    experience?.branding?.theme &&
    !!Object.keys(experience.branding.theme).length
  ) {
    setBrowserCSSVariablesFromTheme(experience.branding.theme)
  } else {
    setBrowserCSSVariablesFromTheme(defaultColors)
  }
}

const setBrowserCSSVariablesFromTheme = (
  theme: FirmExperienceV1BrandingTheme
) => {
  const hexToRgb = (hex: string) => {
    const result = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    if (!result || result?.length !== 4) {
      // throw new Error(`Failed to parse hex to RGB`)
      return ''
    }

    const rgb = result.slice(1).map((x) => parseInt(x, 16))

    return rgb.toString()
  }

  for (const keyName in theme) {
    const colorObject = theme[keyName] as Record<string, string>
    const root = document.documentElement
    for (const secondKeyName in colorObject) {
      const hexValue = colorObject[secondKeyName]

      // Construct the custom property name
      const customPropertyName = `--colors-${keyName}-${secondKeyName}`

      root?.style.setProperty(customPropertyName, hexToRgb(hexValue))
    }
  }
}

export const FIRM_BROWSER_EXPERIENCE_QUERY_KEY = 'FIRM_EXPERIENCE'

export const useGetFirmBrowserExperience = (
  userProfile: UserProfileV1 | null
) => {
  const queryClient = useQueryClient()

  // Attempt to get the initial data from local storage
  const initialData = () => {
    const storedData = sessionStorage.getItem(FIRM_BROWSER_EXPERIENCE_QUERY_KEY)
    return storedData ? JSON.parse(storedData) : undefined
  }

  const query = useQuery<FirmExperienceV1>({
    queryKey: FIRM_BROWSER_EXPERIENCE_QUERY_KEY,
    queryFn: () => getExperienceForFirm(userProfile?.firm?.id || ''),
    initialData: initialData(),
    enabled: !!userProfile?.firm?.id,
    onSuccess: (data) => {
      // Persist the data to local storage on success
      sessionStorage.setItem(
        FIRM_BROWSER_EXPERIENCE_QUERY_KEY,
        JSON.stringify(data)
      )
    },
    onError: (err) => {
      logWarning({
        message: 'Failed to load firm experience',
        error: err,
      })
    },
  })

  // Logic to bust cache when the user changes
  useEffect(() => {
    sessionStorage.removeItem(FIRM_BROWSER_EXPERIENCE_QUERY_KEY)
    queryClient.invalidateQueries(FIRM_BROWSER_EXPERIENCE_QUERY_KEY)
  }, [userProfile, queryClient])

  return query
}
