import styled from '@emotion/styled'

import { BREAKPOINTS } from '../../../../../legacy-shared/ui/design-tokens'
import { FooterDefault } from '../../../../../legacy-shared/ui/footer/default'
import { LayoutCenter } from '../../../../../legacy-shared/ui/layout'
import { H4, BodySmall } from '../../../typography'

const OuterContainer = styled.div`
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
`

const Container = styled.div`
  background-color: rgb(var(--colors-neutral-0));
  padding: var(--s144) var(--s16) var(--s144) var(--s16);
  @media screen and (min-width: ${BREAKPOINTS.breakpointXl}) {
    padding: var(--s144);
  }
`

const Description = styled(BodySmall)`
  margin-top: var(--s24);
`

const CircleBg = styled.div`
  background-color: rgb(var(--colors-warning-600));
  height: 186px;
  width: calc(186px * 2);
  border-top-left-radius: calc(186px * 2);
  border-top-right-radius: calc(186px * 2);
  position: relative;
  left: 65%;
`

const StickToBottom = styled.div`
  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) {
    position: absolute;
    bottom: 0;
  }
  width: 100%;
`
const StyledTypographyH4 = styled(H4)`
  @media screen and (min-width: ${BREAKPOINTS.breakpointMd}) {
    line-height: calc(1rem * 4);
  }
`

interface ErrorGeneralProps {
  title?: string
  description?: string | JSX.Element
}

export const ErrorGeneral = ({
  title = 'Oops. Something went wrong.',
  description = (
    <>
      We are working on getting this fixed as soon as we can. You may be able to{' '}
      <a href={globalThis?.location?.href ?? '/'}>try again.</a>
    </>
  ),
}: ErrorGeneralProps) => {
  return (
    <OuterContainer>
      <Container>
        <LayoutCenter centerText={true}>
          <StyledTypographyH4>{title}</StyledTypographyH4>
          <Description as="div">{description}</Description>
        </LayoutCenter>
      </Container>
      <StickToBottom>
        <CircleBg></CircleBg>
        <FooterDefault positionRelative />
      </StickToBottom>
    </OuterContainer>
  )
}
