import { Shadows } from '@mui/material/styles/shadows'

export const shadows = [
  'none',
  '0px 0px 4px 0px #211C2114',
  '0px 4px 8px 0px #211C210A',
  '0px 4px 16px 0px #0000000A',
  '0px 16px 24px 0px #00000014',
  '0px 0px 32px 0px #0000001F',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
  '',
] as Shadows

export const shadow = (value: string | number) =>
  shadows.find((shdw) => shdw.split(' ')[2]?.includes(`${value}`))
