import cx from 'classnames'
import { DeepPartial, StrictExtract } from 'ts-essentials'

import {
  AllColor,
  EquitySemanticColor,
  allColors,
} from '../../../../particles/colors'
import { BrandIconType, IconType } from '../../../../particles/icons'
import { Icon, BrandIcon, isBrandIconType } from '../../icon'
import { Tooltip } from '../../tooltip'

export type StatusTagVariant = 'dark' | 'regular' | 'light' | 'ghost'

export type SemanticColor = StrictExtract<
  EquitySemanticColor,
  'neutral' | 'primary' | 'accent' | 'success' | 'error' | 'warning'
>
interface AttributeColor {
  borderColor: AllColor
  backgroundColor: AllColor
  textColor: AllColor
}

const getAttributeColors = (
  color: SemanticColor,
  variant: StatusTagVariant
): AttributeColor => {
  const defaultColors: Record<StatusTagVariant, AttributeColor> = {
    dark: {
      borderColor: `eq-color-${color}-800`,
      backgroundColor: `eq-color-${color}-800`,
      textColor: `eq-color-${color}-800-c`,
    },
    regular: {
      borderColor: `eq-color-${color}-600`,
      backgroundColor: `eq-color-${color}-600`,
      textColor: `eq-color-${color}-600-c`,
    },
    light: {
      borderColor: `eq-color-${color}-200`,
      backgroundColor: `eq-color-${color}-100`,
      textColor: `eq-color-${color}-600`,
    },
    ghost: {
      borderColor: `eq-color-${color}-600`,
      backgroundColor: `eq-color-${color}-600-c`,
      textColor: `eq-color-${color}-600`,
    },
  }
  const specificCases: DeepPartial<
    Record<`${SemanticColor}-${StatusTagVariant}`, AttributeColor>
  > = {
    'warning-light': {
      borderColor: `eq-color-warning-400`,
      textColor: `eq-color-warning-800`,
    },
    'warning-ghost': {
      backgroundColor: `eq-color-warning-800-c`,
      textColor: `eq-color-warning-800`,
    },
  }
  return {
    ...defaultColors[variant],
    ...(specificCases[`${color}-${variant}`] ?? {}),
  }
}

type CommonProps = {
  /** The text to be displayed */
  children: string
  /** Optional property to set which variant to be used */
  variant?: StatusTagVariant
  /** Optional property to allow using the whole container width */
  grow?: boolean
  /** Optional property for testing purpose */
  'data-testid'?: string
}

export type StatusTagProps = CommonProps & {
  /** Optional equity semantic color */
  color?: SemanticColor
  /** Optional Icon shown before the text */
  startAdornment?: IconType
  /** Optional Icon shown after the text */
  endAdornment?: IconType
  /** Text for tooltip */
  endAdornmentTooltip?: string
}

/**
 * A status tag is a read-only label that appears beside an item to represent the status or metadata for that area.
 */
export const StatusTag = (props: StatusTagProps) => (
  <InternalStatusTag {...props} />
)

export type BrandedTagProps = CommonProps & {
  /** Optional Icon shown before the text */
  startAdornment?: BrandIconType
}

/**
 * A branded status tag is similar to a status tag, but it uses brand icons instead of regular icons.
 * It has mostly the same properties as the regular status tag,
 * except it only allows having a start adornment (no end adronment),
 * and no color property (it is always primary so it goes well with the branded icon colors).
 *
 * The start adornment is optional to allow for a more flexible usage (e.g. conditional icon rendering),
 * but if you don't need it, you can just use the regular status tag.
 */
export const BrandedStatusTag = (props: BrandedTagProps) => (
  <InternalStatusTag {...props} color="primary" />
)

type InternalStatusTagProps = {
  children: string
  variant?: StatusTagVariant
  color?: SemanticColor
  grow?: boolean
  'data-testid'?: string
  startAdornment?: IconType | BrandIconType
  endAdornment?: IconType
  endAdornmentTooltip?: string
}

/**
 * This component is only for internal use, it is not meant to be used from outside this file as too much persmisive.
 * Either use the StatusTag or BrandedStatusTag components.
 */
const InternalStatusTag = ({
  children,
  variant = 'regular',
  color = 'neutral',
  grow = false,
  startAdornment,
  endAdornment,
  endAdornmentTooltip,
  'data-testid': testId,
}: InternalStatusTagProps) => {
  const colors = getAttributeColors(color, variant)

  return (
    <span
      data-testid={testId}
      className={cx(
        'small-strong flex h-24 items-center justify-center rounded-full border border-solid px-8',
        { 'max-w-fit': !grow }
      )}
      style={{
        backgroundColor: allColors[colors.backgroundColor],
        borderColor: allColors[colors.borderColor],
        color: allColors[colors.textColor],
      }}
    >
      {startAdornment ? (
        isBrandIconType(startAdornment) ? (
          <BrandIcon size="small" type={startAdornment} />
        ) : (
          <Icon size="small" type={startAdornment} color={colors.textColor} />
        )
      ) : null}
      <span className="overflow-hidden text-ellipsis whitespace-nowrap px-8">
        {children}
      </span>
      {endAdornment ? (
        endAdornmentTooltip ? (
          <Tooltip title={endAdornmentTooltip} placement="top">
            <Icon size="small" type={endAdornment} color={colors.textColor} />
          </Tooltip>
        ) : (
          <Icon size="small" type={endAdornment} color={colors.textColor} />
        )
      ) : null}
    </span>
  )
}
