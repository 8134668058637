import { maxBy } from 'lodash-es'

import {
  UserProfileV1,
  UserTermsAndConditionsV1,
} from '../../../../tmp/generated/access-manager-api'
import { logWarning } from '../../logging/lib/logging'

function ensure<T>(
  argument: T | undefined | null,
  message = 'This value was promised to be there.'
): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message)
  }

  return argument
}
export function getTermsAndConditionsToAccept(
  termsAndConditions: UserProfileV1['termsAndConditions'],
  expectedLatestVersion: number,
  termsAndConditionsType: string
): UserTermsAndConditionsV1 | null {
  const relevantTypeOfTermsAndConditions =
    termsAndConditions?.filter((it) => it.type === termsAndConditionsType) || []
  const latestTermsAndCondition = ensure(
    maxBy(relevantTypeOfTermsAndConditions, (it: UserTermsAndConditionsV1) => {
      return it.version
    }),
    'latestTermsAndCondition was not found; this should never happen'
  )

  if (latestTermsAndCondition.version !== expectedLatestVersion) {
    logWarning({
      message:
        'The version of the terms and conditions is not the expected one',
    })
  }

  if (latestTermsAndCondition.acceptedAt) {
    return null
  } else {
    return latestTermsAndCondition
  }
}
