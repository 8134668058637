import classNames from 'classnames'
import React from 'react'

import { useCloseOnEsc } from '../../../../util/hook/use-close-on-esc'
import { EnvType, Menu } from '../types'

import { NavLink } from './nav-link'

export const MenuItems = ({
  menu,
  handleCloseMenu,
  openMenu,
  trackMenuLabel,
  position = '-left-24',
  env,
}: {
  menu: Menu
  handleCloseMenu: () => void
  openMenu: string | null
  trackMenuLabel?: string
  position?: string
  env: EnvType
}) => {
  const focusIndex = React.useRef<number>(0)
  const menuEl =
    React.useRef<HTMLUListElement>() as React.MutableRefObject<HTMLUListElement>
  const menuItems = React.useRef() as React.MutableRefObject<
    HTMLCollectionOf<HTMLAnchorElement>
  >
  const isMenuOpen = openMenu === menu.id
  useOnClickOutside({
    ref: menuEl,
    callback: handleCloseMenu,
  })

  useCloseOnEsc(() => {
    if (menuItems.current) {
      handleCloseMenu()
      return
    }
  })

  React.useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (!menuItems.current) return
      if (event.key === 'Tab') {
        // Close the menu if it's opened and focused
        // This is great for usability as it ensures
        // users don't have to move through every item with tab
        // to exit the menu
        if (menuItems.current) {
          event.preventDefault()
          handleCloseMenu()
          return
        }
      }

      if (event.key === 'ArrowDown') {
        event.preventDefault()
        // Focus first item, if we where at the last item
        if (focusIndex.current === menuItems.current.length - 1) {
          focusIndex.current = 0
          menuItems.current[0].focus()
        } else {
          // Focus the next item
          focusIndex.current++
          menuItems.current[focusIndex.current].focus()
        }
      }

      if (event.key === 'ArrowUp') {
        event.preventDefault()
        // Focus the last item if we are at the first item
        if (focusIndex.current === 0) {
          menuItems.current[menuItems.current.length - 1].focus()
          focusIndex.current = menuItems.current.length - 1
        } else {
          // Focus previous item
          focusIndex.current--
          menuItems.current[focusIndex.current].focus()
        }
      }
    }

    window.addEventListener('keydown', handler)

    return () => window.removeEventListener('keydown', handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (isMenuOpen) {
      menuItems.current = menuEl.current.getElementsByTagName('a')

      // Show the menu
      menuEl.current.classList.remove('hidden')
      menuEl.current.classList.add('block')

      // Focus the first focusable link
      menuItems.current[0]?.focus()
    } else {
      // @ts-ignore
      // Set the menuItems to null so we know the menu is not opened
      // and focused
      menuItems.current = null

      // Hide the menu
      menuEl.current.classList.remove('block')
      menuEl.current.classList.add('hidden')

      // Reset focusIndex to first element
      // so when the menu opens again we
      // can focus it
      focusIndex.current = 0
    }
  }, [isMenuOpen])

  return (
    <div className={`absolute ${position} z-30 pt-8`}>
      <ul
        className={classNames(
          { 'group-hover:block': !openMenu },
          'shadow-5 bg-neutral-0 hidden min-w-[264px] list-none divide-y divide-solid divide-neutral-200 p-16'
        )}
        role="menu"
        id="dropdown-menu"
        data-testid="dropdown-menu"
        ref={menuEl}
        aria-hidden={!isMenuOpen}
      >
        {menu.groups.map((group, index) => (
          <li
            key={`group-${index}`}
            className="py-16 first-of-type:pt-0 last-of-type:pb-0"
            role="presentation"
          >
            {group.name && (
              <p
                className="caption w-fit p-8 uppercase text-neutral-600"
                id="group-name"
              >
                {group.name}
              </p>
            )}
            <ul className="list-none" role="group" aria-labelledby="group-name">
              {group.items.map((item, index) => (
                <li
                  key={item.href}
                  data-pendo={`${menu.id}-${item.name
                    .replace(/\s+/g, '-')
                    .toLowerCase()}-item`}
                  data-link-id={item.id}
                >
                  <NavLink
                    onClick={handleCloseMenu}
                    onLogout={item.onClick}
                    data-testid={item.testId ?? `item${index}`}
                    to={item.href}
                    tabIndex={-1} // The menu items shouldn't be tabable
                    className="[&.active]:bg-primary-100 small block cursor-pointer p-8 text-neutral-900 outline-none transition-colors hover:bg-neutral-100 focus-visible:bg-neutral-100 focus-visible:ring"
                    urlType={item.urlType}
                    data-track-item_name={
                      trackMenuLabel
                        ? `${trackMenuLabel} - ${item.name}`
                        : item.name
                    }
                    env={env}
                  >
                    {item.name}
                  </NavLink>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  )
}

/**
 *
 * Recreated this function as it's slightly different from the one already existing.
 * Main differences:
 * We check if the focus was within the target before we attempt to close it
 * We don't use the focusin event listener
 *
 * TODO: Modify original useOnClickOutside hook
 */
const targetNotContained = <T extends Element>(
  ref: React.MutableRefObject<T>,
  target: T
) => !ref.current?.contains(target)

export const useOnClickOutside = <T extends Element>(options: {
  callback: () => void
  ref: React.MutableRefObject<T>
}) => {
  const handleMouseDown = React.useCallback(
    (event: MouseEvent) => {
      const target = event.target as Element

      if (
        targetNotContained(options.ref, target) &&
        !targetNotContained(options.ref, document.activeElement as Element)
      ) {
        event.preventDefault()
        options.callback()
      }
    },
    [options]
  )

  React.useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [handleMouseDown])
}
