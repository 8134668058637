import styled from '@emotion/styled'

import { Footer as EquityFooter } from '../../../../../equity/web/molecules/footer/lib/footer'
import { H5, BodySmallCss } from '../../../../../legacy-approved/ui/typography'
import { LayoutBox, LayoutCluster, LayoutStack } from '../../../layout'
import { LogoCais } from '../../../logos'

const DEFAULT_BASE_URL = 'https://www.caisgroup.com/'

const EqualSizeCluster = styled(LayoutCluster)`
  & > * > * {
    flex-grow: 1;
  }
`

type FooterProps = {
  $noPaddingTop?: boolean
  fixed?: boolean
}
const Footer = styled(LayoutStack)<FooterProps>`
  --standard-footer-padding: var(--s56);
  background-color: rgb(var(--colors-neutral-900));
  color: rgb(var(--colors-neutral-500));
  ${({ fixed }) =>
    fixed
      ? `
    position: absolute;
    bottom: 0;
    width: 100%;`
      : null};
  padding-top: ${({ $noPaddingTop }) =>
    $noPaddingTop ? '0' : 'var(--standard-footer-padding)'};
`

const Top = styled(LayoutBox)`
  padding: var(--standard-footer-padding);
`

const Middle = styled('div')`
  padding: var(--standard-footer-padding);
  padding-top: 0;
  p {
    ${BodySmallCss}
  }
`

const Strapline = styled(H5)`
  color: rgb(var(--colors-neutral-0));
  @media screen and (min-width: 700px) {
    margin-left: 0;
  }
`

const CaisHomeLink = styled.a`
  &,
  * {
    color: rgb(var(--colors-neutral-0));
  }
`

export type FooterDefaultProps = {
  baseUrl?: string
  className?: string
  children?: React.ReactNode
  positionRelative?: boolean
  withStrapline?: boolean
  fixed?: boolean
}

export const FooterDefault = styled(
  ({
    baseUrl = DEFAULT_BASE_URL,
    className,
    children,
    withStrapline = true,
    fixed = false,
  }: FooterDefaultProps) => {
    return (
      <Footer
        role="contentinfo"
        as="footer"
        fixed={fixed}
        $noPaddingTop={!children && !withStrapline}
        className={className}
      >
        {withStrapline ? (
          <Top data-testid="footer-top-content">
            <EqualSizeCluster justify="flex-start">
              <CaisHomeLink href={`${baseUrl}`} aria-label="CAIS home">
                <LogoCais aria-hidden="true" />
              </CaisHomeLink>
              <Strapline>
                Transforming the world of alternative investing.
              </Strapline>
              <span />
            </EqualSizeCluster>
          </Top>
        ) : null}
        {children && <Middle>{children}</Middle>}
        {/* Temporary as we migrate the above content onto Equity - design pending */}
        <EquityFooter />
      </Footer>
    )
  }
)(
  (props: FooterDefaultProps) => `
      position: ${props.positionRelative ? 'relative' : 'absolute'};
      left: 0;
      width: 100%;
    `
)
