import { flow } from 'lodash-es'

import { sortContentData } from '@cais-group/shared/util/contentful/content-helpers'
import { ContentDataType } from '@cais-group/shared/util/contentful/types'

import { excludeBySysId } from './exclude-by-sys-id'

export const excludeFeaturedItemAndExtract = flow(
  excludeBySysId<ContentDataType>
)

export const excludeFeaturedItemSortAndExtract = flow(
  excludeBySysId<ContentDataType>,
  sortContentData
)
