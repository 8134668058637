import type { EventStatusKey } from '../../../types'
import { getPwsDomain } from '../get-pws-domain'

export function callToActionEventsLink(
  status?: EventStatusKey,
  eventLink?: string | null
) {
  const publicEventsPage = `${getPwsDomain()}/events/cais-live`
  const eventsPage = `${getPwsDomain()}/events`
  if (!status && !eventLink) {
    return eventsPage
  }

  switch (status) {
    case 'upcoming':
    case 'full':
      return publicEventsPage
    default:
      return eventLink || publicEventsPage
  }
}
