export const breakpoints = {
  /** @deprecated
   * The `sm` breakpoint is defined so it can be used in the legacy code, which then allows us to delete the old design tokens.
   * New Equity designs does not use this breakpoint, thus why it does not show up in the docs
   */
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1660,
} as const

export const breakpointsPx = {
  /** @deprecated
   * The `sm` breakpoint is defined so it can be used in the legacy code, which then allows us to delete the old design tokens.
   * New Equity designs does not use this breakpoint, thus why it does not show up in the docs
   */
  sm: `${breakpoints.sm}px`,
  md: `${breakpoints.md}px`,
  lg: `${breakpoints.lg}px`,
  xl: `${breakpoints.xl}px`,
} as const

/**
 * This value represents the smallest width in pixels which is supported by Equity
 * and used in the Figma designs
 */
export const smallestScreenWidth = 360
