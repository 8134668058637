import { ReactNode, Children, isValidElement, ReactElement } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'

import { PageHeader as Header } from '../../../molecules/page-header'
import { NavigationTabs as Tabs } from '../../../molecules/tabs'

import { BaseModal, ModalVariants } from './base-modal'
import { ModalActionType } from './modal-actions'
import { ModalControlProps } from './useModal'

/** Properties for the PageOverlayModal component. */
export type PageOverlayModalProps = {
  /** The optional actions to be rendered within the modal. */
  actions?: ModalActionType[]
  /** The children to be rendered within the modal, being an array of specific React elements or null. */
  children: Array<
    | ReactElement<typeof Header>
    | ReactElement<typeof Tabs>
    | ReactElement<typeof Sidebar>
    | ReactElement<typeof Content>
    | null
  >
  /** The control props for managing modal state. See useModal documentation */
  control: ModalControlProps
}

/**
 * Properties for the Link component to be place in the Sidebar.
 */
type LinkProps = NavLinkProps & {
  /** A string that provides a label to the link for accessibility purposes. */
  ariaLabel: string
  /** An optional string for specifying a test identifier. */
  testId?: string
  /** The content to be rendered within the link. */
  children: ReactNode
}

const Link = ({ to, ariaLabel, testId, children }: LinkProps) => (
  <NavLink
    to={to}
    aria-label={ariaLabel}
    className={({ isActive }) =>
      isActive
        ? 'text-primary-600'
        : 'hover:text-primary-600 active:text-primary-600 text-neutral-900'
    }
    data-testid={testId}
  >
    {children}
  </NavLink>
)

/**
 * Sidebar's children must be <Link /> and <Protected />.
 */
type SidebarChildren =
  | null
  | (ReactElement<typeof Link> & {
      type: {
        displayName: 'Protected'
      }
    })

type SidebarProps = {
  children?: SidebarChildren | Array<SidebarChildren>
}

const Sidebar = ({ children }: SidebarProps) => (
  <div className="flex flex-col gap-y-16">{children}</div>
)

Sidebar.Link = Link

type ContentProps = {
  children: ReactNode | string
}

// eslint-disable-next-line react/jsx-no-useless-fragment
const Content = ({ children }: ContentProps) => <>{children}</>

/**
 * The PageOverlayModal component serves as a container that overlays on the page
 * and can host different kinds of child components like Header, Tabs, Sidebar, and Content.
 * Each child component is placed in its designated area within the modal.
 *
 * @function PageOverlayModal
 * @param {PageOverlayModalProps} props - The properties for the component.
 * @param {ModalControlProps} props.control - The control props for managing modal state.
 * @param {Array} props.children - The array of child elements to be rendered within the modal.
 * @returns {JSX.Element} The rendered PageOverlayModal component.
 */
const PageOverlayModal = ({ control, children }: PageOverlayModalProps) => {
  let header = null
  let tabs = null
  let sidebar = null
  let content = null

  Children.forEach(children, (child) => {
    if (!isValidElement(child)) return

    switch (child.type) {
      case Header:
        header = child
        return
      case Tabs:
        tabs = child
        return
      case Sidebar:
        sidebar = child
        return
      case Content:
        content = child
        return
    }
  })

  return (
    <BaseModal control={control} variant={ModalVariants.Large}>
      <div className="relative h-full w-full">
        <div className="absolute top-0 z-10 w-full">{header}</div>
        <div className="absolute bottom-0 left-0 right-0 top-[128px] flex flex-col overflow-auto">
          {tabs && (
            <div className="flex w-full border-b border-solid border-neutral-200 pl-[384px] max-lg:justify-center max-lg:pl-0">
              {tabs}
            </div>
          )}
          <div className="flex items-start gap-32 px-24 md:px-32 lg:px-56">
            {sidebar && (
              <aside className="sticky top-0 shrink-0 py-32 max-lg:hidden lg:w-[191px] xl:w-[287px]">
                {sidebar}
              </aside>
            )}
            <div className="grow py-32">{content}</div>
          </div>
        </div>
      </div>
    </BaseModal>
  )
}

PageOverlayModal.Header = Header
PageOverlayModal.Tabs = Tabs
PageOverlayModal.Content = Content
PageOverlayModal.Sidebar = Sidebar

export { PageOverlayModal }
