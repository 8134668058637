import { enabledByAccessControl } from '../../../util/entitled-nav-links'

import { LOGOUT_ACTION } from './constants'
import { EnabledAccessControls, Menu, NavItemLink } from './types'

const logoutNavItem: NavItemLink = {
  testId: 'profile-logout-button',
  name: 'Log Out',
  href: LOGOUT_ACTION,
}

export const getProfileMenu = (
  profileMenuData: NavItemLink[],
  enabledAccessControls: EnabledAccessControls,
  onLogout?: () => void
): Menu => {
  const navItems: NavItemLink[] = [
    ...profileMenuData,
    {
      ...(onLogout ? { ...logoutNavItem, onClick: onLogout } : logoutNavItem),
    },
  ]

  return {
    id: 'profile-menu',
    groups: [
      {
        items: navItems.filter((navItem) => {
          return enabledByAccessControl(navItem, enabledAccessControls)
        }),
      },
    ],
  }
}
