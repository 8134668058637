import { useMediaQuery, useTheme } from '@mui/material'
import cx from 'classnames'

import { IconButton } from '../../../../../equity/web/atoms/icon-button'
import type { LightboxProps } from '../lightbox'

export const NonExpandedNode = ({
  children,
  control,
  title,
}: LightboxProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  if (!children) {
    return null
  }
  return (
    <div className="flex flex-col items-center space-y-8">
      <div
        className={cx('flex h-fit w-full items-center', {
          'justify-between': title,
          'justify-end': !title,
          '[&>button]:!hidden': isMobile,
        })}
      >
        {title ? title : null}
        <IconButton
          onClick={control.openLightbox}
          iconType="Expand"
          size="small"
          color="neutral"
          aria-label="Expand image"
        />
      </div>
      <div
        onClick={control.openLightbox}
        className={cx('w-full', {
          'cursor-auto': isMobile,
          ' cursor-zoom-in': !isMobile,
        })}
      >
        {children}
      </div>
    </div>
  )
}
