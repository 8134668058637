export const colors = {
  brand: {
    '100': '#f2f7fc',
    '100-c': '#211c21',
    '200': '#c2dbf1',
    '200-c': '#211c21',
    '300': '#91bee6',
    '300-c': '#211c21',
    '400': '#61a1dc',
    '400-c': '#211c21',
    '500': '#3084d1',
    '500-c': '#ffffff',
    '600': '#0067c6',
    '600-c': '#ffffff',
    '700': '#0053a0',
    '700-c': '#ffffff',
    '800': '#00407b',
    '800-c': '#ffffff',
    '900': '#002c55',
    '900-c': '#ffffff',
  },
  primary: {
    '100': '#f2f7fc',
    '100-c': '#211c21',
    '200': '#c2dbf1',
    '200-c': '#211c21',
    '300': '#91bee6',
    '300-c': '#211c21',
    '400': '#61a1dc',
    '400-c': '#211c21',
    '500': '#3084d1',
    '500-c': '#ffffff',
    '600': '#0067c6',
    '600-c': '#ffffff',
    '700': '#0053a0',
    '700-c': '#ffffff',
    '800': '#00407b',
    '800-c': '#ffffff',
    '900': '#002c55',
    '900-c': '#ffffff',
  },
  accent: {
    '100': '#f5f5f8',
    '100-c': '#211c21',
    '200': '#eae8fc',
    '200-c': '#211c21',
    '300': '#968df1',
    '300-c': '#211c21',
    '400': '#4132e6',
    '400-c': '#ffffff',
    '500': '#1c1291',
    '500-c': '#ffffff',
    '600': '#100a52',
    '600-c': '#ffffff',
    '700': '#0d0842',
    '700-c': '#ffffff',
    '800': '#0a0631',
    '800-c': '#ffffff',
    '900': '#060421',
    '900-c': '#ffffff',
  },
  success: {
    '100': '#f7fbfa',
    '100-c': '#211c21',
    '200': '#c4ddd9',
    '200-c': '#211c21',
    '300': '#9dc6c0',
    '300-c': '#211c21',
    '400': '#77b0a7',
    '400-c': '#211c21',
    '500': '#519a8f',
    '500-c': '#211c21',
    '600': '#2b8476',
    '600-c': '#ffffff',
    '700': '#236c61',
    '700-c': '#ffffff',
    '800': '#1c544c',
    '800-c': '#ffffff',
    '900': '#143d36',
    '900-c': '#ffffff',
  },
  warning: {
    '100': '#fff8ef',
    '100-c': '#211c21',
    '200': '#fff1df',
    '200-c': '#211c21',
    '300': '#ffe3bf',
    '300-c': '#211c21',
    '400': '#fed59f',
    '400-c': '#211c21',
    '500': '#fec77f',
    '500-c': '#211c21',
    '600': '#feb95f',
    '600-c': '#211c21',
    '700': '#cb944c',
    '700-c': '#211c21',
    '800': '#946A33',
    '800-c': '#ffffff',
    '900': '#664a26',
    '900-c': '#ffffff',
  },
  error: {
    '100': '#fef8f7',
    '100-c': '#211c21',
    '200': '#f3cac5',
    '200-c': '#211c21',
    '300': '#eba99f',
    '300-c': '#211c21',
    '400': '#e3877a',
    '400-c': '#211c21',
    '500': '#db6554',
    '500-c': '#211c21',
    '600': '#d3432f',
    '600-c': '#ffffff',
    '700': '#ad3727',
    '700-c': '#ffffff',
    '800': '#872b1e',
    '800-c': '#ffffff',
    '900': '#611f16',
    '900-c': '#ffffff',
  },
  neutral: {
    '100': '#f6f6f6',
    '100-c': '#211c21',
    '200': '#e3e3e3',
    '200-c': '#211c21',
    '300': '#c7c6c7',
    '300-c': '#211c21',
    '400': '#b5b3b5',
    '400-c': '#211c21',
    '500': '#8f8c8f',
    '500-c': '#211c21',
    '600': '#6a666a',
    '600-c': '#ffffff',
    '700': '#454045',
    '700-c': '#ffffff',
    '800': '#2e2a2e',
    '800-c': '#ffffff',
    '900': '#211c21',
    '900-c': '#ffffff',
    '0': '#ffffff',
    '0-c': '#211c21',
  },
  datavis: {
    '1': '#00407b',
    '1-c': '#ffffff',
    '2': '#8cade9',
    '2-c': '#211c21',
    '3': '#ffc170',
    '3-c': '#211c21',
    '4': '#327174',
    '4-c': '#ffffff',
    '5': '#96dbc8',
    '5-c': '#211c21',
    '6': '#bb530a',
    '6-c': '#ffffff',
    '7': '#65162c',
    '7-c': '#ffffff',
    '8': '#c0bbf7',
    '8-c': '#211c21',
    '9': '#595c5c',
    '9-c': '#ffffff',
    '10': '#2a92ba',
    '10-c': '#211c21',
    '11': '#86d3f2',
    '11-c': '#211c21',
    '12': '#6c8f70',
    '12-c': '#211c21',
    '13': '#d1e0a5',
    '13-c': '#211c21',
    '14': '#f3d7bc',
    '14-c': '#211c21',
    '15': '#a8864d',
    '15-c': '#211c21',
    '16': '#925364',
    '16-c': '#ffffff',
    '17': '#917dA3',
    '17-c': '#211c21',
    '18': '#cdd1d8',
    '18-c': '#211c21',
    '19': '#3958a1',
    '19-c': '#ffffff',
    '20': '#c0e0db',
    '20-c': '#211c21',
    '21': '#f9d974',
    '21-c': '#211c21',
    '22': '#c47774',
    '22-c': '#211c21',
  },
  gradient: {
    '1-1': '#30a8ec',
    '1-1-c': '#211c21',
    '1-2': '#c2e5f9',
    '1-2-c': '#211c21',
    '2-1': '#0067c6',
    '2-1-c': '#ffffff',
    '2-2': '#30a8ec',
    '2-2-c': '#ffffff',
    '3-1': '#042353',
    '3-1-c': '#ffffff',
    '3-2': '#0067c6',
    '3-2-c': '#ffffff',
    '4-1': '#feb95f',
    '4-1-c': '#211c21',
    '4-2': '#fff1df',
    '4-2-c': '#211c21',
  },
  heatmap: {
    '0': '#e3e3e3',
    '0-c': '#211c21',
    '1': '#d1e0a5',
    '1-c': '#211c21',
    '2': '#e0d693',
    '2-c': '#211c21',
    '3': '#f0cb82',
    '3-c': '#211c21',
    '4': '#ffc170',
    '4-c': '#211c21',
    '5': '#eda15e',
    '5-c': '#211c21',
    '6': '#dc814b',
    '6-c': '#ffffff',
    '7': '#bb530a',
    '7-c': '#ffffff',
    '8': '#b45440',
    '8-c': '#ffffff',
    '9': '#9d4646',
    '9-c': '#ffffff',
    '10': '#65162c',
    '10-c': '#ffffff',
  },
} as const
