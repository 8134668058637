import { AxiosInstance } from 'axios'
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry'

import { MfeEnv } from '../../../ui/env'
import { CaisiqEnvConfig } from '../../../ui/env-caisiq'

export const addCloudflareChallengeToAxiosInstance = (
  env: MfeEnv | CaisiqEnvConfig,
  axiosInstance: AxiosInstance
) => {
  axiosRetry(axiosInstance, {
    retryCondition: async (error) =>
      new Promise((resolve) => {
        if (isNetworkOrIdempotentRequestError(error)) {
          return resolve(true)
        } else if (
          error.response &&
          'cf-mitigated' in error.response.headers &&
          error.response?.headers['cf-mitigated'] === 'challenge'
        ) {
          turnstile.render('#tt-widget', {
            sitekey: env.TURNSTILE_SITE_KEY,
            'error-callback': function () {
              console.error('Unable to pass challenge')
              resolve(false)
            },
            callback: function (token, preclearanceObtained) {
              resolve(preclearanceObtained)
            },
          })
        } else {
          return resolve(false)
        }
      }),
  })
}
