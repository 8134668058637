import { UserProfileV1 } from '../../../../tmp/generated/access-manager-api'
import { FirmExperienceV1 } from '../../../domain/experience-manager'
import {
  getEntitledNavLinks,
  getUserEnabledFeatures,
  EnabledAccessControls,
  isNavItemLink,
  isNavItemMenu,
  NavItem,
  NavItemLink,
} from '../../../util/entitled-nav-links'
import { type MfeEnv } from '../../env'
import { type CaisiqEnvConfig } from '../../env-caisiq/lib/env-caisiq'

import { ReactComponent as PoweredByCAISLogo } from './assets/powered-by-cais.svg'
import { menus } from './backup-menu-config'
import { FirmLogo } from './components/firm-logo'
import { getFirmLogo } from './getFirmLogo'
import { getProfileMenu } from './getProfileMenu'
import { linkToCaisIq } from './link-to-cais-iq'

type GlobalNav = {
  profileMenu: ReturnType<typeof getProfileMenu>
  navItems: NavItem[]
  logos: JSX.Element[] | null
  enabledFeatureFlags: ReturnType<typeof globalNavFeatureFlags>
}

type Options = {
  onLogout?: () => void
}

export function convertCaisIQNavItemLink(
  navItemLink: NavItemLink
): NavItemLink {
  return {
    ...navItemLink,
    href: navItemLink.href.startsWith('[caisiq]')
      ? linkToCaisIq(navItemLink.href.replace('[caisiq]', '')).toString()
      : navItemLink.href,
  }
}

function processCaisIQLinks(tree: NavItem[]): NavItem[] {
  return tree.map((navItem) => {
    if (isNavItemLink(navItem)) return convertCaisIQNavItemLink(navItem)
    else if (isNavItemMenu(navItem))
      return {
        ...navItem,
        menu: {
          ...navItem.menu,
          groups: navItem.menu.groups.map((group) => ({
            ...group,
            items: group.items.map((item) => convertCaisIQNavItemLink(item)),
          })),
        },
      }
    else return navItem
  })
}

export function globalNavManager({
  userProfile,
  config = {} as MfeEnv,
  options,
  experience,
}: {
  userProfile: UserProfileV1 | null
  config: MfeEnv | CaisiqEnvConfig
  options?: Options
  experience?: FirmExperienceV1
}): GlobalNav {
  const enabledFeatureFlags = userProfile
    ? globalNavFeatureFlags(userProfile, config?.FEATURE_FLAGS)
    : []
  const enabledAccessControls = {
    enabledCapabilities: getEnabledCapabilities(userProfile?.capabilities),
    enabledFeatures: getUserEnabledFeatures(userProfile?.features),
    enabledPermissions: userProfile?.permissions || [],
    enabledPersonas: userProfile?.personas || [],
    enabledFeatureFlags,
  }

  const firmLogoUrl = getFirmLogo({ experience, userProfile })
  const firmName = userProfile?.firm?.name || ''
  const navigation = processCaisIQLinks(config?.MENU || menus.MENU)
  const profileMenuData = processCaisIQLinks(
    config?.PROFILE_MENU || menus.PROFILE_MENU
  ) as NavItemLink[]

  return {
    enabledFeatureFlags,
    navItems: getEntitledNavLinks(navigation, enabledAccessControls),
    profileMenu: getProfileMenu(
      profileMenuData,
      enabledAccessControls,
      options?.onLogout
    ),
    logos: firmLogoUrl
      ? [
          <FirmLogo key={firmName} alt={firmName} src={firmLogoUrl} />,
          <PoweredByCAISLogo key="powered-by-cais" />,
        ]
      : null,
  }
}

function getEnabledCapabilities(
  capabilities: UserProfileV1['capabilities'] = []
): EnabledAccessControls['enabledCapabilities'] {
  return capabilities.map((capability) => capability.id)
}

function globalNavFeatureFlags(userProfile: UserProfileV1, featureFlags = {}) {
  const flags = []

  for (const [name, value] of Object.entries(featureFlags)) {
    if (value === true) {
      flags.push(name)
    }
  }

  const attributes = userProfile?.attributes ?? {}
  const inheritedAttributes = userProfile?.inheritedAttributes ?? {}
  const allAttributes = { ...attributes, ...inheritedAttributes }

  for (const [name, value] of Object.entries(allAttributes)) {
    if (value === true) {
      flags.push(name)
    }
  }

  return flags
}
