export type Config = Record<string, Array<{ label: string; path: string }>>

export const defaultConfig: Config = {
  research: [
    {
      label: 'Research',
      path: `/research/all-research`,
    },
  ],
  resources_structured_investments: [
    {
      label: 'Structured Investments Resources',
      path: `/resources/structured-investments`,
    },
  ],
  tutorials: [
    {
      label: 'Tutorials',
      path: `/help/tutorials`,
    },
  ],
  'in-person-events': [
    {
      label: 'Events',
      path: `/events`,
    },
    {
      label: 'In Person',
      path: `/events/in-person`,
    },
  ],
  webinars: [
    {
      label: 'Events',
      path: `/events`,
    },
    {
      label: 'Webinars',
      path: `/events/webinars`,
    },
  ],
}
