import { StrictMode } from 'react'
import { RouterProvider } from 'react-router-dom'

import { router } from './router'

import '../styles.css'

const AppRoutes = () => {
  return <RouterProvider router={router} />
}

export default function HomepageApp() {
  return (
    <StrictMode>
      <AppRoutes />
    </StrictMode>
  )
}
