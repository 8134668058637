// TODO mkae this a feature when time to use it for real

import {
  HandleCloseTabType,
  PreviewBanner,
} from '@cais-group/shared/ui/contentful/preview-banner'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'

export function PreviewBannerContainer() {
  const onHandleCloseTab: HandleCloseTabType = {
    onExit: previewService.turnOff,
  } as const
  if (!previewService.enabled) {
    return null
  }
  return <PreviewBanner onHandleCloseTab={onHandleCloseTab} />
}
