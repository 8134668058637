import { BaseTextFieldProps, Components } from '@mui/material'

declare module '@mui/material/TextField' {
  interface TextFieldPropsSizeOverrides {
    medium: false
    regular: true
    large: true
  }
}

const getVerticalPadding = (size: BaseTextFieldProps['size']) => {
  return size === 'small'
    ? 'var(--s8)'
    : size === 'large'
    ? 'var(--s16)'
    : 'var(--s12)'
}

export const MuiTextField: Components['MuiTextField'] = {
  defaultProps: {
    size: 'regular',
  },
  styleOverrides: {
    root: ({ ownerState }) => ({
      input: {
        lineHeight: 'var(--s24)',
        height: 'var(--s24)',
        padding: `${getVerticalPadding(ownerState?.size)} var(--s16)`,
      },
    }),
  },
}
