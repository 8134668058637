import { MutableRefObject, RefObject, useCallback, useEffect } from 'react'

const targetNotContained = <T extends Element>(el: T | undefined, target: T) =>
  !el?.contains(target)

/**
 * Hook for detecting clicks outside some ref
 * @param config Consists of:
 * - `callback` this is a dependency of an effect so should be memoized
 * - `ref` the actual component ref
 * - `refFilter` (optional)
 * - `stopPropagation` (optional) Stop clicks propagating (uses capture phase); if conditionally displaying something you will likely want to set the  `active` optional parameter too as otherwise will likely prevent events you do want to happen happening
 * - `active` (optional) should this be applied e.g. if should only work when a menu or similar is open
 */
export const useOnClickOutside = <T extends Element>({
  callback,
  ref,
  refFilter = (r) => r.current ?? undefined,
  stopPropagation,
  active = true,
}: {
  callback(): void
  ref: MutableRefObject<T> | RefObject<T>
  refFilter?(ref: MutableRefObject<T> | RefObject<T>): Element | undefined
  stopPropagation?: boolean
  active?: boolean
}) => {
  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Element
      if (targetNotContained(refFilter(ref), target)) {
        callback()
        if (stopPropagation) event.stopPropagation()
      }
    },
    [ref, refFilter, callback, stopPropagation]
  )

  useEffect(() => {
    if (active) {
      // setting up like this for historical reasons/may impact on cypress otherwise; if don't opt in to `stopPropagation` will see same behavior as before
      const eventKind = stopPropagation ? 'click' : 'mousedown'
      // For stopPropagation to work need to be in capture phase
      document.addEventListener(eventKind, handleMouseDown, stopPropagation)
      return () => {
        document.removeEventListener(
          eventKind,
          handleMouseDown,
          stopPropagation
        )
      }
    } else {
      // to satisfy our TS setup
      return undefined
    }
  }, [handleMouseDown, stopPropagation, active])
}
