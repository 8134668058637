import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import {
  JSXElementConstructor,
  LegacyRef,
  ReactElement,
  ReactSVG,
  forwardRef,
} from 'react'

import { TooltipProps } from './tooltip'

export const TouchEventTooltip = ({
  open,
  externalOpen,
  handleTooltipOpen,
  handleTooltipClose,
  children,
  ...commonTooltipProps
}: Omit<MuiTooltipProps, 'children'> & {
  open: boolean
  externalOpen?: boolean
  handleTooltipOpen: () => void
  handleTooltipClose: () => void
  children: MuiTooltipProps['children']
}) => (
  <ClickAwayListener onClickAway={handleTooltipClose}>
    <MuiTooltip
      {...commonTooltipProps}
      onClose={handleTooltipClose}
      open={externalOpen || open}
      disableFocusListener
      disableHoverListener
    >
      <TouchEventChildrenWrapperElement onClick={handleTooltipOpen}>
        {children}
      </TouchEventChildrenWrapperElement>
    </MuiTooltip>
  </ClickAwayListener>
)

// On touch screen, wrap the tooltip element with a "g" element if it is an SVG element, otherwise wrap it with a "button" element
const TouchEventChildrenWrapperElement = forwardRef(
  (
    props: {
      children: TooltipProps['children']
      onClick: () => void
    },
    ref: LegacyRef<SVGGElement | HTMLButtonElement>
  ) =>
    isSVGElement(props.children) ? (
      <g {...props} ref={ref as LegacyRef<SVGGElement>} />
    ) : (
      <button
        {...props}
        type="button"
        ref={ref as LegacyRef<HTMLButtonElement>}
      />
    )
)

const isSVGElement = <T,>(
  children: ReactElement<T, string | JSXElementConstructor<T>>
) => {
  const childrenType = children.type // Possibly 'rect', 'g', 'path', etc.
  const reactSVGKeys: Array<keyof ReactSVG> = [
    'animate',
    'circle',
    'clipPath',
    'defs',
    'desc',
    'ellipse',
    'feBlend',
    'feColorMatrix',
    'feComponentTransfer',
    'feComposite',
    'feConvolveMatrix',
    'feDiffuseLighting',
    'feDisplacementMap',
    'feDistantLight',
    'feDropShadow',
    'feFlood',
    'feFuncA',
    'feFuncB',
    'feFuncG',
    'feFuncR',
    'feGaussianBlur',
    'feImage',
    'feMerge',
    'feMergeNode',
    'feMorphology',
    'feOffset',
    'fePointLight',
    'feSpecularLighting',
    'feSpotLight',
    'feTile',
    'feTurbulence',
    'filter',
    'foreignObject',
    'g',
    'image',
    'line',
    'linearGradient',
    'marker',
    'mask',
    'metadata',
    'path',
    'pattern',
    'polygon',
    'polyline',
    'radialGradient',
    'rect',
    'stop',
    'svg',
    'switch',
    'symbol',
    'text',
    'textPath',
    'tspan',
    'use',
    'view',
  ]

  const isChildrenAnSVGElement =
    typeof childrenType === 'string' &&
    reactSVGKeys.includes(childrenType as never)

  return isChildrenAnSVGElement
}
