import type { Node } from '@contentful/rich-text-types'
import { Suspense } from 'react'

import { AssetMap, BlockAsset } from '../../../../util/type/rich-text-json'
import { MuxVideoPlayer } from '../../../video/mux-video-player'

function imageAsset(asset: BlockAsset) {
  return (
    <img width={asset.width} alt={asset.title} src={asset.url} loading="lazy" />
  )
}

function videoAsset(
  asset: BlockAsset & { duration?: number },
  videoType: 'contentful' | 'mux'
) {
  let src: string = ''
  if (videoType === 'mux') {
    // I am honestly not sure if marketing will ever use the mux url to upload an asset but if so, uploading a mux video as an asset doesn't
    // have the same functionality as within a contentful CAISIQ video component so we need to grab the mux playbackId from the filename
    // We should consider creating a custom content type for video assets https://www.contentful.com/help/working-with-metadata/
    src = asset.fileName?.replace('.m3u8', '') as string
  }
  if (videoType === 'contentful') {
    src = asset.url as string
  }
  return (
    src &&
    asset && (
      <Suspense fallback={null}>
        <MuxVideoPlayer
          src={src}
          variant="inPageComponent"
          metadata={{
            url: window.location.href,
            video_title: asset.title,
            watchId: (Math.random() * 10).toString(36).replace('.', ''),
            video_id: asset.sys.id,
            viewer_user_id: 'Unknown content asset user',
          }}
        />
      </Suspense>
    )
  )
}

export function embeddedAsset(node: Node, assetMap: AssetMap) {
  const assetId = node.data['target'].sys.id

  if (!assetId || !assetMap) {
    return null
  }

  const asset = assetMap.get(assetId)

  if (!asset) {
    return null
  }

  const isImage = asset.contentType?.includes('image')
  // as far as i can tell, this how to tell the difference between video types
  const videoType = asset.contentType?.includes('video')
    ? 'contentful'
    : asset.contentType === 'application/x-mpegURL'
    ? 'mux'
    : false

  if (isImage) {
    return imageAsset(asset)
  } else if (videoType) {
    return videoAsset(asset, videoType)
  } else {
    return null
  }
}
