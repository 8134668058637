import { Components } from '@mui/material'

export const MuiSkeleton: Components['MuiSkeleton'] = {
  styleOverrides: {
    root: {
      '&.MuiSkeleton-text': {
        borderRadius: 0,
        backgroundColor: 'rgb(var(--colors-neutral-200))',
      },
      '&.MuiSkeleton-rectangular': {
        borderRadius: 0,
        backgroundColor: 'rgb(var(--colors-neutral-200))',
      },
    },
  },
}
