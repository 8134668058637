import { resolveRules } from '../../entitlement-rule-resolver'

import type {
  EnabledAccessControls,
  MenuGroup,
  NavItem,
  NavItemLink,
} from './types'

/**
 * Filters the navigation items by which permissions, features or personas are enabled
 * for the user. Generally quite permissive.
 * NavItems with no requirements are allowed.
 * NavItems with submenu's which have allowed items are allowed.
 */
export const getEntitledNavLinks = (
  navItems: NavItem[],
  enabledAccessControls: EnabledAccessControls
) => {
  return navItems.reduce<NavItem[]>(
    (navItemsAccumulator, currentNavItem: NavItem) => {
      const enabled = isNavItemEnabled(currentNavItem, enabledAccessControls)

      // If it is not enabled and does not have enabled children, ignore it
      if (!enabled) return navItemsAccumulator

      // If it is enabled and does not have a menu, add it directly
      if (!('menu' in currentNavItem)) {
        return [...navItemsAccumulator, currentNavItem]
      }

      // If we have reached this line then it is enabled and it contains children (i.e. a menu)

      // Trim the menu items to those which are enabled
      return [
        ...navItemsAccumulator,
        {
          ...currentNavItem,
          menu: {
            ...currentNavItem.menu,
            groups: currentNavItem.menu.groups.reduce<MenuGroup[]>(
              (groupAccumulator, currentGroup) => {
                // Ignore empty groups
                if (currentGroup.items.length === 0) return groupAccumulator

                const currentGroupsEnabledNavItems = currentGroup.items.filter(
                  (groupNavItem) =>
                    enabledByAccessControl(groupNavItem, enabledAccessControls)
                )

                // Ignore groups with no enabled items
                if (currentGroupsEnabledNavItems.length === 0)
                  return groupAccumulator

                // Add groups with enabled navItems
                return [
                  ...groupAccumulator,
                  { ...currentGroup, items: currentGroupsEnabledNavItems },
                ]
              },
              []
            ),
          },
        },
      ]
    },
    []
  )
}

export const enabledByAccessControl = (
  navItem: NavItemLink,
  enabledAccessControls: EnabledAccessControls
) => {
  // Enable if no entitlements are required
  if (navItem.entitlements === undefined) {
    return true
  }

  return resolveRules({
    rules: navItem.entitlements,
    personas: enabledAccessControls.enabledPersonas,
    permissions: enabledAccessControls.enabledPermissions,
    features: enabledAccessControls.enabledFeatures,
    capabilities: enabledAccessControls.enabledCapabilities,
    featureFlags: enabledAccessControls.enabledFeatureFlags,
  })
}

/**
 * // Also accounts for child menus
 * @returns true if navItem is enabled, or has enabled children
 */
export const isNavItemEnabled = (
  navItem: NavItem,
  enabledAccessControls: EnabledAccessControls
) => {
  if (!('menu' in navItem)) {
    // For items without a menu just check the individual item
    return enabledByAccessControl(navItem, enabledAccessControls)
  }

  // Now deal with items with menus, ignore own requirements

  // Ignore empty groups
  if (navItem.menu.groups.length === 0) return false

  // If any item in any group is enabled, then enable this item
  return navItem.menu.groups.some((group) =>
    group.items.some((groupNavItem) =>
      enabledByAccessControl(groupNavItem, enabledAccessControls)
    )
  )
}
