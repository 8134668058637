import { type EnabledAccessControls } from './types'

// TODO: this should import from shared UserProfileV1, however we have not yet decided how to share these types into the shared library outside the monorepo
type UserProfileV1Capabilities = {
  id: string
  name?: string | null
}[]

export function getUserEnabledCapabilities(
  capabilities: UserProfileV1Capabilities = []
): EnabledAccessControls['enabledCapabilities'] {
  return capabilities.map((capability) => capability.id)
}
