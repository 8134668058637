///
// react-query triggers a fetch on all queries when the window is re-focused
// This behaviour can be disabled with `refetchOnWindowFocus: false`
//
// The purpose of refetching on window focus is to ensure the displayed data isn't stale
// after a long period of inactivity.
//
// Unfortunately, this means that a refetch (which itself triggers re-renders) will happen
// every time the user blurs and focuses the window, which could happen for more reasons than simple inactivity.
//
// This function overrides the focus behaviour to build in a timeout period to measure the inactivity,
// thus ensuring a refetch does not happen accidentally.
//
// See: https://tanstack.com/query/v4/docs/guides/window-focus-refetching for more information.
///
const REFETCH_TIMEOUT_MINUTES = 15

type EventManagerEventListener = (
  setFocused: (focused?: boolean) => void
) => (() => void) | undefined

export const focusListener: EventManagerEventListener = (handleFocus) => {
  let focusLostAt: number

  const handleFocusWrapper: EventListenerOrEventListenerObject = (e) => {
    if (e.type === 'blur') {
      focusLostAt = Date.now()
    }

    if (e.type === 'focus') {
      if (Date.now() - focusLostAt > REFETCH_TIMEOUT_MINUTES * 60 * 1000) {
        handleFocus(true)
      }
    }
  }

  if (typeof window !== 'undefined' && window.addEventListener) {
    window.addEventListener('focus', handleFocusWrapper, false)
    window.addEventListener('blur', handleFocusWrapper, false)
  }

  return () => {
    window.removeEventListener('focus', handleFocusWrapper)
    window.removeEventListener('blur', handleFocusWrapper)
  }
}
