import cx from 'classnames'
import { PropsWithChildren } from 'react'

type Variant = 'rectangular' | 'circular' | 'text'

type SkeletonColor = 'neutral'

type SkeletonType = {
  height?: number | string
  variant?: Variant
  width?: number | string
  testId?: string
  backgroundColor?: SkeletonColor
}

const trimAll = (str: string) => {
  return str.replace(/\s/g, '')
}

const isNumeric = (n: number | string) => {
  return !isNaN(parseFloat(n as string)) && isFinite(n as number)
}

const getHeight = (hasChildren: boolean, h?: number | string) => {
  if (!h) {
    return hasChildren ? 'auto' : undefined
  }
  if (typeof h === 'number' || isNumeric(h)) {
    return trimAll(`${h}px`)
  }

  return trimAll(h)
}

const getWidth = (w?: number | string) => {
  if (!w) {
    return undefined
  }
  if (typeof w === 'number' || isNumeric(w)) {
    return trimAll(`${w}px`)
  }

  return trimAll(w)
}

const getStyles = (
  props: Omit<SkeletonType, 'classNames'> & {
    hasChildren: boolean
  }
) => {
  const {
    hasChildren,
    variant = 'text',
    height: h,
    width: w,
    backgroundColor = 'neutral',
  } = props
  const variantStyle = variantStyles[variant].styles
  const height = getHeight(hasChildren, h)
  const width = getWidth(w)
  const maxW = !w && hasChildren ? 'max-w-fit' : ''
  const visibility = hasChildren ? '[&>*]:invisible' : ''
  const background = backgroundColorMap[backgroundColor]
  return {
    className: cx(
      `${variantStyle}`,
      `${maxW}`,
      `${visibility}`,
      `${background}`
    ),
    height,
    width,
  }
}
const commonStyles = 'block'
const variantStyles = {
  rectangular: {
    styles: `${commonStyles} h-24`,
  },
  circular: {
    styles: `rounded-full ${commonStyles} h-24`,
  },
  text: {
    styles: `my-0 body w-fit h-auto rounded-none empty:before:content-[\\00a0"] ${commonStyles}`,
  },
}

const backgroundColorMap: Record<SkeletonColor, string> = {
  neutral: 'bg-neutral-200',
}

export const Skeleton = (props: PropsWithChildren<SkeletonType>) => {
  const { children, testId, ...rest } = props

  const { className, width, height } = getStyles({
    ...rest,
    hasChildren: Boolean(children),
  })

  return (
    <span
      className={className}
      style={{ width, height }}
      data-testid={`skeleton-${testId}`}
    >
      {children}
    </span>
  )
}

type AnimateProps = PropsWithChildren<{
  type?: 'pulse'
  className?: string
}>

export const SkeletonAnimate = (props: AnimateProps) => {
  const { children, className, type = 'pulse', ...rest } = props
  return (
    <div
      className={cx(
        { 'animate-pulse': type === 'pulse' },
        { [`${className}`]: !!className }
      )}
      {...rest}
    >
      {children}
    </div>
  )
}
