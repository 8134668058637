import { Components } from '@mui/material'

import { breakpointsPx } from '../../../../../equity/particles/breakpoints'

export const MuiCard: Components['MuiCard'] = {
  defaultProps: {
    variant: 'elevation',
    elevation: 0,
  },
  variants: [
    {
      props: {
        variant: 'mobile-full-viewport-width',
      },
      style: {
        [`@media screen and (max-width: ${breakpointsPx.sm})`]: {
          // https://piccalil.li/tutorial/creating-a-full-bleed-css-utility/
          width: '100vw',
          marginLeft: 'calc(50% - 50vw)',
        },
      },
    },
  ],
  styleOverrides: {
    root: {
      borderRadius: 0,
      '.MuiCardContent-root': {
        padding: 'var(--s24)',
        [`@media screen and (min-width: ${breakpointsPx.md})`]: {
          padding: 'var(--s32)',
        },
      },
      '.MuiCardActions-root': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
}
