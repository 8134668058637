import { useEffect } from 'react'

export function useSetTitle(...title: string[]) {
  useEffect(() => {
    document.title = title.concat('CAIS').join(' - ')

    return () => {
      // default title used on most pages, if we don't reset it, it will be used on all subsequent pages
      document.title = 'CAIS'
    }
  }, [title])
}

export function DocumentTitle({ title }: { title: string | string[] }) {
  const titleArray = Array.isArray(title) ? title : [title]
  useSetTitle(...titleArray)
  return null
}
