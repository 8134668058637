import { useEffect } from 'react'

type OnCloseProps = () => void

export const useCloseOnEsc = (onClose: OnCloseProps) => {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === 'Escape') onClose()
    }
    window.addEventListener('keydown', handler)

    return () => window.removeEventListener('keydown', handler)
  }, [onClose])
}

export const CloseOnEsc = ({ onClose }: { onClose: OnCloseProps }) => {
  useCloseOnEsc(onClose)
  return null
}
