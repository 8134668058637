import { createEnvConfigContext } from '../../env'
import { type MfeEnv } from '../../env/lib/env'

export interface CaisiqEnvConfig extends MfeEnv {
  AUTH0_DIRECT_AUDIENCE: string
  AUTH0_MEMBERS_AUDIENCE: string
  AUTH0_MEMBERS_CLIENT_ID: string
  CAISIQ: boolean
}

// Export the previously available components / functions to maintain backward compatability in other libraries
export const {
  EnvContext,
  EnvConfigContainerProvider,
  EnvContextRenderProp,
  getEnvConfig,
  setEnvConfig,
} = createEnvConfigContext<CaisiqEnvConfig>('/env.json')
