import type { MuxPlayerRefAttributes } from '@mux/mux-player-react'
import { useParams } from 'react-router-dom'

import { APPS } from '../../../../domain/apps/lib/apps'
import { useLocationTrackingValues, useTrack } from '../../../../util/segment'

import { MuxVideoPlayerProps } from './video-mux-video-player'

export type Video = MuxPlayerRefAttributes

type Metadata = Omit<MuxVideoPlayerProps['metadata'], 'viewer_user_id'> & {
  currentTime?: number
}

const noop = () => {}

export const CancellationReason = {
  SWITCH_VIDEO: 'switch video',
  UNMOUNT: 'switch page',
  CLOSED: 'close window or tab',
} as const

export type TCancellationReason =
  (typeof CancellationReason)[keyof typeof CancellationReason]

export type TCommonVideoEventProps = {
  video_id: string
  watch_id: string
  video_title: string
  total_length: number
  time_elapsed: number
  fund_id?: string | string[]
}

export const EVENT_VIDEO_ENGAGED = 'Video Engaged'

export function commonPlaybackFields(
  metadata: Metadata,
  fundId?: string | string[]
): TCommonVideoEventProps {
  return {
    fund_id: fundId || metadata.fund_id, // prefere fund_id from URL over metatdata
    video_id: metadata.video_id,
    video_title: metadata.video_title,
    watch_id: metadata.watchId,
    time_elapsed: Number((metadata.currentTime ?? 0).toFixed(1)),
    total_length: Number((metadata.duration ?? 0).toFixed(1)),
  }
}

export const useTrackPlayback = () => {
  const { app, url, ...locationProps } = useLocationTrackingValues()
  const { fundId } = useParams<{ fundId: string }>()
  const payload = { fund_id: fundId, ...locationProps }

  const track = useTrack({
    app,
    url,
  })

  if (app === APPS.CAIS_IQ) {
    return {
      loaded: noop,
      started: noop,
      paused: noop,
      resumed: noop,
      cancelled: noop,
      completed: noop,
    }
  }

  return {
    loaded: (metadata: Metadata) => {
      track({
        event: EVENT_VIDEO_ENGAGED,
        data: {
          ...payload,
          ...commonPlaybackFields(metadata, payload.fund_id),
          engagement_type: 'Loaded',
        },
      })
    },
    started: (metadata: Metadata) => {
      track({
        event: EVENT_VIDEO_ENGAGED,
        data: {
          ...payload,
          ...commonPlaybackFields(metadata, payload.fund_id),
          engagement_type: 'Started',
        },
      })
    },
    paused: (_video: Video, metadata: Metadata) => {
      track({
        event: EVENT_VIDEO_ENGAGED,
        data: {
          ...payload,
          ...commonPlaybackFields(metadata, payload.fund_id),
          engagement_type: 'Paused',
        },
      })
    },
    resumed: (_video: Video, metadata: Metadata) => {
      track({
        event: EVENT_VIDEO_ENGAGED,
        data: {
          ...payload,
          ...commonPlaybackFields(metadata, payload.fund_id),
          engagement_type: 'Resumed',
        },
      })
    },
    cancelled: (metadata: Metadata, reason: TCancellationReason) => {
      track({
        event: EVENT_VIDEO_ENGAGED,
        data: {
          ...payload,
          ...commonPlaybackFields(metadata, payload.fund_id),
          cancellation_reason: reason,
          engagement_type: 'Cancelled',
        },
      })
    },
    completed: (metadata: Metadata) => {
      track({
        event: EVENT_VIDEO_ENGAGED,
        data: {
          ...payload,
          ...commonPlaybackFields(metadata, payload.fund_id),
          engagement_type: 'Completed',
        },
      })
    },
  }
}
