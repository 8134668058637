import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { parseCssString } from './utils'

// Styles from Type system on figma, use these from now on, challenge if design strays from guidelines:
// https://www.figma.com/file/9p46RPQfnXRph68T9RvTOR/Type-system?node-id=42%3A2

export const H1Css = css`
  font-size: 3.5rem; // 56px
  line-height: 5rem; // 80px
  font-weight: normal;
`

export const H1CssObject = parseCssString(H1Css.styles)

export const H1 = styled.h1`
  ${H1Css}
`

export const H1StrongCss = css`
  ${H1Css}
  font-weight: 600;
`

export const H1StrongCssObject = parseCssString(H1StrongCss.styles)

export const H1Strong = styled.h1`
  ${H1StrongCss}
`

export const H1SerifCss = css`
  ${H1Css}
  font-family: var(--font-serif);
`

export const H1SerifCssObject = parseCssString(H1SerifCss.styles)

export const H1Serif = styled.h1`
  ${H1SerifCss}
`
