import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { parseCssString } from './utils'

// Styles from Type system on figma, use these from now on, challenge if design strays from guidelines:
// https://www.figma.com/file/9p46RPQfnXRph68T9RvTOR/Type-system?node-id=42%3A2

export const H6StrongCss = css`
  font-size: 1rem; // 16px
  line-height: 1.5rem; // 24px
  font-weight: 600;
  letter-spacing: 0.2px;
`

export const H6StrongCssObject = parseCssString(H6StrongCss.styles)

// H6 is only available in strong
export const H6Strong = styled.h6`
  ${H6StrongCss}
`
