import { useCallback, useEffect, type MutableRefObject } from 'react'

/**
 * Note: copied from the original implementation
 *
 * Recreated this function as it's slightly different from the one already existing.
 * Main differences:
 * We check if the focus was within the target before we attempt to close it
 * We don't use the focusin event listener
 *
 */
const targetNotContained = <T extends Element>(
  ref: MutableRefObject<T>,
  target: T
) => !ref.current?.contains(target)

export const useOnClickOutside = <T extends Element>(options: {
  callback: () => void
  ref: MutableRefObject<T>
}) => {
  const handleMouseDown = useCallback(
    (event: MouseEvent) => {
      const target = event.target as Element

      if (
        targetNotContained(options.ref, target) &&
        !targetNotContained(options.ref, document.activeElement as Element)
      ) {
        event.preventDefault()
        options.callback()
      }
    },
    [options]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown)
    return () => {
      document.removeEventListener('mousedown', handleMouseDown)
    }
  }, [handleMouseDown])
}
