export function removeAuth0QueryParams() {
  const urlParams = new URLSearchParams(window.location.search)
  urlParams.delete('code')
  urlParams.delete('state')

  const urlParamsString = urlParams.toString()

  if (urlParamsString) {
    return '?' + urlParamsString
  } else {
    return ''
  }
}
