import { Button, ButtonLink } from '../../../../atoms/button'
import type { AnnouncementAction } from '../types'

export const ActionButton = ({ action }: { action: AnnouncementAction }) => {
  const { handler, href, text, loading, disabled } = action

  if (href && !disabled) {
    return (
      <ButtonLink
        href={href}
        variant="primary"
        size="small"
        loading={action?.loading}
        {...(handler && { onClick: handler })}
      >
        {text}
      </ButtonLink>
    )
  } else if ((!href && handler) || (handler && disabled)) {
    return (
      <Button
        onClick={handler}
        variant="primary"
        size="small"
        loading={loading}
        disabled={disabled}
      >
        {text}
      </Button>
    )
  }
  return null
}
