import { PropsWithChildren } from 'react'

import { ErrorSplash } from '../../../../../../equity/web/atoms/error-splash'
import { Link } from '../../../../../../equity/web/atoms/link'

export const PageErrorSplash = ({ children }: PropsWithChildren) => (
  <div className="lg:py-232 py-144 relative flex w-full md:py-16">
    {children}
  </div>
)

const NoAccess = () => (
  <PageErrorSplash>
    <ErrorSplash
      errorTitle="You do not have access to this page"
      errorDescription="If you think you should be able to view this page, reach out to your organization admin."
      variant="error"
      iconType="Lock"
      size="large"
    />
  </PageErrorSplash>
)

PageErrorSplash.NoAccess = NoAccess

const InlineError = () => (
  <ErrorSplash
    errorTitle="Oops. Something Went Wrong"
    errorDescription={
      <span className="body mt-24 text-center text-neutral-600">
        We are working on getting this fixed as soon as we can. You may be able
        to <a href="/homepage">try again.</a>
      </span>
    }
    variant="neutral"
    iconType="Build"
    size="large"
  />
)

const General = () => (
  <PageErrorSplash>
    <InlineError />
  </PageErrorSplash>
)

PageErrorSplash.General = General
PageErrorSplash.Inline = InlineError

const NotReached = () => (
  <PageErrorSplash>
    <ErrorSplash
      errorTitle="The page you are looking for cannot be reached."
      errorDescription={
        <span className="body mt-24 text-center text-neutral-600">
          Please check that the URL is correct, and you may be able to try again
          or
          <Link href="/homepage"> return home.</Link>
        </span>
      }
      variant="warning"
      iconType="Warning"
      size="large"
    />
  </PageErrorSplash>
)

PageErrorSplash.NotReached = NotReached

const Login = () => (
  <PageErrorSplash>
    <ErrorSplash
      errorTitle="You need to log in to access this page."
      errorDescription={
        <span className="body mt-24 text-center text-neutral-600">
          Please <a href="/login">log in</a> and try again.
        </span>
      }
      variant="info"
      iconType="User"
      size="large"
    />
  </PageErrorSplash>
)

PageErrorSplash.Login = Login

const NotFound = ({ home = 'homepage' }: { home?: string }) => (
  <PageErrorSplash>
    <ErrorSplash
      errorTitle="404"
      errorDescription={
        <span className="body mt-24 text-center text-neutral-600">
          Sorry, we can’t find the page you are looking for.
          <Link href={`/${home}`}> Go to home page.</Link>
        </span>
      }
      variant="neutral"
      iconType="Build"
      size="large"
    />
  </PageErrorSplash>
)

PageErrorSplash.NotFound = NotFound
