// TODO: temporary solution to avoid circular dependency in pre-polyrepo moving to all-shared

import { fetchInstance } from '../../../../legacy-shared/util/fetch/lib/fetch'

import type {
  FundProductIdsAndNames,
  GetFundProductIdsAndNamesHeaders,
} from './api.schemas'

/**
 * **Permission:** `funds:products:advisor-read`

Get funds product IDs and names allowed to show to consumer (advisor-facing)
 * @summary Get funds product IDs, names, and actions allowed to show to consumer (advisor-facing)
 */
export const getFundProductIdsAndNames = (
  headers?: GetFundProductIdsAndNamesHeaders,
  signal?: AbortSignal
) => {
  return fetchInstance<FundProductIdsAndNames>({
    url: `/fund-products/v1/fund-products/ids-names`,
    method: 'GET',
    headers,
    signal,
  })
}
