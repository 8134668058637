export class GeneralError extends Error {
  title: string
  description: string

  constructor(title: string, description: string) {
    super(description)
    this.title = title
    this.description = description
    Object.setPrototypeOf(this, GeneralError.prototype)
  }
}
