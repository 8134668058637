import { useEquityTracking } from '../../../../equity/utils/tracking-utils'
import { APPS } from '../../../domain/apps'

import { useTrackItemClicked } from './use-track-item-clicked'

function Setup() {
  useEquityTracking(useTrackItemClicked())

  return null
}

export const EquityTracking = {
  enabledApps: [
    APPS.FUNDS_TRADE,
    APPS.HOMEPAGE,
    APPS.MODEL_PORTFOLIO,
    APPS.INVESTOR_PROFILE,
    APPS.CAIS_COMPASS,
  ],
  Setup,
}
