import cx from 'classnames'
import { useId } from 'react'

import { IconButton } from '../../../../../equity/web/atoms/icon-button'
import { LoadingIndicator } from '../../../../../equity/web/atoms/loading-indicator'
import type { LightboxProps } from '../lightbox'

export const ExpandedNodeModal = ({
  children,
  control,
  width,
  alignment,
  isLoading = false,
  resizeRef,
}: LightboxProps) => {
  const dialogTitleId = useId()
  if (!children) {
    return null
  }

  return (
    <div ref={resizeRef}>
      <dialog
        className={cx(
          'fixed left-0 top-0 z-10 !m-0 grid h-screen w-screen cursor-zoom-out justify-center bg-neutral-900/80',
          {
            'items-center': alignment === 'center',
          }
        )}
        onClick={control.closeLightbox}
        ref={control.lightboxRef}
        autoFocus
        aria-labelledby={dialogTitleId}
        id="expanded-lightbox"
      >
        <div
          className="mb-56 max-h-[calc(80vh+var(--s56))] flex-col flex-wrap place-items-center space-y-16 place-self-center bg-transparent"
          style={{
            maxWidth: `${width}px`,
            width: isLoading ? `${width}px` : 'auto',
            height: isLoading ? '100%' : 'auto',
          }}
        >
          <div className="relative left-32 flex w-full justify-end">
            <IconButton
              iconType="Close"
              color="neutral"
              onClick={control.closeLightbox}
              size="large"
              aria-label="Close expanded image"
            />
          </div>
          {isLoading && (
            <div className="grid h-full [&>svg]:place-self-center">
              <LoadingIndicator color="neutral" size="large" />
            </div>
          )}
          <div
            className={cx({
              'sr-only': isLoading,
            })}
          >
            {children}
          </div>
        </div>
      </dialog>
    </div>
  )
}
