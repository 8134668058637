import { Side } from '../../../type/side'

export const isHorizontal = (side: Side) =>
  side === Side.RIGHT || side === Side.LEFT

export const isReverse = (side: Side) => side === Side.TOP || side === Side.LEFT

const angleMap = {
  [Side.TOP]: '0deg',
  [Side.RIGHT]: '90deg',
  [Side.BOTTOM]: '180deg',
  [Side.LEFT]: '270deg',
}

export const getGradientAngle = (side: Side) => angleMap[side]

export const sleep = async (ms?: number) =>
  await new Promise((resolve) => setTimeout(resolve, ms || 0))

export const isBlankString = (text?: string | null): boolean =>
  text == null || text.match(/^\s*$/) !== null

export const getFileExtension = (filename: string) => {
  if (filename.lastIndexOf('.') === -1) return ''
  return filename.split('.').pop()
}
