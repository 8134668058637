import { DISCLOSURE_PAGE_SLUG } from './screens/legal'

export enum ROUTES {
  HOME = 'homepage',
  DISCLOSURE = DISCLOSURE_PAGE_SLUG,
  EVENTS = 'events',
  WEBINARS = 'webinars',
  'IN_PERSON' = 'in-person',
  ARTICLES = `articles/:slug`,
  'ARTICLES_BFF' = `articles-bff/:slug`,
  GLOSSARY = 'glossary/:id?',
  RESEARCH = 'research',
  FEATURED = 'featured',
  'ALL_RESEARCH' = 'all-research',
  HELP = 'help',
  TUTORIALS = 'tutorials',
  FAQS = 'faqs',
  FAQ_CATEGORY = ':categoryId?/:faqId?',
  'RESOURCES' = 'resources',
  'STRUCTURED_INVESTMENTS' = 'structured-investments',
  NOT_FOUND = 'not-found',
  PARENT_SLUG = ':parentSlug',
  CHILD_SLUG = ':childSlug',
  VIDEOS = 'videos/:playlistId?/:id',
  WHITEPAPERS = 'whitepapers/:slug',
}
