import cx from 'classnames'

/**
 * The purpose of this file is to group together styles that are:
 *  - shared and should be consistent e.g. animations, colors
 *  - related or dependent e.g. padding, margin, offsets etc
 *  - puts the 1311px related styles in one place so that if/when the value changes it's easier to update
 */

/**
 * Transition and animation effects - delay, timing function, duration
 * use duration-short-1 when there's a delay (e.g. menu container), duration-short-2 for everything else
 * */
export const animateBase =
  'motion-reduce:transition-none transition-all ease-standard will-change-auto'
export const animate = `${animateBase} duration-short-2`
export const animateExpandCollapse = `grid grid-rows-[0fr] aria-expanded:grid-rows-[1fr] group/aria-expanded:grid-rows-[1fr] *:overflow-hidden ${animate}`
export const animateWithDelay = `${animateBase} delay-75 duration-short-1`

export const wideScreenBreakpoint = {
  display: {
    block: 'min-[1311px]:block',
    flex: 'min-[1311px]:flex',
    hidden: 'min-[1311px]:hidden',
  },
  margin: {
    logo: 'min-[1311px]:my-0 min-[1311px]:-ml-8 min-[1311px]:mr-32',
  },
  environmentTag: 'min-[1311px]:-ml-24 min-[1311px]:mr-24',
  navLink: 'min-[1311px]:body headline-s',
}

//The top-level nav items e.g. primary nav and profile menu button
const primaryText =
  'text-neutral-900 hover:text-primary-600 group-hover:text-primary-600 focus-visible:text-primary-600'

// Used for secondary text e.g. company name and caption
const secondaryText = 'text-neutral-600'

// Note: delay so that menus don't appear when passively hovering over them
export const menuItems = {
  caption: `caption uppercase border-t border-solid border-neutral-200 mt-16 pt-24 p-8 ${secondaryText}`,
  container: `p-16 ${animateWithDelay}`,
}

export const profile = {
  avatar: `rounded-full bg-transparent p-4 group-hover:bg-primary-200 ${animate}`,
  menuButton: primaryText,
  company: `${secondaryText} group-hover:text-primary-400`,
  userName: primaryText,
}

export const primary = {
  activeMenuItem: `
  [&>li:has(a.active)_[data-menu-label]]:bg-primary-100
  [&>li:has(a.active)_[data-menu-label]]:text-primary-600
  [&>li:has(a.active)_[data-menu-label]>span>svg]:opacity-100
`,
  expandIcon: `text-inherit *:opacity-60`,
  navLink: `${primaryText} ${animate} ${wideScreenBreakpoint.navLink} inline-block w-full whitespace-nowrap py-4 pl-4 pr-4 has-[svg]:pr-0 focus-visible:ring !outline-0`,
  menuItem: cx(
    'block shadow-5 bg-neutral-0 absolute z-30 min-w-[264px]',
    '-rotate-x-90 origin-[top_center] translate-y-0',
    'group-hover:visible group-hover:opacity-100',
    'group-hover:rotate-x-0 group-hover:translate-y-0',
    '[&#profile-menu]:right-0',
    '[&:not(#profile-menu)]:-translate-x-[24px]',
    'invisible opacity-[0.2]',
    'aria-expanded:visible aria-expanded:opacity-100',
    menuItems.container
  ),
}

export const logo = `text-neutral-0 hover:text-primary-100 hover:bg-primary-100 p-8 ${wideScreenBreakpoint.margin.logo}`
