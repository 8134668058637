import { getContentOwnershipTag } from '@cais-group/shared/ui/contentful/card'
import type { HomeContentType } from '@cais-group/shared/util/contentful/types'

const pillsTabLabels = {
  Events: 'Events',
  'By CAIS': 'By CAIS',
  Products: 'Products',
  Webinars: 'Webinars',
  Research: 'Research',
  Tutorials: 'Tutorials',
} as const

const pillsTabValues = {
  latestByFirm: 'latestByFirm',
  latest: 'latest',
  products: 'products',
  webinars: 'webinars',
  events: 'events',
  research: 'research',
  tutorials: 'tutorials',
}
const pillsAllowedRoutes = {
  '/funds-pre-trade': '/funds-pre-trade',
  'events/webinars': 'events/webinars',
  'events/in-person': 'events/in-person',
  'research/all-research': 'research/all-research',
  'help/tutorials': 'help/tutorials',
}

export type PillsType = {
  label: keyof typeof pillsTabValues
  href?: PillsAllowedRouteTypes
}

export type PillTabsTypes = keyof typeof pillsTabLabels | `By ${string}`
type PillsAllowedRouteTypes = keyof typeof pillsAllowedRoutes

const initialPills: [PillTabsTypes, PillsType][] = [
  ['By CAIS', { label: 'latest' }],
  ['Products', { label: 'products', href: '/funds-pre-trade' }],
  ['Webinars', { label: 'webinars', href: 'events/webinars' }],
  ['Events', { label: 'events', href: 'events/in-person' }],
  ['Research', { label: 'research', href: 'research/all-research' }],
  ['Tutorials', { label: 'tutorials', href: 'help/tutorials' }],
]

const latestByFirmValue: PillsType = { label: 'latestByFirm' }

const getPills = (data: HomeContentType): [PillTabsTypes, PillsType][] => {
  const pills = [...initialPills]

  const addFirmPill = data.latestByFirm.carousel.length > 0

  if (addFirmPill) {
    const firstFirmItem = data.latestByFirm.carousel[0]
    const firmName = getContentOwnershipTag(firstFirmItem)?.name
    const firmPillTab: `By ${string}` = `By ${firmName}`

    if (!pills.some(([label]) => label === firmPillTab)) {
      pills.unshift([firmPillTab, latestByFirmValue])
    }
  }
  return pills
}

export const getPillsConfig = (
  data?: HomeContentType,
  firmName?: string
): {
  pills: [PillTabsTypes, PillsType][]
} => {
  if (!data || firmName === 'CAIS') {
    return {
      pills: initialPills,
    }
  }
  return {
    pills: getPills(data),
  }
}
