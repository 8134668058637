import cx from 'classnames'

import { AllColor, allColors } from '../../../particles/colors'

type LoadingIndicatorSize = 'small' | 'medium' | 'large'
type Spec = [width: number, strokeWidth: number]

export const loadingIndicatorSizes: Record<LoadingIndicatorSize, Spec> = {
  small: [20, 2],
  medium: [32, 3],
  large: [48, 4],
} as const

export type LoadingIndicatorColor =
  | 'primary'
  | 'success'
  | 'error'
  | 'neutral'
  | 'inverse'
  | 'primaryInverse'

export const mapColor: Record<LoadingIndicatorColor, AllColor> = {
  primary: 'eq-color-primary-600',
  neutral: 'eq-color-neutral-400',
  success: 'eq-color-success-300',
  error: 'eq-color-error-300',
  inverse: 'eq-color-neutral-0',
  primaryInverse: 'eq-color-primary-300',
} as const

export type LoadingIndicatorProps = {
  /** Optional property to set which semantic color to be used. */
  color?: LoadingIndicatorColor
  /** Optional property to determine what size the loading indicator should be. */
  size?: LoadingIndicatorSize
  /** Optional string which is used for the aria label */
  label?: string
}

/**
 * Loading indicators commonly known as spinners, express an unspecified wait time or display the length of a process.
 */
export const LoadingIndicator = ({
  color = 'primary',
  size = 'medium',
  label = 'Spinner',
}: LoadingIndicatorProps) => {
  const [width, strokeWidth] = loadingIndicatorSizes[size]
  const halfWidth = width / 2
  return (
    <svg
      width={width}
      height={width}
      className={cx('animate-spin', color)}
      viewBox={`0 0 ${width} ${width}`}
      role="progressbar"
      aria-label={label}
    >
      <circle
        pathLength={100}
        className="animate-dash"
        cx={halfWidth}
        cy={halfWidth}
        r={halfWidth - strokeWidth / 2}
        fill="none"
        stroke={allColors[mapColor[color]]}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeDasharray="1 100"
        strokeDashoffset="0"
      />
    </svg>
  )
}
