import {
  useQuery,
  useInfiniteQuery,
  useMutation,
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  QueryFunctionContext,
} from 'react-query'
import { createMembersFetcher } from '../../../create-fetcher'
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  /** An arbitrary precision signed decimal */
  BigDecimal: any
  /** An RFC-3339 compliant Full Date Scalar */
  Date: string
  /** Custom scalar representing ISO 8601 date and time - i.e. YYYY-MM-DDTHH:mm:ss.sssZ */
  IsoDateTime: string
  /** A JSON scalar */
  JSON: any
  /** A universally unique identifier compliant UUID Scalar */
  UUID: string
  /** Custom scalar representing ISO 8601 year and month - i.e. YYYY-MM */
  YearMonth: any
}

export type AcceptTermsAndConditionsInput = {
  type: TermsAndConditionType
  version: Scalars['Int']
}

export type AcceptTermsAndConditionsPayload = {
  __typename?: 'AcceptTermsAndConditionsPayload'
  payload: TermsAndConditions
}

export type Account = {
  accountCategory: Scalars['String']
  accreditedInvestorStatuses?: Maybe<Array<AccreditedInvestorStatusResponse>>
  advisorNotifications?: Maybe<Scalars['Boolean']>
  brokerageDetails?: Maybe<BrokerageDetails>
  commonAccountDetails?: Maybe<CommonAccountDetails>
  createdAt: Scalars['IsoDateTime']
  custodianDetails?: Maybe<CustodianNumberDetails>
  externalId?: Maybe<Scalars['String']>
  feeStructure?: Maybe<Scalars['String']>
  fundAdministratorsDetails?: Maybe<Array<FundAdministratorDetails>>
  householdId: Scalars['ID']
  id: Scalars['ID']
  investorType?: Maybe<InvestorTypeDetailsResponse>
  isLatest: Scalars['Boolean']
  name: Scalars['String']
  qualifiedClientStatuses?: Maybe<Array<QualifiedClientStatusResponse>>
  qualifiedEligiblePersonStatuses?: Maybe<
    Array<QualifiedEligiblePersonStatusResponse>
  >
  qualifiedPurchaserStatuses?: Maybe<Array<QualifiedPurchaserStatusResponse>>
  reportingProviderDetails?: Maybe<ReportingProviderDetails>
  /** @deprecated Use reportingProviderDetails instead */
  reportingProvidersDetails?: Maybe<Array<ReportingProviderDetails>>
  subType: Scalars['String']
  taxStatusDetails?: Maybe<TaxStatusDetailsResponse>
  updatedAt: Scalars['IsoDateTime']
  version: Scalars['Int']
}

export type AccountAddress = {
  __typename?: 'AccountAddress'
  addressLine1: Scalars['String']
  addressLine2?: Maybe<Scalars['String']>
  city: Scalars['String']
  country: Scalars['String']
  state: Scalars['String']
  zipCode: Scalars['String']
}

export enum AccountCategory {
  Individual = 'INDIVIDUAL',
  NonIndividual = 'NON_INDIVIDUAL',
  TaxExemptOrganization = 'TAX_EXEMPT_ORGANIZATION',
}

export type AccountEligibility = {
  __typename?: 'AccountEligibility'
  beneficialInterestsBelowThreshold: Scalars['Boolean']
  charitableOrganization: Scalars['Boolean']
  churchPlan: Scalars['Boolean']
  commonTrustAtLeastOneThousandAccounts: Scalars['Boolean']
  entityOnNationalSecuritiesExchange: Scalars['Boolean']
  fundUnderForeignJurisdiction: Scalars['Boolean']
  insuranceCoAccountAtLeastOneThousandPolicyholders: Scalars['Boolean']
  qualifiedErisaBenefitPlan: Scalars['Boolean']
  registeredUnderInvestmentCompanyAct: Scalars['Boolean']
}

export type AccountFilterInput = {
  accountIds?: InputMaybe<IdFilterInput>
  accountName?: InputMaybe<StringFilterInput>
  accountNumber?: InputMaybe<StringFilterInput>
  firmIds?: InputMaybe<IdFilterInput>
  teamIds?: InputMaybe<IdFilterInput>
}

export type AccountFilterOption = {
  __typename?: 'AccountFilterOption'
  id: Scalars['UUID']
  name: Scalars['String']
}

export type AccountFilterOptions = {
  __typename?: 'AccountFilterOptions'
  items: Array<AccountFilterOption>
  pageInfo: PageInfo
}

export type AccountIdName = {
  __typename?: 'AccountIdName'
  id: Scalars['UUID']
  name?: Maybe<Scalars['String']>
}

export type AccountInput = {
  accountCategory: AccountCategory
  accreditedInvestorStatuses?: InputMaybe<Array<AccreditedInvestorStatusInput>>
  brokerageDetails?: InputMaybe<BrokerageDetailsInput>
  clients?: InputMaybe<IndividualClientsDetailsInput>
  custodianDetails?: InputMaybe<CustodianNumberDetailsInput>
  externalId?: InputMaybe<Scalars['String']>
  feeStructure?: InputMaybe<FeeStructure>
  fundAdministratorsDetails?: InputMaybe<Array<FundAdministratorDetailsInput>>
  investmentEntities?: InputMaybe<InvestmentEntitiesInput>
  investorType?: InputMaybe<InvestorTypeDetailsInput>
  isNominee?: InputMaybe<Scalars['Boolean']>
  planName?: InputMaybe<Scalars['String']>
  primarySignerId?: InputMaybe<Scalars['ID']>
  primarySigners?: InputMaybe<PrimarySignersInput>
  qualifiedClientStatuses?: InputMaybe<Array<QualifiedClientStatusInput>>
  qualifiedEligiblePersonStatuses?: InputMaybe<
    Array<QualifiedEligiblePersonStatusInput>
  >
  qualifiedPurchaserStatuses?: InputMaybe<Array<QualifiedPurchaserStatusInput>>
  reportingProviderDetails?: InputMaybe<ReportingProviderDetailsInput>
  subType: AccountSubType
  subTypeDescription?: InputMaybe<Scalars['String']>
  taxStatusDetails?: InputMaybe<TaxStatusDetailsInput>
}

export type AccountLegalAddress = {
  __typename?: 'AccountLegalAddress'
  address?: Maybe<AccountAddress>
  sameAsPrimaryClientAddress: Scalars['Boolean']
}

export type AccountListItem = {
  __typename?: 'AccountListItem'
  account: Account
  /** @deprecated use household.firm.name instead */
  firmName?: Maybe<Scalars['String']>
  household: Household
  primaryClientOrSigner: Contact
}

export type AccountListItemForContact = {
  __typename?: 'AccountListItemForContact'
  account: Account
  contactRoles: Array<Scalars['String']>
}

export type AccountListItemPageResult = PageResult & {
  __typename?: 'AccountListItemPageResult'
  items: Array<AccountListItem>
  pageInfo: PageInfo
}

export type AccountMailingAddress = {
  __typename?: 'AccountMailingAddress'
  address?: Maybe<AccountAddress>
  sameAsLegalAddress: Scalars['Boolean']
}

export enum AccountSort {
  AccountNameAsc = 'ACCOUNT_NAME_ASC',
  AccountNameDesc = 'ACCOUNT_NAME_DESC',
  AccountNumberAsc = 'ACCOUNT_NUMBER_ASC',
  AccountNumberDesc = 'ACCOUNT_NUMBER_DESC',
  ClientFirstNameAsc = 'CLIENT_FIRST_NAME_ASC',
  ClientFirstNameDesc = 'CLIENT_FIRST_NAME_DESC',
  ClientLastNameAsc = 'CLIENT_LAST_NAME_ASC',
  ClientLastNameDesc = 'CLIENT_LAST_NAME_DESC',
  ClientMiddleInitialAsc = 'CLIENT_MIDDLE_INITIAL_ASC',
  ClientMiddleInitialDesc = 'CLIENT_MIDDLE_INITIAL_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CustodianNameAsc = 'CUSTODIAN_NAME_ASC',
  CustodianNameDesc = 'CUSTODIAN_NAME_DESC',
  HouseholdNameAsc = 'HOUSEHOLD_NAME_ASC',
  HouseholdNameDesc = 'HOUSEHOLD_NAME_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

export enum AccountSubType {
  BenefitPlan = 'BENEFIT_PLAN',
  Ccorp = 'CCORP',
  CharitableTrust = 'CHARITABLE_TRUST',
  CommunityProperty = 'COMMUNITY_PROPERTY',
  ComplexTrust = 'COMPLEX_TRUST',
  Corporation = 'CORPORATION',
  Endowment = 'ENDOWMENT',
  FundOfFunds = 'FUND_OF_FUNDS',
  GrantorTrust = 'GRANTOR_TRUST',
  Individual = 'INDIVIDUAL',
  InheritedIra = 'INHERITED_IRA',
  Ira = 'IRA',
  IrrevocableTrust = 'IRREVOCABLE_TRUST',
  JointRightsOfSurvivorshipBothOwnersAreEntities = 'JOINT_RIGHTS_OF_SURVIVORSHIP_BOTH_OWNERS_ARE_ENTITIES',
  JointTenantsInCommon = 'JOINT_TENANTS_IN_COMMON',
  JointTenantsInCommonBothOwnersAreEntities = 'JOINT_TENANTS_IN_COMMON_BOTH_OWNERS_ARE_ENTITIES',
  JointTrust = 'JOINT_TRUST',
  JointWithRightsOfSurvivorship = 'JOINT_WITH_RIGHTS_OF_SURVIVORSHIP',
  KeoghPlan = 'KEOGH_PLAN',
  LimitedLiabilityCompany = 'LIMITED_LIABILITY_COMPANY',
  LimitedPartnership = 'LIMITED_PARTNERSHIP',
  Other = 'OTHER',
  OtherSelfDirectedRetirementAccount = 'OTHER_SELF_DIRECTED_RETIREMENT_ACCOUNT',
  OtherTaxExemptOrganization = 'OTHER_TAX_EXEMPT_ORGANIZATION',
  Partnership = 'PARTNERSHIP',
  PrivateFoundation = 'PRIVATE_FOUNDATION',
  RemainderTrust = 'REMAINDER_TRUST',
  RevocableTrust = 'REVOCABLE_TRUST',
  RolloverIra = 'ROLLOVER_IRA',
  RothIra = 'ROTH_IRA',
  Scorp = 'SCORP',
  SepIra = 'SEP_IRA',
  SimpleTrust = 'SIMPLE_TRUST',
}

export type AccountSubTypeDetails = {
  __typename?: 'AccountSubTypeDetails'
  label: Scalars['String']
  name: Scalars['String']
}

export type AccountTypeDetails = {
  __typename?: 'AccountTypeDetails'
  label: Scalars['String']
  name: Scalars['String']
  subTypes: Array<AccountSubTypeDetails>
}

export enum AccreditedInvestorStatusCode {
  Iai01 = 'IAI01',
  Iai02 = 'IAI02',
  Iai03 = 'IAI03',
  Iai04 = 'IAI04',
  Iai05 = 'IAI05',
  Iai06 = 'IAI06',
  Niai01 = 'NIAI01',
  Niai02 = 'NIAI02',
  Niai03 = 'NIAI03',
  Niai04 = 'NIAI04',
  Niai05 = 'NIAI05',
  Niai06 = 'NIAI06',
  Niai07 = 'NIAI07',
  Niai08 = 'NIAI08',
  Niai09 = 'NIAI09',
  Niai10 = 'NIAI10',
  Niai11 = 'NIAI11',
  Niai12 = 'NIAI12',
  Niai13 = 'NIAI13',
  Niai14 = 'NIAI14',
  Niai15 = 'NIAI15',
  Niai16 = 'NIAI16',
  Niai17 = 'NIAI17',
  Niai18 = 'NIAI18',
  Niai19 = 'NIAI19',
  Niai20 = 'NIAI20',
  Niai21 = 'NIAI21',
  Niai22 = 'NIAI22',
  Niai23 = 'NIAI23',
}

export type AccreditedInvestorStatusInput = {
  code: AccreditedInvestorStatusCode
  description?: InputMaybe<Scalars['String']>
}

export type AccreditedInvestorStatusResponse = {
  __typename?: 'AccreditedInvestorStatusResponse'
  code: Scalars['String']
  description?: Maybe<Scalars['String']>
}

export type Address = {
  __typename?: 'Address'
  addressType: AddressType
  alternateEmail?: Maybe<Scalars['String']>
  city: Scalars['String']
  country: Scalars['String']
  fax?: Maybe<Scalars['String']>
  id: Scalars['ID']
  phone?: Maybe<Scalars['String']>
  postalCode: Scalars['String']
  primaryEmail?: Maybe<Scalars['String']>
  state: Scalars['String']
  street1: Scalars['String']
  street2?: Maybe<Scalars['String']>
}

export enum AddressType {
  Custodian = 'CUSTODIAN',
  Mailing = 'MAILING',
  Office = 'OFFICE',
  Permanent = 'PERMANENT',
}

export type AdminTypeResult = {
  __typename?: 'AdminTypeResult'
  adminTypePortalId?: Maybe<Scalars['Int']>
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
}

export type AdvisorFirm = {
  __typename?: 'AdvisorFirm'
  firmId: Scalars['UUID']
  firmName: Scalars['String']
}

export type AdvisorFirmIdName = {
  __typename?: 'AdvisorFirmIdName'
  id: Scalars['UUID']
  name?: Maybe<Scalars['String']>
}

export type AdvisorFirmInfo = {
  __typename?: 'AdvisorFirmInfo'
  type: AdvisorFirmType
}

export type AdvisorFirmInfoInput = {
  type: AdvisorFirmType
}

export enum AdvisorFirmType {
  BrokerDealer = 'BROKER_DEALER',
  FamilyOffice = 'FAMILY_OFFICE',
  Hybrid = 'HYBRID',
  Other = 'OTHER',
  Ria = 'RIA',
}

export type Affiliations = {
  __typename?: 'Affiliations'
  finra?: Maybe<FinraAffiliations>
  fund?: Maybe<FundAffiliations>
  political?: Maybe<PoliticalAffiliations>
}

export type AggregatedPositionTotals = {
  __typename?: 'AggregatedPositionTotals'
  /** @deprecated , replace with finalRedemptionValue */
  finalRedemptionPerformance?: Maybe<Scalars['BigDecimal']>
  finalRedemptionValue?: Maybe<Scalars['BigDecimal']>
  /** Market value for aggregate position */
  marketValue?: Maybe<Scalars['BigDecimal']>
  /** Notional value for aggregate position */
  notional: Scalars['BigDecimal']
  /** Product quantity within aggregate position */
  quantity: Scalars['BigDecimal']
  /** @deprecated , replace with staticReturnValue */
  staticReturn?: Maybe<Scalars['BigDecimal']>
  staticReturnValue?: Maybe<Scalars['BigDecimal']>
  /** This is the sum of all positions totalValueOfCoupons paid (of which each is product.yield.totalCouponValue * quantity) */
  totalValueOfCouponsPaid?: Maybe<Scalars['BigDecimal']>
}

export type AggregatedStructuredProductPosition = {
  __typename?: 'AggregatedStructuredProductPosition'
  /** Child positions for this aggregate */
  positions: Array<StructuredProductPosition>
  /** Structured product */
  structuredProduct: StructuredProduct
  /** Calculated total fields for aggregate position */
  totals: AggregatedPositionTotals
}

export type AggregatedStructuredProductPositionPageResult = PageResult & {
  __typename?: 'AggregatedStructuredProductPositionPageResult'
  items: Array<AggregatedStructuredProductPosition>
  pageInfo: PageInfo
}

export type AlternativeProduct = Entity &
  Node &
  Product & {
    __typename?: 'AlternativeProduct'
    caisId: Scalars['String']
    category?: Maybe<Scalars['String']>
    currencyCode: ProductStringField
    exchangeId?: Maybe<Scalars['String']>
    id: Scalars['ID']
    identifiers: Array<ProductIdentifier>
    name?: Maybe<Scalars['String']>
    price: Array<ProductPrice>
    productType: ProductType
    publishState: ProductPublishState
    ticker?: Maybe<Scalars['String']>
    version: Scalars['Int']
  }

export type AnnualBenchmarkValues = {
  __typename?: 'AnnualBenchmarkValues'
  value?: Maybe<Scalars['BigDecimal']>
  year?: Maybe<Scalars['Int']>
}

/** Individual ApprovedExceptions used by both input and output. */
export type ApprovedException = {
  __typename?: 'ApprovedException'
  accountId?: Maybe<Scalars['String']>
  accountName?: Maybe<Scalars['String']>
  externalAccountId?: Maybe<Scalars['String']>
  externalAccountName?: Maybe<Scalars['String']>
  externalFundId?: Maybe<Scalars['String']>
  externalFundIdType?: Maybe<Scalars['String']>
  externalFundName?: Maybe<Scalars['String']>
  externalPositionId?: Maybe<Scalars['String']>
  externalShareClassId?: Maybe<Scalars['String']>
  externalShareClassIdType?: Maybe<Scalars['String']>
  fundId?: Maybe<Scalars['String']>
  fundName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['UUID']>
  positionId?: Maybe<Scalars['String']>
  shareClassId?: Maybe<Scalars['String']>
}

/** Individual ApprovedExceptions used by both input and output. */
export type ApprovedExceptionInput = {
  accountId?: InputMaybe<Scalars['String']>
  accountName?: InputMaybe<Scalars['String']>
  externalAccountId?: InputMaybe<Scalars['String']>
  externalAccountName?: InputMaybe<Scalars['String']>
  externalFundId?: InputMaybe<Scalars['String']>
  externalFundIdType?: InputMaybe<Scalars['String']>
  externalFundName?: InputMaybe<Scalars['String']>
  externalPositionId?: InputMaybe<Scalars['String']>
  externalShareClassId?: InputMaybe<Scalars['String']>
  externalShareClassIdType?: InputMaybe<Scalars['String']>
  fundId?: InputMaybe<Scalars['String']>
  fundName?: InputMaybe<Scalars['String']>
  id?: InputMaybe<Scalars['UUID']>
  positionId?: InputMaybe<Scalars['String']>
  shareClassId?: InputMaybe<Scalars['String']>
}

export type ArrayFilterInput = {
  overlap: Array<Scalars['String']>
}

export type AuditInfo = {
  __typename?: 'AuditInfo'
  iamUserIdFromAuthenticatedSession?: Maybe<Scalars['String']>
  isIamMachineCall: Scalars['Boolean']
  jwtUserIdFromAuthenticatedSession?: Maybe<Scalars['String']>
  originId: Scalars['String']
  providedIamUserId?: Maybe<Scalars['String']>
  resource: Scalars['String']
}

export type AustraliaQualification = {
  __typename?: 'AustraliaQualification'
  grossIncomeAtLeast250K: Scalars['Boolean']
  netAssetsOverTwoAndHalfMillion: Scalars['Boolean']
  subscriptionAmountOver500K: Scalars['Boolean']
  wholesaleClient: Scalars['Boolean']
}

export type BankAccountNameAndNumber = {
  __typename?: 'BankAccountNameAndNumber'
  name?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['String']>
}

export enum BasisOfFundAccounting {
  Ifrs = 'IFRS',
  LuxGaap = 'LUX_GAAP',
  Other = 'OTHER',
  UsGaap = 'US_GAAP',
}

export type BenchmarkResult = {
  __typename?: 'BenchmarkResult'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
}

export type BenefitPlan = {
  __typename?: 'BenefitPlan'
  benefitPlanInvestor: Scalars['Boolean']
  equityInterestPercent?: Maybe<Scalars['Int']>
  equityInterestPercentAtLeast25?: Maybe<Scalars['Boolean']>
  pooledInvestmentFund?: Maybe<Scalars['Boolean']>
}

export type BooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>
}

export type BrokerDealerEligibility = {
  __typename?: 'BrokerDealerEligibility'
  agent: Scalars['Boolean']
  employeeOrAssociatedWith: Scalars['Boolean']
  employeeWithIpoControl: Scalars['Boolean']
  member: Scalars['Boolean']
}

export type BrokerDealerOwnerEligibility = {
  __typename?: 'BrokerDealerOwnerEligibility'
  owningBrokerDealer: Scalars['Boolean']
  owningEntity: Scalars['Boolean']
  owningPublicReportingCompany: Scalars['Boolean']
  owningPublicReportingCompanyHavingInterestIn: Scalars['Boolean']
}

export enum BrokerageAdvisoryCapacity {
  Advisory = 'ADVISORY',
  Brokerage = 'BROKERAGE',
  Unknown = 'UNKNOWN',
}

export type BrokerageDetails = {
  __typename?: 'BrokerageDetails'
  accountNumber: Scalars['String']
  bankName: Scalars['String']
}

export type BrokerageDetailsInput = {
  accountNumber: Scalars['String']
  bankName: Scalars['String']
}

/**
 *
 *         Model to capture bulk deletion of structured products output when successfully, otherwise capture error.
 *
 */
export type BulkDeleteStructuredProductsPayload = {
  __typename?: 'BulkDeleteStructuredProductsPayload'
  deletionResponse?: Maybe<BulkDeleteStructuredProductsResponsePayload>
  error?: Maybe<StructuredProductError>
}

export type BulkDeleteStructuredProductsResponsePayload = {
  __typename?: 'BulkDeleteStructuredProductsResponsePayload'
  deletedProductIds: Array<Scalars['ID']>
  failedProductIds: Array<Scalars['ID']>
}

export type Capability = {
  __typename?: 'Capability'
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export type CapitalGainLoss = {
  __typename?: 'CapitalGainLoss'
  direction?: Maybe<Scalars['String']>
  realized?: Maybe<Scalars['String']>
  shortLongTerm?: Maybe<Scalars['String']>
}

export type ClientEntityAffiliations = {
  __typename?: 'ClientEntityAffiliations'
  disregardedEntity?: Maybe<Scalars['Boolean']>
  investmentCompanyExempt?: Maybe<Scalars['Boolean']>
  registeredWithRegulatoryAgency?: Maybe<Scalars['Boolean']>
  secRegistrationNumber?: Maybe<Scalars['String']>
}

export type CommodityProduct = Entity &
  Node &
  Product & {
    __typename?: 'CommodityProduct'
    caisId: Scalars['String']
    category?: Maybe<Scalars['String']>
    currencyCode: ProductStringField
    exchangeId?: Maybe<Scalars['String']>
    id: Scalars['ID']
    identifiers: Array<ProductIdentifier>
    name?: Maybe<Scalars['String']>
    price: Array<ProductPrice>
    productType: ProductType
    publishState: ProductPublishState
    ticker?: Maybe<Scalars['String']>
    version: Scalars['Int']
  }

export type CommonAccountDetails = {
  __typename?: 'CommonAccountDetails'
  affiliations?: Maybe<Affiliations>
  backupWithholding?: Maybe<Scalars['Boolean']>
  benefitPlan?: Maybe<BenefitPlan>
  erisa?: Maybe<Erisa>
  legalAddress?: Maybe<AccountLegalAddress>
  mailingAddress?: Maybe<AccountMailingAddress>
  newIssuesEligibility?: Maybe<NewIssuesEligibility>
  sourceOfFunds?: Maybe<Scalars['String']>
  taxableYearEnd?: Maybe<TaxableYearEnd>
  wireInstructions: Array<WireInstructions>
}

export type CommonStockProduct = Entity &
  Node &
  Product & {
    __typename?: 'CommonStockProduct'
    caisId: Scalars['String']
    category?: Maybe<Scalars['String']>
    currencyCode: ProductStringField
    exchangeId?: Maybe<Scalars['String']>
    id: Scalars['ID']
    identifiers: Array<ProductIdentifier>
    name?: Maybe<Scalars['String']>
    price: Array<ProductPrice>
    productType: ProductType
    publishState: ProductPublishState
    ticker?: Maybe<Scalars['String']>
    version: Scalars['Int']
  }

export type Contact = {
  __typename?: 'Contact'
  address?: Maybe<ContactAddress>
  citizenship?: Maybe<Scalars['String']>
  createdAt: Scalars['IsoDateTime']
  dateOfBirth?: Maybe<Scalars['Date']>
  email?: Maybe<Scalars['String']>
  employerAddress?: Maybe<ContactAddress>
  employerName?: Maybe<Scalars['String']>
  firstName: Scalars['String']
  householdId: Scalars['ID']
  id: Scalars['ID']
  isLatest?: Maybe<Scalars['Boolean']>
  jobTitle?: Maybe<Scalars['String']>
  lastName: Scalars['String']
  maritalStatus?: Maybe<Scalars['String']>
  middleInitial?: Maybe<Scalars['String']>
  phoneNumber?: Maybe<Scalars['String']>
  positionRole?: Maybe<Scalars['String']>
  ssn?: Maybe<Scalars['String']>
  title?: Maybe<Scalars['String']>
  updatedAt: Scalars['IsoDateTime']
  version: Scalars['Int']
}

export type ContactAccountSummary = {
  __typename?: 'ContactAccountSummary'
  contactRoles: Array<ContactRole>
  numberOfAccounts: Scalars['Int']
}

export type ContactAddress = {
  __typename?: 'ContactAddress'
  addressLine1: Scalars['String']
  addressLine2?: Maybe<Scalars['String']>
  city: Scalars['String']
  country: Scalars['String']
  state: Scalars['String']
  zipCode: Scalars['String']
}

export type ContactFilterInput = {
  contactIds?: InputMaybe<IdFilterInput>
  email?: InputMaybe<StringFilterInput>
  firmIds?: InputMaybe<IdFilterInput>
  firstName?: InputMaybe<StringFilterInput>
  lastName?: InputMaybe<StringFilterInput>
  middleInitial?: InputMaybe<StringFilterInput>
}

export type ContactListItem = {
  __typename?: 'ContactListItem'
  accountSummary: ContactAccountSummary
  contact: Contact
  /** @deprecated use household.firm.name instead */
  firmName?: Maybe<Scalars['String']>
  household: Household
}

export type ContactListItemPageResult = PageResult & {
  __typename?: 'ContactListItemPageResult'
  items: Array<ContactListItem>
  pageInfo: PageInfo
}

export type ContactReference = {
  __typename?: 'ContactReference'
  id: Scalars['ID']
  version: Scalars['Int']
}

export enum ContactRole {
  BeneficiaryOwner = 'BENEFICIARY_OWNER',
  ControllingPerson = 'CONTROLLING_PERSON',
  InterestedParty = 'INTERESTED_PARTY',
  JointClient = 'JOINT_CLIENT',
  JointPrimarySigner = 'JOINT_PRIMARY_SIGNER',
  JointTrust = 'JOINT_TRUST',
  PrimaryClient = 'PRIMARY_CLIENT',
  PrimarySigner = 'PRIMARY_SIGNER',
  Signatory = 'SIGNATORY',
  Trustee = 'TRUSTEE',
}

export enum ContactSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  MiddleInitialAsc = 'MIDDLE_INITIAL_ASC',
  MiddleInitialDesc = 'MIDDLE_INITIAL_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

export type Correlation = {
  __typename?: 'Correlation'
  barcapGlobal?: Maybe<Scalars['BigDecimal']>
  hfriFofCompIndex?: Maybe<Scalars['BigDecimal']>
  hfriHfCompIndex?: Maybe<Scalars['BigDecimal']>
  msciWorld?: Maybe<Scalars['BigDecimal']>
  sp500?: Maybe<Scalars['BigDecimal']>
}

export type CreateAddressInput = {
  addressType: AddressType
  alternateEmail?: InputMaybe<Scalars['String']>
  city: Scalars['String']
  country: Scalars['String']
  fax?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  postalCode: Scalars['String']
  primaryEmail?: InputMaybe<Scalars['String']>
  state: Scalars['String']
  street1: Scalars['String']
  street2?: InputMaybe<Scalars['String']>
}

/** For adding a ApprovedExceptions for the QC Tool consistency checks. This lives in the Funds Investments service */
export type CreateApprovedExceptionsInput = {
  approvedExceptions: Array<ApprovedExceptionInput>
}

/** ApprovedExceptions creation output. */
export type CreateApprovedExceptionsOutput = {
  __typename?: 'CreateApprovedExceptionsOutput'
  approvedExceptions: Array<ApprovedException>
}

export type CreateDraftStructuredProductPayload = {
  __typename?: 'CreateDraftStructuredProductPayload'
  error?: Maybe<StructuredProductError>
  structuredProduct?: Maybe<StructuredProduct>
}

export type CreateFirmInput = {
  address?: InputMaybe<CreateAddressInput>
  advisorFirmInfo?: InputMaybe<AdvisorFirmInfoInput>
  attributes?: InputMaybe<Scalars['JSON']>
  capabilities?: InputMaybe<Array<Scalars['String']>>
  externalId?: InputMaybe<Scalars['String']>
  fundAdminFirmInfo?: InputMaybe<FundAdminFirmInfoInput>
  fundManagerFirmInfo?: InputMaybe<FundManagerFirmInfoInput>
  name: Scalars['String']
  parent?: InputMaybe<HierarchyEdgeInput>
  parentFirmAccessLevelLimit?: InputMaybe<Scalars['Int']>
  passwordLoginAllowed: Scalars['Boolean']
  salesforceId?: InputMaybe<Scalars['String']>
}

export type CreateFirmPayload = {
  __typename?: 'CreateFirmPayload'
  firm?: Maybe<Firm>
}

export type CreateFundDocumentInput = {
  applicability: FundDocumentApplicability
  category: FundDocumentCategory
  contentType: FundDocumentContentType
  description?: InputMaybe<Scalars['String']>
  fileName: Scalars['String']
  shareClasses: Array<Scalars['UUID']>
  title: Scalars['String']
  yearMonth?: InputMaybe<Scalars['YearMonth']>
}

export type CreateFundProductAdminInput = {
  fundDetails: FundDetailsAdminInput
  shareClasses: Array<FundShareClassAdminInput>
}

export type CreateFundProductAdminPayload = {
  __typename?: 'CreateFundProductAdminPayload'
  fundProduct: FundProductAdmin
}

export type CreateHouseholdInput = {
  externalId?: InputMaybe<Scalars['String']>
  firmId: Scalars['ID']
  name: Scalars['String']
  teamIds: Array<Scalars['ID']>
}

/** For adding an Issuer Group, Issuer Groups are held in ProductMaster */
export type CreateIssuerGroupInput = {
  name: Scalars['String']
}

export type CreateIssuerPayload = {
  __typename?: 'CreateIssuerPayload'
  error?: Maybe<IssuerError>
  issuer?: Maybe<Issuer>
}

export type CreateReferenceAssetInput = {
  bbgTickers: Array<Scalars['String']>
  currencyCode?: InputMaybe<Scalars['String']>
  isins: Array<Scalars['String']>
  legalName: Scalars['String']
  productType: ReferenceAssetType
  rics: Array<Scalars['String']>
  ticker: Scalars['String']
}

export type CreateReferenceAssetPayload = {
  __typename?: 'CreateReferenceAssetPayload'
  error?: Maybe<ReferenceAssetError>
  referenceAsset?: Maybe<ReferenceAsset>
}

export type CreateRolePayload = {
  __typename?: 'CreateRolePayload'
  role?: Maybe<Role>
}

export type CreateServiceIdentityInput = {
  email: Scalars['String']
  externalId?: InputMaybe<Scalars['String']>
  firmId: Scalars['ID']
  permissionIds: Array<Scalars['String']>
  serviceName: Scalars['String']
}

export type CreateServiceIdentityPayload = {
  __typename?: 'CreateServiceIdentityPayload'
  createdServiceIdentity: CreatedServiceIdentity
}

export type CreateStructuredProductInput = {
  brokerageAdvisoryCapacity?: InputMaybe<BrokerageAdvisoryCapacity>
  identifierType: ProductIdentifierType
  identifierValue: Scalars['String']
}

export type CreateTeamInput = {
  address?: InputMaybe<CreateAddressInput>
  attributes?: InputMaybe<Scalars['JSON']>
  description?: InputMaybe<Scalars['String']>
  name: Scalars['String']
  parents: Array<NestedHierarchyEdgeInput>
  userIds?: InputMaybe<Array<Scalars['ID']>>
}

export type CreateTeamPayload = {
  __typename?: 'CreateTeamPayload'
  team?: Maybe<Team>
}

export type CreateUserInput = {
  address?: InputMaybe<CreateAddressInput>
  attributes?: InputMaybe<Scalars['JSON']>
  email: Scalars['String']
  firstName: Scalars['String']
  isSupervisor?: InputMaybe<Scalars['Boolean']>
  lastName: Scalars['String']
  middleInitial?: InputMaybe<Scalars['String']>
  parents: Array<NestedHierarchyEdgeInput>
  phone?: InputMaybe<Scalars['String']>
  roleIds: Array<Scalars['ID']>
  salesforceId?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
}

export type CreateUserPayload = {
  __typename?: 'CreateUserPayload'
  user?: Maybe<User>
}

export type CreatedServiceIdentity = {
  __typename?: 'CreatedServiceIdentity'
  clientId: Scalars['String']
  clientSecret: Scalars['String']
  serviceIdentity: ServiceIdentity
}

export type CreditRating = {
  __typename?: 'CreditRating'
  agency: CreditRatingAgency
  rating: Scalars['String']
}

export enum CreditRatingAgency {
  Fitch = 'FITCH',
  Moodys = 'MOODYS',
  StandardAndPoors = 'STANDARD_AND_POORS',
}

export type CreditRatingInput = {
  agency: CreditRatingAgency
  rating: Scalars['String']
}

export type Currency = {
  __typename?: 'Currency'
  code: Scalars['String']
  name?: Maybe<Scalars['String']>
}

export enum CurrencyCode {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Ang = 'ANG',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bgn = 'BGN',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Chf = 'CHF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Czk = 'CZK',
  Dkk = 'DKK',
  Dop = 'DOP',
  Egp = 'EGP',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Ggp = 'GGP',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Imp = 'IMP',
  Inr = 'INR',
  Irr = 'IRR',
  Isk = 'ISK',
  Jep = 'JEP',
  Jmd = 'JMD',
  Jpy = 'JPY',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Mkd = 'MKD',
  Mnt = 'MNT',
  Mur = 'MUR',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sos = 'SOS',
  Srd = 'SRD',
  Svc = 'SVC',
  Syp = 'SYP',
  Thb = 'THB',
  Try = 'TRY',
  Ttd = 'TTD',
  Tvd = 'TVD',
  Twd = 'TWD',
  Uah = 'UAH',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Vnd = 'VND',
  Xcd = 'XCD',
  Yer = 'YER',
  Zar = 'ZAR',
  Zwd = 'ZWD',
}

export type CustodianAccountDetails = {
  __typename?: 'CustodianAccountDetails'
  furtherCreditOptions: Array<CustodianFurtherCreditOption>
  name: Scalars['String']
  number: Scalars['String']
  type: CustodianBankAccountType
}

export type CustodianAccountDetailsInput = {
  furtherCreditOptions: Array<CustodianFurtherCreditOptionInput>
  name: Scalars['String']
  number: Scalars['String']
  type: CustodianBankAccountType
}

export enum CustodianBankAccountType {
  Brokerage = 'BROKERAGE',
  Checking = 'CHECKING',
  Savings = 'SAVINGS',
}

export type CustodianBankDetails = {
  __typename?: 'CustodianBankDetails'
  city: Scalars['String']
  country: Scalars['String']
  name: Scalars['String']
  postalCode: Scalars['String']
  state: Scalars['String']
  street1: Scalars['String']
  street2?: Maybe<Scalars['String']>
  swiftCode?: Maybe<Scalars['String']>
  wireTransferCode: Scalars['String']
}

export type CustodianBankDetailsInput = {
  city: Scalars['String']
  country: Scalars['String']
  name: Scalars['String']
  postalCode: Scalars['String']
  state: Scalars['String']
  street1: Scalars['String']
  street2?: InputMaybe<Scalars['String']>
  swiftCode?: InputMaybe<Scalars['String']>
  wireTransferCode: Scalars['String']
}

export type CustodianContact = {
  __typename?: 'CustodianContact'
  email: Scalars['String']
  name: Scalars['String']
  phone?: Maybe<Scalars['String']>
}

export type CustodianContactInput = {
  email: Scalars['String']
  name: Scalars['String']
  phone?: InputMaybe<Scalars['String']>
}

export type CustodianDetails = {
  __typename?: 'CustodianDetails'
  accountNumberRegex?: Maybe<Scalars['String']>
  carbonCopyEmails: Array<Scalars['String']>
  carbonCopyEnabled: Scalars['Boolean']
  contacts: Array<CustodianContact>
  createdAt: Scalars['IsoDateTime']
  createdBy: Scalars['String']
  default: Scalars['Boolean']
  dtcc: Scalars['Boolean']
  dtccCustodianId?: Maybe<Scalars['String']>
  esignAuthenticationMethod: ESignAuthMethod
  esignEnabled: Scalars['Boolean']
  firmOverrides: Scalars['JSON']
  id: Scalars['ID']
  name: Scalars['String']
  nameCode: Scalars['String']
  nonQualifiedInvestorPostfix?: Maybe<Scalars['String']>
  nonQualifiedInvestorPrefix?: Maybe<Scalars['String']>
  platforms?: Maybe<Array<CustodianPlatform>>
  qualifiedInvestorPostfix?: Maybe<Scalars['String']>
  qualifiedInvestorPrefix?: Maybe<Scalars['String']>
  qualifiedInvestorTypes?: Maybe<Scalars['JSON']>
  version: Scalars['ID']
  wireInstructions?: Maybe<CustodianWireInstructions>
}

export enum CustodianDocumentType {
  Addendum = 'ADDENDUM',
  LetterOfInstruction = 'LETTER_OF_INSTRUCTION',
}

export type CustodianFirmOverrideInput = {
  firmId: Scalars['ID']
  nonQualifiedInvestorPostfix?: InputMaybe<Scalars['String']>
  nonQualifiedInvestorPrefix?: InputMaybe<Scalars['String']>
  qualifiedInvestorPostfix?: InputMaybe<Scalars['String']>
  qualifiedInvestorPrefix?: InputMaybe<Scalars['String']>
}

export type CustodianFurtherCreditOption = {
  __typename?: 'CustodianFurtherCreditOption'
  accountName: Scalars['String']
  accountNumber: Scalars['String']
  optionType: CustodianFurtherCreditOptionType
}

export type CustodianFurtherCreditOptionInput = {
  accountName: Scalars['String']
  accountNumber: Scalars['String']
  optionType: CustodianFurtherCreditOptionType
}

export enum CustodianFurtherCreditOptionType {
  Default = 'DEFAULT',
  FidelityCashDistribution = 'FIDELITY_CASH_DISTRIBUTION',
  FidelityRepaymentsSell = 'FIDELITY_REPAYMENTS_SELL',
  FidelityReturnPurchase = 'FIDELITY_RETURN_PURCHASE',
}

export type CustodianInput = {
  accountNumberRegex?: InputMaybe<Scalars['String']>
  carbonCopyEmails: Array<Scalars['String']>
  carbonCopyEnabled: Scalars['Boolean']
  contacts: Array<CustodianContactInput>
  default: Scalars['Boolean']
  dtcc: Scalars['Boolean']
  dtccCustodianId?: InputMaybe<Scalars['String']>
  esignAuthenticationMethod: ESignAuthMethod
  esignEnabled: Scalars['Boolean']
  firmOverrides: Array<CustodianFirmOverrideInput>
  name: Scalars['String']
  nameCode: Scalars['String']
  nonQualifiedInvestorPostfix?: InputMaybe<Scalars['String']>
  nonQualifiedInvestorPrefix?: InputMaybe<Scalars['String']>
  platforms: Array<CustodianPlatformInput>
  qualifiedInvestorPostfix?: InputMaybe<Scalars['String']>
  qualifiedInvestorPrefix?: InputMaybe<Scalars['String']>
  qualifiedInvestorTypes?: InputMaybe<
    Array<CustodianQualifiedInvestorTypeInput>
  >
  wireInstructions?: InputMaybe<CustodianWireInstructionsInput>
}

export type CustodianListEntry = {
  __typename?: 'CustodianListEntry'
  default: Scalars['Boolean']
  id: Scalars['ID']
  name: Scalars['String']
  platforms?: Maybe<Array<CustodianPlatform>>
  version: Scalars['ID']
}

export type CustodianMutations = {
  __typename?: 'CustodianMutations'
  create: CustodianDetails
  update: CustodianDetails
  updateFirmList: FirmCustodianListResponse
}

export type CustodianMutationsCreateArgs = {
  custodian: CustodianInput
}

export type CustodianMutationsUpdateArgs = {
  custodian: CustodianInput
  custodianId: Scalars['ID']
}

export type CustodianMutationsUpdateFirmListArgs = {
  custodianIds: Array<Scalars['ID']>
  firmId: Scalars['ID']
}

export type CustodianNameResult = {
  __typename?: 'CustodianNameResult'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
}

export type CustodianNumberDetails = {
  __typename?: 'CustodianNumberDetails'
  accountNumber: Scalars['String']
  custodianId: Scalars['ID']
  name: Scalars['String']
}

export type CustodianNumberDetailsInput = {
  accountNumber: Scalars['String']
  custodianId: Scalars['ID']
}

export type CustodianPlatform = {
  __typename?: 'CustodianPlatform'
  documentTypes: Array<CustodianDocumentType>
  id: Scalars['ID']
  name: Scalars['String']
}

export type CustodianPlatformInput = {
  id: Scalars['ID']
  name: Scalars['String']
}

export type CustodianPlatformTypeEntry = {
  __typename?: 'CustodianPlatformTypeEntry'
  id: Scalars['ID']
  name: Scalars['String']
}

export type CustodianPlatformTypesResponse = {
  __typename?: 'CustodianPlatformTypesResponse'
  platforms: Array<CustodianPlatformTypeEntry>
}

export type CustodianQualifiedInvestorTypeInput = {
  enumType: Scalars['String']
  values: Array<Scalars['String']>
}

export type CustodianQueries = {
  __typename?: 'CustodianQueries'
  firmCustodianList: FirmCustodianListResponse
  get: CustodianDetails
  platformTypes: CustodianPlatformTypesResponse
  search: CustodianSearchResponse
}

export type CustodianQueriesFirmCustodianListArgs = {
  firmId: Scalars['ID']
}

export type CustodianQueriesGetArgs = {
  id: Scalars['ID']
}

export type CustodianQueriesSearchArgs = {
  input: CustodianSearchInput
}

export type CustodianSearchFilterInput = {
  name?: InputMaybe<StringFilterInput>
}

export type CustodianSearchInput = {
  filter?: InputMaybe<CustodianSearchFilterInput>
  pageRequest?: InputMaybe<PaginationInput>
}

export type CustodianSearchResponse = {
  __typename?: 'CustodianSearchResponse'
  items: Array<CustodianListEntry>
  pageInfo: PageInfo
}

export type CustodianWireInstructions = {
  __typename?: 'CustodianWireInstructions'
  accountDetails: CustodianAccountDetails
  bankDetails: CustodianBankDetails
}

export type CustodianWireInstructionsInput = {
  accountDetails: CustodianAccountDetailsInput
  bankDetails: CustodianBankDetailsInput
}

/** Individual data pipeline entries used by output */
export type DataPipeline = {
  __typename?: 'DataPipeline'
  batchId?: Maybe<Scalars['UUID']>
  batchVersion?: Maybe<Scalars['Int']>
  dateTimeReceived?: Maybe<Scalars['IsoDateTime']>
  fileId?: Maybe<Scalars['UUID']>
  fileOrDataSetName?: Maybe<Scalars['String']>
  fileVersion?: Maybe<Scalars['Int']>
  fundAdminName?: Maybe<Scalars['String']>
  fundData?: Maybe<Array<FundData>>
  lastProcessed?: Maybe<Scalars['IsoDateTime']>
  numOfFund?: Maybe<Scalars['Int']>
  status?: Maybe<Scalars['String']>
}

export type DataPipelineFilterInput = {
  dateTimeReceived?: InputMaybe<InstantFilterInput>
  fileNameOrDatasetName?: InputMaybe<StringFilterInput>
  fundAdminId?: InputMaybe<IdFilterInput>
  fundName?: InputMaybe<StringFilterInput>
  lastProcessed?: InputMaybe<InstantFilterInput>
  status?: InputMaybe<DataPipelineStatusEnumFilterInput>
}

export type DataPipelineOutput = {
  __typename?: 'DataPipelineOutput'
  items?: Maybe<Array<DataPipeline>>
  pageInfo?: Maybe<PageInfo>
}

export enum DataPipelineSort {
  FileDataSetNameAsc = 'FILE_DATA_SET_NAME_ASC',
  FileDataSetNameDesc = 'FILE_DATA_SET_NAME_DESC',
  FundAdminNameAsc = 'FUND_ADMIN_NAME_ASC',
  FundAdminNameDesc = 'FUND_ADMIN_NAME_DESC',
  LastProcessedAsc = 'LAST_PROCESSED_ASC',
  LastProcessedDesc = 'LAST_PROCESSED_DESC',
  ReceivedAtAsc = 'RECEIVED_AT_ASC',
  ReceivedAtDesc = 'RECEIVED_AT_DESC',
}

/** Data PipelineStatus values */
export enum DataPipelineStatus {
  Completed = 'COMPLETED',
  Processing = 'PROCESSING',
  WithErrors = 'WITH_ERRORS',
}

export type DataPipelineStatusEnumFilterInput = {
  eq?: InputMaybe<DataPipelineStatus>
  in?: InputMaybe<Array<DataPipelineStatus>>
  ne?: InputMaybe<DataPipelineStatus>
}

/**
 *
 *         Model to capture issuer deletion output when successfully, otherwise capture error.
 *
 */
export type DeleteIssuerPayload = {
  __typename?: 'DeleteIssuerPayload'
  error?: Maybe<IssuerError>
  id?: Maybe<Scalars['ID']>
}

export type DeleteProductOnboardingDocsInput = {
  documentId: Scalars['Int']
}

/**
 *
 *         Model to capture structured product deletion output when successfully, otherwise capture error.
 *
 */
export type DeleteStructuredProductPayload = {
  __typename?: 'DeleteStructuredProductPayload'
  error?: Maybe<StructuredProductError>
  id?: Maybe<Scalars['ID']>
}

/** A 'DeniedAction' represents the action denied and the corresponding message */
export type DeniedAction = {
  __typename?: 'DeniedAction'
  action: FundProductAccessPolicyAllowedAction
  reasons?: Maybe<Array<FundProductDeniedReason>>
}

export type DocsManagementResult = {
  __typename?: 'DocsManagementResult'
  success: Scalars['Boolean']
}

/** A document from the document-library service */
export type Document = {
  __typename?: 'Document'
  attributes?: Maybe<Scalars['JSON']>
  category: Scalars['String']
  createdAt: Scalars['IsoDateTime']
  documentName: Scalars['String']
  fileSize: Scalars['Int']
  fileType: Scalars['String']
  id: Scalars['ID']
  subcategory: Scalars['String']
  updatedAt: Scalars['IsoDateTime']
  version: Scalars['Int']
}

export type DocumentDownloadInput = {
  documents: Array<DocumentInput>
  fileName?: InputMaybe<Scalars['String']>
  zipped?: InputMaybe<Scalars['Boolean']>
}

export type DocumentDownloadUrl = {
  __typename?: 'DocumentDownloadUrl'
  url?: Maybe<Scalars['String']>
}

export type DocumentFilterInput = {
  accountNumber?: InputMaybe<StringFilterInput>
  accountOwnerName?: InputMaybe<StringFilterInput>
  advisorName?: InputMaybe<StringFilterInput>
  and?: InputMaybe<Array<DocumentFilterInput>>
  category?: InputMaybe<StringFilterInput>
  collectionId?: InputMaybe<IdFilterInput>
  documentCreatedAt?: InputMaybe<InstantFilterInput>
  documentUpdatedAt?: InputMaybe<InstantFilterInput>
  fileCreatedAt?: InputMaybe<InstantFilterInput>
  fileDateTime?: InputMaybe<InstantFilterInput>
  fileName?: InputMaybe<StringFilterInput>
  fileSize?: InputMaybe<IntegerFilterInput>
  fundAdminId?: InputMaybe<StringFilterInput>
  fundName?: InputMaybe<StringFilterInput>
  not?: InputMaybe<Array<DocumentFilterInput>>
  or?: InputMaybe<Array<DocumentFilterInput>>
  subcategory?: InputMaybe<StringFilterInput>
  subscribingEntity?: InputMaybe<StringFilterInput>
  taxIdentificationNumber?: InputMaybe<StringFilterInput>
}

export type DocumentInput = {
  documentId: Scalars['UUID']
  version?: InputMaybe<Scalars['Int']>
}

export type DocumentPageResult = PageResult & {
  __typename?: 'DocumentPageResult'
  items: Array<Document>
  pageInfo: PageInfo
}

export enum DocumentSort {
  AccountNumberAsc = 'ACCOUNT_NUMBER_ASC',
  AccountNumberDesc = 'ACCOUNT_NUMBER_DESC',
  AccountOwnerNameAsc = 'ACCOUNT_OWNER_NAME_ASC',
  AccountOwnerNameDesc = 'ACCOUNT_OWNER_NAME_DESC',
  AdvisorNameAsc = 'ADVISOR_NAME_ASC',
  AdvisorNameDesc = 'ADVISOR_NAME_DESC',
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  DocumentCreatedAtAsc = 'DOCUMENT_CREATED_AT_ASC',
  DocumentCreatedAtDesc = 'DOCUMENT_CREATED_AT_DESC',
  DocumentNameAsc = 'DOCUMENT_NAME_ASC',
  DocumentNameDesc = 'DOCUMENT_NAME_DESC',
  DocumentUpdatedAtAsc = 'DOCUMENT_UPDATED_AT_ASC',
  DocumentUpdatedAtDesc = 'DOCUMENT_UPDATED_AT_DESC',
  FileCreatedAtAsc = 'FILE_CREATED_AT_ASC',
  FileCreatedAtDesc = 'FILE_CREATED_AT_DESC',
  FileDateTimeAsc = 'FILE_DATE_TIME_ASC',
  FileDateTimeDesc = 'FILE_DATE_TIME_DESC',
  FileSizeAsc = 'FILE_SIZE_ASC',
  FileSizeDesc = 'FILE_SIZE_DESC',
  FileTypeAsc = 'FILE_TYPE_ASC',
  FileTypeDesc = 'FILE_TYPE_DESC',
  FundAdminIdAsc = 'FUND_ADMIN_ID_ASC',
  FundAdminIdDesc = 'FUND_ADMIN_ID_DESC',
  FundNameAsc = 'FUND_NAME_ASC',
  FundNameDesc = 'FUND_NAME_DESC',
  SubcategoryAsc = 'SUBCATEGORY_ASC',
  SubcategoryDesc = 'SUBCATEGORY_DESC',
  SubscribingEntityAsc = 'SUBSCRIBING_ENTITY_ASC',
  SubscribingEntityDesc = 'SUBSCRIBING_ENTITY_DESC',
  TaxIdentificationNumberAsc = 'TAX_IDENTIFICATION_NUMBER_ASC',
  TaxIdentificationNumberDesc = 'TAX_IDENTIFICATION_NUMBER_DESC',
  VersionAsc = 'VERSION_ASC',
  VersionDesc = 'VERSION_DESC',
}

/** Input to upload CSV/ZIP of documents to the document library */
export type DocumentSubmissionBulkUploadInput = {
  contentType: Scalars['String']
  fileName: Scalars['String']
  idempotencyKey?: InputMaybe<Scalars['String']>
}

/** Response to upload CSV/ZIP of documents to the document library */
export type DocumentSubmissionBulkUploadResponse = {
  __typename?: 'DocumentSubmissionBulkUploadResponse'
  fileId: Scalars['ID']
  fileVersion: Scalars['Int']
}

export type DownloadLink = {
  __typename?: 'DownloadLink'
  expiresAt: Scalars['IsoDateTime']
  url: Scalars['String']
}

export enum DownsideProtectionType {
  Barrier = 'BARRIER',
  Buffer = 'BUFFER',
  Floor = 'FLOOR',
  Full = 'FULL',
  None = 'NONE',
}

export type DraftUploadFilterInput = {
  createdBy?: InputMaybe<IdFilterInput>
}

export type DraftUploadPublishAllInput = {
  products: Array<DraftUploadPublishInput>
}

export type DraftUploadPublishInput = {
  id: Scalars['ID']
  productUploadResultVersion: Scalars['Int']
  version: Scalars['Int']
}

/**
 * Allows filtering on products upload results by status.
 *         Examples:
 *         - Find successful draft products created during a product upload
 *
 *         query {
 *             draftStructuredProductUploadResults (id: "e418efd4-1b09-458e-9039-788a596e8301", filter: {
 *               status : { eq : SUCCESS }
 *             }){
 *             ### Add the response fields here
 *           }
 *         }
 *
 *         -  Find errors during a product upload
 *
 *            query {
 *             draftStructuredProductUploadResults (id: "e418efd4-1b09-458e-9039-788a596e8301", filter: {
 *               status : { eq : ERROR }
 *             }){
 *             ### Add the response fields here
 *           }
 *         }
 *
 */
export type DraftUploadResultFilterInput = {
  and?: InputMaybe<Array<DraftUploadResultFilterInput>>
  not?: InputMaybe<Array<DraftUploadResultFilterInput>>
  or?: InputMaybe<Array<DraftUploadResultFilterInput>>
  status?: InputMaybe<DraftUploadStatusFilterInput>
}

export enum DraftUploadResultSort {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
}

export enum DraftUploadSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  FileNameAsc = 'FILE_NAME_ASC',
  FileNameDesc = 'FILE_NAME_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
}

export type DraftUploadStatusFilterInput = {
  /** Equals */
  eq?: InputMaybe<StructuredProductUploadStatus>
}

export type DrawDown = {
  __typename?: 'DrawDown'
  committedCapital?: Maybe<Money>
  unfundedCommitment?: Maybe<Money>
  unfundedCommitmentImpact?: Maybe<Money>
}

export type DrawdownSummary = {
  __typename?: 'DrawdownSummary'
  capitalCalledToDate: Money
  committedCapital: Money
  unfundedCommitment: Money
}

export enum ESignAuthMethod {
  Kba = 'KBA',
  None = 'NONE',
  SmsKba = 'SMS_KBA',
}

export type Entity = {
  id: Scalars['ID']
  version: Scalars['Int']
}

export type EntityClientAccountDetails = {
  __typename?: 'EntityClientAccountDetails'
  exemptFromFatcaCode?: Maybe<Scalars['String']>
  exemptPayeeCode?: Maybe<Scalars['String']>
  formationDate?: Maybe<Scalars['Date']>
  placeOfBusiness?: Maybe<PlaceOfBusiness>
  taxClassification?: Maybe<TaxClassification>
}

/** Generic representation of an entity as basic ID->NAME pair. Useful for representing large lists. */
export type EntityName = {
  __typename?: 'EntityName'
  id: Scalars['ID']
  name: Scalars['String']
}

export type EntityTransferFrom = {
  __typename?: 'EntityTransferFrom'
  fromAccountId?: Maybe<Scalars['UUID']>
}

export type EntityTransferTo = {
  __typename?: 'EntityTransferTo'
  toAccountId?: Maybe<Scalars['UUID']>
}

export type EquityProduct = Entity &
  Node &
  Product & {
    __typename?: 'EquityProduct'
    caisId: Scalars['String']
    category?: Maybe<Scalars['String']>
    currencyCode: ProductStringField
    exchangeId?: Maybe<Scalars['String']>
    id: Scalars['ID']
    identifiers: Array<ProductIdentifier>
    name?: Maybe<Scalars['String']>
    price: Array<ProductPrice>
    productType: ProductType
    publishState: ProductPublishState
    ticker?: Maybe<Scalars['String']>
    version: Scalars['Int']
  }

export type Erisa = {
  __typename?: 'Erisa'
  erisaDetails?: Maybe<ErisaDetails>
  insuranceCoDetails?: Maybe<ErisaInsuranceCoDetails>
  insuranceCoSubjectToErisa: Scalars['Boolean']
  subjectToErisa: Scalars['Boolean']
  subjectToErisaOtherReason: Scalars['Boolean']
}

export type ErisaDetails = {
  __typename?: 'ErisaDetails'
  assetsExceedFiveMillion: Scalars['Boolean']
  planFiduciaryIsBankEtc: Scalars['Boolean']
  selfDirectedByAccreditedInvestors: Scalars['Boolean']
}

export type ErisaInsuranceCoDetails = {
  __typename?: 'ErisaInsuranceCoDetails'
  investorsSubjectToErisaPercent?: Maybe<Scalars['Int']>
  planInvestorsPercent?: Maybe<Scalars['Int']>
}

export enum EsgRating {
  MercerReportNotAvailable = 'MERCER_REPORT_NOT_AVAILABLE',
  MercerReportUnderReview = 'MERCER_REPORT_UNDER_REVIEW',
  RatingEsg_1 = 'RATING_ESG_1',
  RatingEsg_2 = 'RATING_ESG_2',
  RatingEsg_3 = 'RATING_ESG_3',
  RatingEsg_4 = 'RATING_ESG_4',
  RatingEsgNotAvailable = 'RATING_ESG_NOT_AVAILABLE',
  RatingEsgUnderReview = 'RATING_ESG_UNDER_REVIEW',
}

export type ExchangeTradedFundProduct = Entity &
  Node &
  Product & {
    __typename?: 'ExchangeTradedFundProduct'
    caisId: Scalars['String']
    category?: Maybe<Scalars['String']>
    currencyCode: ProductStringField
    exchangeId?: Maybe<Scalars['String']>
    id: Scalars['ID']
    identifiers: Array<ProductIdentifier>
    name?: Maybe<Scalars['String']>
    price: Array<ProductPrice>
    productType: ProductType
    publishState: ProductPublishState
    ticker?: Maybe<Scalars['String']>
    version: Scalars['Int']
  }

export type ExpandedStructuredProductOrder = {
  __typename?: 'ExpandedStructuredProductOrder'
  order: StructuredProductOrder
  product: MergedStructuredProductData
}

export type ExpandedStructuredProductOrderForAdvisor = {
  __typename?: 'ExpandedStructuredProductOrderForAdvisor'
  order: StructuredProductOrderForAdvisor
  product: MergedStructuredProductData
}

export type ExternalIds = {
  __typename?: 'ExternalIds'
  fundAdministrators?: Maybe<Array<Scalars['String']>>
  initialKey?: Maybe<Scalars['UUID']>
  legacy?: Maybe<PortalLegacy>
  reportingProviders?: Maybe<Array<ReportingProvider>>
}

export type ExternalIdsInput = {
  fundAdministrators?: InputMaybe<Array<Scalars['String']>>
  initialKey?: InputMaybe<Scalars['UUID']>
  legacy?: InputMaybe<PortalLegacyInput>
  reportingProviders?: InputMaybe<Array<ReportingProviderInput>>
}

export type ExternalIdsOutput = {
  __typename?: 'ExternalIdsOutput'
  externalIds?: Maybe<ExternalIds>
  id: Scalars['ID']
  version: Scalars['Int']
}

export enum FeeStructure {
  Advisory = 'ADVISORY',
  Transactional = 'TRANSACTIONAL',
}

/** A file from the file-storage service */
export type File = {
  __typename?: 'File'
  collectionId?: Maybe<Scalars['ID']>
  contentType: Scalars['String']
  createdAt: Scalars['IsoDateTime']
  domain: FileDomain
  fileName: Scalars['String']
  id: Scalars['ID']
  messageDigest: Scalars['String']
  messageDigestAlgorithm: Scalars['String']
  metadata?: Maybe<FileMetadata>
  metadataMap?: Maybe<Scalars['JSON']>
  size: Scalars['Int']
  version: Scalars['Int']
  versionCreatedAt: Scalars['IsoDateTime']
}

export enum FileDomain {
  Ads = 'ADS',
  Documents = 'DOCUMENTS',
  Legacy = 'LEGACY',
  Transaction = 'TRANSACTION',
}

export type FileFilterInput = {
  category?: InputMaybe<StringFilterInput>
  createdAt?: InputMaybe<InstantFilterInput>
  fileName?: InputMaybe<StringFilterInput>
  fundAdminId?: InputMaybe<StringFilterInput>
  integration?: InputMaybe<StringFilterInput>
  versionCreatedAt?: InputMaybe<InstantFilterInput>
}

export type FileMetadata = {
  __typename?: 'FileMetadata'
  category?: Maybe<Scalars['String']>
  fundAdminId?: Maybe<Scalars['String']>
  integration?: Maybe<Scalars['String']>
}

export type FilePageResult = {
  __typename?: 'FilePageResult'
  items: Array<File>
  pageInfo: PageInfo
}

export enum FileSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  VersionCreatedAtAsc = 'VERSION_CREATED_AT_ASC',
  VersionCreatedAtDesc = 'VERSION_CREATED_AT_DESC',
}

export type FinderFiduciaryEligibility = {
  __typename?: 'FinderFiduciaryEligibility'
  familyOrMaterialSupport: Scalars['Boolean']
  finderOrFiduciary: Scalars['Boolean']
}

export type FinraAffiliations = {
  __typename?: 'FinraAffiliations'
  cftcOrNfaRegistrationNumbers?: Maybe<Scalars['String']>
  institutionalAccount?: Maybe<Scalars['Boolean']>
  registeredWithCftcOrNfa?: Maybe<Scalars['Boolean']>
}

/**
 * An IAM representation of a firm.
 *     A 'firm' represents the legal business entity registered in CAIS and serves as parent for teams and users.
 *     Within the IAM domain, a firm may belong to another firm or be a top-level firm
 */
export type Firm = Entity &
  Node & {
    __typename?: 'Firm'
    address?: Maybe<Address>
    advisorFirmInfo?: Maybe<AdvisorFirmInfo>
    attributes?: Maybe<Scalars['JSON']>
    capabilities: Array<Capability>
    externalId?: Maybe<Scalars['String']>
    firmTypes: Array<FirmType>
    /** @deprecated Use name instead */
    fullName: Scalars['String']
    fundAdminFirmInfo?: Maybe<FundAdminFirmInfo>
    fundManagerFirmInfo?: Maybe<FundManagerFirmInfo>
    id: Scalars['ID']
    name: Scalars['String']
    parent?: Maybe<HierarchyEdge>
    parentFirmAccessLevelLimit?: Maybe<Scalars['Int']>
    passwordLoginAllowed: Scalars['Boolean']
    permissions: Array<Permission>
    roles: Array<Role>
    salesforceId?: Maybe<Scalars['String']>
    ssoProviders: Array<Scalars['String']>
    totalUsers: Scalars['Int']
    userAssignableRoles: Array<Role>
    users: UsersPageResult
    /** @deprecated User validPersonasForFirmRoles instead */
    validArchetypesForFirm: Array<UserType>
    validCapabilitiesForFirm: Array<Capability>
    validPersonasForFirmRoles: Array<Scalars['String']>
    version: Scalars['Int']
  }

/**
 * An IAM representation of a firm.
 *     A 'firm' represents the legal business entity registered in CAIS and serves as parent for teams and users.
 *     Within the IAM domain, a firm may belong to another firm or be a top-level firm
 */
export type FirmUsersArgs = {
  filter?: InputMaybe<UserFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sorts?: InputMaybe<Array<UserSort>>
}

export type FirmCustodianListResponse = {
  __typename?: 'FirmCustodianListResponse'
  custodians: Array<CustodianListEntry>
  customList: Scalars['Boolean']
}

export type FirmFilterInput = {
  firmType?: InputMaybe<FirmType>
  firmTypeExt?: InputMaybe<ArrayFilterInput>
  id?: InputMaybe<IdFilterInput>
  parentFirmId?: InputMaybe<IdFilterInput>
  searchText?: InputMaybe<StringFilterInput>
}

export type FirmIdFilterInput = {
  eq?: InputMaybe<Scalars['ID']>
  in?: InputMaybe<Array<Scalars['ID']>>
  includeSubFirms?: InputMaybe<Scalars['Boolean']>
}

export type FirmIdPayload = {
  __typename?: 'FirmIdPayload'
  id: Scalars['ID']
}

export type FirmShareClassAccessPolicy = {
  __typename?: 'FirmShareClassAccessPolicy'
  firm: FundProductIamFirm
  hasFundAccess: Scalars['Boolean']
  shareClassAccessPolicies: Array<ShareClassAccessPolicyResponse>
}

export type FirmShareClassAccessPolicyPage = PageResult & {
  __typename?: 'FirmShareClassAccessPolicyPage'
  items: Array<FirmShareClassAccessPolicy>
  pageInfo: PageInfo
}

export enum FirmSort {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  TotalUsersAsc = 'TOTAL_USERS_ASC',
  TotalUsersDesc = 'TOTAL_USERS_DESC',
}

export enum FirmType {
  AdvisorFirm = 'ADVISOR_FIRM',
  CaisFirm = 'CAIS_FIRM',
  FundManagerFirm = 'FUND_MANAGER_FIRM',
}

export type FirmsPageResult = PageResult & {
  __typename?: 'FirmsPageResult'
  items: Array<Firm>
  pageInfo: PageInfo
}

/** Canonical Transaction Details */
export type FlatTransaction = {
  __typename?: 'FlatTransaction'
  account: AccountIdName
  action: Scalars['String']
  advisorFirm?: Maybe<AdvisorFirmIdName>
  amount: Money
  amountPerShare?: Maybe<Money>
  audit?: Maybe<AuditInfo>
  batchId?: Maybe<Scalars['UUID']>
  capitalGainLoss?: Maybe<CapitalGainLoss>
  cashPaidAmount?: Maybe<Money>
  createdAt: Scalars['IsoDateTime']
  custodianAccountNumber?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  drawDown?: Maybe<DrawDown>
  entityTransferFrom?: Maybe<EntityTransferFrom>
  entityTransferTo?: Maybe<EntityTransferTo>
  externalCreatedAt?: Maybe<Scalars['IsoDateTime']>
  externalGroupId?: Maybe<Scalars['String']>
  externalIds?: Maybe<ExternalIds>
  fileId?: Maybe<Scalars['UUID']>
  fund: FundIdName
  groupId?: Maybe<Scalars['String']>
  household: HouseholdIdName
  id: Scalars['UUID']
  initialSubscriptionOrCommitmentDate?: Maybe<Scalars['Date']>
  orderId?: Maybe<Scalars['UUID']>
  parentId?: Maybe<Scalars['UUID']>
  percentOfCommitment?: Maybe<Scalars['BigDecimal']>
  positionId: Scalars['UUID']
  productTransferFrom?: Maybe<ProductTransferFrom>
  productTransferTo?: Maybe<ProductTransferTo>
  publicationStatus: Scalars['String']
  receivedAt: Scalars['IsoDateTime']
  redemption?: Maybe<Redemption>
  reinvestedAmount?: Maybe<Money>
  shareClassId: Scalars['UUID']
  shares?: Maybe<Share>
  source: Source
  stage: Scalars['String']
  transactionDate: Scalars['Date']
  transactionSubType?: Maybe<TransactionSubType>
  transactionType?: Maybe<TransactionType>
  transfer?: Maybe<Transfer>
  valuationStatus: Scalars['String']
  version: Scalars['Int']
}

export enum Frequency {
  Annually = 'ANNUALLY',
  AtMaturity = 'AT_MATURITY',
  Bimonthly = 'BIMONTHLY',
  Bullet = 'BULLET',
  Continuous = 'CONTINUOUS',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Once = 'ONCE',
  Other = 'OTHER',
  Quarterly = 'QUARTERLY',
  SemiAnnually = 'SEMI_ANNUALLY',
  Weekly = 'WEEKLY',
}

export enum FundAccess {
  ConduitVehicle = 'CONDUIT_VEHICLE',
  Direct = 'DIRECT',
  Reference = 'REFERENCE',
}

export type FundAccessAggregation = {
  __typename?: 'FundAccessAggregation'
  count: Scalars['Int']
  value: FundAccess
}

export type FundAccessFilterInput = {
  eq?: InputMaybe<FundAccess>
  in?: InputMaybe<Array<FundAccess>>
  ne?: InputMaybe<FundAccess>
}

export type FundAdminFirmInfo = {
  __typename?: 'FundAdminFirmInfo'
  fundAdminId?: Maybe<Scalars['UUID']>
}

export type FundAdminFirmInfoInput = {
  fundAdminId?: InputMaybe<Scalars['UUID']>
}

export type FundAdministrator = {
  __typename?: 'FundAdministrator'
  id: Scalars['ID']
  name: Scalars['String']
}

export type FundAdministratorDetails = {
  __typename?: 'FundAdministratorDetails'
  fundAdminAccountNumber: Scalars['String']
  fundAdminId: Scalars['UUID']
  funds?: Maybe<Array<FundAdministratorFundDetails>>
}

export type FundAdministratorDetailsInput = {
  fundAdminAccountNumber: Scalars['String']
  fundAdminId: Scalars['UUID']
  funds?: InputMaybe<Array<FundAdministratorFundDetailsInput>>
}

export type FundAdministratorFundDetails = {
  __typename?: 'FundAdministratorFundDetails'
  fundAccountNumber: Scalars['String']
  fundId: Scalars['UUID']
}

export type FundAdministratorFundDetailsInput = {
  fundAccountNumber: Scalars['String']
  fundId: Scalars['UUID']
}

export type FundAdministratorPageResult = PageResult & {
  __typename?: 'FundAdministratorPageResult'
  items: Array<FundAdministrator>
  pageInfo: PageInfo
}

export type FundAdministratorTemplate = {
  __typename?: 'FundAdministratorTemplate'
  category: Scalars['String']
  fundAdminId: Scalars['ID']
  fundId?: Maybe<Scalars['ID']>
  templateId: Scalars['ID']
  templateName: Scalars['String']
}

export enum FundAdvisoryType {
  Advisory = 'ADVISORY',
  Brokerage = 'BROKERAGE',
}

export type FundAdvisoryTypeAggregation = {
  __typename?: 'FundAdvisoryTypeAggregation'
  count: Scalars['Int']
  value: FundAdvisoryType
}

export type FundAdvisoryTypeFilterInput = {
  eq?: InputMaybe<FundAdvisoryType>
  in?: InputMaybe<Array<FundAdvisoryType>>
  ne?: InputMaybe<FundAdvisoryType>
}

export type FundAffiliations = {
  __typename?: 'FundAffiliations'
  affiliateOfDiscretionaryAuthorityOrInvestmentAdvice?: Maybe<
    Scalars['Boolean']
  >
  affiliateOfDiscretionaryAuthorityOrInvestmentAdviceFundIds?: Maybe<
    Array<Scalars['ID']>
  >
  discretionaryAuthority?: Maybe<Scalars['Boolean']>
  discretionaryAuthorityFundIds?: Maybe<Array<Scalars['ID']>>
  investmentAdvice?: Maybe<Scalars['Boolean']>
  investmentAdviceFundIds?: Maybe<Array<Scalars['ID']>>
  otherInvestorInSameFund?: Maybe<Scalars['Boolean']>
  otherInvestorNameInSameFund?: Maybe<Scalars['String']>
}

export type FundAggregations = {
  __typename?: 'FundAggregations'
  access: Array<FundAccessAggregation>
  advisoryType: Array<FundAdvisoryTypeAggregation>
  fundManager: Array<UuidAggregation>
  fundManagerShortName: Array<StringAggregation>
  investorLevel: Array<FundInvestorLevelAggregation>
  investorType: Array<FundInvestorTypeAggregation>
  objective: Array<FundObjectiveAggregation>
  securityType: Array<FundSecurityTypeAggregation>
  strategy: Array<StrategyAggregation>
  subscriptionStatus: Array<FundSubscriptionStatusAggregation>
  totalCount: Scalars['Int']
}

export type FundBrokerageFees = {
  __typename?: 'FundBrokerageFees'
  trailPaymentDisplayAs?: Maybe<Scalars['String']>
}

export type FundBrokerageFeesAdmin = {
  __typename?: 'FundBrokerageFeesAdmin'
  allowableIncrementsWithinRange?: Maybe<Scalars['BigDecimal']>
  maximumPlacementFee?: Maybe<Scalars['BigDecimal']>
  minimumPlacementFee?: Maybe<Scalars['BigDecimal']>
  placementFeeCalculationMethod?: Maybe<FundPlacementFeeCalculationMethod>
  placementFeeDescription?: Maybe<Scalars['String']>
  trailPayment?: Maybe<Scalars['BigDecimal']>
  trailPaymentDescription?: Maybe<Scalars['String']>
  trailPaymentDisplayAs?: Maybe<Scalars['String']>
}

export type FundBrokerageFeesAdminInput = {
  allowableIncrementsWithinRange?: InputMaybe<Scalars['BigDecimal']>
  maximumPlacementFee?: InputMaybe<Scalars['BigDecimal']>
  minimumPlacementFee?: InputMaybe<Scalars['BigDecimal']>
  placementFeeCalculationMethod?: InputMaybe<FundPlacementFeeCalculationMethod>
  placementFeeDescription?: InputMaybe<Scalars['String']>
  trailPayment?: InputMaybe<Scalars['BigDecimal']>
  trailPaymentDescription?: InputMaybe<Scalars['String']>
  trailPaymentDisplayAs?: InputMaybe<Scalars['String']>
}

export type FundClosingSchedule = {
  __typename?: 'FundClosingSchedule'
  closingDate: Scalars['Date']
  isFinalClose: Scalars['Boolean']
}

export type FundClosingScheduleAdmin = {
  __typename?: 'FundClosingScheduleAdmin'
  closingDate: Scalars['Date']
  isFinalClose: Scalars['Boolean']
}

export type FundClosingScheduleAdminInput = {
  closingDate: Scalars['Date']
  isFinalClose: Scalars['Boolean']
}

export type FundCustodianExternalIdentifierAdmin = {
  __typename?: 'FundCustodianExternalIdentifierAdmin'
  additionalInvestment?: Maybe<Scalars['Int']>
  capitalCallExternalIdentifier?: Maybe<Scalars['String']>
  externalIdentifier: Scalars['String']
  fidelityServiceAgreement?: Maybe<Scalars['Boolean']>
  firmIdsWaivedFromMinimumInvestmentRequirements?: Maybe<Array<Scalars['ID']>>
  id: Scalars['ID']
  /** @deprecated Use supportedPlatformIds instead */
  iwsAvailable?: Maybe<Scalars['Boolean']>
  minimumInvestment?: Maybe<Scalars['Int']>
  /** @deprecated Use supportedPlatformIds instead */
  nfsAvailable?: Maybe<Scalars['Boolean']>
  /** @deprecated Use supportedPlatformIds instead */
  schwabAiMarketPlaceFund?: Maybe<Scalars['Boolean']>
  supportedPlatformIds?: Maybe<Array<Scalars['ID']>>
}

export type FundCustodianExternalIdentifierAdminInput = {
  additionalInvestment?: InputMaybe<Scalars['Int']>
  capitalCallExternalIdentifier?: InputMaybe<Scalars['String']>
  externalIdentifier: Scalars['String']
  fidelityServiceAgreement?: InputMaybe<Scalars['Boolean']>
  firmIdsWaivedFromMinimumInvestmentRequirements?: InputMaybe<
    Array<Scalars['ID']>
  >
  id: Scalars['ID']
  minimumInvestment?: InputMaybe<Scalars['Int']>
  supportedPlatformIds?: InputMaybe<Array<Scalars['ID']>>
}

export type FundData = {
  __typename?: 'FundData'
  fundName?: Maybe<Scalars['String']>
  fundType?: Maybe<Scalars['String']>
  numOfLineItems?: Maybe<Scalars['Int']>
  pipelineId?: Maybe<Scalars['String']>
  runId?: Maybe<Scalars['String']>
  stage?: Maybe<Scalars['String']>
  status?: Maybe<Scalars['String']>
  transactionPosition?: Maybe<Scalars['String']>
}

export type FundDetailsAdmin = {
  __typename?: 'FundDetailsAdmin'
  generalInformation?: Maybe<FundGeneralInformationAdmin>
  identifiers?: Maybe<FundIdentifiersAdmin>
  legacy?: Maybe<FundLegacyAdmin>
  objectives?: Maybe<FundObjectivesAdmin>
  reportingStatus?: Maybe<FundReportingStatus>
  reportingSubStatus?: Maybe<FundReportingSubStatus>
  sensitiveInformation?: Maybe<FundSensitiveInformationAdmin>
  subscriptionStatus?: Maybe<FundSubscriptionStatus>
  targetPerformance?: Maybe<FundTargetPerformanceAdmin>
  terms?: Maybe<FundTermsAdmin>
  transactionSupport?: Maybe<FundTransactionSupportAdmin>
}

export type FundDetailsAdminInput = {
  generalInformation?: InputMaybe<FundGeneralInformationAdminInput>
  identifiers?: InputMaybe<FundIdentifiersAdminInput>
  legacy?: InputMaybe<FundLegacyAdminInput>
  objectives?: InputMaybe<FundObjectivesAdminInput>
  reportingStatus?: InputMaybe<FundReportingStatus>
  reportingSubStatus?: InputMaybe<FundReportingSubStatus>
  sensitiveInformation?: InputMaybe<FundSensitiveInformationAdminInput>
  subscriptionStatus?: InputMaybe<FundSubscriptionStatus>
  targetPerformance?: InputMaybe<FundTargetPerformanceAdminInput>
  terms?: InputMaybe<FundTermsAdminInput>
  transactionSupport?: InputMaybe<FundTransactionSupportAdminInput>
}

export enum FundDocumentApplicability {
  FundWide = 'FUND_WIDE',
  PerShareClass = 'PER_SHARE_CLASS',
}

export enum FundDocumentCategory {
  AdditionalMarketingMaterials = 'ADDITIONAL_MARKETING_MATERIALS',
  CaisPpm = 'CAIS_PPM',
  FactSheets = 'FACT_SHEETS',
  MercerMonthlyCommentary = 'MERCER_MONTHLY_COMMENTARY',
  MercerNewsFlash = 'MERCER_NEWS_FLASH',
  Presentation = 'PRESENTATION',
  ResearchSummary = 'RESEARCH_SUMMARY',
}

export type FundDocumentCategoryFilterInput = {
  /** Equals */
  eq?: InputMaybe<FundDocumentCategory>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<FundDocumentCategory>>
  /** Not Equals */
  ne?: InputMaybe<FundDocumentCategory>
}

export enum FundDocumentContentType {
  Pdf = 'PDF',
}

export type FundDueDateAdmin = {
  __typename?: 'FundDueDateAdmin'
  dueDateType: FundDueDateType
  offsetDateType?: Maybe<FundOffsetDateType>
  offsetFromInvestmentDate?: Maybe<Scalars['Int']>
  offsetFromInvestmentDateFrequency?: Maybe<FundFrequency>
  specificDueDate?: Maybe<Scalars['Date']>
}

export type FundDueDateAdminInput = {
  dueDateType: FundDueDateType
  offsetDateType?: InputMaybe<FundOffsetDateType>
  offsetFromInvestmentDate?: InputMaybe<Scalars['Int']>
  offsetFromInvestmentDateFrequency?: InputMaybe<FundFrequency>
  specificDueDate?: InputMaybe<Scalars['Date']>
}

export enum FundDueDateType {
  DueDaily = 'DUE_DAILY',
  OffsetFromInvestmentDate = 'OFFSET_FROM_INVESTMENT_DATE',
  SpecificDate = 'SPECIFIC_DATE',
  Tbd = 'TBD',
}

export type FundDueDatesAdmin = {
  __typename?: 'FundDueDatesAdmin'
  dueDates?: Maybe<Array<FundDueDateAdmin>>
  legacyDueDate?: Maybe<FundLegacyDueDateAdmin>
}

export type FundDueDatesAdminInput = {
  dueDates?: InputMaybe<Array<FundDueDateAdminInput>>
  legacyDueDate?: InputMaybe<FundLegacyDueDateAdminInput>
}

export type FundExitFee = {
  __typename?: 'FundExitFee'
  displayAs?: Maybe<Scalars['String']>
}

export type FundExitFeeAdmin = {
  __typename?: 'FundExitFeeAdmin'
  base?: Maybe<FundFeeExpenseBase>
  description?: Maybe<Scalars['String']>
  displayAs?: Maybe<Scalars['String']>
  fee?: Maybe<Scalars['BigDecimal']>
}

export type FundExitFeeAdminInput = {
  base?: InputMaybe<FundFeeExpenseBase>
  description?: InputMaybe<Scalars['String']>
  displayAs?: InputMaybe<Scalars['String']>
  fee?: InputMaybe<Scalars['BigDecimal']>
}

export type FundExitFeesAdmin = {
  __typename?: 'FundExitFeesAdmin'
  exitFees?: Maybe<Array<FundExitFeeAdmin>>
}

export type FundExitFeesAdminInput = {
  exitFees?: InputMaybe<Array<FundExitFeeAdminInput>>
}

export type FundExpense = {
  __typename?: 'FundExpense'
  base?: Maybe<FundFeeExpenseBase>
  description?: Maybe<Scalars['String']>
  displayAs?: Maybe<Scalars['String']>
  effectiveEndDate?: Maybe<Scalars['Date']>
  effectiveStartDate?: Maybe<Scalars['Date']>
  expense?: Maybe<Scalars['BigDecimal']>
}

export type FundExpenseAdmin = {
  __typename?: 'FundExpenseAdmin'
  base?: Maybe<FundFeeExpenseBase>
  description?: Maybe<Scalars['String']>
  displayAs?: Maybe<Scalars['String']>
  effectiveEndDate?: Maybe<Scalars['Date']>
  effectiveStartDate?: Maybe<Scalars['Date']>
  expense?: Maybe<Scalars['BigDecimal']>
  frequency?: Maybe<FundFrequency>
}

export type FundExpenseAdminInput = {
  base?: InputMaybe<FundFeeExpenseBase>
  description?: InputMaybe<Scalars['String']>
  displayAs?: InputMaybe<Scalars['String']>
  effectiveEndDate?: InputMaybe<Scalars['Date']>
  effectiveStartDate?: InputMaybe<Scalars['Date']>
  expense?: InputMaybe<Scalars['BigDecimal']>
  frequency?: InputMaybe<FundFrequency>
}

export type FundExpenses = {
  __typename?: 'FundExpenses'
  expenses?: Maybe<Array<FundExpense>>
  frequency?: Maybe<FundFrequency>
  type?: Maybe<FundFeeExpenseType>
}

export type FundExpensesAdmin = {
  __typename?: 'FundExpensesAdmin'
  expenses?: Maybe<Array<FundExpenseAdmin>>
  frequency?: Maybe<FundFrequency>
  type?: Maybe<FundFeeExpenseType>
}

export type FundExpensesAdminInput = {
  expenses?: InputMaybe<Array<FundExpenseAdminInput>>
  frequency?: InputMaybe<FundFrequency>
  type?: InputMaybe<FundFeeExpenseType>
}

export type FundExternalIdentifierAdmin = {
  __typename?: 'FundExternalIdentifierAdmin'
  partyId: Scalars['ID']
  value: Scalars['String']
}

export type FundExternalIdentifierAdminInput = {
  partyId: Scalars['ID']
  value: Scalars['String']
}

export type FundFee = {
  __typename?: 'FundFee'
  base?: Maybe<FundFeeExpenseBase>
  description?: Maybe<Scalars['String']>
  displayAs?: Maybe<Scalars['String']>
  effectiveEndDate?: Maybe<Scalars['Date']>
  effectiveStartDate?: Maybe<Scalars['Date']>
  fee?: Maybe<Scalars['BigDecimal']>
}

export type FundFeeAdmin = {
  __typename?: 'FundFeeAdmin'
  base?: Maybe<FundFeeExpenseBase>
  description?: Maybe<Scalars['String']>
  displayAs?: Maybe<Scalars['String']>
  effectiveEndDate?: Maybe<Scalars['Date']>
  effectiveStartDate?: Maybe<Scalars['Date']>
  fee?: Maybe<Scalars['BigDecimal']>
  paymentFrequency?: Maybe<FundFeePaymentFrequency>
}

export type FundFeeAdminInput = {
  base?: InputMaybe<FundFeeExpenseBase>
  description?: InputMaybe<Scalars['String']>
  displayAs?: InputMaybe<Scalars['String']>
  effectiveEndDate?: InputMaybe<Scalars['Date']>
  effectiveStartDate?: InputMaybe<Scalars['Date']>
  fee?: InputMaybe<Scalars['BigDecimal']>
  paymentFrequency?: InputMaybe<FundFeePaymentFrequency>
}

export enum FundFeeExpenseBase {
  CollateralizedLoanAssets = 'COLLATERALIZED_LOAN_ASSETS',
  Commitment = 'COMMITMENT',
  GrossAssetValue = 'GROSS_ASSET_VALUE',
  GrossInvestedCapital = 'GROSS_INVESTED_CAPITAL',
  NetAssetValue = 'NET_ASSET_VALUE',
  NetInvestedCapital = 'NET_INVESTED_CAPITAL',
  UnfundedCommitment = 'UNFUNDED_COMMITMENT',
}

export enum FundFeeExpenseType {
  SingleRate = 'SINGLE_RATE',
  TieredRate = 'TIERED_RATE',
}

export enum FundFeePaymentFrequency {
  AnnuallyInAdvance = 'ANNUALLY_IN_ADVANCE',
  AnnuallyInArrears = 'ANNUALLY_IN_ARREARS',
  MonthlyInAdvance = 'MONTHLY_IN_ADVANCE',
  MonthlyInArrears = 'MONTHLY_IN_ARREARS',
  QuarterlyInAdvance = 'QUARTERLY_IN_ADVANCE',
  QuarterlyInArrears = 'QUARTERLY_IN_ARREARS',
  SemiAnnuallyInAdvance = 'SEMI_ANNUALLY_IN_ADVANCE',
  SemiAnnuallyInArrears = 'SEMI_ANNUALLY_IN_ARREARS',
}

export type FundFees = {
  __typename?: 'FundFees'
  fees?: Maybe<Array<FundFee>>
  paymentFrequency?: Maybe<FundFeePaymentFrequency>
  type?: Maybe<FundFeeExpenseType>
}

export type FundFeesAdmin = {
  __typename?: 'FundFeesAdmin'
  fees?: Maybe<Array<FundFeeAdmin>>
  paymentFrequency?: Maybe<FundFeePaymentFrequency>
  type?: Maybe<FundFeeExpenseType>
}

export type FundFeesAdminInput = {
  fees?: InputMaybe<Array<FundFeeAdminInput>>
  paymentFrequency?: InputMaybe<FundFeePaymentFrequency>
  type?: InputMaybe<FundFeeExpenseType>
}

export type FundFilterInput = {
  accessFilter?: InputMaybe<FundAccessFilterInput>
  advisoryTypeFilter?: InputMaybe<FundAdvisoryTypeFilterInput>
  fundManagerFilter?: InputMaybe<UuidFilterInput>
  fundManagerShortName?: InputMaybe<StringFilterInput>
  investorLevelFilter?: InputMaybe<FundInvestorLevelFilterInput>
  investorTypeFilter?: InputMaybe<FundInvestorTypeFilterInput>
  isFocusNetworkFundFilter?: InputMaybe<BooleanFilterInput>
  minimumInvestmentAmount?: InputMaybe<IntegerFilterInput>
  objectiveFilter?: InputMaybe<FundObjectiveFilterInput>
  queryString?: InputMaybe<Scalars['String']>
  queryStringMatchType?: InputMaybe<QueryStringMatchType>
  securityTypeFilter?: InputMaybe<FundSecurityTypeFilterInput>
  strategyFilter?: InputMaybe<FundStrategyFilterInput>
  strategySubStrategyFilter?: InputMaybe<StrategySubStrategyFilterInput>
  subscriptionStatusFilter?: InputMaybe<FundSubscriptionStatusFilterInput>
}

export type FundFilterOption = {
  __typename?: 'FundFilterOption'
  id: Scalars['UUID']
  name: Scalars['String']
}

export type FundFilterOptions = {
  __typename?: 'FundFilterOptions'
  items: Array<FundFilterOption>
  pageInfo: PageInfo
}

export enum FundFrequency {
  AdHoc = 'AD_HOC',
  Annually = 'ANNUALLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  NotApplicable = 'NOT_APPLICABLE',
  Quarterly = 'QUARTERLY',
  SemiAnnually = 'SEMI_ANNUALLY',
  SemiMonthly = 'SEMI_MONTHLY',
  Weekly = 'WEEKLY',
}

export type FundGeneralInformation = {
  __typename?: 'FundGeneralInformation'
  access: FundAccess
  caisPlatformLaunchDate?: Maybe<Scalars['Date']>
  description?: Maybe<Scalars['String']>
  fundManager: FundProductFundManager
  fundingStructure?: Maybe<FundingStructure>
  fundraisingType?: Maybe<FundraisingType>
  generalDisclaimer?: Maybe<Scalars['String']>
  inceptionDate?: Maybe<Scalars['Date']>
  legalName: Scalars['String']
  mercerRating?: Maybe<MercerRating>
  performanceDisclaimer?: Maybe<Scalars['String']>
  securityType: FundSecurityType
  strategy?: Maybe<FundStrategy>
  strategyDisplay?: Maybe<Scalars['String']>
  strategyIfOther?: Maybe<Scalars['String']>
  structureSubType?: Maybe<FundStructureSubType>
  structureType?: Maybe<FundStructureType>
  subStrategy?: Maybe<FundSubStrategy>
  subStrategyDisplay?: Maybe<Scalars['String']>
  subStrategyIfOther?: Maybe<Scalars['String']>
  subscriptionDisclaimer?: Maybe<Scalars['String']>
  trackRecordStartDate?: Maybe<Scalars['Date']>
  videoPlayListId?: Maybe<Scalars['String']>
}

export type FundGeneralInformationAdmin = {
  __typename?: 'FundGeneralInformationAdmin'
  access?: Maybe<FundAccess>
  actThirtyThreeRegistrationIndicator?: Maybe<FundRegistrationIndicator>
  adminId?: Maybe<Scalars['ID']>
  aipServiceBureau?: Maybe<Scalars['Boolean']>
  areSlotsRequired?: Maybe<Scalars['Boolean']>
  benchmarkId?: Maybe<Scalars['ID']>
  caisPlatformLaunchDate?: Maybe<Scalars['Date']>
  capabilities?: Maybe<Array<FundProductCapability>>
  capitalCall?: Maybe<Scalars['Boolean']>
  chartName?: Maybe<Scalars['String']>
  customFund?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  esgRating?: Maybe<EsgRating>
  fundManagerId?: Maybe<Scalars['ID']>
  fundStage?: Maybe<FundStage>
  fundingStructure?: Maybe<FundingStructure>
  fundraisingType?: Maybe<FundraisingType>
  generalDisclaimer?: Maybe<Scalars['String']>
  generalPartnerId?: Maybe<Scalars['ID']>
  geographicFocus?: Maybe<Array<FundGeographicFocus>>
  inboundUnitizedIndicator?: Maybe<FundUnitizedIndicator>
  inceptionDate?: Maybe<Scalars['Date']>
  industry?: Maybe<Scalars['String']>
  jurisdiction?: Maybe<FundJurisdiction>
  legalName?: Maybe<Scalars['String']>
  legalStructure?: Maybe<FundLegalStructure>
  mercerRating?: Maybe<MercerRating>
  numberOfSlotsAvailable?: Maybe<Scalars['Int']>
  outboundUnitizedIndicator?: Maybe<FundUnitizedIndicator>
  performanceDisclaimer?: Maybe<Scalars['String']>
  referenceFund?: Maybe<Scalars['Boolean']>
  sector?: Maybe<Scalars['String']>
  securitySubType?: Maybe<FundSecuritySubType>
  securityType?: Maybe<FundSecurityType>
  shortName?: Maybe<Scalars['String']>
  /** Deprecated. Please use the numberSlotsAvailable, totalNumberOfSlots and areSlotsRequired */
  slots?: Maybe<FundSlotsAdmin>
  strategy?: Maybe<FundStrategy>
  strategyAum?: Maybe<Scalars['BigDecimal']>
  strategyAumAsOfDate?: Maybe<Scalars['Date']>
  /** Other sub strategy should be non null only when strategy is OTHER */
  strategyIfOther?: Maybe<Scalars['String']>
  structureSubType?: Maybe<FundStructureSubType>
  structureType?: Maybe<FundStructureType>
  subStrategy?: Maybe<FundSubStrategy>
  /** Other sub strategy should be non null only when subStrategy is OTHER */
  subStrategyIfOther?: Maybe<Scalars['String']>
  subscriptionDisclaimer?: Maybe<Scalars['String']>
  totalNumberOfSlots?: Maybe<Scalars['Int']>
  trackRecordStartDate?: Maybe<Scalars['Date']>
  /** Derived based on jurisdiction. Populating a fundType results the input type will be ignored */
  type?: Maybe<FundType>
  /** Set underlying as true when the fund product can be used as an underlying of another fund product */
  underlying?: Maybe<Scalars['Boolean']>
  underlyingFundProductId?: Maybe<Array<Scalars['ID']>>
  videoPlayListId?: Maybe<Scalars['String']>
  vintageFundYear?: Maybe<Scalars['Int']>
}

export type FundGeneralInformationAdminInput = {
  access?: InputMaybe<FundAccess>
  actThirtyThreeRegistrationIndicator?: InputMaybe<FundRegistrationIndicator>
  adminId?: InputMaybe<Scalars['ID']>
  aipServiceBureau?: InputMaybe<Scalars['Boolean']>
  areSlotsRequired?: InputMaybe<Scalars['Boolean']>
  benchmarkId?: InputMaybe<Scalars['ID']>
  caisPlatformLaunchDate?: InputMaybe<Scalars['Date']>
  capabilities?: InputMaybe<Array<FundProductCapability>>
  capitalCall?: InputMaybe<Scalars['Boolean']>
  chartName?: InputMaybe<Scalars['String']>
  customFund?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  esgRating?: InputMaybe<EsgRating>
  fundManagerId?: InputMaybe<Scalars['ID']>
  fundStage?: InputMaybe<FundStage>
  fundingStructure?: InputMaybe<FundingStructure>
  fundraisingType?: InputMaybe<FundraisingType>
  generalDisclaimer?: InputMaybe<Scalars['String']>
  generalPartnerId?: InputMaybe<Scalars['ID']>
  geographicFocus?: InputMaybe<Array<FundGeographicFocus>>
  inboundUnitizedIndicator?: InputMaybe<FundUnitizedIndicator>
  inceptionDate?: InputMaybe<Scalars['Date']>
  industry?: InputMaybe<Scalars['String']>
  jurisdiction?: InputMaybe<FundJurisdiction>
  legalName?: InputMaybe<Scalars['String']>
  legalStructure?: InputMaybe<FundLegalStructure>
  mercerRating?: InputMaybe<MercerRating>
  numberOfSlotsAvailable?: InputMaybe<Scalars['Int']>
  outboundUnitizedIndicator?: InputMaybe<FundUnitizedIndicator>
  performanceDisclaimer?: InputMaybe<Scalars['String']>
  referenceFund?: InputMaybe<Scalars['Boolean']>
  sector?: InputMaybe<Scalars['String']>
  securitySubType?: InputMaybe<FundSecuritySubType>
  securityType?: InputMaybe<FundSecurityType>
  shortName?: InputMaybe<Scalars['String']>
  /** Deprecated. Please use the numberSlotsAvailable, totalNumberOfSlots and areSlotsRequired */
  slots?: InputMaybe<FundSlotsAdminInput>
  strategy?: InputMaybe<FundStrategy>
  strategyAum?: InputMaybe<Scalars['BigDecimal']>
  strategyAumAsOfDate?: InputMaybe<Scalars['Date']>
  /** Other sub strategy should be non null only when strategy is OTHER */
  strategyIfOther?: InputMaybe<Scalars['String']>
  structureSubType?: InputMaybe<FundStructureSubType>
  structureType?: InputMaybe<FundStructureType>
  subStrategy?: InputMaybe<FundSubStrategy>
  /** Other sub strategy should be non null only when subStrategy is OTHER */
  subStrategyIfOther?: InputMaybe<Scalars['String']>
  subscriptionDisclaimer?: InputMaybe<Scalars['String']>
  totalNumberOfSlots?: InputMaybe<Scalars['Int']>
  trackRecordStartDate?: InputMaybe<Scalars['Date']>
  /** Derived based on jurisdiction. Populating a fundType results the input type will be ignored */
  type?: InputMaybe<FundType>
  /** Set underlying as true when the fund product can be used as an underlying of another fund product */
  underlying?: InputMaybe<Scalars['Boolean']>
  underlyingFundProductId?: InputMaybe<Array<Scalars['ID']>>
  videoPlayListId?: InputMaybe<Scalars['String']>
  vintageFundYear?: InputMaybe<Scalars['Int']>
}

export enum FundGeographicFocus {
  Africa = 'AFRICA',
  Antarctica = 'ANTARCTICA',
  Asia = 'ASIA',
  Europe = 'EUROPE',
  Global = 'GLOBAL',
  NorthAmerica = 'NORTH_AMERICA',
  Oceania = 'OCEANIA',
  SouthAmerica = 'SOUTH_AMERICA',
}

export enum FundHasAccess {
  DoesNotHaveFundAccess = 'DOES_NOT_HAVE_FUND_ACCESS',
  HasFundAccess = 'HAS_FUND_ACCESS',
}

export type FundIdName = {
  __typename?: 'FundIdName'
  id: Scalars['UUID']
  name?: Maybe<Scalars['String']>
}

export type FundIdentifiersAdmin = {
  __typename?: 'FundIdentifiersAdmin'
  caisInternalSymbols?: Maybe<Array<Scalars['String']>>
  custodianExternalIds?: Maybe<Array<FundCustodianExternalIdentifierAdmin>>
  firmExternalIds?: Maybe<Array<FundExternalIdentifierAdmin>>
  fundAdminExternalIds?: Maybe<Array<FundExternalIdentifierAdmin>>
  fundManagerExternalIds?: Maybe<Array<FundExternalIdentifierAdmin>>
  nsccNumbers?: Maybe<Array<Scalars['String']>>
  tickers?: Maybe<Array<Scalars['String']>>
}

export type FundIdentifiersAdminInput = {
  caisInternalSymbols?: InputMaybe<Array<Scalars['String']>>
  custodianExternalIds?: InputMaybe<
    Array<FundCustodianExternalIdentifierAdminInput>
  >
  firmExternalIds?: InputMaybe<Array<FundExternalIdentifierAdminInput>>
  fundAdminExternalIds?: InputMaybe<Array<FundExternalIdentifierAdminInput>>
  fundManagerExternalIds?: InputMaybe<Array<FundExternalIdentifierAdminInput>>
  nsccNumbers?: InputMaybe<Array<Scalars['String']>>
  tickers?: InputMaybe<Array<Scalars['String']>>
}

export type FundIncentiveFee = {
  __typename?: 'FundIncentiveFee'
  description?: Maybe<Scalars['String']>
  displayAs?: Maybe<Scalars['String']>
  fee?: Maybe<Scalars['BigDecimal']>
  incentiveFeeCrystallizationFrequency?: Maybe<FundFrequency>
  preferredReturnAndHurdleRate?: Maybe<Scalars['BigDecimal']>
}

export type FundIncentiveFeeAdmin = {
  __typename?: 'FundIncentiveFeeAdmin'
  description?: Maybe<Scalars['String']>
  displayAs?: Maybe<Scalars['String']>
  fee?: Maybe<Scalars['BigDecimal']>
  incentiveFeeCrystallizationFrequency?: Maybe<FundFrequency>
  preferredReturnAndHurdleRate?: Maybe<Scalars['BigDecimal']>
}

export type FundIncentiveFeeAdminInput = {
  description?: InputMaybe<Scalars['String']>
  displayAs?: InputMaybe<Scalars['String']>
  fee?: InputMaybe<Scalars['BigDecimal']>
  incentiveFeeCrystallizationFrequency?: InputMaybe<FundFrequency>
  preferredReturnAndHurdleRate?: InputMaybe<Scalars['BigDecimal']>
}

export type FundIncentiveFees = {
  __typename?: 'FundIncentiveFees'
  feeSchedule?: Maybe<Array<Scalars['Date']>>
  fees?: Maybe<Array<FundIncentiveFee>>
}

export type FundIncentiveFeesAdmin = {
  __typename?: 'FundIncentiveFeesAdmin'
  feeSchedule?: Maybe<Array<Scalars['Date']>>
  fees?: Maybe<Array<FundIncentiveFeeAdmin>>
}

export type FundIncentiveFeesAdminInput = {
  feeSchedule?: InputMaybe<Array<Scalars['Date']>>
  fees?: InputMaybe<Array<FundIncentiveFeeAdminInput>>
}

export enum FundInvestorLevel {
  AccreditedInvestor = 'ACCREDITED_INVESTOR',
  NonAccredited = 'NON_ACCREDITED',
  QualifiedClient = 'QUALIFIED_CLIENT',
  QualifiedPurchaser = 'QUALIFIED_PURCHASER',
}

export type FundInvestorLevelAggregation = {
  __typename?: 'FundInvestorLevelAggregation'
  count: Scalars['Int']
  value: FundInvestorLevel
}

export type FundInvestorLevelFilterInput = {
  eq?: InputMaybe<FundInvestorLevel>
  in?: InputMaybe<Array<FundInvestorLevel>>
  ne?: InputMaybe<FundInvestorLevel>
}

export enum FundInvestorType {
  NonUs = 'NON_US',
  UsTaxable = 'US_TAXABLE',
  UsTaxExempt = 'US_TAX_EXEMPT',
}

export type FundInvestorTypeAggregation = {
  __typename?: 'FundInvestorTypeAggregation'
  count: Scalars['Int']
  value: FundInvestorType
}

export type FundInvestorTypeFilterInput = {
  eq?: InputMaybe<FundInvestorType>
  in?: InputMaybe<Array<FundInvestorType>>
  ne?: InputMaybe<FundInvestorType>
}

export enum FundJurisdiction {
  Alabama = 'ALABAMA',
  Alaska = 'ALASKA',
  Argentina = 'ARGENTINA',
  Arizona = 'ARIZONA',
  Arkansas = 'ARKANSAS',
  Aruba = 'ARUBA',
  Australia = 'AUSTRALIA',
  Bahamas = 'BAHAMAS',
  Bermuda = 'BERMUDA',
  Brazil = 'BRAZIL',
  BritishVirginIslands = 'BRITISH_VIRGIN_ISLANDS',
  California = 'CALIFORNIA',
  Canada = 'CANADA',
  CaymanIslands = 'CAYMAN_ISLANDS',
  Chile = 'CHILE',
  Colorado = 'COLORADO',
  Columbia = 'COLUMBIA',
  Connecticut = 'CONNECTICUT',
  Delaware = 'DELAWARE',
  DominicanRepublic = 'DOMINICAN_REPUBLIC',
  ElSalvador = 'EL_SALVADOR',
  Florida = 'FLORIDA',
  Georgia = 'GEORGIA',
  Guatemala = 'GUATEMALA',
  Hawaii = 'HAWAII',
  HongKong = 'HONG_KONG',
  Idaho = 'IDAHO',
  Illinois = 'ILLINOIS',
  Indiana = 'INDIANA',
  Iowa = 'IOWA',
  Ireland = 'IRELAND',
  Israel = 'ISRAEL',
  Jamaica = 'JAMAICA',
  JerseyGuernsey = 'JERSEY_GUERNSEY',
  Kansas = 'KANSAS',
  Kentucky = 'KENTUCKY',
  Louisiana = 'LOUISIANA',
  Luxembourg = 'LUXEMBOURG',
  Maine = 'MAINE',
  Maryland = 'MARYLAND',
  Massachusetts = 'MASSACHUSETTS',
  Mexico = 'MEXICO',
  Michigan = 'MICHIGAN',
  Minnesota = 'MINNESOTA',
  Mississippi = 'MISSISSIPPI',
  Missouri = 'MISSOURI',
  Montana = 'MONTANA',
  Nebraska = 'NEBRASKA',
  Nevada = 'NEVADA',
  NewHampshire = 'NEW_HAMPSHIRE',
  NewJersey = 'NEW_JERSEY',
  NewMexico = 'NEW_MEXICO',
  NewYork = 'NEW_YORK',
  NewZealand = 'NEW_ZEALAND',
  NorthCarolina = 'NORTH_CAROLINA',
  NorthDakota = 'NORTH_DAKOTA',
  Ohio = 'OHIO',
  Oklahoma = 'OKLAHOMA',
  Oregon = 'OREGON',
  OtherJurisdiction = 'OTHER_JURISDICTION',
  Panama = 'PANAMA',
  Pennsylvania = 'PENNSYLVANIA',
  Peru = 'PERU',
  RhodeIsland = 'RHODE_ISLAND',
  Singapore = 'SINGAPORE',
  SouthCarolina = 'SOUTH_CAROLINA',
  SouthDakota = 'SOUTH_DAKOTA',
  Switzerland = 'SWITZERLAND',
  Tennessee = 'TENNESSEE',
  Texas = 'TEXAS',
  TurksAndCaicos = 'TURKS_AND_CAICOS',
  UnitedKingdom = 'UNITED_KINGDOM',
  Uruguay = 'URUGUAY',
  Utah = 'UTAH',
  Venezuela = 'VENEZUELA',
  Vermont = 'VERMONT',
  Virginia = 'VIRGINIA',
  Washington = 'WASHINGTON',
  WashingtonDc = 'WASHINGTON_DC',
  WestVirginia = 'WEST_VIRGINIA',
  Wisconsin = 'WISCONSIN',
  Wyoming = 'WYOMING',
}

export type FundLegacy = {
  __typename?: 'FundLegacy'
  auditHoldBack?: Maybe<Scalars['BigDecimal']>
  exitFee?: Maybe<Scalars['String']>
  highWaterMark?: Maybe<Scalars['Boolean']>
  incentiveFee?: Maybe<Scalars['String']>
  isFocusNetworkFund?: Maybe<Scalars['Boolean']>
  legacyFundPageMode?: Maybe<Scalars['Boolean']>
  lockup?: Maybe<Scalars['String']>
  managementFee?: Maybe<Scalars['String']>
  minimumAdditionalInvestment?: Maybe<Scalars['Int']>
  minimumInitialInvestment?: Maybe<Scalars['Int']>
  noticeForRedemptions?: Maybe<Scalars['String']>
  parentFundProductId?: Maybe<Scalars['UUID']>
  redemptionInterval?: Maybe<Scalars['String']>
  subscriptionInterval?: Maybe<Scalars['String']>
}

export type FundLegacyAdmin = {
  __typename?: 'FundLegacyAdmin'
  auditHoldBack?: Maybe<Scalars['BigDecimal']>
  exitFee?: Maybe<Scalars['String']>
  highWaterMark?: Maybe<Scalars['Boolean']>
  incentiveFee?: Maybe<Scalars['String']>
  isFocusNetworkFund?: Maybe<Scalars['Boolean']>
  legacyFundPageMode?: Maybe<Scalars['Boolean']>
  lockup?: Maybe<Scalars['String']>
  managementFee?: Maybe<Scalars['String']>
  minimumAdditionalInvestment?: Maybe<Scalars['Int']>
  minimumInitialInvestment?: Maybe<Scalars['Int']>
  noticeForRedemptions?: Maybe<Scalars['String']>
  parentFundProductId?: Maybe<Scalars['ID']>
  redemptionInterval?: Maybe<Scalars['String']>
  subscriptionInterval?: Maybe<Scalars['String']>
}

export type FundLegacyAdminInput = {
  auditHoldBack?: InputMaybe<Scalars['BigDecimal']>
  exitFee?: InputMaybe<Scalars['String']>
  highWaterMark?: InputMaybe<Scalars['Boolean']>
  incentiveFee?: InputMaybe<Scalars['String']>
  isFocusNetworkFund?: InputMaybe<Scalars['Boolean']>
  legacyFundPageMode?: InputMaybe<Scalars['Boolean']>
  lockup?: InputMaybe<Scalars['String']>
  managementFee?: InputMaybe<Scalars['String']>
  minimumAdditionalInvestment?: InputMaybe<Scalars['Int']>
  minimumInitialInvestment?: InputMaybe<Scalars['Int']>
  noticeForRedemptions?: InputMaybe<Scalars['String']>
  parentFundProductId?: InputMaybe<Scalars['ID']>
  redemptionInterval?: InputMaybe<Scalars['String']>
  subscriptionInterval?: InputMaybe<Scalars['String']>
}

export type FundLegacyDueDateAdmin = {
  __typename?: 'FundLegacyDueDateAdmin'
  dueDate?: Maybe<Scalars['Date']>
  dueDateType: FundDueDateType
  offsetFromInvestmentDateInDays?: Maybe<Scalars['Int']>
}

export type FundLegacyDueDateAdminInput = {
  dueDate?: InputMaybe<Scalars['Date']>
  dueDateType: FundDueDateType
  offsetFromInvestmentDateInDays?: InputMaybe<Scalars['Int']>
}

export enum FundLegalStructure {
  Company = 'COMPANY',
  DelawareStatutoryTrust = 'DELAWARE_STATUTORY_TRUST',
  LimitedLiabilityCompanyLlc = 'LIMITED_LIABILITY_COMPANY_LLC',
  LimitedLtd = 'LIMITED_LTD',
  LimitedPartnershipLp = 'LIMITED_PARTNERSHIP_LP',
  Other = 'OTHER',
  UnitTrust = 'UNIT_TRUST',
}

export type FundLockUpAdmin = {
  __typename?: 'FundLockUpAdmin'
  displayAs?: Maybe<Scalars['String']>
  period?: Maybe<LockUpPeriod>
  timeFrameInMonths?: Maybe<Scalars['Int']>
  type?: Maybe<LockType>
}

export type FundLockUpAdminInput = {
  displayAs?: InputMaybe<Scalars['String']>
  period?: InputMaybe<LockUpPeriod>
  timeFrameInMonths?: InputMaybe<Scalars['Int']>
  type?: InputMaybe<LockType>
}

export type FundLockup = {
  __typename?: 'FundLockup'
  displayAs?: Maybe<Scalars['String']>
  timeFrameInMonths?: Maybe<Scalars['Int']>
}

export type FundManagerFirmInfo = {
  __typename?: 'FundManagerFirmInfo'
  fundManagerId?: Maybe<Scalars['UUID']>
}

export type FundManagerFirmInfoInput = {
  fundManagerId?: InputMaybe<Scalars['UUID']>
}

export type FundManagerResult = {
  __typename?: 'FundManagerResult'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
  shortName?: Maybe<Scalars['String']>
}

export enum FundObjective {
  DiversifyRisk = 'DIVERSIFY_RISK',
  EnhanceReturns = 'ENHANCE_RETURNS',
  PreserveCapital = 'PRESERVE_CAPITAL',
  SupplementIncome = 'SUPPLEMENT_INCOME',
}

export type FundObjectiveAggregation = {
  __typename?: 'FundObjectiveAggregation'
  count: Scalars['Int']
  value: FundObjective
}

export type FundObjectiveFilterInput = {
  eq?: InputMaybe<FundObjective>
  in?: InputMaybe<Array<FundObjective>>
  ne?: InputMaybe<FundObjective>
}

export type FundObjectiveScoring = {
  __typename?: 'FundObjectiveScoring'
  objective: FundObjective
  score: Scalars['Int']
}

export type FundObjectivesAdmin = {
  __typename?: 'FundObjectivesAdmin'
  description?: Maybe<Scalars['String']>
  primary?: Maybe<FundObjective>
  scoring?: Maybe<Scalars['JSON']>
  secondary?: Maybe<FundObjective>
}

export type FundObjectivesAdminInput = {
  description?: InputMaybe<Scalars['String']>
  primary?: InputMaybe<FundObjective>
  scoring?: InputMaybe<Scalars['JSON']>
  secondary?: InputMaybe<FundObjective>
}

export enum FundOfferingType {
  Marketplace = 'MARKETPLACE',
  NonMarketplace = 'NON_MARKETPLACE',
}

export enum FundOffsetDateType {
  Business = 'BUSINESS',
  Calendar = 'CALENDAR',
}

export type FundPerformanceNetMonthlyReturns = {
  __typename?: 'FundPerformanceNetMonthlyReturns'
  bestMonth?: Maybe<Scalars['BigDecimal']>
  monthlyReturns?: Maybe<Array<NetMonthlyReturns>>
  positiveMonths?: Maybe<Scalars['BigDecimal']>
  worstMonth?: Maybe<Scalars['BigDecimal']>
}

export type FundPerformanceOverview = {
  __typename?: 'FundPerformanceOverview'
  annualizedReturn?: Maybe<FundPerformanceOverviewAnnualizedReturn>
  benchmarkCumulativeValueOf100k?: Maybe<
    Array<FundPerformanceOverviewBenchmarkCumulativeValueOf100k>
  >
  cumulativeInception?: Maybe<Scalars['BigDecimal']>
  cumulativeYearToDate?: Maybe<Scalars['BigDecimal']>
  monthToDate?: Maybe<Scalars['BigDecimal']>
  referenceDate?: Maybe<Scalars['Date']>
  valueOf100k?: Maybe<Array<FundPerformanceOverviewValueOf100k>>
}

export type FundPerformanceOverviewAnnualizedReturn = {
  __typename?: 'FundPerformanceOverviewAnnualizedReturn'
  fiveYears?: Maybe<Scalars['BigDecimal']>
  oneYear?: Maybe<Scalars['BigDecimal']>
  sinceInception?: Maybe<Scalars['BigDecimal']>
  tenYears?: Maybe<Scalars['BigDecimal']>
  threeYears?: Maybe<Scalars['BigDecimal']>
}

export type FundPerformanceOverviewBenchmarkCumulativeValueOf100k = {
  __typename?: 'FundPerformanceOverviewBenchmarkCumulativeValueOf100k'
  cumulativeBenchmarkValues?: Maybe<
    Array<FundPerformanceOverviewBenchmarkCumulativeValueOf100kValues>
  >
  name?: Maybe<Scalars['String']>
}

export type FundPerformanceOverviewBenchmarkCumulativeValueOf100kValues = {
  __typename?: 'FundPerformanceOverviewBenchmarkCumulativeValueOf100kValues'
  date?: Maybe<Scalars['Date']>
  value?: Maybe<Scalars['BigDecimal']>
}

export type FundPerformanceOverviewValueOf100k = {
  __typename?: 'FundPerformanceOverviewValueOf100k'
  date?: Maybe<Scalars['Date']>
  value?: Maybe<Scalars['BigDecimal']>
}

export type FundPerformanceRiskAndCorrelation = {
  __typename?: 'FundPerformanceRiskAndCorrelation'
  annualPerformance?: Maybe<Array<RiskAndCorrelationAnnualPerformance>>
  benchmark?: Maybe<Array<RiskAndCorrelationBenchmark>>
  correlation?: Maybe<Correlation>
  risk?: Maybe<Risk>
}

export enum FundPlacementFeeCalculationMethod {
  Gross = 'GROSS',
  Net = 'NET',
}

export enum FundPolicyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type FundPositionAdvisorFirmsOutput = {
  __typename?: 'FundPositionAdvisorFirmsOutput'
  items: Array<AdvisorFirm>
  pageInfo: PageInfo
}

export type FundProduct = {
  __typename?: 'FundProduct'
  allowedActions?: Maybe<Array<FundProductAccessPolicyAllowedAction>>
  deniedActions?: Maybe<Array<DeniedAction>>
  experience?: Maybe<FundProductExperience>
  generalInformation: FundGeneralInformation
  id: Scalars['UUID']
  legacy?: Maybe<FundLegacy>
  objectives?: Maybe<FundProductObjectives>
  performance?: Maybe<FundProductPerformance>
  shareClasses?: Maybe<Array<FundShareClass>>
  subscriptionStatus?: Maybe<FundSubscriptionStatus>
  targetPerformance?: Maybe<FundTargetPerformance>
  terms?: Maybe<FundTerms>
  transactionSupport?: Maybe<FundTransactionSupport>
}

/** A 'FundProductAccessPolicyAllowedAction' represents the available allowed actions */
export enum FundProductAccessPolicyAllowedAction {
  Approve = 'APPROVE',
  Invest = 'INVEST',
  Learn = 'LEARN',
  View = 'VIEW',
  ViewDocuments = 'VIEW_DOCUMENTS',
  ViewMercer = 'VIEW_MERCER',
  ViewOverview = 'VIEW_OVERVIEW',
  ViewPerformance = 'VIEW_PERFORMANCE',
}

/** A 'FundProductAccessPolicyFilter' represents the filtering criteria that can be applied */
export type FundProductAccessPolicyFilterInput = {
  firmName?: InputMaybe<StringFilterInput>
  fundProductId: Scalars['ID']
  hasFundAccess?: InputMaybe<FundProductHasAccessFilterInput>
  offeringType?: InputMaybe<FundProductAccessPolicyOfferingTypeInput>
}

/** A 'FundProductAccessPolicyOfferingType' represents the filter criteria on offering type property */
export type FundProductAccessPolicyOfferingTypeInput = {
  eq?: InputMaybe<FundOfferingType>
}

/** A 'Operation' represents if the permission is UPSERT or DELETE */
export enum FundProductAccessPolicyOperationType {
  Delete = 'DELETE',
  Upsert = 'UPSERT',
}

/** A 'FundProductAccessPolicy' represents the subject's permission policy to share classes of the fund */
export type FundProductAccessPolicyRequestInput = {
  policies: Array<FundProductShareClassAccessPolicyInput>
}

/** A 'FundProductAccessPolicyResponse' represents the all the access policies at share class level for a subject */
export type FundProductAccessPolicyResponse = {
  __typename?: 'FundProductAccessPolicyResponse'
  policies: Array<FundProductSubjectAccessPolicy>
}

/** A 'FundProductAccessPolicySort' represents the properties that can be sorted on */
export enum FundProductAccessPolicySort {
  FirmNameAsc = 'FIRM_NAME_ASC',
  FirmNameDesc = 'FIRM_NAME_DESC',
}

export type FundProductAdmin = Entity &
  Node & {
    __typename?: 'FundProductAdmin'
    createdAt: Scalars['IsoDateTime']
    createdBy: Scalars['String']
    deleted: Scalars['Boolean']
    deletedAt?: Maybe<Scalars['IsoDateTime']>
    deletedBy?: Maybe<Scalars['String']>
    fundDetails: FundDetailsAdmin
    id: Scalars['ID']
    publishState: FundPublishState
    shareClassIndicator?: Maybe<FundShareClassIndicator>
    shareClasses: Array<FundShareClassAdmin>
    updatedAt?: Maybe<Scalars['IsoDateTime']>
    updatedBy?: Maybe<Scalars['String']>
    version: Scalars['Int']
  }

export type FundProductAdminFilterInput = {
  and?: InputMaybe<Array<FundProductAdminFilterInput>>
  caisId?: InputMaybe<StringFilterInput>
  createdAt?: InputMaybe<InstantFilterInput>
  fundManagerId?: InputMaybe<IdFilterInput>
  fundType?: InputMaybe<FundTypeFilterInput>
  id?: InputMaybe<IdFilterInput>
  legalName?: InputMaybe<StringFilterInput>
  not?: InputMaybe<Array<FundProductAdminFilterInput>>
  or?: InputMaybe<Array<FundProductAdminFilterInput>>
  publishState?: InputMaybe<FundPublishStateFilterInput>
  securityType?: InputMaybe<SecurityTypeFilterInput>
  strategy?: InputMaybe<FundStrategyFilterInput>
  structure?: InputMaybe<FundStructureTypeFilterInput>
  structureSubType?: InputMaybe<FundStructureSubTypeFilterInput>
  underlying?: InputMaybe<Scalars['Boolean']>
}

export type FundProductAdminPageResult = PageResult & {
  __typename?: 'FundProductAdminPageResult'
  items: Array<FundProductAdmin>
  pageInfo: PageInfo
}

export enum FundProductAdminSort {
  ClosingDateAsc = 'CLOSING_DATE_ASC',
  ClosingDateDesc = 'CLOSING_DATE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LegalNameAsc = 'LEGAL_NAME_ASC',
  LegalNameDesc = 'LEGAL_NAME_DESC',
  SubscriptionStatusAsc = 'SUBSCRIPTION_STATUS_ASC',
  SubscriptionStatusDesc = 'SUBSCRIPTION_STATUS_DESC',
}

export enum FundProductCapability {
  Full = 'FULL',
  SubscriptionsOnly = 'SUBSCRIPTIONS_ONLY',
}

/** A 'FundProductDeniedReason' represents the reason a fund product action was denied and the corresponding message */
export type FundProductDeniedReason = {
  __typename?: 'FundProductDeniedReason'
  message: Scalars['String']
  missing: FundProductItemGroupList
  required: FundProductItemGroupList
  type: FundProductDenyReasonType
}

/** A 'FundProductAccessPolicyAllowedAction' represents the type of reason why a fund product action was denied */
export enum FundProductDenyReasonType {
  MissingAgreementAcceptance = 'MISSING_AGREEMENT_ACCEPTANCE',
  MissingCourses = 'MISSING_COURSES',
  MissingLicenses = 'MISSING_LICENSES',
}

export type FundProductDocument = {
  __typename?: 'FundProductDocument'
  applicability: FundDocumentApplicability
  category: FundDocumentCategory
  contentType: FundDocumentContentType
  createdAt: Scalars['IsoDateTime']
  createdBy: UserLite
  description?: Maybe<Scalars['String']>
  fileId: Scalars['UUID']
  fileName: Scalars['String']
  fundProductId: Scalars['UUID']
  id: Scalars['UUID']
  shareClasses: Array<Scalars['UUID']>
  title: Scalars['String']
  updatedAt?: Maybe<Scalars['IsoDateTime']>
  updatedBy?: Maybe<UserLite>
  version: Scalars['Int']
  yearMonth?: Maybe<Scalars['String']>
}

export type FundProductDocumentFilterInput = {
  category?: InputMaybe<FundDocumentCategoryFilterInput>
  createdAt?: InputMaybe<InstantFilterInput>
  deletedAt?: InputMaybe<InstantFilterInput>
  documentId?: InputMaybe<Array<IdFilterInput>>
  fileId?: InputMaybe<Array<IdFilterInput>>
  fileName?: InputMaybe<StringFilterInput>
  title?: InputMaybe<StringFilterInput>
  updatedAt?: InputMaybe<InstantFilterInput>
}

export type FundProductDocumentMutation = {
  __typename?: 'FundProductDocumentMutation'
  create: FundProductDocument
  delete: FundProductDocument
  updateFile: FundProductDocument
  updateMetadata: FundProductDocument
}

export type FundProductDocumentMutationCreateArgs = {
  document: CreateFundDocumentInput
  filePartName: Scalars['String']
}

export type FundProductDocumentMutationDeleteArgs = {
  documentId: Scalars['UUID']
  version: Scalars['Int']
}

export type FundProductDocumentMutationUpdateFileArgs = {
  documentId: Scalars['UUID']
  filePartName: Scalars['String']
  metadata: UpdateFundProductDocumentMetadataInput
  version: Scalars['Int']
}

export type FundProductDocumentMutationUpdateMetadataArgs = {
  documentId: Scalars['UUID']
  metadata: UpdateFundProductDocumentMetadataInput
  version: Scalars['Int']
}

export type FundProductDocumentPageResult = PageResult & {
  __typename?: 'FundProductDocumentPageResult'
  items: Array<FundProductDocument>
  pageInfo: PageInfo
}

export type FundProductDocumentQuery = {
  __typename?: 'FundProductDocumentQuery'
  document: FundProductDocument
  documentVersions: Array<FundProductDocumentVersion>
  documents: FundProductDocumentPageResult
  download: DownloadLink
}

export type FundProductDocumentQueryDocumentArgs = {
  documentId: Scalars['UUID']
}

export type FundProductDocumentQueryDocumentVersionsArgs = {
  documentId: Scalars['UUID']
}

export type FundProductDocumentQueryDocumentsArgs = {
  filter?: InputMaybe<FundProductDocumentFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sorting?: InputMaybe<Array<FundProductDocumentSort>>
}

export type FundProductDocumentQueryDownloadArgs = {
  documentId: Scalars['UUID']
  fileVersion?: InputMaybe<Scalars['Int']>
}

export enum FundProductDocumentSort {
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  YearMonthAsc = 'YEAR_MONTH_ASC',
  YearMonthDesc = 'YEAR_MONTH_DESC',
}

export type FundProductDocumentVersion = {
  __typename?: 'FundProductDocumentVersion'
  createdAt: Scalars['IsoDateTime']
  createdBy: UserLite
  fileName: Scalars['String']
  version: Scalars['Int']
}

export type FundProductExperience = {
  __typename?: 'FundProductExperience'
  showFundSpecificInfoForInvest?: Maybe<Scalars['Boolean']>
}

export type FundProductFundManager = {
  __typename?: 'FundProductFundManager'
  description?: Maybe<Scalars['String']>
  id: Scalars['UUID']
  name: Scalars['String']
  shortName?: Maybe<Scalars['String']>
}

/** A 'FundProductHasAccessFilter' represents the filter criteria on has fund access property */
export type FundProductHasAccessFilterInput = {
  eq?: InputMaybe<FundHasAccess>
}

export type FundProductIamFirm = {
  __typename?: 'FundProductIamFirm'
  id: Scalars['ID']
  name: Scalars['String']
  subscribeToMarketPlace: Scalars['Boolean']
}

/** A 'FundProductIdAndName' represents the fund product ID, name and share classes */
export type FundProductIdAndName = {
  __typename?: 'FundProductIdAndName'
  fundProductId: Scalars['ID']
  legalName: Scalars['String']
  shareClasses: Array<ShareClassIdAndName>
}

/** A 'FundProductIdsAndNames' represents a collection of fund product IDs, names and share classes */
export type FundProductIdsAndNames = {
  __typename?: 'FundProductIdsAndNames'
  fundProducts: Array<FundProductIdAndName>
}

/** A 'FundProductItemGroup' represents a group of items connected with the operator */
export type FundProductItemGroup = {
  __typename?: 'FundProductItemGroup'
  items: Array<Scalars['String']>
  operator: FundProductItemOperator
}

/** A 'FundProductItemGroupList' represents a list of item groups connected with the operator */
export type FundProductItemGroupList = {
  __typename?: 'FundProductItemGroupList'
  groups: Array<FundProductItemGroup>
  operator: FundProductItemOperator
}

/** A 'FundProductItemOperator' represents the operator to connect the items in a group */
export enum FundProductItemOperator {
  And = 'AND',
  Or = 'OR',
}

export type FundProductObjectives = {
  __typename?: 'FundProductObjectives'
  primaryObjective?: Maybe<FundObjective>
  scoring?: Maybe<Array<FundObjectiveScoring>>
  secondaryObjective?: Maybe<FundObjective>
}

export type FundProductPerformance = {
  __typename?: 'FundProductPerformance'
  netMonthlyReturns?: Maybe<FundPerformanceNetMonthlyReturns>
  overview?: Maybe<FundPerformanceOverview>
  riskAndCorrelation?: Maybe<FundPerformanceRiskAndCorrelation>
}

export type FundProductSearchResult = {
  __typename?: 'FundProductSearchResult'
  items: Array<FundProduct>
  pageInfo: PageInfo
  totalCount: Scalars['Int']
}

/** A 'FundProductShareClassAccessModifier' represents the permission modifier */
export enum FundProductShareClassAccessModifier {
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE',
}

/** A 'FundProductShareClassAccessPolicy' represents the subject's permission to a given share class */
export type FundProductShareClassAccessPolicyInput = {
  operations: Array<FundProductShareClassAccessPolicyOperationInput>
  shareClassId: Scalars['ID']
}

/** A 'FundProductShareClassAccessPolicyOperation' represents if permission is to be UPSERT or DELETE */
export type FundProductShareClassAccessPolicyOperationInput = {
  accesses: Array<FundProductShareClassSubjectAccessInput>
  op: FundProductAccessPolicyOperationType
}

/** A 'ShareClassPolicyStatus' represents the permission status */
export enum FundProductShareClassPolicyStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

/** A 'FundProductShareClassPolicyStatusRequest' represents the request to update the policy statuses of share classes of a fund product */
export type FundProductShareClassPolicyStatusRequestInput = {
  shareClassPolicyStatus: Array<ShareClassPolicyStatusRequestInput>
}

/** A 'FundProductShareClassSubjectAccess' represents if permission is to be UPSERT or DELETE */
export type FundProductShareClassSubjectAccessInput = {
  modifier: FundProductShareClassAccessModifier
  subjectID: Scalars['ID']
  subjectType: FundProductShareClassSubjectType
}

/** A 'SubjectType' represents the subject type */
export enum FundProductShareClassSubjectType {
  Firm = 'FIRM',
  Team = 'TEAM',
  User = 'USER',
}

/** A 'FundProductSubjectAccessPolicy' represents the access policy at share class for a subject */
export type FundProductSubjectAccessPolicy = {
  __typename?: 'FundProductSubjectAccessPolicy'
  access: Array<ShareClassAccessPolicy>
  subjectId: Scalars['ID']
  subjectType: FundProductShareClassSubjectType
}

/** A 'FundProductSubjectV1' represents the slim down version of IAM Firm for which access policies are configured */
export type FundProductSubjectV1 = {
  __typename?: 'FundProductSubjectV1'
  name?: Maybe<Scalars['String']>
  policyId: Scalars['UUID']
  subjectId: Scalars['UUID']
  subjectType: FundProductShareClassSubjectType
  subscribeToMarketPlace?: Maybe<Scalars['Boolean']>
}

export enum FundPublishState {
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type FundPublishStateFilterInput = {
  /** Equals */
  eq?: InputMaybe<FundPublishState>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<FundPublishState>>
  /** Not Equals */
  ne?: InputMaybe<FundPublishState>
}

export type FundRedemptionGateAdmin = {
  __typename?: 'FundRedemptionGateAdmin'
  frequency?: Maybe<FundFrequency>
  frequencyType?: Maybe<FundRedemptionGateFrequencyType>
  redemptionGatePercentage?: Maybe<Scalars['BigDecimal']>
  type?: Maybe<FundRedemptionGateType>
}

export type FundRedemptionGateAdminInput = {
  frequency?: InputMaybe<FundFrequency>
  frequencyType?: InputMaybe<FundRedemptionGateFrequencyType>
  redemptionGatePercentage?: InputMaybe<Scalars['BigDecimal']>
  type?: InputMaybe<FundRedemptionGateType>
}

export enum FundRedemptionGateFrequencyType {
  Fiscal = 'FISCAL',
  Rolling = 'ROLLING',
}

export enum FundRedemptionGateType {
  FundLevel = 'FUND_LEVEL',
  InvestorLevel = 'INVESTOR_LEVEL',
  ShareClassLevel = 'SHARE_CLASS_LEVEL',
}

export type FundReference = {
  __typename?: 'FundReference'
  /** @deprecated Fetched from a tactical endpoint. Use at your own risk. */
  aliases: Array<Scalars['String']>
  /** @deprecated Fetched from a tactical endpoint. Use at your own risk. */
  fundAdminId?: Maybe<Scalars['ID']>
  /** @deprecated Fetched from a tactical endpoint. Use at your own risk. */
  id: Scalars['ID']
  /** @deprecated Fetched from a tactical endpoint. Use at your own risk. */
  name: Scalars['String']
  /** @deprecated Fetched from a tactical endpoint. Use at your own risk. */
  parentId?: Maybe<Scalars['ID']>
  /** @deprecated Fetched from a tactical endpoint. Use at your own risk. */
  type: FundReferenceType
}

export type FundReferencePageResult = PageResult & {
  __typename?: 'FundReferencePageResult'
  /** @deprecated FundReference data is fetched from a tactical endpoint. The developers bear no responsibility for the issues created by its use. */
  items: Array<FundReference>
  pageInfo: PageInfo
}

export enum FundReferenceType {
  Fund = 'FUND',
  ShareClass = 'SHARE_CLASS',
  Unknown = 'UNKNOWN',
}

export enum FundRegistrationIndicator {
  Registered = 'REGISTERED',
  Unregistered = 'UNREGISTERED',
}

export enum FundReportingStatus {
  ActiveForPostTradeReporting = 'ACTIVE_FOR_POST_TRADE_REPORTING',
  InActiveForPostTradeReporting = 'IN_ACTIVE_FOR_POST_TRADE_REPORTING',
}

export enum FundReportingSubStatus {
  FirmRequestedOrArrangement = 'FIRM_REQUESTED_OR_ARRANGEMENT',
  Liquidated = 'LIQUIDATED',
  ListedProduct = 'LISTED_PRODUCT',
  RemovedFromPlatform = 'REMOVED_FROM_PLATFORM',
  WentPublic = 'WENT_PUBLIC',
}

export enum FundSecuritySubType {
  Exchange_1013 = 'EXCHANGE_1013',
  ExchangeFund = 'EXCHANGE_FUND',
  PrivateFund = 'PRIVATE_FUND',
  RegisteredInvestmentCompany = 'REGISTERED_INVESTMENT_COMPANY',
}

export enum FundSecurityType {
  HedgeFund = 'HEDGE_FUND',
  OperatingCompany = 'OPERATING_COMPANY',
  PrivateMarkets = 'PRIVATE_MARKETS',
}

export type FundSecurityTypeAggregation = {
  __typename?: 'FundSecurityTypeAggregation'
  count: Scalars['Int']
  value: FundSecurityType
}

export type FundSecurityTypeFilterInput = {
  eq?: InputMaybe<FundSecurityType>
  in?: InputMaybe<Array<FundSecurityType>>
  ne?: InputMaybe<FundSecurityType>
}

export type FundSensitiveInformationAdmin = {
  __typename?: 'FundSensitiveInformationAdmin'
  fundWireInstructions?: Maybe<FundWireInstructionsAdmin>
  taxId?: Maybe<Scalars['String']>
}

export type FundSensitiveInformationAdminInput = {
  fundWireInstructions?: InputMaybe<FundWireInstructionsAdminInput>
  taxId?: InputMaybe<Scalars['String']>
}

export type FundShareClass = {
  __typename?: 'FundShareClass'
  details?: Maybe<FundShareClassDetails>
  id: Scalars['UUID']
  terms?: Maybe<FundShareClassTerms>
}

export type FundShareClassAdmin = {
  __typename?: 'FundShareClassAdmin'
  createdAt?: Maybe<Scalars['IsoDateTime']>
  createdBy?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['IsoDateTime']>
  deletedBy?: Maybe<Scalars['String']>
  details?: Maybe<FundShareClassDetailsAdmin>
  id: Scalars['ID']
  policyStatus?: Maybe<FundPolicyStatus>
  publishState?: Maybe<FundPublishState>
  terms?: Maybe<FundShareClassTermsAdmin>
  updatedAt?: Maybe<Scalars['IsoDateTime']>
  updatedBy?: Maybe<Scalars['String']>
}

export type FundShareClassAdminInput = {
  details: FundShareClassDetailsAdminInput
  id?: InputMaybe<Scalars['ID']>
  terms: FundShareClassTermsAdminInput
}

export type FundShareClassDetails = {
  __typename?: 'FundShareClassDetails'
  fundingStructure?: Maybe<FundingStructure>
  fundraisingType?: Maybe<FundraisingType>
  generalDisclaimer?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  performanceDisclaimer?: Maybe<Scalars['String']>
  subscriptionDisclaimer?: Maybe<Scalars['String']>
}

export type FundShareClassDetailsAdmin = {
  __typename?: 'FundShareClassDetailsAdmin'
  caisPlatformLaunchDate?: Maybe<Scalars['Date']>
  capitalCall?: Maybe<Scalars['Boolean']>
  description?: Maybe<Scalars['String']>
  fundingStructure?: Maybe<FundingStructure>
  fundraisingType?: Maybe<FundraisingType>
  generalDisclaimer?: Maybe<Scalars['String']>
  identifiers?: Maybe<FundIdentifiersAdmin>
  inceptionDate?: Maybe<Scalars['Date']>
  isDefault?: Maybe<Scalars['Boolean']>
  name?: Maybe<Scalars['String']>
  nonMarketplaceDesignatedFirmIds?: Maybe<Array<Scalars['ID']>>
  offeringType?: Maybe<FundOfferingType>
  performanceDisclaimer?: Maybe<Scalars['String']>
  proprietaryNetworkFirmId?: Maybe<Scalars['ID']>
  stage?: Maybe<FundStage>
  subscriptionDisclaimer?: Maybe<Scalars['String']>
  underlyingShareClasses?: Maybe<Array<FundUnderlyingShareClassAdmin>>
}

export type FundShareClassDetailsAdminInput = {
  caisPlatformLaunchDate?: InputMaybe<Scalars['Date']>
  capitalCall?: InputMaybe<Scalars['Boolean']>
  description?: InputMaybe<Scalars['String']>
  fundingStructure?: InputMaybe<FundingStructure>
  fundraisingType?: InputMaybe<FundraisingType>
  generalDisclaimer?: InputMaybe<Scalars['String']>
  identifiers?: InputMaybe<FundIdentifiersAdminInput>
  inceptionDate?: InputMaybe<Scalars['Date']>
  isDefault?: InputMaybe<Scalars['Boolean']>
  name?: InputMaybe<Scalars['String']>
  nonMarketplaceDesignatedFirmIds?: InputMaybe<Array<Scalars['ID']>>
  offeringType?: InputMaybe<FundOfferingType>
  performanceDisclaimer?: InputMaybe<Scalars['String']>
  proprietaryNetworkFirmId?: InputMaybe<Scalars['ID']>
  stage?: InputMaybe<FundStage>
  subscriptionDisclaimer?: InputMaybe<Scalars['String']>
  underlyingShareClasses?: InputMaybe<Array<FundUnderlyingShareClassAdminInput>>
}

export enum FundShareClassIndicator {
  FundHasShareClasses = 'FUND_HAS_SHARE_CLASSES',
  FundHasShareClassBasedOnAdditionalFundDetails = 'FUND_HAS_SHARE_CLASS_BASED_ON_ADDITIONAL_FUND_DETAILS',
}

export type FundShareClassTerms = {
  __typename?: 'FundShareClassTerms'
  advisoryTypes?: Maybe<Array<FundAdvisoryType>>
  auditHoldBack?: Maybe<Scalars['BigDecimal']>
  auditHoldBackDisplayAs?: Maybe<Scalars['String']>
  brokerageFees?: Maybe<FundBrokerageFees>
  exitFees?: Maybe<Array<FundExitFee>>
  expenseCap?: Maybe<FundExpenses>
  incentiveFees?: Maybe<FundIncentiveFees>
  lockup?: Maybe<FundLockup>
  managementFees?: Maybe<FundFees>
  minimumAdditionalInvestment?: Maybe<Scalars['Int']>
  minimumInitialInvestment?: Maybe<Scalars['Int']>
  noticeForRedemptions?: Maybe<Scalars['String']>
  redemptionIntervals?: Maybe<FundFrequency>
  redemptionIntervalsDisplayAs?: Maybe<Scalars['String']>
  sponsorFees?: Maybe<FundFees>
  subscriptionIntervals?: Maybe<FundFrequency>
  subscriptionIntervalsDisplayAs?: Maybe<Scalars['String']>
  transferFrequency?: Maybe<FundFrequency>
  transferFrequencyDisplayAs?: Maybe<Scalars['String']>
  underlyingManagementFeeDisplayAs?: Maybe<Scalars['String']>
}

export type FundShareClassTermsAdmin = {
  __typename?: 'FundShareClassTermsAdmin'
  advisoryTypes?: Maybe<Array<FundAdvisoryType>>
  auditHoldBack?: Maybe<Scalars['BigDecimal']>
  auditHoldBackDisplayAs?: Maybe<Scalars['String']>
  breakPoint?: Maybe<Array<Scalars['BigDecimal']>>
  brokerageFees?: Maybe<FundBrokerageFeesAdmin>
  catchup?: Maybe<Scalars['BigDecimal']>
  clawbackDescription?: Maybe<Scalars['String']>
  clawbackFeature?: Maybe<Scalars['Boolean']>
  closingFrequency?: Maybe<FundFrequency>
  conduitLevelMinimumAdditionalInvestment?: Maybe<Scalars['Int']>
  conduitLevelMinimumInitialInvestment?: Maybe<Scalars['Int']>
  conduitLevelMinimumRedemptionOrWithdrawal?: Maybe<Scalars['Int']>
  conduitLevelMinimumRemainingInvestment?: Maybe<Scalars['Int']>
  currency?: Maybe<CurrencyCode>
  distributionDividendFrequency?: Maybe<FundFrequency>
  exitFees?: Maybe<FundExitFeesAdmin>
  expenseCap?: Maybe<FundExpensesAdmin>
  gpCommitment?: Maybe<Scalars['BigDecimal']>
  gpCommitmentDescription?: Maybe<Scalars['String']>
  hardCap?: Maybe<Scalars['Int']>
  highWaterMark?: Maybe<Scalars['Boolean']>
  incentiveFees?: Maybe<FundIncentiveFeesAdmin>
  lockUp?: Maybe<FundLockUpAdmin>
  managementFees?: Maybe<FundFeesAdmin>
  managerExpenseAccommodation?: Maybe<FundExpensesAdmin>
  minimumAdditionalInvestment?: Maybe<Scalars['Int']>
  minimumInitialInvestment?: Maybe<Scalars['Int']>
  minimumRedemptionOrWithdrawal?: Maybe<Scalars['Int']>
  minimumRemainingInvestment?: Maybe<Scalars['Int']>
  noFaultTermination?: Maybe<Scalars['Boolean']>
  noFaultTerminationDescription?: Maybe<Scalars['String']>
  noticeForRedemptions?: Maybe<Scalars['String']>
  noticeForSubscriptions?: Maybe<Scalars['String']>
  passThroughExpense?: Maybe<Scalars['Boolean']>
  preferredReturnAndHurdleRate?: Maybe<Scalars['BigDecimal']>
  redemptionGates?: Maybe<Array<FundRedemptionGateAdmin>>
  redemptionIntervals?: Maybe<FundFrequency>
  redemptionIntervalsDisplayAs?: Maybe<Scalars['String']>
  reportingStatus?: Maybe<FundReportingStatus>
  reportingSubStatus?: Maybe<FundReportingSubStatus>
  servicingFees?: Maybe<FundFeesAdmin>
  sponsorFees?: Maybe<FundFeesAdmin>
  statedMinimumAdditionalInvestment?: Maybe<Scalars['Int']>
  statedMinimumInitialInvestment?: Maybe<Scalars['Int']>
  statedMinimumRedemptionOrWithdrawal?: Maybe<Scalars['Int']>
  statedMinimumRemainingInvestment?: Maybe<Scalars['Int']>
  subscriptionIntervals?: Maybe<FundFrequency>
  subscriptionIntervalsDisplayAs?: Maybe<Scalars['String']>
  subscriptionStatus?: Maybe<FundSubscriptionStatus>
  transactionFeeOffSet?: Maybe<FundTransactionFeeOffSetAdmin>
  transferFrequency?: Maybe<FundFrequency>
  transferFrequencyDisplayAs?: Maybe<Scalars['String']>
  underlyingManagementFeeDisplayAs?: Maybe<Scalars['String']>
  valuationFrequency?: Maybe<FundFrequency>
  waterFallType?: Maybe<WaterFallType>
}

export type FundShareClassTermsAdminInput = {
  advisoryTypes?: InputMaybe<Array<FundAdvisoryType>>
  auditHoldBack?: InputMaybe<Scalars['BigDecimal']>
  auditHoldBackDisplayAs?: InputMaybe<Scalars['String']>
  breakPoint?: InputMaybe<Array<Scalars['BigDecimal']>>
  brokerageFees?: InputMaybe<FundBrokerageFeesAdminInput>
  catchup?: InputMaybe<Scalars['BigDecimal']>
  clawbackDescription?: InputMaybe<Scalars['String']>
  clawbackFeature?: InputMaybe<Scalars['Boolean']>
  closingFrequency?: InputMaybe<FundFrequency>
  conduitLevelMinimumAdditionalInvestment?: InputMaybe<Scalars['Int']>
  conduitLevelMinimumInitialInvestment?: InputMaybe<Scalars['Int']>
  conduitLevelMinimumRedemptionOrWithdrawal?: InputMaybe<Scalars['Int']>
  conduitLevelMinimumRemainingInvestment?: InputMaybe<Scalars['Int']>
  currency?: InputMaybe<CurrencyCode>
  distributionDividendFrequency?: InputMaybe<FundFrequency>
  exitFees?: InputMaybe<FundExitFeesAdminInput>
  expenseCap?: InputMaybe<FundExpensesAdminInput>
  gpCommitment?: InputMaybe<Scalars['BigDecimal']>
  gpCommitmentDescription?: InputMaybe<Scalars['String']>
  hardCap?: InputMaybe<Scalars['Int']>
  highWaterMark?: InputMaybe<Scalars['Boolean']>
  incentiveFees?: InputMaybe<FundIncentiveFeesAdminInput>
  lockUp?: InputMaybe<FundLockUpAdminInput>
  managementFees?: InputMaybe<FundFeesAdminInput>
  managerExpenseAccommodation?: InputMaybe<FundExpensesAdminInput>
  minimumAdditionalInvestment?: InputMaybe<Scalars['Int']>
  minimumInitialInvestment?: InputMaybe<Scalars['Int']>
  minimumRedemptionOrWithdrawal?: InputMaybe<Scalars['Int']>
  minimumRemainingInvestment?: InputMaybe<Scalars['Int']>
  noFaultTermination?: InputMaybe<Scalars['Boolean']>
  noFaultTerminationDescription?: InputMaybe<Scalars['String']>
  noticeForRedemptions?: InputMaybe<Scalars['String']>
  noticeForSubscriptions?: InputMaybe<Scalars['String']>
  passThroughExpense?: InputMaybe<Scalars['Boolean']>
  preferredReturnAndHurdleRate?: InputMaybe<Scalars['BigDecimal']>
  redemptionGates?: InputMaybe<Array<FundRedemptionGateAdminInput>>
  redemptionIntervals?: InputMaybe<FundFrequency>
  redemptionIntervalsDisplayAs?: InputMaybe<Scalars['String']>
  reportingStatus?: InputMaybe<FundReportingStatus>
  reportingSubStatus?: InputMaybe<FundReportingSubStatus>
  servicingFees?: InputMaybe<FundFeesAdminInput>
  sponsorFees?: InputMaybe<FundFeesAdminInput>
  statedMinimumAdditionalInvestment?: InputMaybe<Scalars['Int']>
  statedMinimumInitialInvestment?: InputMaybe<Scalars['Int']>
  statedMinimumRedemptionOrWithdrawal?: InputMaybe<Scalars['Int']>
  statedMinimumRemainingInvestment?: InputMaybe<Scalars['Int']>
  subscriptionIntervals?: InputMaybe<FundFrequency>
  subscriptionIntervalsDisplayAs?: InputMaybe<Scalars['String']>
  subscriptionStatus?: InputMaybe<FundSubscriptionStatus>
  transactionFeeOffSet?: InputMaybe<FundTransactionFeeOffSetAdminInput>
  transferFrequency?: InputMaybe<FundFrequency>
  transferFrequencyDisplayAs?: InputMaybe<Scalars['String']>
  underlyingManagementFeeDisplayAs?: InputMaybe<Scalars['String']>
  valuationFrequency?: InputMaybe<FundFrequency>
  waterFallType?: InputMaybe<WaterFallType>
}

export type FundSlotsAdmin = {
  __typename?: 'FundSlotsAdmin'
  numberOfSlotsAvailable?: Maybe<Scalars['Int']>
  totalNumberOfSlots?: Maybe<Scalars['Int']>
}

export type FundSlotsAdminInput = {
  numberOfSlotsAvailable?: InputMaybe<Scalars['Int']>
  totalNumberOfSlots?: InputMaybe<Scalars['Int']>
}

export enum FundSort {
  FundingStructureAsc = 'FUNDING_STRUCTURE_ASC',
  FundingStructureDesc = 'FUNDING_STRUCTURE_DESC',
  FundraisingTypeAsc = 'FUNDRAISING_TYPE_ASC',
  FundraisingTypeDesc = 'FUNDRAISING_TYPE_DESC',
  FundAccessAsc = 'FUND_ACCESS_ASC',
  FundAccessDesc = 'FUND_ACCESS_DESC',
  FundAdvisoryTypeAsc = 'FUND_ADVISORY_TYPE_ASC',
  FundAdvisoryTypeDesc = 'FUND_ADVISORY_TYPE_DESC',
  FundManagerAsc = 'FUND_MANAGER_ASC',
  FundManagerDesc = 'FUND_MANAGER_DESC',
  FundManagerShortNameAsc = 'FUND_MANAGER_SHORT_NAME_ASC',
  FundManagerShortNameDesc = 'FUND_MANAGER_SHORT_NAME_DESC',
  FundNameAsc = 'FUND_NAME_ASC',
  FundNameDesc = 'FUND_NAME_DESC',
  InvestorLevelAsc = 'INVESTOR_LEVEL_ASC',
  InvestorLevelDesc = 'INVESTOR_LEVEL_DESC',
  InvestorTypeAsc = 'INVESTOR_TYPE_ASC',
  InvestorTypeDesc = 'INVESTOR_TYPE_DESC',
  LegacyDocumentDueDateAsc = 'LEGACY_DOCUMENT_DUE_DATE_ASC',
  LegacyDocumentDueDateDesc = 'LEGACY_DOCUMENT_DUE_DATE_DESC',
  MercerRatingAsc = 'MERCER_RATING_ASC',
  MercerRatingDesc = 'MERCER_RATING_DESC',
  MinimumInvestmentAmountAsc = 'MINIMUM_INVESTMENT_AMOUNT_ASC',
  MinimumInvestmentAmountDesc = 'MINIMUM_INVESTMENT_AMOUNT_DESC',
  PrimaryObjectiveAsc = 'PRIMARY_OBJECTIVE_ASC',
  PrimaryObjectiveDesc = 'PRIMARY_OBJECTIVE_DESC',
  RedemptionIntervalsAsc = 'REDEMPTION_INTERVALS_ASC',
  RedemptionIntervalsDesc = 'REDEMPTION_INTERVALS_DESC',
  StrategyAsc = 'STRATEGY_ASC',
  StrategyDesc = 'STRATEGY_DESC',
  StrategyDisplayAsc = 'STRATEGY_DISPLAY_ASC',
  StrategyDisplayDesc = 'STRATEGY_DISPLAY_DESC',
  SubscriptionIntervalsAsc = 'SUBSCRIPTION_INTERVALS_ASC',
  SubscriptionIntervalsDesc = 'SUBSCRIPTION_INTERVALS_DESC',
  SubStrategyAsc = 'SUB_STRATEGY_ASC',
  SubStrategyDesc = 'SUB_STRATEGY_DESC',
  SubStrategyDisplayAsc = 'SUB_STRATEGY_DISPLAY_ASC',
  SubStrategyDisplayDesc = 'SUB_STRATEGY_DISPLAY_DESC',
  TaxReportingAsc = 'TAX_REPORTING_ASC',
  TaxReportingDesc = 'TAX_REPORTING_DESC',
}

export enum FundStage {
  FullyRealized = 'FULLY_REALIZED',
  Fundraising = 'FUNDRAISING',
  Harvesting = 'HARVESTING',
  Investing = 'INVESTING',
  LiquidatedFullyReturned = 'LIQUIDATED_FULLY_RETURNED',
  WindingDown = 'WINDING_DOWN',
}

export type FundStatusResult = {
  __typename?: 'FundStatusResult'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
}

export enum FundStrategy {
  DigitalAssets = 'DIGITAL_ASSETS',
  EquityHedge = 'EQUITY_HEDGE',
  EventDriven = 'EVENT_DRIVEN',
  FundOfFunds = 'FUND_OF_FUNDS',
  Infrastructure = 'INFRASTRUCTURE',
  Macro = 'MACRO',
  MultiAsset = 'MULTI_ASSET',
  NaturalResources = 'NATURAL_RESOURCES',
  OtherStrategy = 'OTHER_STRATEGY',
  PrivateDebt = 'PRIVATE_DEBT',
  PrivateEquity = 'PRIVATE_EQUITY',
  RealEstate = 'REAL_ESTATE',
  RelativeValue = 'RELATIVE_VALUE',
  UnknownStrategy = 'UNKNOWN_STRATEGY',
}

export type FundStrategyFilterInput = {
  /** Equals */
  eq?: InputMaybe<FundStrategy>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<FundStrategy>>
  /** Not Equals */
  ne?: InputMaybe<FundStrategy>
}

export type FundStrategySubStrategyInput = {
  strategy: FundStrategy
  subStrategy: FundSubStrategy
}

export enum FundStructureSubType {
  BusinessDevelopmentCompany = 'BUSINESS_DEVELOPMENT_COMPANY',
  Exchange_1031 = 'EXCHANGE_1031',
  IntervalFund = 'INTERVAL_FUND',
  NonTradedReit = 'NON_TRADED_REIT',
  PreferredStock = 'PREFERRED_STOCK',
  PrivatePlacement = 'PRIVATE_PLACEMENT',
  Reit = 'REIT',
  TenderFund = 'TENDER_FUND',
}

export type FundStructureSubTypeFilterInput = {
  /** Equals */
  eq?: InputMaybe<FundStructureSubType>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<FundStructureSubType>>
  /** Not Equals */
  ne?: InputMaybe<FundStructureSubType>
}

export enum FundStructureType {
  FiniteLife = 'FINITE_LIFE',
  Perpetual = 'PERPETUAL',
}

export type FundStructureTypeFilterInput = {
  /** Equals */
  eq?: InputMaybe<FundStructureType>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<FundStructureType>>
  /** Not Equals */
  ne?: InputMaybe<FundStructureType>
}

export enum FundSubStrategy {
  ActiveTrading = 'ACTIVE_TRADING',
  Activist = 'ACTIVIST',
  Buyout = 'BUYOUT',
  Conservative = 'CONSERVATIVE',
  Core = 'CORE',
  CorePlus = 'CORE_PLUS',
  CreditArbitrage = 'CREDIT_ARBITRAGE',
  Cryptocurrency = 'CRYPTOCURRENCY',
  Currency = 'CURRENCY',
  Debt = 'DEBT',
  DirectLending = 'DIRECT_LENDING',
  DiscretionaryThematic = 'DISCRETIONARY_THEMATIC',
  Distressed = 'DISTRESSED',
  DistressedDebt = 'DISTRESSED_DEBT',
  DistressedRestructuring = 'DISTRESSED_RESTRUCTURING',
  Diversified = 'DIVERSIFIED',
  EquityMarketNeutral = 'EQUITY_MARKET_NEUTRAL',
  FixedIncomeAssetBacked = 'FIXED_INCOME_ASSET_BACKED',
  FixedIncomeConvertibleArbitrage = 'FIXED_INCOME_CONVERTIBLE_ARBITRAGE',
  FixedIncomeCorporate = 'FIXED_INCOME_CORPORATE',
  FixedIncomeSovereign = 'FIXED_INCOME_SOVEREIGN',
  FundamentalGrowth = 'FUNDAMENTAL_GROWTH',
  FundamentalValue = 'FUNDAMENTAL_VALUE',
  FundOfFundsSubStrategy = 'FUND_OF_FUNDS_SUB_STRATEGY',
  Growth = 'GROWTH',
  MarketDefensive = 'MARKET_DEFENSIVE',
  MergerArbitrage = 'MERGER_ARBITRAGE',
  Mezzanine = 'MEZZANINE',
  MultiAssetSubStrategy = 'MULTI_ASSET_SUB_STRATEGY',
  MultiStrategy = 'MULTI_STRATEGY',
  NaturalResourcesSubStrategy = 'NATURAL_RESOURCES_SUB_STRATEGY',
  Opportunistic = 'OPPORTUNISTIC',
  OtherEquityMarketNeutral = 'OTHER_EQUITY_MARKET_NEUTRAL',
  OtherSubStrategy = 'OTHER_SUB_STRATEGY',
  PrivateIssueRegulationD = 'PRIVATE_ISSUE_REGULATION_D',
  QuantitativeDirectional = 'QUANTITATIVE_DIRECTIONAL',
  Secondaries = 'SECONDARIES',
  Sector = 'SECTOR',
  ShortBias = 'SHORT_BIAS',
  SpecialSituations = 'SPECIAL_SITUATIONS',
  Strategic = 'STRATEGIC',
  SystematicDiversified = 'SYSTEMATIC_DIVERSIFIED',
  UnknownSubStrategy = 'UNKNOWN_SUB_STRATEGY',
  ValueAdded = 'VALUE_ADDED',
  VentureCapital = 'VENTURE_CAPITAL',
  VentureDebt = 'VENTURE_DEBT',
  Volatility = 'VOLATILITY',
  YieldAlternatives = 'YIELD_ALTERNATIVES',
}

export type FundSubStrategyAggregation = {
  __typename?: 'FundSubStrategyAggregation'
  count: Scalars['Int']
  value: FundSubStrategy
}

export enum FundSubscriptionStatus {
  Closed = 'CLOSED',
  Onboarding = 'ONBOARDING',
  Open = 'OPEN',
  Pending = 'PENDING',
  WaitList = 'WAIT_LIST',
}

export type FundSubscriptionStatusAggregation = {
  __typename?: 'FundSubscriptionStatusAggregation'
  count: Scalars['Int']
  value: FundSubscriptionStatus
}

export type FundSubscriptionStatusFilterInput = {
  eq?: InputMaybe<FundSubscriptionStatus>
  in?: InputMaybe<Array<FundSubscriptionStatus>>
  ne?: InputMaybe<FundSubscriptionStatus>
}

export type FundTargetGrossAndNetAdmin = {
  __typename?: 'FundTargetGrossAndNetAdmin'
  gross?: Maybe<Scalars['BigDecimal']>
  net?: Maybe<Scalars['BigDecimal']>
}

export type FundTargetGrossAndNetAdminInput = {
  gross?: InputMaybe<Scalars['BigDecimal']>
  net?: InputMaybe<Scalars['BigDecimal']>
}

export type FundTargetPerformance = {
  __typename?: 'FundTargetPerformance'
  targetNetIRR?: Maybe<Scalars['BigDecimal']>
  targetNetIRRHigh?: Maybe<Scalars['BigDecimal']>
  targetNetMoic?: Maybe<Scalars['BigDecimal']>
  targetNetMoicHigh?: Maybe<Scalars['BigDecimal']>
  targetNetReturn?: Maybe<Scalars['BigDecimal']>
  targetNetReturnHigh?: Maybe<Scalars['BigDecimal']>
}

export type FundTargetPerformanceAdmin = {
  __typename?: 'FundTargetPerformanceAdmin'
  targetIRR?: Maybe<FundTargetGrossAndNetAdmin>
  targetIRRHigh?: Maybe<FundTargetGrossAndNetAdmin>
  targetLeverage?: Maybe<Scalars['BigDecimal']>
  targetLeverageDescription?: Maybe<Scalars['String']>
  targetLeverageHigh?: Maybe<Scalars['BigDecimal']>
  targetMoic?: Maybe<FundTargetGrossAndNetAdmin>
  targetMoicHigh?: Maybe<FundTargetGrossAndNetAdmin>
  targetReturn?: Maybe<FundTargetGrossAndNetAdmin>
  targetReturnHigh?: Maybe<FundTargetGrossAndNetAdmin>
  targetSharpeRatio?: Maybe<Scalars['BigDecimal']>
  targetSharpeRatioHigh?: Maybe<Scalars['BigDecimal']>
  targetVolatility?: Maybe<Scalars['BigDecimal']>
  targetVolatilityHigh?: Maybe<Scalars['BigDecimal']>
}

export type FundTargetPerformanceAdminInput = {
  targetIRR?: InputMaybe<FundTargetGrossAndNetAdminInput>
  targetIRRHigh?: InputMaybe<FundTargetGrossAndNetAdminInput>
  targetLeverage?: InputMaybe<Scalars['BigDecimal']>
  targetLeverageDescription?: InputMaybe<Scalars['String']>
  targetLeverageHigh?: InputMaybe<Scalars['BigDecimal']>
  targetMoic?: InputMaybe<FundTargetGrossAndNetAdminInput>
  targetMoicHigh?: InputMaybe<FundTargetGrossAndNetAdminInput>
  targetReturn?: InputMaybe<FundTargetGrossAndNetAdminInput>
  targetReturnHigh?: InputMaybe<FundTargetGrossAndNetAdminInput>
  targetSharpeRatio?: InputMaybe<Scalars['BigDecimal']>
  targetSharpeRatioHigh?: InputMaybe<Scalars['BigDecimal']>
  targetVolatility?: InputMaybe<Scalars['BigDecimal']>
  targetVolatilityHigh?: InputMaybe<Scalars['BigDecimal']>
}

export enum FundTaxReporting {
  Form_1099 = 'FORM_1099',
  K1K3 = 'K1_K3',
  Na = 'NA',
  Pfic = 'PFIC',
}

export type FundTerms = {
  __typename?: 'FundTerms'
  fundTermDisplayAs?: Maybe<Scalars['String']>
  fundTermInYears?: Maybe<Scalars['Int']>
  fundTermInYearsDecimal?: Maybe<Scalars['BigDecimal']>
  investorLevel?: Maybe<Array<InvestorLevel>>
  investorType?: Maybe<Array<InvestorType>>
  taxReporting?: Maybe<Array<TaxReporting>>
}

export type FundTermsAdmin = {
  __typename?: 'FundTermsAdmin'
  basisOfAccounting?: Maybe<BasisOfFundAccounting>
  breakPoint?: Maybe<Array<Scalars['BigDecimal']>>
  currencyCode?: Maybe<CurrencyCode>
  fundTermDisplayAs?: Maybe<Scalars['String']>
  fundTermExtensionInYears?: Maybe<Array<Scalars['Int']>>
  fundTermExtensionInYearsDecimal?: Maybe<Array<Scalars['BigDecimal']>>
  fundTermInYears?: Maybe<Scalars['Int']>
  fundTermInYearsDecimal?: Maybe<Scalars['BigDecimal']>
  investmentPeriodExtensionInYears?: Maybe<Array<Scalars['Int']>>
  investmentPeriodExtensionInYearsDecimal?: Maybe<Array<Scalars['BigDecimal']>>
  investmentPeriodInYears?: Maybe<Scalars['Int']>
  investmentPeriodInYearsDecimal?: Maybe<Scalars['BigDecimal']>
  investorLevel?: Maybe<Array<FundInvestorLevel>>
  investorType?: Maybe<Array<FundInvestorType>>
  taxReporting?: Maybe<Array<FundTaxReporting>>
}

export type FundTermsAdminInput = {
  basisOfAccounting?: InputMaybe<BasisOfFundAccounting>
  breakPoint?: InputMaybe<Array<Scalars['BigDecimal']>>
  currencyCode?: InputMaybe<CurrencyCode>
  fundTermDisplayAs?: InputMaybe<Scalars['String']>
  fundTermExtensionInYears?: InputMaybe<Array<Scalars['Int']>>
  fundTermExtensionInYearsDecimal?: InputMaybe<Array<Scalars['BigDecimal']>>
  fundTermInYears?: InputMaybe<Scalars['Int']>
  fundTermInYearsDecimal?: InputMaybe<Scalars['BigDecimal']>
  investmentPeriodExtensionInYears?: InputMaybe<Array<Scalars['Int']>>
  investmentPeriodExtensionInYearsDecimal?: InputMaybe<
    Array<Scalars['BigDecimal']>
  >
  investmentPeriodInYears?: InputMaybe<Scalars['Int']>
  investmentPeriodInYearsDecimal?: InputMaybe<Scalars['BigDecimal']>
  investorLevel?: InputMaybe<Array<FundInvestorLevel>>
  investorType?: InputMaybe<Array<FundInvestorType>>
  taxReporting?: InputMaybe<Array<FundTaxReporting>>
}

export enum FundTransactionElectronicSignatureType {
  Cais = 'CAIS',
  Other = 'OTHER',
  Schwab = 'SCHWAB',
}

export type FundTransactionFeeOffSetAdmin = {
  __typename?: 'FundTransactionFeeOffSetAdmin'
  description?: Maybe<Scalars['String']>
  offset?: Maybe<Scalars['BigDecimal']>
}

export type FundTransactionFeeOffSetAdminInput = {
  description?: InputMaybe<Scalars['String']>
  offset?: InputMaybe<Scalars['BigDecimal']>
}

export type FundTransactionSupport = {
  __typename?: 'FundTransactionSupport'
  closingSchedules?: Maybe<Array<FundClosingSchedule>>
  nextDocumentDueDate?: Maybe<Scalars['String']>
  nextWireDueDate?: Maybe<Scalars['String']>
}

export type FundTransactionSupportAdmin = {
  __typename?: 'FundTransactionSupportAdmin'
  acceptElectronicSignature?: Maybe<Scalars['Boolean']>
  closingSchedule?: Maybe<Array<FundClosingScheduleAdmin>>
  documentDeliveryWorkflowIds?: Maybe<Array<Scalars['ID']>>
  documentDueDate?: Maybe<FundDueDatesAdmin>
  electronicSignatureType?: Maybe<Array<FundTransactionElectronicSignatureType>>
  overrideFatcaRulesAndRequireFatcaForm?: Maybe<Scalars['Boolean']>
  wireDueDate?: Maybe<FundDueDatesAdmin>
}

export type FundTransactionSupportAdminInput = {
  acceptElectronicSignature?: InputMaybe<Scalars['Boolean']>
  closingSchedule?: InputMaybe<Array<FundClosingScheduleAdminInput>>
  documentDeliveryWorkflowIds?: InputMaybe<Array<Scalars['ID']>>
  documentDueDate?: InputMaybe<FundDueDatesAdminInput>
  electronicSignatureType?: InputMaybe<
    Array<FundTransactionElectronicSignatureType>
  >
  overrideFatcaRulesAndRequireFatcaForm?: InputMaybe<Scalars['Boolean']>
  wireDueDate?: InputMaybe<FundDueDatesAdminInput>
}

export enum FundType {
  Offshore = 'OFFSHORE',
  Onshore = 'ONSHORE',
}

export type FundTypeFilterInput = {
  /** Equals */
  eq?: InputMaybe<FundType>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<FundType>>
  /** Not Equals */
  ne?: InputMaybe<FundType>
}

export type FundUnderlyingShareClassAdmin = {
  __typename?: 'FundUnderlyingShareClassAdmin'
  underlyingFundProductId: Scalars['ID']
  underlyingShareClassId: Scalars['ID']
}

export type FundUnderlyingShareClassAdminInput = {
  underlyingFundProductId: Scalars['ID']
  underlyingShareClassId: Scalars['ID']
}

export enum FundUnitizedIndicator {
  CapitalBalance = 'CAPITAL_BALANCE',
  Unitized = 'UNITIZED',
}

export type FundWireInstructionsAdmin = {
  __typename?: 'FundWireInstructionsAdmin'
  abaNumber?: Maybe<Scalars['String']>
  accountName?: Maybe<Scalars['String']>
  accountNumber?: Maybe<Scalars['String']>
  bankName?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  postalCode?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
  street1?: Maybe<Scalars['String']>
  street2?: Maybe<Scalars['String']>
  swiftCode?: Maybe<Scalars['String']>
}

export type FundWireInstructionsAdminInput = {
  abaNumber?: InputMaybe<Scalars['String']>
  accountName?: InputMaybe<Scalars['String']>
  accountNumber?: InputMaybe<Scalars['String']>
  bankName?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  postalCode?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  street1?: InputMaybe<Scalars['String']>
  street2?: InputMaybe<Scalars['String']>
  swiftCode?: InputMaybe<Scalars['String']>
}

export type FundingAccount = {
  __typename?: 'FundingAccount'
  abaOrChipsNumber?: Maybe<Scalars['String']>
  /** The type of bank account. Currently supported values are `BROKERAGE`, `CHECKING`, and `SAVINGS`, but others may be added in the future. */
  accountType?: Maybe<Scalars['String']>
  additionalReference?: Maybe<Scalars['String']>
  bankAccount?: Maybe<BankAccountNameAndNumber>
  clientAccount?: Maybe<BankAccountNameAndNumber>
  forFurtherCreditAccount?: Maybe<BankAccountNameAndNumber>
  swift?: Maybe<Scalars['String']>
}

export type FundingBank = {
  __typename?: 'FundingBank'
  address?: Maybe<AccountAddress>
  bankName?: Maybe<Scalars['String']>
  inFatfCountry?: Maybe<Scalars['Boolean']>
}

export enum FundingStructure {
  Drawdown = 'DRAWDOWN',
  FullyFunded = 'FULLY_FUNDED',
}

export enum FundraisingType {
  ClosedEnded = 'CLOSED_ENDED',
  ContinuouslyOfferedClosedEnded = 'CONTINUOUSLY_OFFERED_CLOSED_ENDED',
  OpenEnded = 'OPEN_ENDED',
}

export type FxProduct = Entity &
  Node &
  Product & {
    __typename?: 'FxProduct'
    caisId: Scalars['String']
    category?: Maybe<Scalars['String']>
    currencyCode: ProductStringField
    exchangeId?: Maybe<Scalars['String']>
    id: Scalars['ID']
    identifiers: Array<ProductIdentifier>
    name?: Maybe<Scalars['String']>
    price: Array<ProductPrice>
    productType: ProductType
    publishState: ProductPublishState
    ticker?: Maybe<Scalars['String']>
    version: Scalars['Int']
  }

export type GainOrLoss = {
  __typename?: 'GainOrLoss'
  investment: Money
  netTotal: Money
  realized: Money
  unrealized: Money
}

export type GetProductOnboardingDocsInput = {
  fundId: Scalars['String']
}

export type HierarchyEdge = {
  __typename?: 'HierarchyEdge'
  attributes: Scalars['JSON']
  childId: Scalars['ID']
  childType: NestedHierarchyEdgeType
  entityType: NestedHierarchyEdgeType
  id: Scalars['ID']
  name: Scalars['String']
  parentId: Scalars['ID']
  parentName?: Maybe<Scalars['String']>
  parentType: NestedHierarchyEdgeType
}

export type HierarchyEdgeInput = {
  attributes: Scalars['JSON']
  childId: Scalars['ID']
  childType: NestedHierarchyEdgeType
  entityType: NestedHierarchyEdgeType
  id: Scalars['ID']
  name: Scalars['String']
  parentId: Scalars['ID']
  parentName?: InputMaybe<Scalars['String']>
  parentType: NestedHierarchyEdgeType
}

export type Holiday = {
  __typename?: 'Holiday'
  country: Scalars['String']
  date: Scalars['Date']
  name: Scalars['String']
  observed: Scalars['Date']
  public: Scalars['Boolean']
}

/** An investor profile representation of a household.  */
export type Household = Node & {
  __typename?: 'Household'
  createdAt: Scalars['IsoDateTime']
  externalId?: Maybe<Scalars['String']>
  firm?: Maybe<Firm>
  firmId: Scalars['ID']
  id: Scalars['ID']
  name: Scalars['String']
  teamIds: Array<Scalars['ID']>
  updatedAt: Scalars['IsoDateTime']
}

export type HouseholdFilterInput = {
  householdName?: InputMaybe<StringFilterInput>
}

export type HouseholdFilterOption = {
  __typename?: 'HouseholdFilterOption'
  id: Scalars['UUID']
  name: Scalars['String']
}

export type HouseholdFilterOptions = {
  __typename?: 'HouseholdFilterOptions'
  items: Array<HouseholdFilterOption>
  pageInfo: PageInfo
}

export type HouseholdIdName = {
  __typename?: 'HouseholdIdName'
  id?: Maybe<Scalars['UUID']>
  name?: Maybe<Scalars['String']>
}

export type HouseholdPageResult = PageResult & {
  __typename?: 'HouseholdPageResult'
  items: Array<Household>
  pageInfo: PageInfo
}

export enum HouseholdSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  HouseholdNameAsc = 'HOUSEHOLD_NAME_ASC',
  HouseholdNameDesc = 'HOUSEHOLD_NAME_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

export type IamEntityIdentifier = Node & {
  __typename?: 'IamEntityIdentifier'
  id: Scalars['ID']
  type: IamEntityType
}

export type IamEntityIdentifierInput = {
  id: Scalars['ID']
  type: IamEntityType
}

export enum IamEntityType {
  Firm = 'FIRM',
  Team = 'TEAM',
  User = 'USER',
}

export type IdFilterInput = {
  /** Equals */
  eq?: InputMaybe<Scalars['ID']>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<Scalars['ID']>>
  /** Not in the provided range of values */
  notIn?: InputMaybe<Array<Scalars['ID']>>
}

export type IndexProduct = Entity &
  Node &
  Product & {
    __typename?: 'IndexProduct'
    caisId: Scalars['String']
    category?: Maybe<Scalars['String']>
    currencyCode: ProductStringField
    exchangeId?: Maybe<Scalars['String']>
    id: Scalars['ID']
    identifiers: Array<ProductIdentifier>
    name?: Maybe<Scalars['String']>
    price: Array<ProductPrice>
    productType: ProductType
    publishState: ProductPublishState
    ticker?: Maybe<Scalars['String']>
    version: Scalars['Int']
  }

export type IndividualAccount = Account & {
  __typename?: 'IndividualAccount'
  accountCategory: Scalars['String']
  accreditedInvestorStatuses?: Maybe<Array<AccreditedInvestorStatusResponse>>
  advisorNotifications?: Maybe<Scalars['Boolean']>
  brokerageDetails?: Maybe<BrokerageDetails>
  clients: IndividualClientsDetails
  commonAccountDetails?: Maybe<CommonAccountDetails>
  createdAt: Scalars['IsoDateTime']
  custodianDetails?: Maybe<CustodianNumberDetails>
  externalId?: Maybe<Scalars['String']>
  feeStructure?: Maybe<Scalars['String']>
  fundAdministratorsDetails?: Maybe<Array<FundAdministratorDetails>>
  householdId: Scalars['ID']
  id: Scalars['ID']
  individualAccountDetails?: Maybe<IndividualAccountDetails>
  investorType?: Maybe<InvestorTypeDetailsResponse>
  isLatest: Scalars['Boolean']
  name: Scalars['String']
  planName?: Maybe<Scalars['String']>
  qualifiedClientStatuses?: Maybe<Array<QualifiedClientStatusResponse>>
  qualifiedEligiblePersonStatuses?: Maybe<
    Array<QualifiedEligiblePersonStatusResponse>
  >
  qualifiedPurchaserStatuses?: Maybe<Array<QualifiedPurchaserStatusResponse>>
  reportingProviderDetails?: Maybe<ReportingProviderDetails>
  /** @deprecated Use reportingProviderDetails instead */
  reportingProvidersDetails?: Maybe<Array<ReportingProviderDetails>>
  subType: Scalars['String']
  taxStatusDetails?: Maybe<TaxStatusDetailsResponse>
  updatedAt: Scalars['IsoDateTime']
  version: Scalars['Int']
}

export type IndividualAccountDetails = {
  __typename?: 'IndividualAccountDetails'
  australiaQualification?: Maybe<AustraliaQualification>
  jointClient?: Maybe<IndividualClientAccountDetails>
  netWorth?: Maybe<Scalars['String']>
  primaryClient?: Maybe<IndividualClientAccountDetails>
}

export type IndividualClientAccountDetails = {
  __typename?: 'IndividualClientAccountDetails'
  exemptFromFatcaCode?: Maybe<Scalars['String']>
  exemptPayeeCode?: Maybe<Scalars['String']>
  taxClassification?: Maybe<TaxClassification>
}

export type IndividualClientsDetails = {
  __typename?: 'IndividualClientsDetails'
  jointClient?: Maybe<ContactReference>
  primaryClient: ContactReference
}

export type IndividualClientsDetailsInput = {
  jointClientId?: InputMaybe<Scalars['ID']>
  primaryClientId: Scalars['ID']
}

export type InheritedAttributes = {
  __typename?: 'InheritedAttributes'
  attributes: Scalars['JSON']
  parentId: Scalars['String']
  parentType: Scalars['String']
}

export type InstantFilterInput = {
  /** After */
  after?: InputMaybe<Scalars['IsoDateTime']>
  /** Before */
  before?: InputMaybe<Scalars['IsoDateTime']>
  /** Equals */
  eq?: InputMaybe<Scalars['IsoDateTime']>
  /** Filters based on whether a value exists or does not exist. */
  exists?: InputMaybe<Scalars['Boolean']>
  /** Not Equals */
  ne?: InputMaybe<Scalars['IsoDateTime']>
  /** Not after */
  notAfter?: InputMaybe<Scalars['IsoDateTime']>
  /** Not before */
  notBefore?: InputMaybe<Scalars['IsoDateTime']>
}

export type IntegerFilterInput = {
  /** Equals */
  eq?: InputMaybe<Scalars['Int']>
  /** Filters based on whether a value exists or does not exist. */
  exists?: InputMaybe<Scalars['Boolean']>
  /** Greater Than */
  gt?: InputMaybe<Scalars['Int']>
  /** Greater Than Or Equals */
  gte?: InputMaybe<Scalars['Int']>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<Scalars['Int']>>
  /** Less Than */
  lt?: InputMaybe<Scalars['Int']>
  /** Less Than Or Equals */
  lte?: InputMaybe<Scalars['Int']>
  /** Not Equals */
  ne?: InputMaybe<Scalars['Int']>
}

export type InterestRateProduct = Entity &
  Node &
  Product & {
    __typename?: 'InterestRateProduct'
    caisId: Scalars['String']
    category?: Maybe<Scalars['String']>
    currencyCode: ProductStringField
    exchangeId?: Maybe<Scalars['String']>
    id: Scalars['ID']
    identifiers: Array<ProductIdentifier>
    name?: Maybe<Scalars['String']>
    price: Array<ProductPrice>
    productType: ProductType
    publishState: ProductPublishState
    ticker?: Maybe<Scalars['String']>
    version: Scalars['Int']
  }

export type InvalidCreditRating = IssuerError & {
  __typename?: 'InvalidCreditRating'
  message: Scalars['String']
}

export type InvestmentEntities = {
  __typename?: 'InvestmentEntities'
  jointInvestmentEntity?: Maybe<InvestmentEntityDetails>
  primaryInvestmentEntity: InvestmentEntityDetails
}

export type InvestmentEntitiesInput = {
  jointInvestmentEntity?: InputMaybe<InvestmentEntityDetailsInput>
  primaryInvestmentEntity: InvestmentEntityDetailsInput
}

export type InvestmentEntityDetails = {
  __typename?: 'InvestmentEntityDetails'
  ein?: Maybe<Scalars['String']>
  investingEntityName: Scalars['String']
}

export type InvestmentEntityDetailsInput = {
  ein?: InputMaybe<Scalars['String']>
  investingEntityName: Scalars['String']
}

export enum InvestorLevel {
  AccreditedInvestor = 'ACCREDITED_INVESTOR',
  NonAccredited = 'NON_ACCREDITED',
  QualifiedClient = 'QUALIFIED_CLIENT',
  QualifiedPurchaser = 'QUALIFIED_PURCHASER',
}

export enum InvestorType {
  NonUs = 'NON_US',
  UsTaxable = 'US_TAXABLE',
  UsTaxExempt = 'US_TAX_EXEMPT',
}

export enum InvestorTypeCategoryCode {
  Ifp01 = 'IFP01',
  Ifp02 = 'IFP02',
  Ifp03 = 'IFP03',
  Ifp04 = 'IFP04',
  Ifp05 = 'IFP05',
  Ifp06 = 'IFP06',
  Ifp07 = 'IFP07',
  Ifp08 = 'IFP08',
  Ifp09 = 'IFP09',
  Ifp10 = 'IFP10',
  Ifp11 = 'IFP11',
  Ifp12 = 'IFP12',
  Ifp13 = 'IFP13',
}

export type InvestorTypeDetailsInput = {
  category: InvestorTypeCategoryCode
  description?: InputMaybe<Scalars['String']>
}

export type InvestorTypeDetailsResponse = {
  __typename?: 'InvestorTypeDetailsResponse'
  category: Scalars['String']
  description?: Maybe<Scalars['String']>
}

export type IsoDateFilterInput = {
  /** Equals */
  eq?: InputMaybe<Scalars['Date']>
  /** Filters based on whether a value exists or does not exist. */
  exists?: InputMaybe<Scalars['Boolean']>
  /** Greater Than */
  gt?: InputMaybe<Scalars['Date']>
  /** Greater Than Or Equals */
  gte?: InputMaybe<Scalars['Date']>
  /** Less Than */
  lt?: InputMaybe<Scalars['Date']>
  /** Less Than Or Equals */
  lte?: InputMaybe<Scalars['Date']>
  /** Not Equals */
  ne?: InputMaybe<Scalars['Date']>
}

/** A legal entity that develops, registers and sells securities */
export type Issuer = Entity &
  Node & {
    __typename?: 'Issuer'
    acronym: Scalars['String']
    caisId: Scalars['String']
    creditRatings: Array<CreditRating>
    currentOfferings: Scalars['Int']
    distributor?: Maybe<Scalars['String']>
    expiredNotes: Scalars['Int']
    factSheetURL?: Maybe<Scalars['String']>
    group: IssuerGroup
    guarantor?: Maybe<Scalars['String']>
    id: Scalars['ID']
    legalName: Scalars['String']
    logoId?: Maybe<Scalars['String']>
    logoURLs?: Maybe<LogoUrl>
    outstandingNotes: Scalars['Int']
    pricingSupplementURL?: Maybe<Scalars['String']>
    registrationType: Array<StructuredProductRegistrationType>
    version: Scalars['Int']
  }

export type IssuerError = {
  message: Scalars['String']
}

export type IssuerFilterInput = {
  and?: InputMaybe<Array<IssuerFilterInput>>
  caisId?: InputMaybe<StringFilterInput>
  groupName?: InputMaybe<StringFilterInput>
  id?: InputMaybe<IdFilterInput>
  legalName?: InputMaybe<StringFilterInput>
  not?: InputMaybe<Array<IssuerFilterInput>>
  or?: InputMaybe<Array<IssuerFilterInput>>
}

export type IssuerGroup = Entity &
  Node & {
    __typename?: 'IssuerGroup'
    id: Scalars['ID']
    name: Scalars['String']
    version: Scalars['Int']
  }

export type IssuerGroupDoesNotExist = IssuerError & {
  __typename?: 'IssuerGroupDoesNotExist'
  message: Scalars['String']
}

export type IssuerGroupError = {
  message: Scalars['String']
}

export type IssuerGroupFilterInput = {
  id?: InputMaybe<StringFilterInput>
  name?: InputMaybe<StringFilterInput>
}

export type IssuerGroupNameAlreadyTaken = IssuerGroupError & {
  __typename?: 'IssuerGroupNameAlreadyTaken'
  message: Scalars['String']
}

export type IssuerGroupPageResult = {
  __typename?: 'IssuerGroupPageResult'
  items: Array<IssuerGroup>
  pageInfo: PageInfo
}

export type IssuerGroupPayload = {
  __typename?: 'IssuerGroupPayload'
  error?: Maybe<IssuerGroupError>
  issuerGroup?: Maybe<IssuerGroup>
}

export enum IssuerGroupSort {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export type IssuerGroupVersionConflict = IssuerGroupError & {
  __typename?: 'IssuerGroupVersionConflict'
  message: Scalars['String']
}

export type IssuerHasProducts = IssuerError & {
  __typename?: 'IssuerHasProducts'
  message: Scalars['String']
}

/** For adding an Issuer */
export type IssuerInput = {
  acronym: Scalars['String']
  creditRatings: Array<CreditRatingInput>
  distributor?: InputMaybe<Scalars['String']>
  factSheetURL?: InputMaybe<Scalars['String']>
  groupId: Scalars['ID']
  guarantor: Scalars['String']
  legalName: Scalars['String']
  pricingSupplementURL?: InputMaybe<Scalars['String']>
  registrationType: Array<StructuredProductRegistrationType>
}

export type IssuerNameAlreadyTaken = IssuerError & {
  __typename?: 'IssuerNameAlreadyTaken'
  message: Scalars['String']
}

export type IssuerPageResult = {
  __typename?: 'IssuerPageResult'
  items: Array<Issuer>
  pageInfo: PageInfo
}

export enum IssuerSort {
  LegalNameAsc = 'LEGAL_NAME_ASC',
  LegalNameDesc = 'LEGAL_NAME_DESC',
}

export type KycDocument = {
  __typename?: 'KycDocument'
  expiryDate?: Maybe<Scalars['Date']>
  fileId: Scalars['UUID']
  fileName: Scalars['String']
  fileType: Scalars['String']
  fileVersion: Scalars['Int']
  isForJointEntity?: Maybe<Scalars['Boolean']>
  isLatest: Scalars['Boolean']
  issueDate?: Maybe<Scalars['Date']>
  resourceId: Scalars['UUID']
  resourceType: Scalars['String']
}

export enum LockType {
  Hard = 'HARD',
  NotApplicable = 'NOT_APPLICABLE',
  Soft = 'SOFT',
}

export enum LockUpPeriod {
  Fixed = 'FIXED',
  Rolling = 'ROLLING',
}

export type LogoUrl = {
  __typename?: 'LogoURL'
  large: Scalars['String']
  thumbnail: Scalars['String']
}

export type MandatoryFieldValueNotPopulated = StructuredProductError & {
  __typename?: 'MandatoryFieldValueNotPopulated'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export enum MercerRating {
  RatingA = 'RATING_A',
  RatingAP = 'RATING_A_P',
  RatingAT = 'RATING_A_T',
  RatingAW = 'RATING_A_W',
  RatingB = 'RATING_B',
  RatingBP = 'RATING_B_P',
  RatingBPlus = 'RATING_B_PLUS',
  RatingBPlusP = 'RATING_B_PLUS_P',
  RatingBPlusT = 'RATING_B_PLUS_T',
  RatingBPlusW = 'RATING_B_PLUS_W',
  RatingBT = 'RATING_B_T',
  RatingBW = 'RATING_B_W',
  RatingC = 'RATING_C',
  RatingCT = 'RATING_C_T',
  RatingNotAvailable = 'RATING_NOT_AVAILABLE',
  RatingRv = 'RATING_RV',
  RatingUnderReview = 'RATING_UNDER_REVIEW',
}

export enum MercerServicesDocumentType {
  MercerAdvisorServices = 'MERCER_ADVISOR_SERVICES',
  MercerInvestmentServices = 'MERCER_INVESTMENT_SERVICES',
}

export type MergedProductPricingComponent = {
  __typename?: 'MergedProductPricingComponent'
  final?: Maybe<Scalars['BigDecimal']>
  high?: Maybe<Scalars['BigDecimal']>
  low?: Maybe<Scalars['BigDecimal']>
  type: Scalars['String']
}

export type MergedStructuredProductData = {
  __typename?: 'MergedStructuredProductData'
  brokerageAdvisoryCapacity?: Maybe<Scalars['String']>
  feeSalesConcession?: Maybe<MergedProductPricingComponent>
  identifiers: Array<StructuredProductOrderProductIdentifier>
  issuer: ProductIssuer
  offeringType: Scalars['String']
  pricingComponent?: Maybe<MergedProductPricingComponent>
  productId: Scalars['ID']
  source: Scalars['String']
  strikeDate?: Maybe<Scalars['Date']>
  structure: Structure
  tenorFinal?: Maybe<Scalars['BigDecimal']>
  tradeDate?: Maybe<Scalars['Date']>
  underlierTickers?: Maybe<Scalars['String']>
  underliers: Array<Underlier>
}

export type Money = {
  __typename?: 'Money'
  currency: Scalars['String']
  units: Scalars['BigDecimal']
}

export enum MultipleFinalValuationDatesStyle {
  Averaging = 'AVERAGING',
  HighWaterMark = 'HIGH_WATER_MARK',
  LowWaterMark = 'LOW_WATER_MARK',
}

export type MultipleWsdRecordsExistForAnIdentifier = StructuredProductError & {
  __typename?: 'MultipleWsdRecordsExistForAnIdentifier'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Accept terms and conditions for a given type and version */
  acceptTermsAndConditions: AcceptTermsAndConditionsPayload
  /** Adds a capability to a firm */
  addFirmCapability: FirmIdPayload
  /** Add a user to the team */
  addUserToTeam: TeamIdPayload
  /**
   *
   *             Mutation to delete multiple draft structure products
   *
   *             Example :
   *
   *             mutation {
   *                 bulkDeleteStructuredProducts(
   *                     productsIds: ["c2e98f15-7d9e-44dd-b03f-98b33b81932a"]
   *                 ) {
   *                 deletionResponse {
   *                     failedProductIds
   *                     deletedProductIds
   *                 }
   *                 error {
   *                     message
   *                 }
   *               }
   *             }
   *
   */
  bulkDeleteStructuredProducts: BulkDeleteStructuredProductsPayload
  /** Create an account. */
  createAccount: Account
  /**
   *
   *         Creates approved exceptions in bulk.
   *
   *         mutation {
   *           createApprovedExceptions({
   *                approvedExceptions: [{
   *                     fundId: "12479141",
   *                     externalFundId: "6463646"
   *                     }, {
   *                         positionId: "74647445747",
   *                         externalPositionId: "9654353355"
   *                     }
   *                ]
   *           }
   *         }
   */
  createApprovedExceptions: CreateApprovedExceptionsOutput
  /**
   *
   *         Create a DRAFT structured product for a given identifierType and value
   *
   *         Example:
   *         mutation {
   *             createDraftStructuredProduct(identifierType: CUSIP, value: "1234566") {
   *                 structuredProduct {
   *                   id
   *                   caisId
   *                   name
   *                   basketName {
   *                     value
   *                     metadata {
   *                       applicability
   *                       visibility
   *                     }
   *                   }
   *                 }
   *                 error {
   *                   message
   *                 }
   *             }
   *         }
   *
   */
  createDraftStructuredProduct: CreateDraftStructuredProductPayload
  /** Create firm */
  createFirm: CreateFirmPayload
  /** Create firm-specific role */
  createFirmRole: CreateRolePayload
  /** Creates Fund product */
  createFundProductAdmin: CreateFundProductAdminPayload
  /** Create a household. */
  createHousehold: Household
  /**
   *
   *         Example:
   *         mutation {
   *           createIssuer(
   *             input: {
   *               acronym: "BOA"
   *               creditRatings: [{ agency: STANDARD_AND_POORS, rating: "A+" }]
   *               distributor: "bar"
   *               groupId: "1"
   *               guarantor: "foo"
   *               legalName: "BOA"
   *               registrationType: [REG_S, REGISTERED]
   *               pricingSupplementURL: "http://www.location.com/"
   *             }
   *           ) {
   *             ... on CreateIssuerPayload {
   *               issuer {
   *                 id
   *                 legalName
   *                 pricingSupplementURL
   *                 guarantor
   *                 distributor
   *                 acronym
   *                 version
   *                 registrationType
   *                 group {
   *                   id
   *                   name
   *                   version
   *                 }
   *                 creditRatings {
   *                   agency
   *                   rating
   *                 }
   *               }
   *                error {
   *                 ...on IssuerNameAlreadyTaken{
   *                   message
   *                 }
   *                 ...on InvalidCreditRating{
   *                   message
   *                 }
   *                 ...on IssuerGroupDoesNotExist{
   *                   message
   *                 }
   *               }
   *             }
   *           }
   *         }
   *
   */
  createIssuer: CreateIssuerPayload
  /**
   *
   *         Create Issuer Group
   *
   *         Example:
   *         mutation {
   *           createIssuerGroup(
   *             input: {
   *               name: "BOA"
   *             }
   *         ) {
   *              ... on IssuerGroupPayload {
   *              issuerGroup {
   *                 id
   *                 name
   *                 version
   *              },
   *              error {
   *               ... on IssuerGroupNameAlreadyTaken {
   *                 __typename
   *                 }
   *               }
   *            }
   *           }
   *         }
   *
   */
  createIssuerGroup: IssuerGroupPayload
  /**
   *
   *     This mutation allows you create permissions on products, and assign them to a profile (currently only Firm is supported, with user and team in the future).
   *     Product Permissions only drives Current Offerings in the StructuredProducts
   *     Multiple permissions can be created in one go. A success and errors list will come back with the results.
   *     Note that this mutation supports partial success. This is mitigated by an error occurring if you try to create the same ProfileId/ProfileType/ProductId combination.
   *
   *     Example:
   *     mutation {
   *         createProductPermissions(input: [{ productId: "aCusip", profileId: "a280c0a2-c8fb-44b2-9d85-6f8cf444ea54", profileType: FIRM, viewAction: ALLOW },
   *             { productId: "aIsin", profileId: "c280c0a2-c8fb-44b2-9d85-6f8cf444ea54", profileType: FIRM, viewAction: ALLOW },
   *             { productId: "aProductUUID", profileId: "c280c0a2-c8fb-44b2-9d85-6f8cf444ea54", profileType: FIRM, viewAction: ALLOW }
   *         ])
   *         {
   *             successful {
   *                 id
   *                 productId
   *                 profileId
   *                 profileType
   *                 viewAction
   *                 createdAt
   *                 createdBy
   *                 updatedBy
   *                 updatedAt
   *                 version
   *             }
   *             failures {
   *                 productId
   *                 reason
   *             }
   *         }
   *     }
   *
   */
  createProductPermissions: ProductPermissionsCreationResult
  /**
   *
   *         Create Reference Asset
   *
   *         Example:
   *       mutation {
   *           createReferenceAsset(
   *             input: {
   *               rics: [".SPX"]
   *               ticker: "SPX"
   *               bbgTickers: ["SPX"]
   *               legalName: "S&P 500 INDEX"
   *               isins: ["US78378X1072"]
   *               productType: INDEX
   *               currencyCode: "USD"
   *             }
   *         ) {
   *                  referenceAsset {
   *                     id
   *                     caisId
   *                     name
   *                     identifiers {
   *                         value
   *                         type
   *                     }
   *                     ticker
   *                     ric
   *                     exchangeId
   *                     publishState
   *                     productType
   *                     category
   *                     currencyCode {
   *                       value
   *                       metadata {
   *                         source
   *                         visibility
   *                         applicability
   *                       }
   *                     }
   *                     price {
   *                         priceType
   *                         valueType
   *                         value
   *                         source
   *                         valueDate
   *                     }
   *                     customName
   *                     createdAt
   *                     createdBy
   *                     updatedAt
   *                     updatedBy
   *                     version
   *                     latestCloseLevel
   *                     latestCloseAsOf
   *                 },
   *                 error {
   *                   message
   *                   __typename
   *                 }
   *             }
   *         }
   *
   */
  createReferenceAsset: CreateReferenceAssetPayload
  /**
   *
   *             Creates a service identity
   *
   */
  createServiceIdentity: CreateServiceIdentityPayload
  /**
   *
   *         Example:
   *           mutation {
   *              createStructuredProductStructureTemplate(
   *                input: {
   *                  name: "Auto contingent yield notes"
   *                  acronym: "Auto contingent yield notes"
   *                  customName: "Auto contingent yield notes"
   *                  structureWsd: "barrierPhoenix"
   *                  subType: { value: YIELD, metadata: { visibility: VISIBLE } }
   *                  wrapperGroup: { value: WARRANT, metadata: { visibility: VISIBLE } }
   *                  fdicInsured: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasCoupon: { value: true, metadata: { visibility: VISIBLE } }
   *                  couponType: { value: CONTINGENT, metadata: { visibility: VISIBLE } }
   *                  hasCouponBarrier: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasCouponMemory: { value: true, metadata: { visibility: VISIBLE } }
   *                  callType: { value: AUTO_CALLABLE, metadata: { visibility: VISIBLE } }
   *                  hasCallPremium: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasStepUpAutoCallHurdle: { value: true metadata: { visibility: VISIBLE } }
   *                  hasStepDownAutoCallHurdle: { value: true metadata: { visibility: VISIBLE } }
   *                  hasUpsideParticipation: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasMaximumReturn: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasDigitalReturn: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasUpsideAboveDigitalReturn: { value: true metadata: { visibility: VISIBLE } }
   *                  hasUpsideKnockout: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasAbsoluteReturn: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasGearedDownside: { value: true, metadata: { visibility: VISIBLE } }
   *                  downsideProtectionType: { value: BARRIER metadata: { visibility: VISIBLE } }
   *                  hasBearish: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasRainbow: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasLookBack: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasRebate: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasHighWaterMark: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasFixedToFloatTerms: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasAdditionalYieldTerms: { value: true, metadata: { visibility: VISIBLE } }
   *                }
   *              ) {
   *                ... on StructuredProductStructureTemplatePayload {
   *                  structuredProductStructureTemplate {
   *                    id
   *                    caisId
   *                    name { value metadata { visibility } }
   *                    acronym { value metadata { visibility } }
   *                    customName { value metadata { visibility } }
   *                    structureWsd { value metadata { visibility } }
   *                    subType { value metadata { visibility } }
   *                    wrapperGroup { value metadata { visibility } }
   *                    fdicInsured { value metadata { visibility } }
   *                    hasCoupon { value metadata { visibility } }
   *                    couponType { value metadata { visibility } }
   *                    hasCouponBarrier { value metadata { visibility } }
   *                    hasCouponMemory { value metadata { visibility } }
   *                    callType { value metadata { visibility } }
   *                    hasCallPremium { value metadata { visibility } }
   *                    hasStepUpAutoCallHurdle { value metadata { visibility } }
   *                    hasStepDownAutoCallHurdle { value metadata { visibility } }
   *                    hasUpsideParticipation { value metadata { visibility } }
   *                    hasMaximumReturn { value metadata { visibility } }
   *                    hasDigitalReturn { value metadata { visibility } }
   *                    hasUpsideAboveDigitalReturn { value metadata { visibility } }
   *                    hasUpsideKnockout { value metadata { visibility } }
   *                    hasAbsoluteReturn { value metadata { visibility } }
   *                    hasGearedDownside { value metadata { visibility } }
   *                    downsideProtectionType { value metadata { visibility } }
   *                    hasBearish { value metadata { visibility } }
   *                    hasRainbow { value metadata { visibility } }
   *                    hasLookBack { value metadata { visibility } }
   *                    hasRebate { value metadata { visibility } }
   *                    hasHighWaterMark { value metadata { visibility } }
   *                    hasFixedToFloatTerms { value metadata { visibility } }
   *                    hasAdditionalYieldTerms { value metadata { visibility } }
   *                  }
   *                }
   *                error {
   *                  ... on StructuredProductStructureTemplateAlreadyTaken {
   *                    message
   *                  }
   *                  ... on StructuredProductStructureTemplateWsdNameAlreadyTaken {
   *                    message
   *                  }
   *                }
   *              }
   *            }
   *
   */
  createStructuredProductStructureTemplate: StructuredProductStructureTemplatePayload
  createStructuredProducts: StructuredProductUploadPayload
  /** Create new team */
  createTeam: CreateTeamPayload
  /** Create new user */
  createUser: CreateUserPayload
  custodian: CustodianMutations
  /** Delete firm-specific role */
  deleteFirmRole: RoleIdPayload
  /**
   *
   *         Deletes an investment external IDs.
   *
   *         mutation {
   *           deleteInvestmentExternalIds(id: "ea990a63-d2a2-4e8f-8b67-7900eb1e32f4") {
   *             id
   *             version
   *             externalIds {
   *               fundAdministrators
   *               legacy {
   *                 id
   *                 uuid
   *                 salesforceTicketId
   *               }
   *               reportingProviders {
   *                 key
   *                 values
   *               }
   *               initialKey
   *             }
   *           }
   *         }
   */
  deleteInvestmentExternalIds: ExternalIdsOutput
  /**
   *
   *         Example:
   *         mutation {
   *             deleteIssuer(
   *                 id: "123"
   *             ) {
   *                 id
   *             }
   *         }
   *
   */
  deleteIssuer: DeleteIssuerPayload
  /**
   *
   *         Deletes a position external IDs.
   *
   *         mutation {
   *           deletePositionExternalIds(id: "ea990a63-d2a2-4e8f-8b67-7900eb1e32f4") {
   *             id
   *             version
   *             externalIds {
   *               fundAdministrators
   *               legacy {
   *                 id
   *                 uuid
   *                 salesforceTicketId
   *               }
   *               reportingProviders {
   *                 key
   *                 values
   *               }
   *               initialKey
   *             }
   *           }
   *         }
   */
  deletePositionExternalIds: ExternalIdsOutput
  /** Delete product onboarding documents */
  deleteProductOnboardingDocs: DocsManagementResult
  /**
   *
   *         Soft delete a product permission - as a result this product will not show up in the current offerings for the related user(s).
   *         Note that it will still come back in product permission search.
   *
   *
   *         Success will be true if the deletion has occurred, otherwise an errors array will be returned
   *
   *         Example:
   *         mutation {
   *           deleteProductPermission(id: "e280c0a2-c8fb-44b2-9d85-6f8cf444ea54") {
   *             success
   *           }
   *         }
   *
   */
  deleteProductPermission: ProductPermissionDeletionResult
  /**
   *
   *             Removes a service identity
   *
   */
  deleteServiceIdentity: ServiceIdentityIdPayload
  /**
   *
   *         Example:
   *         mutation {
   *             deleteStructuredProduct(
   *                 id: "123"
   *             ) {
   *                 id
   *             }
   *         }
   *
   */
  deleteStructuredProduct: DeleteStructuredProductPayload
  /**
   *
   *         Deletes a transaction external IDs.
   *
   *         mutation {
   *           deleteTransactionExternalIds(id: "ea990a63-d2a2-4e8f-8b67-7900eb1e32f4") {
   *             id
   *             version
   *             externalIds {
   *               fundAdministrators
   *               legacy {
   *                 id
   *                 uuid
   *                 salesforceTicketId
   *               }
   *               reportingProviders {
   *                 key
   *                 values
   *               }
   *               initialKey
   *             }
   *           }
   *         }
   */
  deleteTransactionExternalIds: ExternalIdsOutput
  /** Delete user */
  deleteUser: UserIdPayload
  documentSubmissionBulkUpload: DocumentSubmissionBulkUploadResponse
  fundProductDocuments: FundProductDocumentMutation
  /**
   *
   *         Accepts the `id` and the `version` of the draft `StructuredProduct`
   *         and publishes the product  by promoting from "DRAFT" state to "PUBLISHED" state
   *
   *         Example:
   *        mutation {
   *           publishDraftStructuredProduct(id: "c2e98f15-7d9e-44dd-b03f-98b33b81932a", version: 1) {
   *              structuredProduct {
   *                   id
   *                   caisId
   *                   name
   *                   basketName {
   *                     value
   *                     metadata {
   *                       applicability
   *                       visibility
   *                     }
   *                   }
   *                 }
   *                 error {
   *                   message
   *                 }
   *             }
   *         }
   *
   */
  publishDraftStructuredProduct: PublishDraftStructuredProductPayload
  /**
   *
   *         Publishes all drafts created via a csv product upload.
   *
   *         Example:
   *         mutation {
   *               publishDraftStructuredProducts(
   *               productUploadId: "c2e98f15-7d9e-44dd-b03f-98b33b81932a",
   *               input: {
   *                 products: [{
   *                     id: "c2e98f15-7d9e-44dd-b03f-98b33b81932b",
   *                     version: 1,
   *                     productUploadResultVersion: 1
   *                 }]
   *               }) {
   *                     result { status }
   *                     error { message }
   *                 }
   *         }
   *
   */
  publishDraftStructuredProducts: StructuredProductDraftPublishAllPayload
  /** Publish a fund product */
  publishFundProductAdmin: PublishFundProductAdminPayload
  /** Publish a fund product share class */
  publishFundProductShareClassAdmin: PublishFundProductShareClassAdminPayload
  /** Removes a capability from a firm */
  removeFirmCapability: FirmIdPayload
  /** Removes a user from a team */
  removeUserFromTeam: TeamIdPayload
  /** Rerun an instance of a pipeline */
  rerunPipeline: PipelineRerunPayload
  /** Resend activation email */
  resendActivationEmail: ResendActivationPayload
  /**
   *
   *             Rotate service identity secret
   *
   */
  rotateServiceIdentitySecret: ServiceIdentityRotateSecretPayload
  /** Run an instance of a pipeline */
  runPipeline: PipelineRunPayload
  /** Switch current user to target switch user id */
  switchUser: SwitchUserPayload
  /** Remove any switch user session from current user */
  unswitchUser: UnswitchUserPayload
  /** Update an account. */
  updateAccount: Account
  /** Update firm */
  updateFirm: UpdateFirmPayload
  /** Update firm capabilities */
  updateFirmCapabilities: FirmIdPayload
  /** Update firm-specific role */
  updateFirmRole: UpdateRolePayload
  /** Updates the fund product */
  updateFundProductAdmin: UpdateFundProductAdminPayload
  /** Update a household. */
  updateHousehold: Household
  /**
   *
   *         Updates an investment external IDs.
   *
   *         mutation {
   *           updateInvestmentExternalIds(
   *             input: {
   *               id: "ea990a63-d2a2-4e8f-8b67-7900eb1e32f4"
   *               externalIds: {
   *                 fundAdministrators: ["836d0a61-347d-47a4-9501-b58386e3c604"],
   *                 legacy: {
   *                   id: 123,
   *                   uuid: "ca791f71-9491-4b00-8678-72453a337c4d",
   *                   salesforceTicketId: "ABC123"
   *                 },
   *                 reportingProviders: [{
   *                   key: "bfef6dc7-1d22-4d1d-90b5-647f1e35ce49",
   *                   values: ["b65de286-e9bd-4791-9f16-0783faba0526"]
   *                 }],
   *                 initialKey: "65a5da2f-0ab1-4afc-91ed-eef4312e3d2f"
   *               }
   *             }
   *           ) {
   *             id
   *             version
   *             externalIds {
   *               fundAdministrators
   *               legacy {
   *                 id
   *                 uuid
   *                 salesforceTicketId
   *               }
   *               reportingProviders {
   *                 key
   *                 values
   *               }
   *               initialKey
   *             }
   *           }
   *         }
   */
  updateInvestmentExternalIds: ExternalIdsOutput
  /**
   *
   *         Example:
   *         mutation {
   *             updateIssuer(
   *             id: "123"
   *             version: 1
   *             input: {
   *             acronym: "BOA"
   *             creditRatings: [{ agency: STANDARD_AND_POORS, rating: "A+" }]
   *             distributor: "bar"
   *             groupId: "1"
   *             guarantor: "foo"
   *             legalName: "BOA"
   *             registrationType: [REG_S, REGISTERED]
   *             pricingSupplementURL: "http://www.location.com/"
   *         }
   *             ) {
   *             ... on UpdateIssuerPayload {
   *             issuer {
   *                 id
   *                 legalName
   *                 pricingSupplementURL
   *                 guarantor
   *                 distributor
   *                 acronym
   *                 version
   *                 registrationType
   *                 group {
   *                     id
   *                     name
   *                     version
   *                 }
   *                 creditRatings {
   *                     agency
   *                     rating
   *                 }
   *             }
   *              error {
   *                 ... on IssuerNameAlreadyTaken {
   *                     message
   *                 }
   *              }
   *           }
   *          }
   *         }
   *
   */
  updateIssuer: UpdateIssuerPayload
  /**
   *
   *         Update Issuer Group
   *
   *         Example:
   *         mutation {
   *           updateIssuerGroup(
   *             id: "8cb298de-0b43-4fec-8e1f-99ebf1f0fe90"
   *             input: {
   *               name: "BOA",
   *               version: 1
   *             }
   *         ) {
   *              ... on IssuerGroupPayload {
   *              issuerGroup {
   *                 id
   *                 name
   *                 version
   *              },
   *              error {
   *               ... on IssuerGroupNameAlreadyTaken {
   *                 __typename
   *                 }
   *               }
   *            }
   *           }
   *         }
   *
   */
  updateIssuerGroup: IssuerGroupPayload
  /**
   *
   *         Updates a position external IDs.
   *
   *         mutation {
   *           updatePositionExternalIds(
   *             input: {
   *               id: "ea990a63-d2a2-4e8f-8b67-7900eb1e32f4"
   *               externalIds: {
   *                 fundAdministrators: ["836d0a61-347d-47a4-9501-b58386e3c604"],
   *                 legacy: {
   *                   id: 123,
   *                   uuid: "ca791f71-9491-4b00-8678-72453a337c4d",
   *                   salesforceTicketId: "ABC123"
   *                 },
   *                 reportingProviders: [{
   *                   key: "bfef6dc7-1d22-4d1d-90b5-647f1e35ce49",
   *                   values: ["b65de286-e9bd-4791-9f16-0783faba0526"]
   *                 }],
   *                 initialKey: "65a5da2f-0ab1-4afc-91ed-eef4312e3d2f"
   *               }
   *             }
   *           ) {
   *             id
   *             version
   *             externalIds {
   *               fundAdministrators
   *               legacy {
   *                 id
   *                 uuid
   *                 salesforceTicketId
   *               }
   *               reportingProviders {
   *                 key
   *                 values
   *               }
   *               initialKey
   *             }
   *           }
   *         }
   */
  updatePositionExternalIds: ExternalIdsOutput
  /** Update product onboarding documents */
  updateProductOnboardingDocs: DocsManagementResult
  /**
   *
   *         Update Reference Asset
   *
   *         Example:
   *       mutation {
   *           updateReferenceAsset(
   *             id: "c15d917e-8269-43af-892c-1e68e74c28f7"
   *             input: {
   *               rics: [".SPX"]
   *               ticker: "SPX"
   *               bbgTickers: ["SPX"]
   *               legalName: "S&P 500 INDEX"
   *               isins: ["US78378X1072"]
   *               productType: INDEX
   *               version: 1
   *               currencyCode: "USD"
   *             }
   *         ) {
   *                  referenceAsset {
   *                     id
   *                     caisId
   *                     name
   *                     identifiers {
   *                         value
   *                         type
   *                     }
   *                     ticker
   *                     ric
   *                     exchangeId
   *                     publishState
   *                     productType
   *                     category
   *                     currencyCode {
   *                       value
   *                       metadata {
   *                         source
   *                         visibility
   *                         applicability
   *                       }
   *                     }
   *                     price {
   *                         priceType
   *                         valueType
   *                         value
   *                         source
   *                         valueDate
   *                     }
   *                     customName
   *                     createdAt
   *                     createdBy
   *                     updatedAt
   *                     updatedBy
   *                     version
   *                     latestCloseLevel
   *                     latestCloseAsOf
   *                 },
   *                 error {
   *                   message
   *                   __typename
   *                 }
   *             }
   *         }
   *
   */
  updateReferenceAsset: UpdateReferenceAssetPayload
  updateServiceIdentity: UpdateServiceIdentityPayload
  /** update share class policy status */
  updateShareClassPolicyStatus: UpdateFundProductShareClassPolicyStatusAdminPayload
  /**
   *
   *             mutation {
   *               updateStructuredProduct(
   *                 id: "c2e98f15-7d9e-44dd-b03f-98b33b81932a"
   *                 input: {
   *                   publishState: PUBLISHED
   *                   maxNotiona: 0.11
   *                   offeringType: {
   *                     value: CUSTOM
   *                     metadata: { source: MANUAL_OVERRIDE visibility: VISIBLE }
   *                   }
   *                   isMarketplace: true
   *                   status: {
   *                     value: ACTIVE
   *                     metadata: { source: MANUAL_OVERRIDE visibility: VISIBLE }
   *                   }
   *                   cusip: {
   *                     value: null
   *                     metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                   }
   *                   structure: {
   *                     structureTemplateId: "c2e98f15-7d9e-44dd-b03f-98b33b81932a"
   *                     name: {
   *                       value: "Auto contingent yield note"
   *                       metadata: { source: MANUAL_OVERRIDE visibility: VISIBLE }
   *                     }
   *                     acronym: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     customName: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     wrapperGroup: {
   *                       value: WARRANT
   *                       metadata: { source: MANUAL_OVERRIDE visibility: VISIBLE }
   *                     }
   *                     subType: {
   *                       value: YIELD
   *                       metadata: { source: MANUAL_OVERRIDE, visibility: VISIBLE }
   *                     }
   *                     fdicInsured: {
   *                       value: true
   *                       metadata: { source: MANUAL_OVERRIDE visibility: VISIBLE }
   *                     }
   *                   }
   *                   isin: {
   *                     value: null
   *                     metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                   }
   *                   cins: {
   *                     value: null
   *                     metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                   }
   *                   issuer: {
   *                     value: "13"
   *                     metadata: { source: MANUAL_OVERRIDE visibility: VISIBLE }
   *                   }
   *                   tenor: {
   *                     value: { final: "1234" }
   *                     metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                   }
   *                   currencyCode: {
   *                     value: "USD"
   *                     metadata: { source: MANUAL_OVERRIDE visibility: VISIBLE }
   *                   }
   *                   settlementType: {
   *                     value: CASH
   *                     metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                   }
   *                   denomination: {
   *                     value: 0.98
   *                     metadata: { source: MANUAL_OVERRIDE visibility: VISIBLE }
   *                   }
   *                   registrationType: {
   *                     value: REGISTERED
   *                     metadata: { source: MANUAL_OVERRIDE visibility: VISIBLE }
   *                   }
   *                   investmentArea: {
   *                     value: "test"
   *                     metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                   }
   *                   basketType: {
   *                     value: BASKET
   *                     metadata: { source: MANUAL_OVERRIDE visibility: VISIBLE }
   *                   }
   *                   basketName: {
   *                     value: "basket name"
   *                     metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                   }
   *                   underlierData: {
   *                     underliers: {
   *                       id: "33f778e0-a393-4e5c-982a-9897f1e23fb4"
   *                       quanto: true
   *                       weight: 0.95
   *                       initialLevel: 0.95
   *                       paymentBarrierLevel: 0.95
   *                       autoCallLevel: 0.95
   *                       downsideLevel: 0.95
   *                     }
   *                     metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                   }
   *                   keyDates: {
   *                     closing: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     trade: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     strike: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     issuance: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     finalObservation: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     maturity: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     expired: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     called: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     filing: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     averageIn: []
   *                     averageOut: []
   *                   }
   *                   growth: {
   *                     upsideParticipation: {
   *                       value: { final: 0.66 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     maximumReturn: {
   *                       value: { final: 0.88 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     cap: {
   *                       value: { final: 0.88 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     digitalReturn: {
   *                       value: { final: 0.88 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     digitalReturnBarrier: {
   *                       value: { final: 0.88 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     upsideParticipationAboveDigital: {
   *                       value: { final: 0.88 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     lowerCallStrike: {
   *                       value: { final: 0.88 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     upperCallStrike: {
   *                       value: { final: 0.88 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     knockOutBarrier: {
   *                       value: { final: 0.88 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     knockOutBarrierEventType: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     knockOutBarrierEventObservationType: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     knockOutResult: {
   *                       value: null
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                   }
   *                   yield: {
   *                     couponType: {
   *                       value: ACCRUAL
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     couponRate: {
   *                       value: { final: 0.77 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     couponPerPeriod: {
   *                       value: { final: 0.99 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     couponPaymentFrequency: {
   *                       value: WEEKLY
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     couponObservationFrequency: {
   *                       value: ANNUALLY
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     couponBarrier: {
   *                       value: { final: 0.33 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     couponMemory: {
   *                       value: true
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                   }
   *                   downside: {
   *                     downsideProtectionType: {
   *                       value: FLOOR
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     barrierType: {
   *                       value: "someType"
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     bufferLevel: {
   *                       value: { final: 0.03 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     knockInBarrier: {
   *                       value: { final: 0.03 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     knockInBarrierEventType: {
   *                       value: "someEvent"
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     knockInBarrierObservationType: {
   *                       value: "someObvType"
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     threshold: {
   *                       value: { final: 0.22 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     observationFrequency: {
   *                       value: ANNUALLY
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     downsideGearing: {
   *                       value: { final: 0.66 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     absoluteReturnBarrier: {
   *                       value: { final: 0.55 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     absoluteReturnParticipation: {
   *                       value: { final: 0.66 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     putStrikeLevel: {
   *                       value: { final: 0.77 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                   }
   *                   callable: {
   *                     callType: {
   *                       value: AUTO_CALLABLE
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     callFrequency: {
   *                       value: DAILY
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     nonCallPeriod: {
   *                       value: 0.99
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     autoCallBarrier: {
   *                       value: { final: 0.55 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     callPremium: {
   *                       value: { final: 0.77 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     callHurdle: {
   *                       value: { final: 0.55 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                   }
   *                   miscellaneous: {
   *                     notionalCAIS: {
   *                       value: 0.75
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     notionalOffering: {
   *                       value: 0.99
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     feeCAIS: {
   *                       value: 0.99
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     feeCustodian: {
   *                       value: 0.99
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     feeOther: {
   *                       value: 0.99
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     feeSalesConcession: {
   *                       value: { final: 0.99 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     proceedsToIssuer: {
   *                       value: { final: 0.99 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     estimatedValue: {
   *                       value: { final: 0.99 }
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     staticReturn: {
   *                       value: 0.99
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                     finalRedemptionPerformance: {
   *                       value: 0.99
   *                       metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                     }
   *                   }
   *                   prospectusType: {
   *                     value: PRELIMINARY
   *                     metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                   }
   *                   pricingSupplementURL: {
   *                     value: "http://example.com"
   *                     metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                   }
   *                   factSheetURL: {
   *                     value: "http://example.com"
   *                     metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                   }
   *                   issuePrice: {
   *                     value: 0.98
   *                     metadata: { source: MANUAL_OVERRIDE applicability: NON_APPLICABLE visibility: VISIBLE }
   *                   }
   *                   secondaryPrice: {
   *                     value: 0.99
   *                     metadata: { source: MANUAL_OVERRIDE visibility: VISIBLE }
   *                   }
   *                 }
   *                 version: 1
   *               ) {
   *                 ... on UpdateStructuredProductPayload {
   *                   structuredProduct {
   *                     id
   *                     caisId
   *                     name
   *                     basketName {
   *                       value
   *                       metadata {
   *                         applicability
   *                       }
   *                     }
   *                   }
   *                 }
   *               }
   *             }
   *
   */
  updateStructuredProduct: UpdateStructuredProductPayload
  /**
   *
   *         Example:
   *           mutation {
   *              updateStructuredProductStructureTemplate(
   *                id: "33f778e0-a393-4e5c-982a-9897f1e23fb4"
   *                version: 1
   *                input: {
   *                  name: "Auto contingent yield notes"
   *                  acronym: "Auto contingent yield notes"
   *                  customName: "Auto contingent yield notes"
   *                  structureWsd: "barrierPhoenix"
   *                  subType: { value: YIELD, metadata: { visibility: VISIBLE } }
   *                  wrapperGroup: { value: WARRANT, metadata: { visibility: VISIBLE } }
   *                  fdicInsured: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasCoupon: { value: true, metadata: { visibility: VISIBLE } }
   *                  couponType: { value: CONTINGENT, metadata: { visibility: VISIBLE } }
   *                  hasCouponBarrier: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasCouponMemory: { value: true, metadata: { visibility: VISIBLE } }
   *                  callType: { value: AUTO_CALLABLE, metadata: { visibility: VISIBLE } }
   *                  hasCallPremium: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasStepUpAutoCallHurdle: { value: true metadata: { visibility: VISIBLE } }
   *                  hasStepDownAutoCallHurdle: { value: true metadata: { visibility: VISIBLE } }
   *                  hasUpsideParticipation: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasMaximumReturn: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasDigitalReturn: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasUpsideAboveDigitalReturn: { value: true metadata: { visibility: VISIBLE } }
   *                  hasUpsideKnockout: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasAbsoluteReturn: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasGearedDownside: { value: true, metadata: { visibility: VISIBLE } }
   *                  downsideProtectionType: { value: BARRIER metadata: { visibility: VISIBLE } }
   *                  hasBearish: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasRainbow: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasLookBack: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasRebate: { value: true, metadata: { visibility: VISIBLE } }
   *                  hasHighWaterMark: { value: true, metadata: { visibility: VISIBLE } }
   *                }
   *              ) {
   *                ... on StructuredProductStructureTemplatePayload {
   *                  structuredProductStructureTemplate {
   *                    id
   *                    caisId
   *                    name { value metadata { visibility } }
   *                    acronym { value metadata { visibility } }
   *                    customName { value metadata { visibility } }
   *                    structureWsd { value metadata { visibility } }
   *                    subType { value metadata { visibility } }
   *                    wrapperGroup { value metadata { visibility } }
   *                    fdicInsured { value metadata { visibility } }
   *                    hasCoupon { value metadata { visibility } }
   *                    couponType { value metadata { visibility } }
   *                    hasCouponBarrier { value metadata { visibility } }
   *                    hasCouponMemory { value metadata { visibility } }
   *                    callType { value metadata { visibility } }
   *                    hasCallPremium { value metadata { visibility } }
   *                    hasStepUpAutoCallHurdle { value metadata { visibility } }
   *                    hasStepDownAutoCallHurdle { value metadata { visibility } }
   *                    hasUpsideParticipation { value metadata { visibility } }
   *                    hasMaximumReturn { value metadata { visibility } }
   *                    hasDigitalReturn { value metadata { visibility } }
   *                    hasUpsideAboveDigitalReturn { value metadata { visibility } }
   *                    hasUpsideKnockout { value metadata { visibility } }
   *                    hasAbsoluteReturn { value metadata { visibility } }
   *                    hasGearedDownside { value metadata { visibility } }
   *                    downsideProtectionType { value metadata { visibility } }
   *                    hasBearish { value metadata { visibility } }
   *                    hasRainbow { value metadata { visibility } }
   *                    hasLookBack { value metadata { visibility } }
   *                    hasRebate { value metadata { visibility } }
   *                    hasHighWaterMark { value metadata { visibility } }
   *                  }
   *                }
   *                error {
   *                  ... on StructuredProductStructureTemplateAlreadyTaken {
   *                    message
   *                  }
   *                  ... on StructuredProductStructureTemplateWsdNameAlreadyTaken {
   *                    message
   *                  }
   *                }
   *              }
   *            }
   *
   */
  updateStructuredProductStructureTemplate: StructuredProductStructureTemplatePayload
  /** Update team */
  updateTeam: UpdateTeamPayload
  /**
   *
   *         Updates a transaction external IDs.
   *
   *         mutation {
   *           updateTransactionExternalIds(
   *             input: {
   *               id: "ea990a63-d2a2-4e8f-8b67-7900eb1e32f4"
   *               externalIds: {
   *                 fundAdministrators: ["836d0a61-347d-47a4-9501-b58386e3c604"],
   *                 legacy: {
   *                   id: 123,
   *                   uuid: "ca791f71-9491-4b00-8678-72453a337c4d",
   *                   salesforceTicketId: "ABC123"
   *                 },
   *                 reportingProviders: [{
   *                   key: "bfef6dc7-1d22-4d1d-90b5-647f1e35ce49",
   *                   values: ["b65de286-e9bd-4791-9f16-0783faba0526"]
   *                 }]
   *                 initialKey: "65a5da2f-0ab1-4afc-91ed-eef4312e3d2f"
   *               }
   *             }
   *           ) {
   *             id
   *             version
   *             externalIds {
   *               fundAdministrators
   *               legacy {
   *                 id
   *                 uuid
   *                 salesforceTicketId
   *               }
   *               reportingProviders {
   *                 key
   *                 values
   *               }
   *               initialKey
   *             }
   *           }
   *         }
   */
  updateTransactionExternalIds: ExternalIdsOutput
  /** Update user */
  updateUser: UpdateUserPayload
  /** Update user roles - replace the entire list of roles */
  updateUserRoles: UserIdPayload
  /**
   *
   *         Upload a CSV file with a list of draft products to create in bulk.
   *
   *         CSV Format:
   *         ProductIdentifier,ProductIdentifierType
   *         06368EY71,CUSIP
   *         06368EY72111,ISIN
   *
   *         Example:
   *         mutation {
   *           uploadDraftStructuredProducts(filePartName: "filename") {
   *             upload { status }
   *             error { message }
   *           }
   *         }
   *
   */
  uploadDraftStructuredProducts: StructuredProductDraftUploadPayload
  /** Upload mercer monthly documents */
  uploadMercerMonthlyDocs: DocsManagementResult
  /** Upload performance documents */
  uploadPerformanceDocs: DocsManagementResult
  /** Upload performance statistics */
  uploadPerformanceStats: DocsManagementResult
  /** Upload product onboarding documents */
  uploadProductOnboardingDocs: OnboardingDocsPayload
  /** Upsert Fund product policy */
  upsertFundProductAccessPolicy: FundProductAccessPolicyResponse
}

export type MutationAcceptTermsAndConditionsArgs = {
  input: AcceptTermsAndConditionsInput
}

export type MutationAddFirmCapabilityArgs = {
  capabilityId: Scalars['String']
  firmId: Scalars['ID']
}

export type MutationAddUserToTeamArgs = {
  teamId: Scalars['ID']
  userId: Scalars['ID']
}

export type MutationBulkDeleteStructuredProductsArgs = {
  productsIds: Array<Scalars['ID']>
}

export type MutationCreateAccountArgs = {
  householdId: Scalars['ID']
  input: AccountInput
}

export type MutationCreateApprovedExceptionsArgs = {
  input: CreateApprovedExceptionsInput
}

export type MutationCreateDraftStructuredProductArgs = {
  identifierType: ProductIdentifierType
  value: Scalars['String']
}

export type MutationCreateFirmArgs = {
  input: CreateFirmInput
}

export type MutationCreateFirmRoleArgs = {
  firmId: Scalars['ID']
  input: RoleInput
}

export type MutationCreateFundProductAdminArgs = {
  input: CreateFundProductAdminInput
}

export type MutationCreateHouseholdArgs = {
  input: CreateHouseholdInput
}

export type MutationCreateIssuerArgs = {
  input: IssuerInput
}

export type MutationCreateIssuerGroupArgs = {
  input: CreateIssuerGroupInput
}

export type MutationCreateProductPermissionsArgs = {
  input: Array<ProductPermissionInput>
}

export type MutationCreateReferenceAssetArgs = {
  input: CreateReferenceAssetInput
}

export type MutationCreateServiceIdentityArgs = {
  input: CreateServiceIdentityInput
}

export type MutationCreateStructuredProductStructureTemplateArgs = {
  input: StructuredProductStructureTemplateInput
}

export type MutationCreateStructuredProductsArgs = {
  input: Array<CreateStructuredProductInput>
}

export type MutationCreateTeamArgs = {
  input: CreateTeamInput
}

export type MutationCreateUserArgs = {
  input: CreateUserInput
}

export type MutationDeleteFirmRoleArgs = {
  firmId: Scalars['ID']
  roleId: Scalars['ID']
}

export type MutationDeleteInvestmentExternalIdsArgs = {
  id: Scalars['ID']
}

export type MutationDeleteIssuerArgs = {
  id: Scalars['ID']
}

export type MutationDeletePositionExternalIdsArgs = {
  id: Scalars['ID']
}

export type MutationDeleteProductOnboardingDocsArgs = {
  input: DeleteProductOnboardingDocsInput
}

export type MutationDeleteProductPermissionArgs = {
  id: Scalars['String']
}

export type MutationDeleteServiceIdentityArgs = {
  input: Scalars['ID']
}

export type MutationDeleteStructuredProductArgs = {
  id: Scalars['ID']
}

export type MutationDeleteTransactionExternalIdsArgs = {
  id: Scalars['ID']
}

export type MutationDeleteUserArgs = {
  userId: Scalars['ID']
  version?: InputMaybe<Scalars['Int']>
}

export type MutationDocumentSubmissionBulkUploadArgs = {
  filePartName: Scalars['String']
  input: DocumentSubmissionBulkUploadInput
}

export type MutationFundProductDocumentsArgs = {
  fundId: Scalars['UUID']
}

export type MutationPublishDraftStructuredProductArgs = {
  id: Scalars['ID']
  version: Scalars['Int']
}

export type MutationPublishDraftStructuredProductsArgs = {
  input: DraftUploadPublishAllInput
  productUploadId: Scalars['ID']
}

export type MutationPublishFundProductAdminArgs = {
  id: Scalars['ID']
  version: Scalars['Int']
}

export type MutationPublishFundProductShareClassAdminArgs = {
  fundProductId: Scalars['ID']
  id: Scalars['ID']
  version: Scalars['Int']
}

export type MutationRemoveFirmCapabilityArgs = {
  capabilityId: Scalars['String']
  firmId: Scalars['ID']
}

export type MutationRemoveUserFromTeamArgs = {
  teamId: Scalars['ID']
  userId: Scalars['ID']
}

export type MutationRerunPipelineArgs = {
  input: PipelineRerunInput
}

export type MutationResendActivationEmailArgs = {
  userId: Scalars['ID']
}

export type MutationRotateServiceIdentitySecretArgs = {
  id: Scalars['ID']
}

export type MutationRunPipelineArgs = {
  input: PipelineRunInput
}

export type MutationSwitchUserArgs = {
  input: SwitchUserInput
}

export type MutationUpdateAccountArgs = {
  accountId: Scalars['ID']
  input: AccountInput
}

export type MutationUpdateFirmArgs = {
  input: UpdateFirmInput
}

export type MutationUpdateFirmCapabilitiesArgs = {
  capabilityIds: Array<Scalars['String']>
  firmId: Scalars['ID']
  version?: InputMaybe<Scalars['Int']>
}

export type MutationUpdateFirmRoleArgs = {
  firmId: Scalars['ID']
  input: RoleInput
  roleId: Scalars['ID']
}

export type MutationUpdateFundProductAdminArgs = {
  id: Scalars['ID']
  input: UpdateFundProductAdminInput
  version: Scalars['Int']
}

export type MutationUpdateHouseholdArgs = {
  id: Scalars['ID']
  input: UpdateHouseholdInput
}

export type MutationUpdateInvestmentExternalIdsArgs = {
  input: UpdateExternalIdsInput
}

export type MutationUpdateIssuerArgs = {
  id: Scalars['ID']
  input: IssuerInput
  version: Scalars['Int']
}

export type MutationUpdateIssuerGroupArgs = {
  id: Scalars['ID']
  input: UpdateIssuerGroupInput
}

export type MutationUpdatePositionExternalIdsArgs = {
  input: UpdateExternalIdsInput
}

export type MutationUpdateProductOnboardingDocsArgs = {
  input: UpdateProductOnboardingDocsInput
}

export type MutationUpdateReferenceAssetArgs = {
  id: Scalars['ID']
  input: UpdateReferenceAssetInput
}

export type MutationUpdateServiceIdentityArgs = {
  input: UpdateServiceIdentityInput
}

export type MutationUpdateShareClassPolicyStatusArgs = {
  fundProductId: Scalars['ID']
  request: FundProductShareClassPolicyStatusRequestInput
  version: Scalars['Int']
}

export type MutationUpdateStructuredProductArgs = {
  id: Scalars['ID']
  input: StructuredProductInput
  version: Scalars['Int']
}

export type MutationUpdateStructuredProductStructureTemplateArgs = {
  id: Scalars['ID']
  input: StructuredProductStructureTemplateInput
  version: Scalars['Int']
}

export type MutationUpdateTeamArgs = {
  input: UpdateTeamInput
}

export type MutationUpdateTransactionExternalIdsArgs = {
  input: UpdateExternalIdsInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationUpdateUserRolesArgs = {
  roleIds: Array<Scalars['ID']>
  userId: Scalars['ID']
  version?: InputMaybe<Scalars['Int']>
}

export type MutationUploadDraftStructuredProductsArgs = {
  filePartName: Scalars['String']
}

export type MutationUploadMercerMonthlyDocsArgs = {
  input: UploadMercerMonthlyDocsInput
}

export type MutationUploadPerformanceStatsArgs = {
  input: UploadPerformanceStatsInput
}

export type MutationUploadProductOnboardingDocsArgs = {
  input: UploadProductOnboardingDocsInput
}

export type MutationUpsertFundProductAccessPolicyArgs = {
  fundProductId: Scalars['ID']
  input: FundProductAccessPolicyRequestInput
}

export type NestedHierarchyEdgeInput = {
  parentId: Scalars['ID']
  parentType: NestedHierarchyEdgeType
}

export enum NestedHierarchyEdgeType {
  Firm = 'FIRM',
  Team = 'TEAM',
  User = 'USER',
}

export type NetCapitalActivity = {
  __typename?: 'NetCapitalActivity'
  currentPeriod?: Maybe<Money>
  itd?: Maybe<Money>
  ytd?: Maybe<Money>
}

export type NetMonthlyReturns = {
  __typename?: 'NetMonthlyReturns'
  annual?: Maybe<Scalars['BigDecimal']>
  april?: Maybe<Scalars['BigDecimal']>
  august?: Maybe<Scalars['BigDecimal']>
  december?: Maybe<Scalars['BigDecimal']>
  february?: Maybe<Scalars['BigDecimal']>
  january?: Maybe<Scalars['BigDecimal']>
  july?: Maybe<Scalars['BigDecimal']>
  june?: Maybe<Scalars['BigDecimal']>
  march?: Maybe<Scalars['BigDecimal']>
  may?: Maybe<Scalars['BigDecimal']>
  monthsWithEstimateValue?: Maybe<Array<Scalars['Int']>>
  november?: Maybe<Scalars['BigDecimal']>
  october?: Maybe<Scalars['BigDecimal']>
  september?: Maybe<Scalars['BigDecimal']>
  year?: Maybe<Scalars['Int']>
}

export type NetReturns = {
  __typename?: 'NetReturns'
  itd?: Maybe<Scalars['BigDecimal']>
  mtd?: Maybe<Scalars['BigDecimal']>
  qtd?: Maybe<Scalars['BigDecimal']>
  ytd?: Maybe<Scalars['BigDecimal']>
}

export type NewIssuesEligibility = {
  __typename?: 'NewIssuesEligibility'
  brokerDealer: BrokerDealerEligibility
  brokerDealerOwner: BrokerDealerOwnerEligibility
  finderOrFiduciary: FinderFiduciaryEligibility
  officersAndDirectors: OfficersAndDirectorsEligibility
  portfolioManager: PortfolioManagerEligibility
  restricted: RestrictedEligibility
}

export type Node = {
  id: Scalars['ID']
}

export type NonIndividualAccount = Account & {
  __typename?: 'NonIndividualAccount'
  accountCategory: Scalars['String']
  accreditedInvestorStatuses?: Maybe<Array<AccreditedInvestorStatusResponse>>
  advisorNotifications?: Maybe<Scalars['Boolean']>
  brokerageDetails?: Maybe<BrokerageDetails>
  commonAccountDetails?: Maybe<CommonAccountDetails>
  createdAt: Scalars['IsoDateTime']
  custodianDetails?: Maybe<CustodianNumberDetails>
  externalId?: Maybe<Scalars['String']>
  feeStructure?: Maybe<Scalars['String']>
  fundAdministratorsDetails?: Maybe<Array<FundAdministratorDetails>>
  householdId: Scalars['ID']
  id: Scalars['ID']
  investmentEntities: InvestmentEntities
  investorType?: Maybe<InvestorTypeDetailsResponse>
  isLatest: Scalars['Boolean']
  isNominee?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  nonIndividualAccountDetails?: Maybe<NonIndividualAccountDetails>
  primarySigners: PrimarySigners
  qualifiedClientStatuses?: Maybe<Array<QualifiedClientStatusResponse>>
  qualifiedEligiblePersonStatuses?: Maybe<
    Array<QualifiedEligiblePersonStatusResponse>
  >
  qualifiedPurchaserStatuses?: Maybe<Array<QualifiedPurchaserStatusResponse>>
  reportingProviderDetails?: Maybe<ReportingProviderDetails>
  /** @deprecated Use reportingProviderDetails instead */
  reportingProvidersDetails?: Maybe<Array<ReportingProviderDetails>>
  subType: Scalars['String']
  subTypeDescription?: Maybe<Scalars['String']>
  taxStatusDetails?: Maybe<TaxStatusDetailsResponse>
  updatedAt: Scalars['IsoDateTime']
  version: Scalars['Int']
}

export type NonIndividualAccountDetails = {
  __typename?: 'NonIndividualAccountDetails'
  accountEligibility?: Maybe<AccountEligibility>
  clientEntityAffiliations?: Maybe<ClientEntityAffiliations>
  jointClient?: Maybe<EntityClientAccountDetails>
  primaryClient?: Maybe<EntityClientAccountDetails>
}

export type OfficersAndDirectorsEligibility = {
  __typename?: 'OfficersAndDirectorsEligibility'
  coveredNonPublicCompany: Scalars['Boolean']
  coveredNonPublicCompanyName?: Maybe<Scalars['String']>
  materiallySupportedByEither: Scalars['Boolean']
  materiallySupportedByEitherName?: Maybe<Scalars['String']>
  publicCompany: Scalars['Boolean']
  publicCompanyName?: Maybe<Scalars['String']>
}

export type OnboardingDocsPayload = {
  __typename?: 'OnboardingDocsPayload'
  docs: Array<Scalars['JSON']>
}

/**
 * The `pageInfo` object returned by a query specifies whether there is a previous/next page, and how to reach those pages,
 *     by using the relative cursor.
 *
 *     - To access the next  page: `query (pagination: { first: 10, after: <endCursor>})`
 *     - To access the previous page: `query (pagination: { last: 10, before: <startCursor>})`
 *
 */
export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor?: Maybe<Scalars['String']>
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
  startCursor?: Maybe<Scalars['String']>
}

export type PageResult = {
  pageInfo: PageInfo
}

/**
 * For requesting pagination of the results of a query.
 *     The corresponding return object `PageInfo` should be used in conjunction with this input to retrieve a page's cursor and whether there is another page.
 *
 *     - To access the previous page: `query (pagination: { last: 10, before: <startCursor>})`
 *     - To access the next  page: `query (pagination: { first: 10, after: <endCursor>})`
 *
 */
export type PaginationInput = {
  /**
   * The `after` argument, used in conjunction with `first`, makes it possible to retrieve the next page,
   *         |displaying the `first` (or default) n items `after` the specified cursor.
   *
   */
  after?: InputMaybe<Scalars['String']>
  /**
   * The `before` argument, used in conjunction with `last`, makes it possible to retrieve the previous page,
   *         |displaying the `last` (or default) n items `before` the specified cursor.
   *
   */
  before?: InputMaybe<Scalars['String']>
  /**
   * The `first` argument  (which denotes the page size) should have a default value that makes sense for the given context.
   *         |If in doubt, general guidance is to prefer a default of 100.
   *
   */
  first?: InputMaybe<Scalars['Int']>
  /**
   * The `last` argument  (which denotes the page size) should have a default value that makes sense for the given context.
   *         |If in doubt, general guidance is to prefer a default of 100.
   *
   */
  last?: InputMaybe<Scalars['Int']>
}

export type Permission = {
  __typename?: 'Permission'
  capability: Capability
  description?: Maybe<Scalars['String']>
  id: Scalars['String']
  name: Scalars['String']
}

export enum PermissionAction {
  Allow = 'ALLOW',
}

export enum PipelineDomain {
  Ads = 'ADS',
  Documents = 'DOCUMENTS',
  Unknown = 'UNKNOWN',
}

export type PipelineRerunInput = {
  dagId: Scalars['String']
  runId: Scalars['String']
}

export type PipelineRerunPayload = {
  __typename?: 'PipelineRerunPayload'
  payload: PipelineRun
}

/** A pipeline run */
export type PipelineRun = {
  __typename?: 'PipelineRun'
  domain: PipelineDomain
  executionDate: Scalars['IsoDateTime']
  executionMetadata?: Maybe<Scalars['JSON']>
  pipelineId: Scalars['String']
  runId: Scalars['String']
  state: PipelineState
}

export type PipelineRunInput = {
  config?: InputMaybe<Scalars['String']>
  dagId: Scalars['String']
  domain?: InputMaybe<PipelineDomain>
  runId?: InputMaybe<Scalars['String']>
}

export type PipelineRunPageResult = PageResult & {
  __typename?: 'PipelineRunPageResult'
  items: Array<PipelineRun>
  pageInfo: PageInfo
}

export type PipelineRunPayload = {
  __typename?: 'PipelineRunPayload'
  payload: PipelineRun
}

export type PipelineRunSearchFilterInput = {
  domain?: InputMaybe<StringFilterInput>
  executionDate?: InputMaybe<InstantFilterInput>
  fileId?: InputMaybe<StringFilterInput>
  pipelineId?: InputMaybe<StringFilterInput>
  runId?: InputMaybe<StringFilterInput>
  state?: InputMaybe<StringFilterInput>
}

export enum PipelineRunSort {
  DomainAsc = 'DOMAIN_ASC',
  DomainDesc = 'DOMAIN_DESC',
  ExecutionDateAsc = 'EXECUTION_DATE_ASC',
  ExecutionDateDesc = 'EXECUTION_DATE_DESC',
  FileIdAsc = 'FILE_ID_ASC',
  FileIdDesc = 'FILE_ID_DESC',
  FileNameAsc = 'FILE_NAME_ASC',
  FileNameDesc = 'FILE_NAME_DESC',
  PipelineIdAsc = 'PIPELINE_ID_ASC',
  PipelineIdDesc = 'PIPELINE_ID_DESC',
  RunIdAsc = 'RUN_ID_ASC',
  RunIdDesc = 'RUN_ID_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
}

export enum PipelineState {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Running = 'RUNNING',
  Started = 'STARTED',
}

/**
 * The state of a pipeline run. Example:
 *         {
 *             "state": "COMPLETE",
 *             "message": "success"
 *         }
 *
 */
export type PipelineStateResult = {
  __typename?: 'PipelineStateResult'
  message: Scalars['String']
  state: PipelineState
}

export type PlaceOfBusiness = {
  __typename?: 'PlaceOfBusiness'
  city?: Maybe<Scalars['String']>
  country?: Maybe<Scalars['String']>
  state?: Maybe<Scalars['String']>
}

export type PlaceholderProductPricingComponent = {
  __typename?: 'PlaceholderProductPricingComponent'
  final?: Maybe<Scalars['BigDecimal']>
  high?: Maybe<Scalars['BigDecimal']>
  low?: Maybe<Scalars['BigDecimal']>
  type: Scalars['String']
}

export type PlaceholderProductStructure = {
  __typename?: 'PlaceholderProductStructure'
  structureName?: Maybe<Scalars['String']>
  structureTemplateId: Scalars['ID']
}

export type PlaceholderProductUnderlier = {
  __typename?: 'PlaceholderProductUnderlier'
  legalName?: Maybe<Scalars['String']>
  referenceAssetId?: Maybe<Scalars['ID']>
  ticker: Scalars['String']
}

export type PlaceholderStructuredProduct = {
  __typename?: 'PlaceholderStructuredProduct'
  addons: Scalars['Boolean']
  basketType: Scalars['String']
  closingDateTime: Scalars['IsoDateTime']
  createdAt: Scalars['IsoDateTime']
  createdBy: Scalars['String']
  cusip?: Maybe<Scalars['String']>
  id: Scalars['ID']
  initiatingSalesperson: Scalars['ID']
  issuer: ProductIssuer
  maxNotional?: Maybe<Scalars['BigDecimal']>
  offeringType: Scalars['String']
  placeholderId: Scalars['String']
  pricingComponent?: Maybe<PlaceholderProductPricingComponent>
  structure: PlaceholderProductStructure
  structuredProductId?: Maybe<Scalars['ID']>
  tenorFinal: Scalars['BigDecimal']
  tradeDate: Scalars['Date']
  underliers: Array<PlaceholderProductUnderlier>
  updatedAt: Scalars['IsoDateTime']
  updatedBy: Scalars['String']
  version: Scalars['Int']
}

export type PlaceholderStructuredProductPageResult = PageResult & {
  __typename?: 'PlaceholderStructuredProductPageResult'
  items: Array<PlaceholderStructuredProduct>
  pageInfo: PageInfo
}

export type PoliticalAffiliations = {
  __typename?: 'PoliticalAffiliations'
  governmentOwned?: Maybe<Scalars['Boolean']>
  governmentOwnedBy?: Maybe<Scalars['String']>
  politicalFigure?: Maybe<Scalars['Boolean']>
  politicalFigureDetails?: Maybe<PoliticalFigureDetails>
  politicallyAffiliated?: Maybe<Scalars['Boolean']>
}

export type PoliticalFigureDetails = {
  __typename?: 'PoliticalFigureDetails'
  government: Scalars['String']
  positionInGovernment: Scalars['String']
  relationshipToFigure?: Maybe<Scalars['String']>
}

export type PortalLegacy = {
  __typename?: 'PortalLegacy'
  id: Scalars['Int']
  salesforceTicketId?: Maybe<Scalars['String']>
  uuid: Scalars['UUID']
}

export type PortalLegacyInput = {
  id: Scalars['Int']
  salesforceTicketId?: InputMaybe<Scalars['String']>
  uuid: Scalars['UUID']
}

export type PortfolioManagerEligibility = {
  __typename?: 'PortfolioManagerEligibility'
  familyOrMaterialSupport: Scalars['Boolean']
  portfolioManager: Scalars['Boolean']
}

/** Position Accounting Lot Valuation Details */
export type PositionAccountingLotValuation = {
  __typename?: 'PositionAccountingLotValuation'
  action: Scalars['String']
  beginningCapital?: Maybe<Money>
  contributions?: Maybe<Money>
  contributionsItd?: Maybe<Money>
  contributionsYtd?: Maybe<Money>
  createdAt?: Maybe<Scalars['IsoDateTime']>
  drawdownSummary?: Maybe<DrawdownSummary>
  endingCapital?: Maybe<Money>
  externalCreatedAt?: Maybe<Scalars['IsoDateTime']>
  externalIds?: Maybe<ExternalIds>
  gainOrLoss?: Maybe<GainOrLoss>
  id?: Maybe<Scalars['UUID']>
  incentiveFee?: Maybe<Money>
  managementFee?: Maybe<Money>
  navDate: Scalars['Date']
  netCapitalActivity?: Maybe<NetCapitalActivity>
  netInvestorIrr?: Maybe<Scalars['BigDecimal']>
  netMultiple?: Maybe<Scalars['BigDecimal']>
  netReturns?: Maybe<NetReturns>
  openingCapital?: Maybe<Money>
  operatingFundExpense?: Maybe<Money>
  periodEndDate: Scalars['Date']
  periodStartDate: Scalars['Date']
  positionAccountingLotId: Scalars['UUID']
  publicationStatus: Scalars['String']
  receivedAt: Scalars['IsoDateTime']
  shares?: Maybe<PositionShares>
  source: PositionSource
  stage: Scalars['String']
  startingCapital?: Maybe<Money>
  startingCapitalDate?: Maybe<Scalars['Date']>
  valuationStatus: Scalars['String']
  version?: Maybe<Scalars['Int']>
  withdrawals?: Maybe<Withdrawals>
}

export enum PositionAccountingLotValuationsSort {
  Asc = 'ASC',
  Desc = 'DESC',
}

/** Client account details */
export type PositionClientAccount = {
  __typename?: 'PositionClientAccount'
  /** Brokerage account number */
  brokerageAccountNumber?: Maybe<Scalars['String']>
  createdAt: Scalars['IsoDateTime']
  createdBy: Scalars['String']
  /** Custodian account number */
  custodianAccountNumber?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['IsoDateTime']>
  deletedBy?: Maybe<Scalars['String']>
  /** Primary client ID derived from client account, populated only for individual accounts at the moment */
  derivedClientId?: Maybe<Scalars['ID']>
  /** Primary client name derived from individual or business client account */
  derivedClientName?: Maybe<Scalars['String']>
  /** Account fee structure, i.e. `TRANSACTIONAL` or `ADVISORY`, indicates brokerage or advisory account capacity */
  feeStructure?: Maybe<Scalars['String']>
  /** Client household's firm id */
  householdFirmId: Scalars['ID']
  /** Client household id */
  householdId: Scalars['ID']
  /** Client household name */
  householdName: Scalars['String']
  /** Account id */
  id: Scalars['ID']
  /** Joint client first name for individual joint account */
  jointClientFirstName?: Maybe<Scalars['String']>
  /** Joint client id for individual joint account */
  jointClientId?: Maybe<Scalars['ID']>
  /** Joint client last name for individual joint account */
  jointClientLastName?: Maybe<Scalars['String']>
  /** Joint client version for individual joint account */
  jointClientVersion?: Maybe<Scalars['Int']>
  /** Joint entity name for non-individual account */
  jointEntityName?: Maybe<Scalars['String']>
  /** Account name */
  name: Scalars['String']
  /** Primary client first name for individual single account */
  primaryClientFirstName?: Maybe<Scalars['String']>
  /** Primary client id for individual single/joint account */
  primaryClientId?: Maybe<Scalars['ID']>
  /** Primary client last name for individual single account */
  primaryClientLastName?: Maybe<Scalars['String']>
  /** Primary client version for individual single account */
  primaryClientVersion?: Maybe<Scalars['Int']>
  /** Entity name for non-individual account */
  primaryEntityName?: Maybe<Scalars['String']>
  /** Account name (category) */
  type: Scalars['String']
  updatedAt: Scalars['IsoDateTime']
  updatedBy: Scalars['String']
  version: Scalars['Int']
}

export type PositionCustodianDetails = {
  __typename?: 'PositionCustodianDetails'
  /** Custodian long name */
  name: Scalars['String']
}

export type PositionShares = {
  __typename?: 'PositionShares'
  navPerShare: Scalars['BigDecimal']
  shareBalance: ShareBalance
}

export type PositionSource = {
  __typename?: 'PositionSource'
  id?: Maybe<Scalars['UUID']>
  sourceType: Scalars['String']
  version?: Maybe<Scalars['Int']>
}

export type PositionsPageResult = PageResult & {
  __typename?: 'PositionsPageResult'
  items: Array<StructuredProductPosition>
  pageInfo: PageInfo
}

export type PricingComponent = {
  __typename?: 'PricingComponent'
  final?: Maybe<Scalars['BigDecimal']>
  high?: Maybe<Scalars['BigDecimal']>
  low?: Maybe<Scalars['BigDecimal']>
  max?: Maybe<Scalars['BigDecimal']>
  min?: Maybe<Scalars['BigDecimal']>
}

export type PricingComponentInput = {
  final?: InputMaybe<Scalars['BigDecimal']>
  high?: InputMaybe<Scalars['BigDecimal']>
  low?: InputMaybe<Scalars['BigDecimal']>
  max?: InputMaybe<Scalars['BigDecimal']>
  min?: InputMaybe<Scalars['BigDecimal']>
}

export type PrimarySigners = {
  __typename?: 'PrimarySigners'
  jointTenantPrimarySigner?: Maybe<ContactReference>
  jointTrustSigner?: Maybe<ContactReference>
  primarySigner: ContactReference
}

export type PrimarySignersInput = {
  jointTenantPrimarySignerId?: InputMaybe<Scalars['ID']>
  jointTrustSignerId?: InputMaybe<Scalars['ID']>
  primarySignerId: Scalars['ID']
}

export type Product = {
  caisId: Scalars['String']
  category?: Maybe<Scalars['String']>
  currencyCode: ProductStringField
  id: Scalars['ID']
  identifiers: Array<ProductIdentifier>
  name?: Maybe<Scalars['String']>
  price: Array<ProductPrice>
  productType: ProductType
  publishState: ProductPublishState
  version: Scalars['Int']
}

/**
 * This is a Metadata wrapper around the type, to access the field's information look in value.
 *         In metadata you will get additional presentation and logic information.
 *
 *         Metadata is guaranteed to come back, to know whether value is mandatory look whether this object is mandatory where it is used.
 *
 */
export type ProductBigDecimalField = {
  __typename?: 'ProductBigDecimalField'
  metadata: ProductFieldMetadata
  value?: Maybe<Scalars['BigDecimal']>
}

export type ProductBooleanField = {
  __typename?: 'ProductBooleanField'
  metadata: ProductFieldMetadata
  value?: Maybe<Scalars['Boolean']>
}

export enum ProductFieldApplicability {
  Applicable = 'APPLICABLE',
  NonApplicable = 'NON_APPLICABLE',
  Unknown = 'UNKNOWN',
  /** @deprecated use the UNKNOWN value */
  UnKnown = 'UN_KNOWN',
}

/**
 * Many types within CAIS have metadata, this carries logic and presentation data.
 *
 *         Metadata can be held at the product level or at the structure level, for the fields value you can see where it is being fed from in the source field.
 *
 *         For other fields, such as applicability, you can use look at the associated applicabilityOverridden field to see whether it has been overridden at the
 *         Product level.
 *
 */
export type ProductFieldMetadata = {
  __typename?: 'ProductFieldMetadata'
  applicability?: Maybe<ProductFieldApplicability>
  source: ProductFieldSource
  visibility?: Maybe<ProductFieldVisibility>
}

/**
 * The ProductFieldSource signifies where a fields current data is fed from.
 *
 *         If a field has a source of MANUAL_OVERRIDE, updates from any other source will not be applied.
 *
 *         If you change from MANUAL_OVERRIDE to WSD, the value from WSD will be used and the overridden value will be lost.
 *         Once changed back to WSD, Product Master will go to get this new information straight away.
 *
 *         Note that the corresponding input of this field you can specify "connect: true" and it will set the field's source to the default associated source.
 *         This default source is known by The Product Master service.
 *
 */
export enum ProductFieldSource {
  Issuer = 'ISSUER',
  ManualOverride = 'MANUAL_OVERRIDE',
  ProductSourceUnspecified = 'PRODUCT_SOURCE_UNSPECIFIED',
  Refinitiv = 'REFINITIV',
  StructureTemplate = 'STRUCTURE_TEMPLATE',
  WallStreetDocs = 'WALL_STREET_DOCS',
}

export enum ProductFieldSourceInput {
  Default = 'DEFAULT',
  ManualOverride = 'MANUAL_OVERRIDE',
}

export enum ProductFieldVisibility {
  NonVisible = 'NON_VISIBLE',
  Unknown = 'UNKNOWN',
  /** @deprecated use the UNKNOWN value */
  UnKnown = 'UN_KNOWN',
  Visible = 'VISIBLE',
}

/**
 * This is a Metadata wrapper around the type, to access the field's information look in value.
 *         In metadata you will get additional presentation and logic information.
 *
 *         Metadata is guaranteed to come back, to know whether value is mandatory look whether this object is mandatory where it is used.
 *
 */
export type ProductFrequencyField = {
  __typename?: 'ProductFrequencyField'
  metadata: ProductFieldMetadata
  value?: Maybe<Frequency>
}

export type ProductIdentifier = {
  __typename?: 'ProductIdentifier'
  type: ProductIdentifierType
  value: Scalars['String']
}

export type ProductIdentifierFilterInput = {
  cusip?: InputMaybe<StringFilterInput>
  isin?: InputMaybe<StringFilterInput>
}

export type ProductIdentifierLimit = ReferenceAssetError & {
  __typename?: 'ProductIdentifierLimit'
  message: Scalars['String']
}

export type ProductIdentifierMissing = ReferenceAssetError & {
  __typename?: 'ProductIdentifierMissing'
  message: Scalars['String']
}

export enum ProductIdentifierType {
  BbgTicker = 'BBG_TICKER',
  Cins = 'CINS',
  Cusip = 'CUSIP',
  Isin = 'ISIN',
  Ric = 'RIC',
  Valor = 'VALOR',
}

export type ProductInstantField = {
  __typename?: 'ProductInstantField'
  metadata: ProductFieldMetadata
  value?: Maybe<Scalars['IsoDateTime']>
}

export type ProductIssuer = {
  __typename?: 'ProductIssuer'
  acronym?: Maybe<Scalars['String']>
  groupName?: Maybe<Scalars['String']>
  id: Scalars['ID']
  legalName?: Maybe<Scalars['String']>
  logoId?: Maybe<Scalars['String']>
  logoURLs?: Maybe<LogoUrl>
}

/**
 * This is a Metadata wrapper around the type, to access the field's information look in value.
 *         In metadata you will get additional presentation and logic information.
 *
 *         Metadata is guaranteed to come back, to know whether value is mandatory look whether this object is mandatory where it is used.
 *
 */
export type ProductIssuerField = {
  __typename?: 'ProductIssuerField'
  metadata: ProductFieldMetadata
  value?: Maybe<Issuer>
}

export type ProductLocalDateField = {
  __typename?: 'ProductLocalDateField'
  metadata: ProductFieldMetadata
  value?: Maybe<Scalars['Date']>
}

export type ProductPageResult = PageResult & {
  __typename?: 'ProductPageResult'
  items: Array<Product>
  pageInfo: PageInfo
}

export type ProductPermission = {
  __typename?: 'ProductPermission'
  createdAt: Scalars['IsoDateTime']
  createdBy: Scalars['String']
  id: Scalars['ID']
  identifiers: Array<ProductIdentifier>
  productId: Scalars['ID']
  profileId: Scalars['ID']
  profileType: ProfileType
  updatedAt?: Maybe<Scalars['IsoDateTime']>
  updatedBy?: Maybe<Scalars['String']>
  version: Scalars['Int']
  viewAction: PermissionAction
}

export type ProductPermissionDeletionResult = {
  __typename?: 'ProductPermissionDeletionResult'
  success: Scalars['Boolean']
}

export type ProductPermissionFailure = {
  __typename?: 'ProductPermissionFailure'
  productId: Scalars['String']
  reason: Scalars['String']
}

export type ProductPermissionInput = {
  /** This can be a CUSIP, ISIN or UUID for the product. Which it is will be calculated from the string length. 9 = CUSIP, 12 = ISIN, 36 = UUID */
  productId: Scalars['String']
  profileId: Scalars['String']
  profileType: ProfileType
  viewAction: PermissionAction
}

export type ProductPermissionsCreationResult = {
  __typename?: 'ProductPermissionsCreationResult'
  failures?: Maybe<Array<ProductPermissionFailure>>
  successful?: Maybe<Array<ProductPermission>>
}

export type ProductPermissionsFilterInput = {
  id?: InputMaybe<IdFilterInput>
  productId?: InputMaybe<IdFilterInput>
  profileId?: InputMaybe<IdFilterInput>
  profileType?: InputMaybe<ProfileTypeFilterInput>
  viewAction?: InputMaybe<ViewActionFilterInput>
}

export type ProductPermissionsPageResult = {
  __typename?: 'ProductPermissionsPageResult'
  items: Array<ProductPermission>
  pageInfo: PageInfo
}

export enum ProductPermissionsSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ProductIdAsc = 'PRODUCT_ID_ASC',
  ProductIdDesc = 'PRODUCT_ID_DESC',
  ProfileIdAsc = 'PROFILE_ID_ASC',
  ProfileIdDesc = 'PROFILE_ID_DESC',
}

export type ProductPrice = {
  __typename?: 'ProductPrice'
  priceType: ProductPriceType
  source: ProductPriceSource
  value: Scalars['BigDecimal']
  valueDate: Scalars['IsoDateTime']
  valueType: ProductPriceValueType
}

export enum ProductPriceSource {
  Manual = 'MANUAL',
  Refinitiv = 'REFINITIV',
  Wsd = 'WSD',
}

export enum ProductPriceType {
  Ask = 'ASK',
  Bid = 'BID',
  Close = 'CLOSE',
  DailyHigh = 'DAILY_HIGH',
  DailyLow = 'DAILY_LOW',
  Mid = 'MID',
  Nav = 'NAV',
}

export enum ProductPriceValueType {
  Absolute = 'ABSOLUTE',
  Percentage = 'PERCENTAGE',
}

export type ProductPrices = {
  __typename?: 'ProductPrices'
  prices: Array<ProductPrice>
  productId: Scalars['ID']
}

export type ProductPricesFilterInput = {
  priceTypes?: InputMaybe<Array<ProductPriceType>>
  sources?: InputMaybe<Array<ProductPriceSource>>
  timeRange?: InputMaybe<ProductPricesTimeRangeInput>
  valueTypes?: InputMaybe<Array<ProductPriceValueType>>
}

export type ProductPricesTimeRangeInput = {
  /** From inclusive */
  from?: InputMaybe<Scalars['IsoDateTime']>
  /** To inclusive */
  to?: InputMaybe<Scalars['IsoDateTime']>
}

/**
 * This is a Metadata wrapper around the type, to access the field's information look in value.
 *         In metadata you will get additional presentation and logic information.
 *
 *         Metadata is guaranteed to come back, to know whether value is mandatory look whether this object is mandatory where it is used.
 *
 */
export type ProductPricingComponentField = {
  __typename?: 'ProductPricingComponentField'
  metadata: ProductFieldMetadata
  value?: Maybe<PricingComponent>
}

export enum ProductPublishState {
  Disabled = 'DISABLED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED',
}

export type ProductStatusFilterInput = {
  /** Equals */
  eq?: InputMaybe<StructuredProductStatus>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<StructuredProductStatus>>
  /** Not Equals */
  ne?: InputMaybe<StructuredProductStatus>
}

export type ProductStringField = {
  __typename?: 'ProductStringField'
  metadata: ProductFieldMetadata
  value?: Maybe<Scalars['String']>
}

export type ProductTradeStatusFilterInput = {
  /** Equals */
  eq?: InputMaybe<StructuredProductTradeStatus>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<StructuredProductTradeStatus>>
  /** Not Equals */
  ne?: InputMaybe<StructuredProductTradeStatus>
}

export type ProductTransferFrom = {
  __typename?: 'ProductTransferFrom'
  fromFundId?: Maybe<Scalars['UUID']>
  fromSeries?: Maybe<Scalars['String']>
  fromShareClassId?: Maybe<Scalars['UUID']>
}

export type ProductTransferTo = {
  __typename?: 'ProductTransferTo'
  toFundId?: Maybe<Scalars['UUID']>
  toSeries?: Maybe<Scalars['String']>
  toShareClassId?: Maybe<Scalars['UUID']>
}

export enum ProductType {
  Alternative = 'ALTERNATIVE',
  Commodity = 'COMMODITY',
  CommonStock = 'COMMON_STOCK',
  Equity = 'EQUITY',
  ExchangeTradedFund = 'EXCHANGE_TRADED_FUND',
  Fx = 'FX',
  Index = 'INDEX',
  InterestRate = 'INTEREST_RATE',
  RealEstate = 'REAL_ESTATE',
  StructuredProduct = 'STRUCTURED_PRODUCT',
}

export type ProductTypeFilterInput = {
  /** Equals */
  eq?: InputMaybe<ProductType>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<ProductType>>
  /** Not Equals */
  ne?: InputMaybe<ProductType>
}

export enum ProfileType {
  Firm = 'FIRM',
}

export type ProfileTypeFilterInput = {
  /** Equals */
  eq?: InputMaybe<ProfileType>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<ProfileType>>
}

/** Publication Status values */
export enum PublicationStatus {
  Published = 'PUBLISHED',
  Staged = 'STAGED',
}

export type PublicationStatusEnumFilterInput = {
  eq?: InputMaybe<PublicationStatus>
  in?: InputMaybe<Array<PublicationStatus>>
  ne?: InputMaybe<PublicationStatus>
}

export type PublishDraftStructuredProductPayload = {
  __typename?: 'PublishDraftStructuredProductPayload'
  error?: Maybe<StructuredProductError>
  structuredProduct?: Maybe<StructuredProduct>
}

export type PublishFundProductAdminPayload = {
  __typename?: 'PublishFundProductAdminPayload'
  fundProduct: FundProductAdmin
}

export type PublishFundProductShareClassAdminPayload = {
  __typename?: 'PublishFundProductShareClassAdminPayload'
  fundProduct: FundProductAdmin
}

export type PublishStateFilterInput = {
  /** Equals */
  eq?: InputMaybe<ProductPublishState>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<ProductPublishState>>
  /** Not Equals */
  ne?: InputMaybe<ProductPublishState>
}

export enum QualifiedClientStatusCode {
  Iqc01 = 'IQC01',
  Iqc02 = 'IQC02',
  Niqc01 = 'NIQC01',
  Niqc02 = 'NIQC02',
}

export type QualifiedClientStatusInput = {
  code: QualifiedClientStatusCode
}

export type QualifiedClientStatusResponse = {
  __typename?: 'QualifiedClientStatusResponse'
  code: Scalars['String']
}

export enum QualifiedEligiblePersonStatusCode {
  Iqep01 = 'IQEP01',
  Iqep02 = 'IQEP02',
  Iqep03 = 'IQEP03',
  Iqep04 = 'IQEP04',
  Niqep01 = 'NIQEP01',
  Niqep02 = 'NIQEP02',
  Niqep03 = 'NIQEP03',
  Niqep04 = 'NIQEP04',
}

export type QualifiedEligiblePersonStatusInput = {
  code: QualifiedEligiblePersonStatusCode
}

export type QualifiedEligiblePersonStatusResponse = {
  __typename?: 'QualifiedEligiblePersonStatusResponse'
  code: Scalars['String']
}

export enum QualifiedPurchaserStatusCode {
  Iqp01 = 'IQP01',
  Iqp02 = 'IQP02',
  Iqp03 = 'IQP03',
  Iqp04 = 'IQP04',
  Niqp01 = 'NIQP01',
  Niqp02 = 'NIQP02',
  Niqp03 = 'NIQP03',
  Niqp04 = 'NIQP04',
  Niqp05 = 'NIQP05',
  Niqp06 = 'NIQP06',
  Niqp07 = 'NIQP07',
  Niqp08 = 'NIQP08',
  Niqp09 = 'NIQP09',
  Niqp10 = 'NIQP10',
}

export type QualifiedPurchaserStatusInput = {
  code: QualifiedPurchaserStatusCode
}

export type QualifiedPurchaserStatusResponse = {
  __typename?: 'QualifiedPurchaserStatusResponse'
  code: Scalars['String']
}

export type Query = {
  __typename?: 'Query'
  /** Retrieving an account by id. */
  account: Account
  /** Get a list of account documents */
  accountDocuments: Array<KycDocument>
  /** Get a list of account types */
  accountTypes: Array<AccountTypeDetails>
  adminFundProducts: FundProductAdminPageResult
  /**
   *
   *         Example:
   *         query {
   *           adminTypes {
   *             id
   *             name
   *             adminTypePortalId
   *           }
   *         }
   *
   */
  adminTypes: Array<AdminTypeResult>
  /**
   *
   *         Example:
   *         query {
   *           benchmarks {
   *             id
   *             name
   *           }
   *         }
   *
   */
  benchmarks: Array<BenchmarkResult>
  /** Retrieves information about available capabilities */
  capabilities: Array<Capability>
  /** Retrieving a contact by id. */
  contact: Contact
  /** Get a list of accounts by contact */
  contactAccounts: Array<AccountListItemForContact>
  custodian: CustodianQueries
  /**
   *
   *         Example:
   *         query {
   *           custodians {
   *             id
   *             name
   *           }
   *         }
   *
   */
  custodians: Array<CustodianNameResult>
  documentSubmissionFailuresCsv: DownloadLink
  documents: DocumentPageResult
  documentsDownload: DocumentDownloadUrl
  downloadMercerServicesDocument: DownloadLink
  /**
   *
   *         Gets a product upload by Id.
   *
   *         The following example query gets the status of a specific product upload:
   *         {
   *             draftStructuredProductUpload(id = "ee227f20-e247-428a-b6cc-004bb26838b6") {
   *                 status
   *             }
   *         }
   *
   */
  draftStructuredProductUpload: StructuredProductDraftUpload
  /**
   *
   *         Gets the result of an asynchronous publish all operation by by Id.
   *
   *         The following example query gets the status of a specific publish all operation:
   *         {
   *             draftStructuredProductUploadPublishAllStatus(productUploadId: "ee227f20-e247-428a-b6cc-004bb26838b7", id: "ee227f20-e247-428a-b6cc-004bb26838b6") {
   *                 status
   *             }
   *         }
   *
   */
  draftStructuredProductUploadPublishAllStatus: StructuredProductDraftPublishAll
  /**
   *
   *         Find Draft Product Upload results based on filter, sort and pagination criteria.
   *         The following example query gets the id of all structuredProducts that were uploaded. The first 10 items are returned:
   *
   *         query {
   *           draftStructuredProductUploadResults(
   *             filter: {
   *               status: { eq: SUCCESS }
   *             }
   *             pagination: { first: 10 }
   *             sort: ID_ASC
   *           ) {
   *             pageInfo {
   *               endCursor
   *               hasNextPage
   *             }
   *             items {
   *               id
   *             }
   *           }
   *         }
   *
   */
  draftStructuredProductUploadResults: StructuredProductDraftUploadPageResult
  /**
   *
   *         Find Draft Product uploads based on filter, sort and pagination criteria.
   *         The following example query gets the id of all structured product uploads that were performed by a user with the given id.
   *         The latest 10 items are returned:
   *
   *         query {
   *           draftStructuredProductUploads(
   *             filter: {
   *               createdBy: { eq: "81dad282-dba7-4423-bce0-65e27187ca89" }
   *             }
   *             pagination: { first: 10 }
   *             sort: CREATED_AT_DESC
   *           ) {
   *             pageInfo {
   *               endCursor
   *               hasNextPage
   *             }
   *             items {
   *               id
   *               fileName
   *               createdBy
   *               createdAt
   *               status
   *             }
   *           }
   *         }
   *
   */
  draftStructuredProductUploads: StructuredProductDraftUploadPage
  files: FilePageResult
  /** Retrieving an individual firm by ID. */
  firm: Firm
  /** Retrieving a list of firms by free text search and optional parent firm ID */
  firmNames: Array<EntityName>
  firmPermissions: Array<Permission>
  firmRoles: Array<Role>
  /** Retrieving a list of firms */
  firms: FirmsPageResult
  /**
   *
   *         Example:
   *           query {
   *               fundAdministratorTemplates {
   *                   templateId
   *                   templateName
   *                   category
   *                   fundAdminId
   *                   fundId
   *               }
   *             }
   *
   */
  fundAdministratorTemplates: Array<FundAdministratorTemplate>
  /**
   *
   *         Example:
   *           query {
   *               fundAdministrators {
   *                 items {
   *                   id
   *                   name
   *                 }
   *               }
   *             }
   *
   */
  fundAdministrators: FundAdministratorPageResult
  fundAggregations: FundAggregations
  fundAggregationsMultiFilter: Array<FundAggregations>
  /**
   *
   *         Example:
   *         query {
   *           fundManagers {
   *             id
   *             name
   *           }
   *         }
   *
   */
  fundManagers: Array<FundManagerResult>
  /**
   *
   *           Get the advisor firms of accounts that have a position.
   *
   *           query {
   *             fundPositionAdvisorFirms {
   *               items {
   *                 firmId
   *                 firmName
   *               }
   *               pageInfo {
   *                 startCursor
   *                 endCursor
   *                 hasNextPage
   *                 hasPreviousPage
   *               }
   *             }
   *           }
   *
   */
  fundPositionAdvisorFirms: FundPositionAdvisorFirmsOutput
  fundProduct: FundProduct
  fundProductAdmin: FundProductAdmin
  fundProductDocuments: FundProductDocumentQuery
  fundProductSearch: FundProductSearchResult
  /**
   *
   *         Retrieve a list of funds
   *         Example:
   *           query {
   *               fundReferences {
   *                   items {
   *                       id
   *                       type
   *                       name
   *                       fundAdminId
   *                       aliases
   *                   }
   *               }
   *           }
   *         Retrieve a list of funds with a specific fund admin
   *             query {
   *                 fundReferences(fundAdminId: "a6e2e77d-2a52-436e-b4d9-3e5807cfda9a") {
   *                   items {
   *                       id
   *                       type
   *                       name
   *                       fundAdminId
   *                       aliases
   *                      }
   *                   }
   *             }
   *
   */
  fundReferences: FundReferencePageResult
  /**
   *
   *         Example:
   *         query {
   *           fundStatuses {
   *             id
   *             name
   *           }
   *         }
   *
   */
  fundStatuses: Array<FundStatusResult>
  /**
   *
   *             Get all accounts
   *
   *             query {
   *               getAccountFilterOptions {
   *                  items {
   *                     id
   *                     name
   *                 }
   *                 pageInfo {
   *                   startCursor
   *                   endCursor
   *                   hasNextPage
   *                   hasPreviousPage
   *                 }
   *               }
   *             }
   *
   */
  getAccountFilterOptions: AccountFilterOptions
  /** Returns a list of allowed fund product IDs, names and shareclass IDs and shareclass names */
  getAllowedFundProductIdsAndNames: FundProductIdsAndNames
  /**
   *
   *             Get all funds
   *
   *             query {
   *               getFundFilterOptions {
   *                  items {
   *                     id
   *                     name
   *                 }
   *                 pageInfo {
   *                   startCursor
   *                   endCursor
   *                   hasNextPage
   *                   hasPreviousPage
   *                 }
   *               }
   *             }
   *
   */
  getFundFilterOptions: FundFilterOptions
  /**
   *
   *             Get all households that are tied to an investment.
   *
   *             query {
   *               getHouseholdFilterOptions {
   *                  items {
   *                     id
   *                     name
   *                 }
   *                 pageInfo {
   *                   startCursor
   *                   endCursor
   *                   hasNextPage
   *                   hasPreviousPage
   *                 }
   *               }
   *             }
   *
   */
  getHouseholdFilterOptions: HouseholdFilterOptions
  /**
   *
   *         Example:
   *         query {
   *           getProductOnboardingDocs(input: { fundId: 1 }) {
   *             docs
   *           }
   *         }
   *
   */
  getProductOnboardingDocs: OnboardingDocsPayload
  /**
   *
   *         Retrieve a list of holidays - in this case public holiday in the US for years 2023 and 2024
   *             query {
   *                 holidays(years: [2023, 2024], country: "US", publicOnly: true) {
   *                   items {
   *                       name
   *                       date
   *                       observed
   *                       public
   *                       country
   *                      }
   *                   }
   *             }
   *
   */
  holidays: Array<Holiday>
  /** Retrieving a household by id. */
  household: Household
  /** Get a list of contacts associated with a given household. */
  householdContacts: Array<Contact>
  /** Get the external IDs of an investment. */
  investmentExternalIds: ExternalIds
  /** Fetch an issuer by ID. When an issuer is not found, an error will be returned */
  issuer: Issuer
  /** Fetch an issuerGroup by ID. When an issuerGroup is not found, error will be returned */
  issuerGroup: IssuerGroup
  /**
   *
   *         Find IssuerGroups based on filter, sort and pagination criteria.
   *         The following example query gets the id, name and version of all issuerGroups matching the name. The first 50 items are returned:
   *
   *          query {
   *           issuerGroups(
   *             filter: { name: { like: "%MORG%" } }
   *             pagination: { first: 10 }
   *             sort: NAME_ASC
   *           ) {
   *             pageInfo {
   *               startCursor
   *               hasPreviousPage
   *             }
   *             items {
   *               id
   *               name
   *               version
   *             }
   *           }
   *         }
   *
   */
  issuerGroups: IssuerGroupPageResult
  /**
   *
   *         Find Issuers based on filter, sort and pagination criteria.
   *         The following example query gets the id, legalName and group of all issuers matching the legalName. The first 50 items are returned:
   *
   *         query {
   *           issuers(
   *             filter: {
   *               or: {
   *                 legalName: { caseSensitive: false, like: "%jpm%" }
   *                 groupName: { caseSensitive: false, like: "%jpm%" }
   *               }
   *             }
   *             pagination: { first: 10 }
   *             sort: LEGAL_NAME_ASC
   *           ) {
   *             pageInfo {
   *               endCursor
   *               hasNextPage
   *             }
   *             items {
   *               id
   *               legalName
   *               group {
   *                 id
   *                 name
   *               }
   *               logoURL,
   *             }
   *           }
   *         }
   *
   */
  issuers: IssuerPageResult
  /**
   *
   *           Find PipelineRuns based on filter, sort and pagination criteria.
   *
   *           The following example query gets the details of pipelineRuns that have "COMPLETE" state.
   *           The first 20 items are returned and sorte by execution date:
   *
   *           query {
   *             pipelineRuns(
   *               filter: { state: { eq: "COMPLETE" } }
   *               pagination: { first: 20 }
   *               sort: [ EXECUTION_DATE_ASC ]
   *             ) items {
   *               runId
   *               pipelineId
   *               domain
   *               executionDate
   *               state
   *               executionMetadata
   *             }
   *             pageInfo {
   *               startCursor
   *               endCursor
   *               hasNextPage
   *               hasPreviousPage
   *             }
   *           }
   *
   */
  pipelineRuns: PipelineRunPageResult
  /** Get the external IDs of a position. */
  positionExternalIds: ExternalIds
  /**
   *
   *         Find ProductPermissions based on filter, sort and pagination criteria.
   *         The following example query gets all product permissions for a certain firm
   *
   *          query {
   *           productPermissions(
   *             filter: {
   *               profileId: { eq: "c280c0a2-c8fb-44b2-9d85-6f8cf444ea54" }
   *               profileType: { eq: FIRM }
   *             }
   *             pagination: { first: 10 }
   *             sort: CREATED_AT_DESC
   *           ) {
   *             pageInfo {
   *               startCursor
   *               hasPreviousPage
   *             }
   *             items {
   *               id
   *               productId
   *               profileId
   *               viewAction
   *             }
   *           }
   *         }
   *
   */
  productPermissions: ProductPermissionsPageResult
  productPrices: Array<ProductPrice>
  /**
   *
   *         Gets a reference asset by Id.
   *
   *         The following example query gets the status of a specific product upload:
   *         {
   *             id
   *             caisId
   *             name
   *             identifiers {
   *                 value
   *                 type
   *             }
   *             ticker
   *             ric
   *             exchangeId
   *             publishState
   *             productType
   *             category
   *             currencyCode {
   *               value
   *               metadata {
   *                 source
   *                 visibility
   *                 applicability
   *               }
   *             }
   *             price {
   *                 priceType
   *                 valueType
   *                 value
   *                 source
   *                 valueDate
   *             }
   *             customName
   *             createdAt
   *             createdBy
   *             updatedAt
   *             updatedBy
   *             version
   *             latestCloseLevel
   *             latestCloseAsOf
   *         }
   *
   */
  referenceAsset: ReferenceAsset
  /**
   *
   *         Find reference assets based on filter, sort and pagination criteria.
   *
   *         The following example query gets the id and productType of all reference assets of a specific RIC. The first 50 items are returned:
   *         {
   *             referenceAssets(filter: {
   *                 identifier: { ric: { eq: ".SPY" } }
   *             }, pagination: {
   *                 first: 50
   *             }) {
   *                 items {
   *                     identifiers {
   *                         value
   *                         type
   *                     }
   *                     productType
   *                 }
   *                 pageInfo {
   *                     endCursor
   *                     hasNextPage
   *                 }
   *             }
   *         }
   *
   *         Advanced query that includes multiple filtering and sorting:
   *         {
   *           referenceAssets(
   *             filter: {
   *               ticker: { eq: "GOOG" }
   *               name: { eq: "Alphabet Inc" }
   *             }
   *             pagination: { first: 50 }
   *             sort: NAME_ASC
   *           ) {
   *             items {
   *               identifiers {
   *                 value
   *                 type
   *               }
   *               name
   *               currencyCode { value }
   *               ... on CommonStockProduct { ticker exchangeId }
   *               ... on EquityProduct { ticker exchangeId }
   *               ... on IndexProduct { ticker exchangeId }
   *               ... on ExchangeTradedFundProduct { ticker exchangeId }
   *               ... on CommodityProduct { ticker exchangeId }
   *             }
   *             pageInfo {
   *               endCursor
   *               hasNextPage
   *             }
   *           }
   *         }
   *
   */
  referenceAssets: ProductPageResult
  /** Retrieves a list of accounts matching the given criteria. */
  searchAccounts: AccountListItemPageResult
  /** Retrieves a list of contacts matching the given criteria. */
  searchContacts: ContactListItemPageResult
  searchDataPipeline: DataPipelineOutput
  /** Search for access policies configured for a firm for given fund product id */
  searchFirmAccessPolicies: SubjectShareClassAccessPolicyPageV1
  /** @deprecated Use 'searchFundProductSubjectAccessPolicies' */
  searchFundProductFirmAccessPolicies: FirmShareClassAccessPolicyPage
  /** Retrieving a list of households with a given name. */
  searchHouseholds: HouseholdPageResult
  searchPositionAccountingLotValuations: SearchPositionAccountingLotValuationsOutput
  searchTransactions: SearchTransactionsOutput
  /** Retrieving a list of service identities */
  serviceIdentities: ServiceIdentitiesPageResult
  /** Retrieve a single service identity */
  serviceIdentity: ServiceIdentity
  /**
   *
   *
   *           Get the state of a pipeline run
   *
   *           query {
   *             state {
   *               state,
   *               message
   *             }
   *           }
   *
   *
   */
  state: PipelineStateResult
  structuredProduct?: Maybe<StructuredProduct>
  /**
   *
   *         Get the full list of supported currencies for structured products
   *
   *         query {
   *           structuredProductCurrencies {
   *             code
   *             name
   *           }
   *         }
   *
   */
  structuredProductCurrencies: Array<Currency>
  /** Fetch an structure by ID. When an structure is not found, an error will be returned */
  structuredProductStructureTemplate: StructuredProductStructureTemplate
  /**
   *
   *         Find StructuredProductStructureTemplates based on filter, sort and pagination criteria.
   *
   *         query {
   *           structuredProductStructureTemplates(
   *             filter: { name: { eq: "Autocallable", caseSensitive: true } }
   *             pagination: { first: 10 }
   *             sort: NAME_ASC
   *           ) {
   *             pageInfo {
   *               endCursor
   *               hasNextPage
   *             }
   *             items {
   *              id
   *              version
   *              caisId
   *              name { value metadata { visibility } }
   *              acronym { value metadata { visibility } }
   *              customName { value metadata { visibility } }
   *              structureWsd { value metadata { visibility } }
   *              subType { value metadata { visibility } }
   *              wrapperGroup { value metadata { visibility } }
   *              fdicInsured { value metadata { visibility } }
   *              hasCoupon { value metadata { visibility } }
   *              couponType { value metadata { visibility } }
   *              hasCouponBarrier { value metadata { visibility } }
   *              hasCouponMemory { value metadata { visibility } }
   *              callType { value metadata { visibility } }
   *              hasCallPremium { value metadata { visibility } }
   *              hasStepUpAutoCallHurdle { value metadata { visibility } }
   *              hasStepDownAutoCallHurdle { value metadata { visibility } }
   *              hasUpsideParticipation { value metadata { visibility } }
   *              hasMaximumReturn { value metadata { visibility } }
   *              hasDigitalReturn { value metadata { visibility } }
   *              hasUpsideAboveDigitalReturn { value metadata { visibility } }
   *              hasUpsideKnockout { value metadata { visibility } }
   *              hasAbsoluteReturn { value metadata { visibility } }
   *              hasGearedDownside { value metadata { visibility } }
   *              downsideProtectionType { value metadata { visibility } }
   *              hasBearish { value metadata { visibility } }
   *              hasRainbow { value metadata { visibility } }
   *              hasLookBack { value metadata { visibility } }
   *              hasRebate { value metadata { visibility } }
   *              hasHighWaterMark { value metadata { visibility } }
   *              hasFixedToFloatTerms { value metadata { visibility } }
   *              hasAdditionalYieldTerms { value metadata { visibility } }
   *             }
   *           }
   *         }
   *
   */
  structuredProductStructureTemplates: StructurePageResult
  /**
   *
   *         Find StructuredProducts based on filter, sort and pagination criteria.
   *
   *         The following example query gets the id and productType of all structuredProducts of a specific name. The first 50 items are returned:
   *         {
   *             structuredProducts(filter: {
   *                 identifier: { cusip: {eq: "someCusip"} }
   *             }, pagination: {
   *                 first: 50
   *             }) {
   *                 items {
   *                     identifiers {
   *                         value
   *                         type
   *                     }
   *                     productType
   *                 }
   *                 pageInfo {
   *                     endCursor
   *                     hasNextPage
   *                 }
   *             }
   *         }
   *
   *         Advanced query that includes multiple filtering and sorting:
   *         {
   *           structuredProducts(
   *             filter: {
   *               identifier: { cusip: { eq: "someCusip" } }
   *               structure: { subType: { eq: GROWTH } }
   *             }
   *             pagination: { first: 50 }
   *             sort: TRADE_DATE_DESC
   *           ) {
   *             items {
   *               identifiers {
   *                 value
   *                 type
   *               }
   *               tenor {
   *                 value {
   *                   final
   *                 }
   *               }
   *             }
   *             pageInfo {
   *               endCursor
   *               hasNextPage
   *             }
   *           }
   *         }
   *
   */
  structuredProducts: StructuredProductPageResult
  /** Retrieving an individual team by ID. */
  team: Team
  /** Retrieving a list of teams in a firm by firm ID. */
  teams: Array<Team>
  /** Retrieving a paginated list of teams in a firm by firm ID. */
  teamsPage: TeamsPageResult
  /** Retrieving a list of teams */
  teamsSearch: TeamsPageResult
  /** Get the external IDs of a transaction. */
  transactionExternalIds: ExternalIds
  /**
   *
   *         Gets prices for underliers of a structured product.
   *         {
   *             underlierPrices(
   *                 productId: "ee227f20-e247-428a-b6cc-004bb26838b7" # <-- SP product id
   *                 filter: {
   *                     valueTypes: ABSOLUTE
   *                     timeRange: { from: "2022-11-01T00:00:00Z", to: "2022-11-02T00:00:00Z" }
   *                 }
   *             ) {
   *                 <- an array of underliers each with id and its own array of prices
   *                 productId # <- underlier id
   *                 prices {
   *                     priceType
   *                     source
   *                     value
   *                     valueDate
   *                     valueType
   *                 }
   *             }
   *         }
   *
   */
  underlierPrices: Array<ProductPrices>
  /** Retrieving an individual user by ID */
  user: User
  /** Retrieve the profile of the currently logged-in user based on the Bearer JWT */
  userProfile: UserProfile
  /** Retrieving paginated and optionally filtered list of users for a parent entity (team or firm)  */
  users: UsersPageResult
}

export type QueryAccountArgs = {
  accountId: Scalars['ID']
  version?: InputMaybe<Scalars['Int']>
}

export type QueryAccountDocumentsArgs = {
  accountId: Scalars['ID']
}

export type QueryAdminFundProductsArgs = {
  filter?: InputMaybe<FundProductAdminFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sorting?: InputMaybe<Array<FundProductAdminSort>>
}

export type QueryContactArgs = {
  id: Scalars['ID']
  version?: InputMaybe<Scalars['Int']>
}

export type QueryContactAccountsArgs = {
  contactId: Scalars['ID']
}

export type QueryDocumentsArgs = {
  filter?: InputMaybe<DocumentFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<DocumentSort>>
}

export type QueryDocumentsDownloadArgs = {
  documentDownload: DocumentDownloadInput
}

export type QueryDownloadMercerServicesDocumentArgs = {
  type: MercerServicesDocumentType
}

export type QueryDraftStructuredProductUploadArgs = {
  id: Scalars['ID']
}

export type QueryDraftStructuredProductUploadPublishAllStatusArgs = {
  id: Scalars['ID']
  productUploadId: Scalars['ID']
}

export type QueryDraftStructuredProductUploadResultsArgs = {
  filter?: InputMaybe<DraftUploadResultFilterInput>
  id: Scalars['ID']
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<DraftUploadResultSort>>
}

export type QueryDraftStructuredProductUploadsArgs = {
  filter?: InputMaybe<DraftUploadFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<DraftUploadSort>>
}

export type QueryFilesArgs = {
  domain: FileDomain
  filter?: InputMaybe<FileFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<FileSort>>
}

export type QueryFirmArgs = {
  firmId: Scalars['ID']
}

export type QueryFirmNamesArgs = {
  parentFirmId?: InputMaybe<Scalars['ID']>
  searchText?: InputMaybe<Scalars['String']>
}

export type QueryFirmPermissionsArgs = {
  firmId: Scalars['ID']
}

export type QueryFirmRolesArgs = {
  firmId: Scalars['ID']
}

export type QueryFirmsArgs = {
  filter?: InputMaybe<FirmFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sorts?: InputMaybe<Array<FirmSort>>
}

export type QueryFundAggregationsArgs = {
  filter?: InputMaybe<FundFilterInput>
}

export type QueryFundAggregationsMultiFilterArgs = {
  filters: Array<FundFilterInput>
}

export type QueryFundProductArgs = {
  id: Scalars['ID']
}

export type QueryFundProductAdminArgs = {
  id: Scalars['ID']
}

export type QueryFundProductDocumentsArgs = {
  fundId: Scalars['UUID']
}

export type QueryFundProductSearchArgs = {
  filter?: InputMaybe<FundFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sorting?: InputMaybe<Array<FundSort>>
}

export type QueryFundReferencesArgs = {
  alias?: InputMaybe<Scalars['String']>
  fundAdminId?: InputMaybe<Scalars['ID']>
}

export type QueryGetProductOnboardingDocsArgs = {
  input: GetProductOnboardingDocsInput
}

export type QueryHolidaysArgs = {
  country?: InputMaybe<Scalars['String']>
  publicOnly?: InputMaybe<Scalars['Boolean']>
  years: Array<Scalars['Int']>
}

export type QueryHouseholdArgs = {
  id: Scalars['ID']
}

export type QueryHouseholdContactsArgs = {
  householdId: Scalars['ID']
}

export type QueryInvestmentExternalIdsArgs = {
  id: Scalars['ID']
}

export type QueryIssuerArgs = {
  id: Scalars['ID']
}

export type QueryIssuerGroupArgs = {
  id: Scalars['ID']
}

export type QueryIssuerGroupsArgs = {
  filter?: InputMaybe<IssuerGroupFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<IssuerGroupSort>>
}

export type QueryIssuersArgs = {
  filter?: InputMaybe<IssuerFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<IssuerSort>>
}

export type QueryPipelineRunsArgs = {
  filter?: InputMaybe<PipelineRunSearchFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<PipelineRunSort>>
}

export type QueryPositionExternalIdsArgs = {
  id: Scalars['ID']
}

export type QueryProductPermissionsArgs = {
  filter?: InputMaybe<ProductPermissionsFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<ProductPermissionsSort>>
}

export type QueryProductPricesArgs = {
  filter?: InputMaybe<ProductPricesFilterInput>
  productId: Scalars['ID']
}

export type QueryReferenceAssetArgs = {
  id: Scalars['ID']
}

export type QueryReferenceAssetsArgs = {
  filter?: InputMaybe<ReferenceAssetFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<ReferenceAssetSort>>
}

export type QuerySearchAccountsArgs = {
  filter?: InputMaybe<AccountFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sorts?: InputMaybe<Array<AccountSort>>
}

export type QuerySearchContactsArgs = {
  filter?: InputMaybe<ContactFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sorts?: InputMaybe<Array<ContactSort>>
}

export type QuerySearchDataPipelineArgs = {
  filter?: InputMaybe<DataPipelineFilterInput>
  pagination: PaginationInput
  sorting?: InputMaybe<Array<DataPipelineSort>>
}

export type QuerySearchFirmAccessPoliciesArgs = {
  filter: FundProductAccessPolicyFilterInput
  pagination?: InputMaybe<PaginationInput>
  sorting?: InputMaybe<Array<FundProductAccessPolicySort>>
}

export type QuerySearchFundProductFirmAccessPoliciesArgs = {
  filter: FundProductAccessPolicyFilterInput
  pagination?: InputMaybe<PaginationInput>
  sorting?: InputMaybe<Array<FundProductAccessPolicySort>>
}

export type QuerySearchHouseholdsArgs = {
  filter?: InputMaybe<HouseholdFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sorts?: InputMaybe<Array<HouseholdSort>>
}

export type QuerySearchPositionAccountingLotValuationsArgs = {
  filter?: InputMaybe<SearchPositionAccountingLotValuationsInput>
  pagination: PaginationInput
  sorting?: InputMaybe<Array<PositionAccountingLotValuationsSort>>
}

export type QuerySearchTransactionsArgs = {
  filter?: InputMaybe<SearchTransactionsInput>
  pagination: PaginationInput
  sorting?: InputMaybe<Array<TransactionsSort>>
}

export type QueryServiceIdentitiesArgs = {
  filter?: InputMaybe<ServiceIdentityFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sorts?: InputMaybe<Array<ServiceIdentitySort>>
}

export type QueryServiceIdentityArgs = {
  serviceIdentityId: Scalars['ID']
}

export type QueryStateArgs = {
  pipelineId: Scalars['String']
  runId: Scalars['String']
}

export type QueryStructuredProductArgs = {
  id: Scalars['ID']
}

export type QueryStructuredProductStructureTemplateArgs = {
  id: Scalars['ID']
}

export type QueryStructuredProductStructureTemplatesArgs = {
  filter?: InputMaybe<StructuredProductStructureTemplateFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<StructuredProductStructureTemplateSort>>
}

export type QueryStructuredProductsArgs = {
  filter?: InputMaybe<StructuredProductFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<StructuredProductSort>>
}

export type QueryTeamArgs = {
  teamId: Scalars['ID']
}

export type QueryTeamsArgs = {
  firmId: Scalars['ID']
}

export type QueryTeamsPageArgs = {
  filter?: InputMaybe<TeamFilterInput>
  firmId: Scalars['ID']
  pagination?: InputMaybe<PaginationInput>
  sorts?: InputMaybe<Array<TeamSort>>
}

export type QueryTeamsSearchArgs = {
  filter?: InputMaybe<TeamFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sorts?: InputMaybe<Array<TeamSort>>
}

export type QueryTransactionExternalIdsArgs = {
  id: Scalars['ID']
}

export type QueryUnderlierPricesArgs = {
  filter?: InputMaybe<ProductPricesFilterInput>
  structuredProductId: Scalars['ID']
}

export type QueryUserArgs = {
  userId: Scalars['ID']
}

export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilterInput>
  pagination?: InputMaybe<PaginationInput>
  parent?: InputMaybe<IamEntityIdentifierInput>
  sorts?: InputMaybe<Array<UserSort>>
}

export enum QueryStringMatchType {
  BestMatch = 'BEST_MATCH',
  PhrasePrefix = 'PHRASE_PREFIX',
}

export type RealEstateProduct = Entity &
  Node &
  Product & {
    __typename?: 'RealEstateProduct'
    caisId: Scalars['String']
    category?: Maybe<Scalars['String']>
    currencyCode: ProductStringField
    exchangeId?: Maybe<Scalars['String']>
    id: Scalars['ID']
    identifiers: Array<ProductIdentifier>
    name?: Maybe<Scalars['String']>
    price: Array<ProductPrice>
    productType: ProductType
    publishState: ProductPublishState
    ticker?: Maybe<Scalars['String']>
    version: Scalars['Int']
  }

export type Redemption = {
  __typename?: 'Redemption'
  holdbackAmount?: Maybe<Money>
  percentageRedeemed?: Maybe<Scalars['BigDecimal']>
}

export type ReferenceAsset = {
  __typename?: 'ReferenceAsset'
  caisId?: Maybe<Scalars['String']>
  category?: Maybe<Scalars['String']>
  createdAt: Scalars['IsoDateTime']
  createdBy: Scalars['String']
  currencyCode?: Maybe<ProductStringField>
  customName?: Maybe<Scalars['String']>
  deleted: Scalars['Boolean']
  exchangeId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  identifiers: Array<ProductIdentifier>
  latestCloseAsOf?: Maybe<Scalars['IsoDateTime']>
  latestCloseLevel?: Maybe<Scalars['BigDecimal']>
  name: Scalars['String']
  price: Array<ProductPrice>
  productType: ProductType
  publishState: ProductPublishState
  ric?: Maybe<Scalars['String']>
  ticker?: Maybe<Scalars['String']>
  updatedAt?: Maybe<Scalars['IsoDateTime']>
  updatedBy?: Maybe<Scalars['String']>
  version: Scalars['Int']
}

export type ReferenceAssetError = {
  message: Scalars['String']
}

export type ReferenceAssetFilterInput = {
  and?: InputMaybe<Array<ReferenceAssetFilterInput>>
  currencyCode?: InputMaybe<StringFilterInput>
  id?: InputMaybe<IdFilterInput>
  identifier?: InputMaybe<ProductIdentifierFilterInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<Array<ReferenceAssetFilterInput>>
  or?: InputMaybe<Array<ReferenceAssetFilterInput>>
  productType?: InputMaybe<ProductTypeFilterInput>
  ticker?: InputMaybe<StringFilterInput>
}

export enum ReferenceAssetSort {
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ProductTypeAsc = 'PRODUCT_TYPE_ASC',
  ProductTypeDesc = 'PRODUCT_TYPE_DESC',
  TickerAsc = 'TICKER_ASC',
  TickerDesc = 'TICKER_DESC',
}

export enum ReferenceAssetType {
  Alternative = 'ALTERNATIVE',
  Commodity = 'COMMODITY',
  Equity = 'EQUITY',
  ExchangeTradedFund = 'EXCHANGE_TRADED_FUND',
  Fx = 'FX',
  Index = 'INDEX',
  InterestRate = 'INTEREST_RATE',
}

export type ReportingProvider = {
  __typename?: 'ReportingProvider'
  key: Scalars['String']
  values: Array<Scalars['String']>
}

export type ReportingProviderDetails = {
  __typename?: 'ReportingProviderDetails'
  funds?: Maybe<Array<ReportingProviderFundDetails>>
  reportingProviderAccountNumber: Scalars['String']
  reportingProviderId: Scalars['UUID']
}

export type ReportingProviderDetailsInput = {
  funds?: InputMaybe<Array<ReportingProviderFundDetailsInput>>
  reportingProviderAccountNumber: Scalars['String']
  reportingProviderId: Scalars['UUID']
}

export type ReportingProviderFundDetails = {
  __typename?: 'ReportingProviderFundDetails'
  fundAccountNumber: Scalars['String']
  fundId: Scalars['UUID']
}

export type ReportingProviderFundDetailsInput = {
  fundAccountNumber: Scalars['String']
  fundId: Scalars['UUID']
}

export type ReportingProviderInput = {
  key: Scalars['String']
  values: Array<Scalars['String']>
}

export type ResendActivationPayload = {
  __typename?: 'ResendActivationPayload'
  id: Scalars['ID']
}

export type RestrictedEligibility = {
  __typename?: 'RestrictedEligibility'
  coveredPerson: Scalars['Boolean']
  restrictedPerson: Scalars['Boolean']
}

export type Risk = {
  __typename?: 'Risk'
  annualizedVolatility?: Maybe<Scalars['BigDecimal']>
  downsideDeviation?: Maybe<Scalars['BigDecimal']>
  maxDrawdown?: Maybe<Scalars['BigDecimal']>
  sharpeRatio?: Maybe<Scalars['BigDecimal']>
  sortinoRatio?: Maybe<Scalars['BigDecimal']>
}

export type RiskAndCorrelationAnnualPerformance = {
  __typename?: 'RiskAndCorrelationAnnualPerformance'
  value?: Maybe<Scalars['BigDecimal']>
  year?: Maybe<Scalars['Int']>
}

export type RiskAndCorrelationBenchmark = {
  __typename?: 'RiskAndCorrelationBenchmark'
  annualBenchmarkValues?: Maybe<Array<AnnualBenchmarkValues>>
  name?: Maybe<Scalars['String']>
}

export type Role = Node & {
  __typename?: 'Role'
  /** @deprecated use persona instead */
  archetype: UserType
  description?: Maybe<Scalars['String']>
  id: Scalars['ID']
  inheritable: Scalars['Boolean']
  isDefault?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  permissions: Array<Permission>
  persona: Scalars['String']
}

export type RoleIdPayload = {
  __typename?: 'RoleIdPayload'
  id?: Maybe<Scalars['ID']>
}

export type RoleInput = {
  archetype: UserType
  description?: InputMaybe<Scalars['String']>
  inheritable: Scalars['Boolean']
  name: Scalars['String']
  permissionIds: Array<Scalars['String']>
  persona?: InputMaybe<Scalars['String']>
}

export type SearchPositionAccountingLotValuationsInput = {
  accountId?: InputMaybe<IdFilterInput>
  batchId?: InputMaybe<IdFilterInput>
  batchVersion?: InputMaybe<IntegerFilterInput>
  dateTimeReceived?: InputMaybe<InstantFilterInput>
  firmId?: InputMaybe<IdFilterInput>
  householdId?: InputMaybe<IdFilterInput>
  navDate?: InputMaybe<IsoDateFilterInput>
  periodEndDate?: InputMaybe<IsoDateFilterInput>
  positionAccountingLotId?: InputMaybe<IdFilterInput>
  positionId?: InputMaybe<IdFilterInput>
  publicationStatus?: InputMaybe<PublicationStatusEnumFilterInput>
}

export type SearchPositionAccountingLotValuationsOutput = {
  __typename?: 'SearchPositionAccountingLotValuationsOutput'
  items: Array<PositionAccountingLotValuation>
  pageInfo?: Maybe<PageInfo>
}

export type SearchTransactionsInput = {
  accountId?: InputMaybe<IdFilterInput>
  batchId?: InputMaybe<IdFilterInput>
  batchVersion?: InputMaybe<IntegerFilterInput>
  dateTimeReceived?: InputMaybe<InstantFilterInput>
  fileName?: InputMaybe<StringFilterInput>
  firmId?: InputMaybe<IdFilterInput>
  fundId?: InputMaybe<IdFilterInput>
  householdId?: InputMaybe<IdFilterInput>
  initialSubscriptionOrCommitmentDate?: InputMaybe<IsoDateFilterInput>
  positionId?: InputMaybe<IdFilterInput>
  publicationStatus?: InputMaybe<PublicationStatusEnumFilterInput>
  stage?: InputMaybe<TransactionStageEnumFilterInput>
  transactionDate?: InputMaybe<IsoDateFilterInput>
  transactionSubType?: InputMaybe<TransactionSubTypeFilterInput>
  transactionType?: InputMaybe<TransactionTypeFilterInput>
}

export type SearchTransactionsOutput = {
  __typename?: 'SearchTransactionsOutput'
  items: Array<FlatTransaction>
  pageInfo?: Maybe<PageInfo>
}

export type SecurityTypeFilterInput = {
  /** Equals */
  eq?: InputMaybe<FundSecurityType>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<FundSecurityType>>
  /** Not Equals */
  ne?: InputMaybe<FundSecurityType>
}

export type ServiceIdentitiesPageResult = PageResult & {
  __typename?: 'ServiceIdentitiesPageResult'
  items: Array<ServiceIdentity>
  pageInfo: PageInfo
}

export type ServiceIdentity = {
  __typename?: 'ServiceIdentity'
  createDate: Scalars['IsoDateTime']
  deleteDate?: Maybe<Scalars['IsoDateTime']>
  email: Scalars['String']
  externalId?: Maybe<Scalars['String']>
  id: Scalars['ID']
  permissions: Array<Permission>
  serviceName: Scalars['String']
  updateDate?: Maybe<Scalars['IsoDateTime']>
}

export type ServiceIdentityFilterInput = {
  email?: InputMaybe<StringFilterInput>
  firmId?: InputMaybe<IdFilterInput>
  id?: InputMaybe<IdFilterInput>
  name?: InputMaybe<StringFilterInput>
}

export type ServiceIdentityIdPayload = {
  __typename?: 'ServiceIdentityIdPayload'
  id: Scalars['ID']
}

export type ServiceIdentityRotateSecretPayload = {
  __typename?: 'ServiceIdentityRotateSecretPayload'
  rotatedSecret: ServiceIdentityRotatedSecret
}

export type ServiceIdentityRotatedSecret = {
  __typename?: 'ServiceIdentityRotatedSecret'
  clientId: Scalars['String']
  clientSecret: Scalars['String']
}

export enum ServiceIdentitySort {
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export type Share = {
  __typename?: 'Share'
  navDate: Scalars['Date']
  navPerShare: Scalars['BigDecimal']
  number: Scalars['BigDecimal']
}

export type ShareBalance = {
  __typename?: 'ShareBalance'
  beginning: Money
  ending: Money
  opening: Money
}

/** A 'ShareClassAccessPolicy' represents the share class access policy with status and modifier */
export type ShareClassAccessPolicy = {
  __typename?: 'ShareClassAccessPolicy'
  modifier: FundProductShareClassAccessModifier
  shareClassId: Scalars['ID']
}

export type ShareClassAccessPolicyResponse = {
  __typename?: 'ShareClassAccessPolicyResponse'
  /** @deprecated use `hasDefaultAccess` */
  hasAccess?: Maybe<Scalars['Boolean']>
  hasDefaultAccess?: Maybe<Scalars['Boolean']>
  modifier?: Maybe<FundProductShareClassAccessModifier>
  shareClassId: Scalars['ID']
}

/** A 'ShareClassAccessPolicyV1' represents the permission configured at share class level */
export type ShareClassAccessPolicyV1 = {
  __typename?: 'ShareClassAccessPolicyV1'
  /** @deprecated use `hasDefaultAccess` */
  hasAccess?: Maybe<Scalars['Boolean']>
  hasDefaultAccess?: Maybe<Scalars['Boolean']>
  modifier?: Maybe<FundProductShareClassAccessModifier>
  shareClassId: Scalars['UUID']
}

/** A 'ShareClassIdAndName' represents the share class ID and name */
export type ShareClassIdAndName = {
  __typename?: 'ShareClassIdAndName'
  name?: Maybe<Scalars['String']>
  shareClassId: Scalars['ID']
}

/** A 'ShareClassPolicyStatusRequest' represents the request to update the policy statuses of a share class */
export type ShareClassPolicyStatusRequestInput = {
  shareClassId: Scalars['ID']
  status: FundProductShareClassPolicyStatus
}

export type Source = {
  __typename?: 'Source'
  fileName?: Maybe<Scalars['String']>
  id?: Maybe<Scalars['UUID']>
  sourceType: Scalars['String']
  version?: Maybe<Scalars['Int']>
}

/** Client account details */
export type SpoClientAccount = {
  __typename?: 'SpoClientAccount'
  /** Brokerage account number */
  brokerageAccountNumber?: Maybe<Scalars['String']>
  createdAt: Scalars['IsoDateTime']
  createdBy: Scalars['String']
  /** Custodian account number */
  custodianAccountNumber?: Maybe<Scalars['String']>
  deletedAt?: Maybe<Scalars['IsoDateTime']>
  deletedBy?: Maybe<Scalars['String']>
  derivedAccountNumber?: Maybe<Scalars['String']>
  derivedClientId?: Maybe<Scalars['String']>
  derivedClientName?: Maybe<Scalars['String']>
  /** Account fee structure, i.e. `TRANSACTIONAL` or `ADVISORY`, indicates brokerage or advisory account capacity */
  feeStructure?: Maybe<Scalars['String']>
  /** Teams that can access client household */
  householdAcls: SpoHouseholdAcls
  /** Client household's firm id */
  householdFirmId: Scalars['ID']
  /** Client household id */
  householdId: Scalars['ID']
  /** Client household name */
  householdName: Scalars['String']
  /** Account id */
  id: Scalars['ID']
  /** Joint client first name for individual joint account */
  jointClientFirstName?: Maybe<Scalars['String']>
  /** Joint client id for individual joint account */
  jointClientId?: Maybe<Scalars['ID']>
  /** Joint client last name for individual joint account */
  jointClientLastName?: Maybe<Scalars['String']>
  /** Joint client version for individual joint account */
  jointClientVersion?: Maybe<Scalars['Int']>
  /** Joint entity name for non-individual account */
  jointEntityName?: Maybe<Scalars['String']>
  /** Account name */
  name: Scalars['String']
  /** Primary client first name for individual single account */
  primaryClientFirstName?: Maybe<Scalars['String']>
  /** Primary client id for individual single/joint account */
  primaryClientId?: Maybe<Scalars['ID']>
  /** Primary client last name for individual single account */
  primaryClientLastName?: Maybe<Scalars['String']>
  /** Primary client version for individual single account */
  primaryClientVersion?: Maybe<Scalars['Int']>
  /** Entity name for non-individual account */
  primaryEntityName?: Maybe<Scalars['String']>
  /** Account name (category) */
  type: Scalars['String']
  updatedAt: Scalars['IsoDateTime']
  updatedBy: Scalars['String']
  version: Scalars['Int']
}

export type SpoCustodianDetails = {
  __typename?: 'SpoCustodianDetails'
  /** Custodian code, linked to custodian service */
  code?: Maybe<Scalars['String']>
  /** Custodian id, linked to custodian service */
  id?: Maybe<Scalars['ID']>
  /** Custodian long name */
  name: Scalars['String']
  /** Custodian enum code, local to SPO service */
  spoCustodianEnumCode?: Maybe<Scalars['String']>
}

/** Client account ACL details */
export type SpoHouseholdAcls = {
  __typename?: 'SpoHouseholdAcls'
  /** Client account's household id' */
  householdId: Scalars['ID']
  /** Teams that can access client household */
  teamIds: Array<Scalars['ID']>
}

export type StrategyAggregation = {
  __typename?: 'StrategyAggregation'
  count: Scalars['Int']
  subStrategies?: Maybe<Array<FundSubStrategyAggregation>>
  value: FundStrategy
}

export type StrategySubStrategyFilterInput = {
  eq?: InputMaybe<FundStrategySubStrategyInput>
  in?: InputMaybe<Array<FundStrategySubStrategyInput>>
  ne?: InputMaybe<FundStrategySubStrategyInput>
}

export type StringAggregation = {
  __typename?: 'StringAggregation'
  count: Scalars['Int']
  value: Scalars['String']
}

export type StringFilterInput = {
  /** Determines if operations are case sensitive or insensitive. Default to case sensitive. */
  caseSensitive?: InputMaybe<Scalars['Boolean']>
  /** Equals */
  eq?: InputMaybe<Scalars['String']>
  /** Filters based on whether a value exists or does not exist. */
  exists?: InputMaybe<Scalars['Boolean']>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<Scalars['String']>>
  /** Searches with wildcards. The % character is a zero-to-many wildcard. */
  like?: InputMaybe<Scalars['String']>
  /** Not Equals */
  ne?: InputMaybe<Scalars['String']>
  /** Not in the provided range of values */
  notIn?: InputMaybe<Array<Scalars['String']>>
}

export type Structure = {
  __typename?: 'Structure'
  structureName?: Maybe<Scalars['String']>
  structureTemplateId?: Maybe<Scalars['ID']>
}

export type StructurePageResult = {
  __typename?: 'StructurePageResult'
  items: Array<StructuredProductStructureTemplate>
  pageInfo: PageInfo
}

export type StructureProductUploadError = StructuredProductError & {
  __typename?: 'StructureProductUploadError'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export type StructuredProduct = Entity &
  Node &
  Product & {
    __typename?: 'StructuredProduct'
    basketName: ProductStringField
    basketType: StructuredProductBasketTypeField
    brokerageAdvisoryCapacity?: Maybe<BrokerageAdvisoryCapacity>
    caisId: Scalars['String']
    callable: StructuredProductCallable
    category?: Maybe<Scalars['String']>
    currencyCode: ProductStringField
    currentProductReturn?: Maybe<Scalars['BigDecimal']>
    denomination: ProductBigDecimalField
    distanceToCouponBarrier?: Maybe<Scalars['BigDecimal']>
    distanceToDownside?: Maybe<Scalars['BigDecimal']>
    distanceToMaximumGain?: Maybe<Scalars['BigDecimal']>
    downside: StructuredProductDownside
    errors: Array<StructuredProductDraftError>
    factSheetURL?: Maybe<ProductStringField>
    growth: StructuredProductGrowth
    id: Scalars['ID']
    identifiers: Array<ProductIdentifier>
    initiatingSalesperson?: Maybe<Scalars['ID']>
    investmentArea: ProductStringField
    isMarketplace?: Maybe<Scalars['Boolean']>
    issuePrice: ProductBigDecimalField
    issuer: ProductIssuerField
    keyDates: StructuredProductKeyDates
    maxNotional?: Maybe<Scalars['BigDecimal']>
    miscellaneous: StructuredProductMisc
    name?: Maybe<Scalars['String']>
    offeringType: StructuredProductOfferingTypeField
    price: Array<ProductPrice>
    pricingSupplementURL: ProductStringField
    productType: ProductType
    prospectusType: StructuredProductProspectusTypeField
    publishState: ProductPublishState
    registration: StructuredProductRegistrationTypeField
    settlementType: StructuredProductSettlementTypeField
    status: StructuredProductStatusField
    structure?: Maybe<StructuredProductStructure>
    suppressNotifications: Scalars['Boolean']
    tenor: ProductPricingComponentField
    tradeStatus?: Maybe<StructuredProductTradeStatusField>
    underlierData: StructuredProductUnderlierData
    underlierReturn?: Maybe<Scalars['BigDecimal']>
    version: Scalars['Int']
    yield: StructuredProductYield
  }

export type StructuredProductBaseFilterInput = {
  publishState?: InputMaybe<PublishStateFilterInput>
}

export enum StructuredProductBasketType {
  Basket = 'BASKET',
  BestOf = 'BEST_OF',
  Cliquet = 'CLIQUET',
  Custom = 'CUSTOM',
  Equal = 'EQUAL',
  LeastOf = 'LEAST_OF',
  None = 'NONE',
  Other = 'OTHER',
  RainbowBasket = 'RAINBOW_BASKET',
  Single = 'SINGLE',
}

export type StructuredProductBasketTypeField = {
  __typename?: 'StructuredProductBasketTypeField'
  metadata: ProductFieldMetadata
  value?: Maybe<StructuredProductBasketType>
}

/** Model to update basket type in StructuredProduct along with metadata. */
export type StructuredProductBasketTypeFieldInput = {
  metadata: StructuredProductMandatoryFieldMetadataInput
  value?: InputMaybe<StructuredProductBasketType>
}

/** Model to update optional BigDecimal type property of StructuredProduct along with metadata. */
export type StructuredProductBigDecimalFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<Scalars['BigDecimal']>
}

/** Model to update optional Boolean type property of StructuredProduct along with metadata. */
export type StructuredProductBooleanFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<Scalars['Boolean']>
}

export enum StructuredProductCallObservationOutcome {
  Called = 'CALLED',
  NotCalled = 'NOT_CALLED',
  Pending = 'PENDING',
}

export type StructuredProductCallSchedule = {
  __typename?: 'StructuredProductCallSchedule'
  barrierLevel?: Maybe<Scalars['BigDecimal']>
  observationDate?: Maybe<Scalars['Date']>
  outcome?: Maybe<StructuredProductCallObservationOutcome>
  paymentDate?: Maybe<Scalars['Date']>
  premium?: Maybe<Scalars['BigDecimal']>
}

/** Model to represent StructuredProduct call schedule field. */
export type StructuredProductCallScheduleInput = {
  barrierLevel?: InputMaybe<Scalars['BigDecimal']>
  observationDate?: InputMaybe<Scalars['Date']>
  outcome?: InputMaybe<StructuredProductCallObservationOutcome>
  paymentDate?: InputMaybe<Scalars['Date']>
  premium?: InputMaybe<Scalars['BigDecimal']>
}

export enum StructuredProductCallType {
  AutoCallable = 'AUTO_CALLABLE',
  AutoWithStep = 'AUTO_WITH_STEP',
  IssuerCallable = 'ISSUER_CALLABLE',
  IssuerWithStep = 'ISSUER_WITH_STEP',
  None = 'NONE',
}

export type StructuredProductCallTypeField = {
  __typename?: 'StructuredProductCallTypeField'
  metadata: ProductFieldMetadata
  value?: Maybe<StructuredProductCallType>
}

/** Model to update call type property of StructuredProduct along with metadata. */
export type StructuredProductCallTypeFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<StructuredProductCallType>
}

export type StructuredProductCallable = {
  __typename?: 'StructuredProductCallable'
  autoCallBarrier: ProductPricingComponentField
  callFirstDate: ProductLocalDateField
  callFrequency: ProductFrequencyField
  callHurdle: ProductPricingComponentField
  callPremium: ProductPricingComponentField
  callType: StructuredProductCallTypeField
  nextCallObservationDate: ProductLocalDateField
  nextCallPaymentDate: ProductLocalDateField
  nonCallPeriod: ProductBigDecimalField
  schedule: StructuredProductCallableScheduleField
}

/** Model to represent StructuredProduct callable call schedule field. */
export type StructuredProductCallableCallScheduleFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<Array<StructuredProductCallScheduleInput>>
}

/** Model to update properties of Callable in StructuredProduct along with metadata. */
export type StructuredProductCallableInput = {
  autoCallBarrier: StructuredProductPricingComponentFieldInput
  callFirstDate: StructuredProductLocalDateFieldInput
  callFrequency: StructuredProductFrequencyFieldInput
  callHurdle: StructuredProductPricingComponentFieldInput
  callPremium: StructuredProductPricingComponentFieldInput
  callSchedule?: InputMaybe<StructuredProductCallableCallScheduleFieldInput>
  callType: StructuredProductCallTypeFieldInput
  nonCallPeriod: StructuredProductBigDecimalFieldInput
}

export type StructuredProductCallableScheduleField = {
  __typename?: 'StructuredProductCallableScheduleField'
  metadata: ProductFieldMetadata
  value?: Maybe<Array<StructuredProductCallSchedule>>
}

export enum StructuredProductCouponOutcome {
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
}

export type StructuredProductCouponSchedule = {
  __typename?: 'StructuredProductCouponSchedule'
  barrierLevel?: Maybe<Scalars['BigDecimal']>
  observationDate?: Maybe<Scalars['Date']>
  outcome?: Maybe<StructuredProductCouponOutcome>
  periodRate?: Maybe<Scalars['BigDecimal']>
  settlementDate?: Maybe<Scalars['Date']>
}

export enum StructuredProductCouponType {
  Accrual = 'ACCRUAL',
  Contingent = 'CONTINGENT',
  Fixed = 'FIXED',
  Floating = 'FLOATING',
  None = 'NONE',
}

export type StructuredProductCouponTypeField = {
  __typename?: 'StructuredProductCouponTypeField'
  metadata: ProductFieldMetadata
  value?: Maybe<StructuredProductCouponType>
}

/** Model to update coupon type property of StructuredProduct along with metadata. */
export type StructuredProductCouponTypeFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<StructuredProductCouponType>
}

export type StructuredProductDownside = {
  __typename?: 'StructuredProductDownside'
  absoluteReturnBarrier: ProductPricingComponentField
  absoluteReturnParticipation: ProductPricingComponentField
  barrierType: ProductStringField
  bufferLevel: ProductPricingComponentField
  downsideGearing: ProductPricingComponentField
  downsideProtectionType: StructuredProductDownsideProtectionTypeField
  knockInBarrier: ProductPricingComponentField
  knockInBarrierEventType: ProductStringField
  knockInBarrierObservationType: ProductStringField
  observationFrequency: ProductStringField
  putStrikeLevel: ProductPricingComponentField
  threshold: ProductPricingComponentField
}

/** Model to update properties of DownSide in StructuredProduct along with metadata. */
export type StructuredProductDownsideInput = {
  absoluteReturnBarrier: StructuredProductPricingComponentFieldInput
  absoluteReturnParticipation: StructuredProductPricingComponentFieldInput
  barrierType: StructuredProductStringFieldInput
  bufferLevel: StructuredProductPricingComponentFieldInput
  downsideGearing: StructuredProductPricingComponentFieldInput
  downsideProtectionType: StructuredProductDownsideProtectionTypeFieldInput
  knockInBarrier: StructuredProductPricingComponentFieldInput
  knockInBarrierEventType: StructuredProductStringFieldInput
  knockInBarrierObservationType: StructuredProductStringFieldInput
  observationFrequency: StructuredProductStringFieldInput
  putStrikeLevel: StructuredProductPricingComponentFieldInput
  threshold: StructuredProductPricingComponentFieldInput
}

export type StructuredProductDownsideProtectionTypeField = {
  __typename?: 'StructuredProductDownsideProtectionTypeField'
  metadata: ProductFieldMetadata
  value?: Maybe<DownsideProtectionType>
}

/** Model to update downside protection type property of StructuredProduct along with metadata. */
export type StructuredProductDownsideProtectionTypeFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<DownsideProtectionType>
}

export type StructuredProductDraftError = {
  __typename?: 'StructuredProductDraftError'
  code: Scalars['String']
  description: Scalars['String']
  fieldName?: Maybe<Scalars['String']>
}

export type StructuredProductDraftPublishAll = {
  __typename?: 'StructuredProductDraftPublishAll'
  createdAt: Scalars['IsoDateTime']
  createdBy: Scalars['ID']
  finishedAt?: Maybe<Scalars['IsoDateTime']>
  id: Scalars['ID']
  productUploadId: Scalars['ID']
  status: StructuredProductUploadStatus
  updatedAt?: Maybe<Scalars['IsoDateTime']>
  version: Scalars['Int']
}

export type StructuredProductDraftPublishAllPayload = {
  __typename?: 'StructuredProductDraftPublishAllPayload'
  error?: Maybe<StructuredProductError>
  result?: Maybe<StructuredProductDraftPublishAll>
}

export type StructuredProductDraftUpload = {
  __typename?: 'StructuredProductDraftUpload'
  createdAt: Scalars['IsoDateTime']
  createdBy: Scalars['ID']
  createdByUser?: Maybe<UserLite>
  fileName: Scalars['String']
  finishedAt?: Maybe<Scalars['IsoDateTime']>
  id: Scalars['ID']
  status: StructuredProductUploadStatus
  updatedAt?: Maybe<Scalars['IsoDateTime']>
  version: Scalars['Int']
}

export type StructuredProductDraftUploadPage = PageResult & {
  __typename?: 'StructuredProductDraftUploadPage'
  items: Array<StructuredProductDraftUpload>
  pageInfo: PageInfo
}

export type StructuredProductDraftUploadPageResult = PageResult & {
  __typename?: 'StructuredProductDraftUploadPageResult'
  items: Array<StructuredProductDraftUploadResult>
  pageInfo: PageInfo
}

export type StructuredProductDraftUploadPayload = {
  __typename?: 'StructuredProductDraftUploadPayload'
  error?: Maybe<StructuredProductError>
  upload?: Maybe<StructuredProductDraftUpload>
}

export type StructuredProductDraftUploadResult = {
  __typename?: 'StructuredProductDraftUploadResult'
  /** Error code - Ex. STRUCTURED_TEMPLATE_MAPPING_NOT_FOUND */
  errorCode?: Maybe<StructuredProductErrorCode>
  id: Scalars['ID']
  /** Product Id from the CSV. CUSIP, ISIN or CAISID */
  productIdentifier: Scalars['String']
  productUploadId: Scalars['ID']
  /** Reason of the error */
  reason?: Maybe<Scalars['String']>
  /** Status of the upload result, SUCCESS or ERROR */
  status: StructuredProductUploadStatus
  /** Created product */
  structuredProduct?: Maybe<StructuredProduct>
  /** Version of the result */
  version: Scalars['Int']
}

export type StructuredProductError = {
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export enum StructuredProductErrorCode {
  ExceededBulkStructuredProductLimit = 'EXCEEDED_BULK_STRUCTURED_PRODUCT_LIMIT',
  IdentifierNotFound = 'IDENTIFIER_NOT_FOUND',
  InvalidMetaDataError = 'INVALID_META_DATA_ERROR',
  InvariantViolation = 'INVARIANT_VIOLATION',
  IssuerNotFound = 'ISSUER_NOT_FOUND',
  MandatoryDraftFieldValueIsNull = 'MANDATORY_DRAFT_FIELD_VALUE_IS_NULL',
  MandatoryFieldValueNotPopulated = 'MANDATORY_FIELD_VALUE_NOT_POPULATED',
  MandatoryWsdFieldValueIsNull = 'MANDATORY_WSD_FIELD_VALUE_IS_NULL',
  MultipleWsdRecordsForAnIdentifier = 'MULTIPLE_WSD_RECORDS_FOR_AN_IDENTIFIER',
  NoEnumMappingFound = 'NO_ENUM_MAPPING_FOUND',
  PricesError = 'PRICES_ERROR',
  ProductInDisabledState = 'PRODUCT_IN_DISABLED_STATE',
  ProductInDraftState = 'PRODUCT_IN_DRAFT_STATE',
  ProductInPublishedState = 'PRODUCT_IN_PUBLISHED_STATE',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  StaleVersionError = 'STALE_VERSION_ERROR',
  StructuredTemplateMappingNotFound = 'STRUCTURED_TEMPLATE_MAPPING_NOT_FOUND',
  StructureNotFound = 'STRUCTURE_NOT_FOUND',
  StructureProductNameAlreadyTaken = 'STRUCTURE_PRODUCT_NAME_ALREADY_TAKEN',
  StructureTemplateCannotBeResolvedError = 'STRUCTURE_TEMPLATE_CANNOT_BE_RESOLVED_ERROR',
  UnknownMappingError = 'UNKNOWN_MAPPING_ERROR',
  UnsupportedIdentifierType = 'UNSUPPORTED_IDENTIFIER_TYPE',
  ValidationFailure = 'VALIDATION_FAILURE',
  WsdParsingError = 'WSD_PARSING_ERROR',
}

export type StructuredProductExistsInDisabledState = StructuredProductError & {
  __typename?: 'StructuredProductExistsInDisabledState'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export type StructuredProductExistsInDraftState = StructuredProductError & {
  __typename?: 'StructuredProductExistsInDraftState'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export type StructuredProductExistsInPublishedState = StructuredProductError & {
  __typename?: 'StructuredProductExistsInPublishedState'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

/** Model to represent metadata input of StructuredProduct field. */
export type StructuredProductFieldMetadataInput = {
  applicability: ProductFieldApplicability
  source: ProductFieldSourceInput
  visibility: ProductFieldVisibility
}

/**
 * Allows filtering on StructuredProducts by a number predicates that can be seen below.
 *         An implicit AND is performed between each field.
 *
 *         StructuredProductFilterInput.base field allows filtering on StructuredProducts by publish state (PUBLISHED/DRAFT/DISABLED).
 *         If base is not specified, the default of { publishState : { eq : "PUBLISHED"} } is applied on the server side.
 *         The conditions built from "base" is connected with rest of the filters with "AND" operator
 *         Please note that specifying base filter conditions in "or"/"and/"not" nodes will not have any effect.
 *
 *         Examples:
 *         - Find published/draft products by isin or cusip.
 *
 *         query {
 *             structuredProducts (filter: {
 *               base : { publishState : {in : [DRAFT,PUBLISHED]}}
 *               or: [
 *                 {identifier : {cusip : {eq : "61773QNA3"}}},
 *                 {identifier : {isin : {eq : "61773QNA3"}}}
 *               ]
 *             }){
 *             ### Add the response fields here
 *           }
 *         }
 *
 *         - Find published products by isin or cusip (explicitly specifying the base filter)
 *
 *         query {
 *             structuredProducts (filter: {
 *               base : { publishState : {in : [PUBLISHED]}}
 *               or: [
 *                 { identifier : {cusip : {eq : "61773QNA3"}} },
 *                 { identifier : {isin : {eq : "61773QNA3"}} }
 *               ]
 *             }){
 *             ### Add the response fields here
 *           }
 *         }
 *
 *         - Find published products by isin or cusip (without specifying the base filter. When the base filter is not specified a default of base :
 *           { publishState : {in : [PUBLISHED]}} is applied )
 *
 *         query {
 *             structuredProducts (filter: {
 *               or: [
 *                 { identifier : {cusip : {eq : "61773QNA3"}} },
 *                 { identifier : {isin : {eq : "61773QNA3"}} }
 *               ]
 *             }){
 *             ### Add the response fields here
 *           }
 *         }
 *
 *
 */
export type StructuredProductFilterInput = {
  and?: InputMaybe<Array<StructuredProductFilterInput>>
  base?: InputMaybe<StructuredProductBaseFilterInput>
  caisId?: InputMaybe<StringFilterInput>
  id?: InputMaybe<IdFilterInput>
  identifier?: InputMaybe<ProductIdentifierFilterInput>
  not?: InputMaybe<Array<StructuredProductFilterInput>>
  onlyPurchasable?: InputMaybe<Scalars['Boolean']>
  or?: InputMaybe<Array<StructuredProductFilterInput>>
  status?: InputMaybe<ProductStatusFilterInput>
  structureName?: InputMaybe<StringFilterInput>
  tradeStatus?: InputMaybe<ProductTradeStatusFilterInput>
}

export type StructuredProductFirmLite = {
  __typename?: 'StructuredProductFirmLite'
  id: Scalars['ID']
  name: Scalars['String']
}

/** Model to update frequency type property of StructuredProduct along with metadata. */
export type StructuredProductFrequencyFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<Frequency>
}

export type StructuredProductGrowth = {
  __typename?: 'StructuredProductGrowth'
  cap: ProductPricingComponentField
  digitalReturn: ProductPricingComponentField
  digitalReturnBarrier: ProductPricingComponentField
  knockOutBarrier: ProductPricingComponentField
  knockOutBarrierEventObservationType: ProductStringField
  knockOutBarrierEventType: ProductStringField
  knockOutResult: ProductStringField
  lowerCallStrike: ProductPricingComponentField
  maximumReturn: ProductPricingComponentField
  multipleFinalValuationDates?: Maybe<StructuredProductMultipleFinalValuationDates>
  rebate: ProductPricingComponentField
  uncapped: ProductBooleanField
  upperCallStrike: ProductPricingComponentField
  upsideParticipation: ProductPricingComponentField
  upsideParticipationAboveDigital: ProductPricingComponentField
}

/** Model to update properties of growth in StructuredProduct along with metadata. */
export type StructuredProductGrowthInput = {
  cap: StructuredProductPricingComponentFieldInput
  digitalReturn: StructuredProductPricingComponentFieldInput
  digitalReturnBarrier: StructuredProductPricingComponentFieldInput
  knockOutBarrier: StructuredProductPricingComponentFieldInput
  knockOutBarrierEventObservationType: StructuredProductStringFieldInput
  knockOutBarrierEventType: StructuredProductStringFieldInput
  knockOutResult: StructuredProductStringFieldInput
  lowerCallStrike: StructuredProductPricingComponentFieldInput
  maximumReturn: StructuredProductPricingComponentFieldInput
  multipleFinalValuationDates?: InputMaybe<StructuredProductMultipleFinalValuationDatesFieldInput>
  rebate?: InputMaybe<StructuredProductPricingComponentFieldInput>
  upperCallStrike: StructuredProductPricingComponentFieldInput
  upsideParticipation: StructuredProductPricingComponentFieldInput
  upsideParticipationAboveDigital: StructuredProductPricingComponentFieldInput
}

/**  Model to update values of StructuredProduct fields along with metadata where applicable.  */
export type StructuredProductInput = {
  basketName: StructuredProductStringFieldInput
  basketType: StructuredProductBasketTypeFieldInput
  brokerageAdvisoryCapacity?: InputMaybe<BrokerageAdvisoryCapacity>
  callable: StructuredProductCallableInput
  cins: StructuredProductStringFieldInput
  currencyCode: StructuredProductMandatoryStringFieldInput
  cusip: StructuredProductStringFieldInput
  denomination: StructuredProductMandatoryBigDecimalFieldInput
  downside: StructuredProductDownsideInput
  factSheetURL?: InputMaybe<StructuredProductStringFieldInput>
  growth: StructuredProductGrowthInput
  initiatingSalesperson?: InputMaybe<Scalars['ID']>
  investmentArea: StructuredProductStringFieldInput
  isMarketplace?: InputMaybe<Scalars['Boolean']>
  isin: StructuredProductStringFieldInput
  issuePrice: StructuredProductBigDecimalFieldInput
  issuer: StructuredProductIssuerInput
  keyDates: StructuredProductKeyDatesInput
  maxNotional?: InputMaybe<Scalars['BigDecimal']>
  miscellaneous: StructuredProductMiscInput
  offeringType: StructuredProductOfferingTypeFieldInput
  pricingSupplementURL: StructuredProductStringFieldInput
  prospectusType: StructuredProductProspectusTypeFieldInput
  publishState: ProductPublishState
  registrationType: StructuredProductRegistrationTypeFieldInput
  secondaryPrice: StructuredProductMandatoryBigDecimalFieldInput
  settlementType: StructuredProductSettlementTypeInput
  status: StructuredProductStatusFieldInput
  structure: StructuredProductStructureInput
  suppressNotifications?: InputMaybe<Scalars['Boolean']>
  tenor: StructuredProductPricingComponentFieldInput
  tradeStatus?: InputMaybe<StructuredProductTradeStatusFieldInput>
  underlierData: StructuredProductUnderlierInput
  yield: StructuredProductYieldInput
}

/** Model which support updating of Instant type fields of StructuredProduct along with metadata. */
export type StructuredProductInstantFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<Scalars['IsoDateTime']>
}

export type StructuredProductInvalidMetaDataError = StructuredProductError & {
  __typename?: 'StructuredProductInvalidMetaDataError'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export type StructuredProductInvariantViolationError =
  StructuredProductError & {
    __typename?: 'StructuredProductInvariantViolationError'
    errorAttributes: Scalars['JSON']
    message: Scalars['String']
  }

/** Model to update issuerId in StructuredProduct along with metadata. */
export type StructuredProductIssuerInput = {
  metadata: StructuredProductMandatoryFieldMetadataInput
  value?: InputMaybe<Scalars['ID']>
}

export type StructuredProductIssuerNotFoundError = StructuredProductError & {
  __typename?: 'StructuredProductIssuerNotFoundError'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export type StructuredProductKeyDates = {
  __typename?: 'StructuredProductKeyDates'
  averageIn: Array<Scalars['Date']>
  averageOut: Array<Scalars['Date']>
  called: ProductLocalDateField
  closing: ProductInstantField
  expired: ProductLocalDateField
  filing: ProductLocalDateField
  finalObservation: ProductLocalDateField
  issuance: ProductLocalDateField
  maturity: ProductLocalDateField
  strike: ProductLocalDateField
  trade: ProductLocalDateField
}

/** Model to update key dates of StructuredProduct along with metadata. */
export type StructuredProductKeyDatesInput = {
  averageIn: Array<Scalars['Date']>
  averageOut: Array<Scalars['Date']>
  called: StructuredProductLocalDateFieldInput
  closing: StructuredProductInstantFieldInput
  expired: StructuredProductLocalDateFieldInput
  filing: StructuredProductLocalDateFieldInput
  finalObservation: StructuredProductLocalDateFieldInput
  issuance: StructuredProductLocalDateFieldInput
  maturity: StructuredProductLocalDateFieldInput
  strike: StructuredProductLocalDateFieldInput
  trade: StructuredProductLocalDateFieldInput
}

/** Model which support updating of LocalDate type fields of StructuredProduct along with metadata. */
export type StructuredProductLocalDateFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<Scalars['Date']>
}

/**
 *
 *     Model to update mandatory BigDecimal type property of StructuredProduct along with metadata.
 *     This input type doesn't allow changing applicability metaData
 *
 */
export type StructuredProductMandatoryBigDecimalFieldInput = {
  metadata: StructuredProductMandatoryFieldMetadataInput
  value?: InputMaybe<Scalars['BigDecimal']>
}

/**
 *
 *     Model to update mandatory Boolean type property of StructuredProduct along with metadata.
 *     This input type doesn't allow changing applicability metaData
 *
 */
export type StructuredProductMandatoryBooleanFieldInput = {
  metadata: StructuredProductMandatoryFieldMetadataInput
  value?: InputMaybe<Scalars['Boolean']>
}

export type StructuredProductMandatoryFieldMetadataInput = {
  source: ProductFieldSourceInput
  visibility: ProductFieldVisibility
}

export type StructuredProductMandatoryFieldValueError =
  StructuredProductError & {
    __typename?: 'StructuredProductMandatoryFieldValueError'
    errorAttributes: Scalars['JSON']
    message: Scalars['String']
  }

/**
 *
 *         Model to update mandatory String type property of StructuredProduct along with metadata.
 *         This input type doesn't allow changing applicability metaData
 *
 */
export type StructuredProductMandatoryStringFieldInput = {
  metadata: StructuredProductMandatoryFieldMetadataInput
  value?: InputMaybe<Scalars['String']>
}

export type StructuredProductMisc = {
  __typename?: 'StructuredProductMisc'
  estimatedValue: ProductPricingComponentField
  feeCAIS: ProductBigDecimalField
  feeCustodian: ProductBigDecimalField
  feeOther: ProductBigDecimalField
  feeSalesConcession: ProductPricingComponentField
  finalRedemptionPerformance: ProductBigDecimalField
  notionalCAIS: ProductBigDecimalField
  notionalOffering: ProductBigDecimalField
  proceedsToIssuer: ProductPricingComponentField
  redemptionAsOf?: Maybe<Scalars['Date']>
  redemptionPrice?: Maybe<Scalars['BigDecimal']>
  staticReturn: ProductBigDecimalField
  staticReturnAsOf?: Maybe<Scalars['Date']>
  staticReturnPrice?: Maybe<Scalars['BigDecimal']>
}

/** Model to update properties of miscellaneous in StructuredProduct along with metadata. */
export type StructuredProductMiscInput = {
  estimatedValue: StructuredProductPricingComponentFieldInput
  feeCAIS: StructuredProductBigDecimalFieldInput
  feeCustodian: StructuredProductBigDecimalFieldInput
  feeOther: StructuredProductBigDecimalFieldInput
  feeSalesConcession: StructuredProductPricingComponentFieldInput
  finalRedemptionPerformance?: InputMaybe<StructuredProductBigDecimalFieldInput>
  notionalCAIS: StructuredProductBigDecimalFieldInput
  notionalOffering: StructuredProductBigDecimalFieldInput
  proceedsToIssuer: StructuredProductPricingComponentFieldInput
  staticReturn?: InputMaybe<StructuredProductBigDecimalFieldInput>
}

export type StructuredProductMultipleFinalValuationDates = {
  __typename?: 'StructuredProductMultipleFinalValuationDates'
  firstDate: ProductLocalDateField
  interval: ProductFrequencyField
  lastDate: ProductLocalDateField
  style: StructuredProductMultipleFinalValuationDatesStyleField
}

/** Model to update multiple final valuation dates in StructuredProduct */
export type StructuredProductMultipleFinalValuationDatesFieldInput = {
  firstDate?: InputMaybe<StructuredProductLocalDateFieldInput>
  interval?: InputMaybe<StructuredProductFrequencyFieldInput>
  lastDate?: InputMaybe<StructuredProductLocalDateFieldInput>
  style?: InputMaybe<StructuredProductMultipleFinalValuationDatesStyleFieldInput>
}

export type StructuredProductMultipleFinalValuationDatesStyleField = {
  __typename?: 'StructuredProductMultipleFinalValuationDatesStyleField'
  metadata: ProductFieldMetadata
  value?: Maybe<MultipleFinalValuationDatesStyle>
}

/** Model to update multiple final valuation dates style property of StructuredProduct along with metadata. */
export type StructuredProductMultipleFinalValuationDatesStyleFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<MultipleFinalValuationDatesStyle>
}

export type StructuredProductNameAlreadyTaken = StructuredProductError & {
  __typename?: 'StructuredProductNameAlreadyTaken'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export type StructuredProductNoEnumMappingFoundError =
  StructuredProductError & {
    __typename?: 'StructuredProductNoEnumMappingFoundError'
    errorAttributes: Scalars['JSON']
    message: Scalars['String']
  }

export type StructuredProductNoIdentifierFoundError = StructuredProductError & {
  __typename?: 'StructuredProductNoIdentifierFoundError'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export type StructuredProductNotFoundError = StructuredProductError & {
  __typename?: 'StructuredProductNotFoundError'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export enum StructuredProductOfferingType {
  Calendar = 'CALENDAR',
  Custom = 'CUSTOM',
}

export type StructuredProductOfferingTypeField = {
  __typename?: 'StructuredProductOfferingTypeField'
  metadata: ProductFieldMetadata
  value?: Maybe<StructuredProductOfferingType>
}

/** Model to update offering type in StructuredProduct along with metadata. */
export type StructuredProductOfferingTypeFieldInput = {
  metadata: StructuredProductMandatoryFieldMetadataInput
  value?: InputMaybe<StructuredProductOfferingType>
}

export type StructuredProductOrder = {
  __typename?: 'StructuredProductOrder'
  /** Currently only populated on order search */
  advisor?: Maybe<UserLite>
  advisorId?: Maybe<Scalars['ID']>
  allocations: Array<StructuredProductOrderAllocation>
  caisId: Scalars['ID']
  contactId: Scalars['ID']
  createdAt: Scalars['IsoDateTime']
  createdBy: Scalars['ID']
  /** Represents the person who created the order. Currently only populated on order search */
  creator?: Maybe<UserLite>
  /** Currently only populated on order search */
  firm?: Maybe<StructuredProductFirmLite>
  firmId: Scalars['ID']
  holderType: Scalars['String']
  id: Scalars['ID']
  /** Order creator type. Values SALES, ADVISOR. Shows what user created the order. */
  orderCreatorType: Scalars['String']
  placeholderProductId?: Maybe<Scalars['ID']>
  productSource: Scalars['String']
  /** Represents the person who sold the structured product. Often the same as the createdBy, but not always */
  salespersonId?: Maybe<Scalars['ID']>
  status: Scalars['String']
  structuredProductId?: Maybe<Scalars['ID']>
  /** Currently only populated on order search */
  team?: Maybe<StructuredProductTeamLite>
  teamId?: Maybe<Scalars['ID']>
  totalNotional: Scalars['BigDecimal']
  /** Trading away means executing a trade through a broker-dealer other than a custodian's affiliated broker-dealer */
  tradeAway?: Maybe<Scalars['Boolean']>
  updatedAt: Scalars['IsoDateTime']
  updatedBy: Scalars['String']
  version: Scalars['Int']
}

export type StructuredProductOrderAllocation = {
  __typename?: 'StructuredProductOrderAllocation'
  caisFee?: Maybe<Scalars['BigDecimal']>
  /** Client account details */
  clientAccount?: Maybe<SpoClientAccount>
  createdAt: Scalars['IsoDateTime']
  createdBy: Scalars['ID']
  /** Custodian details */
  custodianDetails: SpoCustodianDetails
  custodianFee?: Maybe<Scalars['BigDecimal']>
  id: Scalars['ID']
  notional: Scalars['BigDecimal']
  orderId: Scalars['ID']
  solicited?: Maybe<Scalars['Boolean']>
  updatedAt: Scalars['IsoDateTime']
  updatedBy: Scalars['String']
  version: Scalars['Int']
}

export type StructuredProductOrderAllocationForAdvisor = {
  __typename?: 'StructuredProductOrderAllocationForAdvisor'
  /** Client account details */
  clientAccount?: Maybe<SpoClientAccount>
  createdAt: Scalars['IsoDateTime']
  createdBy: Scalars['ID']
  /** Custodian details */
  custodianDetails: SpoCustodianDetails
  id: Scalars['ID']
  notional: Scalars['BigDecimal']
  orderId: Scalars['ID']
  solicited?: Maybe<Scalars['Boolean']>
  updatedAt: Scalars['IsoDateTime']
  updatedBy: Scalars['String']
  version: Scalars['Int']
}

export type StructuredProductOrderForAdvisor = {
  __typename?: 'StructuredProductOrderForAdvisor'
  /** Currently only populated on order search */
  advisor?: Maybe<UserLite>
  advisorId?: Maybe<Scalars['ID']>
  allocations: Array<StructuredProductOrderAllocationForAdvisor>
  caisId: Scalars['ID']
  contactId: Scalars['ID']
  createdAt: Scalars['IsoDateTime']
  createdBy: Scalars['ID']
  /** Currently only populated on order search */
  firm?: Maybe<StructuredProductFirmLite>
  firmId: Scalars['ID']
  holderType: Scalars['String']
  id: Scalars['ID']
  /** Order creator type. Values SALES, ADVISOR. Shows what user created the order. */
  orderCreatorType: Scalars['String']
  /** Represents the person who sold the structured product. Often the same as the createdBy, but not always */
  productSource: Scalars['String']
  status: Scalars['String']
  /** Only published products are ever returned from endpoints that use this type */
  structuredProductId: Scalars['ID']
  /** Currently only populated on order search */
  team?: Maybe<StructuredProductTeamLite>
  teamId?: Maybe<Scalars['ID']>
  totalNotional: Scalars['BigDecimal']
  /** Trading away means executing a trade through a broker-dealer other than a custodian's affiliated broker-dealer */
  tradeAway?: Maybe<Scalars['Boolean']>
  updatedAt: Scalars['IsoDateTime']
  updatedBy: Scalars['String']
  version: Scalars['Int']
}

export type StructuredProductOrderProductIdentifier = {
  __typename?: 'StructuredProductOrderProductIdentifier'
  type: StructuredProductOrderProductIdentifierType
  value: Scalars['String']
}

export enum StructuredProductOrderProductIdentifierType {
  Cusip = 'CUSIP',
  Isin = 'ISIN',
  Placeholder = 'PLACEHOLDER',
}

export type StructuredProductOrdersPageResult = PageResult & {
  __typename?: 'StructuredProductOrdersPageResult'
  items: Array<ExpandedStructuredProductOrder>
  pageInfo: PageInfo
}

export type StructuredProductOrdersPageResultForAdvisor = PageResult & {
  __typename?: 'StructuredProductOrdersPageResultForAdvisor'
  items: Array<ExpandedStructuredProductOrderForAdvisor>
  pageInfo: PageInfo
}

export type StructuredProductPageResult = PageResult & {
  __typename?: 'StructuredProductPageResult'
  items: Array<StructuredProduct>
  pageInfo: PageInfo
}

export type StructuredProductPosition = {
  __typename?: 'StructuredProductPosition'
  /** Resolves to IAM if requested - do not request it if not needed. Currently only returns on positions search, if you need it elsewhere request it */
  advisor?: Maybe<UserLite>
  advisorId?: Maybe<Scalars['ID']>
  /** The aggregation type of position */
  aggregationType?: Maybe<Scalars['String']>
  /** Position client account details */
  clientAccount?: Maybe<PositionClientAccount>
  /** Position custodian details */
  custodianDetails?: Maybe<PositionCustodianDetails>
  /** The per unit value at issuance. To get the currency associated find it in the related product */
  denomination: Scalars['BigDecimal']
  /** @deprecated , replace with finalRedemptionValue */
  finalRedemptionPerformance?: Maybe<Scalars['BigDecimal']>
  finalRedemptionValue?: Maybe<Scalars['BigDecimal']>
  /** Resolves to IAM if requested - do not request it if not needed. Currently only returns on positions search, if you need it elsewhere request it */
  firm?: Maybe<StructuredProductFirmLite>
  firmId?: Maybe<Scalars['ID']>
  holderType: Scalars['String']
  id: Scalars['ID']
  /** The value of the position based on the most recent price. Optional as we may not hold a price for the related product */
  marketValue?: Maybe<Scalars['BigDecimal']>
  /** This is quantity * denomination */
  notional: Scalars['BigDecimal']
  /** Originating order allocation id */
  orderAllocationId?: Maybe<Scalars['ID']>
  /** Originating order id */
  orderId?: Maybe<Scalars['ID']>
  purchaseDate?: Maybe<Scalars['Date']>
  /** Number of units held for a given position */
  quantity: Scalars['BigDecimal']
  /** @deprecated , replace with staticReturnValue */
  staticReturn?: Maybe<Scalars['BigDecimal']>
  staticReturnValue?: Maybe<Scalars['BigDecimal']>
  structuredProduct?: Maybe<StructuredProduct>
  /** Resolves to IAM if requested - do not request it if not needed. Currently only returns on positions search, if you need it elsewhere request it */
  team?: Maybe<StructuredProductTeamLite>
  teamId?: Maybe<Scalars['ID']>
  /** This is product.yield.totalCouponValue * quantity */
  totalValueOfCouponsPaid?: Maybe<Scalars['BigDecimal']>
  version: Scalars['Int']
}

export type StructuredProductPriceError = StructuredProductError & {
  __typename?: 'StructuredProductPriceError'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

/** Model to update pricing component of StructuredProduct along with metadata. */
export type StructuredProductPricingComponentFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<PricingComponentInput>
}

export enum StructuredProductProspectusType {
  Final = 'FINAL',
  Other = 'OTHER',
  Preliminary = 'PRELIMINARY',
}

export type StructuredProductProspectusTypeField = {
  __typename?: 'StructuredProductProspectusTypeField'
  metadata: ProductFieldMetadata
  value?: Maybe<StructuredProductProspectusType>
}

/** Model to update prospectus type property of StructuredProduct along with metadata. */
export type StructuredProductProspectusTypeFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<StructuredProductProspectusType>
}

export enum StructuredProductRegistrationType {
  CertificateOfDeposit = 'CERTIFICATE_OF_DEPOSIT',
  Registered = 'REGISTERED',
  RegS = 'REG_S',
  ThreeATwo = 'THREE_A_TWO',
  Warrant = 'WARRANT',
}

export type StructuredProductRegistrationTypeField = {
  __typename?: 'StructuredProductRegistrationTypeField'
  metadata: ProductFieldMetadata
  value?: Maybe<StructuredProductRegistrationType>
}

/** Model to update registration type of StructuredProduct along with metadata. */
export type StructuredProductRegistrationTypeFieldInput = {
  metadata: StructuredProductMandatoryFieldMetadataInput
  value?: InputMaybe<StructuredProductRegistrationType>
}

export enum StructuredProductSettlementType {
  Cash = 'CASH',
  Other = 'OTHER',
  Physical = 'PHYSICAL',
}

export type StructuredProductSettlementTypeField = {
  __typename?: 'StructuredProductSettlementTypeField'
  metadata: ProductFieldMetadata
  value?: Maybe<StructuredProductSettlementType>
}

/** Model to update settlement type of StructuredProduct along with metadata. */
export type StructuredProductSettlementTypeInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<StructuredProductSettlementType>
}

export enum StructuredProductSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  TradeDateAsc = 'TRADE_DATE_ASC',
  TradeDateDesc = 'TRADE_DATE_DESC',
}

export type StructuredProductStaleVersionError = StructuredProductError & {
  __typename?: 'StructuredProductStaleVersionError'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export enum StructuredProductStatus {
  Active = 'ACTIVE',
  Called = 'CALLED',
  Matured = 'MATURED',
  Offering = 'OFFERING',
  WillNotTrade = 'WILL_NOT_TRADE',
}

export type StructuredProductStatusField = {
  __typename?: 'StructuredProductStatusField'
  metadata: ProductFieldMetadata
  value?: Maybe<StructuredProductStatus>
}

/** Model to update status of StructuredProduct status along with metadata. */
export type StructuredProductStatusFieldInput = {
  metadata: StructuredProductMandatoryFieldMetadataInput
  value?: InputMaybe<StructuredProductStatus>
}

/** Model to update optional String type property of StructuredProduct along with metadata. */
export type StructuredProductStringFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<Scalars['String']>
}

/**
 *
 *         This is the structure that lives at the StructuredProduct-level, it is initially created from the `StructuredProductStructureTemplate` entity.
 *         Fields can be overridden here, where they take precedent over the StructureProductStructureEntity entity. If they are not overridden,
 *         then this information is always sourced from the StructuredProductStructuredTemplate entity.
 *
 *         You can identify if a field has been overridden by looking at the `source` in the metadata for each field.
 *
 */
export type StructuredProductStructure = {
  __typename?: 'StructuredProductStructure'
  acronym: ProductStringField
  customName: ProductStringField
  fdicInsured: ProductBooleanField
  name: ProductStringField
  /** This is the link to the StructuredProductStructureTemplate that this StructuredProductStructure was originally created from. */
  structureTemplateId?: Maybe<Scalars['ID']>
  subType: StructuredProductSubTypeField
  wrapperGroup: StructuredProductWrapperGroupField
}

export type StructuredProductStructureInput = {
  acronym: StructuredProductStringFieldInput
  customName: StructuredProductStringFieldInput
  fdicInsured: StructuredProductMandatoryBooleanFieldInput
  name: StructuredProductMandatoryStringFieldInput
  /** This is the link to the StructuredProductStructureTemplate that this StructuredProductStructure was originally created from. */
  structureTemplateId?: InputMaybe<Scalars['ID']>
  subType: StructuredProductSubTypeFieldInput
  wrapperGroup: StructuredProductWrapperGroupFieldInput
}

export type StructuredProductStructureNotFoundError = StructuredProductError & {
  __typename?: 'StructuredProductStructureNotFoundError'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

/**
 *
 *         This is an template structure which has it's own CaisId. This is set up manually by Cais Employees and is a prototype
 *         that can be applied to a StructuredProduct.
 *
 */
export type StructuredProductStructureTemplate = {
  __typename?: 'StructuredProductStructureTemplate'
  acronym: StructuredProductStructureTemplateStructuredStringField
  caisId: Scalars['String']
  callType: StructuredProductStructureTemplateCallTypeField
  couponType: StructuredProductStructureTemplateCouponTypeField
  customName: StructuredProductStructureTemplateStructuredStringField
  downsideProtectionType: StructuredProductStructureTemplateDownsideProtectionTypeField
  fdicInsured: StructuredProductStructureTemplateMandatoryBooleanField
  hasAbsoluteReturn: StructuredProductStructureTemplateBooleanField
  hasAdditionalYieldTerms: StructuredProductStructureTemplateBooleanField
  hasBearish: StructuredProductStructureTemplateBooleanField
  hasCallPremium: StructuredProductStructureTemplateBooleanField
  hasCoupon: StructuredProductStructureTemplateBooleanField
  hasCouponBarrier: StructuredProductStructureTemplateBooleanField
  hasCouponMemory: StructuredProductStructureTemplateBooleanField
  hasDigitalReturn: StructuredProductStructureTemplateBooleanField
  hasFixedToFloatTerms: StructuredProductStructureTemplateBooleanField
  hasGearedDownside: StructuredProductStructureTemplateBooleanField
  hasLookBack: StructuredProductStructureTemplateBooleanField
  hasMaximumReturn: StructuredProductStructureTemplateBooleanField
  hasRainbow: StructuredProductStructureTemplateBooleanField
  hasRebate: StructuredProductStructureTemplateBooleanField
  hasStepDownAutoCallHurdle: StructuredProductStructureTemplateBooleanField
  hasStepUpAutoCallHurdle: StructuredProductStructureTemplateBooleanField
  hasUpsideAboveDigitalReturn: StructuredProductStructureTemplateBooleanField
  hasUpsideKnockout: StructuredProductStructureTemplateBooleanField
  hasUpsideParticipation: StructuredProductStructureTemplateBooleanField
  id: Scalars['ID']
  multipleFinalValuationDatesStyle: StructuredProductStructureTemplateMultipleFinalValuationDateStylesField
  name: StructuredProductStructureTemplateMandatoryStringField
  /** This represents the Wall Street Docs Structure Name */
  structureWsd: StructuredProductStructureTemplateStructuredStringField
  subType: StructuredProductStructureTemplateStructuredProductSubTypeField
  version: Scalars['Int']
  wrapperGroup: StructuredProductStructureTemplateWrapperGroupField
}

export type StructuredProductStructureTemplateAlreadyTaken =
  StructuredProductStructureTemplateError & {
    __typename?: 'StructuredProductStructureTemplateAlreadyTaken'
    message: Scalars['String']
  }

/**
 *
 *         Supports Optional Boolean type fields on StructuredProductStructureTemplate.
 *
 */
export type StructuredProductStructureTemplateBooleanField = {
  __typename?: 'StructuredProductStructureTemplateBooleanField'
  metadata: StructuredProductStructureTemplateFieldMetadata
  value?: Maybe<Scalars['Boolean']>
}

/**
 *
 *         Supports Optional Boolean type fields on StructuredProductStructureTemplate.
 *
 */
export type StructuredProductStructureTemplateBooleanFieldInput = {
  metadata: StructuredProductStructureTemplateFieldMetadataInput
  value?: InputMaybe<Scalars['Boolean']>
}

/**
 *
 *         Supports StructuredProductCallType field on StructuredProductStructureTemplate, which is optional.
 *
 */
export type StructuredProductStructureTemplateCallTypeField = {
  __typename?: 'StructuredProductStructureTemplateCallTypeField'
  metadata: StructuredProductStructureTemplateFieldMetadata
  value?: Maybe<StructuredProductCallType>
}

/**
 *
 *         Supports StructuredProductCallType field on StructuredProductStructureTemplate, which is optional.
 *
 */
export type StructuredProductStructureTemplateCallTypeFieldInput = {
  metadata: StructuredProductStructureTemplateFieldMetadataInput
  value?: InputMaybe<StructuredProductCallType>
}

export type StructuredProductStructureTemplateCannotBeResolvedError =
  StructuredProductError & {
    __typename?: 'StructuredProductStructureTemplateCannotBeResolvedError'
    errorAttributes: Scalars['JSON']
    message: Scalars['String']
  }

/**
 *
 *         Supports CouponType field on StructuredProductStructureTemplate, which is optional.
 *
 */
export type StructuredProductStructureTemplateCouponTypeField = {
  __typename?: 'StructuredProductStructureTemplateCouponTypeField'
  metadata: StructuredProductStructureTemplateFieldMetadata
  value?: Maybe<StructuredProductCouponType>
}

/**
 *
 *         Supports CouponType field on StructuredProductStructureTemplate, which is optional.
 *
 */
export type StructuredProductStructureTemplateCouponTypeFieldInput = {
  metadata: StructuredProductStructureTemplateFieldMetadataInput
  value?: InputMaybe<StructuredProductCouponType>
}

export type StructuredProductStructureTemplateCouponTypeNotFound =
  StructuredProductStructureTemplateError & {
    __typename?: 'StructuredProductStructureTemplateCouponTypeNotFound'
    message: Scalars['String']
  }

/**
 *
 *         Supports DownsideProtectionType field on StructuredProductStructureTemplate, which is optional.
 *
 */
export type StructuredProductStructureTemplateDownsideProtectionTypeField = {
  __typename?: 'StructuredProductStructureTemplateDownsideProtectionTypeField'
  metadata: StructuredProductStructureTemplateFieldMetadata
  value?: Maybe<DownsideProtectionType>
}

/**
 *
 *         Supports DownsideProtectionType field on StructuredProductStructureTemplate, which is optional.
 *
 */
export type StructuredProductStructureTemplateDownsideProtectionTypeFieldInput =
  {
    metadata: StructuredProductStructureTemplateFieldMetadataInput
    value?: InputMaybe<DownsideProtectionType>
  }

export type StructuredProductStructureTemplateDuplicateField =
  StructuredProductStructureTemplateError & {
    __typename?: 'StructuredProductStructureTemplateDuplicateField'
    message: Scalars['String']
  }

export type StructuredProductStructureTemplateError = {
  message: Scalars['String']
}

/**
 *
 *         This is to set the meta data for each field on StructuredProductStructureTemplate. Field visibility is only supported at the moment.
 *         If visibility is null, the value field is used to set the visibility. if (value == null) NON_VISIBLE else VISIBLE.
 *
 */
export type StructuredProductStructureTemplateFieldMetadata = {
  __typename?: 'StructuredProductStructureTemplateFieldMetadata'
  visibility?: Maybe<ProductFieldVisibility>
}

/**
 *
 *         This is to set the meta data for each field on StructuredProductStructureTemplate. Field visibility is only supported at the moment.
 *         If visibility is null, the value field is used to set the visibility. if (value == null) NON_VISIBLE else VISIBLE.
 *
 */
export type StructuredProductStructureTemplateFieldMetadataInput = {
  visibility?: InputMaybe<ProductFieldVisibility>
}

export type StructuredProductStructureTemplateFilterInput = {
  and?: InputMaybe<Array<StructuredProductStructureTemplateFilterInput>>
  caisId?: InputMaybe<StringFilterInput>
  name?: InputMaybe<StringFilterInput>
  not?: InputMaybe<Array<StructuredProductStructureTemplateFilterInput>>
  or?: InputMaybe<Array<StructuredProductStructureTemplateFilterInput>>
  subType?: InputMaybe<StringFilterInput>
}

/**
 *
 *         Input model to create/update StructuredProductStructureTemplate.
 *
 */
export type StructuredProductStructureTemplateInput = {
  acronym?: InputMaybe<Scalars['String']>
  callType: StructuredProductStructureTemplateCallTypeFieldInput
  couponType: StructuredProductStructureTemplateCouponTypeFieldInput
  customName?: InputMaybe<Scalars['String']>
  downsideProtectionType: StructuredProductStructureTemplateDownsideProtectionTypeFieldInput
  fdicInsured: StructuredProductStructureTemplateMandatoryBooleanFieldInput
  hasAbsoluteReturn: StructuredProductStructureTemplateBooleanFieldInput
  hasAdditionalYieldTerms?: InputMaybe<StructuredProductStructureTemplateBooleanFieldInput>
  hasBearish: StructuredProductStructureTemplateBooleanFieldInput
  hasCallPremium: StructuredProductStructureTemplateBooleanFieldInput
  hasCoupon: StructuredProductStructureTemplateBooleanFieldInput
  hasCouponBarrier: StructuredProductStructureTemplateBooleanFieldInput
  hasCouponMemory: StructuredProductStructureTemplateBooleanFieldInput
  hasDigitalReturn: StructuredProductStructureTemplateBooleanFieldInput
  hasFixedToFloatTerms?: InputMaybe<StructuredProductStructureTemplateBooleanFieldInput>
  hasGearedDownside: StructuredProductStructureTemplateBooleanFieldInput
  hasLookBack: StructuredProductStructureTemplateBooleanFieldInput
  hasMaximumReturn: StructuredProductStructureTemplateBooleanFieldInput
  hasRainbow: StructuredProductStructureTemplateBooleanFieldInput
  hasRebate?: InputMaybe<StructuredProductStructureTemplateBooleanFieldInput>
  hasStepDownAutoCallHurdle: StructuredProductStructureTemplateBooleanFieldInput
  hasStepUpAutoCallHurdle: StructuredProductStructureTemplateBooleanFieldInput
  hasUpsideAboveDigitalReturn: StructuredProductStructureTemplateBooleanFieldInput
  hasUpsideKnockout: StructuredProductStructureTemplateBooleanFieldInput
  hasUpsideParticipation: StructuredProductStructureTemplateBooleanFieldInput
  multipleFinalValuationDatesStyle?: InputMaybe<StructuredProductStructureTemplateMultipleFinalValuationDateStylesFieldInput>
  name: Scalars['String']
  structureWsd?: InputMaybe<Scalars['String']>
  subType: StructuredProductStructureTemplateStructuredProductSubTypeFieldInput
  wrapperGroup: StructuredProductStructureTemplateWrapperGroupFieldInput
}

export type StructuredProductStructureTemplateInvalidField =
  StructuredProductStructureTemplateError & {
    __typename?: 'StructuredProductStructureTemplateInvalidField'
    message: Scalars['String']
  }

/**
 *
 *         Supports StructuredProductStructureTemplate Mandatory Boolean field.
 *
 */
export type StructuredProductStructureTemplateMandatoryBooleanField = {
  __typename?: 'StructuredProductStructureTemplateMandatoryBooleanField'
  metadata: StructuredProductStructureTemplateFieldMetadata
  value: Scalars['Boolean']
}

/**
 *
 *         Supports StructuredProductStructureTemplate Mandatory Boolean field.
 *
 */
export type StructuredProductStructureTemplateMandatoryBooleanFieldInput = {
  metadata: StructuredProductStructureTemplateFieldMetadataInput
  value: Scalars['Boolean']
}

/**
 *
 *         Supports StructuredProductStructureTemplate String Boolean field.
 *
 */
export type StructuredProductStructureTemplateMandatoryStringField = {
  __typename?: 'StructuredProductStructureTemplateMandatoryStringField'
  metadata: StructuredProductStructureTemplateFieldMetadata
  value: Scalars['String']
}

export type StructuredProductStructureTemplateMappingNotFoundError =
  StructuredProductError & {
    __typename?: 'StructuredProductStructureTemplateMappingNotFoundError'
    errorAttributes: Scalars['JSON']
    message: Scalars['String']
  }

export type StructuredProductStructureTemplateMissingField =
  StructuredProductStructureTemplateError & {
    __typename?: 'StructuredProductStructureTemplateMissingField'
    message: Scalars['String']
  }

export type StructuredProductStructureTemplateMissingFieldValues =
  StructuredProductStructureTemplateError & {
    __typename?: 'StructuredProductStructureTemplateMissingFieldValues'
    message: Scalars['String']
  }

/**
 *
 *         Supports MultipleFinalValuationDateStyle field on StructuredProductStructureTemplate, which is optional.
 *
 */
export type StructuredProductStructureTemplateMultipleFinalValuationDateStylesField =
  {
    __typename?: 'StructuredProductStructureTemplateMultipleFinalValuationDateStylesField'
    metadata: StructuredProductStructureTemplateFieldMetadata
    value?: Maybe<MultipleFinalValuationDatesStyle>
  }

/**
 *
 *         Supports MultipleFinalValuationDateStyle field on StructuredProductStructureTemplate, which is optional.
 *
 */
export type StructuredProductStructureTemplateMultipleFinalValuationDateStylesFieldInput =
  {
    metadata: StructuredProductStructureTemplateFieldMetadataInput
    value?: InputMaybe<MultipleFinalValuationDatesStyle>
  }

/**
 *
 *         Model to create/update StructuredProductStructureTemplate.
 *
 */
export type StructuredProductStructureTemplatePayload = {
  __typename?: 'StructuredProductStructureTemplatePayload'
  error?: Maybe<StructuredProductStructureTemplateError>
  structuredProductStructureTemplate?: Maybe<StructuredProductStructureTemplate>
}

export enum StructuredProductStructureTemplateSort {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  ProductSubTypeAsc = 'PRODUCT_SUB_TYPE_ASC',
  ProductSubTypeDesc = 'PRODUCT_SUB_TYPE_DESC',
}

/**
 *
 *         Supports StructuredProductStructureTemplate StructuredProductSubType field, which is mandatory.
 *
 */
export type StructuredProductStructureTemplateStructuredProductSubTypeField = {
  __typename?: 'StructuredProductStructureTemplateStructuredProductSubTypeField'
  metadata: StructuredProductStructureTemplateFieldMetadata
  value: StructuredProductSubType
}

/**
 *
 *         Supports StructuredProductStructureTemplate StructuredProductSubType field, which is mandatory.
 *
 */
export type StructuredProductStructureTemplateStructuredProductSubTypeFieldInput =
  {
    metadata: StructuredProductStructureTemplateFieldMetadataInput
    value: StructuredProductSubType
  }

/**
 *
 *         Supports StructuredProductStructureTemplate Optional String field.
 *
 */
export type StructuredProductStructureTemplateStructuredStringField = {
  __typename?: 'StructuredProductStructureTemplateStructuredStringField'
  metadata: StructuredProductStructureTemplateFieldMetadata
  value?: Maybe<Scalars['String']>
}

/**
 *
 *         Supports StructuredProductStructureTemplate StructuredProductWrapperGroup field, which is mandatory.
 *
 */
export type StructuredProductStructureTemplateWrapperGroupField = {
  __typename?: 'StructuredProductStructureTemplateWrapperGroupField'
  metadata: StructuredProductStructureTemplateFieldMetadata
  value: StructuredProductWrapperGroup
}

/**
 *
 *         Supports StructuredProductStructureTemplate StructuredProductWrapperGroup field, which is mandatory.
 *
 */
export type StructuredProductStructureTemplateWrapperGroupFieldInput = {
  metadata: StructuredProductStructureTemplateFieldMetadataInput
  value: StructuredProductWrapperGroup
}

export type StructuredProductStructureTemplateWsdNameAlreadyTaken =
  StructuredProductStructureTemplateError & {
    __typename?: 'StructuredProductStructureTemplateWsdNameAlreadyTaken'
    message: Scalars['String']
  }

export enum StructuredProductSubType {
  Growth = 'GROWTH',
  Protection = 'PROTECTION',
  Yield = 'YIELD',
}

export type StructuredProductSubTypeField = {
  __typename?: 'StructuredProductSubTypeField'
  metadata: ProductFieldMetadata
  value?: Maybe<StructuredProductSubType>
}

/** Model to update product subtype in StructuredProduct along with metadata. */
export type StructuredProductSubTypeFieldInput = {
  metadata: StructuredProductMandatoryFieldMetadataInput
  value?: InputMaybe<StructuredProductSubType>
}

export type StructuredProductSubTypeNotFound =
  StructuredProductStructureTemplateError & {
    __typename?: 'StructuredProductSubTypeNotFound'
    message: Scalars['String']
  }

export type StructuredProductTeamLite = {
  __typename?: 'StructuredProductTeamLite'
  id: Scalars['ID']
  name: Scalars['String']
}

export enum StructuredProductTradeStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export type StructuredProductTradeStatusField = {
  __typename?: 'StructuredProductTradeStatusField'
  metadata: ProductFieldMetadata
  value?: Maybe<StructuredProductTradeStatus>
}

/** Model to update status of StructuredProduct trade status along with metadata. */
export type StructuredProductTradeStatusFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value?: InputMaybe<StructuredProductTradeStatus>
}

export type StructuredProductUnKnownMappingError = StructuredProductError & {
  __typename?: 'StructuredProductUnKnownMappingError'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export type StructuredProductUnderlier = {
  __typename?: 'StructuredProductUnderlier'
  autoCallLevel?: Maybe<Scalars['BigDecimal']>
  currentLevel?: Maybe<Scalars['BigDecimal']>
  currentLevelAsOf?: Maybe<Scalars['IsoDateTime']>
  distanceToCap?: Maybe<Scalars['BigDecimal']>
  distanceToCouponBarrier?: Maybe<Scalars['BigDecimal']>
  distanceToDownside?: Maybe<Scalars['BigDecimal']>
  downsideLevel?: Maybe<Scalars['BigDecimal']>
  id?: Maybe<Scalars['ID']>
  initialLevel?: Maybe<Scalars['BigDecimal']>
  legalName?: Maybe<Scalars['String']>
  paymentBarrierLevel?: Maybe<Scalars['BigDecimal']>
  quanto?: Maybe<Scalars['Boolean']>
  referenceAssetId?: Maybe<Scalars['ID']>
  return?: Maybe<Scalars['BigDecimal']>
  ric?: Maybe<Scalars['String']>
  ticker?: Maybe<Scalars['String']>
  underlierProductId?: Maybe<Scalars['ID']>
  weight?: Maybe<Scalars['BigDecimal']>
}

export type StructuredProductUnderlierData = {
  __typename?: 'StructuredProductUnderlierData'
  metadata: ProductFieldMetadata
  underliers: Array<StructuredProductUnderlier>
}

/** Model to update underliers of StructuredProduct along with metadata. */
export type StructuredProductUnderlierInput = {
  metadata: StructuredProductMandatoryFieldMetadataInput
  underliers: Array<UnderlierInput>
}

export type StructuredProductUploadFailure = {
  __typename?: 'StructuredProductUploadFailure'
  identifier: ProductIdentifier
  message: Scalars['String']
}

export type StructuredProductUploadPayload = {
  __typename?: 'StructuredProductUploadPayload'
  error?: Maybe<StructuredProductError>
  failure: Array<StructuredProductUploadFailure>
  success: Array<StructuredProduct>
}

export enum StructuredProductUploadStatus {
  Error = 'ERROR',
  InProgress = 'IN_PROGRESS',
  Success = 'SUCCESS',
}

export type StructuredProductValidation = StructuredProductError & {
  __typename?: 'StructuredProductValidation'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export enum StructuredProductWrapperGroup {
  Cd = 'CD',
  Certificate = 'CERTIFICATE',
  Insurance = 'INSURANCE',
  Note = 'NOTE',
  Warrant = 'WARRANT',
}

export type StructuredProductWrapperGroupField = {
  __typename?: 'StructuredProductWrapperGroupField'
  metadata: ProductFieldMetadata
  value?: Maybe<StructuredProductWrapperGroup>
}

/** Model to update wrapper group in StructuredProduct along with metadata. */
export type StructuredProductWrapperGroupFieldInput = {
  metadata: StructuredProductMandatoryFieldMetadataInput
  value?: InputMaybe<StructuredProductWrapperGroup>
}

export type StructuredProductWsdParsingError = StructuredProductError & {
  __typename?: 'StructuredProductWsdParsingError'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export type StructuredProductYield = {
  __typename?: 'StructuredProductYield'
  additionalTerms?: Maybe<ProductStringField>
  couponBarrier: ProductPricingComponentField
  couponMemory: ProductBooleanField
  couponObservationFrequency: ProductFrequencyField
  couponPaymentFrequency: ProductFrequencyField
  couponPerPeriod: ProductPricingComponentField
  couponRate: ProductPricingComponentField
  couponSchedule: StructuredProductYieldCouponSchedule
  couponType: StructuredProductCouponTypeField
  couponYieldSinceIssuance?: Maybe<Scalars['BigDecimal']>
  fixedRatePeriod?: Maybe<ProductBigDecimalField>
  floatingRateAdjustmentFactor?: Maybe<ProductBigDecimalField>
  floatingRateLeverageFactor?: Maybe<ProductBigDecimalField>
  maximumInterestRate?: Maybe<ProductBigDecimalField>
  minimumInterestRate?: Maybe<ProductBigDecimalField>
  nextCouponObservationDate: ProductLocalDateField
  nextCouponPaymentDate: ProductLocalDateField
  numberOfCouponsObserved?: Maybe<Scalars['Int']>
  numberOfCouponsPaid?: Maybe<Scalars['Int']>
  totalCouponValue?: Maybe<Scalars['BigDecimal']>
}

export type StructuredProductYieldCouponSchedule = {
  __typename?: 'StructuredProductYieldCouponSchedule'
  metadata: ProductFieldMetadata
  value?: Maybe<Array<StructuredProductCouponSchedule>>
}

/** Model to represent StructuredProduct yield coupon schedule field. */
export type StructuredProductYieldCouponScheduleFieldInput = {
  metadata: StructuredProductFieldMetadataInput
  value: Array<StructuredProductYieldCouponScheduleInput>
}

/** Model to represent StructuredProduct coupon schedule field. */
export type StructuredProductYieldCouponScheduleInput = {
  barrierLevel?: InputMaybe<Scalars['BigDecimal']>
  observationDate?: InputMaybe<Scalars['Date']>
  outcome?: InputMaybe<StructuredProductCouponOutcome>
  periodRate?: InputMaybe<Scalars['BigDecimal']>
  settlementDate?: InputMaybe<Scalars['Date']>
}

/** Model to update properties of Yield in StructuredProduct along with metadata. */
export type StructuredProductYieldInput = {
  additionalTerms?: InputMaybe<StructuredProductStringFieldInput>
  couponBarrier: StructuredProductPricingComponentFieldInput
  couponMemory: StructuredProductBooleanFieldInput
  couponObservationFrequency: StructuredProductFrequencyFieldInput
  couponPaymentFrequency: StructuredProductFrequencyFieldInput
  couponPerPeriod: StructuredProductPricingComponentFieldInput
  couponRate: StructuredProductPricingComponentFieldInput
  couponSchedule?: InputMaybe<StructuredProductYieldCouponScheduleFieldInput>
  couponType: StructuredProductCouponTypeFieldInput
  fixedRatePeriod?: InputMaybe<StructuredProductBigDecimalFieldInput>
  floatingRateAdjustmentFactor?: InputMaybe<StructuredProductBigDecimalFieldInput>
  floatingRateLeverageFactor?: InputMaybe<StructuredProductBigDecimalFieldInput>
  maximumInterestRate?: InputMaybe<StructuredProductBigDecimalFieldInput>
  minimumInterestRate?: InputMaybe<StructuredProductBigDecimalFieldInput>
}

export type StructuredProductsExceededBulkCreateLimit =
  StructuredProductError & {
    __typename?: 'StructuredProductsExceededBulkCreateLimit'
    errorAttributes: Scalars['JSON']
    message: Scalars['String']
  }

/** A 'SubjectShareClassAccessPolicyPageV1' represents the search response with pagination information */
export type SubjectShareClassAccessPolicyPageV1 = PageResult & {
  __typename?: 'SubjectShareClassAccessPolicyPageV1'
  items: Array<SubjectShareClassAccessPolicyV1>
  pageInfo: PageInfo
}

/** A 'SubjectShareClassAccessPolicyV1' represents the access policy configured for a firm for each share class of a fund product */
export type SubjectShareClassAccessPolicyV1 = {
  __typename?: 'SubjectShareClassAccessPolicyV1'
  hasFundAccess: Scalars['Boolean']
  shareClassAccessPolicies: Array<ShareClassAccessPolicyV1>
  subject: FundProductSubjectV1
}

export type SwitchUserInput = {
  id: Scalars['ID']
}

export type SwitchUserPayload = {
  __typename?: 'SwitchUserPayload'
  sessionId: Scalars['ID']
  switchUserId: Scalars['ID']
}

export type TaxClassification = {
  __typename?: 'TaxClassification'
  description?: Maybe<Scalars['String']>
  type?: Maybe<Scalars['String']>
}

export type TaxExemptOrganizationAccount = Account & {
  __typename?: 'TaxExemptOrganizationAccount'
  accountCategory: Scalars['String']
  accreditedInvestorStatuses?: Maybe<Array<AccreditedInvestorStatusResponse>>
  advisorNotifications?: Maybe<Scalars['Boolean']>
  brokerageDetails?: Maybe<BrokerageDetails>
  commonAccountDetails?: Maybe<CommonAccountDetails>
  createdAt: Scalars['IsoDateTime']
  custodianDetails?: Maybe<CustodianNumberDetails>
  externalId?: Maybe<Scalars['String']>
  feeStructure?: Maybe<Scalars['String']>
  fundAdministratorsDetails?: Maybe<Array<FundAdministratorDetails>>
  householdId: Scalars['ID']
  id: Scalars['ID']
  investmentEntities: InvestmentEntities
  investorType?: Maybe<InvestorTypeDetailsResponse>
  isLatest: Scalars['Boolean']
  isNominee?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  primarySigner: ContactReference
  qualifiedClientStatuses?: Maybe<Array<QualifiedClientStatusResponse>>
  qualifiedEligiblePersonStatuses?: Maybe<
    Array<QualifiedEligiblePersonStatusResponse>
  >
  qualifiedPurchaserStatuses?: Maybe<Array<QualifiedPurchaserStatusResponse>>
  reportingProviderDetails?: Maybe<ReportingProviderDetails>
  /** @deprecated Use reportingProviderDetails instead */
  reportingProvidersDetails?: Maybe<Array<ReportingProviderDetails>>
  subType: Scalars['String']
  taxExemptOrganizationAccountDetails?: Maybe<TaxExemptOrganizationAccountDetails>
  taxStatusDetails?: Maybe<TaxStatusDetailsResponse>
  updatedAt: Scalars['IsoDateTime']
  version: Scalars['Int']
}

export type TaxExemptOrganizationAccountDetails = {
  __typename?: 'TaxExemptOrganizationAccountDetails'
  accountEligibility?: Maybe<AccountEligibility>
  clientEntityAffiliations?: Maybe<ClientEntityAffiliations>
  primaryClient?: Maybe<EntityClientAccountDetails>
}

export enum TaxExemptionBasisCode {
  Corporation = 'Corporation',
  EstateOrTrustNotGrantOrRevocable = 'EstateOrTrustNotGrantOrRevocable',
  Foundation = 'Foundation',
  GrantorOrRevocableTrust = 'GrantorOrRevocableTrust',
  Ira = 'IRA',
  Llc = 'LLC',
  Other = 'Other',
  Partnership = 'Partnership',
}

export enum TaxReporting {
  Form_1099 = 'FORM_1099',
  K1K3 = 'K1_K3',
  Na = 'NA',
  Pfic = 'PFIC',
}

export enum TaxStatus {
  NonUs = 'NON_US',
  UsTaxable = 'US_TAXABLE',
  UsTaxExempt = 'US_TAX_EXEMPT',
}

export type TaxStatusDetailsInput = {
  taxExemptionBasisCode?: InputMaybe<TaxExemptionBasisCode>
  taxExemptionBasisReason?: InputMaybe<Scalars['String']>
  taxStatus: TaxStatus
}

export type TaxStatusDetailsResponse = {
  __typename?: 'TaxStatusDetailsResponse'
  taxExemptionBasisCode?: Maybe<Scalars['String']>
  taxExemptionBasisReason?: Maybe<Scalars['String']>
  taxStatus: Scalars['String']
}

export type TaxableYearEnd = {
  __typename?: 'TaxableYearEnd'
  day: Scalars['Int']
  month: Scalars['Int']
}

/**
 * An IAM representation of a team.
 *     The IAM meaning of 'team' is a way to group users in a firm by functional, departmental or other roles.
 *     Teams themselves do not carry specific access permissions.
 *     Within the IAM domain, a team must belong to either another team (parent team) or a firm
 */
export type Team = Entity &
  Node & {
    __typename?: 'Team'
    activeUsers: Scalars['Int']
    address?: Maybe<Address>
    attributes?: Maybe<Scalars['JSON']>
    description?: Maybe<Scalars['String']>
    id: Scalars['ID']
    name: Scalars['String']
    parent?: Maybe<HierarchyEdge>
    totalUsers: Scalars['Int']
    users: UsersPageResult
    version: Scalars['Int']
    withStats: Team
  }

/**
 * An IAM representation of a team.
 *     The IAM meaning of 'team' is a way to group users in a firm by functional, departmental or other roles.
 *     Teams themselves do not carry specific access permissions.
 *     Within the IAM domain, a team must belong to either another team (parent team) or a firm
 */
export type TeamUsersArgs = {
  filter?: InputMaybe<UserFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sorts?: InputMaybe<Array<UserSort>>
}

/**
 * An IAM representation of a team.
 *     The IAM meaning of 'team' is a way to group users in a firm by functional, departmental or other roles.
 *     Teams themselves do not carry specific access permissions.
 *     Within the IAM domain, a team must belong to either another team (parent team) or a firm
 */
export type TeamWithStatsArgs = {
  stats: TeamStatsInput
}

export type TeamFilterInput = {
  externalId?: InputMaybe<StringFilterInput>
  firmId?: InputMaybe<FirmIdFilterInput>
  id?: InputMaybe<IdFilterInput>
  name?: InputMaybe<StringFilterInput>
}

export type TeamIdPayload = {
  __typename?: 'TeamIdPayload'
  id: Scalars['ID']
}

export enum TeamSort {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
}

export type TeamStatsInput = {
  activeUsers: Scalars['Int']
  teamId: Scalars['String']
  totalUsers: Scalars['Int']
}

export type TeamsPageResult = PageResult & {
  __typename?: 'TeamsPageResult'
  items: Array<Team>
  pageInfo: PageInfo
}

export enum TermsAndConditionType {
  FundManager = 'FUND_MANAGER',
  Platform = 'PLATFORM',
  StructuredProducts = 'STRUCTURED_PRODUCTS',
}

export type TermsAndConditions = {
  __typename?: 'TermsAndConditions'
  acceptedAt?: Maybe<Scalars['IsoDateTime']>
  type: TermsAndConditionType
  version: Scalars['Int']
}

/** Transaction Stage values */
export enum TransactionStage {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

export type TransactionStageEnumFilterInput = {
  eq?: InputMaybe<TransactionStage>
  in?: InputMaybe<Array<TransactionStage>>
  ne?: InputMaybe<TransactionStage>
}

export enum TransactionSubType {
  CapitalGainOrLoss = 'CAPITAL_GAIN_OR_LOSS',
  Commitment = 'COMMITMENT',
  Dividend = 'DIVIDEND',
  EntityOrAccountTransferIn = 'ENTITY_OR_ACCOUNT_TRANSFER_IN',
  EntityOrAccountTransferOut = 'ENTITY_OR_ACCOUNT_TRANSFER_OUT',
  EqualizationAdj = 'EQUALIZATION_ADJ',
  IncentiveFee = 'INCENTIVE_FEE',
  Income = 'INCOME',
  Interest = 'INTEREST',
  Investment = 'INVESTMENT',
  ManagementFee = 'MANAGEMENT_FEE',
  OperatingFee = 'OPERATING_FEE',
  PlacementFee = 'PLACEMENT_FEE',
  RedemptionFee = 'REDEMPTION_FEE',
  Reinvestment = 'REINVESTMENT',
  ReturnOfCapital = 'RETURN_OF_CAPITAL',
  SeriesExchangeIn = 'SERIES_EXCHANGE_IN',
  SeriesExchangeOut = 'SERIES_EXCHANGE_OUT',
  ShareClassExchangeIn = 'SHARE_CLASS_EXCHANGE_IN',
  ShareClassExchangeOut = 'SHARE_CLASS_EXCHANGE_OUT',
  Subscription = 'SUBSCRIPTION',
  SubscriptionFee = 'SUBSCRIPTION_FEE',
  WithholdingTax = 'WITHHOLDING_TAX',
}

export type TransactionSubTypeFilterInput = {
  eq?: InputMaybe<TransactionSubType>
  in?: InputMaybe<Array<TransactionSubType>>
  ne?: InputMaybe<TransactionSubType>
}

export enum TransactionType {
  CapitalCall = 'CAPITAL_CALL',
  Distribution = 'DISTRIBUTION',
  Fee = 'FEE',
  Investment = 'INVESTMENT',
  Redemption = 'REDEMPTION',
  Transfer = 'TRANSFER',
}

export type TransactionTypeFilterInput = {
  eq?: InputMaybe<TransactionType>
  in?: InputMaybe<Array<TransactionType>>
  ne?: InputMaybe<TransactionType>
}

export enum TransactionsSort {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ReceivedAtAsc = 'RECEIVED_AT_ASC',
  ReceivedAtDesc = 'RECEIVED_AT_DESC',
}

export type Transfer = {
  __typename?: 'Transfer'
  full?: Maybe<Scalars['Boolean']>
  percentageTransferred?: Maybe<Scalars['BigDecimal']>
}

export type UuidAggregation = {
  __typename?: 'UUIDAggregation'
  count: Scalars['Int']
  value: Scalars['UUID']
}

export type UuidFilterInput = {
  eq?: InputMaybe<Scalars['UUID']>
  in?: InputMaybe<Array<Scalars['UUID']>>
}

export type UnSupportedIdentifierType = StructuredProductError & {
  __typename?: 'UnSupportedIdentifierType'
  errorAttributes: Scalars['JSON']
  message: Scalars['String']
}

export type Underlier = {
  __typename?: 'Underlier'
  legalName?: Maybe<Scalars['String']>
  referenceAssetId?: Maybe<Scalars['ID']>
  ticker?: Maybe<Scalars['String']>
}

/** Model to update properties of underlier in StructuredProduct. */
export type UnderlierInput = {
  autoCallLevel?: InputMaybe<Scalars['BigDecimal']>
  downsideLevel?: InputMaybe<Scalars['BigDecimal']>
  id?: InputMaybe<Scalars['ID']>
  initialLevel?: InputMaybe<Scalars['BigDecimal']>
  paymentBarrierLevel?: InputMaybe<Scalars['BigDecimal']>
  quanto?: InputMaybe<Scalars['Boolean']>
  referenceAssetId: Scalars['UUID']
  weight?: InputMaybe<Scalars['BigDecimal']>
}

export type UnswitchUserPayload = {
  __typename?: 'UnswitchUserPayload'
  success: Scalars['Boolean']
}

export type UpdateAddressInput = {
  addressType?: InputMaybe<AddressType>
  alternateEmail?: InputMaybe<Scalars['String']>
  city?: InputMaybe<Scalars['String']>
  country?: InputMaybe<Scalars['String']>
  fax?: InputMaybe<Scalars['String']>
  phone?: InputMaybe<Scalars['String']>
  postalCode?: InputMaybe<Scalars['String']>
  primaryEmail?: InputMaybe<Scalars['String']>
  state?: InputMaybe<Scalars['String']>
  street1?: InputMaybe<Scalars['String']>
  street2?: InputMaybe<Scalars['String']>
}

export type UpdateAdvisorFirmInfoInput = {
  type?: InputMaybe<AdvisorFirmType>
}

export type UpdateExternalIdsInput = {
  externalIds: ExternalIdsInput
  id: Scalars['ID']
}

export type UpdateFirmInput = {
  address?: InputMaybe<UpdateAddressInput>
  advisorFirmInfo?: InputMaybe<UpdateAdvisorFirmInfoInput>
  attributes?: InputMaybe<Scalars['JSON']>
  capabilities?: InputMaybe<Array<Scalars['String']>>
  fundAdminFirmInfo?: InputMaybe<UpdateFundAdminFirmInfoInput>
  fundManagerFirmInformation?: InputMaybe<UpdateFundManagerFirmInfoInput>
  id: Scalars['ID']
  name?: InputMaybe<Scalars['String']>
  parent?: InputMaybe<HierarchyEdgeInput>
  parentFirmAccessLevelLimit?: InputMaybe<Scalars['Int']>
  passwordLoginAllowed?: InputMaybe<Scalars['Boolean']>
  salesforceId?: InputMaybe<Scalars['String']>
  version?: InputMaybe<Scalars['Int']>
}

export type UpdateFirmPayload = {
  __typename?: 'UpdateFirmPayload'
  firm?: Maybe<Firm>
  name: Scalars['String']
}

export type UpdateFundAdminFirmInfoInput = {
  fundAdminId?: InputMaybe<Scalars['ID']>
}

export type UpdateFundManagerFirmInfoInput = {
  fundManagerId?: InputMaybe<Scalars['ID']>
}

export type UpdateFundProductAdminInput = {
  fundDetails: FundDetailsAdminInput
  shareClasses: Array<FundShareClassAdminInput>
}

export type UpdateFundProductAdminPayload = {
  __typename?: 'UpdateFundProductAdminPayload'
  fundProduct: FundProductAdmin
}

export type UpdateFundProductDocumentMetadataInput = {
  applicability: FundDocumentApplicability
  category: FundDocumentCategory
  contentType: FundDocumentContentType
  description?: InputMaybe<Scalars['String']>
  fileName: Scalars['String']
  shareClasses?: InputMaybe<Array<Scalars['UUID']>>
  title: Scalars['String']
  yearMonth?: InputMaybe<Scalars['String']>
}

export type UpdateFundProductShareClassPolicyStatusAdminPayload = {
  __typename?: 'UpdateFundProductShareClassPolicyStatusAdminPayload'
  fundProduct: FundProductAdmin
}

export type UpdateHouseholdInput = {
  name: Scalars['String']
  teamIds: Array<Scalars['ID']>
}

/** For editing an Issuer Group */
export type UpdateIssuerGroupInput = {
  name: Scalars['String']
  version: Scalars['Int']
}

/**
 *
 *         Model to capture created/updated issuer when successfully, otherwise capture error.
 *
 */
export type UpdateIssuerPayload = {
  __typename?: 'UpdateIssuerPayload'
  error?: Maybe<IssuerError>
  issuer?: Maybe<Issuer>
}

export type UpdateProductOnboardingDocsInput = {
  description?: InputMaybe<Scalars['String']>
  displayName?: InputMaybe<Scalars['String']>
  documentId: Scalars['Int']
  fileName?: InputMaybe<Scalars['String']>
  fundId: Scalars['String']
}

export type UpdateReferenceAssetInput = {
  bbgTickers: Array<Scalars['String']>
  currencyCode?: InputMaybe<Scalars['String']>
  isins: Array<Scalars['String']>
  legalName: Scalars['String']
  productType: ReferenceAssetType
  rics: Array<Scalars['String']>
  ticker: Scalars['String']
  version: Scalars['Int']
}

export type UpdateReferenceAssetPayload = {
  __typename?: 'UpdateReferenceAssetPayload'
  error?: Maybe<ReferenceAssetError>
  referenceAsset?: Maybe<ReferenceAsset>
}

export type UpdateRolePayload = {
  __typename?: 'UpdateRolePayload'
  role?: Maybe<Role>
}

export type UpdateServiceIdentityInput = {
  email: Scalars['String']
  externalId?: InputMaybe<Scalars['String']>
  firmId: Scalars['ID']
  id: Scalars['ID']
  permissionIds: Array<Scalars['String']>
  serviceName: Scalars['String']
}

export type UpdateServiceIdentityPayload = {
  __typename?: 'UpdateServiceIdentityPayload'
  serviceIdentity: ServiceIdentity
}

export type UpdateStructuredProductPayload = {
  __typename?: 'UpdateStructuredProductPayload'
  error?: Maybe<StructuredProductError>
  structuredProduct?: Maybe<StructuredProduct>
}

export type UpdateTeamInput = {
  address?: InputMaybe<CreateAddressInput>
  attributes?: InputMaybe<Scalars['JSON']>
  description?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  name: Scalars['String']
  parents: Array<NestedHierarchyEdgeInput>
  userIds?: InputMaybe<Array<Scalars['ID']>>
  version?: InputMaybe<Scalars['Int']>
}

export type UpdateTeamPayload = {
  __typename?: 'UpdateTeamPayload'
  team?: Maybe<Team>
}

export type UpdateUserInput = {
  address?: InputMaybe<UpdateAddressInput>
  attributes?: InputMaybe<Scalars['JSON']>
  email?: InputMaybe<Scalars['String']>
  firstName?: InputMaybe<Scalars['String']>
  id: Scalars['ID']
  isSupervisor?: InputMaybe<Scalars['Boolean']>
  lastName?: InputMaybe<Scalars['String']>
  middleInitial?: InputMaybe<Scalars['String']>
  parents?: InputMaybe<Array<NestedHierarchyEdgeInput>>
  phone?: InputMaybe<Scalars['String']>
  roleIds?: InputMaybe<Array<Scalars['ID']>>
  salesforceId?: InputMaybe<Scalars['String']>
  status?: InputMaybe<Scalars['String']>
  title?: InputMaybe<Scalars['String']>
  version?: InputMaybe<Scalars['Int']>
}

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload'
  user?: Maybe<User>
}

export type UploadMercerMonthlyDocsInput = {
  commentaryDate?: InputMaybe<Scalars['String']>
  productId: Scalars['Int']
}

export type UploadPerformanceStatsInput = {
  fundId: Scalars['String']
  overwrite: Scalars['Boolean']
  sheetName: Scalars['String']
}

export type UploadProductOnboardingDocsInput = {
  caisXDocument?: InputMaybe<Scalars['Int']>
  categoryId?: InputMaybe<Scalars['Int']>
  devFolderId?: InputMaybe<Scalars['String']>
  displayName?: InputMaybe<Scalars['String']>
  fileDate?: InputMaybe<Scalars['String']>
  fundId: Scalars['String']
  investorTypeId?: InputMaybe<Scalars['Int']>
  prodFolderId?: InputMaybe<Scalars['String']>
}

/**
 * An IAM representation of a user.
 *     The business meaning of 'user' is anyone who is registered and authorised to use CAIS applications, e.g. Portal, CAIS-IQ.
 *     Within the IAM domain, users may or may not be part of firms and/or teams
 */
export type User = Entity &
  Node & {
    __typename?: 'User'
    address?: Maybe<Address>
    attributes?: Maybe<Scalars['JSON']>
    capabilities: Array<Capability>
    email: Scalars['String']
    firmNames: Array<Scalars['String']>
    firmTypes: Array<Scalars['String']>
    firms: Array<UserFirm>
    firstName: Scalars['String']
    id: Scalars['ID']
    lastName: Scalars['String']
    lastVisitDate?: Maybe<Scalars['String']>
    middleInitial?: Maybe<Scalars['String']>
    mobilePhone?: Maybe<Scalars['String']>
    parents: Array<HierarchyEdge>
    personas: Array<Scalars['String']>
    phone?: Maybe<Scalars['String']>
    roles: Array<Role>
    salesforceId?: Maybe<Scalars['String']>
    status: UserStatus
    teams: Array<UserTeam>
    title?: Maybe<Scalars['String']>
    version: Scalars['Int']
  }

export type UserFilterInput = {
  email?: InputMaybe<StringFilterInput>
  firmId?: InputMaybe<IdFilterInput>
  persona?: InputMaybe<StringFilterInput>
  roleId?: InputMaybe<IdFilterInput>
  searchText?: InputMaybe<StringFilterInput>
  status?: InputMaybe<UserStatusInputFilterInput>
  teamId?: InputMaybe<IdFilterInput>
  userId?: InputMaybe<IdFilterInput>
}

export type UserFirm = {
  __typename?: 'UserFirm'
  id: Scalars['ID']
  name: Scalars['String']
  parent?: Maybe<HierarchyEdge>
}

export type UserIdPayload = {
  __typename?: 'UserIdPayload'
  id: Scalars['ID']
}

export type UserLite = {
  __typename?: 'UserLite'
  email: Scalars['String']
  firstName: Scalars['String']
  id: Scalars['ID']
  lastName: Scalars['String']
  middleInitial?: Maybe<Scalars['String']>
}

/**
 * A profile representation of the currently logged-in user.
 *     This is different from the more generic `User` entity which represents any user on the system.
 */
export type UserProfile = {
  __typename?: 'UserProfile'
  allParentFirms: Array<UserProfileFirm>
  attributes: Scalars['JSON']
  capabilities: Array<Capability>
  emailAddress: Scalars['String']
  features: Array<UserProfileFeature>
  firm?: Maybe<UserProfileFirm>
  firstName: Scalars['String']
  id: Scalars['ID']
  inheritedAttributes: Array<InheritedAttributes>
  isSwitched: Scalars['Boolean']
  lastName: Scalars['String']
  permissions: Array<Scalars['String']>
  personas: Array<Scalars['String']>
  termsAndConditions: Array<TermsAndConditions>
}

export type UserProfileFeature = {
  __typename?: 'UserProfileFeature'
  name: Scalars['String']
  status: UserProfileFeatureStatus
}

export enum UserProfileFeatureStatus {
  Off = 'OFF',
  On = 'ON',
}

export type UserProfileFirm = {
  __typename?: 'UserProfileFirm'
  id: Scalars['ID']
  name: Scalars['String']
}

export enum UserSort {
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FirmNamesAsc = 'FIRM_NAMES_ASC',
  FirmNamesDesc = 'FIRM_NAMES_DESC',
  FirmTypesAsc = 'FIRM_TYPES_ASC',
  FirmTypesDesc = 'FIRM_TYPES_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
}

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

export type UserStatusInputFilterInput = {
  /** User with this status */
  eq?: InputMaybe<UserStatus>
  /** Users with any of these statuses */
  in?: InputMaybe<Array<UserStatus>>
  /** Users with status different than this */
  ne?: InputMaybe<UserStatus>
}

export type UserTeam = {
  __typename?: 'UserTeam'
  id: Scalars['ID']
  name: Scalars['String']
  parent?: Maybe<HierarchyEdge>
}

export enum UserType {
  Admin = 'ADMIN',
  Advisor = 'ADVISOR',
  Associate = 'ASSOCIATE',
  Cais = 'CAIS',
  CaisStructuredNotesSales = 'CAIS_STRUCTURED_NOTES_SALES',
  FundManager = 'FUND_MANAGER',
  HomeOffice = 'HOME_OFFICE',
  Legal = 'LEGAL',
  Operations = 'OPERATIONS',
  PartnerDeveloper = 'PARTNER_DEVELOPER',
  PortalUser = 'PORTAL_USER',
  Product = 'PRODUCT',
  Supervisor = 'SUPERVISOR',
  UserTypeUnknown = 'USER_TYPE_UNKNOWN',
}

export type UsersPageResult = PageResult & {
  __typename?: 'UsersPageResult'
  items: Array<User>
  pageInfo: PageInfo
}

export type ViewActionFilterInput = {
  /** Equals */
  eq?: InputMaybe<PermissionAction>
  /** In the provided range of values. Alternative to multiple ORs */
  in?: InputMaybe<Array<PermissionAction>>
}

export enum WaterFallType {
  American = 'AMERICAN',
  European = 'EUROPEAN',
}

export type WireInstructions = {
  __typename?: 'WireInstructions'
  accountInformation?: Maybe<FundingAccount>
  bankInformation?: Maybe<FundingBank>
  useCustodianAccount: Scalars['Boolean']
}

export type Withdrawals = {
  __typename?: 'Withdrawals'
  currentPeriod?: Maybe<Money>
  itd?: Maybe<Money>
  ytd?: Maybe<Money>
}

export type GetPipelineRunsQueryVariables = Exact<{
  filter?: InputMaybe<PipelineRunSearchFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<PipelineRunSort> | PipelineRunSort>
}>

export type GetPipelineRunsQuery = {
  __typename?: 'Query'
  pipelineRuns: {
    __typename?: 'PipelineRunPageResult'
    items: Array<{
      __typename?: 'PipelineRun'
      runId: string
      pipelineId: string
      domain: PipelineDomain
      executionDate: string
      state: PipelineState
      executionMetadata?: any | null
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
    }
  }
}

export type RetryDocumentMutationVariables = Exact<{
  input: PipelineRerunInput
}>

export type RetryDocumentMutation = {
  __typename?: 'Mutation'
  rerunPipeline: {
    __typename?: 'PipelineRerunPayload'
    payload: {
      __typename?: 'PipelineRun'
      runId: string
      pipelineId: string
      domain: PipelineDomain
      executionDate: string
      state: PipelineState
      executionMetadata?: any | null
    }
  }
}

export type GetFirmQueryVariables = Exact<{
  firmId: Scalars['ID']
  roleFilters?: InputMaybe<IdFilterInput>
  searchText?: InputMaybe<StringFilterInput>
  sort?: InputMaybe<Array<UserSort> | UserSort>
  statusFilters?: InputMaybe<UserStatusInputFilterInput>
  pagination?: InputMaybe<PaginationInput>
}>

export type GetFirmQuery = {
  __typename?: 'Query'
  firm: {
    __typename?: 'Firm'
    id: string
    name: string
    firmTypes: Array<FirmType>
    totalUsers: number
    validPersonasForFirmRoles: Array<string>
    advisorFirmInfo?: {
      __typename?: 'AdvisorFirmInfo'
      type: AdvisorFirmType
    } | null
    address?: {
      __typename?: 'Address'
      country: string
      street1: string
      street2?: string | null
      city: string
      state: string
      postalCode: string
    } | null
    validCapabilitiesForFirm: Array<{
      __typename?: 'Capability'
      id: string
      name?: string | null
      description?: string | null
    }>
    capabilities: Array<{
      __typename?: 'Capability'
      id: string
      name?: string | null
      description?: string | null
    }>
    userAssignableRoles: Array<{
      __typename?: 'Role'
      id: string
      persona: string
      name: string
      isDefault?: boolean | null
    }>
    users: {
      __typename?: 'UsersPageResult'
      items: Array<{
        __typename?: 'User'
        title?: string | null
        attributes?: any | null
        phone?: string | null
        id: string
        email: string
        firstName: string
        lastName: string
        status: UserStatus
        firmNames: Array<string>
        firmTypes: Array<string>
        address?: {
          __typename?: 'Address'
          country: string
          street1: string
          street2?: string | null
          city: string
          state: string
          postalCode: string
        } | null
        roles: Array<{
          __typename?: 'Role'
          name: string
          persona: string
          isDefault?: boolean | null
        }>
      }>
      pageInfo: {
        __typename?: 'PageInfo'
        hasNextPage: boolean
        endCursor?: string | null
        startCursor?: string | null
        hasPreviousPage: boolean
      }
    }
  }
}

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput
}>

export type CreateUserMutation = {
  __typename?: 'Mutation'
  createUser: {
    __typename?: 'CreateUserPayload'
    user?: {
      __typename?: 'User'
      id: string
      email: string
      firstName: string
      lastName: string
      roles: Array<{ __typename?: 'Role'; id: string; name: string }>
    } | null
  }
}

export type GetFirmRolesQueryVariables = Exact<{
  firmId: Scalars['ID']
}>

export type GetFirmRolesQuery = {
  __typename?: 'Query'
  firmRoles: Array<{
    __typename?: 'Role'
    id: string
    name: string
    description?: string | null
    isDefault?: boolean | null
    persona: string
    permissions: Array<{
      __typename?: 'Permission'
      id: string
      name: string
      capability: {
        __typename?: 'Capability'
        id: string
        name?: string | null
      }
    }>
  }>
}

export type GetFirmPermissionsQueryVariables = Exact<{
  firmId: Scalars['ID']
}>

export type GetFirmPermissionsQuery = {
  __typename?: 'Query'
  firmPermissions: Array<{
    __typename?: 'Permission'
    id: string
    name: string
    capability: { __typename?: 'Capability'; id: string; name?: string | null }
  }>
}

export type UserQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type UserQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'User'
    title?: string | null
    phone?: string | null
    status: UserStatus
    personas: Array<string>
    id: string
    email: string
    firstName: string
    lastName: string
    capabilities: Array<{
      __typename?: 'Capability'
      id: string
      name?: string | null
    }>
    address?: {
      __typename?: 'Address'
      country: string
      street1: string
      street2?: string | null
      city: string
      state: string
      postalCode: string
    } | null
    parents: Array<{
      __typename?: 'HierarchyEdge'
      id: string
      entityType: NestedHierarchyEdgeType
    }>
    teams: Array<{ __typename?: 'UserTeam'; id: string; name: string }>
    firms: Array<{ __typename?: 'UserFirm'; id: string; name: string }>
    roles: Array<{
      __typename?: 'Role'
      id: string
      persona: string
      name: string
      description?: string | null
    }>
  }
}

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  __typename?: 'Mutation'
  updateUser: {
    __typename?: 'UpdateUserPayload'
    user?: {
      __typename?: 'User'
      id: string
      email: string
      firstName: string
      lastName: string
      roles: Array<{ __typename?: 'Role'; id: string; name: string }>
    } | null
  }
}

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteUserMutation = {
  __typename?: 'Mutation'
  deleteUser: { __typename?: 'UserIdPayload'; id: string }
}

export type ResendActivationEmailMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type ResendActivationEmailMutation = {
  __typename?: 'Mutation'
  resendActivationEmail: { __typename?: 'ResendActivationPayload'; id: string }
}

export type GetCapabilitiesQueryVariables = Exact<{ [key: string]: never }>

export type GetCapabilitiesQuery = {
  __typename?: 'Query'
  capabilities: Array<{
    __typename?: 'Capability'
    name?: string | null
    id: string
  }>
}

export type GetFirmNamesQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>
}>

export type GetFirmNamesQuery = {
  __typename?: 'Query'
  firmNames: Array<{ __typename?: 'EntityName'; id: string; name: string }>
}

export type GetUsersQueryVariables = Exact<{
  roleFilters?: InputMaybe<IdFilterInput>
  firmFilters?: InputMaybe<IdFilterInput>
  searchText?: InputMaybe<StringFilterInput>
  sort?: InputMaybe<Array<UserSort> | UserSort>
  statusFilters?: InputMaybe<UserStatusInputFilterInput>
  pagination?: InputMaybe<PaginationInput>
  parent?: InputMaybe<IamEntityIdentifierInput>
}>

export type GetUsersQuery = {
  __typename?: 'Query'
  users: {
    __typename?: 'UsersPageResult'
    items: Array<{
      __typename?: 'User'
      id: string
      email: string
      firstName: string
      lastName: string
      status: UserStatus
      firmNames: Array<string>
      attributes?: any | null
      firmTypes: Array<string>
      roles: Array<{ __typename?: 'Role'; name: string; persona: string }>
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
      startCursor?: string | null
      hasPreviousPage: boolean
    }
  }
}

export type GetFirmsQueryVariables = Exact<{
  searchText?: InputMaybe<StringFilterInput>
  pagination?: InputMaybe<PaginationInput>
  firmTypeFilters?: InputMaybe<ArrayFilterInput>
}>

export type GetFirmsQuery = {
  __typename?: 'Query'
  firms: {
    __typename?: 'FirmsPageResult'
    items: Array<{
      __typename?: 'Firm'
      id: string
      name: string
      attributes?: any | null
      firmTypes: Array<FirmType>
      totalUsers: number
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
      startCursor?: string | null
      hasPreviousPage: boolean
    }
  }
}

export type UpdateFirmRoleMutationVariables = Exact<{
  firmId: Scalars['ID']
  roleId: Scalars['ID']
  input: RoleInput
}>

export type UpdateFirmRoleMutation = {
  __typename?: 'Mutation'
  updateFirmRole: {
    __typename?: 'UpdateRolePayload'
    role?: {
      __typename?: 'Role'
      id: string
      name: string
      description?: string | null
      persona: string
      isDefault?: boolean | null
      permissions: Array<{
        __typename?: 'Permission'
        id: string
        name: string
        description?: string | null
        capability: {
          __typename?: 'Capability'
          id: string
          name?: string | null
        }
      }>
    } | null
  }
}

export type UpdateFirmCapabilitiesMutationVariables = Exact<{
  firmId: Scalars['ID']
  capabilityIds: Array<Scalars['String']> | Scalars['String']
}>

export type UpdateFirmCapabilitiesMutation = {
  __typename?: 'Mutation'
  updateFirmCapabilities: { __typename?: 'FirmIdPayload'; id: string }
}

export type CreateFirmRoleMutationVariables = Exact<{
  firmId: Scalars['ID']
  input: RoleInput
}>

export type CreateFirmRoleMutation = {
  __typename?: 'Mutation'
  createFirmRole: {
    __typename?: 'CreateRolePayload'
    role?: {
      __typename?: 'Role'
      id: string
      name: string
      description?: string | null
      persona: string
      isDefault?: boolean | null
      permissions: Array<{
        __typename?: 'Permission'
        id: string
        name: string
        description?: string | null
        capability: {
          __typename?: 'Capability'
          id: string
          name?: string | null
        }
      }>
    } | null
  }
}

export type DeleteFirmRoleMutationVariables = Exact<{
  firmId: Scalars['ID']
  roleId: Scalars['ID']
}>

export type DeleteFirmRoleMutation = {
  __typename?: 'Mutation'
  deleteFirmRole: { __typename?: 'RoleIdPayload'; id?: string | null }
}

export type GetFirmTeamsQueryVariables = Exact<{
  firmId: Scalars['ID']
  pagination?: InputMaybe<PaginationInput>
  searchText?: InputMaybe<StringFilterInput>
}>

export type GetFirmTeamsQuery = {
  __typename?: 'Query'
  teamsPage: {
    __typename?: 'TeamsPageResult'
    items: Array<{
      __typename?: 'Team'
      id: string
      name: string
      totalUsers: number
      activeUsers: number
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
      startCursor?: string | null
      hasPreviousPage: boolean
    }
  }
}

export type UpdateTeamMutationVariables = Exact<{
  input: UpdateTeamInput
}>

export type UpdateTeamMutation = {
  __typename?: 'Mutation'
  updateTeam: {
    __typename?: 'UpdateTeamPayload'
    team?: { __typename?: 'Team'; id: string } | null
  }
}

export type GetTeamQueryVariables = Exact<{
  teamId: Scalars['ID']
  pagination?: InputMaybe<PaginationInput>
}>

export type GetTeamQuery = {
  __typename?: 'Query'
  team: {
    __typename?: 'Team'
    id: string
    name: string
    description?: string | null
    users: {
      __typename?: 'UsersPageResult'
      items: Array<{
        __typename?: 'User'
        id: string
        email: string
        firstName: string
        lastName: string
        attributes?: any | null
        status: UserStatus
        firmNames: Array<string>
        firmTypes: Array<string>
        roles: Array<{ __typename?: 'Role'; name: string; persona: string }>
      }>
    }
  }
}

export type CreateTeamMutationVariables = Exact<{
  input: CreateTeamInput
}>

export type CreateTeamMutation = {
  __typename?: 'Mutation'
  createTeam: {
    __typename?: 'CreateTeamPayload'
    team?: { __typename?: 'Team'; id: string } | null
  }
}

export type SwitchUserMutationVariables = Exact<{
  switchUserInput: SwitchUserInput
}>

export type SwitchUserMutation = {
  __typename?: 'Mutation'
  switchUser: {
    __typename?: 'SwitchUserPayload'
    switchUserId: string
    sessionId: string
  }
}

export type UnswitchUserMutationVariables = Exact<{ [key: string]: never }>

export type UnswitchUserMutation = {
  __typename?: 'Mutation'
  unswitchUser: { __typename?: 'UnswitchUserPayload'; success: boolean }
}

export type GetServiceIdentitiesQueryVariables = Exact<{
  firmId: Scalars['ID']
}>

export type GetServiceIdentitiesQuery = {
  __typename?: 'Query'
  serviceIdentities: {
    __typename?: 'ServiceIdentitiesPageResult'
    items: Array<{
      __typename?: 'ServiceIdentity'
      createDate: string
      deleteDate?: string | null
      email: string
      externalId?: string | null
      id: string
      serviceName: string
      updateDate?: string | null
      permissions: Array<{
        __typename?: 'Permission'
        id: string
        name: string
        description?: string | null
      }>
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
      startCursor?: string | null
      hasPreviousPage: boolean
    }
  }
}

export type CreateFirmMutationVariables = Exact<{
  input: CreateFirmInput
}>

export type CreateFirmMutation = {
  __typename?: 'Mutation'
  createFirm: {
    __typename?: 'CreateFirmPayload'
    firm?: { __typename?: 'Firm'; id: string } | null
  }
}

export type UpdateFirmMutationVariables = Exact<{
  input: UpdateFirmInput
}>

export type UpdateFirmMutation = {
  __typename?: 'Mutation'
  updateFirm: {
    __typename?: 'UpdateFirmPayload'
    firm?: { __typename?: 'Firm'; id: string } | null
  }
}

export type CreateServiceIdentityMutationVariables = Exact<{
  input: CreateServiceIdentityInput
}>

export type CreateServiceIdentityMutation = {
  __typename?: 'Mutation'
  createServiceIdentity: {
    __typename?: 'CreateServiceIdentityPayload'
    createdServiceIdentity: {
      __typename?: 'CreatedServiceIdentity'
      clientId: string
      clientSecret: string
    }
  }
}

export type UpdateServiceIdentityMutationVariables = Exact<{
  input: UpdateServiceIdentityInput
}>

export type UpdateServiceIdentityMutation = {
  __typename?: 'Mutation'
  updateServiceIdentity: {
    __typename?: 'UpdateServiceIdentityPayload'
    serviceIdentity: { __typename?: 'ServiceIdentity'; id: string }
  }
}

export type DeleteServiceIdentityMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteServiceIdentityMutation = {
  __typename?: 'Mutation'
  deleteServiceIdentity: { __typename?: 'ServiceIdentityIdPayload'; id: string }
}

export type UpdateFirmCustodianMutationVariables = Exact<{
  firmId: Scalars['ID']
  custodianIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type UpdateFirmCustodianMutation = {
  __typename?: 'Mutation'
  custodian: {
    __typename?: 'CustodianMutations'
    updateFirmList: {
      __typename?: 'FirmCustodianListResponse'
      customList: boolean
      custodians: Array<{
        __typename?: 'CustodianListEntry'
        id: string
        version: string
        name: string
        default: boolean
      }>
    }
  }
}

export type GetFirmCustodiansQueryVariables = Exact<{
  firmId: Scalars['ID']
}>

export type GetFirmCustodiansQuery = {
  __typename?: 'Query'
  custodian: {
    __typename?: 'CustodianQueries'
    firmCustodianList: {
      __typename?: 'FirmCustodianListResponse'
      customList: boolean
      custodians: Array<{
        __typename?: 'CustodianListEntry'
        id: string
        version: string
        name: string
        default: boolean
      }>
    }
  }
}

export type CreateCustodianMutationVariables = Exact<{
  custodian: CustodianInput
}>

export type CreateCustodianMutation = {
  __typename?: 'Mutation'
  custodian: {
    __typename?: 'CustodianMutations'
    create: {
      __typename?: 'CustodianDetails'
      accountNumberRegex?: string | null
      id: string
      name: string
      nameCode: string
      dtcc: boolean
      dtccCustodianId?: string | null
      qualifiedInvestorPrefix?: string | null
      qualifiedInvestorPostfix?: string | null
      nonQualifiedInvestorPrefix?: string | null
      nonQualifiedInvestorPostfix?: string | null
      esignEnabled: boolean
      esignAuthenticationMethod: ESignAuthMethod
      carbonCopyEnabled: boolean
      carbonCopyEmails: Array<string>
      default: boolean
      firmOverrides: any
      contacts: Array<{
        __typename?: 'CustodianContact'
        name: string
        email: string
        phone?: string | null
      }>
      platforms?: Array<{
        __typename?: 'CustodianPlatform'
        name: string
        id: string
      }> | null
      wireInstructions?: {
        __typename?: 'CustodianWireInstructions'
        bankDetails: {
          __typename?: 'CustodianBankDetails'
          name: string
          country: string
          street1: string
          street2?: string | null
          city: string
          state: string
          postalCode: string
          swiftCode?: string | null
          wireTransferCode: string
        }
        accountDetails: {
          __typename?: 'CustodianAccountDetails'
          type: CustodianBankAccountType
          name: string
          number: string
          furtherCreditOptions: Array<{
            __typename?: 'CustodianFurtherCreditOption'
            optionType: CustodianFurtherCreditOptionType
            accountName: string
            accountNumber: string
          }>
        }
      } | null
    }
  }
}

export type UpdateCustodianMutationVariables = Exact<{
  custodianId: Scalars['ID']
  custodian: CustodianInput
}>

export type UpdateCustodianMutation = {
  __typename?: 'Mutation'
  custodian: {
    __typename?: 'CustodianMutations'
    update: {
      __typename?: 'CustodianDetails'
      accountNumberRegex?: string | null
      id: string
      name: string
      nameCode: string
      dtcc: boolean
      dtccCustodianId?: string | null
      qualifiedInvestorPrefix?: string | null
      qualifiedInvestorPostfix?: string | null
      nonQualifiedInvestorPrefix?: string | null
      nonQualifiedInvestorPostfix?: string | null
      esignEnabled: boolean
      esignAuthenticationMethod: ESignAuthMethod
      carbonCopyEnabled: boolean
      carbonCopyEmails: Array<string>
      default: boolean
      firmOverrides: any
      contacts: Array<{
        __typename?: 'CustodianContact'
        name: string
        email: string
        phone?: string | null
      }>
      platforms?: Array<{
        __typename?: 'CustodianPlatform'
        name: string
        id: string
      }> | null
      wireInstructions?: {
        __typename?: 'CustodianWireInstructions'
        bankDetails: {
          __typename?: 'CustodianBankDetails'
          name: string
          country: string
          street1: string
          street2?: string | null
          city: string
          state: string
          postalCode: string
          swiftCode?: string | null
          wireTransferCode: string
        }
        accountDetails: {
          __typename?: 'CustodianAccountDetails'
          type: CustodianBankAccountType
          name: string
          number: string
          furtherCreditOptions: Array<{
            __typename?: 'CustodianFurtherCreditOption'
            optionType: CustodianFurtherCreditOptionType
            accountName: string
            accountNumber: string
          }>
        }
      } | null
    }
  }
}

export type GetCustodianDetailsQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetCustodianDetailsQuery = {
  __typename?: 'Query'
  custodian: {
    __typename?: 'CustodianQueries'
    get: {
      __typename?: 'CustodianDetails'
      accountNumberRegex?: string | null
      id: string
      carbonCopyEmails: Array<string>
      carbonCopyEnabled: boolean
      createdAt: string
      createdBy: string
      default: boolean
      dtcc: boolean
      dtccCustodianId?: string | null
      esignAuthenticationMethod: ESignAuthMethod
      esignEnabled: boolean
      firmOverrides: any
      name: string
      nameCode: string
      nonQualifiedInvestorPostfix?: string | null
      nonQualifiedInvestorPrefix?: string | null
      qualifiedInvestorPostfix?: string | null
      qualifiedInvestorPrefix?: string | null
      version: string
      contacts: Array<{
        __typename?: 'CustodianContact'
        email: string
        name: string
        phone?: string | null
      }>
      platforms?: Array<{
        __typename?: 'CustodianPlatform'
        name: string
        id: string
      }> | null
      wireInstructions?: {
        __typename?: 'CustodianWireInstructions'
        accountDetails: {
          __typename?: 'CustodianAccountDetails'
          name: string
          number: string
          type: CustodianBankAccountType
          furtherCreditOptions: Array<{
            __typename?: 'CustodianFurtherCreditOption'
            accountName: string
            accountNumber: string
            optionType: CustodianFurtherCreditOptionType
          }>
        }
        bankDetails: {
          __typename?: 'CustodianBankDetails'
          city: string
          country: string
          name: string
          postalCode: string
          state: string
          street1: string
          street2?: string | null
          swiftCode?: string | null
          wireTransferCode: string
        }
      } | null
    }
  }
}

export type GetPlatformTypesQueryVariables = Exact<{ [key: string]: never }>

export type GetPlatformTypesQuery = {
  __typename?: 'Query'
  custodian: {
    __typename?: 'CustodianQueries'
    platformTypes: {
      __typename?: 'CustodianPlatformTypesResponse'
      platforms: Array<{
        __typename?: 'CustodianPlatformTypeEntry'
        id: string
        name: string
      }>
    }
  }
}

export type SearchCustodiansQueryVariables = Exact<{
  input: CustodianSearchInput
}>

export type SearchCustodiansQuery = {
  __typename?: 'Query'
  custodian: {
    __typename?: 'CustodianQueries'
    search: {
      __typename?: 'CustodianSearchResponse'
      items: Array<{
        __typename?: 'CustodianListEntry'
        id: string
        name: string
        default: boolean
      }>
      pageInfo: {
        __typename?: 'PageInfo'
        hasNextPage: boolean
        hasPreviousPage: boolean
        startCursor?: string | null
        endCursor?: string | null
      }
    }
  }
}

export type GetTeamsUsersQueryVariables = Exact<{
  teams?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>
  pagination?: InputMaybe<PaginationInput>
}>

export type GetTeamsUsersQuery = {
  __typename?: 'Query'
  users: {
    __typename?: 'UsersPageResult'
    items: Array<{
      __typename?: 'User'
      id: string
      firstName: string
      lastName: string
    }>
  }
}

export type GetUserTeamsQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type GetUserTeamsQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'User'
    parents: Array<{
      __typename?: 'HierarchyEdge'
      parentId: string
      parentType: NestedHierarchyEdgeType
      parentName?: string | null
    }>
  }
}

export type FundProductSearchQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>
  filter?: InputMaybe<FundFilterInput>
}>

export type FundProductSearchQuery = {
  __typename?: 'Query'
  fundProductSearch: {
    __typename?: 'FundProductSearchResult'
    items: Array<{
      __typename?: 'FundProduct'
      id: string
      generalInformation: {
        __typename?: 'FundGeneralInformation'
        legalName: string
      }
    }>
  }
}

export type HoiCaisIqTableFirmIdQueryVariables = Exact<{ [key: string]: never }>

export type HoiCaisIqTableFirmIdQuery = {
  __typename?: 'Query'
  userProfile: {
    __typename?: 'UserProfile'
    firm?: { __typename?: 'UserProfileFirm'; id: string } | null
  }
}

export type BulkDeleteStructuredProductsMutationVariables = Exact<{
  productsIds: Array<Scalars['ID']> | Scalars['ID']
}>

export type BulkDeleteStructuredProductsMutation = {
  __typename?: 'Mutation'
  bulkDeleteStructuredProducts: {
    __typename?: 'BulkDeleteStructuredProductsPayload'
    deletionResponse?: {
      __typename?: 'BulkDeleteStructuredProductsResponsePayload'
      failedProductIds: Array<string>
      deletedProductIds: Array<string>
    } | null
    error?:
      | { __typename?: 'MandatoryFieldValueNotPopulated'; message: string }
      | {
          __typename?: 'MultipleWsdRecordsExistForAnIdentifier'
          message: string
        }
      | { __typename?: 'StructureProductUploadError'; message: string }
      | {
          __typename?: 'StructuredProductExistsInDisabledState'
          message: string
        }
      | { __typename?: 'StructuredProductExistsInDraftState'; message: string }
      | {
          __typename?: 'StructuredProductExistsInPublishedState'
          message: string
        }
      | {
          __typename?: 'StructuredProductInvalidMetaDataError'
          message: string
        }
      | {
          __typename?: 'StructuredProductInvariantViolationError'
          message: string
        }
      | { __typename?: 'StructuredProductIssuerNotFoundError'; message: string }
      | {
          __typename?: 'StructuredProductMandatoryFieldValueError'
          message: string
        }
      | { __typename?: 'StructuredProductNameAlreadyTaken'; message: string }
      | {
          __typename?: 'StructuredProductNoEnumMappingFoundError'
          message: string
        }
      | {
          __typename?: 'StructuredProductNoIdentifierFoundError'
          message: string
        }
      | { __typename?: 'StructuredProductNotFoundError'; message: string }
      | { __typename?: 'StructuredProductPriceError'; message: string }
      | { __typename?: 'StructuredProductStaleVersionError'; message: string }
      | {
          __typename?: 'StructuredProductStructureNotFoundError'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateCannotBeResolvedError'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateMappingNotFoundError'
          message: string
        }
      | { __typename?: 'StructuredProductUnKnownMappingError'; message: string }
      | { __typename?: 'StructuredProductValidation'; message: string }
      | { __typename?: 'StructuredProductWsdParsingError'; message: string }
      | {
          __typename?: 'StructuredProductsExceededBulkCreateLimit'
          message: string
        }
      | { __typename?: 'UnSupportedIdentifierType'; message: string }
      | null
  }
}

export type CreateDraftStructuredProductMutationVariables = Exact<{
  identifierType: ProductIdentifierType
  value: Scalars['String']
}>

export type CreateDraftStructuredProductMutation = {
  __typename?: 'Mutation'
  createDraftStructuredProduct: {
    __typename?: 'CreateDraftStructuredProductPayload'
    error?:
      | { __typename?: 'MandatoryFieldValueNotPopulated'; message: string }
      | {
          __typename?: 'MultipleWsdRecordsExistForAnIdentifier'
          message: string
        }
      | { __typename?: 'StructureProductUploadError'; message: string }
      | {
          __typename?: 'StructuredProductExistsInDisabledState'
          message: string
        }
      | { __typename?: 'StructuredProductExistsInDraftState'; message: string }
      | {
          __typename?: 'StructuredProductExistsInPublishedState'
          message: string
        }
      | {
          __typename?: 'StructuredProductInvalidMetaDataError'
          message: string
        }
      | {
          __typename?: 'StructuredProductInvariantViolationError'
          message: string
        }
      | { __typename?: 'StructuredProductIssuerNotFoundError'; message: string }
      | {
          __typename?: 'StructuredProductMandatoryFieldValueError'
          message: string
        }
      | { __typename?: 'StructuredProductNameAlreadyTaken'; message: string }
      | {
          __typename?: 'StructuredProductNoEnumMappingFoundError'
          message: string
        }
      | {
          __typename?: 'StructuredProductNoIdentifierFoundError'
          message: string
        }
      | { __typename?: 'StructuredProductNotFoundError'; message: string }
      | { __typename?: 'StructuredProductPriceError'; message: string }
      | { __typename?: 'StructuredProductStaleVersionError'; message: string }
      | {
          __typename?: 'StructuredProductStructureNotFoundError'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateCannotBeResolvedError'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateMappingNotFoundError'
          message: string
        }
      | { __typename?: 'StructuredProductUnKnownMappingError'; message: string }
      | { __typename?: 'StructuredProductValidation'; message: string }
      | { __typename?: 'StructuredProductWsdParsingError'; message: string }
      | {
          __typename?: 'StructuredProductsExceededBulkCreateLimit'
          message: string
        }
      | { __typename?: 'UnSupportedIdentifierType'; message: string }
      | null
    structuredProduct?: { __typename?: 'StructuredProduct'; id: string } | null
  }
}

export type DeleteStructuredProductMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type DeleteStructuredProductMutation = {
  __typename?: 'Mutation'
  deleteStructuredProduct: {
    __typename?: 'DeleteStructuredProductPayload'
    id?: string | null
    error?:
      | { __typename?: 'MandatoryFieldValueNotPopulated'; message: string }
      | {
          __typename?: 'MultipleWsdRecordsExistForAnIdentifier'
          message: string
        }
      | { __typename?: 'StructureProductUploadError'; message: string }
      | {
          __typename?: 'StructuredProductExistsInDisabledState'
          message: string
        }
      | { __typename?: 'StructuredProductExistsInDraftState'; message: string }
      | {
          __typename?: 'StructuredProductExistsInPublishedState'
          message: string
        }
      | {
          __typename?: 'StructuredProductInvalidMetaDataError'
          message: string
        }
      | {
          __typename?: 'StructuredProductInvariantViolationError'
          message: string
        }
      | { __typename?: 'StructuredProductIssuerNotFoundError'; message: string }
      | {
          __typename?: 'StructuredProductMandatoryFieldValueError'
          message: string
        }
      | { __typename?: 'StructuredProductNameAlreadyTaken'; message: string }
      | {
          __typename?: 'StructuredProductNoEnumMappingFoundError'
          message: string
        }
      | {
          __typename?: 'StructuredProductNoIdentifierFoundError'
          message: string
        }
      | { __typename?: 'StructuredProductNotFoundError'; message: string }
      | { __typename?: 'StructuredProductPriceError'; message: string }
      | { __typename?: 'StructuredProductStaleVersionError'; message: string }
      | {
          __typename?: 'StructuredProductStructureNotFoundError'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateCannotBeResolvedError'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateMappingNotFoundError'
          message: string
        }
      | { __typename?: 'StructuredProductUnKnownMappingError'; message: string }
      | { __typename?: 'StructuredProductValidation'; message: string }
      | { __typename?: 'StructuredProductWsdParsingError'; message: string }
      | {
          __typename?: 'StructuredProductsExceededBulkCreateLimit'
          message: string
        }
      | { __typename?: 'UnSupportedIdentifierType'; message: string }
      | null
  }
}

export type FieldsToRetrieveFragment = {
  __typename?: 'StructuredProduct'
  id: string
  version: number
  initiatingSalesperson?: string | null
  brokerageAdvisoryCapacity?: BrokerageAdvisoryCapacity | null
  suppressNotifications: boolean
  isMarketplace?: boolean | null
  publishState: ProductPublishState
  caisId: string
  errors: Array<{
    __typename?: 'StructuredProductDraftError'
    code: string
    description: string
    fieldName?: string | null
  }>
  offeringType: {
    __typename?: 'StructuredProductOfferingTypeField'
    value?: StructuredProductOfferingType | null
  }
  status: {
    __typename?: 'StructuredProductStatusField'
    value?: StructuredProductStatus | null
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
    }
  }
  currencyCode: {
    __typename?: 'ProductStringField'
    value?: string | null
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
    }
  }
  prospectusType: {
    __typename?: 'StructuredProductProspectusTypeField'
    value?: StructuredProductProspectusType | null
    metadata: {
      __typename?: 'ProductFieldMetadata'
      applicability?: ProductFieldApplicability | null
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
    }
  }
  settlementType: {
    __typename?: 'StructuredProductSettlementTypeField'
    value?: StructuredProductSettlementType | null
    metadata: {
      __typename?: 'ProductFieldMetadata'
      applicability?: ProductFieldApplicability | null
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
    }
  }
  structure?: {
    __typename?: 'StructuredProductStructure'
    structureTemplateId?: string | null
    name: { __typename?: 'ProductStringField'; value?: string | null }
    subType: {
      __typename?: 'StructuredProductSubTypeField'
      value?: StructuredProductSubType | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
      }
    }
    wrapperGroup: {
      __typename?: 'StructuredProductWrapperGroupField'
      value?: StructuredProductWrapperGroup | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
      }
    }
    fdicInsured: {
      __typename?: 'ProductBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
      }
    }
    acronym: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    customName: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
  } | null
  pricingSupplementURL: {
    __typename?: 'ProductStringField'
    value?: string | null
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
      applicability?: ProductFieldApplicability | null
    }
  }
  factSheetURL?: {
    __typename?: 'ProductStringField'
    value?: string | null
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
      applicability?: ProductFieldApplicability | null
    }
  } | null
  price: Array<{ __typename?: 'ProductPrice'; value: any; valueDate: string }>
  identifiers: Array<{
    __typename?: 'ProductIdentifier'
    type: ProductIdentifierType
    value: string
  }>
  issuer: {
    __typename?: 'ProductIssuerField'
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
    }
    value?: { __typename?: 'Issuer'; legalName: string; id: string } | null
  }
  issuePrice: {
    __typename?: 'ProductBigDecimalField'
    value?: any | null
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
      applicability?: ProductFieldApplicability | null
    }
  }
  tenor: {
    __typename?: 'ProductPricingComponentField'
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
      applicability?: ProductFieldApplicability | null
    }
    value?: {
      __typename?: 'PricingComponent'
      final?: any | null
      high?: any | null
      low?: any | null
    } | null
  }
  denomination: {
    __typename?: 'ProductBigDecimalField'
    value?: any | null
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
    }
  }
  registration: {
    __typename?: 'StructuredProductRegistrationTypeField'
    value?: StructuredProductRegistrationType | null
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
    }
  }
  underlierData: {
    __typename?: 'StructuredProductUnderlierData'
    underliers: Array<{
      __typename?: 'StructuredProductUnderlier'
      ticker?: string | null
      referenceAssetId?: string | null
      legalName?: string | null
      weight?: any | null
      quanto?: boolean | null
      initialLevel?: any | null
      paymentBarrierLevel?: any | null
      autoCallLevel?: any | null
      downsideLevel?: any | null
    }>
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
    }
  }
  investmentArea: {
    __typename?: 'ProductStringField'
    value?: string | null
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
      applicability?: ProductFieldApplicability | null
    }
  }
  basketType: {
    __typename?: 'StructuredProductBasketTypeField'
    value?: StructuredProductBasketType | null
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
    }
  }
  basketName: {
    __typename?: 'ProductStringField'
    value?: string | null
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      applicability?: ProductFieldApplicability | null
    }
  }
  tradeStatus?: {
    __typename?: 'StructuredProductTradeStatusField'
    value?: StructuredProductTradeStatus | null
    metadata: {
      __typename?: 'ProductFieldMetadata'
      source: ProductFieldSource
      visibility?: ProductFieldVisibility | null
      applicability?: ProductFieldApplicability | null
    }
  } | null
  keyDates: {
    __typename?: 'StructuredProductKeyDates'
    averageIn: Array<string>
    averageOut: Array<string>
    called: {
      __typename?: 'ProductLocalDateField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    expired: {
      __typename?: 'ProductLocalDateField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    closing: {
      __typename?: 'ProductInstantField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    trade: {
      __typename?: 'ProductLocalDateField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    strike: {
      __typename?: 'ProductLocalDateField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    finalObservation: {
      __typename?: 'ProductLocalDateField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    issuance: {
      __typename?: 'ProductLocalDateField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    maturity: {
      __typename?: 'ProductLocalDateField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    filing: {
      __typename?: 'ProductLocalDateField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
  }
  growth: {
    __typename?: 'StructuredProductGrowth'
    upsideParticipation: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    maximumReturn: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    cap: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    digitalReturn: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    digitalReturnBarrier: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    upsideParticipationAboveDigital: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    lowerCallStrike: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    upperCallStrike: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    multipleFinalValuationDates?: {
      __typename?: 'StructuredProductMultipleFinalValuationDates'
      firstDate: {
        __typename?: 'ProductLocalDateField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      interval: {
        __typename?: 'ProductFrequencyField'
        value?: Frequency | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      lastDate: {
        __typename?: 'ProductLocalDateField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      style: {
        __typename?: 'StructuredProductMultipleFinalValuationDatesStyleField'
        value?: MultipleFinalValuationDatesStyle | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
    } | null
    knockOutBarrier: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    knockOutBarrierEventType: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    knockOutResult: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    knockOutBarrierEventObservationType: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    rebate: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
  }
  yield: {
    __typename?: 'StructuredProductYield'
    floatingRateAdjustmentFactor?: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    } | null
    floatingRateLeverageFactor?: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    } | null
    fixedRatePeriod?: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    } | null
    minimumInterestRate?: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    } | null
    maximumInterestRate?: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    } | null
    additionalTerms?: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    } | null
    couponType: {
      __typename?: 'StructuredProductCouponTypeField'
      value?: StructuredProductCouponType | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    couponRate: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    couponPerPeriod: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        low?: any | null
        high?: any | null
      } | null
    }
    couponPaymentFrequency: {
      __typename?: 'ProductFrequencyField'
      value?: Frequency | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    couponObservationFrequency: {
      __typename?: 'ProductFrequencyField'
      value?: Frequency | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    couponBarrier: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        low?: any | null
        high?: any | null
      } | null
    }
    couponMemory: {
      __typename?: 'ProductBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    couponSchedule: {
      __typename?: 'StructuredProductYieldCouponSchedule'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: Array<{
        __typename?: 'StructuredProductCouponSchedule'
        barrierLevel?: any | null
        observationDate?: string | null
        outcome?: StructuredProductCouponOutcome | null
        periodRate?: any | null
        settlementDate?: string | null
      }> | null
    }
  }
  downside: {
    __typename?: 'StructuredProductDownside'
    downsideProtectionType: {
      __typename?: 'StructuredProductDownsideProtectionTypeField'
      value?: DownsideProtectionType | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    observationFrequency: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    downsideGearing: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    absoluteReturnBarrier: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    threshold: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        applicability?: ProductFieldApplicability | null
        visibility?: ProductFieldVisibility | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    absoluteReturnParticipation: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    putStrikeLevel: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    barrierType: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    bufferLevel: {
      __typename?: 'ProductPricingComponentField'
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    knockInBarrier: {
      __typename?: 'ProductPricingComponentField'
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    knockInBarrierEventType: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    knockInBarrierObservationType: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
  }
  callable: {
    __typename?: 'StructuredProductCallable'
    callFirstDate: {
      __typename?: 'ProductLocalDateField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    callType: {
      __typename?: 'StructuredProductCallTypeField'
      value?: StructuredProductCallType | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    callFrequency: {
      __typename?: 'ProductFrequencyField'
      value?: Frequency | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    nonCallPeriod: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    autoCallBarrier: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    callPremium: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    callHurdle: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    schedule: {
      __typename?: 'StructuredProductCallableScheduleField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: Array<{
        __typename?: 'StructuredProductCallSchedule'
        barrierLevel?: any | null
        observationDate?: string | null
        outcome?: StructuredProductCallObservationOutcome | null
        paymentDate?: string | null
        premium?: any | null
      }> | null
    }
  }
  miscellaneous: {
    __typename?: 'StructuredProductMisc'
    notionalCAIS: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        applicability?: ProductFieldApplicability | null
      }
    }
    notionalOffering: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    feeCAIS: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        applicability?: ProductFieldApplicability | null
      }
    }
    feeCustodian: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        applicability?: ProductFieldApplicability | null
      }
    }
    feeOther: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        applicability?: ProductFieldApplicability | null
      }
    }
    proceedsToIssuer: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    staticReturn: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        applicability?: ProductFieldApplicability | null
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
      }
    }
    finalRedemptionPerformance: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        visibility?: ProductFieldVisibility | null
        source: ProductFieldSource
        applicability?: ProductFieldApplicability | null
      }
    }
    estimatedValue: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    feeSalesConcession: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
  }
}

export type DraftStructuredProductUploadQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type DraftStructuredProductUploadQuery = {
  __typename?: 'Query'
  draftStructuredProductUpload: {
    __typename?: 'StructuredProductDraftUpload'
    status: StructuredProductUploadStatus
  }
}

export type DraftStructuredProductUploadPublishAllStatusQueryVariables = Exact<{
  id: Scalars['ID']
  productUploadId: Scalars['ID']
}>

export type DraftStructuredProductUploadPublishAllStatusQuery = {
  __typename?: 'Query'
  draftStructuredProductUploadPublishAllStatus: {
    __typename?: 'StructuredProductDraftPublishAll'
    status: StructuredProductUploadStatus
  }
}

export type DraftStructuredProductUploadResultsQueryVariables = Exact<{
  id: Scalars['ID']
  filter?: InputMaybe<DraftUploadResultFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<DraftUploadResultSort> | DraftUploadResultSort>
}>

export type DraftStructuredProductUploadResultsQuery = {
  __typename?: 'Query'
  draftStructuredProductUploadResults: {
    __typename?: 'StructuredProductDraftUploadPageResult'
    items: Array<{
      __typename?: 'StructuredProductDraftUploadResult'
      id: string
      productIdentifier: string
      productUploadId: string
      errorCode?: StructuredProductErrorCode | null
      reason?: string | null
      status: StructuredProductUploadStatus
      version: number
      structuredProduct?: {
        __typename?: 'StructuredProduct'
        id: string
        version: number
        initiatingSalesperson?: string | null
        brokerageAdvisoryCapacity?: BrokerageAdvisoryCapacity | null
        suppressNotifications: boolean
        isMarketplace?: boolean | null
        publishState: ProductPublishState
        caisId: string
        errors: Array<{
          __typename?: 'StructuredProductDraftError'
          code: string
          description: string
          fieldName?: string | null
        }>
        offeringType: {
          __typename?: 'StructuredProductOfferingTypeField'
          value?: StructuredProductOfferingType | null
        }
        status: {
          __typename?: 'StructuredProductStatusField'
          value?: StructuredProductStatus | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        currencyCode: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        prospectusType: {
          __typename?: 'StructuredProductProspectusTypeField'
          value?: StructuredProductProspectusType | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            applicability?: ProductFieldApplicability | null
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        settlementType: {
          __typename?: 'StructuredProductSettlementTypeField'
          value?: StructuredProductSettlementType | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            applicability?: ProductFieldApplicability | null
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        structure?: {
          __typename?: 'StructuredProductStructure'
          structureTemplateId?: string | null
          name: { __typename?: 'ProductStringField'; value?: string | null }
          subType: {
            __typename?: 'StructuredProductSubTypeField'
            value?: StructuredProductSubType | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
            }
          }
          wrapperGroup: {
            __typename?: 'StructuredProductWrapperGroupField'
            value?: StructuredProductWrapperGroup | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
            }
          }
          fdicInsured: {
            __typename?: 'ProductBooleanField'
            value?: boolean | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
            }
          }
          acronym: {
            __typename?: 'ProductStringField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          customName: {
            __typename?: 'ProductStringField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
        } | null
        pricingSupplementURL: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        factSheetURL?: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        price: Array<{
          __typename?: 'ProductPrice'
          value: any
          valueDate: string
        }>
        identifiers: Array<{
          __typename?: 'ProductIdentifier'
          type: ProductIdentifierType
          value: string
        }>
        issuer: {
          __typename?: 'ProductIssuerField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
          value?: {
            __typename?: 'Issuer'
            legalName: string
            id: string
          } | null
        }
        issuePrice: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        tenor: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        denomination: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        registration: {
          __typename?: 'StructuredProductRegistrationTypeField'
          value?: StructuredProductRegistrationType | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        underlierData: {
          __typename?: 'StructuredProductUnderlierData'
          underliers: Array<{
            __typename?: 'StructuredProductUnderlier'
            ticker?: string | null
            referenceAssetId?: string | null
            legalName?: string | null
            weight?: any | null
            quanto?: boolean | null
            initialLevel?: any | null
            paymentBarrierLevel?: any | null
            autoCallLevel?: any | null
            downsideLevel?: any | null
          }>
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        investmentArea: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        basketType: {
          __typename?: 'StructuredProductBasketTypeField'
          value?: StructuredProductBasketType | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        basketName: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            applicability?: ProductFieldApplicability | null
          }
        }
        tradeStatus?: {
          __typename?: 'StructuredProductTradeStatusField'
          value?: StructuredProductTradeStatus | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        keyDates: {
          __typename?: 'StructuredProductKeyDates'
          averageIn: Array<string>
          averageOut: Array<string>
          called: {
            __typename?: 'ProductLocalDateField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          expired: {
            __typename?: 'ProductLocalDateField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          closing: {
            __typename?: 'ProductInstantField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          trade: {
            __typename?: 'ProductLocalDateField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          strike: {
            __typename?: 'ProductLocalDateField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          finalObservation: {
            __typename?: 'ProductLocalDateField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          issuance: {
            __typename?: 'ProductLocalDateField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          maturity: {
            __typename?: 'ProductLocalDateField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          filing: {
            __typename?: 'ProductLocalDateField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
        }
        growth: {
          __typename?: 'StructuredProductGrowth'
          upsideParticipation: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          maximumReturn: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          cap: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          digitalReturn: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          digitalReturnBarrier: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          upsideParticipationAboveDigital: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          lowerCallStrike: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          upperCallStrike: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          multipleFinalValuationDates?: {
            __typename?: 'StructuredProductMultipleFinalValuationDates'
            firstDate: {
              __typename?: 'ProductLocalDateField'
              value?: string | null
              metadata: {
                __typename?: 'ProductFieldMetadata'
                source: ProductFieldSource
                visibility?: ProductFieldVisibility | null
                applicability?: ProductFieldApplicability | null
              }
            }
            interval: {
              __typename?: 'ProductFrequencyField'
              value?: Frequency | null
              metadata: {
                __typename?: 'ProductFieldMetadata'
                source: ProductFieldSource
                visibility?: ProductFieldVisibility | null
                applicability?: ProductFieldApplicability | null
              }
            }
            lastDate: {
              __typename?: 'ProductLocalDateField'
              value?: string | null
              metadata: {
                __typename?: 'ProductFieldMetadata'
                source: ProductFieldSource
                visibility?: ProductFieldVisibility | null
                applicability?: ProductFieldApplicability | null
              }
            }
            style: {
              __typename?: 'StructuredProductMultipleFinalValuationDatesStyleField'
              value?: MultipleFinalValuationDatesStyle | null
              metadata: {
                __typename?: 'ProductFieldMetadata'
                source: ProductFieldSource
                visibility?: ProductFieldVisibility | null
                applicability?: ProductFieldApplicability | null
              }
            }
          } | null
          knockOutBarrier: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          knockOutBarrierEventType: {
            __typename?: 'ProductStringField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          knockOutResult: {
            __typename?: 'ProductStringField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          knockOutBarrierEventObservationType: {
            __typename?: 'ProductStringField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          rebate: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
        }
        yield: {
          __typename?: 'StructuredProductYield'
          floatingRateAdjustmentFactor?: {
            __typename?: 'ProductBigDecimalField'
            value?: any | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          } | null
          floatingRateLeverageFactor?: {
            __typename?: 'ProductBigDecimalField'
            value?: any | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          } | null
          fixedRatePeriod?: {
            __typename?: 'ProductBigDecimalField'
            value?: any | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          } | null
          minimumInterestRate?: {
            __typename?: 'ProductBigDecimalField'
            value?: any | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          } | null
          maximumInterestRate?: {
            __typename?: 'ProductBigDecimalField'
            value?: any | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          } | null
          additionalTerms?: {
            __typename?: 'ProductStringField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          } | null
          couponType: {
            __typename?: 'StructuredProductCouponTypeField'
            value?: StructuredProductCouponType | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          couponRate: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          couponPerPeriod: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              low?: any | null
              high?: any | null
            } | null
          }
          couponPaymentFrequency: {
            __typename?: 'ProductFrequencyField'
            value?: Frequency | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          couponObservationFrequency: {
            __typename?: 'ProductFrequencyField'
            value?: Frequency | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          couponBarrier: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              low?: any | null
              high?: any | null
            } | null
          }
          couponMemory: {
            __typename?: 'ProductBooleanField'
            value?: boolean | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          couponSchedule: {
            __typename?: 'StructuredProductYieldCouponSchedule'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: Array<{
              __typename?: 'StructuredProductCouponSchedule'
              barrierLevel?: any | null
              observationDate?: string | null
              outcome?: StructuredProductCouponOutcome | null
              periodRate?: any | null
              settlementDate?: string | null
            }> | null
          }
        }
        downside: {
          __typename?: 'StructuredProductDownside'
          downsideProtectionType: {
            __typename?: 'StructuredProductDownsideProtectionTypeField'
            value?: DownsideProtectionType | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          observationFrequency: {
            __typename?: 'ProductStringField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          downsideGearing: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          absoluteReturnBarrier: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          threshold: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              applicability?: ProductFieldApplicability | null
              visibility?: ProductFieldVisibility | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          absoluteReturnParticipation: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          putStrikeLevel: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          barrierType: {
            __typename?: 'ProductStringField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          bufferLevel: {
            __typename?: 'ProductPricingComponentField'
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          knockInBarrier: {
            __typename?: 'ProductPricingComponentField'
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          knockInBarrierEventType: {
            __typename?: 'ProductStringField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          knockInBarrierObservationType: {
            __typename?: 'ProductStringField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
        }
        callable: {
          __typename?: 'StructuredProductCallable'
          callFirstDate: {
            __typename?: 'ProductLocalDateField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          callType: {
            __typename?: 'StructuredProductCallTypeField'
            value?: StructuredProductCallType | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          callFrequency: {
            __typename?: 'ProductFrequencyField'
            value?: Frequency | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          nonCallPeriod: {
            __typename?: 'ProductBigDecimalField'
            value?: any | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          autoCallBarrier: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          callPremium: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          callHurdle: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          schedule: {
            __typename?: 'StructuredProductCallableScheduleField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: Array<{
              __typename?: 'StructuredProductCallSchedule'
              barrierLevel?: any | null
              observationDate?: string | null
              outcome?: StructuredProductCallObservationOutcome | null
              paymentDate?: string | null
              premium?: any | null
            }> | null
          }
        }
        miscellaneous: {
          __typename?: 'StructuredProductMisc'
          notionalCAIS: {
            __typename?: 'ProductBigDecimalField'
            value?: any | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              applicability?: ProductFieldApplicability | null
            }
          }
          notionalOffering: {
            __typename?: 'ProductBigDecimalField'
            value?: any | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          feeCAIS: {
            __typename?: 'ProductBigDecimalField'
            value?: any | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              applicability?: ProductFieldApplicability | null
            }
          }
          feeCustodian: {
            __typename?: 'ProductBigDecimalField'
            value?: any | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              applicability?: ProductFieldApplicability | null
            }
          }
          feeOther: {
            __typename?: 'ProductBigDecimalField'
            value?: any | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              applicability?: ProductFieldApplicability | null
            }
          }
          proceedsToIssuer: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          staticReturn: {
            __typename?: 'ProductBigDecimalField'
            value?: any | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              applicability?: ProductFieldApplicability | null
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
            }
          }
          finalRedemptionPerformance: {
            __typename?: 'ProductBigDecimalField'
            value?: any | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              visibility?: ProductFieldVisibility | null
              source: ProductFieldSource
              applicability?: ProductFieldApplicability | null
            }
          }
          estimatedValue: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
          feeSalesConcession: {
            __typename?: 'ProductPricingComponentField'
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
            value?: {
              __typename?: 'PricingComponent'
              final?: any | null
              high?: any | null
              low?: any | null
            } | null
          }
        }
      } | null
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      startCursor?: string | null
      endCursor?: string | null
      hasNextPage: boolean
      hasPreviousPage: boolean
    }
  }
}

export type DraftStructuredProductUploadsQueryVariables = Exact<{
  filter?: InputMaybe<DraftUploadFilterInput>
  pagination?: InputMaybe<PaginationInput>
  sort?: InputMaybe<Array<DraftUploadSort> | DraftUploadSort>
}>

export type DraftStructuredProductUploadsQuery = {
  __typename?: 'Query'
  draftStructuredProductUploads: {
    __typename?: 'StructuredProductDraftUploadPage'
    items: Array<{
      __typename?: 'StructuredProductDraftUpload'
      id: string
      fileName: string
      createdBy: string
      createdAt: string
      status: StructuredProductUploadStatus
      createdByUser?: { __typename?: 'UserLite'; email: string } | null
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      endCursor?: string | null
      hasNextPage: boolean
    }
  }
}

export type GetStructuredProductsQueryVariables = Exact<{
  sort?: InputMaybe<Array<StructuredProductSort> | StructuredProductSort>
  filter?: InputMaybe<StructuredProductFilterInput>
  pagination?: InputMaybe<PaginationInput>
}>

export type GetStructuredProductsQuery = {
  __typename?: 'Query'
  structuredProducts: {
    __typename?: 'StructuredProductPageResult'
    items: Array<{
      __typename?: 'StructuredProduct'
      id: string
      version: number
      caisId: string
      publishState: ProductPublishState
      basketName: { __typename?: 'ProductStringField'; value?: string | null }
      errors: Array<{
        __typename?: 'StructuredProductDraftError'
        code: string
      }>
      basketType: {
        __typename?: 'StructuredProductBasketTypeField'
        value?: StructuredProductBasketType | null
      }
      underlierData: {
        __typename?: 'StructuredProductUnderlierData'
        underliers: Array<{
          __typename?: 'StructuredProductUnderlier'
          ticker?: string | null
          legalName?: string | null
          weight?: any | null
        }>
      }
      issuer: {
        __typename?: 'ProductIssuerField'
        value?: {
          __typename?: 'Issuer'
          acronym: string
          logoURLs?: { __typename?: 'LogoURL'; thumbnail: string } | null
        } | null
      }
      structure?: {
        __typename?: 'StructuredProductStructure'
        name: { __typename?: 'ProductStringField'; value?: string | null }
        subType: {
          __typename?: 'StructuredProductSubTypeField'
          value?: StructuredProductSubType | null
        }
      } | null
      identifiers: Array<{
        __typename?: 'ProductIdentifier'
        type: ProductIdentifierType
        value: string
      }>
      tenor: {
        __typename?: 'ProductPricingComponentField'
        value?: { __typename?: 'PricingComponent'; final?: any | null } | null
      }
      keyDates: {
        __typename?: 'StructuredProductKeyDates'
        trade: { __typename?: 'ProductLocalDateField'; value?: string | null }
        closing: { __typename?: 'ProductInstantField'; value?: string | null }
      }
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
      startCursor?: string | null
    }
  }
}

export type GetStructuredProductQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetStructuredProductQuery = {
  __typename?: 'Query'
  structuredProduct?: {
    __typename?: 'StructuredProduct'
    id: string
    version: number
    initiatingSalesperson?: string | null
    brokerageAdvisoryCapacity?: BrokerageAdvisoryCapacity | null
    suppressNotifications: boolean
    isMarketplace?: boolean | null
    publishState: ProductPublishState
    caisId: string
    errors: Array<{
      __typename?: 'StructuredProductDraftError'
      code: string
      description: string
      fieldName?: string | null
    }>
    offeringType: {
      __typename?: 'StructuredProductOfferingTypeField'
      value?: StructuredProductOfferingType | null
    }
    status: {
      __typename?: 'StructuredProductStatusField'
      value?: StructuredProductStatus | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
      }
    }
    currencyCode: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
      }
    }
    prospectusType: {
      __typename?: 'StructuredProductProspectusTypeField'
      value?: StructuredProductProspectusType | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        applicability?: ProductFieldApplicability | null
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
      }
    }
    settlementType: {
      __typename?: 'StructuredProductSettlementTypeField'
      value?: StructuredProductSettlementType | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        applicability?: ProductFieldApplicability | null
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
      }
    }
    structure?: {
      __typename?: 'StructuredProductStructure'
      structureTemplateId?: string | null
      name: { __typename?: 'ProductStringField'; value?: string | null }
      subType: {
        __typename?: 'StructuredProductSubTypeField'
        value?: StructuredProductSubType | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      wrapperGroup: {
        __typename?: 'StructuredProductWrapperGroupField'
        value?: StructuredProductWrapperGroup | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      fdicInsured: {
        __typename?: 'ProductBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      acronym: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      customName: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
    } | null
    pricingSupplementURL: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    factSheetURL?: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    } | null
    price: Array<{ __typename?: 'ProductPrice'; value: any; valueDate: string }>
    identifiers: Array<{
      __typename?: 'ProductIdentifier'
      type: ProductIdentifierType
      value: string
    }>
    issuer: {
      __typename?: 'ProductIssuerField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
      }
      value?: { __typename?: 'Issuer'; legalName: string; id: string } | null
    }
    issuePrice: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    tenor: {
      __typename?: 'ProductPricingComponentField'
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
      value?: {
        __typename?: 'PricingComponent'
        final?: any | null
        high?: any | null
        low?: any | null
      } | null
    }
    denomination: {
      __typename?: 'ProductBigDecimalField'
      value?: any | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
      }
    }
    registration: {
      __typename?: 'StructuredProductRegistrationTypeField'
      value?: StructuredProductRegistrationType | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
      }
    }
    underlierData: {
      __typename?: 'StructuredProductUnderlierData'
      underliers: Array<{
        __typename?: 'StructuredProductUnderlier'
        ticker?: string | null
        referenceAssetId?: string | null
        legalName?: string | null
        weight?: any | null
        quanto?: boolean | null
        initialLevel?: any | null
        paymentBarrierLevel?: any | null
        autoCallLevel?: any | null
        downsideLevel?: any | null
      }>
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
      }
    }
    investmentArea: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    }
    basketType: {
      __typename?: 'StructuredProductBasketTypeField'
      value?: StructuredProductBasketType | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
      }
    }
    basketName: {
      __typename?: 'ProductStringField'
      value?: string | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        applicability?: ProductFieldApplicability | null
      }
    }
    tradeStatus?: {
      __typename?: 'StructuredProductTradeStatusField'
      value?: StructuredProductTradeStatus | null
      metadata: {
        __typename?: 'ProductFieldMetadata'
        source: ProductFieldSource
        visibility?: ProductFieldVisibility | null
        applicability?: ProductFieldApplicability | null
      }
    } | null
    keyDates: {
      __typename?: 'StructuredProductKeyDates'
      averageIn: Array<string>
      averageOut: Array<string>
      called: {
        __typename?: 'ProductLocalDateField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      expired: {
        __typename?: 'ProductLocalDateField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      closing: {
        __typename?: 'ProductInstantField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      trade: {
        __typename?: 'ProductLocalDateField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      strike: {
        __typename?: 'ProductLocalDateField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      finalObservation: {
        __typename?: 'ProductLocalDateField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      issuance: {
        __typename?: 'ProductLocalDateField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      maturity: {
        __typename?: 'ProductLocalDateField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      filing: {
        __typename?: 'ProductLocalDateField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
    }
    growth: {
      __typename?: 'StructuredProductGrowth'
      upsideParticipation: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      maximumReturn: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      cap: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      digitalReturn: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      digitalReturnBarrier: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      upsideParticipationAboveDigital: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      lowerCallStrike: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      upperCallStrike: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      multipleFinalValuationDates?: {
        __typename?: 'StructuredProductMultipleFinalValuationDates'
        firstDate: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        interval: {
          __typename?: 'ProductFrequencyField'
          value?: Frequency | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        lastDate: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        style: {
          __typename?: 'StructuredProductMultipleFinalValuationDatesStyleField'
          value?: MultipleFinalValuationDatesStyle | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
      } | null
      knockOutBarrier: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      knockOutBarrierEventType: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      knockOutResult: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      knockOutBarrierEventObservationType: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      rebate: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
    }
    yield: {
      __typename?: 'StructuredProductYield'
      floatingRateAdjustmentFactor?: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      } | null
      floatingRateLeverageFactor?: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      } | null
      fixedRatePeriod?: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      } | null
      minimumInterestRate?: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      } | null
      maximumInterestRate?: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      } | null
      additionalTerms?: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      } | null
      couponType: {
        __typename?: 'StructuredProductCouponTypeField'
        value?: StructuredProductCouponType | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      couponRate: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      couponPerPeriod: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          low?: any | null
          high?: any | null
        } | null
      }
      couponPaymentFrequency: {
        __typename?: 'ProductFrequencyField'
        value?: Frequency | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      couponObservationFrequency: {
        __typename?: 'ProductFrequencyField'
        value?: Frequency | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      couponBarrier: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          low?: any | null
          high?: any | null
        } | null
      }
      couponMemory: {
        __typename?: 'ProductBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      couponSchedule: {
        __typename?: 'StructuredProductYieldCouponSchedule'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: Array<{
          __typename?: 'StructuredProductCouponSchedule'
          barrierLevel?: any | null
          observationDate?: string | null
          outcome?: StructuredProductCouponOutcome | null
          periodRate?: any | null
          settlementDate?: string | null
        }> | null
      }
    }
    downside: {
      __typename?: 'StructuredProductDownside'
      downsideProtectionType: {
        __typename?: 'StructuredProductDownsideProtectionTypeField'
        value?: DownsideProtectionType | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      observationFrequency: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      downsideGearing: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      absoluteReturnBarrier: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      threshold: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          applicability?: ProductFieldApplicability | null
          visibility?: ProductFieldVisibility | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      absoluteReturnParticipation: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      putStrikeLevel: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      barrierType: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      bufferLevel: {
        __typename?: 'ProductPricingComponentField'
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      knockInBarrier: {
        __typename?: 'ProductPricingComponentField'
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      knockInBarrierEventType: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      knockInBarrierObservationType: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
    }
    callable: {
      __typename?: 'StructuredProductCallable'
      callFirstDate: {
        __typename?: 'ProductLocalDateField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      callType: {
        __typename?: 'StructuredProductCallTypeField'
        value?: StructuredProductCallType | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      callFrequency: {
        __typename?: 'ProductFrequencyField'
        value?: Frequency | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      nonCallPeriod: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      autoCallBarrier: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      callPremium: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      callHurdle: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      schedule: {
        __typename?: 'StructuredProductCallableScheduleField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: Array<{
          __typename?: 'StructuredProductCallSchedule'
          barrierLevel?: any | null
          observationDate?: string | null
          outcome?: StructuredProductCallObservationOutcome | null
          paymentDate?: string | null
          premium?: any | null
        }> | null
      }
    }
    miscellaneous: {
      __typename?: 'StructuredProductMisc'
      notionalCAIS: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          applicability?: ProductFieldApplicability | null
        }
      }
      notionalOffering: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      feeCAIS: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          applicability?: ProductFieldApplicability | null
        }
      }
      feeCustodian: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          applicability?: ProductFieldApplicability | null
        }
      }
      feeOther: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          applicability?: ProductFieldApplicability | null
        }
      }
      proceedsToIssuer: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      staticReturn: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          applicability?: ProductFieldApplicability | null
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      finalRedemptionPerformance: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          visibility?: ProductFieldVisibility | null
          source: ProductFieldSource
          applicability?: ProductFieldApplicability | null
        }
      }
      estimatedValue: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      feeSalesConcession: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
    }
  } | null
}

export type GetStructuredProductsForFormQueryVariables = Exact<{
  filter?: InputMaybe<StructuredProductFilterInput>
}>

export type GetStructuredProductsForFormQuery = {
  __typename?: 'Query'
  structuredProducts: {
    __typename?: 'StructuredProductPageResult'
    items: Array<{
      __typename?: 'StructuredProduct'
      id: string
      publishState: ProductPublishState
    }>
  }
}

export type PublishDraftStructuredProductMutationVariables = Exact<{
  id: Scalars['ID']
  version: Scalars['Int']
}>

export type PublishDraftStructuredProductMutation = {
  __typename?: 'Mutation'
  publishDraftStructuredProduct: {
    __typename?: 'PublishDraftStructuredProductPayload'
    structuredProduct?: {
      __typename?: 'StructuredProduct'
      id: string
      version: number
      initiatingSalesperson?: string | null
      brokerageAdvisoryCapacity?: BrokerageAdvisoryCapacity | null
      suppressNotifications: boolean
      isMarketplace?: boolean | null
      publishState: ProductPublishState
      caisId: string
      errors: Array<{
        __typename?: 'StructuredProductDraftError'
        code: string
        description: string
        fieldName?: string | null
      }>
      offeringType: {
        __typename?: 'StructuredProductOfferingTypeField'
        value?: StructuredProductOfferingType | null
      }
      status: {
        __typename?: 'StructuredProductStatusField'
        value?: StructuredProductStatus | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      currencyCode: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      prospectusType: {
        __typename?: 'StructuredProductProspectusTypeField'
        value?: StructuredProductProspectusType | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          applicability?: ProductFieldApplicability | null
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      settlementType: {
        __typename?: 'StructuredProductSettlementTypeField'
        value?: StructuredProductSettlementType | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          applicability?: ProductFieldApplicability | null
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      structure?: {
        __typename?: 'StructuredProductStructure'
        structureTemplateId?: string | null
        name: { __typename?: 'ProductStringField'; value?: string | null }
        subType: {
          __typename?: 'StructuredProductSubTypeField'
          value?: StructuredProductSubType | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        wrapperGroup: {
          __typename?: 'StructuredProductWrapperGroupField'
          value?: StructuredProductWrapperGroup | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        fdicInsured: {
          __typename?: 'ProductBooleanField'
          value?: boolean | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        acronym: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        customName: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
      } | null
      pricingSupplementURL: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      factSheetURL?: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      } | null
      price: Array<{
        __typename?: 'ProductPrice'
        value: any
        valueDate: string
      }>
      identifiers: Array<{
        __typename?: 'ProductIdentifier'
        type: ProductIdentifierType
        value: string
      }>
      issuer: {
        __typename?: 'ProductIssuerField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
        value?: { __typename?: 'Issuer'; legalName: string; id: string } | null
      }
      issuePrice: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      tenor: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      denomination: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      registration: {
        __typename?: 'StructuredProductRegistrationTypeField'
        value?: StructuredProductRegistrationType | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      underlierData: {
        __typename?: 'StructuredProductUnderlierData'
        underliers: Array<{
          __typename?: 'StructuredProductUnderlier'
          ticker?: string | null
          referenceAssetId?: string | null
          legalName?: string | null
          weight?: any | null
          quanto?: boolean | null
          initialLevel?: any | null
          paymentBarrierLevel?: any | null
          autoCallLevel?: any | null
          downsideLevel?: any | null
        }>
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      investmentArea: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      basketType: {
        __typename?: 'StructuredProductBasketTypeField'
        value?: StructuredProductBasketType | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      basketName: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          applicability?: ProductFieldApplicability | null
        }
      }
      tradeStatus?: {
        __typename?: 'StructuredProductTradeStatusField'
        value?: StructuredProductTradeStatus | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      } | null
      keyDates: {
        __typename?: 'StructuredProductKeyDates'
        averageIn: Array<string>
        averageOut: Array<string>
        called: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        expired: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        closing: {
          __typename?: 'ProductInstantField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        trade: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        strike: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        finalObservation: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        issuance: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        maturity: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        filing: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
      }
      growth: {
        __typename?: 'StructuredProductGrowth'
        upsideParticipation: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        maximumReturn: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        cap: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        digitalReturn: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        digitalReturnBarrier: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        upsideParticipationAboveDigital: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        lowerCallStrike: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        upperCallStrike: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        multipleFinalValuationDates?: {
          __typename?: 'StructuredProductMultipleFinalValuationDates'
          firstDate: {
            __typename?: 'ProductLocalDateField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          interval: {
            __typename?: 'ProductFrequencyField'
            value?: Frequency | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          lastDate: {
            __typename?: 'ProductLocalDateField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          style: {
            __typename?: 'StructuredProductMultipleFinalValuationDatesStyleField'
            value?: MultipleFinalValuationDatesStyle | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
        } | null
        knockOutBarrier: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        knockOutBarrierEventType: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        knockOutResult: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        knockOutBarrierEventObservationType: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        rebate: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
      }
      yield: {
        __typename?: 'StructuredProductYield'
        floatingRateAdjustmentFactor?: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        floatingRateLeverageFactor?: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        fixedRatePeriod?: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        minimumInterestRate?: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        maximumInterestRate?: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        additionalTerms?: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        couponType: {
          __typename?: 'StructuredProductCouponTypeField'
          value?: StructuredProductCouponType | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        couponRate: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        couponPerPeriod: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            low?: any | null
            high?: any | null
          } | null
        }
        couponPaymentFrequency: {
          __typename?: 'ProductFrequencyField'
          value?: Frequency | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        couponObservationFrequency: {
          __typename?: 'ProductFrequencyField'
          value?: Frequency | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        couponBarrier: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            low?: any | null
            high?: any | null
          } | null
        }
        couponMemory: {
          __typename?: 'ProductBooleanField'
          value?: boolean | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        couponSchedule: {
          __typename?: 'StructuredProductYieldCouponSchedule'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: Array<{
            __typename?: 'StructuredProductCouponSchedule'
            barrierLevel?: any | null
            observationDate?: string | null
            outcome?: StructuredProductCouponOutcome | null
            periodRate?: any | null
            settlementDate?: string | null
          }> | null
        }
      }
      downside: {
        __typename?: 'StructuredProductDownside'
        downsideProtectionType: {
          __typename?: 'StructuredProductDownsideProtectionTypeField'
          value?: DownsideProtectionType | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        observationFrequency: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        downsideGearing: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        absoluteReturnBarrier: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        threshold: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            applicability?: ProductFieldApplicability | null
            visibility?: ProductFieldVisibility | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        absoluteReturnParticipation: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        putStrikeLevel: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        barrierType: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        bufferLevel: {
          __typename?: 'ProductPricingComponentField'
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        knockInBarrier: {
          __typename?: 'ProductPricingComponentField'
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        knockInBarrierEventType: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        knockInBarrierObservationType: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
      }
      callable: {
        __typename?: 'StructuredProductCallable'
        callFirstDate: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        callType: {
          __typename?: 'StructuredProductCallTypeField'
          value?: StructuredProductCallType | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        callFrequency: {
          __typename?: 'ProductFrequencyField'
          value?: Frequency | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        nonCallPeriod: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        autoCallBarrier: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        callPremium: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        callHurdle: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        schedule: {
          __typename?: 'StructuredProductCallableScheduleField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: Array<{
            __typename?: 'StructuredProductCallSchedule'
            barrierLevel?: any | null
            observationDate?: string | null
            outcome?: StructuredProductCallObservationOutcome | null
            paymentDate?: string | null
            premium?: any | null
          }> | null
        }
      }
      miscellaneous: {
        __typename?: 'StructuredProductMisc'
        notionalCAIS: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            applicability?: ProductFieldApplicability | null
          }
        }
        notionalOffering: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        feeCAIS: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            applicability?: ProductFieldApplicability | null
          }
        }
        feeCustodian: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            applicability?: ProductFieldApplicability | null
          }
        }
        feeOther: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            applicability?: ProductFieldApplicability | null
          }
        }
        proceedsToIssuer: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        staticReturn: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            applicability?: ProductFieldApplicability | null
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        finalRedemptionPerformance: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            visibility?: ProductFieldVisibility | null
            source: ProductFieldSource
            applicability?: ProductFieldApplicability | null
          }
        }
        estimatedValue: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        feeSalesConcession: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
      }
    } | null
    error?:
      | { __typename?: 'MandatoryFieldValueNotPopulated'; message: string }
      | {
          __typename?: 'MultipleWsdRecordsExistForAnIdentifier'
          message: string
        }
      | { __typename?: 'StructureProductUploadError'; message: string }
      | {
          __typename?: 'StructuredProductExistsInDisabledState'
          message: string
        }
      | { __typename?: 'StructuredProductExistsInDraftState'; message: string }
      | {
          __typename?: 'StructuredProductExistsInPublishedState'
          message: string
        }
      | {
          __typename?: 'StructuredProductInvalidMetaDataError'
          message: string
        }
      | {
          __typename?: 'StructuredProductInvariantViolationError'
          message: string
        }
      | { __typename?: 'StructuredProductIssuerNotFoundError'; message: string }
      | {
          __typename?: 'StructuredProductMandatoryFieldValueError'
          message: string
        }
      | { __typename?: 'StructuredProductNameAlreadyTaken'; message: string }
      | {
          __typename?: 'StructuredProductNoEnumMappingFoundError'
          message: string
        }
      | {
          __typename?: 'StructuredProductNoIdentifierFoundError'
          message: string
        }
      | { __typename?: 'StructuredProductNotFoundError'; message: string }
      | { __typename?: 'StructuredProductPriceError'; message: string }
      | { __typename?: 'StructuredProductStaleVersionError'; message: string }
      | {
          __typename?: 'StructuredProductStructureNotFoundError'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateCannotBeResolvedError'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateMappingNotFoundError'
          message: string
        }
      | { __typename?: 'StructuredProductUnKnownMappingError'; message: string }
      | { __typename?: 'StructuredProductValidation'; message: string }
      | { __typename?: 'StructuredProductWsdParsingError'; message: string }
      | {
          __typename?: 'StructuredProductsExceededBulkCreateLimit'
          message: string
        }
      | { __typename?: 'UnSupportedIdentifierType'; message: string }
      | null
  }
}

export type PublishDraftStructuredProductsMutationVariables = Exact<{
  productUploadId: Scalars['ID']
  input: DraftUploadPublishAllInput
}>

export type PublishDraftStructuredProductsMutation = {
  __typename?: 'Mutation'
  publishDraftStructuredProducts: {
    __typename?: 'StructuredProductDraftPublishAllPayload'
    result?: {
      __typename?: 'StructuredProductDraftPublishAll'
      id: string
      productUploadId: string
    } | null
    error?:
      | { __typename?: 'MandatoryFieldValueNotPopulated'; message: string }
      | {
          __typename?: 'MultipleWsdRecordsExistForAnIdentifier'
          message: string
        }
      | { __typename?: 'StructureProductUploadError'; message: string }
      | {
          __typename?: 'StructuredProductExistsInDisabledState'
          message: string
        }
      | { __typename?: 'StructuredProductExistsInDraftState'; message: string }
      | {
          __typename?: 'StructuredProductExistsInPublishedState'
          message: string
        }
      | {
          __typename?: 'StructuredProductInvalidMetaDataError'
          message: string
        }
      | {
          __typename?: 'StructuredProductInvariantViolationError'
          message: string
        }
      | { __typename?: 'StructuredProductIssuerNotFoundError'; message: string }
      | {
          __typename?: 'StructuredProductMandatoryFieldValueError'
          message: string
        }
      | { __typename?: 'StructuredProductNameAlreadyTaken'; message: string }
      | {
          __typename?: 'StructuredProductNoEnumMappingFoundError'
          message: string
        }
      | {
          __typename?: 'StructuredProductNoIdentifierFoundError'
          message: string
        }
      | { __typename?: 'StructuredProductNotFoundError'; message: string }
      | { __typename?: 'StructuredProductPriceError'; message: string }
      | { __typename?: 'StructuredProductStaleVersionError'; message: string }
      | {
          __typename?: 'StructuredProductStructureNotFoundError'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateCannotBeResolvedError'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateMappingNotFoundError'
          message: string
        }
      | { __typename?: 'StructuredProductUnKnownMappingError'; message: string }
      | { __typename?: 'StructuredProductValidation'; message: string }
      | { __typename?: 'StructuredProductWsdParsingError'; message: string }
      | {
          __typename?: 'StructuredProductsExceededBulkCreateLimit'
          message: string
        }
      | { __typename?: 'UnSupportedIdentifierType'; message: string }
      | null
  }
}

export type UpdateStructuredProductMutationVariables = Exact<{
  id: Scalars['ID']
  version: Scalars['Int']
  input: StructuredProductInput
}>

export type UpdateStructuredProductMutation = {
  __typename?: 'Mutation'
  updateStructuredProduct: {
    __typename?: 'UpdateStructuredProductPayload'
    structuredProduct?: {
      __typename?: 'StructuredProduct'
      id: string
      version: number
      initiatingSalesperson?: string | null
      brokerageAdvisoryCapacity?: BrokerageAdvisoryCapacity | null
      suppressNotifications: boolean
      isMarketplace?: boolean | null
      publishState: ProductPublishState
      caisId: string
      errors: Array<{
        __typename?: 'StructuredProductDraftError'
        code: string
        description: string
        fieldName?: string | null
      }>
      offeringType: {
        __typename?: 'StructuredProductOfferingTypeField'
        value?: StructuredProductOfferingType | null
      }
      status: {
        __typename?: 'StructuredProductStatusField'
        value?: StructuredProductStatus | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      currencyCode: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      prospectusType: {
        __typename?: 'StructuredProductProspectusTypeField'
        value?: StructuredProductProspectusType | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          applicability?: ProductFieldApplicability | null
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      settlementType: {
        __typename?: 'StructuredProductSettlementTypeField'
        value?: StructuredProductSettlementType | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          applicability?: ProductFieldApplicability | null
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      structure?: {
        __typename?: 'StructuredProductStructure'
        structureTemplateId?: string | null
        name: { __typename?: 'ProductStringField'; value?: string | null }
        subType: {
          __typename?: 'StructuredProductSubTypeField'
          value?: StructuredProductSubType | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        wrapperGroup: {
          __typename?: 'StructuredProductWrapperGroupField'
          value?: StructuredProductWrapperGroup | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        fdicInsured: {
          __typename?: 'ProductBooleanField'
          value?: boolean | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        acronym: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        customName: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
      } | null
      pricingSupplementURL: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      factSheetURL?: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      } | null
      price: Array<{
        __typename?: 'ProductPrice'
        value: any
        valueDate: string
      }>
      identifiers: Array<{
        __typename?: 'ProductIdentifier'
        type: ProductIdentifierType
        value: string
      }>
      issuer: {
        __typename?: 'ProductIssuerField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
        value?: { __typename?: 'Issuer'; legalName: string; id: string } | null
      }
      issuePrice: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      tenor: {
        __typename?: 'ProductPricingComponentField'
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
        value?: {
          __typename?: 'PricingComponent'
          final?: any | null
          high?: any | null
          low?: any | null
        } | null
      }
      denomination: {
        __typename?: 'ProductBigDecimalField'
        value?: any | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      registration: {
        __typename?: 'StructuredProductRegistrationTypeField'
        value?: StructuredProductRegistrationType | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      underlierData: {
        __typename?: 'StructuredProductUnderlierData'
        underliers: Array<{
          __typename?: 'StructuredProductUnderlier'
          ticker?: string | null
          referenceAssetId?: string | null
          legalName?: string | null
          weight?: any | null
          quanto?: boolean | null
          initialLevel?: any | null
          paymentBarrierLevel?: any | null
          autoCallLevel?: any | null
          downsideLevel?: any | null
        }>
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      investmentArea: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      }
      basketType: {
        __typename?: 'StructuredProductBasketTypeField'
        value?: StructuredProductBasketType | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
        }
      }
      basketName: {
        __typename?: 'ProductStringField'
        value?: string | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          applicability?: ProductFieldApplicability | null
        }
      }
      tradeStatus?: {
        __typename?: 'StructuredProductTradeStatusField'
        value?: StructuredProductTradeStatus | null
        metadata: {
          __typename?: 'ProductFieldMetadata'
          source: ProductFieldSource
          visibility?: ProductFieldVisibility | null
          applicability?: ProductFieldApplicability | null
        }
      } | null
      keyDates: {
        __typename?: 'StructuredProductKeyDates'
        averageIn: Array<string>
        averageOut: Array<string>
        called: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        expired: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        closing: {
          __typename?: 'ProductInstantField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        trade: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        strike: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        finalObservation: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        issuance: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        maturity: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        filing: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
      }
      growth: {
        __typename?: 'StructuredProductGrowth'
        upsideParticipation: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        maximumReturn: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        cap: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        digitalReturn: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        digitalReturnBarrier: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        upsideParticipationAboveDigital: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        lowerCallStrike: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        upperCallStrike: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        multipleFinalValuationDates?: {
          __typename?: 'StructuredProductMultipleFinalValuationDates'
          firstDate: {
            __typename?: 'ProductLocalDateField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          interval: {
            __typename?: 'ProductFrequencyField'
            value?: Frequency | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          lastDate: {
            __typename?: 'ProductLocalDateField'
            value?: string | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
          style: {
            __typename?: 'StructuredProductMultipleFinalValuationDatesStyleField'
            value?: MultipleFinalValuationDatesStyle | null
            metadata: {
              __typename?: 'ProductFieldMetadata'
              source: ProductFieldSource
              visibility?: ProductFieldVisibility | null
              applicability?: ProductFieldApplicability | null
            }
          }
        } | null
        knockOutBarrier: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        knockOutBarrierEventType: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        knockOutResult: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        knockOutBarrierEventObservationType: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        rebate: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
      }
      yield: {
        __typename?: 'StructuredProductYield'
        floatingRateAdjustmentFactor?: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        floatingRateLeverageFactor?: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        fixedRatePeriod?: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        minimumInterestRate?: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        maximumInterestRate?: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        additionalTerms?: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        } | null
        couponType: {
          __typename?: 'StructuredProductCouponTypeField'
          value?: StructuredProductCouponType | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        couponRate: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        couponPerPeriod: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            low?: any | null
            high?: any | null
          } | null
        }
        couponPaymentFrequency: {
          __typename?: 'ProductFrequencyField'
          value?: Frequency | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        couponObservationFrequency: {
          __typename?: 'ProductFrequencyField'
          value?: Frequency | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        couponBarrier: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            low?: any | null
            high?: any | null
          } | null
        }
        couponMemory: {
          __typename?: 'ProductBooleanField'
          value?: boolean | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        couponSchedule: {
          __typename?: 'StructuredProductYieldCouponSchedule'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: Array<{
            __typename?: 'StructuredProductCouponSchedule'
            barrierLevel?: any | null
            observationDate?: string | null
            outcome?: StructuredProductCouponOutcome | null
            periodRate?: any | null
            settlementDate?: string | null
          }> | null
        }
      }
      downside: {
        __typename?: 'StructuredProductDownside'
        downsideProtectionType: {
          __typename?: 'StructuredProductDownsideProtectionTypeField'
          value?: DownsideProtectionType | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        observationFrequency: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        downsideGearing: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        absoluteReturnBarrier: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        threshold: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            applicability?: ProductFieldApplicability | null
            visibility?: ProductFieldVisibility | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        absoluteReturnParticipation: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        putStrikeLevel: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        barrierType: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        bufferLevel: {
          __typename?: 'ProductPricingComponentField'
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        knockInBarrier: {
          __typename?: 'ProductPricingComponentField'
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        knockInBarrierEventType: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        knockInBarrierObservationType: {
          __typename?: 'ProductStringField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
      }
      callable: {
        __typename?: 'StructuredProductCallable'
        callFirstDate: {
          __typename?: 'ProductLocalDateField'
          value?: string | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        callType: {
          __typename?: 'StructuredProductCallTypeField'
          value?: StructuredProductCallType | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        callFrequency: {
          __typename?: 'ProductFrequencyField'
          value?: Frequency | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        nonCallPeriod: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        autoCallBarrier: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        callPremium: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        callHurdle: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        schedule: {
          __typename?: 'StructuredProductCallableScheduleField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: Array<{
            __typename?: 'StructuredProductCallSchedule'
            barrierLevel?: any | null
            observationDate?: string | null
            outcome?: StructuredProductCallObservationOutcome | null
            paymentDate?: string | null
            premium?: any | null
          }> | null
        }
      }
      miscellaneous: {
        __typename?: 'StructuredProductMisc'
        notionalCAIS: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            applicability?: ProductFieldApplicability | null
          }
        }
        notionalOffering: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
        }
        feeCAIS: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            applicability?: ProductFieldApplicability | null
          }
        }
        feeCustodian: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            applicability?: ProductFieldApplicability | null
          }
        }
        feeOther: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            applicability?: ProductFieldApplicability | null
          }
        }
        proceedsToIssuer: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        staticReturn: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            applicability?: ProductFieldApplicability | null
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
          }
        }
        finalRedemptionPerformance: {
          __typename?: 'ProductBigDecimalField'
          value?: any | null
          metadata: {
            __typename?: 'ProductFieldMetadata'
            visibility?: ProductFieldVisibility | null
            source: ProductFieldSource
            applicability?: ProductFieldApplicability | null
          }
        }
        estimatedValue: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
        feeSalesConcession: {
          __typename?: 'ProductPricingComponentField'
          metadata: {
            __typename?: 'ProductFieldMetadata'
            source: ProductFieldSource
            visibility?: ProductFieldVisibility | null
            applicability?: ProductFieldApplicability | null
          }
          value?: {
            __typename?: 'PricingComponent'
            final?: any | null
            high?: any | null
            low?: any | null
          } | null
        }
      }
    } | null
    error?:
      | {
          __typename?: 'MandatoryFieldValueNotPopulated'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'MultipleWsdRecordsExistForAnIdentifier'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructureProductUploadError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductExistsInDisabledState'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductExistsInDraftState'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductExistsInPublishedState'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductInvalidMetaDataError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductInvariantViolationError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductIssuerNotFoundError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductMandatoryFieldValueError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductNameAlreadyTaken'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductNoEnumMappingFoundError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductNoIdentifierFoundError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductNotFoundError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductPriceError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductStaleVersionError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductStructureNotFoundError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductStructureTemplateCannotBeResolvedError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductStructureTemplateMappingNotFoundError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductUnKnownMappingError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductValidation'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductWsdParsingError'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'StructuredProductsExceededBulkCreateLimit'
          message: string
          errorAttributes: any
        }
      | {
          __typename?: 'UnSupportedIdentifierType'
          message: string
          errorAttributes: any
        }
      | null
  }
}

export type CreateStructuredProductStructureTemplateMutationVariables = Exact<{
  input: StructuredProductStructureTemplateInput
}>

export type CreateStructuredProductStructureTemplateMutation = {
  __typename?: 'Mutation'
  createStructuredProductStructureTemplate: {
    __typename?: 'StructuredProductStructureTemplatePayload'
    structuredProductStructureTemplate?: {
      __typename?: 'StructuredProductStructureTemplate'
      id: string
      version: number
      caisId: string
      name: {
        __typename?: 'StructuredProductStructureTemplateMandatoryStringField'
        value: string
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      acronym: {
        __typename?: 'StructuredProductStructureTemplateStructuredStringField'
        value?: string | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      customName: {
        __typename?: 'StructuredProductStructureTemplateStructuredStringField'
        value?: string | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      structureWsd: {
        __typename?: 'StructuredProductStructureTemplateStructuredStringField'
        value?: string | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      subType: {
        __typename?: 'StructuredProductStructureTemplateStructuredProductSubTypeField'
        value: StructuredProductSubType
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      wrapperGroup: {
        __typename?: 'StructuredProductStructureTemplateWrapperGroupField'
        value: StructuredProductWrapperGroup
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      fdicInsured: {
        __typename?: 'StructuredProductStructureTemplateMandatoryBooleanField'
        value: boolean
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasCoupon: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasRebate: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      couponType: {
        __typename?: 'StructuredProductStructureTemplateCouponTypeField'
        value?: StructuredProductCouponType | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasCouponBarrier: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasAdditionalYieldTerms: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasFixedToFloatTerms: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasCouponMemory: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      callType: {
        __typename?: 'StructuredProductStructureTemplateCallTypeField'
        value?: StructuredProductCallType | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasCallPremium: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasStepUpAutoCallHurdle: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasStepDownAutoCallHurdle: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasUpsideParticipation: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasMaximumReturn: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasDigitalReturn: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasUpsideAboveDigitalReturn: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasUpsideKnockout: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasAbsoluteReturn: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasGearedDownside: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      multipleFinalValuationDatesStyle: {
        __typename?: 'StructuredProductStructureTemplateMultipleFinalValuationDateStylesField'
        value?: MultipleFinalValuationDatesStyle | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      downsideProtectionType: {
        __typename?: 'StructuredProductStructureTemplateDownsideProtectionTypeField'
        value?: DownsideProtectionType | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasBearish: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasRainbow: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasLookBack: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
    } | null
    error?:
      | {
          __typename?: 'StructuredProductStructureTemplateAlreadyTaken'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateCouponTypeNotFound'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateDuplicateField'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateInvalidField'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateMissingField'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateMissingFieldValues'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateWsdNameAlreadyTaken'
          message: string
        }
      | { __typename?: 'StructuredProductSubTypeNotFound'; message: string }
      | null
  }
}

export type TemplateFieldsToRetrieveFragment = {
  __typename?: 'StructuredProductStructureTemplate'
  id: string
  version: number
  caisId: string
  name: {
    __typename?: 'StructuredProductStructureTemplateMandatoryStringField'
    value: string
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  acronym: {
    __typename?: 'StructuredProductStructureTemplateStructuredStringField'
    value?: string | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  customName: {
    __typename?: 'StructuredProductStructureTemplateStructuredStringField'
    value?: string | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  structureWsd: {
    __typename?: 'StructuredProductStructureTemplateStructuredStringField'
    value?: string | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  subType: {
    __typename?: 'StructuredProductStructureTemplateStructuredProductSubTypeField'
    value: StructuredProductSubType
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  wrapperGroup: {
    __typename?: 'StructuredProductStructureTemplateWrapperGroupField'
    value: StructuredProductWrapperGroup
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  fdicInsured: {
    __typename?: 'StructuredProductStructureTemplateMandatoryBooleanField'
    value: boolean
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasCoupon: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasRebate: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  couponType: {
    __typename?: 'StructuredProductStructureTemplateCouponTypeField'
    value?: StructuredProductCouponType | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasCouponBarrier: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasAdditionalYieldTerms: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasFixedToFloatTerms: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasCouponMemory: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  callType: {
    __typename?: 'StructuredProductStructureTemplateCallTypeField'
    value?: StructuredProductCallType | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasCallPremium: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasStepUpAutoCallHurdle: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasStepDownAutoCallHurdle: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasUpsideParticipation: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasMaximumReturn: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasDigitalReturn: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasUpsideAboveDigitalReturn: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasUpsideKnockout: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasAbsoluteReturn: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasGearedDownside: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  multipleFinalValuationDatesStyle: {
    __typename?: 'StructuredProductStructureTemplateMultipleFinalValuationDateStylesField'
    value?: MultipleFinalValuationDatesStyle | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  downsideProtectionType: {
    __typename?: 'StructuredProductStructureTemplateDownsideProtectionTypeField'
    value?: DownsideProtectionType | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasBearish: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasRainbow: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
  hasLookBack: {
    __typename?: 'StructuredProductStructureTemplateBooleanField'
    value?: boolean | null
    metadata: {
      __typename?: 'StructuredProductStructureTemplateFieldMetadata'
      visibility?: ProductFieldVisibility | null
    }
  }
}

export type GetStructureTemplateQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type GetStructureTemplateQuery = {
  __typename?: 'Query'
  structuredProductStructureTemplate: {
    __typename?: 'StructuredProductStructureTemplate'
    id: string
    version: number
    caisId: string
    name: {
      __typename?: 'StructuredProductStructureTemplateMandatoryStringField'
      value: string
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    acronym: {
      __typename?: 'StructuredProductStructureTemplateStructuredStringField'
      value?: string | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    customName: {
      __typename?: 'StructuredProductStructureTemplateStructuredStringField'
      value?: string | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    structureWsd: {
      __typename?: 'StructuredProductStructureTemplateStructuredStringField'
      value?: string | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    subType: {
      __typename?: 'StructuredProductStructureTemplateStructuredProductSubTypeField'
      value: StructuredProductSubType
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    wrapperGroup: {
      __typename?: 'StructuredProductStructureTemplateWrapperGroupField'
      value: StructuredProductWrapperGroup
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    fdicInsured: {
      __typename?: 'StructuredProductStructureTemplateMandatoryBooleanField'
      value: boolean
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasCoupon: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasRebate: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    couponType: {
      __typename?: 'StructuredProductStructureTemplateCouponTypeField'
      value?: StructuredProductCouponType | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasCouponBarrier: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasAdditionalYieldTerms: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasFixedToFloatTerms: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasCouponMemory: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    callType: {
      __typename?: 'StructuredProductStructureTemplateCallTypeField'
      value?: StructuredProductCallType | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasCallPremium: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasStepUpAutoCallHurdle: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasStepDownAutoCallHurdle: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasUpsideParticipation: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasMaximumReturn: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasDigitalReturn: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasUpsideAboveDigitalReturn: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasUpsideKnockout: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasAbsoluteReturn: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasGearedDownside: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    multipleFinalValuationDatesStyle: {
      __typename?: 'StructuredProductStructureTemplateMultipleFinalValuationDateStylesField'
      value?: MultipleFinalValuationDatesStyle | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    downsideProtectionType: {
      __typename?: 'StructuredProductStructureTemplateDownsideProtectionTypeField'
      value?: DownsideProtectionType | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasBearish: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasRainbow: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
    hasLookBack: {
      __typename?: 'StructuredProductStructureTemplateBooleanField'
      value?: boolean | null
      metadata: {
        __typename?: 'StructuredProductStructureTemplateFieldMetadata'
        visibility?: ProductFieldVisibility | null
      }
    }
  }
}

export type GetStructureTemplatesQueryVariables = Exact<{
  sort?: InputMaybe<
    | Array<StructuredProductStructureTemplateSort>
    | StructuredProductStructureTemplateSort
  >
  pagination?: InputMaybe<PaginationInput>
  filter?: InputMaybe<StructuredProductStructureTemplateFilterInput>
}>

export type GetStructureTemplatesQuery = {
  __typename?: 'Query'
  structuredProductStructureTemplates: {
    __typename?: 'StructurePageResult'
    items: Array<{
      __typename?: 'StructuredProductStructureTemplate'
      id: string
      caisId: string
      subType: {
        __typename?: 'StructuredProductStructureTemplateStructuredProductSubTypeField'
        value: StructuredProductSubType
      }
      name: {
        __typename?: 'StructuredProductStructureTemplateMandatoryStringField'
        value: string
      }
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
      startCursor?: string | null
    }
  }
}

export type GetStructuredProductsNamesQueryVariables = Exact<{
  pagination?: InputMaybe<PaginationInput>
}>

export type GetStructuredProductsNamesQuery = {
  __typename?: 'Query'
  structuredProductStructureTemplates: {
    __typename?: 'StructurePageResult'
    items: Array<{
      __typename?: 'StructuredProductStructureTemplate'
      id: string
      name: {
        __typename?: 'StructuredProductStructureTemplateMandatoryStringField'
        value: string
      }
    }>
    pageInfo: {
      __typename?: 'PageInfo'
      hasNextPage: boolean
      endCursor?: string | null
      startCursor?: string | null
    }
  }
}

export type UpdateStructuredProductStructureTemplateMutationVariables = Exact<{
  id: Scalars['ID']
  version: Scalars['Int']
  input: StructuredProductStructureTemplateInput
}>

export type UpdateStructuredProductStructureTemplateMutation = {
  __typename?: 'Mutation'
  updateStructuredProductStructureTemplate: {
    __typename?: 'StructuredProductStructureTemplatePayload'
    structuredProductStructureTemplate?: {
      __typename?: 'StructuredProductStructureTemplate'
      id: string
      version: number
      caisId: string
      name: {
        __typename?: 'StructuredProductStructureTemplateMandatoryStringField'
        value: string
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      acronym: {
        __typename?: 'StructuredProductStructureTemplateStructuredStringField'
        value?: string | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      customName: {
        __typename?: 'StructuredProductStructureTemplateStructuredStringField'
        value?: string | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      structureWsd: {
        __typename?: 'StructuredProductStructureTemplateStructuredStringField'
        value?: string | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      subType: {
        __typename?: 'StructuredProductStructureTemplateStructuredProductSubTypeField'
        value: StructuredProductSubType
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      wrapperGroup: {
        __typename?: 'StructuredProductStructureTemplateWrapperGroupField'
        value: StructuredProductWrapperGroup
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      fdicInsured: {
        __typename?: 'StructuredProductStructureTemplateMandatoryBooleanField'
        value: boolean
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasCoupon: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasRebate: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      couponType: {
        __typename?: 'StructuredProductStructureTemplateCouponTypeField'
        value?: StructuredProductCouponType | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasCouponBarrier: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasAdditionalYieldTerms: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasFixedToFloatTerms: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasCouponMemory: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      callType: {
        __typename?: 'StructuredProductStructureTemplateCallTypeField'
        value?: StructuredProductCallType | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasCallPremium: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasStepUpAutoCallHurdle: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasStepDownAutoCallHurdle: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasUpsideParticipation: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasMaximumReturn: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasDigitalReturn: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasUpsideAboveDigitalReturn: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasUpsideKnockout: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasAbsoluteReturn: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasGearedDownside: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      multipleFinalValuationDatesStyle: {
        __typename?: 'StructuredProductStructureTemplateMultipleFinalValuationDateStylesField'
        value?: MultipleFinalValuationDatesStyle | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      downsideProtectionType: {
        __typename?: 'StructuredProductStructureTemplateDownsideProtectionTypeField'
        value?: DownsideProtectionType | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasBearish: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasRainbow: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
      hasLookBack: {
        __typename?: 'StructuredProductStructureTemplateBooleanField'
        value?: boolean | null
        metadata: {
          __typename?: 'StructuredProductStructureTemplateFieldMetadata'
          visibility?: ProductFieldVisibility | null
        }
      }
    } | null
    error?:
      | {
          __typename?: 'StructuredProductStructureTemplateAlreadyTaken'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateCouponTypeNotFound'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateDuplicateField'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateInvalidField'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateMissingField'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateMissingFieldValues'
          message: string
        }
      | {
          __typename?: 'StructuredProductStructureTemplateWsdNameAlreadyTaken'
          message: string
        }
      | { __typename?: 'StructuredProductSubTypeNotFound'; message: string }
      | null
  }
}

export type CreateProductPermissionsMutationVariables = Exact<{
  input: Array<ProductPermissionInput> | ProductPermissionInput
}>

export type CreateProductPermissionsMutation = {
  __typename?: 'Mutation'
  createProductPermissions: {
    __typename?: 'ProductPermissionsCreationResult'
    successful?: Array<{
      __typename?: 'ProductPermission'
      createdAt: string
      productId: string
    }> | null
    failures?: Array<{
      __typename?: 'ProductPermissionFailure'
      productId: string
      reason: string
    }> | null
  }
}

export type SearchFirmsQueryVariables = Exact<{
  query: Scalars['String']
}>

export type SearchFirmsQuery = {
  __typename?: 'Query'
  firms: {
    __typename?: 'FirmsPageResult'
    items: Array<{
      __typename?: 'Firm'
      id: string
      name: string
      totalUsers: number
    }>
    pageInfo: { __typename?: 'PageInfo'; hasNextPage: boolean }
  }
}

export type UserRolesQueryVariables = Exact<{
  userId: Scalars['ID']
}>

export type UserRolesQuery = {
  __typename?: 'Query'
  user: {
    __typename?: 'User'
    roles: Array<{ __typename?: 'Role'; name: string; archetype: UserType }>
  }
}

export const FieldsToRetrieveFragmentDoc = `
    fragment fieldsToRetrieve on StructuredProduct {
  id
  version
  initiatingSalesperson
  brokerageAdvisoryCapacity
  suppressNotifications
  errors {
    code
    description
    fieldName
  }
  offeringType {
    value
  }
  isMarketplace
  status {
    metadata {
      source
      visibility
    }
    value
  }
  currencyCode {
    metadata {
      source
      visibility
    }
    value
  }
  prospectusType {
    metadata {
      applicability
      source
      visibility
    }
    value
  }
  settlementType {
    metadata {
      applicability
      source
      visibility
    }
    value
  }
  structure {
    name {
      value
    }
    subType {
      metadata {
        source
        visibility
      }
      value
    }
    wrapperGroup {
      metadata {
        source
        visibility
      }
      value
    }
    fdicInsured {
      metadata {
        source
        visibility
      }
      value
    }
    acronym {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    customName {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    structureTemplateId
  }
  pricingSupplementURL {
    metadata {
      source
      visibility
      applicability
    }
    value
  }
  factSheetURL {
    metadata {
      source
      visibility
      applicability
    }
    value
  }
  publishState
  price {
    value
    valueDate
  }
  identifiers {
    type
    value
  }
  caisId
  issuer {
    metadata {
      source
      visibility
    }
    value {
      legalName
      id
    }
  }
  issuePrice {
    value
    metadata {
      source
      visibility
      applicability
    }
  }
  tenor {
    metadata {
      source
      visibility
      applicability
    }
    value {
      final
      high
      low
    }
  }
  denomination {
    metadata {
      source
      visibility
    }
    value
  }
  registration {
    metadata {
      source
      visibility
    }
    value
  }
  underlierData {
    underliers {
      ticker
      referenceAssetId
      legalName
      weight
      quanto
      initialLevel
      paymentBarrierLevel
      autoCallLevel
      downsideLevel
    }
    metadata {
      source
      visibility
    }
  }
  investmentArea {
    metadata {
      source
      visibility
      applicability
    }
    value
  }
  basketType {
    metadata {
      source
      visibility
    }
    value
  }
  basketName {
    metadata {
      source
      applicability
    }
    value
  }
  tradeStatus {
    metadata {
      source
      visibility
      applicability
    }
    value
  }
  keyDates {
    averageIn
    averageOut
    called {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    expired {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    closing {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    trade {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    strike {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    finalObservation {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    issuance {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    maturity {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    filing {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
  }
  growth {
    upsideParticipation {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    maximumReturn {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    cap {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    digitalReturn {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    digitalReturnBarrier {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    upsideParticipationAboveDigital {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    lowerCallStrike {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    upperCallStrike {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    multipleFinalValuationDates {
      firstDate {
        metadata {
          source
          visibility
          applicability
        }
        value
      }
      interval {
        metadata {
          source
          visibility
          applicability
        }
        value
      }
      lastDate {
        metadata {
          source
          visibility
          applicability
        }
        value
      }
      style {
        metadata {
          source
          visibility
          applicability
        }
        value
      }
    }
    knockOutBarrier {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    knockOutBarrierEventType {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    knockOutResult {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    knockOutBarrierEventObservationType {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    rebate {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
  }
  yield {
    floatingRateAdjustmentFactor {
      value
      metadata {
        source
        visibility
        applicability
      }
    }
    floatingRateLeverageFactor {
      value
      metadata {
        source
        visibility
        applicability
      }
    }
    fixedRatePeriod {
      value
      metadata {
        source
        visibility
        applicability
      }
    }
    minimumInterestRate {
      value
      metadata {
        source
        visibility
        applicability
      }
    }
    maximumInterestRate {
      value
      metadata {
        source
        visibility
        applicability
      }
    }
    additionalTerms {
      value
      metadata {
        source
        visibility
        applicability
      }
    }
    couponType {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    couponRate {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    couponPerPeriod {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        low
        high
      }
    }
    couponPaymentFrequency {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    couponObservationFrequency {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    couponBarrier {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        low
        high
      }
    }
    couponMemory {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    couponSchedule {
      metadata {
        source
        visibility
        applicability
      }
      value {
        barrierLevel
        observationDate
        outcome
        periodRate
        settlementDate
      }
    }
  }
  downside {
    downsideProtectionType {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    observationFrequency {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    downsideGearing {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    absoluteReturnBarrier {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    threshold {
      metadata {
        source
        applicability
        visibility
      }
      value {
        final
        high
        low
      }
    }
    absoluteReturnParticipation {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    putStrikeLevel {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    barrierType {
      value
      metadata {
        source
        visibility
        applicability
      }
    }
    bufferLevel {
      value {
        final
        high
        low
      }
      metadata {
        source
        visibility
        applicability
      }
    }
    knockInBarrier {
      value {
        final
        high
        low
      }
      metadata {
        source
        visibility
        applicability
      }
    }
    knockInBarrierEventType {
      value
      metadata {
        source
        visibility
        applicability
      }
    }
    knockInBarrierObservationType {
      value
      metadata {
        source
        visibility
        applicability
      }
    }
  }
  callable {
    callFirstDate {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    callType {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    callFrequency {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    nonCallPeriod {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    autoCallBarrier {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    callPremium {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    callHurdle {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    schedule {
      metadata {
        source
        visibility
        applicability
      }
      value {
        barrierLevel
        observationDate
        outcome
        paymentDate
        premium
      }
    }
  }
  miscellaneous {
    notionalCAIS {
      metadata {
        source
        applicability
      }
      value
    }
    notionalOffering {
      metadata {
        source
        visibility
        applicability
      }
      value
    }
    feeCAIS {
      metadata {
        source
        applicability
      }
      value
    }
    feeCustodian {
      metadata {
        source
        applicability
      }
      value
    }
    feeOther {
      metadata {
        source
        applicability
      }
      value
    }
    proceedsToIssuer {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    staticReturn {
      value
      metadata {
        applicability
        source
        visibility
      }
    }
    finalRedemptionPerformance {
      value
      metadata {
        visibility
        source
        applicability
      }
    }
    estimatedValue {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
    feeSalesConcession {
      metadata {
        source
        visibility
        applicability
      }
      value {
        final
        high
        low
      }
    }
  }
}
    `
export const TemplateFieldsToRetrieveFragmentDoc = `
    fragment templateFieldsToRetrieve on StructuredProductStructureTemplate {
  id
  version
  caisId
  name {
    value
    metadata {
      visibility
    }
  }
  acronym {
    value
    metadata {
      visibility
    }
  }
  customName {
    value
    metadata {
      visibility
    }
  }
  structureWsd {
    value
    metadata {
      visibility
    }
  }
  subType {
    value
    metadata {
      visibility
    }
  }
  wrapperGroup {
    value
    metadata {
      visibility
    }
  }
  fdicInsured {
    value
    metadata {
      visibility
    }
  }
  hasCoupon {
    value
    metadata {
      visibility
    }
  }
  hasRebate {
    value
    metadata {
      visibility
    }
  }
  couponType {
    value
    metadata {
      visibility
    }
  }
  hasCouponBarrier {
    value
    metadata {
      visibility
    }
  }
  hasAdditionalYieldTerms {
    value
    metadata {
      visibility
    }
  }
  hasFixedToFloatTerms {
    value
    metadata {
      visibility
    }
  }
  hasCouponMemory {
    value
    metadata {
      visibility
    }
  }
  callType {
    value
    metadata {
      visibility
    }
  }
  hasCallPremium {
    value
    metadata {
      visibility
    }
  }
  hasStepUpAutoCallHurdle {
    value
    metadata {
      visibility
    }
  }
  hasStepDownAutoCallHurdle {
    value
    metadata {
      visibility
    }
  }
  hasUpsideParticipation {
    value
    metadata {
      visibility
    }
  }
  hasMaximumReturn {
    value
    metadata {
      visibility
    }
  }
  hasDigitalReturn {
    value
    metadata {
      visibility
    }
  }
  hasUpsideAboveDigitalReturn {
    value
    metadata {
      visibility
    }
  }
  hasUpsideKnockout {
    value
    metadata {
      visibility
    }
  }
  hasAbsoluteReturn {
    value
    metadata {
      visibility
    }
  }
  hasGearedDownside {
    value
    metadata {
      visibility
    }
  }
  multipleFinalValuationDatesStyle {
    value
    metadata {
      visibility
    }
  }
  downsideProtectionType {
    value
    metadata {
      visibility
    }
  }
  hasBearish {
    value
    metadata {
      visibility
    }
  }
  hasRainbow {
    value
    metadata {
      visibility
    }
  }
  hasLookBack {
    value
    metadata {
      visibility
    }
  }
}
    `
export const GetPipelineRunsDocument = `
    query getPipelineRuns($filter: PipelineRunSearchFilterInput, $pagination: PaginationInput, $sort: [PipelineRunSort!]) {
  pipelineRuns(filter: $filter, pagination: $pagination, sort: $sort) {
    items {
      runId
      pipelineId
      domain
      executionDate
      state
      executionMetadata
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `
export const useGetPipelineRunsQuery = <
  TData = GetPipelineRunsQuery,
  TError = unknown
>(
  variables?: GetPipelineRunsQueryVariables,
  options?: UseQueryOptions<GetPipelineRunsQuery, TError, TData>
) =>
  useQuery<GetPipelineRunsQuery, TError, TData>(
    variables === undefined
      ? ['getPipelineRuns']
      : ['getPipelineRuns', variables],
    createMembersFetcher<GetPipelineRunsQuery, GetPipelineRunsQueryVariables>(
      GetPipelineRunsDocument,
      variables
    ),
    options
  )
export const useInfiniteGetPipelineRunsQuery = <
  TData = GetPipelineRunsQuery,
  TError = unknown
>(
  variables?: GetPipelineRunsQueryVariables,
  options?: UseInfiniteQueryOptions<GetPipelineRunsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetPipelineRunsQuery, TError, TData>(
    variables === undefined
      ? ['getPipelineRuns.infinite']
      : ['getPipelineRuns.infinite', variables],
    (metaData) =>
      createMembersFetcher<GetPipelineRunsQuery, GetPipelineRunsQueryVariables>(
        GetPipelineRunsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const RetryDocumentDocument = `
    mutation retryDocument($input: PipelineRerunInput!) {
  rerunPipeline(input: $input) {
    payload {
      runId
      pipelineId
      domain
      executionDate
      state
      executionMetadata
    }
  }
}
    `
export const useRetryDocumentMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    RetryDocumentMutation,
    TError,
    RetryDocumentMutationVariables,
    TContext
  >
) =>
  useMutation<
    RetryDocumentMutation,
    TError,
    RetryDocumentMutationVariables,
    TContext
  >(
    ['retryDocument'],
    (variables?: RetryDocumentMutationVariables) =>
      createMembersFetcher<
        RetryDocumentMutation,
        RetryDocumentMutationVariables
      >(RetryDocumentDocument, variables)(),
    options
  )
export const GetFirmDocument = `
    query getFirm($firmId: ID!, $roleFilters: IdFilterInput, $searchText: StringFilterInput, $sort: [UserSort!], $statusFilters: UserStatusInputFilterInput, $pagination: PaginationInput) {
  firm(firmId: $firmId) {
    id
    name
    firmTypes
    totalUsers
    advisorFirmInfo {
      type
    }
    validPersonasForFirmRoles
    address {
      country
      street1
      street2
      city
      state
      postalCode
    }
    validCapabilitiesForFirm {
      id
      name
      description
    }
    capabilities {
      id
      name
      description
    }
    userAssignableRoles {
      id
      persona
      name
      isDefault
    }
    users(
      pagination: $pagination
      sorts: $sort
      filter: {roleId: $roleFilters, status: $statusFilters, searchText: $searchText}
    ) {
      items {
        title
        attributes
        phone
        address {
          country
          street1
          street2
          city
          state
          postalCode
        }
        id
        email
        firstName
        lastName
        status
        firmNames
        firmTypes
        roles {
          name
          persona
          isDefault
        }
      }
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
    }
  }
}
    `
export const useGetFirmQuery = <TData = GetFirmQuery, TError = unknown>(
  variables: GetFirmQueryVariables,
  options?: UseQueryOptions<GetFirmQuery, TError, TData>
) =>
  useQuery<GetFirmQuery, TError, TData>(
    ['getFirm', variables],
    createMembersFetcher<GetFirmQuery, GetFirmQueryVariables>(
      GetFirmDocument,
      variables
    ),
    options
  )
export const useInfiniteGetFirmQuery = <TData = GetFirmQuery, TError = unknown>(
  variables: GetFirmQueryVariables,
  options?: UseInfiniteQueryOptions<GetFirmQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFirmQuery, TError, TData>(
    ['getFirm.infinite', variables],
    (metaData) =>
      createMembersFetcher<GetFirmQuery, GetFirmQueryVariables>(
        GetFirmDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const CreateUserDocument = `
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    user {
      id
      email
      firstName
      lastName
      roles {
        id
        name
      }
    }
  }
}
    `
export const useCreateUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateUserMutation,
    TError,
    CreateUserMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateUserMutation,
    TError,
    CreateUserMutationVariables,
    TContext
  >(
    ['createUser'],
    (variables?: CreateUserMutationVariables) =>
      createMembersFetcher<CreateUserMutation, CreateUserMutationVariables>(
        CreateUserDocument,
        variables
      )(),
    options
  )
export const GetFirmRolesDocument = `
    query getFirmRoles($firmId: ID!) {
  firmRoles(firmId: $firmId) {
    id
    name
    description
    isDefault
    persona
    permissions {
      id
      name
      capability {
        id
        name
      }
    }
  }
}
    `
export const useGetFirmRolesQuery = <
  TData = GetFirmRolesQuery,
  TError = unknown
>(
  variables: GetFirmRolesQueryVariables,
  options?: UseQueryOptions<GetFirmRolesQuery, TError, TData>
) =>
  useQuery<GetFirmRolesQuery, TError, TData>(
    ['getFirmRoles', variables],
    createMembersFetcher<GetFirmRolesQuery, GetFirmRolesQueryVariables>(
      GetFirmRolesDocument,
      variables
    ),
    options
  )
export const useInfiniteGetFirmRolesQuery = <
  TData = GetFirmRolesQuery,
  TError = unknown
>(
  variables: GetFirmRolesQueryVariables,
  options?: UseInfiniteQueryOptions<GetFirmRolesQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFirmRolesQuery, TError, TData>(
    ['getFirmRoles.infinite', variables],
    (metaData) =>
      createMembersFetcher<GetFirmRolesQuery, GetFirmRolesQueryVariables>(
        GetFirmRolesDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetFirmPermissionsDocument = `
    query getFirmPermissions($firmId: ID!) {
  firmPermissions(firmId: $firmId) {
    id
    name
    capability {
      id
      name
    }
  }
}
    `
export const useGetFirmPermissionsQuery = <
  TData = GetFirmPermissionsQuery,
  TError = unknown
>(
  variables: GetFirmPermissionsQueryVariables,
  options?: UseQueryOptions<GetFirmPermissionsQuery, TError, TData>
) =>
  useQuery<GetFirmPermissionsQuery, TError, TData>(
    ['getFirmPermissions', variables],
    createMembersFetcher<
      GetFirmPermissionsQuery,
      GetFirmPermissionsQueryVariables
    >(GetFirmPermissionsDocument, variables),
    options
  )
export const useInfiniteGetFirmPermissionsQuery = <
  TData = GetFirmPermissionsQuery,
  TError = unknown
>(
  variables: GetFirmPermissionsQueryVariables,
  options?: UseInfiniteQueryOptions<GetFirmPermissionsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFirmPermissionsQuery, TError, TData>(
    ['getFirmPermissions.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        GetFirmPermissionsQuery,
        GetFirmPermissionsQueryVariables
      >(GetFirmPermissionsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const UserDocument = `
    query user($userId: ID!) {
  user(userId: $userId) {
    title
    phone
    status
    capabilities {
      id
      name
    }
    personas
    address {
      country
      street1
      street2
      city
      state
      postalCode
    }
    parents {
      id
      entityType
    }
    teams {
      id
      name
    }
    firms {
      id
      name
    }
    id
    email
    firstName
    lastName
    roles {
      id
      persona
      name
      description
    }
  }
}
    `
export const useUserQuery = <TData = UserQuery, TError = unknown>(
  variables: UserQueryVariables,
  options?: UseQueryOptions<UserQuery, TError, TData>
) =>
  useQuery<UserQuery, TError, TData>(
    ['user', variables],
    createMembersFetcher<UserQuery, UserQueryVariables>(
      UserDocument,
      variables
    ),
    options
  )
export const useInfiniteUserQuery = <TData = UserQuery, TError = unknown>(
  variables: UserQueryVariables,
  options?: UseInfiniteQueryOptions<UserQuery, TError, TData>
) => {
  return useInfiniteQuery<UserQuery, TError, TData>(
    ['user.infinite', variables],
    (metaData) =>
      createMembersFetcher<UserQuery, UserQueryVariables>(UserDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const UpdateUserDocument = `
    mutation updateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      id
      email
      firstName
      lastName
      roles {
        id
        name
      }
    }
  }
}
    `
export const useUpdateUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateUserMutation,
    TError,
    UpdateUserMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateUserMutation,
    TError,
    UpdateUserMutationVariables,
    TContext
  >(
    ['updateUser'],
    (variables?: UpdateUserMutationVariables) =>
      createMembersFetcher<UpdateUserMutation, UpdateUserMutationVariables>(
        UpdateUserDocument,
        variables
      )(),
    options
  )
export const DeleteUserDocument = `
    mutation deleteUser($id: ID!) {
  deleteUser(userId: $id) {
    id
  }
}
    `
export const useDeleteUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteUserMutation,
    TError,
    DeleteUserMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteUserMutation,
    TError,
    DeleteUserMutationVariables,
    TContext
  >(
    ['deleteUser'],
    (variables?: DeleteUserMutationVariables) =>
      createMembersFetcher<DeleteUserMutation, DeleteUserMutationVariables>(
        DeleteUserDocument,
        variables
      )(),
    options
  )
export const ResendActivationEmailDocument = `
    mutation resendActivationEmail($id: ID!) {
  resendActivationEmail(userId: $id) {
    id
  }
}
    `
export const useResendActivationEmailMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    ResendActivationEmailMutation,
    TError,
    ResendActivationEmailMutationVariables,
    TContext
  >
) =>
  useMutation<
    ResendActivationEmailMutation,
    TError,
    ResendActivationEmailMutationVariables,
    TContext
  >(
    ['resendActivationEmail'],
    (variables?: ResendActivationEmailMutationVariables) =>
      createMembersFetcher<
        ResendActivationEmailMutation,
        ResendActivationEmailMutationVariables
      >(ResendActivationEmailDocument, variables)(),
    options
  )
export const GetCapabilitiesDocument = `
    query getCapabilities {
  capabilities {
    name
    id
  }
}
    `
export const useGetCapabilitiesQuery = <
  TData = GetCapabilitiesQuery,
  TError = unknown
>(
  variables?: GetCapabilitiesQueryVariables,
  options?: UseQueryOptions<GetCapabilitiesQuery, TError, TData>
) =>
  useQuery<GetCapabilitiesQuery, TError, TData>(
    variables === undefined
      ? ['getCapabilities']
      : ['getCapabilities', variables],
    createMembersFetcher<GetCapabilitiesQuery, GetCapabilitiesQueryVariables>(
      GetCapabilitiesDocument,
      variables
    ),
    options
  )
export const useInfiniteGetCapabilitiesQuery = <
  TData = GetCapabilitiesQuery,
  TError = unknown
>(
  variables?: GetCapabilitiesQueryVariables,
  options?: UseInfiniteQueryOptions<GetCapabilitiesQuery, TError, TData>
) => {
  return useInfiniteQuery<GetCapabilitiesQuery, TError, TData>(
    variables === undefined
      ? ['getCapabilities.infinite']
      : ['getCapabilities.infinite', variables],
    (metaData) =>
      createMembersFetcher<GetCapabilitiesQuery, GetCapabilitiesQueryVariables>(
        GetCapabilitiesDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetFirmNamesDocument = `
    query getFirmNames($searchText: String) {
  firmNames(searchText: $searchText) {
    id
    name
  }
}
    `
export const useGetFirmNamesQuery = <
  TData = GetFirmNamesQuery,
  TError = unknown
>(
  variables?: GetFirmNamesQueryVariables,
  options?: UseQueryOptions<GetFirmNamesQuery, TError, TData>
) =>
  useQuery<GetFirmNamesQuery, TError, TData>(
    variables === undefined ? ['getFirmNames'] : ['getFirmNames', variables],
    createMembersFetcher<GetFirmNamesQuery, GetFirmNamesQueryVariables>(
      GetFirmNamesDocument,
      variables
    ),
    options
  )
export const useInfiniteGetFirmNamesQuery = <
  TData = GetFirmNamesQuery,
  TError = unknown
>(
  variables?: GetFirmNamesQueryVariables,
  options?: UseInfiniteQueryOptions<GetFirmNamesQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFirmNamesQuery, TError, TData>(
    variables === undefined
      ? ['getFirmNames.infinite']
      : ['getFirmNames.infinite', variables],
    (metaData) =>
      createMembersFetcher<GetFirmNamesQuery, GetFirmNamesQueryVariables>(
        GetFirmNamesDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetUsersDocument = `
    query getUsers($roleFilters: IdFilterInput, $firmFilters: IdFilterInput, $searchText: StringFilterInput, $sort: [UserSort!], $statusFilters: UserStatusInputFilterInput, $pagination: PaginationInput, $parent: IamEntityIdentifierInput) {
  users(
    pagination: $pagination
    parent: $parent
    sorts: $sort
    filter: {roleId: $roleFilters, status: $statusFilters, firmId: $firmFilters, searchText: $searchText}
  ) {
    items {
      id
      email
      firstName
      lastName
      status
      firmNames
      attributes
      firmTypes
      roles {
        name
        persona
      }
    }
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
  }
}
    `
export const useGetUsersQuery = <TData = GetUsersQuery, TError = unknown>(
  variables?: GetUsersQueryVariables,
  options?: UseQueryOptions<GetUsersQuery, TError, TData>
) =>
  useQuery<GetUsersQuery, TError, TData>(
    variables === undefined ? ['getUsers'] : ['getUsers', variables],
    createMembersFetcher<GetUsersQuery, GetUsersQueryVariables>(
      GetUsersDocument,
      variables
    ),
    options
  )
export const useInfiniteGetUsersQuery = <
  TData = GetUsersQuery,
  TError = unknown
>(
  variables?: GetUsersQueryVariables,
  options?: UseInfiniteQueryOptions<GetUsersQuery, TError, TData>
) => {
  return useInfiniteQuery<GetUsersQuery, TError, TData>(
    variables === undefined
      ? ['getUsers.infinite']
      : ['getUsers.infinite', variables],
    (metaData) =>
      createMembersFetcher<GetUsersQuery, GetUsersQueryVariables>(
        GetUsersDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetFirmsDocument = `
    query getFirms($searchText: StringFilterInput, $pagination: PaginationInput, $firmTypeFilters: ArrayFilterInput) {
  firms(
    pagination: $pagination
    filter: {searchText: $searchText, firmTypeExt: $firmTypeFilters}
  ) {
    items {
      id
      name
      attributes
      firmTypes
      totalUsers
    }
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
  }
}
    `
export const useGetFirmsQuery = <TData = GetFirmsQuery, TError = unknown>(
  variables?: GetFirmsQueryVariables,
  options?: UseQueryOptions<GetFirmsQuery, TError, TData>
) =>
  useQuery<GetFirmsQuery, TError, TData>(
    variables === undefined ? ['getFirms'] : ['getFirms', variables],
    createMembersFetcher<GetFirmsQuery, GetFirmsQueryVariables>(
      GetFirmsDocument,
      variables
    ),
    options
  )
export const useInfiniteGetFirmsQuery = <
  TData = GetFirmsQuery,
  TError = unknown
>(
  variables?: GetFirmsQueryVariables,
  options?: UseInfiniteQueryOptions<GetFirmsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFirmsQuery, TError, TData>(
    variables === undefined
      ? ['getFirms.infinite']
      : ['getFirms.infinite', variables],
    (metaData) =>
      createMembersFetcher<GetFirmsQuery, GetFirmsQueryVariables>(
        GetFirmsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const UpdateFirmRoleDocument = `
    mutation UpdateFirmRole($firmId: ID!, $roleId: ID!, $input: RoleInput!) {
  updateFirmRole(firmId: $firmId, roleId: $roleId, input: $input) {
    role {
      id
      name
      description
      persona
      isDefault
      permissions {
        id
        name
        description
        capability {
          id
          name
        }
      }
    }
  }
}
    `
export const useUpdateFirmRoleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateFirmRoleMutation,
    TError,
    UpdateFirmRoleMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateFirmRoleMutation,
    TError,
    UpdateFirmRoleMutationVariables,
    TContext
  >(
    ['UpdateFirmRole'],
    (variables?: UpdateFirmRoleMutationVariables) =>
      createMembersFetcher<
        UpdateFirmRoleMutation,
        UpdateFirmRoleMutationVariables
      >(UpdateFirmRoleDocument, variables)(),
    options
  )
export const UpdateFirmCapabilitiesDocument = `
    mutation UpdateFirmCapabilities($firmId: ID!, $capabilityIds: [String!]!) {
  updateFirmCapabilities(firmId: $firmId, capabilityIds: $capabilityIds) {
    id
  }
}
    `
export const useUpdateFirmCapabilitiesMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateFirmCapabilitiesMutation,
    TError,
    UpdateFirmCapabilitiesMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateFirmCapabilitiesMutation,
    TError,
    UpdateFirmCapabilitiesMutationVariables,
    TContext
  >(
    ['UpdateFirmCapabilities'],
    (variables?: UpdateFirmCapabilitiesMutationVariables) =>
      createMembersFetcher<
        UpdateFirmCapabilitiesMutation,
        UpdateFirmCapabilitiesMutationVariables
      >(UpdateFirmCapabilitiesDocument, variables)(),
    options
  )
export const CreateFirmRoleDocument = `
    mutation CreateFirmRole($firmId: ID!, $input: RoleInput!) {
  createFirmRole(firmId: $firmId, input: $input) {
    role {
      id
      name
      description
      persona
      isDefault
      permissions {
        id
        name
        description
        capability {
          id
          name
        }
      }
    }
  }
}
    `
export const useCreateFirmRoleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateFirmRoleMutation,
    TError,
    CreateFirmRoleMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateFirmRoleMutation,
    TError,
    CreateFirmRoleMutationVariables,
    TContext
  >(
    ['CreateFirmRole'],
    (variables?: CreateFirmRoleMutationVariables) =>
      createMembersFetcher<
        CreateFirmRoleMutation,
        CreateFirmRoleMutationVariables
      >(CreateFirmRoleDocument, variables)(),
    options
  )
export const DeleteFirmRoleDocument = `
    mutation DeleteFirmRole($firmId: ID!, $roleId: ID!) {
  deleteFirmRole(firmId: $firmId, roleId: $roleId) {
    id
  }
}
    `
export const useDeleteFirmRoleMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    DeleteFirmRoleMutation,
    TError,
    DeleteFirmRoleMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteFirmRoleMutation,
    TError,
    DeleteFirmRoleMutationVariables,
    TContext
  >(
    ['DeleteFirmRole'],
    (variables?: DeleteFirmRoleMutationVariables) =>
      createMembersFetcher<
        DeleteFirmRoleMutation,
        DeleteFirmRoleMutationVariables
      >(DeleteFirmRoleDocument, variables)(),
    options
  )
export const GetFirmTeamsDocument = `
    query getFirmTeams($firmId: ID!, $pagination: PaginationInput, $searchText: StringFilterInput) {
  teamsPage(firmId: $firmId, pagination: $pagination, filter: {name: $searchText}) {
    items {
      id
      name
      totalUsers
      activeUsers
    }
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
  }
}
    `
export const useGetFirmTeamsQuery = <
  TData = GetFirmTeamsQuery,
  TError = unknown
>(
  variables: GetFirmTeamsQueryVariables,
  options?: UseQueryOptions<GetFirmTeamsQuery, TError, TData>
) =>
  useQuery<GetFirmTeamsQuery, TError, TData>(
    ['getFirmTeams', variables],
    createMembersFetcher<GetFirmTeamsQuery, GetFirmTeamsQueryVariables>(
      GetFirmTeamsDocument,
      variables
    ),
    options
  )
export const useInfiniteGetFirmTeamsQuery = <
  TData = GetFirmTeamsQuery,
  TError = unknown
>(
  variables: GetFirmTeamsQueryVariables,
  options?: UseInfiniteQueryOptions<GetFirmTeamsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFirmTeamsQuery, TError, TData>(
    ['getFirmTeams.infinite', variables],
    (metaData) =>
      createMembersFetcher<GetFirmTeamsQuery, GetFirmTeamsQueryVariables>(
        GetFirmTeamsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const UpdateTeamDocument = `
    mutation updateTeam($input: UpdateTeamInput!) {
  updateTeam(input: $input) {
    team {
      id
    }
  }
}
    `
export const useUpdateTeamMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateTeamMutation,
    TError,
    UpdateTeamMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateTeamMutation,
    TError,
    UpdateTeamMutationVariables,
    TContext
  >(
    ['updateTeam'],
    (variables?: UpdateTeamMutationVariables) =>
      createMembersFetcher<UpdateTeamMutation, UpdateTeamMutationVariables>(
        UpdateTeamDocument,
        variables
      )(),
    options
  )
export const GetTeamDocument = `
    query getTeam($teamId: ID!, $pagination: PaginationInput) {
  team(teamId: $teamId) {
    id
    name
    description
    users(pagination: $pagination) {
      items {
        id
        email
        firstName
        lastName
        attributes
        status
        firmNames
        firmTypes
        roles {
          name
          persona
        }
      }
    }
  }
}
    `
export const useGetTeamQuery = <TData = GetTeamQuery, TError = unknown>(
  variables: GetTeamQueryVariables,
  options?: UseQueryOptions<GetTeamQuery, TError, TData>
) =>
  useQuery<GetTeamQuery, TError, TData>(
    ['getTeam', variables],
    createMembersFetcher<GetTeamQuery, GetTeamQueryVariables>(
      GetTeamDocument,
      variables
    ),
    options
  )
export const useInfiniteGetTeamQuery = <TData = GetTeamQuery, TError = unknown>(
  variables: GetTeamQueryVariables,
  options?: UseInfiniteQueryOptions<GetTeamQuery, TError, TData>
) => {
  return useInfiniteQuery<GetTeamQuery, TError, TData>(
    ['getTeam.infinite', variables],
    (metaData) =>
      createMembersFetcher<GetTeamQuery, GetTeamQueryVariables>(
        GetTeamDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const CreateTeamDocument = `
    mutation createTeam($input: CreateTeamInput!) {
  createTeam(input: $input) {
    team {
      id
    }
  }
}
    `
export const useCreateTeamMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateTeamMutation,
    TError,
    CreateTeamMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateTeamMutation,
    TError,
    CreateTeamMutationVariables,
    TContext
  >(
    ['createTeam'],
    (variables?: CreateTeamMutationVariables) =>
      createMembersFetcher<CreateTeamMutation, CreateTeamMutationVariables>(
        CreateTeamDocument,
        variables
      )(),
    options
  )
export const SwitchUserDocument = `
    mutation SwitchUser($switchUserInput: SwitchUserInput!) {
  switchUser(input: $switchUserInput) {
    switchUserId
    sessionId
  }
}
    `
export const useSwitchUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    SwitchUserMutation,
    TError,
    SwitchUserMutationVariables,
    TContext
  >
) =>
  useMutation<
    SwitchUserMutation,
    TError,
    SwitchUserMutationVariables,
    TContext
  >(
    ['SwitchUser'],
    (variables?: SwitchUserMutationVariables) =>
      createMembersFetcher<SwitchUserMutation, SwitchUserMutationVariables>(
        SwitchUserDocument,
        variables
      )(),
    options
  )
export const UnswitchUserDocument = `
    mutation unswitchUser {
  unswitchUser {
    success
  }
}
    `
export const useUnswitchUserMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UnswitchUserMutation,
    TError,
    UnswitchUserMutationVariables,
    TContext
  >
) =>
  useMutation<
    UnswitchUserMutation,
    TError,
    UnswitchUserMutationVariables,
    TContext
  >(
    ['unswitchUser'],
    (variables?: UnswitchUserMutationVariables) =>
      createMembersFetcher<UnswitchUserMutation, UnswitchUserMutationVariables>(
        UnswitchUserDocument,
        variables
      )(),
    options
  )
export const GetServiceIdentitiesDocument = `
    query getServiceIdentities($firmId: ID!) {
  serviceIdentities(pagination: {first: 15}, filter: {firmId: {eq: $firmId}}) {
    items {
      createDate
      deleteDate
      email
      externalId
      id
      serviceName
      permissions {
        id
        name
        description
      }
      updateDate
    }
    pageInfo {
      hasNextPage
      endCursor
      startCursor
      hasPreviousPage
    }
  }
}
    `
export const useGetServiceIdentitiesQuery = <
  TData = GetServiceIdentitiesQuery,
  TError = unknown
>(
  variables: GetServiceIdentitiesQueryVariables,
  options?: UseQueryOptions<GetServiceIdentitiesQuery, TError, TData>
) =>
  useQuery<GetServiceIdentitiesQuery, TError, TData>(
    ['getServiceIdentities', variables],
    createMembersFetcher<
      GetServiceIdentitiesQuery,
      GetServiceIdentitiesQueryVariables
    >(GetServiceIdentitiesDocument, variables),
    options
  )
export const useInfiniteGetServiceIdentitiesQuery = <
  TData = GetServiceIdentitiesQuery,
  TError = unknown
>(
  variables: GetServiceIdentitiesQueryVariables,
  options?: UseInfiniteQueryOptions<GetServiceIdentitiesQuery, TError, TData>
) => {
  return useInfiniteQuery<GetServiceIdentitiesQuery, TError, TData>(
    ['getServiceIdentities.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        GetServiceIdentitiesQuery,
        GetServiceIdentitiesQueryVariables
      >(GetServiceIdentitiesDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const CreateFirmDocument = `
    mutation createFirm($input: CreateFirmInput!) {
  createFirm(input: $input) {
    firm {
      id
    }
  }
}
    `
export const useCreateFirmMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    CreateFirmMutation,
    TError,
    CreateFirmMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateFirmMutation,
    TError,
    CreateFirmMutationVariables,
    TContext
  >(
    ['createFirm'],
    (variables?: CreateFirmMutationVariables) =>
      createMembersFetcher<CreateFirmMutation, CreateFirmMutationVariables>(
        CreateFirmDocument,
        variables
      )(),
    options
  )
export const UpdateFirmDocument = `
    mutation updateFirm($input: UpdateFirmInput!) {
  updateFirm(input: $input) {
    firm {
      id
    }
  }
}
    `
export const useUpdateFirmMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    UpdateFirmMutation,
    TError,
    UpdateFirmMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateFirmMutation,
    TError,
    UpdateFirmMutationVariables,
    TContext
  >(
    ['updateFirm'],
    (variables?: UpdateFirmMutationVariables) =>
      createMembersFetcher<UpdateFirmMutation, UpdateFirmMutationVariables>(
        UpdateFirmDocument,
        variables
      )(),
    options
  )
export const CreateServiceIdentityDocument = `
    mutation createServiceIdentity($input: CreateServiceIdentityInput!) {
  createServiceIdentity(input: $input) {
    createdServiceIdentity {
      clientId
      clientSecret
    }
  }
}
    `
export const useCreateServiceIdentityMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateServiceIdentityMutation,
    TError,
    CreateServiceIdentityMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateServiceIdentityMutation,
    TError,
    CreateServiceIdentityMutationVariables,
    TContext
  >(
    ['createServiceIdentity'],
    (variables?: CreateServiceIdentityMutationVariables) =>
      createMembersFetcher<
        CreateServiceIdentityMutation,
        CreateServiceIdentityMutationVariables
      >(CreateServiceIdentityDocument, variables)(),
    options
  )
export const UpdateServiceIdentityDocument = `
    mutation updateServiceIdentity($input: UpdateServiceIdentityInput!) {
  updateServiceIdentity(input: $input) {
    serviceIdentity {
      id
    }
  }
}
    `
export const useUpdateServiceIdentityMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateServiceIdentityMutation,
    TError,
    UpdateServiceIdentityMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateServiceIdentityMutation,
    TError,
    UpdateServiceIdentityMutationVariables,
    TContext
  >(
    ['updateServiceIdentity'],
    (variables?: UpdateServiceIdentityMutationVariables) =>
      createMembersFetcher<
        UpdateServiceIdentityMutation,
        UpdateServiceIdentityMutationVariables
      >(UpdateServiceIdentityDocument, variables)(),
    options
  )
export const DeleteServiceIdentityDocument = `
    mutation deleteServiceIdentity($id: ID!) {
  deleteServiceIdentity(input: $id) {
    id
  }
}
    `
export const useDeleteServiceIdentityMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteServiceIdentityMutation,
    TError,
    DeleteServiceIdentityMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteServiceIdentityMutation,
    TError,
    DeleteServiceIdentityMutationVariables,
    TContext
  >(
    ['deleteServiceIdentity'],
    (variables?: DeleteServiceIdentityMutationVariables) =>
      createMembersFetcher<
        DeleteServiceIdentityMutation,
        DeleteServiceIdentityMutationVariables
      >(DeleteServiceIdentityDocument, variables)(),
    options
  )
export const UpdateFirmCustodianDocument = `
    mutation updateFirmCustodian($firmId: ID!, $custodianIds: [ID!]!) {
  custodian {
    updateFirmList(firmId: $firmId, custodianIds: $custodianIds) {
      custodians {
        id
        version
        name
        default
      }
      customList
    }
  }
}
    `
export const useUpdateFirmCustodianMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateFirmCustodianMutation,
    TError,
    UpdateFirmCustodianMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateFirmCustodianMutation,
    TError,
    UpdateFirmCustodianMutationVariables,
    TContext
  >(
    ['updateFirmCustodian'],
    (variables?: UpdateFirmCustodianMutationVariables) =>
      createMembersFetcher<
        UpdateFirmCustodianMutation,
        UpdateFirmCustodianMutationVariables
      >(UpdateFirmCustodianDocument, variables)(),
    options
  )
export const GetFirmCustodiansDocument = `
    query getFirmCustodians($firmId: ID!) {
  custodian {
    firmCustodianList(firmId: $firmId) {
      custodians {
        id
        version
        name
        default
      }
      customList
    }
  }
}
    `
export const useGetFirmCustodiansQuery = <
  TData = GetFirmCustodiansQuery,
  TError = unknown
>(
  variables: GetFirmCustodiansQueryVariables,
  options?: UseQueryOptions<GetFirmCustodiansQuery, TError, TData>
) =>
  useQuery<GetFirmCustodiansQuery, TError, TData>(
    ['getFirmCustodians', variables],
    createMembersFetcher<
      GetFirmCustodiansQuery,
      GetFirmCustodiansQueryVariables
    >(GetFirmCustodiansDocument, variables),
    options
  )
export const useInfiniteGetFirmCustodiansQuery = <
  TData = GetFirmCustodiansQuery,
  TError = unknown
>(
  variables: GetFirmCustodiansQueryVariables,
  options?: UseInfiniteQueryOptions<GetFirmCustodiansQuery, TError, TData>
) => {
  return useInfiniteQuery<GetFirmCustodiansQuery, TError, TData>(
    ['getFirmCustodians.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        GetFirmCustodiansQuery,
        GetFirmCustodiansQueryVariables
      >(GetFirmCustodiansDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const CreateCustodianDocument = `
    mutation createCustodian($custodian: CustodianInput!) {
  custodian {
    create(custodian: $custodian) {
      accountNumberRegex
      id
      name
      nameCode
      dtcc
      dtccCustodianId
      qualifiedInvestorPrefix
      qualifiedInvestorPostfix
      nonQualifiedInvestorPrefix
      nonQualifiedInvestorPostfix
      esignEnabled
      esignAuthenticationMethod
      carbonCopyEnabled
      carbonCopyEmails
      contacts {
        name
        email
        phone
      }
      default
      firmOverrides
      platforms {
        name
        id
      }
      wireInstructions {
        bankDetails {
          name
          country
          street1
          street2
          city
          state
          postalCode
          swiftCode
          wireTransferCode
        }
        accountDetails {
          type
          name
          number
          furtherCreditOptions {
            optionType
            accountName
            accountNumber
          }
        }
      }
    }
  }
}
    `
export const useCreateCustodianMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateCustodianMutation,
    TError,
    CreateCustodianMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateCustodianMutation,
    TError,
    CreateCustodianMutationVariables,
    TContext
  >(
    ['createCustodian'],
    (variables?: CreateCustodianMutationVariables) =>
      createMembersFetcher<
        CreateCustodianMutation,
        CreateCustodianMutationVariables
      >(CreateCustodianDocument, variables)(),
    options
  )
export const UpdateCustodianDocument = `
    mutation updateCustodian($custodianId: ID!, $custodian: CustodianInput!) {
  custodian {
    update(custodianId: $custodianId, custodian: $custodian) {
      accountNumberRegex
      id
      name
      nameCode
      dtcc
      dtccCustodianId
      qualifiedInvestorPrefix
      qualifiedInvestorPostfix
      nonQualifiedInvestorPrefix
      nonQualifiedInvestorPostfix
      esignEnabled
      esignAuthenticationMethod
      carbonCopyEnabled
      carbonCopyEmails
      contacts {
        name
        email
        phone
      }
      default
      firmOverrides
      platforms {
        name
        id
      }
      wireInstructions {
        bankDetails {
          name
          country
          street1
          street2
          city
          state
          postalCode
          swiftCode
          wireTransferCode
        }
        accountDetails {
          type
          name
          number
          furtherCreditOptions {
            optionType
            accountName
            accountNumber
          }
        }
      }
    }
  }
}
    `
export const useUpdateCustodianMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateCustodianMutation,
    TError,
    UpdateCustodianMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateCustodianMutation,
    TError,
    UpdateCustodianMutationVariables,
    TContext
  >(
    ['updateCustodian'],
    (variables?: UpdateCustodianMutationVariables) =>
      createMembersFetcher<
        UpdateCustodianMutation,
        UpdateCustodianMutationVariables
      >(UpdateCustodianDocument, variables)(),
    options
  )
export const GetCustodianDetailsDocument = `
    query getCustodianDetails($id: ID!) {
  custodian {
    get(id: $id) {
      accountNumberRegex
      id
      carbonCopyEmails
      carbonCopyEnabled
      createdAt
      createdBy
      contacts {
        email
        name
        phone
      }
      default
      dtcc
      dtccCustodianId
      esignAuthenticationMethod
      esignEnabled
      firmOverrides
      name
      nameCode
      nonQualifiedInvestorPostfix
      nonQualifiedInvestorPrefix
      platforms {
        name
        id
      }
      qualifiedInvestorPostfix
      qualifiedInvestorPrefix
      version
      wireInstructions {
        accountDetails {
          furtherCreditOptions {
            accountName
            accountNumber
            optionType
          }
          name
          number
          type
        }
        bankDetails {
          city
          country
          name
          postalCode
          state
          street1
          street2
          swiftCode
          wireTransferCode
        }
      }
    }
  }
}
    `
export const useGetCustodianDetailsQuery = <
  TData = GetCustodianDetailsQuery,
  TError = unknown
>(
  variables: GetCustodianDetailsQueryVariables,
  options?: UseQueryOptions<GetCustodianDetailsQuery, TError, TData>
) =>
  useQuery<GetCustodianDetailsQuery, TError, TData>(
    ['getCustodianDetails', variables],
    createMembersFetcher<
      GetCustodianDetailsQuery,
      GetCustodianDetailsQueryVariables
    >(GetCustodianDetailsDocument, variables),
    options
  )
export const useInfiniteGetCustodianDetailsQuery = <
  TData = GetCustodianDetailsQuery,
  TError = unknown
>(
  variables: GetCustodianDetailsQueryVariables,
  options?: UseInfiniteQueryOptions<GetCustodianDetailsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetCustodianDetailsQuery, TError, TData>(
    ['getCustodianDetails.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        GetCustodianDetailsQuery,
        GetCustodianDetailsQueryVariables
      >(GetCustodianDetailsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetPlatformTypesDocument = `
    query getPlatformTypes {
  custodian {
    platformTypes {
      platforms {
        id
        name
      }
    }
  }
}
    `
export const useGetPlatformTypesQuery = <
  TData = GetPlatformTypesQuery,
  TError = unknown
>(
  variables?: GetPlatformTypesQueryVariables,
  options?: UseQueryOptions<GetPlatformTypesQuery, TError, TData>
) =>
  useQuery<GetPlatformTypesQuery, TError, TData>(
    variables === undefined
      ? ['getPlatformTypes']
      : ['getPlatformTypes', variables],
    createMembersFetcher<GetPlatformTypesQuery, GetPlatformTypesQueryVariables>(
      GetPlatformTypesDocument,
      variables
    ),
    options
  )
export const useInfiniteGetPlatformTypesQuery = <
  TData = GetPlatformTypesQuery,
  TError = unknown
>(
  variables?: GetPlatformTypesQueryVariables,
  options?: UseInfiniteQueryOptions<GetPlatformTypesQuery, TError, TData>
) => {
  return useInfiniteQuery<GetPlatformTypesQuery, TError, TData>(
    variables === undefined
      ? ['getPlatformTypes.infinite']
      : ['getPlatformTypes.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        GetPlatformTypesQuery,
        GetPlatformTypesQueryVariables
      >(GetPlatformTypesDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const SearchCustodiansDocument = `
    query searchCustodians($input: CustodianSearchInput!) {
  custodian {
    search(input: $input) {
      items {
        id
        name
        default
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
}
    `
export const useSearchCustodiansQuery = <
  TData = SearchCustodiansQuery,
  TError = unknown
>(
  variables: SearchCustodiansQueryVariables,
  options?: UseQueryOptions<SearchCustodiansQuery, TError, TData>
) =>
  useQuery<SearchCustodiansQuery, TError, TData>(
    ['searchCustodians', variables],
    createMembersFetcher<SearchCustodiansQuery, SearchCustodiansQueryVariables>(
      SearchCustodiansDocument,
      variables
    ),
    options
  )
export const useInfiniteSearchCustodiansQuery = <
  TData = SearchCustodiansQuery,
  TError = unknown
>(
  variables: SearchCustodiansQueryVariables,
  options?: UseInfiniteQueryOptions<SearchCustodiansQuery, TError, TData>
) => {
  return useInfiniteQuery<SearchCustodiansQuery, TError, TData>(
    ['searchCustodians.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        SearchCustodiansQuery,
        SearchCustodiansQueryVariables
      >(SearchCustodiansDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetTeamsUsersDocument = `
    query getTeamsUsers($teams: [ID!], $pagination: PaginationInput) {
  users(filter: {teamId: {in: $teams}}, pagination: $pagination) {
    items {
      id
      firstName
      lastName
    }
  }
}
    `
export const useGetTeamsUsersQuery = <
  TData = GetTeamsUsersQuery,
  TError = unknown
>(
  variables?: GetTeamsUsersQueryVariables,
  options?: UseQueryOptions<GetTeamsUsersQuery, TError, TData>
) =>
  useQuery<GetTeamsUsersQuery, TError, TData>(
    variables === undefined ? ['getTeamsUsers'] : ['getTeamsUsers', variables],
    createMembersFetcher<GetTeamsUsersQuery, GetTeamsUsersQueryVariables>(
      GetTeamsUsersDocument,
      variables
    ),
    options
  )
export const useInfiniteGetTeamsUsersQuery = <
  TData = GetTeamsUsersQuery,
  TError = unknown
>(
  variables?: GetTeamsUsersQueryVariables,
  options?: UseInfiniteQueryOptions<GetTeamsUsersQuery, TError, TData>
) => {
  return useInfiniteQuery<GetTeamsUsersQuery, TError, TData>(
    variables === undefined
      ? ['getTeamsUsers.infinite']
      : ['getTeamsUsers.infinite', variables],
    (metaData) =>
      createMembersFetcher<GetTeamsUsersQuery, GetTeamsUsersQueryVariables>(
        GetTeamsUsersDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const GetUserTeamsDocument = `
    query getUserTeams($userId: ID!) {
  user(userId: $userId) {
    parents {
      parentId
      parentType
      parentName
    }
  }
}
    `
export const useGetUserTeamsQuery = <
  TData = GetUserTeamsQuery,
  TError = unknown
>(
  variables: GetUserTeamsQueryVariables,
  options?: UseQueryOptions<GetUserTeamsQuery, TError, TData>
) =>
  useQuery<GetUserTeamsQuery, TError, TData>(
    ['getUserTeams', variables],
    createMembersFetcher<GetUserTeamsQuery, GetUserTeamsQueryVariables>(
      GetUserTeamsDocument,
      variables
    ),
    options
  )
export const useInfiniteGetUserTeamsQuery = <
  TData = GetUserTeamsQuery,
  TError = unknown
>(
  variables: GetUserTeamsQueryVariables,
  options?: UseInfiniteQueryOptions<GetUserTeamsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetUserTeamsQuery, TError, TData>(
    ['getUserTeams.infinite', variables],
    (metaData) =>
      createMembersFetcher<GetUserTeamsQuery, GetUserTeamsQueryVariables>(
        GetUserTeamsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const FundProductSearchDocument = `
    query fundProductSearch($pagination: PaginationInput, $filter: FundFilterInput) {
  fundProductSearch(pagination: $pagination, filter: $filter) {
    items {
      id
      generalInformation {
        legalName
      }
    }
  }
}
    `
export const useFundProductSearchQuery = <
  TData = FundProductSearchQuery,
  TError = unknown
>(
  variables?: FundProductSearchQueryVariables,
  options?: UseQueryOptions<FundProductSearchQuery, TError, TData>
) =>
  useQuery<FundProductSearchQuery, TError, TData>(
    variables === undefined
      ? ['fundProductSearch']
      : ['fundProductSearch', variables],
    createMembersFetcher<
      FundProductSearchQuery,
      FundProductSearchQueryVariables
    >(FundProductSearchDocument, variables),
    options
  )
export const useInfiniteFundProductSearchQuery = <
  TData = FundProductSearchQuery,
  TError = unknown
>(
  variables?: FundProductSearchQueryVariables,
  options?: UseInfiniteQueryOptions<FundProductSearchQuery, TError, TData>
) => {
  return useInfiniteQuery<FundProductSearchQuery, TError, TData>(
    variables === undefined
      ? ['fundProductSearch.infinite']
      : ['fundProductSearch.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        FundProductSearchQuery,
        FundProductSearchQueryVariables
      >(FundProductSearchDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const HoiCaisIqTableFirmIdDocument = `
    query hoiCaisIqTableFirmId {
  userProfile {
    firm {
      id
    }
  }
}
    `
export const useHoiCaisIqTableFirmIdQuery = <
  TData = HoiCaisIqTableFirmIdQuery,
  TError = unknown
>(
  variables?: HoiCaisIqTableFirmIdQueryVariables,
  options?: UseQueryOptions<HoiCaisIqTableFirmIdQuery, TError, TData>
) =>
  useQuery<HoiCaisIqTableFirmIdQuery, TError, TData>(
    variables === undefined
      ? ['hoiCaisIqTableFirmId']
      : ['hoiCaisIqTableFirmId', variables],
    createMembersFetcher<
      HoiCaisIqTableFirmIdQuery,
      HoiCaisIqTableFirmIdQueryVariables
    >(HoiCaisIqTableFirmIdDocument, variables),
    options
  )
export const useInfiniteHoiCaisIqTableFirmIdQuery = <
  TData = HoiCaisIqTableFirmIdQuery,
  TError = unknown
>(
  variables?: HoiCaisIqTableFirmIdQueryVariables,
  options?: UseInfiniteQueryOptions<HoiCaisIqTableFirmIdQuery, TError, TData>
) => {
  return useInfiniteQuery<HoiCaisIqTableFirmIdQuery, TError, TData>(
    variables === undefined
      ? ['hoiCaisIqTableFirmId.infinite']
      : ['hoiCaisIqTableFirmId.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        HoiCaisIqTableFirmIdQuery,
        HoiCaisIqTableFirmIdQueryVariables
      >(HoiCaisIqTableFirmIdDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const BulkDeleteStructuredProductsDocument = `
    mutation bulkDeleteStructuredProducts($productsIds: [ID!]!) {
  bulkDeleteStructuredProducts(productsIds: $productsIds) {
    deletionResponse {
      failedProductIds
      deletedProductIds
    }
    error {
      message
    }
  }
}
    `
export const useBulkDeleteStructuredProductsMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    BulkDeleteStructuredProductsMutation,
    TError,
    BulkDeleteStructuredProductsMutationVariables,
    TContext
  >
) =>
  useMutation<
    BulkDeleteStructuredProductsMutation,
    TError,
    BulkDeleteStructuredProductsMutationVariables,
    TContext
  >(
    ['bulkDeleteStructuredProducts'],
    (variables?: BulkDeleteStructuredProductsMutationVariables) =>
      createMembersFetcher<
        BulkDeleteStructuredProductsMutation,
        BulkDeleteStructuredProductsMutationVariables
      >(BulkDeleteStructuredProductsDocument, variables)(),
    options
  )
export const CreateDraftStructuredProductDocument = `
    mutation createDraftStructuredProduct($identifierType: ProductIdentifierType!, $value: String!) {
  createDraftStructuredProduct(identifierType: $identifierType, value: $value) {
    ... on CreateDraftStructuredProductPayload {
      error {
        message
      }
      structuredProduct {
        id
      }
    }
  }
}
    `
export const useCreateDraftStructuredProductMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateDraftStructuredProductMutation,
    TError,
    CreateDraftStructuredProductMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateDraftStructuredProductMutation,
    TError,
    CreateDraftStructuredProductMutationVariables,
    TContext
  >(
    ['createDraftStructuredProduct'],
    (variables?: CreateDraftStructuredProductMutationVariables) =>
      createMembersFetcher<
        CreateDraftStructuredProductMutation,
        CreateDraftStructuredProductMutationVariables
      >(CreateDraftStructuredProductDocument, variables)(),
    options
  )
export const DeleteStructuredProductDocument = `
    mutation deleteStructuredProduct($id: ID!) {
  deleteStructuredProduct(id: $id) {
    error {
      message
    }
    id
  }
}
    `
export const useDeleteStructuredProductMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    DeleteStructuredProductMutation,
    TError,
    DeleteStructuredProductMutationVariables,
    TContext
  >
) =>
  useMutation<
    DeleteStructuredProductMutation,
    TError,
    DeleteStructuredProductMutationVariables,
    TContext
  >(
    ['deleteStructuredProduct'],
    (variables?: DeleteStructuredProductMutationVariables) =>
      createMembersFetcher<
        DeleteStructuredProductMutation,
        DeleteStructuredProductMutationVariables
      >(DeleteStructuredProductDocument, variables)(),
    options
  )
export const DraftStructuredProductUploadDocument = `
    query draftStructuredProductUpload($id: ID!) {
  draftStructuredProductUpload(id: $id) {
    status
  }
}
    `
export const useDraftStructuredProductUploadQuery = <
  TData = DraftStructuredProductUploadQuery,
  TError = unknown
>(
  variables: DraftStructuredProductUploadQueryVariables,
  options?: UseQueryOptions<DraftStructuredProductUploadQuery, TError, TData>
) =>
  useQuery<DraftStructuredProductUploadQuery, TError, TData>(
    ['draftStructuredProductUpload', variables],
    createMembersFetcher<
      DraftStructuredProductUploadQuery,
      DraftStructuredProductUploadQueryVariables
    >(DraftStructuredProductUploadDocument, variables),
    options
  )
export const useInfiniteDraftStructuredProductUploadQuery = <
  TData = DraftStructuredProductUploadQuery,
  TError = unknown
>(
  variables: DraftStructuredProductUploadQueryVariables,
  options?: UseInfiniteQueryOptions<
    DraftStructuredProductUploadQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<DraftStructuredProductUploadQuery, TError, TData>(
    ['draftStructuredProductUpload.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        DraftStructuredProductUploadQuery,
        DraftStructuredProductUploadQueryVariables
      >(DraftStructuredProductUploadDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const DraftStructuredProductUploadPublishAllStatusDocument = `
    query draftStructuredProductUploadPublishAllStatus($id: ID!, $productUploadId: ID!) {
  draftStructuredProductUploadPublishAllStatus(
    id: $id
    productUploadId: $productUploadId
  ) {
    status
  }
}
    `
export const useDraftStructuredProductUploadPublishAllStatusQuery = <
  TData = DraftStructuredProductUploadPublishAllStatusQuery,
  TError = unknown
>(
  variables: DraftStructuredProductUploadPublishAllStatusQueryVariables,
  options?: UseQueryOptions<
    DraftStructuredProductUploadPublishAllStatusQuery,
    TError,
    TData
  >
) =>
  useQuery<DraftStructuredProductUploadPublishAllStatusQuery, TError, TData>(
    ['draftStructuredProductUploadPublishAllStatus', variables],
    createMembersFetcher<
      DraftStructuredProductUploadPublishAllStatusQuery,
      DraftStructuredProductUploadPublishAllStatusQueryVariables
    >(DraftStructuredProductUploadPublishAllStatusDocument, variables),
    options
  )
export const useInfiniteDraftStructuredProductUploadPublishAllStatusQuery = <
  TData = DraftStructuredProductUploadPublishAllStatusQuery,
  TError = unknown
>(
  variables: DraftStructuredProductUploadPublishAllStatusQueryVariables,
  options?: UseInfiniteQueryOptions<
    DraftStructuredProductUploadPublishAllStatusQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<
    DraftStructuredProductUploadPublishAllStatusQuery,
    TError,
    TData
  >(
    ['draftStructuredProductUploadPublishAllStatus.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        DraftStructuredProductUploadPublishAllStatusQuery,
        DraftStructuredProductUploadPublishAllStatusQueryVariables
      >(DraftStructuredProductUploadPublishAllStatusDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const DraftStructuredProductUploadResultsDocument = `
    query draftStructuredProductUploadResults($id: ID!, $filter: DraftUploadResultFilterInput, $pagination: PaginationInput, $sort: [DraftUploadResultSort!]) {
  draftStructuredProductUploadResults(
    id: $id
    filter: $filter
    pagination: $pagination
    sort: $sort
  ) {
    items {
      id
      structuredProduct {
        ...fieldsToRetrieve
      }
      productIdentifier
      productUploadId
      errorCode
      reason
      status
      version
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${FieldsToRetrieveFragmentDoc}`
export const useDraftStructuredProductUploadResultsQuery = <
  TData = DraftStructuredProductUploadResultsQuery,
  TError = unknown
>(
  variables: DraftStructuredProductUploadResultsQueryVariables,
  options?: UseQueryOptions<
    DraftStructuredProductUploadResultsQuery,
    TError,
    TData
  >
) =>
  useQuery<DraftStructuredProductUploadResultsQuery, TError, TData>(
    ['draftStructuredProductUploadResults', variables],
    createMembersFetcher<
      DraftStructuredProductUploadResultsQuery,
      DraftStructuredProductUploadResultsQueryVariables
    >(DraftStructuredProductUploadResultsDocument, variables),
    options
  )
export const useInfiniteDraftStructuredProductUploadResultsQuery = <
  TData = DraftStructuredProductUploadResultsQuery,
  TError = unknown
>(
  variables: DraftStructuredProductUploadResultsQueryVariables,
  options?: UseInfiniteQueryOptions<
    DraftStructuredProductUploadResultsQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<
    DraftStructuredProductUploadResultsQuery,
    TError,
    TData
  >(
    ['draftStructuredProductUploadResults.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        DraftStructuredProductUploadResultsQuery,
        DraftStructuredProductUploadResultsQueryVariables
      >(DraftStructuredProductUploadResultsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const DraftStructuredProductUploadsDocument = `
    query draftStructuredProductUploads($filter: DraftUploadFilterInput, $pagination: PaginationInput, $sort: [DraftUploadSort!]) {
  draftStructuredProductUploads(
    filter: $filter
    pagination: $pagination
    sort: $sort
  ) {
    items {
      id
      fileName
      createdBy
      createdAt
      status
      createdByUser {
        email
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `
export const useDraftStructuredProductUploadsQuery = <
  TData = DraftStructuredProductUploadsQuery,
  TError = unknown
>(
  variables?: DraftStructuredProductUploadsQueryVariables,
  options?: UseQueryOptions<DraftStructuredProductUploadsQuery, TError, TData>
) =>
  useQuery<DraftStructuredProductUploadsQuery, TError, TData>(
    variables === undefined
      ? ['draftStructuredProductUploads']
      : ['draftStructuredProductUploads', variables],
    createMembersFetcher<
      DraftStructuredProductUploadsQuery,
      DraftStructuredProductUploadsQueryVariables
    >(DraftStructuredProductUploadsDocument, variables),
    options
  )
export const useInfiniteDraftStructuredProductUploadsQuery = <
  TData = DraftStructuredProductUploadsQuery,
  TError = unknown
>(
  variables?: DraftStructuredProductUploadsQueryVariables,
  options?: UseInfiniteQueryOptions<
    DraftStructuredProductUploadsQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<DraftStructuredProductUploadsQuery, TError, TData>(
    variables === undefined
      ? ['draftStructuredProductUploads.infinite']
      : ['draftStructuredProductUploads.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        DraftStructuredProductUploadsQuery,
        DraftStructuredProductUploadsQueryVariables
      >(DraftStructuredProductUploadsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetStructuredProductsDocument = `
    query getStructuredProducts($sort: [StructuredProductSort!], $filter: StructuredProductFilterInput, $pagination: PaginationInput) {
  structuredProducts(sort: $sort, filter: $filter, pagination: $pagination) {
    items {
      id
      version
      caisId
      basketName {
        value
      }
      errors {
        code
      }
      basketType {
        value
      }
      underlierData {
        underliers {
          ticker
          legalName
          weight
        }
      }
      issuer {
        value {
          acronym
          logoURLs {
            thumbnail
          }
        }
      }
      structure {
        name {
          value
        }
        subType {
          value
        }
      }
      identifiers {
        type
        value
      }
      tenor {
        value {
          final
        }
      }
      keyDates {
        trade {
          value
        }
        closing {
          value
        }
      }
      publishState
    }
    pageInfo {
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `
export const useGetStructuredProductsQuery = <
  TData = GetStructuredProductsQuery,
  TError = unknown
>(
  variables?: GetStructuredProductsQueryVariables,
  options?: UseQueryOptions<GetStructuredProductsQuery, TError, TData>
) =>
  useQuery<GetStructuredProductsQuery, TError, TData>(
    variables === undefined
      ? ['getStructuredProducts']
      : ['getStructuredProducts', variables],
    createMembersFetcher<
      GetStructuredProductsQuery,
      GetStructuredProductsQueryVariables
    >(GetStructuredProductsDocument, variables),
    options
  )
export const useInfiniteGetStructuredProductsQuery = <
  TData = GetStructuredProductsQuery,
  TError = unknown
>(
  variables?: GetStructuredProductsQueryVariables,
  options?: UseInfiniteQueryOptions<GetStructuredProductsQuery, TError, TData>
) => {
  return useInfiniteQuery<GetStructuredProductsQuery, TError, TData>(
    variables === undefined
      ? ['getStructuredProducts.infinite']
      : ['getStructuredProducts.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        GetStructuredProductsQuery,
        GetStructuredProductsQueryVariables
      >(GetStructuredProductsDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetStructuredProductDocument = `
    query getStructuredProduct($id: ID!) {
  structuredProduct(id: $id) {
    ...fieldsToRetrieve
  }
}
    ${FieldsToRetrieveFragmentDoc}`
export const useGetStructuredProductQuery = <
  TData = GetStructuredProductQuery,
  TError = unknown
>(
  variables: GetStructuredProductQueryVariables,
  options?: UseQueryOptions<GetStructuredProductQuery, TError, TData>
) =>
  useQuery<GetStructuredProductQuery, TError, TData>(
    ['getStructuredProduct', variables],
    createMembersFetcher<
      GetStructuredProductQuery,
      GetStructuredProductQueryVariables
    >(GetStructuredProductDocument, variables),
    options
  )
export const useInfiniteGetStructuredProductQuery = <
  TData = GetStructuredProductQuery,
  TError = unknown
>(
  variables: GetStructuredProductQueryVariables,
  options?: UseInfiniteQueryOptions<GetStructuredProductQuery, TError, TData>
) => {
  return useInfiniteQuery<GetStructuredProductQuery, TError, TData>(
    ['getStructuredProduct.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        GetStructuredProductQuery,
        GetStructuredProductQueryVariables
      >(GetStructuredProductDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetStructuredProductsForFormDocument = `
    query getStructuredProductsForForm($filter: StructuredProductFilterInput) {
  structuredProducts(filter: $filter, pagination: {first: 1}) {
    items {
      id
      publishState
    }
  }
}
    `
export const useGetStructuredProductsForFormQuery = <
  TData = GetStructuredProductsForFormQuery,
  TError = unknown
>(
  variables?: GetStructuredProductsForFormQueryVariables,
  options?: UseQueryOptions<GetStructuredProductsForFormQuery, TError, TData>
) =>
  useQuery<GetStructuredProductsForFormQuery, TError, TData>(
    variables === undefined
      ? ['getStructuredProductsForForm']
      : ['getStructuredProductsForForm', variables],
    createMembersFetcher<
      GetStructuredProductsForFormQuery,
      GetStructuredProductsForFormQueryVariables
    >(GetStructuredProductsForFormDocument, variables),
    options
  )
export const useInfiniteGetStructuredProductsForFormQuery = <
  TData = GetStructuredProductsForFormQuery,
  TError = unknown
>(
  variables?: GetStructuredProductsForFormQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetStructuredProductsForFormQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<GetStructuredProductsForFormQuery, TError, TData>(
    variables === undefined
      ? ['getStructuredProductsForForm.infinite']
      : ['getStructuredProductsForForm.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        GetStructuredProductsForFormQuery,
        GetStructuredProductsForFormQueryVariables
      >(GetStructuredProductsForFormDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const PublishDraftStructuredProductDocument = `
    mutation publishDraftStructuredProduct($id: ID!, $version: Int!) {
  publishDraftStructuredProduct(id: $id, version: $version) {
    structuredProduct {
      ...fieldsToRetrieve
    }
    error {
      message
    }
  }
}
    ${FieldsToRetrieveFragmentDoc}`
export const usePublishDraftStructuredProductMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    PublishDraftStructuredProductMutation,
    TError,
    PublishDraftStructuredProductMutationVariables,
    TContext
  >
) =>
  useMutation<
    PublishDraftStructuredProductMutation,
    TError,
    PublishDraftStructuredProductMutationVariables,
    TContext
  >(
    ['publishDraftStructuredProduct'],
    (variables?: PublishDraftStructuredProductMutationVariables) =>
      createMembersFetcher<
        PublishDraftStructuredProductMutation,
        PublishDraftStructuredProductMutationVariables
      >(PublishDraftStructuredProductDocument, variables)(),
    options
  )
export const PublishDraftStructuredProductsDocument = `
    mutation publishDraftStructuredProducts($productUploadId: ID!, $input: DraftUploadPublishAllInput!) {
  publishDraftStructuredProducts(productUploadId: $productUploadId, input: $input) {
    result {
      id
      productUploadId
    }
    error {
      message
    }
  }
}
    `
export const usePublishDraftStructuredProductsMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    PublishDraftStructuredProductsMutation,
    TError,
    PublishDraftStructuredProductsMutationVariables,
    TContext
  >
) =>
  useMutation<
    PublishDraftStructuredProductsMutation,
    TError,
    PublishDraftStructuredProductsMutationVariables,
    TContext
  >(
    ['publishDraftStructuredProducts'],
    (variables?: PublishDraftStructuredProductsMutationVariables) =>
      createMembersFetcher<
        PublishDraftStructuredProductsMutation,
        PublishDraftStructuredProductsMutationVariables
      >(PublishDraftStructuredProductsDocument, variables)(),
    options
  )
export const UpdateStructuredProductDocument = `
    mutation updateStructuredProduct($id: ID!, $version: Int!, $input: StructuredProductInput!) {
  updateStructuredProduct(id: $id, version: $version, input: $input) {
    structuredProduct {
      ...fieldsToRetrieve
    }
    error {
      message
      errorAttributes
    }
  }
}
    ${FieldsToRetrieveFragmentDoc}`
export const useUpdateStructuredProductMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateStructuredProductMutation,
    TError,
    UpdateStructuredProductMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateStructuredProductMutation,
    TError,
    UpdateStructuredProductMutationVariables,
    TContext
  >(
    ['updateStructuredProduct'],
    (variables?: UpdateStructuredProductMutationVariables) =>
      createMembersFetcher<
        UpdateStructuredProductMutation,
        UpdateStructuredProductMutationVariables
      >(UpdateStructuredProductDocument, variables)(),
    options
  )
export const CreateStructuredProductStructureTemplateDocument = `
    mutation createStructuredProductStructureTemplate($input: StructuredProductStructureTemplateInput!) {
  createStructuredProductStructureTemplate(input: $input) {
    structuredProductStructureTemplate {
      ...templateFieldsToRetrieve
    }
    error {
      message
    }
  }
}
    ${TemplateFieldsToRetrieveFragmentDoc}`
export const useCreateStructuredProductStructureTemplateMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateStructuredProductStructureTemplateMutation,
    TError,
    CreateStructuredProductStructureTemplateMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateStructuredProductStructureTemplateMutation,
    TError,
    CreateStructuredProductStructureTemplateMutationVariables,
    TContext
  >(
    ['createStructuredProductStructureTemplate'],
    (variables?: CreateStructuredProductStructureTemplateMutationVariables) =>
      createMembersFetcher<
        CreateStructuredProductStructureTemplateMutation,
        CreateStructuredProductStructureTemplateMutationVariables
      >(CreateStructuredProductStructureTemplateDocument, variables)(),
    options
  )
export const GetStructureTemplateDocument = `
    query getStructureTemplate($id: ID!) {
  structuredProductStructureTemplate(id: $id) {
    ...templateFieldsToRetrieve
  }
}
    ${TemplateFieldsToRetrieveFragmentDoc}`
export const useGetStructureTemplateQuery = <
  TData = GetStructureTemplateQuery,
  TError = unknown
>(
  variables: GetStructureTemplateQueryVariables,
  options?: UseQueryOptions<GetStructureTemplateQuery, TError, TData>
) =>
  useQuery<GetStructureTemplateQuery, TError, TData>(
    ['getStructureTemplate', variables],
    createMembersFetcher<
      GetStructureTemplateQuery,
      GetStructureTemplateQueryVariables
    >(GetStructureTemplateDocument, variables),
    options
  )
export const useInfiniteGetStructureTemplateQuery = <
  TData = GetStructureTemplateQuery,
  TError = unknown
>(
  variables: GetStructureTemplateQueryVariables,
  options?: UseInfiniteQueryOptions<GetStructureTemplateQuery, TError, TData>
) => {
  return useInfiniteQuery<GetStructureTemplateQuery, TError, TData>(
    ['getStructureTemplate.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        GetStructureTemplateQuery,
        GetStructureTemplateQueryVariables
      >(GetStructureTemplateDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetStructureTemplatesDocument = `
    query getStructureTemplates($sort: [StructuredProductStructureTemplateSort!], $pagination: PaginationInput, $filter: StructuredProductStructureTemplateFilterInput) {
  structuredProductStructureTemplates(
    sort: $sort
    pagination: $pagination
    filter: $filter
  ) {
    items {
      id
      caisId
      subType {
        value
      }
      name {
        value
      }
    }
    pageInfo {
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `
export const useGetStructureTemplatesQuery = <
  TData = GetStructureTemplatesQuery,
  TError = unknown
>(
  variables?: GetStructureTemplatesQueryVariables,
  options?: UseQueryOptions<GetStructureTemplatesQuery, TError, TData>
) =>
  useQuery<GetStructureTemplatesQuery, TError, TData>(
    variables === undefined
      ? ['getStructureTemplates']
      : ['getStructureTemplates', variables],
    createMembersFetcher<
      GetStructureTemplatesQuery,
      GetStructureTemplatesQueryVariables
    >(GetStructureTemplatesDocument, variables),
    options
  )
export const useInfiniteGetStructureTemplatesQuery = <
  TData = GetStructureTemplatesQuery,
  TError = unknown
>(
  variables?: GetStructureTemplatesQueryVariables,
  options?: UseInfiniteQueryOptions<GetStructureTemplatesQuery, TError, TData>
) => {
  return useInfiniteQuery<GetStructureTemplatesQuery, TError, TData>(
    variables === undefined
      ? ['getStructureTemplates.infinite']
      : ['getStructureTemplates.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        GetStructureTemplatesQuery,
        GetStructureTemplatesQueryVariables
      >(GetStructureTemplatesDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const GetStructuredProductsNamesDocument = `
    query getStructuredProductsNames($pagination: PaginationInput) {
  structuredProductStructureTemplates(pagination: $pagination) {
    items {
      id
      name {
        value
      }
    }
    pageInfo {
      hasNextPage
      endCursor
      startCursor
    }
  }
}
    `
export const useGetStructuredProductsNamesQuery = <
  TData = GetStructuredProductsNamesQuery,
  TError = unknown
>(
  variables?: GetStructuredProductsNamesQueryVariables,
  options?: UseQueryOptions<GetStructuredProductsNamesQuery, TError, TData>
) =>
  useQuery<GetStructuredProductsNamesQuery, TError, TData>(
    variables === undefined
      ? ['getStructuredProductsNames']
      : ['getStructuredProductsNames', variables],
    createMembersFetcher<
      GetStructuredProductsNamesQuery,
      GetStructuredProductsNamesQueryVariables
    >(GetStructuredProductsNamesDocument, variables),
    options
  )
export const useInfiniteGetStructuredProductsNamesQuery = <
  TData = GetStructuredProductsNamesQuery,
  TError = unknown
>(
  variables?: GetStructuredProductsNamesQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetStructuredProductsNamesQuery,
    TError,
    TData
  >
) => {
  return useInfiniteQuery<GetStructuredProductsNamesQuery, TError, TData>(
    variables === undefined
      ? ['getStructuredProductsNames.infinite']
      : ['getStructuredProductsNames.infinite', variables],
    (metaData) =>
      createMembersFetcher<
        GetStructuredProductsNamesQuery,
        GetStructuredProductsNamesQueryVariables
      >(GetStructuredProductsNamesDocument, {
        ...variables,
        ...(metaData.pageParam ?? {}),
      })(),
    options
  )
}

export const UpdateStructuredProductStructureTemplateDocument = `
    mutation updateStructuredProductStructureTemplate($id: ID!, $version: Int!, $input: StructuredProductStructureTemplateInput!) {
  updateStructuredProductStructureTemplate(
    id: $id
    version: $version
    input: $input
  ) {
    structuredProductStructureTemplate {
      ...templateFieldsToRetrieve
    }
    error {
      message
    }
  }
}
    ${TemplateFieldsToRetrieveFragmentDoc}`
export const useUpdateStructuredProductStructureTemplateMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    UpdateStructuredProductStructureTemplateMutation,
    TError,
    UpdateStructuredProductStructureTemplateMutationVariables,
    TContext
  >
) =>
  useMutation<
    UpdateStructuredProductStructureTemplateMutation,
    TError,
    UpdateStructuredProductStructureTemplateMutationVariables,
    TContext
  >(
    ['updateStructuredProductStructureTemplate'],
    (variables?: UpdateStructuredProductStructureTemplateMutationVariables) =>
      createMembersFetcher<
        UpdateStructuredProductStructureTemplateMutation,
        UpdateStructuredProductStructureTemplateMutationVariables
      >(UpdateStructuredProductStructureTemplateDocument, variables)(),
    options
  )
export const CreateProductPermissionsDocument = `
    mutation createProductPermissions($input: [ProductPermissionInput!]!) {
  createProductPermissions(input: $input) {
    successful {
      createdAt
      productId
    }
    failures {
      productId
      reason
    }
  }
}
    `
export const useCreateProductPermissionsMutation = <
  TError = unknown,
  TContext = unknown
>(
  options?: UseMutationOptions<
    CreateProductPermissionsMutation,
    TError,
    CreateProductPermissionsMutationVariables,
    TContext
  >
) =>
  useMutation<
    CreateProductPermissionsMutation,
    TError,
    CreateProductPermissionsMutationVariables,
    TContext
  >(
    ['createProductPermissions'],
    (variables?: CreateProductPermissionsMutationVariables) =>
      createMembersFetcher<
        CreateProductPermissionsMutation,
        CreateProductPermissionsMutationVariables
      >(CreateProductPermissionsDocument, variables)(),
    options
  )
export const SearchFirmsDocument = `
    query searchFirms($query: String!) {
  firms(
    pagination: {first: 50}
    filter: {searchText: {like: $query, caseSensitive: false}}
  ) {
    items {
      id
      name
      totalUsers
    }
    pageInfo {
      hasNextPage
    }
  }
}
    `
export const useSearchFirmsQuery = <TData = SearchFirmsQuery, TError = unknown>(
  variables: SearchFirmsQueryVariables,
  options?: UseQueryOptions<SearchFirmsQuery, TError, TData>
) =>
  useQuery<SearchFirmsQuery, TError, TData>(
    ['searchFirms', variables],
    createMembersFetcher<SearchFirmsQuery, SearchFirmsQueryVariables>(
      SearchFirmsDocument,
      variables
    ),
    options
  )
export const useInfiniteSearchFirmsQuery = <
  TData = SearchFirmsQuery,
  TError = unknown
>(
  variables: SearchFirmsQueryVariables,
  options?: UseInfiniteQueryOptions<SearchFirmsQuery, TError, TData>
) => {
  return useInfiniteQuery<SearchFirmsQuery, TError, TData>(
    ['searchFirms.infinite', variables],
    (metaData) =>
      createMembersFetcher<SearchFirmsQuery, SearchFirmsQueryVariables>(
        SearchFirmsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}

export const UserRolesDocument = `
    query userRoles($userId: ID!) {
  user(userId: $userId) {
    roles {
      name
      archetype
    }
  }
}
    `
export const useUserRolesQuery = <TData = UserRolesQuery, TError = unknown>(
  variables: UserRolesQueryVariables,
  options?: UseQueryOptions<UserRolesQuery, TError, TData>
) =>
  useQuery<UserRolesQuery, TError, TData>(
    ['userRoles', variables],
    createMembersFetcher<UserRolesQuery, UserRolesQueryVariables>(
      UserRolesDocument,
      variables
    ),
    options
  )
export const useInfiniteUserRolesQuery = <
  TData = UserRolesQuery,
  TError = unknown
>(
  variables: UserRolesQueryVariables,
  options?: UseInfiniteQueryOptions<UserRolesQuery, TError, TData>
) => {
  return useInfiniteQuery<UserRolesQuery, TError, TData>(
    ['userRoles.infinite', variables],
    (metaData) =>
      createMembersFetcher<UserRolesQuery, UserRolesQueryVariables>(
        UserRolesDocument,
        { ...variables, ...(metaData.pageParam ?? {}) }
      )(),
    options
  )
}
