import MarkdownToJsx from 'markdown-to-jsx'

import { markdownToEquityMap } from './markdown-to-equity-map'

type MarkdownToJsxProps = Parameters<typeof MarkdownToJsx>[0]

export type MarkdownObj = {
  markdown: string
}

export type EquityText = string | MarkdownObj

export function isMarkdown(text: EquityText): text is MarkdownObj {
  return (text as MarkdownObj).markdown !== undefined
}

export function renderText(
  text: EquityText,
  options?: MarkdownToJsxProps['options']
): JSX.Element | string {
  return isMarkdown(text) ? (
    <Markdown options={options}>{text.markdown}</Markdown>
  ) : (
    text
  )
}

export type MarkdownProps = {
  /** The Markdown string to be converted */
  children: MarkdownToJsxProps['children']
  /** Optional overrides that can map the HTML to different class names or render specific React components. See the [markdown-to-jsx](https://www.npmjs.com/package/markdown-to-jsx) docs for more information. */
  options?: MarkdownToJsxProps['options']
}

/**
 * This component is a wrapper for [markdown-to-jsx](https://www.npmjs.com/package/markdown-to-jsx).

 * - Converts a markdown string to JSX
 * - Matches the HTML element with the appropriate Equity CSS classes
 */
export const Markdown = (props: MarkdownProps) => {
  const { children, options = {} } = props

  if (!children) {
    return null
  }

  return (
    <MarkdownToJsx options={mergeOptions(options)}>{children}</MarkdownToJsx>
  )
}

function mergeOptions(options: Required<MarkdownProps>['options']) {
  const { overrides, ...rest } = options
  return {
    ...markdownToEquityMap,
    ...rest,
    overrides: {
      ...markdownToEquityMap.overrides,
      ...overrides,
    },
  }
}
