import { useQueryParams } from '../../../hook/use-query-params'

const SESSION_KEY_ENABLED = 'previewService.enabled'
const SESSION_KEY_ENABLED_FIRM = 'previewService.enabledFirm'

export const ROLE_REQUIRED = 'portal:ROLE_CAISIQ_ADMIN' // @todo: we need to get a proper role set-up

export type UserRoleResolver = { hasRole(r: string): boolean }

let _roleResolver: UserRoleResolver

export const previewService = {
  setResolver: (roleResolver: UserRoleResolver) => {
    _roleResolver = roleResolver
  },
  set enabled(isEnabled: boolean) {
    sessionStorage.setItem(SESSION_KEY_ENABLED, JSON.stringify(isEnabled))
  },
  set enabledFirm(firmId: string | false) {
    sessionStorage.setItem(SESSION_KEY_ENABLED_FIRM, JSON.stringify(firmId))
  },
  get enabled() {
    try {
      const hasAccess = _roleResolver.hasRole(ROLE_REQUIRED)
      const switchedOn = JSON.parse(
        sessionStorage.getItem(SESSION_KEY_ENABLED) || 'false'
      )

      return Boolean(hasAccess && switchedOn)
    } catch (e) {
      console.warn(
        `Preview Service found invalid JSON set in ${SESSION_KEY_ENABLED} sessionStorage`,
        e
      )
      return false
    }
  },
  get enabledFirm() {
    try {
      const hasAccess = _roleResolver.hasRole(ROLE_REQUIRED)
      const firm = JSON.parse(
        sessionStorage.getItem(SESSION_KEY_ENABLED_FIRM as string) || 'false'
      )
      return hasAccess && firm
    } catch (e) {
      console.warn(
        `Preview Service found invalid JSON set in ${SESSION_KEY_ENABLED_FIRM} sessionStorage`
      )
      return false
    }
  },
  turnOff: () => {
    previewService.enabled = false
    previewService.enabledFirm = false
    // force a window reload, but remove
    window.location.replace(window.location.pathname)
  },
}

export const usePreviewService = () => {
  const preview = useQueryParams().get('preview')
  const previewFirmId = useQueryParams().get('id')
  try {
    const parsedPreviewParam =
      preview === 'yes' || JSON.parse(preview || 'false')
    const parsedPreviewFirmParam =
      previewFirmId || JSON.parse(previewFirmId || 'false')

    // only turn on service if preview is turned on even if a firmId is passed
    if (parsedPreviewParam) {
      previewService.enabled = true
      if (parsedPreviewFirmParam) {
        previewService.enabledFirm = previewFirmId || 'false'
      }
      // force a window reload, but remove
      window.location.replace(window.location.pathname)
    }
    // We only tuen ON from here, as otherwise any redirects without 'preview' will turn off the service
  } catch (e) {
    console.warn('Unable to parse preview as JSON')
  }
}
