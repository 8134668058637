import { FileDownload } from '@cais-group/equity/labs/file-download'
import { RichText } from '@cais-group/shared/ui/contentful/rich-text'
import { LegalPage as LegalPageData } from '@cais-group/shared/util/graphql/mfe-contentful'

interface LegalPageProps {
  data: LegalPageData
}

export function DisclosurePage({ data }: LegalPageProps) {
  const { title, text, pdfsCollection } = data
  const pdfs = pdfsCollection?.items ?? []

  return (
    <div className="mx-auto max-w-[760px] space-y-24 px-24 pt-56 lg:px-0 [&>:is(h2,h3,figure)]:!mt-56">
      <h1 data-testid="title" className="headline-xxl-strong text-primary-600">
        {title}
      </h1>
      <RichText field={text} />
      {pdfs.length > 0 && (
        <div className="space-y-24 py-56">
          {pdfs.map((doc) => (
            <FileDownload
              fileType="PDF"
              key={doc?.sys.id}
              title={doc?.pdf?.title ?? ''}
              source={doc?.pdf?.url ?? ''}
            />
          ))}
        </div>
      )}
    </div>
  )
}
