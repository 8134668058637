import { Image } from '@unpic/react'

type UnpicImageProps = Parameters<typeof Image>[0]

export function ContentfulImage(props: UnpicImageProps) {
  if (!props.src) {
    return null
  }

  // Extract unpic props so that <img> is valid
  const {
    aspectRatio,
    background = 'auto',
    layout,
    priority,
    src,
    ...imgProps
  } = props

  if (src.startsWith('/')) {
    return <img alt="" src={src} {...imgProps} />
  }

  const unpicProps = {
    ...imgProps,
    aspectRatio,
    background,
    layout,
    priority,
    src,
  } as UnpicImageProps

  return (
    <picture>
      <source srcSet={`${src}?fm=avif`} type="image/avif" />
      <source srcSet={`${src}?fm=webp`} type="image/webp" />
      <Image alt="" {...unpicProps} />
    </picture>
  )
}

export function calcAspectRatio(height?: number, width?: number) {
  return height && width ? width / height : 0
}
