import {
  useRef,
  type MutableRefObject,
  type ComponentType,
  type PropsWithChildren,
} from 'react'

import { type MenuProps } from '../types'

import { MenuItems } from './menu-items'
import * as styles from './styles'
import { useKeyboardMenuNavigation } from './use-keyboard-menu-navigation'

type MenuElement = HTMLDivElement | HTMLLIElement

export function Menu(props: MenuProps) {
  const {
    handleCloseMenu,
    handleOpenMenu,
    isMenuOpen,
    menuButton,
    menuId,
    ...otherProps
  } = props
  const buttonRef =
    useRef<HTMLButtonElement>() as MutableRefObject<HTMLButtonElement>
  const menuRef = useRef<MenuElement>() as MutableRefObject<MenuElement>

  const handleClose = (options?: { refocus?: boolean }) => {
    if (options?.refocus !== false) {
      buttonRef?.current?.focus()
    }
    handleCloseMenu()
  }

  useKeyboardMenuNavigation({
    handleClose,
    isMenuOpen,
    menuRef,
  })

  const handleOpen = () => {
    handleOpenMenu(menuId)
  }

  // Toggles menu open/close for mobile and keyboard
  const handleMenuButtonClick = () => {
    isMenuOpen ? handleClose() : handleOpen()
  }

  const Wrapper = (menuId === 'profile-menu'
    ? 'div'
    : 'li') as unknown as ComponentType<
    PropsWithChildren<Record<string, unknown>>
  >
  const wrapperProps =
    menuId === 'profile-menu' ? { 'data-testid': menuId } : {}

  return (
    <Wrapper
      ref={menuRef}
      className={props.classNames.listItem}
      {...wrapperProps}
    >
      <button
        ref={buttonRef}
        type="button"
        className={`group relative ${styles.primary.navLink} ${props.classNames.menu}`}
        onClick={handleMenuButtonClick}
        aria-expanded={isMenuOpen}
        aria-controls={menuId}
        data-menu-label // Note: this is used by css to style active menu state
        data-menu-id={menuId.replace(`-${otherProps.type}`, '')}
      >
        {menuButton}
      </button>
      <MenuItems {...otherProps} isMenuOpen={isMenuOpen} menuId={menuId} />
    </Wrapper>
  )
}
