import type { Options } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'

import { Overrides } from './types'
import { RichTextElementProps, makeRichTextElement } from './utils'

export const baseBlockComponents: Options['renderNode'] = {
  [BLOCKS.HEADING_1]: (_, children) =>
    makeRichTextElement(children, richTextConfig.h1.props),
  [BLOCKS.HEADING_2]: (_, children) =>
    makeRichTextElement(children, richTextConfig.h2.props),
  [BLOCKS.HEADING_3]: (_, children) =>
    makeRichTextElement(children, richTextConfig.h3.props),
  [BLOCKS.HEADING_4]: (_, children) =>
    makeRichTextElement(children, richTextConfig.h4.props),
  [BLOCKS.HEADING_5]: (_, children) =>
    makeRichTextElement(children, richTextConfig.h5.props),
  [BLOCKS.HEADING_6]: (_, children) =>
    makeRichTextElement(children, richTextConfig.h6.props),
  [BLOCKS.PARAGRAPH]: (_, children) =>
    makeRichTextElement(children, richTextConfig.p.props),
  [BLOCKS.QUOTE]: (_, children) => (
    <>
      <div className="lg:mt-88 bg-warning-600 mx-auto mt-56 h-[2px] w-56" />
      {makeRichTextElement(children, richTextConfig.blockquote.props)}
    </>
  ),
  [BLOCKS.UL_LIST]: (_, children) =>
    makeRichTextElement(children, richTextConfig.ul.props),
  [BLOCKS.OL_LIST]: (_, children) =>
    makeRichTextElement(children, richTextConfig.ol.props),
  [BLOCKS.LIST_ITEM]: (_, children) =>
    makeRichTextElement(children, richTextConfig.li.props),
}

export const richTextConfig: Record<
  keyof Overrides,
  { props: Omit<RichTextElementProps, 'customTag'>; block: BLOCKS }
> = {
  h1: {
    block: BLOCKS.HEADING_1,
    props: {
      className: 'headline-l-strong',
      tag: 'h1',
    },
  },
  h2: {
    block: BLOCKS.HEADING_2,
    props: { className: 'headline-m', tag: 'h2' },
  },
  h3: {
    block: BLOCKS.HEADING_3,
    props: {
      className: 'headline-s-strong',
      tag: 'h3',
    },
  },
  h4: {
    block: BLOCKS.HEADING_4,
    props: {
      className: 'headline-body-strong',
      tag: 'h4',
    },
  },
  h5: { block: BLOCKS.HEADING_5, props: { className: 'overtext', tag: 'h5' } },
  h6: {
    block: BLOCKS.HEADING_6,
    props: { className: 'body-strong', tag: 'h6' },
  },
  p: {
    block: BLOCKS.PARAGRAPH,
    props: {
      className: 'body',
      tag: 'p',
    },
  },

  ul: {
    block: BLOCKS.UL_LIST,
    props: { className: 'my-24 ml-32 list-disc', tag: 'ul' },
  },
  ol: {
    block: BLOCKS.OL_LIST,
    props: {
      className: 'my-24 ml-32 list-decimal',
      tag: 'ol',
    },
  },
  li: {
    block: BLOCKS.LIST_ITEM,
    props: { className: '[&_p]:mt-4', tag: 'li' },
  },
  blockquote: {
    block: BLOCKS.QUOTE,
    props: {
      className:
        '[&_p]:body mt-56 [&_p]:mb-24 [&_p]:leading-loose [&_p]:tracking-wide',
      tag: 'blockquote',
    },
  },
}
