import {
  ApiStateEnum,
  isData,
  isError,
  parseFirstFromCollection,
  parseFromCollection,
  useGetAllowedContentPermissions,
  useReactQueryResultAsApiState,
  type ApiError,
  type ContentPermissionsData,
} from '@cais-group/shared/domain/contentful/api'
import { filterAllowedPermissionData } from '@cais-group/shared/domain/contentful/api'
import {
  DEFAULT_CMS_CURATED_FIRM_UUID,
  FIRMS_COLLECTION_LIMIT,
} from '@cais-group/shared/util/constants'
import type { CuratedContentDataType } from '@cais-group/shared/util/contentful/types'
import {
  useGetFirmContentOptionsQuery,
  useGetFirmsWithContentQuery,
  type GetFirmContentOptionsQuery,
  type GetFirmsWithContentQuery,
  type MemberFirm,
} from '@cais-group/shared/util/graphql/mfe-contentful'
import { logWarning } from '@cais-group/shared/util/logging'

type Data = GetFirmContentOptionsQuery | null

type CuratedCmsFirm = {
  isDefault: boolean
  id: MemberFirm['id']
  hasCustomContent: boolean
}
export const getFirmContentOptionsId = (
  isLoading: boolean,
  allowedUserFirmIds: string[],
  curatedCmsFirms?: CuratedCmsFirm[]
) => {
  if (isLoading) {
    return undefined
  }
  if (!allowedUserFirmIds.length || !curatedCmsFirms?.length) {
    return DEFAULT_CMS_CURATED_FIRM_UUID
  }
  const curatedCmsFirmsMap = new Map(
    curatedCmsFirms.map((firm) => [firm.id, firm])
  )

  let closestCuratedCmsFirm: CuratedCmsFirm | undefined

  const closestMatchingAllowedUserFirmId = allowedUserFirmIds.find((id) => {
    closestCuratedCmsFirm = curatedCmsFirmsMap.get(id)
    return curatedCmsFirmsMap.has(id)
  })

  if (closestCuratedCmsFirm?.isDefault) {
    return DEFAULT_CMS_CURATED_FIRM_UUID
  }
  if (
    !closestCuratedCmsFirm?.hasCustomContent &&
    !closestCuratedCmsFirm?.isDefault
  ) {
    return undefined
  }
  return closestMatchingAllowedUserFirmId
}

export const useGetFirmCuratedContent = () => {
  const allowedPermissions = useGetAllowedContentPermissions()

  const allowedUserFirmIds = allowedPermissions.data.firmIds

  const memberFirmsIds = useGetFirmsWithContentQuery(
    {
      firmIds: allowedUserFirmIds,
      limit: FIRMS_COLLECTION_LIMIT,
    },
    {
      refetchOnWindowFocus: false,
      enabled: !allowedPermissions.isLoading,
      select: (data) =>
        parseFromCollection<GetFirmsWithContentQuery, MemberFirm>(
          data,
          'memberFirmCollection'
        ).map((firm) => ({
          isDefault: !firm.overrideDefault,
          id: firm.id,
          hasCustomContent: Boolean(firm.optionsCollection?.total),
        })),
      onError: (error) => {
        logWarning({
          message: 'Failed to load member firms',
          error,
        })
      },
    }
  )
  const contentOptionsId = getFirmContentOptionsId(
    memberFirmsIds.isLoading,
    allowedUserFirmIds ?? [],
    memberFirmsIds.data
  )

  const response = useReactQueryResultAsApiState(
    useGetFirmContentOptionsQuery(
      {
        firmId: contentOptionsId,
        firmsLimit: FIRMS_COLLECTION_LIMIT,
      },
      {
        enabled: Boolean(contentOptionsId) && !allowedPermissions.isLoading,
        refetchOnWindowFocus: false,
        onError: (error) => {
          logWarning({ error, message: 'Curated content failed to load' })
        },
      }
    ),
    (data) => data,
    'Could not load curated firm content query'
  )
  const loading = response === ApiStateEnum.LOADING || memberFirmsIds.isLoading
  const error = isError(response) || memberFirmsIds.isError

  return compileResults(response, loading, error)
}

const compileResults = (
  data: Data | ApiError | ApiStateEnum,
  loading: boolean,
  error: boolean
) => {
  return {
    data: isData(data) ? data : null,
    isError: error,
    isLoading: loading,
  }
}

export function selectFirmCuratedContent(
  data: GetFirmContentOptionsQuery | null,
  allowedPermissionsData: Omit<ContentPermissionsData, 'notFound'>
): CuratedContentDataType[] | null {
  if (!data) {
    return null
  }
  const firms = parseFirstFromCollection<
    GetFirmContentOptionsQuery,
    MemberFirm
  >(data, 'memberFirmCollection')

  return firms?.optionsCollection?.items.length
    ? (filterAllowedPermissionData(
        parseFromCollection(firms, 'optionsCollection'),
        allowedPermissionsData
      ) as CuratedContentDataType[])
    : null
}
