export type SortDirection = 'asc' | 'desc'

function isDateConstructorValue(
  value: unknown
): value is string | number | Date {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    value instanceof Date
  )
}
type SortableKeys<T> = {
  [K in keyof T]: T[K] extends string | number | Date ? K : never
}[keyof T]

export function sortContentData<
  T extends Record<string, unknown>,
  K extends SortableKeys<T> = SortableKeys<T>
>(
  data: T[],
  options?: {
    order?: SortDirection
    key?: K
  }
) {
  const order = options?.order || 'desc'
  const direction = order?.toLowerCase() === 'desc' ? -1 : 1
  const key = options?.key || 'displayDate'

  return data.sort((a, b) => {
    const aValue = direction === 1 ? a[key] : b[key]
    const bValue = direction === 1 ? b[key] : a[key]
    if (!isDateConstructorValue(aValue) || !isDateConstructorValue(bValue)) {
      return 0
    }
    const aDate = new Date(aValue)
    const bDate = new Date(bValue)
    return aDate.getTime() - bDate.getTime()
  })
}
