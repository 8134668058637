import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const ScrollToTop = () => {
  const { pathname, state } = useLocation()

  useEffect(() => {
    const canControlScrollRestoration = 'scrollRestoration' in window.history
    if (canControlScrollRestoration) {
      window.history.scrollRestoration = 'manual'
    }

    if (state?.preventScrollToTop !== true) {
      window.scrollTo({
        top: 0,
      })
    }
  }, [pathname, state])
  return null
}
