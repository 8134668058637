import { Button, ButtonProps } from '../../../atoms/button'

/**
 * The properties for the ModalAction component.
 * It extends from ButtonProps, with an additional property for container width.
 *
 * @typedef {Object} ModalActionType
 * @property {number} [btnContainerWidth] - The optional width for the button container.
 * @extends ButtonProps
 */
export type ModalActionType = ButtonProps & {
  btnContainerWidth?: number
}

/**
 * A component that renders a set of actions as buttons within a modal.
 *
 * @function ModalActions
 * @param {Object} props - The properties for the component.
 * @param {ModalActionType[]} props.actions - The array of actions to be rendered as buttons.
 * @returns {JSX.Element} The rendered modal actions.
 */
export const ModalActions = ({ actions }: { actions: ModalActionType[] }) => (
  <div className="flex justify-end gap-x-16">
    {actions.map((action, index) => {
      const { btnContainerWidth, ...withoutBtnContainerWidth } = action
      const btnAction = action.btnContainerWidth
        ? { ...withoutBtnContainerWidth, grow: true }
        : withoutBtnContainerWidth

      const children = (
        <Button
          key={action.children?.toString() ?? index}
          size="regular"
          {...btnAction}
        >
          {action.children}
        </Button>
      )

      if (action.btnContainerWidth)
        return (
          <div
            key={index}
            className="flex"
            style={{ minWidth: action.btnContainerWidth }}
            data-testid="btnContainer"
          >
            {children}
          </div>
        )
      return children
    })}
  </div>
)
