import { Fragment } from 'react'

import { type MenuItemsProps } from '../types'

export const MenuItems = (props: MenuItemsProps) => {
  const { classNames, isMenuOpen, menuContents, menuId, type } = props

  // Note: this extra div is required to allow the animation to work when the menu is expanded
  const Wrapper = type === 'mobile' ? 'div' : Fragment

  return (
    <div
      id={menuId}
      className={classNames.menuItem}
      aria-expanded={isMenuOpen}
      data-submenu-container
    >
      <Wrapper>{menuContents}</Wrapper>
    </div>
  )
}
