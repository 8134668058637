import { useState } from 'react'
import { useEffectOnce } from 'react-use'

import {
  defaultState,
  userContentPermissionsService,
  UserContentPermissionsState,
} from '../content-permissions.service'

export function useGetAllowedContentPermissions() {
  const [permissions, setPermissions] =
    useState<UserContentPermissionsState>(defaultState)

  useEffectOnce(() => {
    const fetchPermissions = async () => {
      const permissions =
        await userContentPermissionsService.contentPermissionsAsync
      setPermissions(permissions)
    }
    fetchPermissions()
  })

  return permissions
}
