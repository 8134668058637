import cx from 'classnames'
import * as React from 'react'

import { presets, defaultPreset } from './presets'
import type { Presets } from './presets'
import { settings } from './settings'

type HeroWaveProps = React.SVGProps<SVGSVGElement> & {
  /** A specific color combination */
  preset?: Presets
}

/**
 * A React component that renders a theme aware "wave" SVG.
 *
 * "waves" are used in CAIS to help provide a modern and welcoming UX.
 * This component provides various preset colour combinations and styles.
 *
 * * Hero - used at, or near, the top of the page
 * * Breaker - used for "break out" content such as banners and promotions
 *
 */
export const Wave = (props: HeroWaveProps) => {
  const { preset = defaultPreset, ...rest } = props
  const { background, topStart, topEnd, type } = presets[preset]
  const { className, path, viewBox } = settings[type]

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      preserveAspectRatio="none"
      aria-hidden="true"
      viewBox={viewBox}
      className={cx({
        [className]: !!className,
        [background]: !!background,
      })}
      {...rest}
    >
      <defs>
        <linearGradient id={preset}>
          <stop offset="0%" stopColor={topStart} />
          <stop offset="1" stopColor={topEnd || topStart} />
        </linearGradient>
      </defs>
      <path d={path} fill={`url(#${preset})`} />
    </svg>
  )
}
