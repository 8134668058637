import { And, EntitlementRules, Or, Rule } from './types'

export const HARD_CODED_RULE_CAISIQ = 'justcaisiq'
export const HARD_CODED_RULE_NOT_JUST_CAISIQ = '!justcaisiq'

export type ResolveRulesParams = {
  rules: EntitlementRules
  personas?: string[]
  permissions?: string[]
  capabilities?: string[]
  features?: string[]
  featureFlags?: string[]
}

export function resolveRules({
  rules,
  personas,
  permissions,
  capabilities,
  features,
  featureFlags,
}: ResolveRulesParams): boolean {
  return Object.keys(rules)
    .map((key) => {
      if (key === 'or') {
        return (rules as Or).or.some((rule) =>
          resolveRules({
            rules: rule,
            personas,
            permissions,
            capabilities,
            features,
            featureFlags,
          })
        )
      }
      if (key === 'and') {
        return (rules as And).and.every((rule) =>
          resolveRules({
            rules: rule,
            personas,
            permissions,
            capabilities,
            features,
            featureFlags,
          })
        )
      }
      if (key === 'persona') {
        if (personas === undefined) return false
        const persona = (rules as Rule).persona!
        if (persona.startsWith('!')) {
          return !personas.includes(persona.slice(1))
        }
        return personas.includes(persona)
      }
      if (key === 'permission') {
        if (permissions === undefined) return false
        const permission = (rules as Rule).permission!
        if (permission.startsWith('!')) {
          return !permissions.includes(permission.slice(1))
        }
        return permissions.includes(permission)
      }
      if (key === 'capability') {
        if (capabilities === undefined) return false
        const capability = (rules as Rule).capability!
        if (capability.startsWith('!')) {
          return !capabilities.includes(capability.slice(1))
        }
        return capabilities.includes(capability)
      }
      if (key === 'feature') {
        if (features === undefined) return false
        const feature = (rules as Rule).feature!
        if (feature.startsWith('!')) {
          return !features.includes(feature.slice(1))
        }
        return features.includes(feature)
      }
      if (key === 'featureFlag') {
        if (featureFlags === undefined) return false
        const featureFlag = (rules as Rule).featureFlag!
        if (featureFlag.startsWith('!')) {
          return !featureFlags.includes(featureFlag.slice(1))
        }
        return featureFlags.includes(featureFlag)
      }
      if (key === 'hard_coded_rule') {
        const hardCodedRule = (rules as Rule).hard_coded_rule!
        if (hardCodedRule === HARD_CODED_RULE_CAISIQ) {
          // We can assume that users who can only access caisiq do not have any personas set
          if (personas === undefined) return true
          return personas.length === 0
        } else if (hardCodedRule === HARD_CODED_RULE_NOT_JUST_CAISIQ) {
          if (personas === undefined) return false
          return personas.length > 0
        } else {
          console.error('Unknown hard coded rule:', hardCodedRule)
        }
      }

      return false
    })
    .every(Boolean)
}
