import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { parseCssString } from './utils'

// Styles from Type system on figma, use these from now on, challenge if design strays from guidelines:
// https://www.figma.com/file/9p46RPQfnXRph68T9RvTOR/Type-system?node-id=42%3A2

export const BodyCss = css`
  font-size: 1rem; // 16px
  line-height: 1.5rem; // 24px
  font-weight: normal;
  letter-spacing: 0.2px;
`
export const Body = styled.p`
  ${BodyCss}
`
export const BodyCssObject = parseCssString(BodyCss.styles)

export const BodyStrongCss = css`
  ${BodyCss}
  font-weight: 600;
`
export const BodyStrong = styled.p`
  ${BodyStrongCss}
`
export const BodyStrongCssObject = parseCssString(BodyStrongCss.styles)

export const BodySmallCss = css`
  color: inherit;
  font-size: 0.875rem; // 14px
  line-height: 1.5rem; // 24px
  font-weight: normal;
  letter-spacing: 0.3px;
`
export const BodySmall = styled.p`
  ${BodySmallCss};
`
export const BodySmallCssObject = parseCssString(BodySmallCss.styles)

export const LabelCss = css`
  font-size: 0.875rem; // 14px
  line-height: 1.5rem; // 24px
  font-weight: 600;
  letter-spacing: 0.3px;
`
export const Label = styled.p`
  ${LabelCss};
`
export const LabelCssObject = parseCssString(LabelCss.styles)

export const SubtitleMonoCss = css`
  font-size: calc(0.875rem); // 14px
  line-height: 1.5rem; // 24px
  font-weight: normal;
  font-family: var(--font-mono);
  letter-spacing: 0.4px;
  text-rendering: geometricPrecision;
`
export const SubtitleMono = styled.p`
  ${SubtitleMonoCss}
`
export const SubtitleMonoCssObject = parseCssString(SubtitleMonoCss.styles)

export const CaptionRegularCss = css`
  font-size: 0.75rem; // 12px
  line-height: 1rem; // 16px
  font-weight: normal;
  letter-spacing: 1px;
`
export const CaptionRegular = styled.p`
  ${CaptionRegularCss}
`
export const CaptionRegularCSSObject = parseCssString(CaptionRegularCss.styles)

export const CaptionMonoCSS = css`
  ${CaptionRegularCss}
  font-family: var(--font-mono);
`
export const CaptionMono = styled.p`
  ${CaptionMonoCSS}
`
export const CaptionMonoCSSObject = parseCssString(CaptionMonoCSS.styles)

export const OverlineCss = css`
  font-size: 0.625rem; // 10px
  line-height: 1rem; // 16px
  font-weight: normal;
  letter-spacing: 5px;
  text-transform: uppercase;
`
export const Overline = styled.p`
  ${OverlineCss}
`
export const OverlineCssObject = parseCssString(OverlineCss.styles)
