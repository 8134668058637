import React from 'react'

import { EnvType, Menu as MenuType } from '../types'

import { MenuItems } from './menu-items'

export const Menu = ({
  trigger,
  menu,
  testId,
  trackMenuLabel,
  handleOpenMenu,
  handleCloseMenu,
  openMenu,
  position,
  env,
}: {
  trigger: React.ReactNode
  menu: MenuType
  testId: string
  trackMenuLabel?: string
  position?: string
  handleOpenMenu: (id: string) => void
  handleCloseMenu: () => void
  openMenu: string | null
  env: EnvType
}) => {
  const ref =
    React.useRef<HTMLButtonElement>() as React.MutableRefObject<HTMLButtonElement>
  const lastFocusedElement = React.useRef<HTMLButtonElement>()

  const handleClose = () => {
    // Return focus to the button that opened
    // the menu
    lastFocusedElement?.current?.focus()
    handleCloseMenu()
  }

  const handleOpen = () => {
    // Remember the button that opened the menu
    // so we can refocus it when the menu closes
    lastFocusedElement.current = ref.current

    handleOpenMenu(menu.id)
  }

  const isMenuOpen = openMenu === menu.id

  const activeMenuStyles =
    '[&:has(a.active)_[data-menu-label]]:font-semibold [&:has(a.active)_[data-menu-label]]:text-neutral-900 [&:has(a.active)_[data-menu-label]_svg]:text-neutral-900'

  return (
    <li className={`group relative inline-block ${activeMenuStyles} `}>
      <button
        ref={ref}
        className="p-0 focus-visible:ring"
        aria-haspopup="menu"
        aria-expanded={isMenuOpen}
        aria-controls="dropdown-menu"
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            handleOpen()
          }
        }}
        data-testid={`menu-expand-btn-${testId?.replace(' ', '-')}`}
        data-menu-id={menu.id}
      >
        {trigger}
      </button>

      <MenuItems
        menu={menu}
        trackMenuLabel={trackMenuLabel}
        handleCloseMenu={handleClose}
        openMenu={openMenu}
        position={position}
        env={env}
      />
    </li>
  )
}
