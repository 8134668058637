import type { AllColor } from '../../../../../../equity/particles/colors'
import type { IconType } from '../../../../../../equity/particles/icons'
import { Icon } from '../../../../../../equity/web/atoms/icon'
import { CloudinaryImage } from '../../../../../ui/cloudinary-image'
import type { AnnouncementFragment } from '../../../../../util/graphql/mfe-contentful'
import { prepareImageProps } from '../helpers'

export const Adornment = ({
  announcement,
  color,
}: {
  announcement: {
    icon?: IconType
    image?: AnnouncementFragment['image']
  } | null
  color: AllColor
}) => {
  if (!announcement) {
    return null
  }

  if (announcement.icon) {
    return <Icon type={announcement.icon} color={color} />
  }

  const image = announcement.image && prepareImageProps(announcement.image[0])
  if (image) {
    return (
      <CloudinaryImage
        src={image.src}
        layout="fixed"
        width={image.width}
        aspectRatio={image.aspect_ratio}
      />
    )
  }

  return null
}
