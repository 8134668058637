import styled from '@emotion/styled'
import { forwardRef, ReactNode } from 'react'
import { omit } from 'remeda'

import { spacing } from '../../../../../equity/particles/spacing'
export type LayoutCenterProps = {
  as?: string
  max?: (typeof spacing)[keyof typeof spacing]
  centerText?: boolean
  minGutters?: (typeof spacing)[keyof typeof spacing]
  /* ↓ LayoutCenter child elements based on their content width */
  intrinsic?: boolean
  children: ReactNode
  className?: string
}

export const LayoutCenter = styled(
  forwardRef<HTMLDivElement, LayoutCenterProps>((props, ref) => {
    const passedProps = omit(props, [
      'max',
      'centerText',
      'minGutters',
      'intrinsic',
      'className',
      'children',
    ])
    return (
      <div
        ref={ref}
        className={props.className}
        data-layout="LayoutCenter"
        {...passedProps}
      >
        {props.children}
      </div>
    )
  })
)(
  ({
    max,
    centerText = false,
    minGutters = 'var(--s16)',
    intrinsic = false,
  }) => {
    return `
    display: block;
    /* ↓ Remove padding from the width calculation */
    box-sizing: content-box;
    /* ↓ The maximum width is the maximum measure */

    ${max ? `max-width: ${max};` : ''}
    /* ↓ Only affect horizontal margins */
    margin-left: auto;
    margin-right: auto;
    /* ↓ Apply the minimum horizontal gutter */
    ${`
      padding-left: ${minGutters};
      padding-right: ${minGutters};
    `}
    ${centerText ? 'text-align: center;' : ''}
    ${
      intrinsic
        ? `
      display: flex;
      flex-direction: column;
      align-items: center;
    `
        : ''
    }
    `
  }
)

LayoutCenter.displayName = 'LayoutCenter'
