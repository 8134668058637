import { useCallback, useEffect, useRef } from 'react'
import { useLocation, type Location } from 'react-router-dom'

import { UserProfileV1 } from '../../../../tmp/generated/access-manager-api'

type RuleTest = {
  userProfile: UserProfileV1
  location?: Location
  previousLocation?: string
}

type Action = {
  location: Location
}

export type Rule = {
  test: ({ userProfile, location, previousLocation }: RuleTest) => boolean
  action: ({ location }: Action) => void
}

export function useRedirect(
  userProfile: UserProfileV1 | null,
  rulesToRunOnceAfterLogin: Rule[],
  rulesToRunOnEachLocationChange: Rule[] = []
) {
  const location = useLocation()
  const prevLocationRef = useRef<string>()
  const execRules = useCallback(
    (rules: Rule[]) => {
      const match =
        rules && userProfile
          ? rules.find(
              (rule) =>
                rule.test({
                  userProfile,
                  location,
                  previousLocation: prevLocationRef.current,
                }) === true
            )
          : null

      if (match && match.action) {
        match.action({ location })
      }
    },
    [userProfile, location]
  )

  useEffect(() => {
    execRules(rulesToRunOnceAfterLogin)
    // avoid running multiple times (if possible) so that redirects don't happen on every render. Unfortuantely, userProfile is initially null.
  }, [userProfile]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    execRules(rulesToRunOnEachLocationChange)
    prevLocationRef.current = window.location.pathname
    // Running only on location changes
  }, [location]) // eslint-disable-line react-hooks/exhaustive-deps
}
