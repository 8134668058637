import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from 'axios'
import flat from 'flat'

import { MfeEnv, getEnvConfig } from '../../../ui/env'
import { CaisiqEnvConfig } from '../../../ui/env-caisiq'
import { authService } from '../../../util/auth-service'

import { addCloudflareChallengeToAxiosInstance } from './cloudflare-interceptor'

export enum ServiceFailureType {
  SERVER,
  NOT_FOUND,
  BAD_REQUEST,
  FORBIDDEN,
  CONFLICT,
  UNAUTHORIZED,
  VERSION_ERROR,
  RATE_LIMIT,
}

export type ErrorBE = {
  displayMessage: string
  type: ServiceFailureType
  reason: string
  cause?: string
  errorCode?: string
  errorAttributes?: Record<string, unknown>
}

type ErrorFE = Omit<ErrorBE, 'displayMessage'> & {
  message: string
  status: number
}

export type ErrorType<TError = ErrorFE> = {
  type: TError
  // Added to the type for backwards compatibility
  // (this fetcher is rejecting the promise with this payload when the response is empty)
  message?: string
}

export const axiosInstance = axios.create()

const transformError = (response?: AxiosResponse, errorMessage?: string) => {
  if (response?.data.errors) {
    return response
  } else if (response?.data) {
    return {
      type: {
        ...response.data,
        message: response.data.displayMessage,
      },
    }
  } else if (response) {
    return {
      type: {
        message: response.statusText,
        status: response.status,
      },
    }
  }

  return { type: { message: errorMessage } }
}

export type FetchOptions = {
  envConfig?: CaisiqEnvConfig | MfeEnv
  injectAuthToken?: boolean
}

export async function fetchInstance<T>(
  config: AxiosRequestConfig,
  { envConfig, injectAuthToken = true }: FetchOptions = {}
): Promise<T> {
  return fetchResponse<T>(config, { envConfig, injectAuthToken }).then(
    (response) => response.data
  )
}

export async function fetchResponse<T>(
  config: AxiosRequestConfig,
  { envConfig, injectAuthToken = true }: FetchOptions = {}
): Promise<AxiosResponse<T>> {
  const env = envConfig || getEnvConfig()

  addCloudflareChallengeToAxiosInstance(env, axiosInstance)

  config.params = new URLSearchParams(
    config.params ? flat(config.params, { safe: true }) : undefined
  )

  config.headers = {
    ...config.headers,
    ...(injectAuthToken
      ? {
          Authorization: `Bearer ${await authService.getAccessTokenSilently()}`,
        }
      : {}),
  } as AxiosRequestHeaders

  config.url = config.url?.startsWith('http')
    ? config.url
    : `${env.PORTAL_URL}${config.url}`

  const response = await axiosInstance
    .request<T>(config)
    .catch((error: AxiosError<ErrorBE>) =>
      Promise.reject(transformError(error.response, error.message))
    )

  // Handle fetch errors and connection issues
  if (!response) {
    return Promise.reject({ message: 'No response from the server' })
  }

  // If response is not ok, reject with the error
  if (response.status >= 400) {
    return Promise.reject(transformError(response))
  }

  return response
}
