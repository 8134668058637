import { Components } from '@mui/material'

import { colors } from '../../../../../equity/particles/colors'

export const MuiOutlinedInput: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: colors['eq-color-neutral-200'],
        borderWidth: '1.5px',
      },
      '&:hover fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: colors['eq-color-neutral-200'],
        borderWidth: '1.5px',
      },
      '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: colors['eq-color-primary-600'],
        borderWidth: '1.5px',
      },
    },
  },
}
