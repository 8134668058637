export const settings = {
  breaker: {
    viewBox: '0 0 1660 160',
    className:
      'absolute bottom-0 left-1/2 w-full min-w-[1660px] -translate-x-1/2',
    path: 'M484.219 82.0774C251.023 86.8514 64.2413 145.233 0 173.827H2437.96V-257.322C2419.76 -259.808 2360.28 -269.63 2266.11 -269.63C2141.64 -269.63 1894.32 -235.69 1701.59 -74.9413C1508.87 85.8071 1399.66 114.526 1157.95 121.985C916.242 129.444 775.714 76.11 484.219 82.0774Z',
  },
  hero: {
    viewBox: '0 0 1660 200',
    className: 'h-[200px] w-full',
    path: 'M1660 0H0V156C238.625 156 485.319 90.8525 827.821 90.8525C1170.32 90.8525 1420.22 121.352 1660 61.9171L1660 0Z',
  },
}
