import { ValueOf } from 'ts-essentials'

import { colors as twColors } from './tw-colors'

type SemanticColor = keyof typeof twColors

type FlattenObjectKeys<
  T extends Record<string, unknown>,
  Key = keyof T
> = Key extends string
  ? T[Key] extends Record<string, unknown>
    ? `eq-color-${Key}-${FlattenObjectKeys<T[Key]>}`
    : `${Key}`
  : never

type FlatKeys = FlattenObjectKeys<typeof twColors>

export type Color = FlatKeys extends infer K
  ? K extends `${string}-c`
    ? never
    : K
  : never

export type ContrastColor = FlatKeys extends infer K
  ? K extends `${string}-c`
    ? K
    : never
  : never

type GetValue<T> =
  T extends `eq-color-${infer C extends SemanticColor}-${infer S}`
    ? `rgb(var(--colors-${C}-${S}))`
    : never

export const colors: { [K in Color]: GetValue<K> } = {
  'eq-color-brand-100': 'rgb(var(--colors-brand-100))',
  'eq-color-brand-200': 'rgb(var(--colors-brand-200))',
  'eq-color-brand-300': 'rgb(var(--colors-brand-300))',
  'eq-color-brand-400': 'rgb(var(--colors-brand-400))',
  'eq-color-brand-500': 'rgb(var(--colors-brand-500))',
  'eq-color-brand-600': 'rgb(var(--colors-brand-600))',
  'eq-color-brand-700': 'rgb(var(--colors-brand-700))',
  'eq-color-brand-800': 'rgb(var(--colors-brand-800))',
  'eq-color-brand-900': 'rgb(var(--colors-brand-900))',

  'eq-color-primary-100': 'rgb(var(--colors-primary-100))',
  'eq-color-primary-200': 'rgb(var(--colors-primary-200))',
  'eq-color-primary-300': 'rgb(var(--colors-primary-300))',
  'eq-color-primary-400': 'rgb(var(--colors-primary-400))',
  'eq-color-primary-500': 'rgb(var(--colors-primary-500))',
  'eq-color-primary-600': 'rgb(var(--colors-primary-600))',
  'eq-color-primary-700': 'rgb(var(--colors-primary-700))',
  'eq-color-primary-800': 'rgb(var(--colors-primary-800))',
  'eq-color-primary-900': 'rgb(var(--colors-primary-900))',

  'eq-color-accent-100': 'rgb(var(--colors-accent-100))',
  'eq-color-accent-200': 'rgb(var(--colors-accent-200))',
  'eq-color-accent-300': 'rgb(var(--colors-accent-300))',
  'eq-color-accent-400': 'rgb(var(--colors-accent-400))',
  'eq-color-accent-500': 'rgb(var(--colors-accent-500))',
  'eq-color-accent-600': 'rgb(var(--colors-accent-600))',
  'eq-color-accent-700': 'rgb(var(--colors-accent-700))',
  'eq-color-accent-800': 'rgb(var(--colors-accent-800))',
  'eq-color-accent-900': 'rgb(var(--colors-accent-900))',

  'eq-color-success-100': 'rgb(var(--colors-success-100))',
  'eq-color-success-200': 'rgb(var(--colors-success-200))',
  'eq-color-success-300': 'rgb(var(--colors-success-300))',
  'eq-color-success-400': 'rgb(var(--colors-success-400))',
  'eq-color-success-500': 'rgb(var(--colors-success-500))',
  'eq-color-success-600': 'rgb(var(--colors-success-600))',
  'eq-color-success-700': 'rgb(var(--colors-success-700))',
  'eq-color-success-800': 'rgb(var(--colors-success-800))',
  'eq-color-success-900': 'rgb(var(--colors-success-900))',

  'eq-color-warning-100': 'rgb(var(--colors-warning-100))',
  'eq-color-warning-200': 'rgb(var(--colors-warning-200))',
  'eq-color-warning-300': 'rgb(var(--colors-warning-300))',
  'eq-color-warning-400': 'rgb(var(--colors-warning-400))',
  'eq-color-warning-500': 'rgb(var(--colors-warning-500))',
  'eq-color-warning-600': 'rgb(var(--colors-warning-600))',
  'eq-color-warning-700': 'rgb(var(--colors-warning-700))',
  'eq-color-warning-800': 'rgb(var(--colors-warning-800))',
  'eq-color-warning-900': 'rgb(var(--colors-warning-900))',

  'eq-color-error-100': 'rgb(var(--colors-error-100))',
  'eq-color-error-200': 'rgb(var(--colors-error-200))',
  'eq-color-error-300': 'rgb(var(--colors-error-300))',
  'eq-color-error-400': 'rgb(var(--colors-error-400))',
  'eq-color-error-500': 'rgb(var(--colors-error-500))',
  'eq-color-error-600': 'rgb(var(--colors-error-600))',
  'eq-color-error-700': 'rgb(var(--colors-error-700))',
  'eq-color-error-800': 'rgb(var(--colors-error-800))',
  'eq-color-error-900': 'rgb(var(--colors-error-900))',

  'eq-color-neutral-100': 'rgb(var(--colors-neutral-100))',
  'eq-color-neutral-200': 'rgb(var(--colors-neutral-200))',
  'eq-color-neutral-300': 'rgb(var(--colors-neutral-300))',
  'eq-color-neutral-400': 'rgb(var(--colors-neutral-400))',
  'eq-color-neutral-500': 'rgb(var(--colors-neutral-500))',
  'eq-color-neutral-600': 'rgb(var(--colors-neutral-600))',
  'eq-color-neutral-700': 'rgb(var(--colors-neutral-700))',
  'eq-color-neutral-800': 'rgb(var(--colors-neutral-800))',
  'eq-color-neutral-900': 'rgb(var(--colors-neutral-900))',
  'eq-color-neutral-0': 'rgb(var(--colors-neutral-0))',

  'eq-color-datavis-1': 'rgb(var(--colors-datavis-1))',
  'eq-color-datavis-2': 'rgb(var(--colors-datavis-2))',
  'eq-color-datavis-3': 'rgb(var(--colors-datavis-3))',
  'eq-color-datavis-4': 'rgb(var(--colors-datavis-4))',
  'eq-color-datavis-5': 'rgb(var(--colors-datavis-5))',
  'eq-color-datavis-6': 'rgb(var(--colors-datavis-6))',
  'eq-color-datavis-7': 'rgb(var(--colors-datavis-7))',
  'eq-color-datavis-8': 'rgb(var(--colors-datavis-8))',
  'eq-color-datavis-9': 'rgb(var(--colors-datavis-9))',
  'eq-color-datavis-10': 'rgb(var(--colors-datavis-10))',
  'eq-color-datavis-11': 'rgb(var(--colors-datavis-11))',
  'eq-color-datavis-12': 'rgb(var(--colors-datavis-12))',
  'eq-color-datavis-13': 'rgb(var(--colors-datavis-13))',
  'eq-color-datavis-14': 'rgb(var(--colors-datavis-14))',
  'eq-color-datavis-15': 'rgb(var(--colors-datavis-15))',
  'eq-color-datavis-16': 'rgb(var(--colors-datavis-16))',
  'eq-color-datavis-17': 'rgb(var(--colors-datavis-17))',
  'eq-color-datavis-18': 'rgb(var(--colors-datavis-18))',
  'eq-color-datavis-19': 'rgb(var(--colors-datavis-19))',
  'eq-color-datavis-20': 'rgb(var(--colors-datavis-20))',
  'eq-color-datavis-21': 'rgb(var(--colors-datavis-21))',
  'eq-color-datavis-22': 'rgb(var(--colors-datavis-22))',

  'eq-color-gradient-1-1': 'rgb(var(--colors-gradient-1-1))',
  'eq-color-gradient-1-2': 'rgb(var(--colors-gradient-1-2))',
  'eq-color-gradient-2-1': 'rgb(var(--colors-gradient-2-1))',
  'eq-color-gradient-2-2': 'rgb(var(--colors-gradient-2-2))',
  'eq-color-gradient-3-1': 'rgb(var(--colors-gradient-3-1))',
  'eq-color-gradient-3-2': 'rgb(var(--colors-gradient-3-2))',
  'eq-color-gradient-4-1': 'rgb(var(--colors-gradient-4-1))',
  'eq-color-gradient-4-2': 'rgb(var(--colors-gradient-4-2))',

  'eq-color-heatmap-0': 'rgb(var(--colors-heatmap-0))',
  'eq-color-heatmap-1': 'rgb(var(--colors-heatmap-1))',
  'eq-color-heatmap-2': 'rgb(var(--colors-heatmap-2))',
  'eq-color-heatmap-3': 'rgb(var(--colors-heatmap-3))',
  'eq-color-heatmap-4': 'rgb(var(--colors-heatmap-4))',
  'eq-color-heatmap-5': 'rgb(var(--colors-heatmap-5))',
  'eq-color-heatmap-6': 'rgb(var(--colors-heatmap-6))',
  'eq-color-heatmap-7': 'rgb(var(--colors-heatmap-7))',
  'eq-color-heatmap-8': 'rgb(var(--colors-heatmap-8))',
  'eq-color-heatmap-9': 'rgb(var(--colors-heatmap-9))',
  'eq-color-heatmap-10': 'rgb(var(--colors-heatmap-10))',
} as const

export const contrastColors: { [K in ContrastColor]: GetValue<K> } = {
  'eq-color-brand-100-c': 'rgb(var(--colors-brand-100-c))',
  'eq-color-brand-200-c': 'rgb(var(--colors-brand-200-c))',
  'eq-color-brand-300-c': 'rgb(var(--colors-brand-300-c))',
  'eq-color-brand-400-c': 'rgb(var(--colors-brand-400-c))',
  'eq-color-brand-500-c': 'rgb(var(--colors-brand-500-c))',
  'eq-color-brand-600-c': 'rgb(var(--colors-brand-600-c))',
  'eq-color-brand-700-c': 'rgb(var(--colors-brand-700-c))',
  'eq-color-brand-800-c': 'rgb(var(--colors-brand-800-c))',
  'eq-color-brand-900-c': 'rgb(var(--colors-brand-900-c))',

  'eq-color-primary-100-c': 'rgb(var(--colors-primary-100-c))',
  'eq-color-primary-200-c': 'rgb(var(--colors-primary-200-c))',
  'eq-color-primary-300-c': 'rgb(var(--colors-primary-300-c))',
  'eq-color-primary-400-c': 'rgb(var(--colors-primary-400-c))',
  'eq-color-primary-500-c': 'rgb(var(--colors-primary-500-c))',
  'eq-color-primary-600-c': 'rgb(var(--colors-primary-600-c))',
  'eq-color-primary-700-c': 'rgb(var(--colors-primary-700-c))',
  'eq-color-primary-800-c': 'rgb(var(--colors-primary-800-c))',
  'eq-color-primary-900-c': 'rgb(var(--colors-primary-900-c))',

  'eq-color-accent-100-c': 'rgb(var(--colors-accent-100-c))',
  'eq-color-accent-200-c': 'rgb(var(--colors-accent-200-c))',
  'eq-color-accent-300-c': 'rgb(var(--colors-accent-300-c))',
  'eq-color-accent-400-c': 'rgb(var(--colors-accent-400-c))',
  'eq-color-accent-500-c': 'rgb(var(--colors-accent-500-c))',
  'eq-color-accent-600-c': 'rgb(var(--colors-accent-600-c))',
  'eq-color-accent-700-c': 'rgb(var(--colors-accent-700-c))',
  'eq-color-accent-800-c': 'rgb(var(--colors-accent-800-c))',
  'eq-color-accent-900-c': 'rgb(var(--colors-accent-900-c))',

  'eq-color-success-100-c': 'rgb(var(--colors-success-100-c))',
  'eq-color-success-200-c': 'rgb(var(--colors-success-200-c))',
  'eq-color-success-300-c': 'rgb(var(--colors-success-300-c))',
  'eq-color-success-400-c': 'rgb(var(--colors-success-400-c))',
  'eq-color-success-500-c': 'rgb(var(--colors-success-500-c))',
  'eq-color-success-600-c': 'rgb(var(--colors-success-600-c))',
  'eq-color-success-700-c': 'rgb(var(--colors-success-700-c))',
  'eq-color-success-800-c': 'rgb(var(--colors-success-800-c))',
  'eq-color-success-900-c': 'rgb(var(--colors-success-900-c))',

  'eq-color-warning-100-c': 'rgb(var(--colors-warning-100-c))',
  'eq-color-warning-200-c': 'rgb(var(--colors-warning-200-c))',
  'eq-color-warning-300-c': 'rgb(var(--colors-warning-300-c))',
  'eq-color-warning-400-c': 'rgb(var(--colors-warning-400-c))',
  'eq-color-warning-500-c': 'rgb(var(--colors-warning-500-c))',
  'eq-color-warning-600-c': 'rgb(var(--colors-warning-600-c))',
  'eq-color-warning-700-c': 'rgb(var(--colors-warning-700-c))',
  'eq-color-warning-800-c': 'rgb(var(--colors-warning-800-c))',
  'eq-color-warning-900-c': 'rgb(var(--colors-warning-900-c))',

  'eq-color-error-100-c': 'rgb(var(--colors-error-100-c))',
  'eq-color-error-200-c': 'rgb(var(--colors-error-200-c))',
  'eq-color-error-300-c': 'rgb(var(--colors-error-300-c))',
  'eq-color-error-400-c': 'rgb(var(--colors-error-400-c))',
  'eq-color-error-500-c': 'rgb(var(--colors-error-500-c))',
  'eq-color-error-600-c': 'rgb(var(--colors-error-600-c))',
  'eq-color-error-700-c': 'rgb(var(--colors-error-700-c))',
  'eq-color-error-800-c': 'rgb(var(--colors-error-800-c))',
  'eq-color-error-900-c': 'rgb(var(--colors-error-900-c))',

  'eq-color-neutral-100-c': 'rgb(var(--colors-neutral-100-c))',
  'eq-color-neutral-200-c': 'rgb(var(--colors-neutral-200-c))',
  'eq-color-neutral-300-c': 'rgb(var(--colors-neutral-300-c))',
  'eq-color-neutral-400-c': 'rgb(var(--colors-neutral-400-c))',
  'eq-color-neutral-500-c': 'rgb(var(--colors-neutral-500-c))',
  'eq-color-neutral-600-c': 'rgb(var(--colors-neutral-600-c))',
  'eq-color-neutral-700-c': 'rgb(var(--colors-neutral-700-c))',
  'eq-color-neutral-800-c': 'rgb(var(--colors-neutral-800-c))',
  'eq-color-neutral-900-c': 'rgb(var(--colors-neutral-900-c))',
  'eq-color-neutral-0-c': 'rgb(var(--colors-neutral-0-c))',

  'eq-color-datavis-1-c': 'rgb(var(--colors-datavis-1-c))',
  'eq-color-datavis-2-c': 'rgb(var(--colors-datavis-2-c))',
  'eq-color-datavis-3-c': 'rgb(var(--colors-datavis-3-c))',
  'eq-color-datavis-4-c': 'rgb(var(--colors-datavis-4-c))',
  'eq-color-datavis-5-c': 'rgb(var(--colors-datavis-5-c))',
  'eq-color-datavis-6-c': 'rgb(var(--colors-datavis-6-c))',
  'eq-color-datavis-7-c': 'rgb(var(--colors-datavis-7-c))',
  'eq-color-datavis-8-c': 'rgb(var(--colors-datavis-8-c))',
  'eq-color-datavis-9-c': 'rgb(var(--colors-datavis-9-c))',
  'eq-color-datavis-10-c': 'rgb(var(--colors-datavis-10-c))',
  'eq-color-datavis-11-c': 'rgb(var(--colors-datavis-11-c))',
  'eq-color-datavis-12-c': 'rgb(var(--colors-datavis-12-c))',
  'eq-color-datavis-13-c': 'rgb(var(--colors-datavis-13-c))',
  'eq-color-datavis-14-c': 'rgb(var(--colors-datavis-14-c))',
  'eq-color-datavis-15-c': 'rgb(var(--colors-datavis-15-c))',
  'eq-color-datavis-16-c': 'rgb(var(--colors-datavis-16-c))',
  'eq-color-datavis-17-c': 'rgb(var(--colors-datavis-17-c))',
  'eq-color-datavis-18-c': 'rgb(var(--colors-datavis-18-c))',
  'eq-color-datavis-19-c': 'rgb(var(--colors-datavis-19-c))',
  'eq-color-datavis-20-c': 'rgb(var(--colors-datavis-20-c))',
  'eq-color-datavis-21-c': 'rgb(var(--colors-datavis-21-c))',
  'eq-color-datavis-22-c': 'rgb(var(--colors-datavis-22-c))',

  'eq-color-gradient-1-1-c': 'rgb(var(--colors-gradient-1-1-c))',
  'eq-color-gradient-1-2-c': 'rgb(var(--colors-gradient-1-2-c))',
  'eq-color-gradient-2-1-c': 'rgb(var(--colors-gradient-2-1-c))',
  'eq-color-gradient-2-2-c': 'rgb(var(--colors-gradient-2-2-c))',
  'eq-color-gradient-3-1-c': 'rgb(var(--colors-gradient-3-1-c))',
  'eq-color-gradient-3-2-c': 'rgb(var(--colors-gradient-3-2-c))',
  'eq-color-gradient-4-1-c': 'rgb(var(--colors-gradient-4-1-c))',
  'eq-color-gradient-4-2-c': 'rgb(var(--colors-gradient-4-2-c))',

  'eq-color-heatmap-0-c': 'rgb(var(--colors-heatmap-0-c))',
  'eq-color-heatmap-1-c': 'rgb(var(--colors-heatmap-1-c))',
  'eq-color-heatmap-2-c': 'rgb(var(--colors-heatmap-2-c))',
  'eq-color-heatmap-3-c': 'rgb(var(--colors-heatmap-3-c))',
  'eq-color-heatmap-4-c': 'rgb(var(--colors-heatmap-4-c))',
  'eq-color-heatmap-5-c': 'rgb(var(--colors-heatmap-5-c))',
  'eq-color-heatmap-6-c': 'rgb(var(--colors-heatmap-6-c))',
  'eq-color-heatmap-7-c': 'rgb(var(--colors-heatmap-7-c))',
  'eq-color-heatmap-8-c': 'rgb(var(--colors-heatmap-8-c))',
  'eq-color-heatmap-9-c': 'rgb(var(--colors-heatmap-9-c))',
  'eq-color-heatmap-10-c': 'rgb(var(--colors-heatmap-10-c))',
} as const

export const allColors = { ...colors, ...contrastColors }

export type ColorValue = ValueOf<typeof allColors>

export const datavisPalette = [
  'eq-color-datavis-1',
  'eq-color-datavis-2',
  'eq-color-datavis-3',
  'eq-color-datavis-4',
  'eq-color-datavis-5',
  'eq-color-datavis-6',
  'eq-color-datavis-7',
  'eq-color-datavis-8',
  'eq-color-datavis-9',
  'eq-color-datavis-10',
  'eq-color-datavis-11',
  'eq-color-datavis-12',
  'eq-color-datavis-13',
  'eq-color-datavis-14',
  'eq-color-datavis-15',
  'eq-color-datavis-16',
  'eq-color-datavis-17',
  'eq-color-datavis-18',
  'eq-color-datavis-19',
  'eq-color-datavis-20',
  'eq-color-datavis-21',
  'eq-color-datavis-22',
] as const

export const heatMapPalette = [
  'eq-color-heatmap-0',
  'eq-color-heatmap-1',
  'eq-color-heatmap-2',
  'eq-color-heatmap-3',
  'eq-color-heatmap-4',
  'eq-color-heatmap-5',
  'eq-color-heatmap-6',
  'eq-color-heatmap-7',
  'eq-color-heatmap-8',
  'eq-color-heatmap-9',
  'eq-color-heatmap-10',
] as const

export type AllColor = Color | ContrastColor

export default colors

export type Shade = 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900

export type EquitySemanticColor = SemanticColor

type TailwindColorPrefix =
  | 'to'
  | 'bg'
  | 'via'
  | 'from'
  | 'fill'
  | 'text'
  | 'ring'
  | 'caret'
  | 'divide'
  | 'stroke'
  | 'accent'
  | 'shadow'
  | 'border'
  | 'outline'
  | 'border-x'
  | 'border-y'
  | 'border-t'
  | 'border-r'
  | 'border-b'
  | 'border-l'
  | 'decoration'
  | 'placeholder'
  | 'ring-offset'

type Pseudo = string
export type TailwindEquityColor = AllColor extends `eq-color-${infer C}`
  ? `${TailwindColorPrefix}-${C}` | `${Pseudo}:${TailwindColorPrefix}-${C}`
  : never
