import { ButtonProps } from '@mui/material'
import {
  Components,
  Interpolation,
  SimplePaletteColorOptions,
  Theme,
} from '@mui/material/styles'

import { colors } from '../../../../../equity/particles/colors'
import { palette, paletteColors } from '../palette'

const getTextButtonCss = (colorIn: string) => {
  const themeColor = paletteColors[
    colorIn as unknown as keyof typeof paletteColors
  ] as SimplePaletteColorOptions

  return {
    '&.MuiButtonBase-root': {
      background: 'none',
      color: themeColor.main,
      minWidth: 0,
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&:hover': {
      color: themeColor.darker,
    },
  }
}

const getOutlinedButtonCss = (colorIn: string) => {
  const themeColor = paletteColors[
    colorIn as unknown as keyof typeof paletteColors
  ] as SimplePaletteColorOptions

  const paletteColorsToOverRideTextColorToContrastText = ['neutral']
  const color =
    paletteColorsToOverRideTextColorToContrastText.indexOf(colorIn) > -1
      ? themeColor.contrastText
      : themeColor.main

  return {
    color,
    background: 'none',
    border: `1px solid ${themeColor.dark || themeColor.main}`,
    '&:hover': {
      color,
      background: 'none',
      border: `1px solid ${themeColor.darker || themeColor.main}`,
    },
  }
}

const getContainedButtonCss = (color: SimplePaletteColorOptions) => ({
  background: color.main,
  color: color.contrastText,
  border: `1px solid ${color.main}`,
  '&:hover': {
    background: color.main,
    color: color.contrastText,
    border: `1px solid ${color.main}`,
  },
})

const textButtons = Object.keys(paletteColors).map((color) => {
  return {
    props: {
      color,
      variant: 'text',
    },
    style: getTextButtonCss(color),
  }
}) as {
  props: Partial<ButtonProps<'button', unknown>>
  style: Interpolation<{
    theme: Theme
  }>
}[]

const roundedOutlinedButtons = Object.keys(paletteColors).map((color) => {
  return {
    props: {
      color,
      variant: 'outlined-round',
    },
    style: getOutlinedButtonCss(color),
  }
}) as {
  props: Partial<ButtonProps<'button', unknown>>
  style: Interpolation<{
    theme: Theme
  }>
}[]

const roundedContainedButtons = Object.keys(paletteColors).map((color) => {
  const themeColor =
    paletteColors[color as unknown as keyof typeof paletteColors]

  return {
    props: {
      color,
      variant: 'contained-round',
    },
    style: getContainedButtonCss(themeColor),
  }
}) as {
  props: Partial<ButtonProps<'button', unknown>>
  style: Interpolation<{
    theme: Theme
  }>
}[]

const neutralTextStyles = getTextButtonCss('neutral')

export const MuiButton: Components['MuiButton'] = {
  styleOverrides: {
    root: {
      fontWeight: 600,
      textTransform: 'none',
      borderRadius: 0,
      boxShadow: ['none'],
      '&:hover': {
        boxShadow: ['none'],
      },
    },
    sizeSmall: {
      padding: 'var(--s4) var(--s12)',
    },
    sizeMedium: {
      padding: 'var(--s8) var(--s16)',
    },
    sizeLarge: {
      padding: 'var(--s12) var(--s24)',
    },
  },
  defaultProps: {
    disableRipple: true,
  },
  variants: [
    {
      props: {
        variant: 'outlined-round',
      },
      style: {
        borderRadius: '24px',
      },
    },
    {
      props: {
        variant: 'contained-round',
      },
      style: {
        borderRadius: '24px',
        '&.Mui-disabled': {
          color: colors['eq-color-neutral-0'],
        },
      },
    },
    {
      props: {
        color: 'neutral',
        variant: 'outlined',
      },
      style: getOutlinedButtonCss('neutral'),
    },
    {
      props: {
        color: 'neutral',
        variant: 'contained',
      },
      style: getContainedButtonCss(palette.neutral),
    },
    ...textButtons,
    {
      props: {
        color: 'neutral',
        variant: 'text',
      },
      style: {
        '&.MuiButtonBase-root': {
          ...neutralTextStyles['&.MuiButtonBase-root'],
          color: palette.neutral.dark,
        },
        '&:hover': {
          ...neutralTextStyles['&:hover'],
          color: palette.neutral.darker,
        },
      },
    },
    ...roundedOutlinedButtons,
    ...roundedContainedButtons,
  ],
} as Components['MuiButton']
