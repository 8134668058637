import { ThemeOptions, createTheme } from '@mui/material/styles'

import { breakpoints } from './breakpoints'
import { components } from './components'
import { palette } from './palette'
import { shadows } from './shadows'
import { typography } from './typography'

export * from './components'

const theme: ThemeOptions = {
  palette,
  breakpoints,
  components,
  shadows,
  typography,
  shape: {
    borderRadius: 0,
  },
  spacing: [4, 8, 12, 16, 24, 32, 56, 88, 144, 232, 376, 608],
}

export const muiTheme = createTheme(theme)
