/**
 * Capitalizes the first letter of a string.
 *
 * @example
 * capitalizeFirstLetter("hello world"); // Output: "Hello world"
 * capitalizeFirstLetter("foo bar"); // Output: "Foo bar"
 * capitalizeFirstLetter("123abc"); // Output: "123abc"
 * capitalizeFirstLetter(""); // Output: ""
 * capitalizeFirstLetter(undefined); // Output: undefined
 *
 * @param str - The input string.
 * @returns The input string with the first letter capitalized, or the input string itself if it is empty or undefined.
 */
export function capitalizeFirstLetter(str: string): string {
  if (!str) {
    return str // Return empty or undefined input as is
  }

  const [firstLetter, ...remainingLetters] = str
  return `${firstLetter.toUpperCase()}${remainingLetters.join('')}`
}
