import styled from '@emotion/styled'
import { CSSProperties, ReactElement } from 'react'

import { colors } from '../../../../equity/particles/colors'
import { Button } from '../../../../equity/web/atoms/button'
import { H6Strong, BodySmall } from '../../typography'

type FallbackContainerProps = {
  backgroundColor?: CSSProperties['backgroundColor']
  width?: CSSProperties['width']
  height?: CSSProperties['height']
}
const FallbackContainer = styled.div<FallbackContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--s56);
  row-gap: var(--eq-s-8);
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
`

const StyledTypographyBodySmall = styled(BodySmall)`
  text-align: center;
  color: rgb(var(--colors-neutral-600));
`

type IconProps = {
  icon: boolean | (() => JSX.Element)
}
const Icon: React.FC<IconProps> = ({ icon = true }) => {
  const Component = typeof icon === 'boolean' ? BarChartImage : icon

  return <Component />
}

const defaults: FallbackProps = {
  icon: BarChartImage,
  title: undefined,
  text: undefined,
  callToAction: undefined,
}

export interface FallbackProps {
  icon?: boolean | (() => JSX.Element)
  title?: string | ReactElement
  text?: string | ReactElement
  callToAction?: {
    text: string
    onClick: () => void
  }
  backgroundColor?: CSSProperties['backgroundColor']
  width?: CSSProperties['width']
  height?: CSSProperties['height']
  testId?: string
}
export const Fallback: React.FC<FallbackProps> = ({
  icon = defaults.icon,
  title = defaults.title,
  text = defaults.text,
  callToAction = defaults.callToAction,
  backgroundColor = colors['eq-color-neutral-100'],
  width = 'auto',
  height = 'auto',
  testId = 'Fallback',
}) => {
  return (
    <FallbackContainer
      backgroundColor={backgroundColor}
      width={width}
      height={height}
      data-testid={testId}
    >
      {icon ? <Icon icon={icon} data-testid="fallback-icon" /> : null}
      {title ? <H6Strong data-testid="fallback-title">{title}</H6Strong> : null}
      {text ? (
        <StyledTypographyBodySmall data-testid="fallback-text">
          {text}
        </StyledTypographyBodySmall>
      ) : null}
      {callToAction ? (
        <Button
          variant="secondary"
          onClick={callToAction.onClick}
          data-testid="fallback-cta"
        >
          {callToAction.text}
        </Button>
      ) : null}
    </FallbackContainer>
  )
}

function BarChartImage() {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64 0H8C3.6 0 0 3.6 0 8V64C0 68.4 3.6 72 8 72H64C68.4 72 72 68.4 72 64V8C72 3.6 68.4 0 64 0ZM24 56H16V28H24V56ZM40 56H32V16H40V56ZM56 56H48V40H56V56Z"
        fill={colors['eq-color-neutral-200']}
      />
    </svg>
  )
}
