import { APP_PATHS } from './apps'

// The HACK! enjoy :) SP doesn't want to change their app name, so...
const getAppBasePath = (path: string) =>
  path === 'structured-investments' ? 'structured-products' : path

export function getAppEnvConfigPath() {
  if (typeof globalThis.location === 'undefined') {
    return '/env.json'
  }
  const appPath = globalThis.location.pathname.split('/')[1]
  let appBasePath = getAppBasePath(appPath)

  // Fallback to load env from the homepage on Portal routes (needed for the external nav)
  if (
    !appBasePath.startsWith('_') &&
    !APP_PATHS.includes(appBasePath as (typeof APP_PATHS)[number])
  ) {
    appBasePath = 'homepage'
  }

  // app paths will never start with an underscore, this is to prevent loading the env.json from
  // /__cypress/env.json for instance
  return appBasePath && !appBasePath.startsWith('_')
    ? `/${appBasePath}/env.json`
    : '/env.json'
}
