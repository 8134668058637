import { Image } from '@unpic/react'

import { cloudinaryUrl } from './cloudinary-utils'

type UnpicImageProps = Parameters<typeof Image>[0]

export function CloudinaryImage(
  props: UnpicImageProps & { options?: { transform?: string } }
) {
  if (!props.src) {
    return null
  }

  // Extract unpic props so that <img> is valid
  const {
    aspectRatio,
    background = 'auto',
    layout,
    options,
    priority,
    src,
    ...imgProps
  } = props

  if (src.startsWith('/')) {
    return <img alt="" {...imgProps} />
  }

  const unpicProps = {
    ...imgProps,
    aspectRatio,
    background,
    layout,
    priority,
    src: cloudinaryUrl(src, options),
  } as UnpicImageProps

  return <Image alt="" {...unpicProps} />
}
