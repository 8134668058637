import { Color } from '../../../../particles/colors'
import { IconType } from '../../../../particles/icons'
import { Button } from '../../button'
import { Icon } from '../../icon'

export type AlertVariant = 'info' | 'warning' | 'error' | 'success'

// A map of each alert variant to its corresponding icon type and color
export const alertBannerIconColorMap: Record<AlertVariant, [IconType, Color]> =
  {
    info: ['Info', 'eq-color-accent-300'],
    warning: ['Warning', 'eq-color-warning-600'],
    error: ['Error', 'eq-color-error-600'],
    success: ['Check', 'eq-color-success-600'],
  }

/**
 * Get the icon type for a given variant
 * @param variant The alert variant
 */
const getIcon = (variant: AlertVariant): IconType => {
  return alertBannerIconColorMap[variant][0]
}

/**
 * Get the icon color for a given variant
 * @param variant The alert variant
 */
const getIconColor = (variant: AlertVariant): Color => {
  return alertBannerIconColorMap[variant][1]
}

export type AlertBannerAction = {
  /** The text to display on the button */
  text: string
  /** A function to call when the button is clicked */
  handler: () => void
  loading?: boolean
}

export type AlertBannerProps = {
  /** The variant of the alert banner */
  variant?: AlertVariant
  /** The text to display in the alert banner. */
  text: string
  /** An optional callback function to dismiss the alert banner */
  onDismiss?: () => void
  action?: AlertBannerAction
  testId?: string
}

/**
 * Alert banners are designed to display important and urgent messages, such as system alerts,
 * that require immediate attention from users.
 * They serve as a visual cue that draws users' attention to the message and prompts them to take appropriate action.
 */
export const AlertBanner = ({
  variant = 'info',
  text,
  onDismiss,
  action,
  testId,
}: AlertBannerProps) => {
  const ActionButton = action?.text && (
    <Button
      variant="secondary"
      size="small"
      loading={action?.loading}
      data-testid="alert-action-button"
      onClick={() => {
        action?.handler()
      }}
    >
      {action.text}
    </Button>
  )

  return (
    <div className="relative flex w-full items-center justify-center bg-neutral-900 px-24 py-16">
      <div className="mx-auto flex items-center justify-center">
        <div className="flex items-center justify-center">
          <Icon
            type={getIcon(variant)}
            color={getIconColor(variant)}
            data-testid="pill-icon"
          />
        </div>
        <p
          className="bodyStrong text-neutral-0 my-4 ml-16"
          data-testid={testId}
        >
          {text}
        </p>
      </div>
      <div className="flex items-center justify-center">
        {ActionButton}
        {onDismiss ? (
          <div
            data-testid="alert-banner"
            className="absolute right-16 flex cursor-pointer items-center justify-center pr-4"
            onClick={onDismiss}
          >
            <Icon type="Close" color="eq-color-neutral-600" />
          </div>
        ) : null}
      </div>
    </div>
  )
}
