export const FacebookIcon = () => {
  return (
    <svg
      aria-hidden={true}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.1345 12.0673C23.1345 5.95498 18.1795 1 12.0673 1C5.95498 1 1 5.95498 1 12.0673C1 17.5912 5.04714 22.1698 10.338 23V15.2664H7.52795V12.0673H10.338V9.629C10.338 6.85527 11.9903 5.32315 14.5183 5.32315C15.7291 5.32315 16.9956 5.5393 16.9956 5.5393V8.26289H15.6001C14.2253 8.26289 13.7965 9.116 13.7965 9.99122V12.0673H16.866L16.3753 15.2664H13.7965V23C19.0874 22.1698 23.1345 17.5912 23.1345 12.0673Z"
        className="duration-short-1 fill-neutral-400 group-hover:fill-neutral-500"
      ></path>
    </svg>
  )
}

export const XIcon = () => {
  return (
    <svg
      aria-hidden={true}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath="url(#clip0_1001_928)">
        <mask
          id="mask0_1001_928"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 0H24V24H0V0Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_1001_928)">
          <path
            d="M1.05364 1L9.54759 13.1343L1 23H2.92372L10.4071 14.3624L16.4535 23H23L14.0281 10.1832L21.9842 1H20.0605L13.1686 8.95503L7.60013 1H1.05364ZM3.88261 2.51397H6.89009L20.1706 21.4858H17.1631L3.88261 2.51397Z"
            className="duration-short-1 fill-neutral-400 group-hover:fill-neutral-500"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1001_928">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const LinkedInIcon = () => {
  return (
    <svg
      aria-hidden={true}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 2.838C1 2.35053 1.19365 1.88303 1.53834 1.53834C1.88303 1.19365 2.35053 1 2.838 1H21.16C21.4016 0.999608 21.6409 1.04687 21.8641 1.13907C22.0874 1.23127 22.2903 1.36661 22.4612 1.53734C22.6322 1.70807 22.7677 1.91083 22.8602 2.13401C22.9526 2.3572 23.0001 2.59643 23 2.838V21.16C23.0003 21.4016 22.9529 21.6409 22.8606 21.8642C22.7683 22.0875 22.6328 22.2904 22.462 22.4613C22.2912 22.6322 22.0884 22.7678 21.8651 22.8602C21.6419 22.9526 21.4026 23.0001 21.161 23H2.838C2.59655 23 2.35746 22.9524 2.1344 22.86C1.91134 22.7676 1.70867 22.6321 1.53798 22.4613C1.3673 22.2905 1.23193 22.0878 1.13962 21.8647C1.04731 21.6416 0.999869 21.4025 1 21.161V2.838ZM9.708 9.388H12.687V10.884C13.117 10.024 14.217 9.25 15.87 9.25C19.039 9.25 19.79 10.963 19.79 14.106V19.928H16.583V14.822C16.583 13.032 16.153 12.022 15.061 12.022C13.546 12.022 12.916 13.111 12.916 14.822V19.928H9.708V9.388ZM4.208 19.791H7.416V9.25H4.208V19.79V19.791ZM7.875 5.812C7.88105 6.08668 7.83217 6.35979 7.73124 6.61532C7.63031 6.87084 7.47935 7.10364 7.28723 7.30003C7.09511 7.49643 6.8657 7.65248 6.61246 7.75901C6.35921 7.86554 6.08724 7.92042 5.8125 7.92042C5.53776 7.92042 5.26579 7.86554 5.01255 7.75901C4.7593 7.65248 4.52989 7.49643 4.33777 7.30003C4.14565 7.10364 3.99469 6.87084 3.89376 6.61532C3.79283 6.35979 3.74395 6.08668 3.75 5.812C3.76187 5.27286 3.98439 4.75979 4.36989 4.38269C4.75539 4.00558 5.27322 3.79442 5.8125 3.79442C6.35178 3.79442 6.86961 4.00558 7.25512 4.38269C7.64062 4.75979 7.86313 5.27286 7.875 5.812Z"
        className="duration-short-1 fill-neutral-400 group-hover:fill-neutral-500"
      ></path>
    </svg>
  )
}
