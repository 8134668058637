const CLOUDINARY_BASE_URL = `https://res.cloudinary.com/cais`

export function calcAspectRatioValue(height?: number, width?: number) {
  return height && width ? width / height : 1
}

export function cloudinaryUrl(id: string, options?: { transform?: string }) {
  if (id.startsWith(CLOUDINARY_BASE_URL)) {
    return id
  }

  if (id.indexOf('.') > 0) {
    console.warn(
      `Image filename contains "." and will break the component - ${id}`
    )
    return ''
  }

  return `${CLOUDINARY_BASE_URL}/image/upload/f_auto/q_auto${
    options?.transform ? `/${options.transform}` : ''
  }/${id}`
}

export function isSvg(url: string) {
  return url?.endsWith('.svg')
}
