import { Link } from '../../../../../equity/web/atoms/link'

import { CardBase } from './card-base'
import { DateTime } from './date-time'
import { getContentOwner, getPageType } from './helper'
import { OwnershipTag } from './ownership-tag'
import type { CuratedContentCardProps } from './types'

export type CuratedCardProps = CuratedContentCardProps & {
  variant: 'primary' | 'secondary'
}

export const CardCurated = (props: CuratedCardProps) => {
  const {
    callToAction,
    title,
    variant,
    contentOwnershipTag,
    date,
    timezone,
    __typename,
  } = props

  return (
    <CardBase variant={`curated${variant === 'primary' ? '-primary' : ''}`}>
      <h3 className="body line-clamp-2 text-balance">
        <Link
          href={callToAction.href}
          className="font-normal text-neutral-900"
          tracking={{
            click_type: 'Content',
            content_type: getPageType(props),
            content_owner: getContentOwner(props),
            item_name: title,
            sub_section: 'Curated Content',
          }}
        >
          {title}
        </Link>
      </h3>
      <DateTime
        date={date}
        includeTime={__typename === 'Event'}
        timezone={timezone}
      />
      <div className="order-first flex flex-wrap items-center justify-between gap-16">
        {contentOwnershipTag?.name ? (
          <OwnershipTag name={contentOwnershipTag.name} />
        ) : (
          <OwnershipTag name="CAIS" />
        )}
        {variant === 'primary' ? (
          <span className="text-brand-800 caption-strong">Highlighted</span>
        ) : (
          ''
        )}
      </div>
    </CardBase>
  )
}
