import cx from 'classnames'

import { Icon } from '../../icon'

import {
  getBackgroundColor,
  getIconColor,
  getPadding,
  getTextColor,
  getFont,
} from './styles'
import { CounterVariant, SemanticColor, Size } from './types'

export type CounterProps = {
  /** The numeric value to display in the counter */
  value: number
  /** The size of the counter. Defaults to `large` */
  size?: Size
  /**  The variant of the counter. Defaults to `dark` */
  variant?: CounterVariant
  /** Callback function to handle dismissal of the counter */
  onDismiss?: () => void
  /** The color of the counter. Defaults to `neutral` */
  color?: SemanticColor
  /** Optional test id value to apply to the `data-testid` property */
  testId?: string
}

/**
 * Counters are visual elements that display a total count for a specific entity. For example, a Counter could represent the number of users in a team.
 */
export const Counter = ({
  value,
  testId = 'counter',
  size = 'large',
  variant = 'dark',
  onDismiss,
  color = 'neutral',
}: CounterProps) => {
  return (
    <button
      onClick={(e) => {
        e.stopPropagation()
        onDismiss?.()
      }}
      data-testid={testId}
      className={cx(
        'flex w-fit min-w-[32px] items-center justify-center rounded-full',
        {
          'cursor-pointer': !!onDismiss,
        },
        getTextColor(variant, color),
        getPadding(size),
        getBackgroundColor(color, variant)
      )}
      type="button"
    >
      <span className={cx('px-4', getFont(size))}>
        {value.toLocaleString('en-US')}
      </span>
      {onDismiss ? (
        <div className="flex items-center justify-center">
          <Icon
            type="Close"
            size="small"
            color={getIconColor(color, variant)}
          />
        </div>
      ) : null}
    </button>
  )
}
