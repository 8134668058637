import { Components } from '@mui/material'

export const MuiFormLabel: Components['MuiFormLabel'] = {
  styleOverrides: {
    root: {
      '.MuiFormLabel-asterisk': {
        color: 'var(--color-red)',
      },
    },
  },
}
