import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { RichTextBaseProps } from './types'

export function RichTextBase(props: RichTextBaseProps) {
  const {
    field,
    renderOptions,
    segmentPageId,
    renderNode,
    urlPrefix,
    overrides,
  } = props

  if (!field || !renderOptions) {
    return null
  }

  const { json, links } = field

  if (!json) {
    return null
  }
  return (
    <>
      {documentToReactComponents(
        json,
        renderOptions({
          links,
          segmentPageId,
          renderNode,
          urlPrefix,
          overrides,
        })
      )}
    </>
  )
}
