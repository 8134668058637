import {
  ContentPermissionsData,
  checkContentAccess,
  filterAllowedPermissionData,
} from '@cais-group/shared/domain/contentful/api'

type SelectFeaturedItem<T> = {
  options?: T[]
  fallback?: T
  userAccessData?: ContentPermissionsData
}

/*
 For each featured option check for a matching FPID on the item with the user's permissions
 Return the first matching item
 If there are zero matches from options, return the fallback item
*/

export function selectFeaturedItem<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends { fundProductIds?: any; firmsCollection?: any }
>(props?: SelectFeaturedItem<T>): T | null {
  const { fallback, options, userAccessData } = props || {}
  let featuredItem = fallback
    ? filterAllowedPermissionData<T>([fallback], userAccessData ?? {})[0] ??
      null
    : null
  if (
    !Array.isArray(options) ||
    (!userAccessData?.firmIds?.length &&
      !userAccessData?.fundProductIds?.length)
  ) {
    return featuredItem
  }

  for (const option of options) {
    const optionFPIDs = option?.fundProductIds?.fundProductIds
    const optionFirmIDs = option?.firmsCollection?.items?.map(
      (firm: { id: string }) => firm.id
    )

    const userHasAccess = checkContentAccess(userAccessData, {
      fundProductIds: optionFPIDs,
      firmIds: optionFirmIDs,
    })
    if (userHasAccess) {
      featuredItem = option
      break
    }
  }

  return featuredItem
}
