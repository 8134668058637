import { previewService } from '../../../../util/contentful/preview-service'
import {
  GetTermsAndConditionsLegalPageBySlugQuery,
  LegalPage,
  useGetTermsAndConditionsLegalPageBySlugQuery,
} from '../../../../util/graphql/mfe-contentful'
import {
  ApiError,
  ApiStateEnum,
  isData,
  useReactQueryResultAsApiState,
} from '../../api'

export type TermsPdf = {
  title:
    | NonNullable<NonNullable<LegalPage['pdfsCollection']>['items'][0]>['title']
  source: NonNullable<
    NonNullable<NonNullable<LegalPage['pdfsCollection']>['items'][0]>['pdf']
  >['url']
} | null

export const useGetTermsAndConditionsLegalPageBySlug = (variables: {
  slug: string
}) => {
  const response = useReactQueryResultAsApiState<
    GetTermsAndConditionsLegalPageBySlugQuery,
    TermsPdf
  >(
    useGetTermsAndConditionsLegalPageBySlugQuery(
      {
        preview: previewService.enabled,
        ...variables,
      },
      { refetchOnWindowFocus: false }
    ),
    (data) => {
      return {
        title:
          data.legalPageCollection?.items[0]?.pdfsCollection?.items[0]?.title ??
          '',
        source:
          data.legalPageCollection?.items[0]?.pdfsCollection?.items[0]?.pdf
            ?.url ?? '',
      }
    },
    `Could not load termsAndConditionslegalPageCollection query for slug: ${variables.slug}`
  )
  return compileResults(response)
}

type Data = TermsPdf | null

const compileResults = (data: Data | ApiError | ApiStateEnum) => {
  return {
    pageData: isData(data) ? data : null,
  }
}
