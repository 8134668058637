import { DateTime } from './date-time'
import { OwnershipTag } from './ownership-tag'

interface TagAndTimeProps {
  contentOwnershipTag?: { name?: string }
  date?: string
  includeTime?: boolean
  timezone?: string
}

export const TagAndTime = (props: TagAndTimeProps) => {
  const { contentOwnershipTag, date, includeTime, timezone } = props
  const content =
    contentOwnershipTag || date ? (
      <>
        {contentOwnershipTag?.name && (
          <OwnershipTag name={contentOwnershipTag.name} />
        )}
        <DateTime date={date} includeTime={includeTime} timezone={timezone} />
      </>
    ) : null

  return contentOwnershipTag && date ? (
    <div className="flex flex-wrap gap-8">{content}</div>
  ) : (
    content
  )
}
