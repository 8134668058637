import { useEffect } from 'react'

/**
 * Calls the supplied callback when the component is unmounted or when
 * the tab / window is closed
 * @param callback the function to call when unmounting or closing tab/window
 */
export const useRunOnUnmount = (callback: (isUnload: boolean) => void) => {
  useEffect(() => {
    // Run when the tab or window is closed
    const isUnloadCallback = () => callback(true)
    window.addEventListener('beforeunload', isUnloadCallback)
    return () => {
      window.removeEventListener('beforeunload', isUnloadCallback)
    }
  }, [callback])

  // This will run when switching routes, intentionally does not specify
  // on the 'callback' dependency so it only fires once on unmount
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => callback(false), [])
}
