import {
  ApiError,
  ApiStateEnum,
  isData,
  isError,
  useReactQueryResultAsApiState,
} from '@cais-group/shared/domain/contentful/api'
import { previewService } from '@cais-group/shared/util/contentful/preview-service'
import {
  useGetLegalPageBySlugQuery,
  GetLegalPageBySlugQuery,
  LegalPage as LegalPageData,
} from '@cais-group/shared/util/graphql/mfe-contentful'

export const useGetLegalPageBySlug = (variables: { slug: string }) => {
  const response = useReactQueryResultAsApiState<
    GetLegalPageBySlugQuery,
    LegalPageData
  >(
    useGetLegalPageBySlugQuery(
      {
        preview: previewService.enabled,
        ...variables,
      },
      { refetchOnWindowFocus: false }
    ),
    (data) => {
      return data?.legalPageCollection?.items[0] as LegalPageData
    },
    `Could not load legalPageCollection query for slug: ${variables.slug}`
  )
  return compileResults(response)
}

type Data = LegalPageData | null

export const legalPageSlugs = {
  disclosure: `disclosure`,
} as const

export const useGetLegalPageDisclaimer = () => {
  return useGetLegalPageBySlug({
    slug: legalPageSlugs.disclosure,
  })
}

const compileResults = (data: Data | ApiError | ApiStateEnum) => {
  return {
    pageData: isData(data) ? data : null,
    error: isError(data),
    loading: data === ApiStateEnum.LOADING,
  }
}
