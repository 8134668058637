import { useEffect, useRef, useState } from 'react'

export type ModalControlProps = {
  isOpen: boolean
  modalRef: React.RefObject<HTMLDialogElement>
  openModal: () => void
  closeModal: () => void
}

/**
 * Custom hook for controlling a modal dialog.
 * @returns An object that contains the state of the modal (isOpen), a ref to the modal (modalRef),
 * and two functions for showing and hiding the modal (showModal and onClose).
 */
export function useModal(): ModalControlProps {
  /**
   * A ref to the HTMLDialogElement.
   */
  const modalRef = useRef<HTMLDialogElement>(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)

  /**
   * Effect that observes the 'open' attribute of the dialog.
   * When the 'open' attribute changes, it updates the 'isOpen' state.
   */
  useEffect(() => {
    let observer: MutationObserver

    if (modalRef.current) {
      observer = new MutationObserver((mutations) => {
        const openMutations = mutations
          .filter((mutation) => mutation.attributeName === 'open')
          .reverse() // Reverse the order to get the last occurrence

        if (openMutations.length > 0) {
          const target = openMutations[0].target as HTMLDialogElement
          setIsOpen(target.open)
        }
      })

      observer.observe(modalRef.current, { attributes: true })
    }

    return () => observer?.disconnect()
  }, [])

  return {
    isOpen,
    modalRef,
    openModal: () => {
      if (__NX_BUILD_MODE__ === 'test') {
        modalRef.current?.setAttribute('open', '')
        return
      }
      modalRef.current?.showModal()
    },
    closeModal: () => {
      if (__NX_BUILD_MODE__ === 'test') {
        modalRef.current?.removeAttribute('open')
        return
      }
      modalRef.current?.close()
    },
  }
}
