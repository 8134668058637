import { useEffect, type MutableRefObject } from 'react'

export const useFocusOut = <T extends HTMLElement>(
  ref: MutableRefObject<T>,
  callback: () => void
) => {
  useEffect(() => {
    const refCurrent = ref.current
    const handleFocusOut = (event: FocusEvent) => {
      // Note: relatedTarget is the appropriate approach for this check
      if (!ref.current.contains(event.relatedTarget as HTMLElement)) {
        callback()
      }
    }

    refCurrent.addEventListener('focusout', handleFocusOut)

    return () => {
      refCurrent.removeEventListener('focusout', handleFocusOut)
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
