import { TailwindEquityColor } from '../../../../particles/colors'

import { CheckboxColor } from './types'

/**
 * Map of semantic colors to border colors for the checkbox component
 */
const borderColorMap: Record<CheckboxColor, Array<TailwindEquityColor>> = {
  primary: [
    '[&>svg]:border-neutral-300',
    'group-hover:peer-enabled:peer-checked:[&>svg]:border-primary-700',
    'group-hover:peer-enabled:[&>svg]:border-primary-600',
    'peer-checked:peer-enabled:[&>svg]:border-primary-600',
  ],
  success: [
    '[&>svg]:border-neutral-300',
    'group-hover:peer-enabled:peer-checked:[&>svg]:border-success-700',
    'group-hover:peer-enabled:[&>svg]:border-success-600',
    'peer-checked:peer-enabled:[&>svg]:border-success-600',
  ],
  error: [
    '[&>svg]:border-neutral-300',
    'group-hover:peer-enabled:peer-checked:[&>svg]:border-error-700',
    'group-hover:peer-enabled:[&>svg]:border-error-600',
    'peer-checked:peer-enabled:[&>svg]:border-error-600',
  ],
  warning: [
    '[&>svg]:border-neutral-300',
    'group-hover:peer-enabled:peer-checked:[&>svg]:border-warning-700',
    'group-hover:peer-enabled:[&>svg]:border-warning-600',
    'peer-checked:peer-enabled:[&>svg]:border-warning-600',
  ],
}

/**
 * Map of semantic colors to background colors for the checkbox component
 */
const backgroundColorMap: Record<CheckboxColor, Array<TailwindEquityColor>> = {
  primary: [
    'peer-checked:peer-disabled:[&>svg]:bg-neutral-300',
    'peer-disabled:[&>svg]:bg-neutral-100',
    'peer-checked:peer-enabled:[&>svg]:bg-primary-600',
    'group-hover:peer-enabled:peer-checked:[&>svg]:bg-primary-700',
  ],
  success: [
    'peer-checked:peer-disabled:[&>svg]:bg-neutral-300',
    'peer-disabled:[&>svg]:bg-neutral-100',
    'peer-checked:peer-enabled:[&>svg]:bg-success-600',
    'group-hover:peer-enabled:peer-checked:[&>svg]:bg-success-700',
  ],
  error: [
    'peer-checked:peer-disabled:[&>svg]:bg-neutral-300',
    'peer-disabled:[&>svg]:bg-neutral-100',
    'peer-checked:peer-enabled:[&>svg]:bg-error-600',
    'group-hover:peer-enabled:peer-checked:[&>svg]:bg-error-700',
  ],
  warning: [
    'peer-checked:peer-disabled:[&>svg]:bg-neutral-300',
    'peer-disabled:[&>svg]:bg-neutral-100',
    'peer-checked:peer-enabled:[&>svg]:bg-warning-600',
    'group-hover:peer-enabled:peer-checked:[&>svg]:bg-warning-700',
  ],
}

/**
 * Returns the border color for the checkbox component
 *
 * @param color The semantic color of the checkbox
 */
export const getBorderColor = (color: CheckboxColor): string => {
  return borderColorMap[color].join(' ')
}

/**
 * Returns the background color for the checkbox component
 *
 * @param color The semantic color of the checkbox
 */
export const getBackgroundColor = (color: CheckboxColor): string => {
  return backgroundColorMap[color].join(' ')
}
