import { LogoutOptions, Auth0ContextInterface } from '@auth0/auth0-react'
import { assign, createMachine, interpret } from 'xstate'

export const interval = 10000

const warnMessage = (name: string) =>
  console.warn(`userIdleMachine: ${name} fn not attached!`)

export type Auth0Logout = (options?: LogoutOptions) => void

export type GetTokenSilently = Auth0ContextInterface['getAccessTokenSilently']
type UserIdleEvents = {
  type: 'attachMethods'
  logout: Auth0Logout
  getAccessTokenSilently: GetTokenSilently
}

type RejectWithWarning = () => Promise<never>
type UserIdleContext = {
  logoutHandle: Auth0Logout
  getAccessTokenSilentlyHandle: GetTokenSilently | RejectWithWarning
}

export const userIdleMachine = createMachine<UserIdleContext, UserIdleEvents>(
  {
    initial: 'init',
    predictableActionArguments: true,
    context: {
      logoutHandle: () => warnMessage('logout'),
      getAccessTokenSilentlyHandle: () => {
        return Promise.reject(warnMessage('getAccessTokenSilently'))
      },
    },
    states: {
      init: {
        on: {
          attachMethods: {
            target: 'countdown',
            actions: assign({
              logoutHandle: (context, data) => data.logout,
              getAccessTokenSilentlyHandle: (context, data) =>
                data.getAccessTokenSilently,
            }),
          },
        },
      },
      countdown: {
        after: {
          [interval]: 'validate',
        },
      },
      validate: {
        invoke: {
          src: 'getAccessTokenSilently',
          onDone: {
            target: 'countdown',
          },
          onError: {
            target: 'logout',
          },
        },
      },
      logout: {
        entry: ['logout'],
      },
    },
  },
  {
    actions: {
      logout: (context) => context.logoutHandle(),
    },
    services: {
      getAccessTokenSilently: async (context) => {
        return context.getAccessTokenSilentlyHandle()
      },
    },
  }
)

export const userIdleService = interpret(userIdleMachine).start()
