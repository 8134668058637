import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'

import { authService } from './auth-service'
import { userIdleService } from './userIdleMachine'

/**
 * Set the token generator on the auth service for easy access outside of hooks.
 * We set this here rather than in the container app because we need it to be
 * the same instance as the state is stored directly in the service on a root
 * level const.
 */
export const useSetAuthTokenGetter = (isIdleServiceEnabled?: boolean) => {
  const { getAccessTokenSilently, isAuthenticated, logout } = useAuth0()

  useEffect(() => {
    // Only set the user idle service check if user is authenticated.
    // This is relevant for CAIS IQ because, unlike the other apps, it has some routes that do not
    // require authentication (like the CAIS IQ Direct login page).
    if (isAuthenticated && isIdleServiceEnabled) {
      userIdleService.send({
        type: 'attachMethods',
        logout,
        getAccessTokenSilently,
      })
    }
  }, [isAuthenticated, logout, getAccessTokenSilently, isIdleServiceEnabled])

  useEffect(() => {
    authService.setTokenGenerator(getAccessTokenSilently)
  }, [getAccessTokenSilently])
}
