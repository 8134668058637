import styled from '@emotion/styled'

import { spacing } from '../../../../../equity/particles/spacing'

export type LayoutBoxProps = {
  padding?: (typeof spacing)[keyof typeof spacing] | '0'
  [key: string]: unknown
}

export const LayoutBox = styled.div<LayoutBoxProps>`
  padding: ${(props) => props.padding || spacing.s16};
`
LayoutBox.displayName = 'Box'
LayoutBox.defaultProps = {
  'data-layout': 'Box',
}
