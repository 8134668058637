import { mapTrackingKeysToDataAttributes } from '../../../../../equity/utils/tracking-utils'
import { ButtonLink } from '../../../../../equity/web/atoms/button'
import { InfoTag } from '../../../../../equity/web/atoms/info-tag'
import { isExternalLink } from '../../../../../equity/web/atoms/link'
import { ContentfulImage } from '../../../contentful-image'

import { CardBase } from './card-base'
import type { BaseCardProps } from './card-base'
import { ExternalCardDescription } from './components/external-card-description'
import { FallbackImage } from './fallback-image'
import { getContentOwner, getPageType } from './helper'
import { TagAndTime } from './tag-and-time'
import type { CardProps, TextCardProps } from './types'

/**
 * This is the default card component used for contentful cards that are not of __typename 'ComponentText'
 */
export const Card = (props: BaseCardProps & CardProps) => {
  const {
    __typename,
    date,
    description,
    href,
    imageComponent,
    thumbnail,
    timezone,
    title,
    testId,
    type,
    externalContentDetails,
    callToAction,
    icon,
    contentOwnershipTag,
    tracking,
    ...rest
  } = props
  const tag = props.tag || getPageType(props)
  const withExternalContent = externalContentDetails && type
  const Image = imageComponent || ContentfulImage
  const externalLink = isExternalLink(href)

  return (
    <CardBase
      {...mapTrackingKeysToDataAttributes(tracking)}
      {...rest}
      testId={`card-${testId}`}
    >
      <hgroup className="@lg/main-column:p-24 @md/main-column:gap-8 flex flex-auto flex-col gap-4 p-16">
        {icon?.url ? (
          <img src={icon.url} height="32" width="32" alt="" />
        ) : null}
        <h3 className="small-strong lg:body-strong line-clamp-2">{title}</h3>
        <TagAndTime
          date={date}
          includeTime={__typename === 'Event'}
          timezone={timezone}
          contentOwnershipTag={contentOwnershipTag}
        />

        {description && !withExternalContent ? (
          <p className="small md:body description-neutral-600 line-clamp-3">
            {description}
          </p>
        ) : null}

        {withExternalContent ? (
          <ExternalCardDescription
            externalContentDetails={externalContentDetails}
          />
        ) : null}
        {href ? (
          <span className="mt-auto">
            <ButtonLink
              variant="tertiary"
              endAdornment={externalLink ? 'Launch' : 'ArrowRight'}
              href={href}
              tracking={{
                click_type: 'Content',
                content_type: getPageType(props),
                content_owner: getContentOwner(props),
                item_name: title,
              }}
            >
              {callToAction}
            </ButtonLink>
          </span>
        ) : null}
      </hgroup>
      <div
        className="relative order-first"
        aria-label={tag === 'Whitepaper' ? 'PDF' : tag}
      >
        {thumbnail ? (
          <Image
            src={thumbnail.url}
            aspectRatio={2}
            layout="fullWidth"
            alt={thumbnail.alt || thumbnail.title || ''}
          />
        ) : (
          <FallbackImage />
        )}
        <span className="@lg/main-column:bottom-24 @lg/main-column:left-24 absolute bottom-16 left-16">
          <InfoTag>{tag === 'Whitepaper' ? 'PDF' : tag}</InfoTag>
        </span>
      </div>
    </CardBase>
  )
}

/**
 * This is the card component used for contentful cards of __typename 'ComponentText'
 */
export const TextCard = (props: BaseCardProps & TextCardProps) => {
  const { description, title, testId, variant = 'text' } = props

  return (
    <CardBase
      {...props}
      testId={`card-${testId}`}
      variant={variant}
      tracking={{ item_name: title }}
    >
      <hgroup className="flex flex-auto flex-col gap-16 p-24 md:gap-24 md:p-32">
        <h3 className="small-strong lg:body-strong">{title}</h3>
        <div className="small md:body description-neutral-600 flex flex-auto flex-col gap-24">
          {description}
        </div>
      </hgroup>
    </CardBase>
  )
}
